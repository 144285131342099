import React, { useState } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../ui/dialog';
import { Card, CardHeader, CardContent } from '../ui/card';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceLine } from 'recharts';
import { Check, X, Plus, Clock, AlertCircle, Info, FileQuestion, CheckCircle2, XCircle, AlertTriangle } from 'lucide-react';

// Define interfaces for better type safety
interface RDIStatus {
  color: string;
  icon: React.FC<{ className?: string }>;
}

interface Estados {
  [key: string]: RDIStatus;
}

interface RDIData {
  id: number;
  numero: string;
  asunto: string;
  documentos: string;
  nombre: string;
  dias: number;
  estatus: keyof typeof ESTADOS;
}

interface NewRDI {
  numero: string;
  asunto: string;
  dias: string;
  estatus: keyof typeof ESTADOS;
  anexos: string[];
}

const ESTADOS: Estados = {
  'En Revisión': { color: 'text-blue-500', icon: Clock },
  'Aprobado': { color: 'text-green-600', icon: CheckCircle2 },
  'Rechazado': { color: 'text-red-600', icon: XCircle },
  'Pendiente Información': { color: 'text-orange-500', icon: FileQuestion },
  'En Proceso': { color: 'text-purple-500', icon: Info },
  'Cerrado': { color: 'text-gray-600', icon: Check },
  'Anulado': { color: 'text-red-800', icon: X },
  'Extemporáneo': { color: 'text-yellow-600', icon: AlertTriangle }
};

type EstadoType = keyof typeof ESTADOS;


const RDIDashboard: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [newRDI, setNewRDI] = useState<NewRDI>({
    numero: '',
    asunto: '',
    dias: '',
    estatus: 'En Revisión',
    anexos: []
  });

  const [data, setData] = useState<RDIData[]>([
    { 
      id: 1, 
      numero: 'RDI N°1', 
      asunto: 'Solicitud de especificaciones técnicas',
      documentos: 'Ver documentos', 
      nombre: 'RDI 1', 
      dias: 4, 
      estatus: 'Aprobado' 
    },
    { 
      id: 2, 
      numero: 'RDI N°2', 
      asunto: 'Cambio en materiales de construcción',
      documentos: 'Ver documentos', 
      nombre: 'RDI 2',
      dias: 2, 
      estatus: 'Rechazado' 
    },
    { 
      id: 3, 
      numero: 'RDI N°3', 
      asunto: 'Actualización de planos estructurales',
      documentos: 'Ver documentos', 
      nombre: 'RDI 3',
      dias: 1, 
      estatus: 'En Proceso' 
    },
    { 
      id: 4, 
      numero: 'RDI N°4', 
      asunto: 'Consulta sobre normativa sísmica',
      documentos: 'Ver documentos', 
      nombre: 'RDI 4',
      dias: 8, 
      estatus: 'Pendiente Información' 
    },
    { 
      id: 5, 
      numero: 'RDI N°5', 
      asunto: 'Modificación de cronograma',
      documentos: 'Ver documentos', 
      nombre: 'RDI 5',
      dias: 10, 
      estatus: 'Extemporáneo' 
    },
    { 
      id: 6, 
      numero: 'RDI N°6', 
      asunto: 'Solicitud de cambio de proveedor',
      documentos: 'Ver documentos', 
      nombre: 'RDI 6',
      dias: 12, 
      estatus: 'Cerrado' 
    },
    { 
      id: 7, 
      numero: 'RDI N°7', 
      asunto: 'Validación de presupuesto adicional',
      documentos: 'Ver documentos', 
      nombre: 'RDI 7',
      dias: 5, 
      estatus: 'En Revisión' 
    }
  ]);

  const handleClose = () => {
    setIsOpen(false);
  };


  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setNewRDI(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const newId = data.length + 1;
    const newEntry: RDIData = {
      id: newId,
      numero: `RDI N°${newId}`,
      asunto: newRDI.asunto,
      documentos: 'Ver documentos',
      nombre: `RDI ${newId}`,
      dias: parseInt(newRDI.dias) || 0,
      estatus: newRDI.estatus
    };
    
    setData(prev => [...prev, newEntry]);
    setIsOpen(false);
    setNewRDI({
      numero: '',
      asunto: '',
      dias: '',
      estatus: 'En Revisión',
      anexos: []
    });
  };

  const handleAddAnexo = () => {
    if (newRDI.anexos.length < 3) {
      setNewRDI(prev => ({
        ...prev,
        anexos: [...prev.anexos, `Anexo ${prev.anexos.length + 1}`]
      }));
    }
  };

  const calcularEstadisticas = () => {
    const dias = data.map(item => item.dias);
    const promedio = dias.reduce((a, b) => a + b, 0) / dias.length;
    const varianza = dias.reduce((a, b) => a + Math.pow(b - promedio, 2), 0) / dias.length;
    const desviacionEstandar = Math.sqrt(varianza);
    
    return {
      promedio: Math.round(promedio * 10) / 10,
      desviacionEstandar: Math.round(desviacionEstandar * 10) / 10,
      limiteInferior: Math.round((promedio - desviacionEstandar) * 10) / 10,
      limiteSuperior: Math.round((promedio + desviacionEstandar) * 10) / 10
    };
  };

  const estadisticas = calcularEstadisticas();

  const getStatusIcon = (status: keyof typeof ESTADOS) => {
    const statusConfig = ESTADOS[status];
    if (!statusConfig) return null;
    
    const IconComponent = statusConfig.icon;
    return (
      <div className={`flex items-center ${statusConfig.color}`}>
        <IconComponent className="w-4 h-4 mr-1" />
        {status}
      </div>
    );
  };

  return (
    <div className="w-full max-w-6xl mx-auto p-4">
      <Card>
        <CardHeader>
          <div className="flex justify-between items-center">
            <h2>GESTIÓN ► REQUERIMIENTOS DE INFORMACIÓN (RDI)</h2>
            <div className="flex gap-4 items-center">
              <select 
                className="p-2 border rounded"
                onChange={(e) => {
                  console.log(e.target.value);
                }}
              >
                <option value="">Filtrar por Estado</option>
                {Object.keys(ESTADOS).map(estado => (
                  <option key={estado} value={estado}>{estado}</option>
                ))}
              </select>
              <Dialog isOpen={isOpen} onClose={handleClose}>
                <button className="flex items-center gap-2">
                  <Plus className="w-4 h-4" />
                  Nuevo RDI
                </button>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>Agregar nuevo RDI</DialogTitle>
                  </DialogHeader>
                  <form onSubmit={handleSubmit} className="space-y-4">
                    <div className="space-y-2">
                      <label htmlFor="asunto">Asunto</label>
                      <Input
                        id="asunto"
                        name="asunto"
                        value={newRDI.asunto}
                        onChange={handleInputChange}
                        required
                      />
                    </div>

                    <div className="space-y-2">
                      <label>Anexos</label>
                      {[1, 2, 3].map((num) => (
                        <div key={num} className="text-red-500 hover:text-red-700 cursor-pointer">
                          Añadir Anexo {num}
                        </div>
                      ))}
                    </div>

                    <div className="space-y-2">
                      <label htmlFor="dias">Días de Respuesta</label>
                      <Input
                        id="dias"
                        name="dias"
                        type="number"
                        value={newRDI.dias}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="space-y-2">
                      <label htmlFor="estatus">Estado</label>
                      <select
                        id="estatus"
                        name="estatus"
                        className="w-full p-2 border rounded"
                        value={newRDI.estatus}
                        onChange={handleInputChange}
                      >
                        {Object.keys(ESTADOS).map(estado => (
                          <option key={estado} value={estado}>{estado}</option>
                        ))}
                      </select>
                    </div>
                    <button type="submit" className="w-full">
                      Agregar RDI
                    </button>
                  </form>
                </DialogContent>
              </Dialog>
            </div>
          </div>
        </CardHeader>
        <CardContent>
          <div className="overflow-x-auto">
            <table className="w-full border-collapse">
              <thead>
                <tr className="bg-blue-500 text-white">
                  <th className="p-3 text-left">RDI N°</th>
                  <th className="p-3 text-left w-1/4">Asunto</th>
                  <th className="p-3 text-left">Ver documentos</th>
                  <th className="p-3 text-left">RDI</th>
                  <th className="p-3 text-left">Días de Respuesta</th>
                  <th className="p-3 text-left">Estatus</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                  <tr key={item.id} className="border-b hover:bg-gray-50">
                    <td className="p-3">{item.numero}</td>
                    <td className="p-3">{item.asunto}</td>
                    <td className="p-3">
                      <button className="text-blue-500 hover:underline">
                        {item.documentos}
                      </button>
                    </td>
                    <td className="p-3">{item.nombre}</td>
                    <td className="p-3">{item.dias}</td>
                    <td className="p-3">
                      {getStatusIcon(item.estatus)}
                    </td>
                  </tr>
                ))}
                <tr className="bg-yellow-100">
                  <td colSpan={4} className="p-3 font-bold">Promedio ± Desv. Est.</td>
                  <td className="p-3 font-bold">
                    {estadisticas.promedio} ± {estadisticas.desviacionEstandar}
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="mt-8">
            <select className="w-full p-2 border rounded mb-4">
              <option value="">Documento Pago RDI</option>
              {data.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.numero} - {item.asunto}
                </option>
              ))}
            </select>

            <div className="mt-4">
              <BarChart width={800} height={300} data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="nombre" />
                <YAxis />
                <Tooltip content={({ active, payload }) => {
                  if (active && payload && payload.length) {
                    return (
                      <div className="bg-white p-2 border rounded shadow">
                        <p className="font-bold">{payload[0].payload.asunto}</p>
                        <p>Días: {payload[0].value}</p>
                        <p>Estatus: {payload[0].payload.estatus}</p>
                      </div>
                    );
                  }
                  return null;
                }}/>
                <Bar dataKey="dias" fill="#8884d8" name="Días" />
                <ReferenceLine y={estadisticas.promedio} label="Promedio" stroke="#ff7300" strokeDasharray="3 3" />
                <ReferenceLine y={estadisticas.limiteSuperior} label="+1σ" stroke="#82ca9d" strokeDasharray="3 3" />
                <ReferenceLine y={estadisticas.limiteInferior} label="-1σ" stroke="#82ca9d" strokeDasharray="3 3" />
              </BarChart>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default RDIDashboard;