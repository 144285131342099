import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { Gantt, Task, ViewMode, EventOption, StylingOption, DisplayOption } from 'gantt-task-react-lite';
import "gantt-task-react-lite/dist/index.css";
import { Button } from '../ui/button';
import { ZoomIn, ZoomOut, Maximize, Minimize, Layers, ChevronRight, ChevronDown, Shrink, Expand, LucideZoomIn, LucideZoomOut, X, ChevronUp, Check, Loader2, Calendar, Clock, AlertTriangle, Info, CheckCircle, XCircle } from 'lucide-react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { motion, AnimatePresence, progress } from 'framer-motion';
import dateUtils from './dateUtils';



import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from '../ui/dialog';
import toast, { Toaster } from 'react-hot-toast';
import { MdBusiness } from 'react-icons/md';
import { group } from 'console';
import { start } from 'repl';


interface UpdateTaskEvent {
  taskId: string;
  groupId?: string;
  updates: {
    name?: string;
    start?: Date;
    end?: Date;
    progress?: number;
    isClosed?: boolean;
    comments?: string;
    reminder_code?: string;
    reminder_type?: string;
    reminder_value?: string;
  };
}


enum SemaphoreStatus {
  Red = "red",
  Orange = "orange",
  Yellow = "yellow",
  Green = "green",
  Gray = "gray"
  
}
  

const ProgressSlider: React.FC<{
  value: number;
  onChange: (value: number) => void;
  style?: React.CSSProperties;
  ariaLabel?: string;
}> = ({ value, onChange, style, ariaLabel }) => {
  return (
    <Slider
      value={value}
      min={0}
      max={100}
      step={1}
      railStyle={{
        height: 8,
        backgroundColor: '#e0e0e0',
      }}
      handleStyle={{
        width: 16,
        height: 16,
        marginLeft: -8,
        marginTop: -4,
        backgroundColor: '#0044ff',
        border: 'none',
      }}
      trackStyle={{
        backgroundColor: '#0044ff',
      }}
      style={{
        width: '100%',
        ...style,
      }}
    />
  );
};





const CustomHeader: React.FC<{
  headerHeight: number;
  rowWidth: string;
  fontFamily: string;
  fontSize: string;
}> = ({ headerHeight, rowWidth, fontFamily, fontSize }) => {
  
  return (
    <div
      className="gantt-task-list-header"
      style={{
        height: headerHeight,
        fontFamily: fontFamily,
        fontSize: fontSize,
      }}
    >
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr style={{ height: `${headerHeight}px` }}>
           {/* Columna de posición */}
           <th style={{
              width: '40px',  // Ancho de la columna de posición
              textAlign: 'right',
              paddingLeft: '5px',
              paddingRight: '5px',
              borderBottom: '2px solid #e0e0e0',
              fontWeight: 'bold',
            }}>
              #
            </th>
            {/* Columna de agrupador/tarea con espacio extra para el chevron */}
            <th style={{
  width: `calc(${rowWidth} - 100px)`, // Ajuste para espacio extra
  textAlign: 'center',
              paddingLeft: '5px',
              paddingRight: '20px', // Alineación igual que en las celdas
              borderBottom: '2px solid #e0e0e0',
              fontWeight: 'bold',
            }}>
              Agrupador/Tarea
            </th>
            {/* Columna de dependencia */}
            <th style={{
              width: '120px',
              textAlign: 'left',
              paddingLeft: '5px',
              borderBottom: '2px solid #e0e0e0',
              fontWeight: 'bold',
            }}>
              Dependencia
            </th>
            {/* Columna desde */}
            <th style={{
              width: '80px',
              textAlign: 'left',
              paddingLeft: '35px',
              borderBottom: '2px solid #e0e0e0',
              fontWeight: 'bold',
            }}>
              Desde
            </th>
            {/* Columna hasta */}
            <th style={{
              width: '120px',
              textAlign: 'left',
              paddingLeft: '40px',
              borderBottom: '2px solid #e0e0e0',
              fontWeight: 'bold',
            }}>
              Hasta
            </th>
          </tr>
        </thead>
      </table>
    </div>
  );
};



interface DateSetup {
  dates: Date[];
  // Añade otras propiedades si son necesarias
}


interface ResolutionBadgeProps {
  type?: string;
  className?: string;
  style?: React.CSSProperties;
}

// Global function for resolution styling
const getResolutionStyle = (type: string | undefined): string => {
  switch (type) {
    case 'APROBADO':
      return 'bg-green-100 text-green-800';
    case 'RECHAZADO':
      return 'bg-red-100 text-red-800';
    case 'DESISTIMIENTO':
      return 'bg-orange-100 text-orange-800';
    case 'SILENCIO ADMINISTRATIVO POSITIVO':
      return 'bg-green-100 text-green-800';
    case 'SILENCIO ADMINISTRATIVO NEGATIVO':
      return 'bg-red-100 text-red-800';
    case 'NO ADMITIDO':
      return 'bg-red-100 text-red-800';
    default:
      return 'bg-gray-100 text-gray-800';
  }
};

// Global resolution badge component
const ResolutionBadge: React.FC<ResolutionBadgeProps> = ({ type, className = '', style = {} }) => (
  <div 
    className={`inline-flex items-center shrink-0 ${getResolutionStyle(type)} ${className}`}
    style={{
      padding: '0px 3px',
      borderRadius: '9999px',
      fontSize: '0.6rem',
      lineHeight: '1rem',
      fontWeight: 500,
      whiteSpace: 'nowrap',
      maxWidth: '200px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      position: 'absolute',
      right: '0px',
      ...style
    }}
    title={type || 'PENDIENTE'}
  >
    {type || 'PENDIENTE'}
  </div>
);

// Global helper to check if task is a subtask with resolution
const isResolutionSubtask = (task: Task): boolean => {
  if (!task.id) return false;
  const parts = String(task.id).split('-');
  const subtask = task as unknown as ExtendedSubtask;
  return parts.length === 3 && subtask.name === 'RESOLUCIÓN';
};

// Global function to get resolution badge for a task
const getGlobalResolutionBadge = (task: Task): JSX.Element | null => {
  if (!isResolutionSubtask(task)) return null;
  
  const subtask = task as unknown as ExtendedSubtask;
  return <ResolutionBadge type={subtask.resolutionType }  />;
};



const formattedResolutionType = (resolutionType: string) => {
  const resolutionLabel = resolutionTypes.find(rt => rt.value === resolutionType)?.label.toUpperCase();

  if (resolutionLabel === 'SILENCIO ADMINISTRATIVO POSITIVO') {
    return (
      <span className="flex items-center gap-1">
        <CheckCircle className="text-green-500" size={16} /> SAP
      </span>
    );
  }

  if (resolutionLabel === 'SILENCIO ADMINISTRATIVO NEGATIVO') {
    return (
      <span className="flex items-center gap-1">
        <XCircle className="text-red-500" size={16} /> SAN
      </span>
    );
  }

  return resolutionLabel;  // Para otros casos, simplemente retorna el label en mayúsculas.
};


const CustomTaskListTable: React.FC<{
  rowHeight: number;
  rowWidth: string;
  fontFamily: string;
  fontSize: string;
  locale: string;
  tasks: Task[];
  selectedTaskId: string;
  setSelectedTask: (taskId: string) => void;
  onExpanderClick: (task: Task) => void;
}> = ({
  rowHeight,
  rowWidth,
  fontFamily,
  fontSize,
  locale,
  tasks,
  selectedTaskId,
  setSelectedTask,
  onExpanderClick,
}) => {
  const [hoveredTaskId, setHoveredTaskId] = useState<string | null>(null);
 

  // Función para obtener el número de tarea dentro de su grupo
  const getTaskNumberInGroup = (task: Task): number => {
    if (!task.project?.startsWith('group-')) return 0;
    
    const groupId = task.project;
    let currentNumber = 0;
    
    // Recorrer todas las tareas hasta encontrar la actual
    for (let i = 0; i < tasks.length; i++) {
      const currentTask = tasks[i];
      
      // Si encontramos la tarea que estamos buscando, devolver el número actual
      if (currentTask.id === task.id) {
        return currentNumber + 1;
      }
      
      // Incrementar el contador solo para tareas regulares del mismo grupo
      if (currentTask.project === groupId && 
          !currentTask.type?.includes('project') && 
          !currentTask.project?.includes('-')) {
        currentNumber++;
      }
    }
    return currentNumber;
  };

  // Función para generar la numeración jerárquica
  const getTaskNumber = (task: Task, index: number): string => {
    // Si es un grupo principal, no mostrar número
    if (task.type === 'project' && !task.project) {
      return '';
    }

    // Para subtareas de TRAMITACIÓN
    if (task.project && task.project.includes('-')) {
      const [groupId, taskId, subtaskId] = task.id.split('-').map(Number);
      const parentTask = tasks.find(t => t.id === task.project);
      if (parentTask && parentTask.name === 'TRAMITACIÓN') {
        // Obtener el número de la tarea TRAMITACIÓN dentro de su grupo
        const parentNumber = taskId;
        return `${parentNumber}.${subtaskId}`;
      }
    }

    // Para tareas regulares dentro de un grupo
    if (task.project?.startsWith('group-')) {
      const [_, taskId] = task.id.split('-').map(Number);
      return taskId.toString();
    }

    return '';
  };

  const formatDate = (date: Date): string => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // Función para generar colores para los agrupadores
  const getGroupColor = (index: number): string => {
    const colors = ['#FFD700', '#98FB98', '#87CEFA', '#FFA07A', '#DDA0DD'];
    return colors[index % colors.length];
  };

  const darkenColor = (color: string, amount: number = 0.2): string => {
    // Convertir el color hexadecimal a RGB
    let [r, g, b] = color.match(/\w\w/g)?.map((c) => parseInt(c, 16)) || [0, 0, 0];
    
    // Oscurecer los valores RGB
    r = Math.max(0, Math.min(255, r - Math.round(255 * amount)));
    g = Math.max(0, Math.min(255, g - Math.round(255 * amount)));
    b = Math.max(0, Math.min(255, b - Math.round(255 * amount)));
    
    // Convertir de vuelta a hexadecimal
    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
  };


  const lightenColor = (color: string, amount: number = 0.2): string => {
    // Convertir el color hexadecimal a RGB
    let [r, g, b] = color.match(/\w\w/g)?.map((c) => parseInt(c, 16)) || [0, 0, 0];
    
    // Aumentar los valores RGB para aclarar el color
    r = Math.max(0, Math.min(255, r + Math.round(255 * amount)));
    g = Math.max(0, Math.min(255, g + Math.round(255 * amount)));
    b = Math.max(0, Math.min(255, b + Math.round(255 * amount)));
    
    // Convertir de vuelta a hexadecimal
    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
  };
  
  

  const removeAlpha = (color: string): string => {
    // Si el color tiene 8 caracteres (incluyendo alpha), recortar los últimos 2.
    return color.length === 9 ? color.slice(0, 7) : color;
  };
  const formatDependencies = (dependencies: string[] | undefined): string => {
    if (!dependencies || dependencies.length === 0) return '';
    
    return dependencies.map(dep => {
      const parts = dep.split('-');
      const depTask = tasks.find(t => t.id === dep);
      if (!depTask) return '';

      // Si es una subtarea de TRAMITACIÓN
      if (parts.length === 3) {
        const parentTask = tasks.find(t => t.id === depTask.project);
        if (parentTask) {
          const parentNumber = getTaskNumberInGroup(parentTask);
          const subtaskIndex = parseInt(parts[2]);
          return `${parentNumber}.${subtaskIndex}`;
        }
      }
      
      return getTaskNumber(depTask, tasks.indexOf(depTask));
    }).filter(Boolean).join(', ');
  };
  const getTaskBackgroundColor = (task: Task, isHovered: boolean, isSelected: boolean): string => {
    let color;
    
    // Verifica si la tarea es "TRAMITACIÓN"
    if (task.name === 'TRAMITACIÓN') {
        // Establece un color neutro (blanco) para tareas de "TRAMITACIÓN"
        color = '#FFFFFF';
    } else if (task.type === 'project') {
        // Mantiene el color de fondo para encabezados de grupo (proyectos)
        color = task.styles?.backgroundColor || getGroupColor(parseInt(task.id.split('-')[1]));
    } else {
        // Por defecto, color blanco para tareas regulares
        color = '#FFFFFF';
    }

    // Solo aplica estilos de selección y hover a tareas que no sean de tipo "project" ni "TRAMITACIÓN"
    if (task.type !== 'project' && task.name !== 'TRAMITACIÓN') {
        if (isSelected) {
            return darkenColor(lightenColor(color, 0.2), 0.1); // Ajuste para selección
        } else if (isHovered) {
            return darkenColor(lightenColor(color, 0.3), 0.1); // Ajuste para hover
        }
    }

    return color;
};





  let currentGroupIndex = -1;
  let taskIndexInGroup = 0;
  let overallTaskIndex = 0;
  //const [taskName, resolutionType] = task.name.split('|');

  return (
    <div className="gantt-task-list-wrapper" style={{ fontFamily, fontSize }}>
      {tasks.map((task, index) => {
                const [taskName, resolutionType] = task.name.split('|');

        const isHovered = hoveredTaskId === task.id;
        const isSelected = selectedTaskId === task.id;
        const isTramitacion = task.name === 'TRAMITACIÓN';
        const taskNumber = getTaskNumber(task, index);
        const isGroupTask = task.type === 'project' && !task.project;
        
        // Calcular el padding basado en el nivel de jerarquía
        let paddingLeft = '5px';
        if (task.project?.includes('-') || task.name === 'TRAMITACIÓN') {
          paddingLeft = '45px';
        } else if (task.type !== 'project') {
          paddingLeft = '25px';
        }
        

        return (
          <div
            key={task.id}
            className="gantt-task-list-row"
            style={{
              height: rowHeight,
              display: 'flex',
              alignItems: 'center',
              marginTop: '0px',
              paddingTop: '10px',
              borderBottom: '1px solid #e0e0e0',
              backgroundColor: getTaskBackgroundColor(task, isHovered, isSelected),
              transition: 'background-color 0.3s ease',
              cursor: 'pointer',
            }}
            onClick={(e) => {
              const clickedExpander = (e.target as HTMLElement).closest('.gantt-task-list-expander');
              if (isTramitacion && clickedExpander) {
                onExpanderClick(task);
              } else {
                setSelectedTask(task.id);
                if (task.type === 'project' && !isTramitacion) {
                  onExpanderClick(task);
                }
              }
            }}
            onMouseEnter={() => setHoveredTaskId(task.id)}
            onMouseLeave={() => setHoveredTaskId(null)}
          >
            <div
              className="gantt-task-list-cell"
              style={{
                width: rowWidth,
                display: 'flex',
                alignItems: 'center',
                paddingLeft,
                paddingRight: '20px',
                position: 'relative',
                overflow: 'visible',
              }}
            >
              {(task.type === 'project' || task.name === 'TRAMITACIÓN') && (
                <div
                  className="gantt-task-list-expander"
                  style={{ 
                    marginRight: '5px', 
                    cursor: 'pointer',
                    flexShrink: 0 // Evita que el expander se encoja
                  }}
                >
                  {task.hideChildren ? <ChevronRight size={16} /> : <ChevronDown size={16} />}
                </div>
              )}
              <div
                className="gantt-task-list-cell"
                style={{
                  width: '40px',
                  textAlign: 'left',
                  fontWeight: 'bold',
                  paddingLeft: '5px',
                  paddingRight: '5px',
                  flexShrink: 0 // Evita que el número se encoja
                }}
              >
                {taskNumber}
              </div>
              <div
  style={{ 
   fontWeight: isGroupTask ? 'bold' : 'normal',
    marginLeft: isGroupTask ? '0' : '10px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    width: '100%',
    overflow: 'visible',
    right: '20px',
    padding: '3px' // Padding general

  }}
>
  <span
    style={{
    overflow: 'visible',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      minWidth: 3,
      flex: '1 1 auto',
      paddingLeft: '5px',
      paddingRight: '25px', // Más espacio para evitar que la etiqueta se acerque demasiado

    }}
    title={task.name}
  >
    <span>{task.name.split('|')[0]}</span>
  </span>
  {resolutionType && (
  <div 
    className={`inline-flex items-center gap-1 ${
      resolutionType === 'APROBADO' ? 'bg-green-100 text-green-800 border border-green-300' :
      resolutionType === 'RECHAZADO' ? 'bg-red-100 text-red-800 border border-red-300' :
      resolutionType === 'DESISTIMIENTO' ? 'bg-orange-100 text-orange-800 border border-orange-300' :
      resolutionType === 'SILENCIO ADMINISTRATIVO POSITIVO' ? 'bg-green-100 text-green-800 border border-green-300' :
      resolutionType === 'SILENCIO ADMINISTRATIVO NEGATIVO' ? 'bg-red-100 text-red-800 border border-red-300' :
      resolutionType === 'NO ADMITIDO' ? 'bg-red-100 text-red-800 border border-red-300' :
      'bg-gray-100 text-gray-800 border border-gray-300'
    }`}
    style={{
      padding: '0px 5px',
      borderRadius: '9999px',
      fontSize: '0.6rem',
      lineHeight: '1rem',
      fontWeight: 500,
      position: 'absolute',
      right: '-37px', // Ajuste fino para mover la etiqueta
      top: '50%',
      transform: 'translateY(-50%)',
    }}
  >
    {/* Agregar el icono antes del texto */}
    {formattedResolutionType(resolutionType)}
    </div>
)}

</div>

            </div>
            <div 
  className="gantt-task-list-cell" 
  style={{ 
    width: '140px', // Ajustar el ancho si es necesario
    flexShrink: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    paddingRight: '10px', // Espaciado interno para la dependencia
    marginLeft: '60px' // Separar la columna de dependencias del nombre
  }}
>
  {formatDependencies(task.dependencies)}
</div>
            <div 
              className="gantt-task-list-cell" 
              style={{ 
                width: '120px',
                flexShrink: 0,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
            >
              {formatDate(task.start)}
            </div>
            <div 
              className="gantt-task-list-cell" 
              style={{ 
                width: '120px',
                flexShrink: 0,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
            >
              {formatDate(task.end)}
            </div>
          </div>
        );
      })}
    </div>
  );
};










interface TaskModalProps {
  isOpen: boolean;
  groups: Group[];
  onClose: () => void;
  task: ExtendedTask;
  onChangeTask: (task: ExtendedTask) => void;
  backgroundColor: string;
  daysDelayed?: number;
  processName: string;
  subprocessName: string;
}


// Update ExtendedTask to ensure proper inheritance


interface ExtendedTask extends Task {
  id: string;
  globalId?: number; // Add this line

  name: string;
  start: Date;
  end: Date;
  progress: number;
  dependencies?: string[];
  description?: string;
  organismo?: string;
  responsible?: string;
  comments?: string;
  isClosed?: boolean;
  semaphoreStatus?: string;
  type: TaskType; // Use TaskType instead of string
  project?: string;
  dependsOn?: { groupId: number; taskId: number }[];

  styles?: {
    progressColor?: string;
    progressSelectedColor?: string;
    backgroundColor?: string;
    backgroundSelectedColor?: string;
  };
    duracion?: number;
    reminder_code?: string;
    reminder_type?: string;
    reminder_value?: string;
    reminder_calculated_date?: string;
    reminder_active?: true,
    reminder_triggered?: false
  
}




interface TaskInput {
  id?: number;
  name: string;
  responsible: string;
  progress: number;
  start: string;
  end: string;
  dependsOn: { groupId: number; taskId: number }[];
  color?: string; // Añadido para el color de la tarea
  subtasks?: SubTask[];
  enabled?: boolean;
  isTramitacion?: boolean;
  type?: TaskType; // Ensure compatibility
}

interface SubTask {
  id?: number;
  name: string;
  responsible: string;
  progress: number;
  start: string;
  end: string;
  isClosed: boolean;
  followUpDate: string;
  semaphoreStatus: SemaphoreStatus; // New property for semaphore status
  st_type: 'INGRESO' | 'OBSERVACIÓN' | 'RESPUESTA' | 'RESOLUCIÓN' | 'OTROS';
  resolutionType?: 'APROBADO' | 'RECHAZADO' | 'DESISTIMIENTO' | 'SA+' | 'SA-' | 'OTRO';
  duration: number;
  comments?: string;
  orden: number;
  organism: string;
  dependsOn: { groupId: number; taskId: number; subtaskId?: number }[];
  enabled: boolean;
  type: TaskType; // Agregado
}





interface Group {
  agrupador: string;
  tasks: TaskInput[];
  subprocess: string; // Añadido para el nombre del subproceso
  color?: string; // Añadido para el color del grupo/subproceso
  name: string;
  expanded: boolean;
} 


interface TasksState {
  name: string;
  groups: Group[];
}

interface Agrupador {
  id: string;
  nombre: string;
  fecha_inicio: string;
  fecha_termino: string;
  progreso: number;
  subproceso: string;
  tareas: ExtendedTask[]; // Añadimos esta línea
  enabled: boolean; // Add this line

}
interface CommentResponse {
  success: boolean;
  comments?: string[];
  message?: string;
}



type ReminderType = 'specific-date' | 'relative-time' | 'progress-based' | 'task-expired' | 'task-closed';

interface ReminderInfo {
  type: ReminderType | null;
  value: string | null;
}


interface ReminderData {
  task_id: number;
  reminder_type: 'specific-date' | 'relative-time' | 'progress-based' | 'task-expired' | 'task-closed' | null;
  reminder_value: string | null;
  reminder_code: string | null;
  reminder_calculated_date: string | null;
  reminder_active: boolean;
  reminder_triggered: boolean;
}

interface TaskUpdateData {
  id: number;
  tarea_id?: number;
  isClosed: boolean;
  progress: number;
  start: string;
  end: string;
  followUpDate: string;
  comments: string;
  semaphoreStatus: SemaphoreStatus;
  delayInDays?: number;
}

const TaskModal: React.FC<TaskModalProps> = ({
  groups,
  isOpen,
  onClose,
  task,
  onChangeTask,
  backgroundColor,
  daysDelayed = 0,
  processName,         // Prop: Nombre del proceso
  subprocessName, 
}) => {
  const [comments, setComments] = useState<string[]>([]); // Estado inicial como array vacío
  const [newComment, setNewComment] = useState('');
  const [showComments, setShowComments] = useState(true); // Set to true by default for better visibility
  const [showReminderPicker, setShowReminderPicker] = useState(false);
  const storedFirstName = sessionStorage.getItem('firstName');
  const storedTitle = sessionStorage.getItem('title');
  const storedLastName = sessionStorage.getItem('lastName');
  const [isLoadingComments, setIsLoadingComments] = useState(false);
  const commentsEndRef = useRef<HTMLDivElement>(null);
  const [activeTab, setActiveTab] = useState<'details' | 'followup'>('details');
  const [editedName, setEditedName] = useState(task.name);
  const storedUserId = sessionStorage.getItem('userId');
  const [localStartDate, setLocalStartDate] = useState<string>(
    task.start ? dateUtils.formatLocalDate(task.start): ''
  );
  const [localEndDate, setLocalEndDate] = useState<string>(
    task.end ? dateUtils.formatLocalDate(task.end): ''
  );
    const [isLoading, setIsLoading] = useState(false);
  const [reminderType, setReminderType] = useState<'date' | 'relative' | 'progress'>('progress');
  const [selectedDate, setSelectedDate] = useState('');
  const [timeValue, setTimeValue] = useState(1);
  const [timeUnit, setTimeUnit] = useState<'days' | 'weeks' | 'months'>('days');
  const [progressValue, setProgressValue] = useState(50);
  const [isLoadingReminder, setIsLoadingReminder] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const currentAgrupador = useMemo(() => {
    if (!task?.id) return null;
  
    const [groupIndex] = task.id.split("-").map(Number);
    return groups[groupIndex] || null; // Ensure index matches agrupadores
  }, [task, groups]);
  

// Verificar si es una subtarea al inicio del componente

   



  
  const lightenColor = (color: string, amount: number): string => {
    return '#' + color.replace(/^#/, '').replace(/../g, color => 
      ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2)
    );
  }; 


   

  const isEndDateValid = useMemo(() => {
    const startDate = dateUtils.parseLocalDate(localStartDate);
    const endDate = dateUtils.parseLocalDate(localEndDate);
    return endDate >= startDate;
  }, [localStartDate, localEndDate]);
  

  
 

const contentVariants = {
  expanded: { 
    height: "auto",
    opacity: 1,
    transition: {
      height: {
        duration: 0.3,
        ease: [0.87, 0, 0.13, 1]
      },
      opacity: {
        duration: 0.25,
        ease: "easeInOut"
      }
    }
  },
  collapsed: { 
    height: 0,
    opacity: 0,
    transition: {
      height: {
        duration: 0.3,
        ease: [0.87, 0, 0.13, 1]
      },
      opacity: {
        duration: 0.25,
        ease: "easeInOut"
      }
    }
  }
};
  
const calculateSemaphoreStatus = (
  start: string | Date,
  end: string | Date,
  taskId: number,
  dependencia: string | number | null,
  isClosed: boolean,
  allTasks?: Task[],
  parentTask?: Task
): SemaphoreStatus => {
  // Si la tarea está cerrada, retornar gris
  if (isClosed) {
    return SemaphoreStatus.Gray;
  }

  const today = dateUtils.getTodayLocal();

  // Convertir fechas de string a Date si es necesario
  const startDate = typeof start === 'string' ? dateUtils.parseLocalDate(start) : start;
  const endDate = typeof end === 'string' ? dateUtils.parseLocalDate(end) : end;

  // Validación de fechas
  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
    return SemaphoreStatus.Gray;
  }

  // Si la fecha de inicio no ha llegado aún
  if (startDate > today) {
    return SemaphoreStatus.Gray;
  }

  // Si la tarea está vencida
  if (today > endDate) {
    return SemaphoreStatus.Red;
  }

  // Calcular porcentaje transcurrido
  const totalTime = endDate.getTime() - startDate.getTime();
  const elapsedTime = today.getTime() - startDate.getTime();
  const progressPercentage = (elapsedTime / totalTime) * 100;

  // Determinar el estado del semáforo según los porcentajes
  if (progressPercentage >= 100) {
    return SemaphoreStatus.Red; // Vencida
  } else if (progressPercentage >= 80) {
    return SemaphoreStatus.Orange; // Muy cerca de vencer
  } else if (progressPercentage >= 50) {
    return SemaphoreStatus.Yellow; // Próxima a vencer
  }
  return SemaphoreStatus.Green; // En tiempo
};

  
   
  
const getSemaphoreStatus = (task: Task) => {
  const today = dateUtils.getTodayLocal();
  
  // If task is completed, return Gray
  if (task.progress === 100) {
    return SemaphoreStatus.Gray;
  }

  const startDate = task.start;
  const endDate = task.end;

  // If task hasn't started yet
  if (startDate > today) {
    return SemaphoreStatus.Gray;
  }

  // If task is overdue
  if (today > endDate) {
    return SemaphoreStatus.Red;
  }

  // Calculate progress percentage based on time
  const totalTime = endDate.getTime() - startDate.getTime();
  const elapsedTime = today.getTime() - startDate.getTime();
  const timeProgressPercentage = (elapsedTime / totalTime) * 100;

  // Return status based on time progress
  if (timeProgressPercentage >= 100) {
    return SemaphoreStatus.Red;
  } else if (timeProgressPercentage >= 75) {
    return SemaphoreStatus.Orange;
  } else if (timeProgressPercentage >= 50) {
    return SemaphoreStatus.Yellow;
  }
  return SemaphoreStatus.Green;
};
  const formatDateDDMMYYYY = (date: string | Date): string => {
    const dateObj = typeof date === 'string' ? new Date(date) : date;
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const year = dateObj.getFullYear();
    return `${day}/${month}/${year}`;
  };
  
  const handleLocalDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'start') {
      setLocalStartDate(value);
      // Ajustar fecha de fin si es necesario
      if (dateUtils.parseLocalDate(value) > dateUtils.parseLocalDate(localEndDate)) {
        setLocalEndDate(value);
      }
    } else if (name === 'end') {
      setLocalEndDate(value); // Actualiza el estado primero
    }
  };

  const handleLocalNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedName(e.target.value);
  };
  const calculateDurationInDays = (startDate: string | Date, endDate: string | Date): number => {
    const start = typeof startDate === 'string' ? new Date(startDate) : startDate;
    const end = typeof endDate === 'string' ? new Date(endDate) : endDate;
    
    if (isNaN(start.getTime()) || isNaN(end.getTime())) {
      throw new Error('Invalid date provided');
    }
  
    const diffTime = end.getTime() - start.getTime();
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };
  
  const renderProgressSelector = () => {
    const totalDays = calculateDurationInDays(task.start, task.end)
    return (
      <div className="space-y-4">
        <div className="bg-white rounded-lg">
          <h4 className="font-medium mb-2">Progreso basado en duración</h4>
          <p className="text-sm text-gray-600 mb-4">
            La tarea tiene una duración de {totalDays} días. 
            El recordatorio se activará cuando se alcance el porcentaje seleccionado del tiempo total.
          </p>
          
          <select
            value={progressValue}
            onChange={e => setProgressValue(parseInt(e.target.value))}
            className="w-full rounded-md border-gray-300"
            disabled={task.isClosed}
          >
            <option value={25}>25% del tiempo (Día {Math.ceil(totalDays * 0.25)})</option>
            <option value={50}>50% del tiempo (Día {Math.ceil(totalDays * 0.5)})</option>
            <option value={75}>75% del tiempo (Día {Math.ceil(totalDays * 0.75)})</option>
            <option value={90}>90% del tiempo (Día {Math.ceil(totalDays * 0.9)})</option>
          </select>
  
          <div className="bg-blue-50 border-l-4 border-blue-400 p-4 mt-4">
            <div className="flex">
              <Info className="h-5 w-5 text-blue-400 mr-2" />
              <p className="text-sm text-blue-700">
                El recordatorio se activará en el día {Math.ceil(totalDays * (progressValue / 100))} 
                de los {totalDays} días totales de la tarea.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  
  // Función para generar el reminder_code
  const generateReminderCode = (type: ReminderType, value: string): string => {
    switch (type) {
      case 'specific-date':
        return `f-${value}`;
      case 'relative-time':
        return `rt-${value}`;
      case 'progress-based':
        return `p-${value}`;
      case 'task-expired':
        return `exp-${value}`;
      case 'task-closed':
        return `closed-${value}`;
      default:
        return '';
    }
  };
  
  // Handler de guardado actualizado
  const handleReminderSave = async () => {
    try {
      setIsLoadingReminder(true);
      const currentUser = sessionStorage.getItem('userId');
      let reminder: {
        type: ReminderType;
        value: string;
        code: string;
      };
  
      switch (reminderType) {
        case 'date':
          reminder = {
            type: 'specific-date',
            value: selectedDate,
            code: generateReminderCode('specific-date', selectedDate)
          };
          break;
        case 'relative':
          const relativeValue = `${timeValue}${timeUnit === 'weeks' ? 'w' : 'd'}`;
          reminder = {
            type: 'relative-time',
            value: relativeValue,
            code: generateReminderCode('relative-time', relativeValue)
          };
          break;
        case 'progress':
          reminder = {
            type: 'progress-based',
            value: progressValue.toString(),
            code: generateReminderCode('progress-based', progressValue.toString())
          };
          break;
        default:
          throw new Error('Tipo de recordatorio no válido');
      }
  
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/update_task_reminder.php`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          task_id: task.id,
          reminder_code: reminder.code,
          reminder_type: reminder.type,
          reminder_value: reminder.value,
          user_id: currentUser
        })
      });
  
      if (!response.ok) throw new Error('Error al guardar recordatorio');
  
      const result = await response.json();
      onChangeTask({
        ...task,
        reminder_code: reminder.code,
        reminder_type: reminder.type,
        reminder_value: reminder.value,
        reminder_calculated_date: result.reminder_calculated_date,
        reminder_active: true,
        reminder_triggered: false
      });
  
      toast.success('Recordatorio configurado exitosamente');
      setShowReminderPicker(false);
      onClose()
    } catch (error) {
      console.error('Error:', error);
      toast.error('Error al configurar recordatorio');
    } finally {
      setIsLoadingReminder(false);
    }
  };
  
  

  
  const renderTrackingContent = () => (
    <div className="p-6 space-y-6">
      <div className="bg-white p-4 rounded-lg border">
        <h4 className="font-medium mb-2">Configuración de Recordatorio</h4>
        
        <div className="flex gap-4 mb-6">
          {[
            { id: 'date', label: 'Fecha Específica', icon: Calendar },
            { id: 'relative', label: 'Tiempo Relativo', icon: Clock },
            { id: 'progress', label: 'Por Progreso', icon: AlertTriangle }
          ].map(({ id, label, icon: Icon }) => (
            <button
              key={id}
              onClick={() => setReminderType(id as typeof reminderType)}
              className={`flex-1 p-4 rounded-lg border-2 transition-all ${
                reminderType === id 
                  ? 'border-teal-500 bg-teal-50 text-teal-700' 
                  : 'border-gray-200 hover:border-teal-200'
              }`}
              disabled={task.isClosed}
            >
              <Icon className="w-6 h-6 mx-auto mb-2" />
              <span className="text-sm font-medium block text-center">{label}</span>
            </button>
          ))}
        </div>

        {reminderType === 'date' && (
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Seleccionar Fecha
            </label>
            <input
              type="date"
              value={selectedDate}
              onChange={e => setSelectedDate(e.target.value)}
              min={dateUtils.getTodayString()}
              className="w-full rounded-md border-gray-300"
              disabled={task.isClosed}
            />
          </div>
        )}

        {reminderType === 'relative' && (
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Repetir Cada
            </label>
            <div className="flex gap-4">
              <input
                type="number"
                min="1"
                value={timeValue}
                onChange={e => setTimeValue(parseInt(e.target.value))}
                className="w-24 rounded-md border-gray-300"
                disabled={task.isClosed}
              />
              <select
                value={timeUnit}
                onChange={e => setTimeUnit(e.target.value as 'days' | 'weeks' | 'months')}
                className="rounded-md border-gray-300"
                disabled={task.isClosed}
              >
                <option value="days">Días</option>
                <option value="weeks">Semanas</option>
                <option value="months">Meses</option>
              </select>
            </div>
          </div>
        )}

        {reminderType === 'progress' && (
          <div className="space-y-4">
            {renderProgressSelector ()}
          </div>
        )}
      </div>

      <div className="flex justify-end">
        <Button
          onClick={handleReminderSave}
          disabled={isLoadingReminder || task.isClosed}
          className="bg-teal-600 text-white hover:bg-teal-700"
        >
          {isLoadingReminder ? (
            <>
              <Loader2 className="w-4 h-4 mr-2 animate-spin" />
              Guardando...
            </>
          ) : (
            'Guardar Recordatorio'
          )}
        </Button>
      </div>
    </div>
  );



  const parseExistingReminder = (code: string) => {
    if (code.startsWith('f')) {
      setReminderType('date');
      setSelectedDate(code.substring(1));
    } else if (code.startsWith('rt')) {
      setReminderType('relative');
      const [value, unit] = code.substring(2).split(':');
      setTimeValue(parseInt(value));
      setTimeUnit(unit as 'days' | 'weeks' | 'months');
    } else if (code.startsWith('p')) {
      setReminderType('progress');
      setProgressValue(parseInt(code.substring(1)));
    }
  };


  useEffect(() => {
    if (commentsEndRef.current) {
      commentsEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [task.comments]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    onChangeTask({ ...task, [name]: value });
  };
  
  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (task.isClosed) return;
  
    const { name, value } = e.target;
    const parsedDate = dateUtils.parseLocalDate(value);
    const today = dateUtils.getTodayLocal();
    
    if (parsedDate < today) {
      toast.error('La fecha no puede ser anterior a hoy.');
      return;
    }
  
    onChangeTask({ 
      ...task, 
      [name]: value 
    });
  };


  
  const commentsContainerRef = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    
    if (commentsContainerRef.current) {
      commentsContainerRef.current.scrollTop = commentsContainerRef.current.scrollHeight;
    }
  }, [task.comments, isOpen]); // Agrega isOpen como dependencia

  
  
  useEffect(() => {
    if (commentsEndRef.current) {
      commentsEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [comments]);
  


  const groupsRef = useRef(groups);

useEffect(() => {
  groupsRef.current = groups;
}, [groups]);


  const loadTaskComments = async (taskName: string, globalId?: number): Promise<string[]> => {
    try {
      // If globalId is provided, use it directly
      if (globalId) {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/get_task_comments.php?taskId=${globalId}`
        );
  
        if (!response.ok) {
          throw new Error("Error al cargar los comentarios de la tarea.");
        }
  
        const data: CommentResponse = await response.json();
        return data.success && data.comments ? data.comments : [];
      }
  
      // Fallback to searching by name if no globalId provided
      const taskData = groups.flatMap(group => group.tasks || []).find(t => t.name === taskName);
      
      if (!taskData) {
        console.error("No se encontró la tarea con el nombre:", taskName);
        throw new Error("Tarea no encontrada.");
      }
  
      const response = await fetch(
       `${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/get_task_comments.php?taskId=${taskData.id}`
      );
  
      if (!response.ok) {
        throw new Error("Error al cargar los comentarios de la tarea.");
      }
      
      const data: CommentResponse = await response.json();
      return data.success && data.comments ? data.comments : [];
    } catch (error) {
      console.error("Error al cargar comentarios:", error);
      throw error;
    }
  };

  const loadComments = useCallback(async () => {
    if (!task.globalId) return;
  
    try {
      setIsLoadingComments(true);
      const response = await fetch(
       `${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/get_task_comments.php?taskId=${task.globalId}`
      );
  
      if (!response.ok) {
        throw new Error('Error al cargar los comentarios');
      }
  
      const data = await response.json();
      console.log('Comentarios cargados:', data.comments);
      
      if (data.success && Array.isArray(data.comments)) {
        setComments(data.comments); // Esto debe actualizar el UI

      }
    } catch (error) {
      console.error('Error loading comments:', error);
      toast.error('Error al cargar los comentarios');
    } finally {
      setIsLoadingComments(false);
    }
  }, [task.globalId]);
  

  useEffect(() => {
    if (comments.length > 0) {
      task.comments = JSON.stringify(comments); // Actualiza task.comments
    }
  }, [comments, task]);

  
// Efecto para cargar comentarios cuando se abre el modal
useEffect(() => {
  if (isOpen) {
    loadComments();
  }
}, [isOpen, loadComments]);

const handleAddComment = () => {
  if (!newComment.trim()) return;

  try {
    const timestamp = new Date().toLocaleString('es-CL', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    });

    const commentWithTimestamp = `[${timestamp}] ${storedFirstName} ${storedLastName}: ${newComment}`;
    
    // Actualizar el estado de comentarios localmente
    setComments((prev) => {
      const updatedComments = [...prev, commentWithTimestamp];
      
      // Actualizar task.comments localmente también
      task.comments = JSON.stringify(updatedComments);
      return updatedComments;
    });

    // Limpiar el campo de nuevo comentario
    setNewComment('');
  } catch (error) {
    console.error('Error al agregar comentario:', error);
    toast.error(`Error al agregar el comentario: ${error}`);
  }
};


  const [localTask, setLocalTask] = useState<Task>(task);




  
  const handleCloseTask = async () => {
    try {
      setIsLoading(true);
      const today = dateUtils.getTodayLocal();
      const updatedTask = {
        ...task,
        id: task.id,
        globalId: task.globalId,
        name: task.name,
        isClosed: true,
        progress: 100,
        start: dateUtils.parseLocalDate(localStartDate),
        end: today,
        comments: task.comments // Preserve comments

      };
  
      if (task.name === 'TRAMITACIÓN' && task.subtasks) {
        // Get the time difference between original and new start date
        const timeDiff = updatedTask.start.getTime() - task.start.getTime();
        
        // Update all subtasks dates by the same time difference
        updatedTask.subtasks = task.subtasks.map(subtask => ({
          ...subtask,
          start: new Date(subtask.start.getTime() + timeDiff),
          end: new Date(subtask.end.getTime() + timeDiff)
        }));
      }
  
      const response = await fetch(
       `${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/update_task.php`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            id: task.globalId,
            name: task.name,
            isClosed: true,
            progress: 100,
            start: localStartDate,
            end: today,
            comments: updatedTask.comments, 
            subtasks: updatedTask.subtasks
          }),
        }
      );
  
      if (!response.ok) throw new Error("Error al actualizar la tarea.");
  
      onChangeTask({...updatedTask});
      onClose();
      toast.success("Tarea actualizada correctamente.");
    } catch (error) {
      console.error('Error al cerrar la tarea:', error);
      toast.error('Error al cerrar la tarea');
    } finally {
      setIsLoading(false);
    }
  };

  
  const handleOpenTask = async () => {
    try {
      if (task.type === 'project') return;
  
      // Conservar la fecha de inicio original y convertirla a Date
      const originalStartDate = task.start;
      
      // Calcular la nueva fecha de término
      const startDate = new Date(originalStartDate);
      const newEndDate = new Date(startDate);
      newEndDate.setDate(startDate.getDate() + (task.duracion ? task.duracion * 7 : 7));
  
      // Crear el objeto de tarea actualizado
      const updatedTask: ExtendedTask = {
        ...task,
        isClosed: false,
        progress: 0,
        start: startDate,
        end: newEndDate,
        // Mantener las propiedades requeridas de ExtendedTask
        type: task.type,
        name: task.name,
        dependencies: task.dependencies,
        description: task.description || '',
        responsible: task.responsible || '',
        comments: task.comments || '',
        semaphoreStatus: calculateSemaphoreStatus(
          startDate,
          newEndDate,
          task.globalId ?? 0,
          Array.isArray(task.dependencies)
            ? task.dependencies.join(',') // Convertir a string si es un arreglo
            : task.dependencies || null, // Usar null si no está definido
          false
        ),
        reminder_type: task.reminder_type,
        reminder_value: task.reminder_value,
        reminder_calculated_date: task.reminder_calculated_date,
        reminder_active: task.reminder_active,
        reminder_triggered: task.reminder_triggered,
        reminder_code: task.reminder_code
      };
  
      // Actualizar la tarea en la base de datos
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/update_task.php`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            id: task.globalId,
            name: task.name,
            isClosed: false,
            duration: task.duracion,
            progress: 0,
            start: originalStartDate,
            end: newEndDate.toISOString().split('T')[0],
            semaphoreStatus: updatedTask.semaphoreStatus
          }),
        }
      );
  
      if (!response.ok) throw new Error("Error al actualizar la tarea.");
  
      // Actualizar el estado local con la tarea actualizada
      onChangeTask(updatedTask);
      onClose();
      toast.success("Tarea actualizada correctamente.");
  
    } catch (error) {
      console.error('Error al abrir la tarea:', error);
      toast.error('Error al abrir la tarea');
    }
  };


  const mapTasks = (tasksGroups: Agrupador[]): any[] => {
    return tasksGroups.flatMap((group, groupIndex) => 
      group.tareas.flatMap((task, taskIndex) => {
        // Mapeo de subtareas
        const subtasks = task.subtasks?.map((subtask, subtaskIndex) => ({
          groupIndex,
          taskIndex,
          subtaskIndex,
          id: `${groupIndex}-${taskIndex + 1}-${subtaskIndex + 1}`,
          name: subtask.name,
          start: subtask.start,
          end: subtask.end,
          progress: subtask.progress,
          dependencies: subtask.dependencies || [],
          type: "subtask",
          parentTask: task.name,
        })) || [];
  
        // Mapeo de tarea principal
        const mainTask = {
          groupIndex,
          taskIndex,
          id: `${groupIndex}-${taskIndex + 1}`,
          name: task.name,
          start: task.start,
          end: task.end,
          progress: task.progress,
          dependencies: task.dependencies || [],
          type: "task",
          subtasks: subtasks.map((sub) => sub.id), // Referencias de IDs de subtareas
        };
  
        return [mainTask, ...subtasks];
      })
    );
  };
  
  const handleSaveChanges = async () => {
    try {
      setIsLoading(true);
      
      if (dateUtils.parseLocalDate(localEndDate) < dateUtils.parseLocalDate(localStartDate)) {
        toast.error('La fecha de fin no puede ser anterior a la fecha de inicio');
        return;
      }
  
      // Create the updated task object with all necessary fields
      const updatedTask = {
        ...task,
        id: task.id,
        globalId: task.globalId,
        name: editedName, // Include the edited name
        start: dateUtils.parseLocalDate(localStartDate),
        end: dateUtils.parseLocalDate(localEndDate),
        comments: task.comments,
        subtasks: task.subtasks
      };
  
      // Send update to database
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/update_task.php`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            id: task.globalId,
            start: localStartDate,
            end: localEndDate,
            name: editedName, // Include name in update payload
            comments: task.comments,
          }),
        }
      );
  
      if (!response.ok) {
        throw new Error("Error al actualizar la tarea.");
      }
  
      // Update subtasks if this is a TRAMITACIÓN task
      if (task.name === 'TRAMITACIÓN' && task.subtasks) {
        for (const subtask of task.subtasks) {
          await updateSubtaskInDB(subtask);
        }
      }
  
      // Update local state and UI
      onChangeTask({...updatedTask});
      onClose();
      toast.success("Tarea actualizada correctamente.");
    } catch (error) {
      console.error("Error al guardar cambios:", error);
      toast.error("Error al actualizar la tarea.");
    } finally {
      setIsLoading(false);
    }
  }

  const updateSubtaskInDB = async (subtaskData: any) => {
    try {
  //    alert(32)
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/subtask_operations.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'update',
          subtask: subtaskData,
          fecha_inicio: subtaskData.start,
          fecha_termino: dateUtils.parseLocalDate(subtaskData.end), // Asegurar que la fecha de término se envíe correctamente
  // Ensure both naming conventions are included
  resolutionType: subtaskData.resolutionType || null,
  resolucion_tipo: subtaskData.resolutionType || null, // Add this line
  tipo_resolucion: subtaskData.resolutionType || null, 
        })
      });
      console.log(`STD: ${JSON.stringify(subtaskData)}`)
      if (!response.ok) {
        throw new Error('Error al actualizar la subtarea');
      }

      return await response.json();
    } catch (error) {
      console.error('Error en updateSubtaskInDB:', error);
      throw error;
    }
  };



  const modalVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { 
      opacity: 1, 
      scale: 1,
      transition: { type: 'spring', stiffness: 500, damping: 25 }
    },
    exit: { 
      opacity: 0, 
      scale: 0.8,
      transition: { duration: 0.2 }
    }
  };
  





  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const isValidDate = (dateStr: string) => {
        if (!dateStr || !/^\d{4}-\d{2}-\d{2}$/.test(dateStr)) {
          return false;
        }
        const [year, month, day] = dateStr.split('-').map(Number);
        const date = new Date(year, month - 1, day);
        return !(
          isNaN(date.getTime()) ||
          date.getFullYear() !== year ||
          date.getMonth() + 1 !== month ||
          date.getDate() !== day
        );
      };
  
      // Validar fecha inicio
      if (!isValidDate(localStartDate)) {
        toast.error("La fecha de inicio no es válida");
        return;
      }
  
      // Validar fecha término
      if (!isValidDate(localEndDate)) {
        toast.error("La fecha de término no es válida");
        return;
      }
  
      // Validar orden de fechas
      if (new Date(localEndDate) < new Date(localStartDate)) {
        toast.error("La fecha de término no puede ser anterior a la fecha de inicio.");
        return;
      }
  
      handleSaveChanges();
    }
  };

  return (
    <AnimatePresence>
   {isOpen && (
    <>
      <div style={{ position: 'fixed', top: 0, right: 0, zIndex: 10002 }}>
      <Toaster
  position="top-right"
  toastOptions={{
    duration: 5000, // Duración más prolongada para mayor visibilidad
    style: {
  
      zIndex: 10002, // Asegúrate de que esté por encima de otros elementos
    },
    success: {
      style: {
        background: '#38A169', // Verde para mensajes de éxito
        color: '#FFFFFF',
      },
    },
    error: {
      style: {
        background: '#E53E3E', // Rojo para errores
        color: '#FFFFFF',
      },
    },
   
  }}
/>

          </div>
      <motion.div
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={contentVariants}
        className="fixed inset-0 flex items-center justify-center  z-[10001] "
      >
          <motion.div className="rounded-xl bg-white rounded-lg w-full max-w-2xl shadow-xl z-[10001]">
          <Dialog isOpen={isOpen} onClose={onClose } width="600px" className=" z-[10001]">
          <motion.div 
            className="bg-white rounded-lg overflow-hidden shadow-xl"
            variants={contentVariants}
          >
     

            <div
              className="bg-teal-600 p-3 text-white"
             
            >
                     <div className="relative">
  {/* Botón de cierre */}
  <button
    onClick={onClose}
    className="absolute top-2 right-2 z-[500] text-white/80 hover:text-white focus:outline-none"
  >
    <X size={20} />
  </button>
</div>
    <h3 className="text-base font-semibold flex items-center space-x-2">
      <MdBusiness className="text-white w-5 h-5" /> 
      <span className="text-sm opacity-80 tracking-wide">
      {`${processName?.toUpperCase()} / ${subprocessName?.toUpperCase()} / ${currentAgrupador?.name}`}
      </span>
    </h3>
    <div className="flex mt-4 mb-3">

    <div className="flex items-center">
      <span className="text-lg font-bold text-white">{task.name}</span>
    </div>
  {/* Estado de la tarea */}
<div className="ml-3 flex items-center">
<div
  className={`px-3 py-1 rounded-full text-sm font-medium ${
    task.progress === 100 && task.isClosed
      ? `${backgroundColor} text-gray-800`
      : task.styles?.progressColor === '#EF4444' 
        ? 'bg-red-100 text-red-700'
        : task.styles?.progressColor === '#FB923C'
        ? 'bg-orange-100 text-orange-700'
        : task.styles?.progressColor === '#FDE047'
        ? 'bg-yellow-100 text-yellow-700'
        : task.styles?.progressColor === '#4ADE80'
        ? 'bg-green-100 text-green-700'
        : 'bg-gray-100 text-gray-700'
  }`}
  style={
    task.progress === 100 && task.isClosed
      ? {
          backgroundImage: `repeating-conic-gradient(
            ${lightenColor(backgroundColor, 80)} 0% 25%, 
            #ffffff 25% 50%
          )`,
          backgroundSize: '10px 10px',
        }
      : undefined
  }
>
  {task.progress === 100 && task.isClosed
    ? 'Cerrada'
    : task.styles?.progressColor === '#EF4444'
    ? `Retrasada por ${daysDelayed} día(s)`
    : task.styles?.progressColor === '#FB923C'
    ? 'Próxima a vencer'
    : task.styles?.progressColor === '#FDE047'
    ? 'A tiempo, pero próxima'
    : task.styles?.progressColor === '#4ADE80'
    ? 'A tiempo'
    : 'No iniciada'}
</div>
</div>

    </div>
    <div className="flex items-center gap-4 text-white/70 text-xs mt-1">
      <span className="flex items-center gap-1">
        <Calendar className="w-4 h-4" />
        {formatDateDDMMYYYY(task.start)}
      </span>
      <span className="flex items-center gap-1">
        <Clock className="w-4 h-4" />
        {formatDateDDMMYYYY(task.end)}
      </span>

    </div>
 
    </div>
  
  </motion.div>

<div className="border-b">
          <nav className="flex">
            <button
              onClick={() => setActiveTab('details')}
              className={`px-4 py-2 ${activeTab === 'details' ? 'border-b-2 border-teal-500' : ''}`}
            >
              Detalles
            </button>
            <button
              onClick={() => setActiveTab('followup')}
              className={`px-4 py-2 ${activeTab === 'followup' ? 'border-b-2 border-teal-500' : ''}`}
            >
              Seguimiento
            </button>
          </nav>
        </div>

        {activeTab === 'details' ? (
  <div className="p-4">
   

    {/* Grid de campos */}
    <div className="grid grid-cols-2 gap-3 mb-4">
      <div>
        <label className="block text-xs font-medium text-gray-700 mb-1">
          Nombre
        </label>
        <input
          type="text"
          value={editedName || ''}
          onChange={handleLocalNameChange}
          className="w-full text-sm rounded border-gray-300 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
          disabled={task.isClosed}
        />
      </div>

      <div></div>

      <div>
        <label className="block text-xs font-medium text-gray-700 mb-1">
          Fecha de Inicio
        </label>
        <input
          type="date"
          name="start"
          value={localStartDate}
          onChange={handleLocalDateChange}
          onKeyDown={handleKeyDown}
          className="w-full text-sm rounded border-gray-300 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
          disabled={task.isClosed && task.progress === 100}
        />
      </div>
      <div>
        <label className="block text-xs font-medium text-gray-700 mb-1">
          Fecha de Término
        </label>
        <input
          type="date"
          name="end"
          value={localEndDate}
          onChange={handleLocalDateChange}
          onKeyDown={handleKeyDown}
          className="w-full text-sm rounded border-gray-300 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
          disabled={task.isClosed && task.progress === 100}
        />
      </div>
    </div>

    {/* Actualizar sección de comentarios */}
    <div className="comments-section">
      <div className="flex items-center justify-between mb-2">
        <h3 className="text-lg font-medium">Comentarios</h3>
        {isLoadingComments && <Loader2 className="animate-spin h-4 w-4" />}
      </div>

      <div
        ref={commentsContainerRef}
        className="max-h-60 overflow-y-auto border rounded-md p-4 bg-gray-50"
      >

{task.comments ? (
  (() => {
    try {
      console.log('Contenido original de task.comments:', task.comments);
      
      // Parsear task.comments
      const parsedComments = JSON.parse(task.comments);
      console.log('Comentarios parseados:', parsedComments);

      // Validar que parsedComments sea un array con datos
      if (Array.isArray(parsedComments) && parsedComments.length > 0) {
        return parsedComments.map((comment, index) => {
          // Dividir y procesar cada comentario
          const [datePart, userAndContent] = comment.split('] ');
          const date = datePart.replace('[', '').trim();
          const [user, ...contentParts] = userAndContent.split(':');
          const content = contentParts.join(':').trim();
          const isCurrentUser = user === `${storedFirstName} ${storedLastName}`;

          return (
            <div
              key={index}
              className={`mb-3 last:mb-0 flex ${
                isCurrentUser ? 'justify-start' : 'justify-end'
              }`}
            >
              <div
                className={`max-w-fit p-3 rounded-lg shadow-md break-words ${
                  isCurrentUser
                    ? 'bg-green-100 text-green-900'
                    : 'bg-blue-100 text-blue-900'
                }`}
                style={{
                  maxWidth: 'calc(100% - 20px)',
                  wordBreak: 'break-word',
                }}
              >
                <div className="flex items-center gap-2 text-xs text-gray-600 mb-1">
                  <span className="font-medium">{user}</span>
                  <span className="text-gray-400">•</span>
                  <span className="text-gray-400">{date}</span>
                </div>
                <p className="text-sm whitespace-pre-wrap">{content}</p>
              </div>
            </div>
          );
        });
      } else {
        return <p className="text-gray-500 italic">No hay comentarios.</p>;
      }
    } catch (error) {
      console.error('Error al procesar comentarios:', error);
      return (
        <p className="text-red-500 italic">Error al cargar comentarios.</p>
      );
    }
  })()
) : (
  <p className="text-gray-500 italic">No hay comentarios disponibles.</p>
)}


      </div>

      <div className="mt-4 flex gap-2">
        <textarea
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          onKeyDown={async (e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              if (newComment.trim() && !task.isClosed && !isLoadingComments) {
                await handleAddComment();
                setNewComment('');
              }
            }
          }}
          placeholder="Escribir un comentario..."
          rows={2}
          className="w-full rounded-md border border-gray-300 px-3 py-2 resize-none overflow-y-auto"
          disabled={task.isClosed}
          maxLength={250}
        />
        <button
          onClick={async () => {
            await handleAddComment();
            setNewComment(''); // Limpiar input después de agregar el comentario
          }}
          disabled={task.isClosed || !newComment.trim() || isLoadingComments}
          className="px-4 py-2 bg-teal-600 text-white rounded-md hover:bg-teal-700 disabled:opacity-50"
        >
          Agregar
        </button>
      </div>
    </div>
     {/* Botones de acción */}
     <div className="flex justify-between mt-4 pt-2 border-t">
              <button
  type="button"
  className={`px-3 py-1.5 rounded text-sm font-medium ${
    task.isClosed
      ? 'bg-green-600 hover:bg-green-700 text-white'
      : task.semaphoreStatus === SemaphoreStatus.Gray || 
        (task.name === 'TRAMITACIÓN' )
      ? 'bg-gray-400 text-white cursor-not-allowed'
      : 'bg-red-600 hover:bg-red-700 text-white'
  }`}
  onClick={task.isClosed ? handleOpenTask : handleCloseTask}
  disabled={
    !task.isClosed && 
    (task.semaphoreStatus === SemaphoreStatus.Gray && !task.isClosed || 
     (task.name === 'TRAMITACIÓN' ))
  }
>
                  {task.isClosed ? "ABRIR TAREA" : "CERRAR TAREA"}
                </button>

                <div className="relative">
                <button
  type="button"
  onClick={() => {
    // Función para validar si una fecha existe
    const isValidDate = (dateStr: string) => {
      if (!dateStr || !/^\d{4}-\d{2}-\d{2}$/.test(dateStr)) {
        return false;
      }
      const [year, month, day] = dateStr.split('-').map(Number);
      const date = new Date(year, month - 1, day);
      return !(
        isNaN(date.getTime()) ||
        date.getFullYear() !== year ||
        date.getMonth() + 1 !== month ||
        date.getDate() !== day
      );
    };

    // Validar fecha inicio
    if (!isValidDate(localStartDate)) {
      toast.error("La fecha de inicio no es válida");
      return;
    }

    // Validar fecha término
    if (!isValidDate(localEndDate)) {
      toast.error("La fecha de término no es válida");
      return;
    }

    // Validar orden de fechas
    if (new Date(localEndDate) < new Date(localStartDate)) {
      toast.error("La fecha de término no puede ser anterior a la fecha de inicio.");
      return;
    }

    handleSaveChanges();
  }}
  className="px-3 py-1.5 bg-blue-600 text-white text-sm font-medium rounded hover:bg-blue-700"
>
  Guardar Cambios
</button>
</div>
                </div>
  </div>
  
) :            
<motion.div
initial="hidden"
animate="visible"
exit="exit"
variants={modalVariants}
className="space-y-4 "
>  { 
renderTrackingContent()
}
      
</motion.div>
}
</Dialog>



               </motion.div>
               
          </motion.div>
          
          </>
      )}
    </AnimatePresence>
  );
};


interface ExtendedSubtask extends SubTask {
  reminder_code?: string;
  reminder_type?: string;
  reminder_value?: string;
  reminder_calculated_date?: string;
  reminder_active?: boolean;
  reminder_triggered?: boolean;
  comments: string;
}



interface NewSubtaskOption {
  st_type: 'INGRESO' | 'OBSERVACIÓN' | 'RESPUESTA' | 'RESOLUCIÓN' | 'OTROS';
  name: string;
  resolutionType: string;
  responsable: string;
  organismo: string;
}


interface User {
  id: number;
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  is_admin: boolean;
  departamento: string;
  is_active: boolean;
}



interface SubtaskModalProps {
  isOpen: boolean;
  onClose: () => void;
  subtask: ExtendedSubtask;
  onChangeTask: (task: ExtendedTask) => void;
  onChangeSubtask: (subtask: ExtendedSubtask) => void;

  backgroundColor: string;
  
  mainTask: ExtendedTask;
  groupProgress?: number;
  processName?: string;
  subprocessName?: string;
  agrupadorName?: string;
  daysDelayed?: number; // Agregar esta prop
    onAddNewSubtask: (option: NewSubtaskOption) => void;  // Agregamos esta prop
    forceTableRerender: () => void;  // Agregamos esta línea

  }


interface ExtendedSubtask extends SubTask {
  styles?: {
    progressColor?: string;
    progressSelectedColor?: string;
    backgroundColor?: string;
    backgroundSelectedColor?: string;
  };
  reminder_code?: string;
  reminder_type?: string;
  reminder_value?: string;
  reminder_calculated_date?: string;
  reminder_active?: boolean;
  reminder_triggered?: boolean;
  comments: string;
  tarea_id?: number;
}

const resolutionTypes = [
  {
    value: 'APROBADO',
    label: 'Aprobado',
    icon: <Check className="h-5 w-5" />,
    color: 'text-green-700',
    bgColor: 'bg-green-100'
  },
  {
    value: 'RECHAZADO',
    label: 'Rechazado',
    icon: <X className="h-5 w-5" />,
    color: 'text-red-700',
    bgColor: 'bg-red-100'
  },
  {
    value: 'DESISTIMIENTO',
    label: 'Desistimiento',
    icon: <AlertTriangle className="h-5 w-5" />,
    color: 'text-orange-700',
    bgColor: 'bg-orange-100'
  },
  {
    value: 'SILENCIO ADMINISTRATIVO POSITIVO',
    label: 'SILENCIO ADMINISTRATIVO POSITIVO',
    icon: <div className="flex items-center">
            <Check className="h-5 w-5 mr-1" />
            <span className="text-sm font-medium">SAP</span>
          </div>,
    color: 'text-green-700',
    bgColor: 'bg-green-50'
  },
  {
    value: 'SILENCIO ADMINISTRATIVO NEGATIVO',
    label: 'SILENCIO ADMINISTRATIVO NEGATIVO',
    icon: <div className="flex items-center">
            <X className="h-5 w-5 mr-1" />
            <span className="text-sm font-medium">SAN</span>
          </div>,
    color: 'text-red-700',
    bgColor: 'bg-red-50'
  },
  {
    value: 'NO ADMITIDO',
    label: 'No Admitido',
    icon: <div className="flex items-center">
            <svg viewBox="0 0 24 24" className="h-5 w-5 mr-1 text-red-700">
              <circle cx="12" cy="12" r="10" fill="none" stroke="currentColor" strokeWidth="2"/>
              <line x1="4" y1="4" x2="20" y2="20" stroke="currentColor" strokeWidth="2"/>
            </svg>
            
          </div>,
    color: 'text-red-700',
    bgColor: 'bg-red-50'
  }
];


const SubtaskModal: React.FC<SubtaskModalProps> = ({
  isOpen,
  onClose,
  subtask,
  onChangeTask,
  onChangeSubtask,
  onAddNewSubtask,
  backgroundColor,
  mainTask,
  groupProgress,
  processName,
  subprocessName,
  agrupadorName,
  daysDelayed
}) => {
  const [activeTab, setActiveTab] = useState<'details' | 'followup'>('details');
  const [editedName, setEditedName] = useState(subtask.name);
  const [comments, setComments] = useState<string[]>([]);
  const [newComment, setNewComment] = useState('');
  const [isLoadingComments, setIsLoadingComments] = useState(false);
  const [localStartDate, setLocalStartDate] = useState(dateUtils.formatLocalDate(new Date(subtask.start)));
  const [localEndDate, setLocalEndDate] = useState(dateUtils.formatLocalDate(new Date(subtask.end)));
  const [isLoading, setIsLoading] = useState(false);
  const commentsEndRef = useRef<HTMLDivElement>(null);
  const commentsContainerRef = useRef<HTMLDivElement>(null);
  const storedFirstName = sessionStorage.getItem('firstName');
  const storedLastName = sessionStorage.getItem('lastName');
  const [showResolutionTypeDialog, setShowResolutionTypeDialog] = useState(false);
  const [showNewSubtaskDialog, setShowNewSubtaskDialog] = useState(false);
  const [selectedResolutionType, setSelectedResolutionType] = useState('');
  const [selectedNewSubtaskType, setSelectedNewSubtaskType] = useState<NewSubtaskOption | null>(null);
  const formatDateDDMMYYYY = (date: string | Date): string => {
    const dateObj = typeof date === 'string' ? new Date(date) : date;
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const year = dateObj.getFullYear();
    return `${day}/${month}/${year}`;
  };



  // Función auxiliar para extraer el número de una observación o respuesta
const extractNumber = (name: string): number => {
  const match = name.match(/\d+$/);
  return match ? parseInt(match[0]) : 0;
};


  const getNextSubtaskOptions = (
    currentType: string,
    parentTask: Task,
    currentSubtask: SubTask
  ): NewSubtaskOption[] => {
    // Helper function to generate correct name
    const generateName = (type: 'OBSERVACIÓN' | 'RESPUESTA' | 'RESOLUCIÓN'): string => {
      if (type === 'RESOLUCIÓN') return 'RESOLUCIÓN';
      
      const existingSubtasks = parentTask.subtasks || [];
      const currentNumber = extractNumber(currentSubtask.name);
      
      if (type === 'OBSERVACIÓN') {
        return `OBSERVACIÓN ${currentNumber + 1}`;
      } else {
        return `RESPUESTA ${currentNumber}`;
      }
    };

    switch (currentType) {
      case 'INGRESO':
        return [
          {
            st_type: 'OBSERVACIÓN',
            name: 'OBSERVACIÓN 1',
            resolutionType: '',
            responsable: '',
            organismo: mainTask.organismo || ''
          },
          {
            st_type: 'RESOLUCIÓN',
            name: generateName('RESOLUCIÓN'),
            resolutionType: '',
            responsable: '',
            organismo: mainTask.organismo || ''
          }
        ];

      case 'OBSERVACIÓN':
        const observationNumber = parseInt(subtask.name.split(' ')[1]) || 1;
        return [
          {
            st_type: 'RESPUESTA',
            name: `RESPUESTA ${observationNumber}`,
            resolutionType: '',
            responsable: mainTask.responsible || '',
            organismo: mainTask.organismo || ''
          },
          {
            st_type: 'RESOLUCIÓN',
            name: 'RESOLUCIÓN',
            resolutionType: '',
            responsable: '',
            organismo: mainTask.organismo || ''
          }
        ];

      case 'RESPUESTA':
        const responseNumber = parseInt(subtask.name.split(' ')[1]) || 1;
        return [
          {
            st_type: 'OBSERVACIÓN',
            name: `OBSERVACIÓN ${responseNumber + 1}`,
            resolutionType: '',
            responsable: '',
            organismo: mainTask.organismo || ''
          },
          {
            st_type: 'RESOLUCIÓN',
            name: 'RESOLUCIÓN',
            resolutionType: '',
            responsable: '',
            organismo: mainTask.organismo || ''
          }
        ];
 
      default:
        return [];
    }
  };


  const nextOptions = getNextSubtaskOptions(subtask.type, mainTask, subtask);

  const lightenColor = (color: string, amount: number = 0.2): string => {
    // Convertir el color hexadecimal a RGB
    let [r, g, b] = color.match(/\w\w/g)?.map((c) => parseInt(c, 16)) || [0, 0, 0];
    
    // Aumentar los valores RGB para aclarar el color
    r = Math.max(0, Math.min(255, r + Math.round(255 * amount)));
    g = Math.max(0, Math.min(255, g + Math.round(255 * amount)));
    b = Math.max(0, Math.min(255, b + Math.round(255 * amount)));
    
    // Convertir de vuelta a hexadecimal
    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
  };
  

  
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      const isValidDate = (dateStr: string) => {
        if (!dateStr || !/^\d{4}-\d{2}-\d{2}$/.test(dateStr)) {
          return false;
        }
        const [year, month, day] = dateStr.split('-').map(Number);
        const date = new Date(year, month - 1, day);
        return !(
          isNaN(date.getTime()) ||
          date.getFullYear() !== year ||
          date.getMonth() + 1 !== month ||
          date.getDate() !== day
        );
      };
  
      // Validar fecha inicio
      if (!isValidDate(localStartDate)) {
        toast.error("La fecha de inicio no es válida");
        return;
      }
  
      // Validar fecha término
      if (!isValidDate(localEndDate)) {
        toast.error("La fecha de término no es válida");
        return;
      }
  
      // Validar orden de fechas
      if (new Date(localEndDate) < new Date(localStartDate)) {
        toast.error("La fecha de término no puede ser anterior a la fecha de inicio.");
        return;
      }
  
      handleSaveChanges();    }
  };


  const modalVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { 
      opacity: 1, 
      scale: 1,
      transition: { type: 'spring', stiffness: 500, damping: 25 }
    },
    exit: { 
      opacity: 0, 
      scale: 0.8,
      transition: { duration: 0.2 }
    }
  };
  

  const isEndDateValid = useMemo(() => {
    const startDate = dateUtils.parseLocalDate(localStartDate);
    const endDate = dateUtils.parseLocalDate(localEndDate);
    return endDate >= startDate;
  }, [localStartDate, localEndDate]);
  

  useEffect(() => {
    if (isOpen && subtask.comments) {
      try {
        const parsedComments = JSON.parse(subtask.comments);
        setComments(parsedComments);  // Asegúrate de cargar los comentarios existentes en el estado
      } catch (error) {
        console.error('Error parsing comments:', error);
        setComments([]);  // Si hay un error al parsear, asegúrate de no dejar comentarios vacíos
      }
    }
  }, [isOpen, subtask.comments]);
  
  
  
  // Efectos para manejo de comentarios
  useEffect(() => {
    if (commentsContainerRef.current) {
      commentsContainerRef.current.scrollTop = commentsContainerRef.current.scrollHeight;
    }
  }, [subtask.comments, isOpen]);

  useEffect(() => {
    if (commentsEndRef.current) {
      commentsEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [comments]);

  // Manejadores de eventos
  const handleLocalDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (subtask.isClosed) return;

    const parsedDate = dateUtils.parseLocalDate(value);
    const today = dateUtils.getTodayLocal();



    if (name === 'start') {
      setLocalStartDate(value);
    } else if (name === 'end') {
      setLocalEndDate(value);
    }
  };



  const handleAddComment = async () => {
    if (!newComment.trim()) return;

    try {
      const timestamp = new Date().toLocaleString('es-CL', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
      });

      const commentWithTimestamp = `[${timestamp}] ${storedFirstName} ${storedLastName}: ${newComment}`;
      let currentComments = [];
      
      try {
        currentComments = subtask.comments ? JSON.parse(subtask.comments) : [];
      } catch (error) {
        console.error('Error parsing comments:', error);
        currentComments = [];
      }
      const updatedComments = [...currentComments, commentWithTimestamp];
      const newCommentsString = JSON.stringify(updatedComments);
 
      const subtaskData = { 
        ...subtask,
        id: subtask.id,
        name: subtask.name,
        tarea_id: mainTask.globalId,
        comments: newCommentsString
      };

      await updateSubtaskInDB(subtaskData);
      
   // Actualizar el estado de comentarios localmente
 setComments((prev) => {
  const updatedComments = [...prev, commentWithTimestamp];
  
  // Actualizar task.comments localmente también
  subtask.comments = JSON.stringify(updatedComments);
  return updatedComments;
});

      setNewComment('');

      setTimeout(() => {
        if (commentsContainerRef.current) {
          commentsContainerRef.current.scrollTo({
            top: commentsContainerRef.current.scrollHeight,
            behavior: 'smooth'
          });
        }
      }, 100);
    } catch (error) {
      console.error('Error al agregar comentario:', error);
      toast.error('Error al agregar el comentario');
    }
  };

// Modifica la función updateSubtaskInDB
const updateSubtaskInDB = async (subtaskData: any): Promise<void> => {
  try {

    // Process dependsOn array before sending
    const dependencies = Array.isArray(subtaskData.dependsOn) && subtaskData.dependsOn.length > 0
      ? subtaskData.dependsOn.map((dep: any) => dep.subtaskId)
      : [];
      console.log("subtaskData:", subtaskData);
     console.trace();
   
      const formattedSubtaskData = {
        action: 'update',
        subtask: {
          id: subtaskData.id,
          tarea_id: subtaskData.tarea_id,
          name: subtaskData.name || subtaskData.nombre || 'Sin Nombre', // Prioridad a 'name' o 'nombre'
          type: subtaskData.type || subtaskData.tipo || subtaskData.st_type || 'Sin Tipo', // Prioridad a 'type', 'tipo', o 'st_type'
          responsible: subtaskData.responsible,
          organism: subtaskData.organism || subtaskData.organismo,
          progress: subtaskData.progress,
          start: dateUtils.parseAndFormatReadableYYYY(subtaskData.start),
          end: dateUtils.parseAndFormatReadableYYYY(subtaskData.end),
          duration: subtaskData.duration,
          dependsOn: JSON.stringify(subtaskData.dependsOn),
          enabled: subtaskData.enabled,
          resolutionType: subtaskData.resolutionType || null,
          resolucion_tipo: subtaskData.resolutionType || null,
          orden: subtaskData.orden,
          comments: subtaskData.comments,
          isClosed: subtaskData.isClosed, 
          followUpDate: subtaskData.followUpDate,
          semaphoreStatus: subtaskData.semaphoreStatus,
        }
      };

      
 //   alert(JSON.stringify(formattedSubtaskData))
    const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/subtask_operations.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formattedSubtaskData)
    });

   
    if (!response.ok) {
      throw new Error('Error updating subtask');
    }

    const result = await response.json();
    if (!result.success) {

      throw new Error(result.message || 'Error updating subtask');
    }

  } catch (error) {
    console.error('Error in updateSubtaskInDB:', error);
    //alert(JSON.stringify(subtaskData))
    throw error;
  }
};
  
  // Función auxiliar para crear subtareas en la BD
const createSubtaskInDB = async (subtaskData: any) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/subtask_operations.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        action: 'create',
        subtask: subtaskData
      }),
    });

    if (!response.ok) {
      throw new Error('Error al crear la subtarea');
    }

    return await response.json();
  } catch (error) {
    console.error('Error en createSubtaskInDB:', error);
    throw error;
  }
};

const handleResolutionTypeSelection = async (resolutionType: string) => {
  try {
    setIsLoading(true);
    const today = dateUtils.getTodayString();
    const todayDate = dateUtils.getTodayLocal();
  
    // 1. Primero actualizar la subtarea actual (la RESOLUCIÓN)
    const updatedSubtaskData = {
      ...subtask,
      tarea_id: mainTask.globalId,
      id: subtask.id,
      name: subtask.name,
      type: subtask.type,
      isClosed: true,
      progress: 100, 
      start: subtask.start, 
      end: today,
      resolutionType: subtask.name === 'RESOLUCIÓN' ? resolutionType : subtask.resolutionType,
      resolucion_tipo: subtask.name === 'RESOLUCIÓN' ? resolutionType : subtask.resolutionType,
      st_type: 'RESOLUCIÓN'  
    };
    //alert(updatedSubtaskData.resolutionType)
    await updateSubtaskInDB(updatedSubtaskData);

    /* 2. Actualizar todas las demás subtareas
    if (mainTask.subtasks) {
      for (const st of mainTask.subtasks) {
        if (st.name !== subtask.name) { // No actualizar la RESOLUCIÓN de nuevo
          const updatedSt = {
            ...st,
            tarea_id: mainTask.globalId,
            id: st.id,
            type: st.type,
            isClosed: true,
            progress: 100,
            start: st.start, // Mantener la fecha de inicio original
            end: today
          };
          await updateSubtaskInDB(updatedSt);
        }
      }
    }*/

    // 3. Preparar las subtareas actualizadas manteniendo las fechas originales
    const updatedSubtasks = mainTask.subtasks ? mainTask.subtasks.map(st => ({
      ...st,
      isClosed: true,
      progress: 100,
      // Mantener la fecha de inicio original y solo actualizar la fecha de fin
      start: st.start,
      end: todayDate,
      type: st.type,
      st_type: st.type,
      semaphoreStatus: SemaphoreStatus.Gray,
      resolutionType: resolutionType,
      resolucion_tipo: resolutionType,
    })) : [];

    // 4. Actualizar la tarea principal TRAMITACIÓN
    const updatedMainTask: ExtendedTask = {
      ...mainTask,
      id: mainTask.id,
      globalId: mainTask.globalId,
      name: mainTask.name,
      isClosed: true,
      progress: 100,
      start: mainTask.start,
      end: todayDate,
      type: mainTask.type,
      subtasks: updatedSubtasks,
      styles: mainTask.styles
    };

    // 5. Enviar actualización de la tarea principal
    const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/update_task.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: mainTask.globalId,
        name: mainTask.name,
        isClosed: true,
        progress: 100,
        start: dateUtils.formatLocalDate(mainTask.start),
        end: today,
        type: mainTask.type
      }),
    });

    if (!response.ok) {
      throw new Error('Error al actualizar TRAMITACIÓN');
    }

   

    // 6. Actualizar el estado en la UI
    onChangeTask(updatedMainTask);
    toast.success('TRAMITACIÓN cerrada exitosamente');

    


    // 7. Cerrar diálogos
    setShowResolutionTypeDialog(false);
    onClose();

  } catch (error) {
    console.error('Error:', error);
    toast.error('Error al cerrar la resolución');
  } finally {
    setIsLoading(false);
  }
};


const handleCloseSubtask = async () => {
  try {
    setIsLoading(true);

    if (
      mainTask.name === 'TRAMITACION' &&
      mainTask.semaphoreStatus === SemaphoreStatus.Gray
    ) {
      toast.error('No se puede cerrar una subtarea cuando TRAMITACIÓN no ha iniciado');
      return;
    }
    if (subtask.st_type === 'RESOLUCIÓN') {
      setSelectedResolutionType('');
      setShowResolutionTypeDialog(true);
    } else {

    const today = dateUtils.getTodayString();
    const todayDate = dateUtils.getTodayLocal();
    //alert(`${today}   ${todayDate}`)
    const updatedSubtaskData: ExtendedSubtask = {
      ...subtask, 
      id: subtask.id, 
      type: subtask.type,
      name: subtask.name,
      st_type: subtask.st_type,
      organism: subtask.organism,
      start: subtask.start,
      end: today,
      tarea_id: mainTask.globalId,
      isClosed: true,
      comments: subtask.comments, 
      followUpDate: subtask.followUpDate,
      semaphoreStatus: subtask.semaphoreStatus,
      progress: 100,
      duration: subtask.duration,
      orden: subtask.orden,
      enabled: true,
    }; 

    await updateSubtaskInDB(updatedSubtaskData);

    // Si es una subtarea de tipo RESOLUCIÓN, cerrar TRAMITACIÓN
   

      setShowNewSubtaskDialog(true);
    }
    
  } catch (error) {
    console.error('Error:', error);
    toast.error('Error al cerrar la subtarea');
  } finally {
    setIsLoading(false);
  }
};


  const handleNewSubtaskSelection = async (option: NewSubtaskOption) => {
    try {
      setIsLoading(true);
      const formattedStartDate = dateUtils.getTodayString();
      const formattedEndDate = dateUtils.addDaysToDate(formattedStartDate, 7);

      const newSubtaskData = {
        tarea_id: mainTask.globalId,
        nombre: option.name,
        tipo: option.st_type,
        responsable: option.responsable,
        organismo: subtask.organism,
        progreso: 0,
        fecha_inicio: formattedStartDate,
        fecha_termino: formattedEndDate,
        duracion: 1,
        isClosed: false,
        dependencias: JSON.stringify([{
          groupId: mainTask.id,
          taskId: mainTask.id,
          subtaskId: subtask.id
        }]),
        enabled: true,
        orden: subtask.orden + 1,
              semaphoreStatus: SemaphoreStatus.Green // Iniciar en estado verde

      };

      const result = await createSubtaskInDB(newSubtaskData);
    //  alert(JSON.stringify(newSubtaskData))
      if (result.success) {
          onAddNewSubtask(option);
     //   onChangeSubtask({ ...subtask, ...newSubtaskData });
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Error al crear nueva subtarea');
    } finally {
      setIsLoading(false);
      setShowNewSubtaskDialog(false);
      onClose();
    }
  };


  
  const handleOpenSubtask = async () => {
    try {
      setIsLoading(true);
      
      const startDate = dateUtils.parseLocalDate(subtask.start);
      const newEndDate = new Date(startDate);
      newEndDate.setDate(startDate.getDate() + (subtask.duration * 7));
      const formattedEndDate = dateUtils.formatLocalDate(newEndDate);

      const updatedSubtaskData = {
        ...subtask,
        tarea_id: mainTask.globalId,
        id: subtask.id,
        isClosed: false,
        progress: 0,
        end: formattedEndDate,
        fecha_termino: formattedEndDate
      };

      await updateSubtaskInDB(updatedSubtaskData);
      onChangeSubtask(updatedSubtaskData);
      
      onChangeTask({...mainTask});
      toast.success('Subtarea abierta exitosamente');
      onClose();
    } catch (error) {
      console.error('Error al abrir subtarea:', error);
      toast.error('Error al abrir la subtarea');
    } finally {
      setIsLoading(false);
    }
  };

  
  
const contentVariants = {
  expanded: { 
    height: "auto",
    opacity: 1,
    transition: {
      height: {
        duration: 0.3,
        ease: [0.87, 0, 0.13, 1]
      },
      opacity: {
        duration: 0.25,
        ease: "easeInOut"
      }
    }
  },
  collapsed: { 
    height: 0,
    opacity: 0,
    transition: {
      height: {
        duration: 0.3,
        ease: [0.87, 0, 0.13, 1]
      },
      opacity: {
        duration: 0.25,
        ease: "easeInOut"
      }
    }
  }
};
  

  const handleSaveChanges = async () => {
    try {
      setIsLoading(true);

      if (dateUtils.parseLocalDate(localEndDate) < dateUtils.parseLocalDate(localStartDate)) {
        toast.error('La fecha de término no puede ser anterior a la fecha de inicio');
        return;
      }

      const updatedSubtask = {
        ...subtask,
        name: editedName,
        tarea_id: mainTask.globalId,
        start: subtask.start,
        end: subtask.end
      };

      await updateSubtaskInDB(updatedSubtask);
      onChangeSubtask(updatedSubtask);
      toast.success('Cambios guardados exitosamente');
      onClose();
    } catch (error) {
      console.error('Error al guardar cambios:', error);
      toast.error('Error al guardar los cambios');
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <AnimatePresence>
    {isOpen && (
     <>
       <div style={{ position: 'fixed', top: 0, right: 0, zIndex: 10002 }}>
       <Toaster
   position="top-right"
   toastOptions={{
     duration: 5000, // Duración más prolongada para mayor visibilidad
     style: {
   
       zIndex: 10002, // Asegúrate de que esté por encima de otros elementos
     },
     success: {
       style: {
         background: '#38A169', // Verde para mensajes de éxito
         color: '#FFFFFF',
       },
     },
     error: {
       style: {
         background: '#E53E3E', // Rojo para errores
         color: '#FFFFFF',
       },
     },
    
   }}
 />
           </div>
       <motion.div
         initial="hidden"
         animate="visible"
         exit="exit"
         variants={contentVariants}
         className="fixed inset-0 flex items-center justify-center  z-[10001] "
       >
           <motion.div className="rounded-xl bg-white rounded-lg w-full max-w-2xl shadow-xl z-[10001]">
           <Dialog isOpen={isOpen} onClose={onClose } width="600px" className=" z-[10001]">
           <motion.div 
             className="bg-white rounded-lg overflow-hidden shadow-xl"
             variants={contentVariants}
           >
      
 
             <div
               className="bg-teal-600 p-3 text-white"
              
             >
                      <div className="relative">
   {/* Botón de cierre */}
   <button
     onClick={onClose}
     className="absolute top-2 right-2 z-[500] text-white/80 hover:text-white focus:outline-none"
   >
     <X size={20} />
   </button>
 </div>
     <h3 className="text-base font-semibold flex items-center space-x-2">
       <MdBusiness className="text-white w-5 h-5" /> 
       <span className="text-sm opacity-80 tracking-wide">
  {`${processName?.toUpperCase()} / ${subprocessName?.toUpperCase()} / ${agrupadorName || ''}`}
</span>
     </h3>
     <div className="flex mt-4 mb-3">
 
     <div className="flex items-center">
       <span className="text-lg font-bold text-white">{subtask.name}</span>
     </div>
   {/* Estado de la tarea */}
 <div className="ml-3 flex items-center">
 <div
   className={`px-3 py-1 rounded-full text-sm font-medium ${
     subtask.progress === 100 && subtask.isClosed
       ? `${backgroundColor} text-gray-800`
       : subtask.styles?.progressColor === '#EF4444' 
         ? 'bg-red-100 text-red-700'
         : subtask.styles?.progressColor === '#FB923C'
         ? 'bg-orange-100 text-orange-700'
         : subtask.styles?.progressColor === '#FDE047'
         ? 'bg-yellow-100 text-yellow-700'
         : subtask.styles?.progressColor === '#4ADE80'
         ? 'bg-green-100 text-green-700'
         : 'bg-gray-100 text-gray-700'
   }`}
   style={
     subtask.progress === 100 && subtask.isClosed
       ? {
           backgroundImage: `repeating-conic-gradient(
             ${lightenColor(backgroundColor, 80)} 0% 25%, 
             #ffffff 25% 50%
           )`,
           backgroundSize: '10px 10px',
         }
       : undefined
   }
 >
   {subtask.progress === 100 && subtask.isClosed
     ? 'Cerrada'
     : subtask.styles?.progressColor === '#EF4444'
     ? `Retrasada por ${daysDelayed} día(s)`
     : subtask.styles?.progressColor === '#FB923C'
     ? 'Próxima a vencer'
     : subtask.styles?.progressColor === '#FDE047'
     ? 'A tiempo, pero próxima'
     : subtask.styles?.progressColor === '#4ADE80'
     ? 'A tiempo'
     : 'No iniciada'}
 </div>
 </div>
 
     </div>
     <div className="flex items-center gap-4 text-white/70 text-xs mt-1">
       <span className="flex items-center gap-1">
         <Calendar className="w-4 h-4" />
         {formatDateDDMMYYYY(subtask.start)}
       </span>
       <span className="flex items-center gap-1">
         <Clock className="w-4 h-4" />
         {formatDateDDMMYYYY(subtask.end)}
       </span>
 
     </div>
  
     </div>
   
   </motion.div>
 
 <div className="border-b">
           <nav className="flex">
             <button
               onClick={() => setActiveTab('details')}
               className={`px-4 py-2 ${activeTab === 'details' ? 'border-b-2 border-teal-500' : ''}`}
             >
               Detalles
             </button>
             <button
               onClick={() => setActiveTab('followup')}
               className={`px-4 py-2 ${activeTab === 'followup' ? 'border-b-2 border-teal-500' : ''}`}
             >
               Seguimiento
             </button>
           </nav>
         </div>
 
         {activeTab === 'details' ? (
   <div className="p-4">
    
 
     {/* Grid de campos */}
     <div className="grid grid-cols-2 gap-3 mb-4">
       <div>
         <label className="block text-xs font-medium text-gray-700 mb-1">
           Nombre
         </label>
         <input
           type="text"
           value={editedName || ''}
           className="w-full text-sm rounded border-gray-300 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
           disabled={subtask.isClosed}
         />
       </div>
 
       <div></div>
 
       <div>
         <label className="block text-xs font-medium text-gray-700 mb-1">
           Fecha de Inicio
         </label>
         <input
           type="date"
           name="start"
           value={localStartDate}
           onChange={handleLocalDateChange}
           onKeyDown={handleKeyDown}
           className="w-full text-sm rounded border-gray-300 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
           disabled={subtask.isClosed && subtask.progress === 100}
         />
       </div>
       <div>
         <label className="block text-xs font-medium text-gray-700 mb-1">
           Fecha de Término
         </label>
         <input
           type="date"
           name="end"
           value={localEndDate}
           onChange={handleLocalDateChange}
           onKeyDown={handleKeyDown}
           className="w-full text-sm rounded border-gray-300 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
           disabled={subtask.isClosed && subtask.progress === 100}
         />
       </div>
     </div>
 
     {/* Actualizar sección de comentarios */}
     <div className="comments-section">
       <div className="flex items-center justify-between mb-2">
         <h3 className="text-lg font-medium">Comentarios</h3>
         {isLoadingComments && <Loader2 className="animate-spin h-4 w-4" />}
       </div>
 
       <div
         ref={commentsContainerRef}
         className="max-h-60 overflow-y-auto border rounded-md p-4 bg-gray-50"
       >
 
 {subtask.comments ? (
   (() => {
     try {
       console.log('Contenido original de subtask.comments:', subtask.comments);
       
       // Parsear subtask.comments
       const parsedComments = JSON.parse(subtask.comments);
       console.log('Comentarios parseados:', parsedComments);
 
       // Validar que parsedComments sea un array con datos
       if (Array.isArray(parsedComments) && parsedComments.length > 0) {
         return parsedComments.map((comment, index) => {
           // Dividir y procesar cada comentario
           const [datePart, userAndContent] = comment.split('] ');
           const date = datePart.replace('[', '').trim();
           const [user, ...contentParts] = userAndContent.split(':');
           const content = contentParts.join(':').trim();
           const isCurrentUser = user === `${storedFirstName} ${storedLastName}`;
 
           return (
             <div
               key={index}
               className={`mb-3 last:mb-0 flex ${
                 isCurrentUser ? 'justify-start' : 'justify-end'
               }`}
             >
               <div
                 className={`max-w-fit p-3 rounded-lg shadow-md break-words ${
                   isCurrentUser
                     ? 'bg-green-100 text-green-900'
                     : 'bg-blue-100 text-blue-900'
                 }`}
                 style={{
                   maxWidth: 'calc(100% - 20px)',
                   wordBreak: 'break-word',
                 }}
               >
                 <div className="flex items-center gap-2 text-xs text-gray-600 mb-1">
                   <span className="font-medium">{user}</span>
                   <span className="text-gray-400">•</span>
                   <span className="text-gray-400">{date}</span>
                 </div>
                 <p className="text-sm whitespace-pre-wrap">{content}</p>
               </div>
             </div>
           );
         });
       } else {
         return <p className="text-gray-500 italic">No hay comentarios.</p>;
       }
     } catch (error) {
       console.error('Error al procesar comentarios:', error);
       return (
         <p className="text-red-500 italic">Error al cargar comentarios.</p>
       );
     }
   })()
 ) : (
   <p className="text-gray-500 italic">No hay comentarios disponibles.</p>
 )}
 
 
       </div>
 
       <div className="mt-4 flex gap-2">
         <textarea
           value={newComment}
           onChange={(e) => setNewComment(e.target.value)}
           onKeyDown={async (e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              if (newComment.trim() && !subtask.isClosed && !isLoadingComments) {
                await handleAddComment();
                setNewComment('');
              }
            }
          }}
           placeholder="Escribir un comentario..."
           rows={2}
           className="w-full rounded-md border border-gray-300 px-3 py-2 resize-none overflow-y-auto"
           disabled={subtask.isClosed}
           maxLength={250}
         />
         <button
           onClick={async () => {
             await handleAddComment();
             setNewComment(''); // Limpiar input después de agregar el comentario
           }}
           disabled={subtask.isClosed || !newComment.trim() || isLoadingComments}
           className="px-4 py-2 bg-teal-600 text-white rounded-md hover:bg-teal-700 disabled:opacity-50"
         >
           Agregar
         </button>
       </div>
     </div>
      {/* Botones de acción */}
      <div className="flex justify-between mt-4 pt-2 border-t">
    <button
 type="button"
 className={`px-3 py-1.5 rounded text-sm font-medium ${
   subtask.progress === 100
     ? 'bg-green-600 hover:bg-green-700 text-white'
     : subtask.semaphoreStatus === SemaphoreStatus.Gray 
       ? 'bg-gray-400 text-white cursor-not-allowed' 
       : 'bg-red-600 hover:bg-red-700 text-white'
 }`}
 onClick={subtask.progress === 100 ? handleOpenSubtask : handleCloseSubtask}
 disabled={subtask.semaphoreStatus === SemaphoreStatus.Gray && subtask.progress === 0}
>
 {subtask.progress === 100 ? "ABRIR SUBTAREA" : "CERRAR SUBTAREA"}
</button>
                 <div className="relative">
                 <button
  type="button"
  onClick={() => {
    // Función para validar si una fecha existe
    const isValidDate = (dateStr: string) => {
      if (!dateStr || !/^\d{4}-\d{2}-\d{2}$/.test(dateStr)) {
        return false;
      }
      const [year, month, day] = dateStr.split('-').map(Number);
      const date = new Date(year, month - 1, day);
      return !(
        isNaN(date.getTime()) ||
        date.getFullYear() !== year ||
        date.getMonth() + 1 !== month ||
        date.getDate() !== day
      );
    };

    // Validar fecha inicio
    if (!isValidDate(localStartDate)) {
      toast.error("La fecha de inicio no es válida");
      return;
    }

    // Validar fecha término
    if (!isValidDate(localEndDate)) {
      toast.error("La fecha de término no es válida");
      return;
    }

    // Validar orden de fechas
    if (new Date(localEndDate) < new Date(localStartDate)) {
      toast.error("La fecha de término no puede ser anterior a la fecha de inicio.");
      return;
    }

    handleSaveChanges();
  }}
  className="px-3 py-1.5 bg-blue-600 text-white text-sm font-medium rounded hover:bg-blue-700"
>
  Guardar Cambios
</button>
 </div>
                 </div>
   </div>
   
 ) :            
 <motion.div
 initial="hidden"
 animate="visible"
 exit="exit"
 variants={modalVariants}
 className="space-y-4 "
 >  { 
 //renderTrackingContent()
 }
       
 </motion.div>
 }
 </Dialog>
 
 
 
                </motion.div>
                
           </motion.div>
           
           </>
       )}
       {showResolutionTypeDialog && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[10002]">
    <motion.div
      className="bg-white rounded-lg p-6 w-full max-w-md relative"
      variants={modalVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <button
        onClick={() => setShowResolutionTypeDialog(false)}
        className="absolute top-2 right-2 text-gray-300 hover:text-gray-400 z-10"
      >
        <X size={24} />
      </button>

      <h3 className="text-lg font-semibold text-gray-900 mb-4">
        Seleccionar tipo de resolución
      </h3>
      <div className="space-y-2">
        {resolutionTypes.map((type) => (
          <button
            key={type.value}
            onClick={() => handleResolutionTypeSelection(type.value)}
            className={`w-full flex items-center p-3 rounded-lg transition-all duration-200 ${type.bgColor} hover:opacity-90`}
          > 
            <div className={`mr-3 ${type.color}`}>{type.icon}</div>
            <span className={`font-medium ${type.color}`}>{type.label}</span>
          </button>
        ))}
      </div>
    </motion.div>
  </div>
)}

         {/* Modal para seleccionar nueva subtarea */}
       <AnimatePresence>
        {showNewSubtaskDialog && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[10002]">
            <motion.div
              className="bg-white rounded-lg p-6 w-full max-w-md"
              variants={modalVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              <h3 className="text-lg font-medium mb-4">Seleccionar siguiente subtarea</h3>
              <div className="space-y-4">
              {nextOptions.map((option) => (

                <button
                  key={option.st_type}
                  className="w-full px-4 py-2 bg-teal-600 text-white rounded hover:bg-teal-700"
                 onClick={() => handleNewSubtaskSelection(option)}
                >
                    {option.name}
                </button>
                            ))}

                <button
                  className="w-full px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700"
                  onClick={() => setShowNewSubtaskDialog(false)}
                >
                  Cancelar
                </button>
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
     </AnimatePresence>
  );
};




const ensureMinimumDuration = (start: Date, end: Date): Date => {
  // Si las fechas son iguales o la duración es menor a un día
  const duration = end.getTime() - start.getTime();
  if (duration <= 0) {
    // Crear una nueva fecha de fin que sea el mismo día pero algunas horas después
    const newEnd = new Date(start);
    newEnd.setHours(start.getHours() + 20); // Añadir 8 horas para que sea visible
    return newEnd;
  }
  return end;
};
interface GanttTask extends Task {
  globalId: number;
  comments?: string;
  description?: string;
  responsible?: string;
  isClosed?: boolean;
  semaphoreStatus?: string;
}

// En el archivo TaskModal.tsx

type TaskType = 'task' | 'project' | 'milestone';


interface TaskModalProps {
  isOpen: boolean;
  onClose: () => void;
  task: ExtendedTask;
  onChangeTask: (task: ExtendedTask) => void;
  backgroundColor: string;
  daysDelayed?: number;
  processName: string;
  subprocessName: string;
  agrupadorName?: string;
}
interface ExtendedTaskData {
  comments?: string;
  globalId: number;
  description?: string;
  responsible?: string;
  isClosed?: boolean;
  semaphoreStatus?: string;
}


interface Subproceso {
  id?: number;
  subproceso: string;
  color: string;
}


function isSubtask(task: ExtendedTask) {
  const [groupId, taskId, subtaskId] = task.id.split('-').map(Number);
  return subtaskId !== undefined && !isNaN(subtaskId);
}



interface GanttChartViewProps {
  tasks: TasksState;
  colors?: { [key: string]: string };  // Nuevo prop para colores personalizados
  onUpdateTask?: (updateEvent: UpdateTaskEvent) => void;
  processName: string;
  subprocessName: string;

}



  const GanttChartView: React.FC<GanttChartViewProps> = ({  onUpdateTask, tasks, colors = {}, processName, subprocessName }) => {
  const [zoom, setZoom] = useState(100);
    const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.Week);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
    const [isGrouped, setIsGrouped] = useState(true);
    const [isProcessLoaded, setIsProcessLoaded] = useState(false);
    const [activeSubprocess, setActiveSubprocess] = useState<number>(0);
    const [allAgrupadores, setAllAgrupadores] = useState<Agrupador[]>([]);
    const [currentAgrupadores, setCurrentAgrupadores] = useState<Agrupador[]>([]);
    const [filteredSubprocesos, setFilteredSubprocesos] = useState<Subproceso[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const previousProcessRef = useRef<string | null>(null);
    const [draggedColumnId, setDraggedColumnId] = useState<string | null>(null);
    const commentsContainerRef = useRef<HTMLDivElement>(null);
    const [isLoadingComments, setIsLoadingComments] = useState(false);
    const [renderKey, setRenderKey] = useState(0); // Estado para forzar el renderizado
const [loading, setLoading] = useState<boolean>(true);
    const [selectedNombreProyecto, setSelectedNombreProyecto] = useState('');

console.log(`${JSON.stringify(tasks)}`)
    
      // Inicializa expandedGroups con todos los grupos expandidos desde el inicio
  const initialExpandedGroups = useMemo(() => {
    return new Set(tasks.groups.map((_, index) => `group-${index}`));
  }, [tasks.groups]);

  const initialExpandedSubgroups = useMemo(() => {
    const subgroups = new Set<string>();
    tasks.groups.forEach((group, groupIndex) => {
      group.tasks.forEach((task, taskIndex) => {
        if (task.name === 'TRAMITACIÓN') {
          subgroups.add(`${groupIndex}-${taskIndex + 1}`);
        }
      });
    });
    return subgroups;
  }, [tasks]);

  const [expandedGroups, setExpandedGroups] = useState<Set<string>>(initialExpandedGroups);
  const [expandedSubgroups, setExpandedSubgroups] = useState<Set<string>>(initialExpandedSubgroups);


  const [areAllGroupsExpanded, setAreAllGroupsExpanded] = useState(true);

    const ganttRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const [selectedTaskId, setSelectedTaskId] = useState<string>('');
    const [selectedTask, setSelectedTask] = useState<ExtendedTask | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [selectedSubtask, setSelectedSubtask] = useState<ExtendedSubtask | null>(null);
    const [isSubtaskModalOpen, setIsSubtaskModalOpen] = useState(false);
    
    const forceGanttRerender = useCallback(() => {
      setRenderKey((prevKey) => prevKey + 1); // Cambiar clave para forzar renderizado
    }, []);
    

    const handleTaskChange = useCallback(async (updatedTask: ExtendedTask) => {
      if (onUpdateTask) {
        const updateEvent: UpdateTaskEvent = {
          taskId: updatedTask.id,
          updates: {
            name: updatedTask.name,
            start: updatedTask.start,
            end: updatedTask.end,
            progress: updatedTask.progress,
            isClosed: updatedTask.isClosed,
            comments: updatedTask.comments,
            reminder_code: updatedTask.reminder_code,
            reminder_type: updatedTask.reminder_type,
            reminder_value: updatedTask.reminder_value
          }
        };
  
        try {
          // Llamar a onUpdateTask y esperar su resolución
          await onUpdateTask(updateEvent);
          forceGanttRerender();
          // Obtener el código del proceso actual de la URL
          const urlParams = new URLSearchParams(window.location.search);
          const processId = urlParams.get('processId');
          
          if (processId) {
            // Usar el mismo endpoint que GanttGeneral
            const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/proceso/get_process_data.php?codigo=${processId}`);
  
            if (!response.ok) throw new Error('Error al recargar datos');
            
            const result = await response.json();
            if (result) {
              // Incrementar la key para forzar re-render completo
              setRenderKey(prev => prev + 1);
            }
          }
  
          // Cerrar modal y limpiar selección
          setSelectedTask(null);
          setIsModalOpen(false);
          
        } catch (error) {
          console.error('Error updating task:', error);
          toast.error('Error al actualizar la tarea');
        }
      }
    }, [onUpdateTask]);
  

    const handleSubtaskSelection = async (currentSubtask: ExtendedSubtask, option: NewSubtaskOption) => {
      if (!currentSubtask || !selectedTask?.subtasks) return;
    
      try {
        // Actualizar la subtarea actual a cerrada
        const updatedCurrentSubtask: ExtendedSubtask = {
          ...currentSubtask,
          id: currentSubtask.id ?? 0,
          isClosed: true,
          end: dateUtils.getTodayString(),
          progress: 100,
          semaphoreStatus: SemaphoreStatus.Gray
        };
    
        // Crear nueva subtarea
        const formattedStartDate = dateUtils.getTodayString();
        const formattedEndDate = dateUtils.addDaysToDate(formattedStartDate, 7);
    
        // Encontrar el máximo orden entre las subtareas existentes
    
        const newSubtask: ExtendedSubtask = {
          name: option.name,
          st_type: option.st_type,
          responsible: '',
          progress: 0,
          start: formattedStartDate,
          end: formattedEndDate,
          duration: 1,
          organism: currentSubtask.organism,
          enabled: true,
          orden: 0,
          comments: '',
          followUpDate: '',
          semaphoreStatus: SemaphoreStatus.Green,
          isClosed: false,
          type: 'task',
          dependsOn: [{ 
            groupId: Number(selectedTask.id.split('-')[0]),
            taskId: Number(selectedTask.id.split('-')[1]),
            subtaskId: Number(currentSubtask.id)
          }],
          resolutionType: option.resolutionType as typeof currentSubtask.resolutionType
        };
    
        // Actualizar array de subtareas
        const updatedSubtasks = [...selectedTask.subtasks];
       
       
    
        // Ordenar subtareas por orden
    
        // Actualizar fecha de fin de la tarea principal
        const maxEndDate = new Date(
          Math.max(
            ...updatedSubtasks.map(st => new Date(st.end).getTime())
          )
        );
        
        
     
        // Crear tarea actualizada
        const updatedTask: ExtendedTask = {
          ...selectedTask,
          end: maxEndDate,
          subtasks: updatedSubtasks,
          progress: updatedSubtasks.reduce((sum, st) => sum + st.progress, 0) / updatedSubtasks.length
        };
        
        // Usar handleTaskChange para actualizar
        await handleTaskChange(updatedTask);
    
      } catch (error) {
        console.error('Error in handleSubtaskSelection:', error);
        toast.error('Error al procesar la selección de subtarea');
      }
    };
    const getTaskColor = (task: TaskInput, allTasks: TaskInput[], groupColor: string): string => {
      const today = dateUtils.getTodayLocal();
      today.setHours(0, 0, 0, 0);
    
      // Colores constantes
      const COLORS = {
        COMPLETED: groupColor,
        NOT_STARTED: '#AEB8C2',
        RED: '#EF4444',
        ORANGE: '#FB923C',
        YELLOW: '#FDE047',
        GREEN: '#4ADE80'
      };
    
      // Función para obtener color específico con lógica híbrida
      const getSpecificColor = (startDate: Date, endDate: Date, progress: number): string => {
        // Si la tarea está completada
        if (progress === 100) {
          return COLORS.COMPLETED;
        }
    
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(0, 0, 0, 0);
    
        // Si la tarea no ha comenzado
        if (startDate > today) {
          return COLORS.NOT_STARTED;
        }
    
        // Si la tarea está vencida
        if (today >= endDate) {
          return COLORS.RED;
        }
    
        // Calcular duración total y días restantes
        const totalDuration = Math.max(1, Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)));
        const daysRemaining = Math.ceil((endDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
        const percentageCompleted = ((totalDuration - daysRemaining) / totalDuration) * 100;
    
        // Lógica híbrida
        if (totalDuration <= 5) {
          // Para tareas cortas (5 días o menos)
          if (daysRemaining === 0) {
            return COLORS.RED;      // Mismo día
          } else if (daysRemaining === 1) {
            return COLORS.ORANGE;   // 1 día restante
          } else if (daysRemaining <= 3) {
            return COLORS.YELLOW;   // 2-3 días restantes
          }
          return COLORS.GREEN;      // Más de 3 días
        } else {
          // Para tareas largas (más de 5 días)
          if (percentageCompleted >= 90) {
            return COLORS.RED;      // ≥90% completado
          } else if (percentageCompleted >= 75) {
            return COLORS.ORANGE;   // ≥75% completado
          } else if (percentageCompleted >= 50) {
            return COLORS.YELLOW;   // ≥50% completado
          }
          return COLORS.GREEN;      // <50% completado
        }
      };
    
      // Parsear fechas
      const startDate = dateUtils.parseLocalDate(task.start);
      const endDate = dateUtils.parseLocalDate(task.end);
    
      // Verificar dependencias
      const allPredecessorsCompleted = task.dependsOn.every(dep => {
        const predecessorTask = allTasks.find(t => t.id === dep.taskId);
        return predecessorTask && predecessorTask.progress === 100;
      });
    
      if (!allPredecessorsCompleted && today < startDate) {
        return COLORS.NOT_STARTED;
      }
    
      // Si la tarea tiene subtareas
      if (task.subtasks && task.subtasks.length > 0) {
        const subtaskColors = task.subtasks.map(subtask => {
          const subtaskStart = dateUtils.parseLocalDate(subtask.start);
          const subtaskEnd = dateUtils.parseLocalDate(subtask.end);
          return getSpecificColor(subtaskStart, subtaskEnd, subtask.progress);
        });
    
        // Prioridad de colores en subtareas
        if (subtaskColors.includes(COLORS.RED)) return COLORS.RED;
        if (subtaskColors.includes(COLORS.ORANGE)) return COLORS.ORANGE;
        if (subtaskColors.includes(COLORS.YELLOW)) return COLORS.YELLOW;
        if (task.subtasks.every(st => st.progress === 100)) return COLORS.COMPLETED;
        return COLORS.GREEN;
      }
    
      // Para tareas sin subtareas
      return getSpecificColor(startDate, endDate, task.progress);
    };

    const getTaskProgress = (groupIndex: number, taskPosition: number): number | null => {
      const group = tasks.groups[groupIndex];  // Obtener el grupo basado en el índice
    
      if (!group) {
        console.error(`No se encontró el grupo con índice ${groupIndex}`);
        return null; // Retorna null si no se encuentra el grupo
      }
    
      // Verificar que la posición sea válida dentro del grupo
      if (taskPosition < 1 || taskPosition > group.tasks.length) {
        console.error(`La posición de la tarea ${taskPosition} está fuera de rango en el grupo ${groupIndex}`);
        return null; // Retorna null si la posición está fuera de rango
      }
    
      const task = group.tasks[taskPosition - 1]; // Las posiciones empiezan en 1, así que restamos 1 para obtener el índice correcto
    
      return task.progress; // Retornar el progreso de la tarea
    };
    

    

    const getDefaultColor = (index: number): string => {
      const colors = ['#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#DDA0DD', '#F7DC6F', '#BB8FCE', '#5DADE2', '#45B39D', '#EC7063'];
      return colors[index % colors.length];
    };

    
    // Función para convertir fecha de string a Date en la zona horaria local
  // Update the date parsing function to handle invalid dates
 // Update the date parsing function to handle invalid dates
 const parseLocalDate = (dateString: string): Date => {
  if (!dateString) {
    // Return a default date if dateString is undefined or empty
    return new Date();
  }

  const [year, month, day] = dateString.split('-').map(Number);
  
  // Validate date components
  if (!year || !month || !day || isNaN(year) || isNaN(month) || isNaN(day)) {
    console.warn(`Invalid date string: ${dateString}, using current date`);
    return new Date();
  }

  const localDate = new Date(year, month - 1, day);
  
  // Check if date is valid
  if (isNaN(localDate.getTime())) {
    console.warn(`Invalid date created from: ${dateString}, using current date`);
    return new Date();
  }

  return localDate;
};
  


    const darkenColor = (color: string, amount: number = 0.2): string => {
      // Convertir el color hexadecimal a RGB
      let [r, g, b] = color.match(/\w\w/g)?.map((c) => parseInt(c, 16)) || [0, 0, 0];
      
      // Oscurecer los valores RGB
      r = Math.max(0, Math.min(255, r - Math.round(255 * amount)));
      g = Math.max(0, Math.min(255, g - Math.round(255 * amount)));
      b = Math.max(0, Math.min(255, b - Math.round(255 * amount)));
      
      // Convertir de vuelta a hexadecimal
      return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
    };

    
      const chartData: Task[] = useMemo(() => {
        const today = dateUtils.getTodayLocal();
     

      
        const formatTaskNameWithResolution = (name: string, resolutionType: string | null): string => {
          if (name === 'RESOLUCIÓN' && resolutionType) {
            return `${name}|${resolutionType}`;
          }
          return name;
        };
        
        const parseTaskNameAndResolution = (taskName: string): { name: string; resolutionType: string | null } => {
          const [name, resolution] = taskName.split('|');
          return {
            name,
            resolutionType: resolution || null
          };
        };


const processSubtask = (
  subtask: SubTask,
  taskId: string,
  subtaskIndex: number,
  groupColor: string,
  allSubtasks: SubTask[]
): Task => {
  const start = dateUtils.parseLocalDate(subtask.start);
  const end = dateUtils.parseLocalDate(subtask.end);
  
  const adjustedStart = new Date(start);
  const adjustedEnd = new Date(end);
  adjustedStart.setHours(0, 0, 0, 0);
  adjustedEnd.setHours(23, 59, 0, 0);
  
  
  const formattedName = formatTaskNameWithResolution(
    subtask.name, 
    subtask.resolutionType ?? null // Usa null si resolutionType es undefined
  );
  
  // Get the color for the subtask
  const subtaskColor = getTaskColor({
    start: subtask.start,
    end: subtask.end,
    progress: subtask.progress,
    dependsOn: subtask.dependsOn || [], 
    name: subtask.name
  } as TaskInput, allSubtasks as TaskInput[], groupColor);
  
  const minDuration = 10 * 60 * 60 * 1000;
  if (adjustedEnd.getTime() - adjustedStart.getTime() < minDuration) {
    adjustedEnd.setTime(adjustedStart.getTime() + minDuration);
  }

  return {
    id: `${taskId}-${subtaskIndex + 1}`,
    type: 'task',
    name: formattedName, // Usar el nombre formateado
    start: adjustedStart,
    end: adjustedEnd,
    progress: subtask.progress,
    project: taskId,
    dependencies: subtaskIndex > 0 ? [`${taskId}-${subtaskIndex}`] : [],
    isDisabled: !subtask.enabled,
    styles: {
      progressColor: subtaskColor,
      progressSelectedColor: subtaskColor,
      backgroundColor: `${subtaskColor}66`,
      backgroundSelectedColor: `${subtaskColor}aa`,
    },
    

  };
};

      
      if (isGrouped) {
        return tasks.groups.flatMap((group, groupIndex): Task[] => {
          const groupColor = group.color || getDefaultColor(groupIndex);
    
          const groupTasks = group.tasks.map((task, taskIndex): Task[] => {
            // Ensure valid dates for task
            let start = parseLocalDate(task.start);
            let end = parseLocalDate(task.end);
            
            start.setHours(0, 0, 0, 0);

                end.setHours(23, 59, 0, 0);
           
            
            // Ensure minimum duration
            const duration = end.getTime() - start.getTime();
            if (duration < 0) {
              end.setTime(start.getTime() + (24 * 60 * 60 * 1000)); // Set minimum 1 day duration
            }


              const isCompletedAndFinished = task.progress === 100 && today > end;
              let taskColor = getTaskColor(task, group.tasks, groupColor);
      
              task.dependsOn.forEach(dep => {
                const progresoDependencia = getTaskProgress(groupIndex, dep.taskId);
                if (progresoDependencia !== null && progresoDependencia !== undefined && progresoDependencia < 100) {
                  taskColor = '#AEB8C2';
                }
              });
      
              const mainTask: Task = {
                id: `${groupIndex}-${taskIndex + 1}`,
                name: task.name,
                start,
                end:  end,
                progress: task.isTramitacion && Array.isArray(task.subtasks) && task.subtasks.length > 0
                ? task.subtasks.every(st => st.progress === 100)
                  ? 100
                  : task.subtasks.reduce((sum, st) => sum + (st.progress || 0), 0) / task.subtasks.length
                : task.progress || 0,
              
                type: task.isTramitacion ? 'project' : 'task',
                project: `group-${groupIndex}`,
                
                dependencies: task.dependsOn.map(dep => `${groupIndex}-${dep.taskId}`),
                styles:
                typeof taskColor === 'string' 
                  ? {
                      progressColor: taskColor,
                      progressSelectedColor: taskColor,
                      backgroundColor: `${taskColor}66`,
                      backgroundSelectedColor: `${taskColor}aa`,
                    }
                  : {
                      ...(taskColor && typeof taskColor === 'object' ? taskColor : {}), // Validación adicional
                      progressColor: 'transparent',
                      progressSelectedColor: 'transparent',
                    },
              
                hideChildren: task.isTramitacion ? !expandedSubgroups.has(`${groupIndex}-${taskIndex + 1}`) : undefined,
              };
      
                // Dentro del useMemo de chartData, modifica la sección donde se procesan las tareas de TRAMITACIÓN:

                if (task.isTramitacion && task.subtasks) {
                  // Preservar la fecha de fin original de la tarea TRAMITACIÓN
                  const tramitacionEndDate = dateUtils.parseLocalDate(task.end);
                  tramitacionEndDate.setHours(23, 59, 0, 0);
                
                  const sameDaySubtasks = task.subtasks.filter(
                    (subtask) => subtask.start === subtask.end
                  );

                  // Asegurarse de que la fecha de fin de TRAMITACIÓN no se modifique
                  // y sea al menos igual a la fecha más tardía de sus subtareas
                  const latestSubtaskEnd = new Date(
                    Math.max(...task.subtasks.map(st => dateUtils.parseLocalDate(st.end).getTime()))
                  );
                  latestSubtaskEnd.setHours(23, 59, 59, 999);
                
                  if (latestSubtaskEnd > tramitacionEndDate) {
                    // Actualizar la fecha de fin de la tarea principal
                    task.end = dateUtils.formatLocalDate(latestSubtaskEnd); // Ensure task.end is updated as a string in YYYY-MM-DD format
                  }
                
                
                
                  const subtasks = task.subtasks.map((subtask, subtaskIndex) =>
                    processSubtask(
                      subtask,
                      mainTask.id, 
                      subtaskIndex,
                      groupColor,
                      Array.isArray(task.subtasks) ? task.subtasks : [] // Aseguramos que sea un array
                    )
                    
                  );
                  console.log(JSON.stringify(subtasks));
                  return [mainTask, ...subtasks];
                }

              return [mainTask];

              
            }).flat(); // Aplanar el array resultante
      


            // Ensure valid group dates
            const validGroupTasks = groupTasks.filter(task => 
              task.start instanceof Date && 
               task.end instanceof Date && 
              !isNaN(task.start.getTime()) && 
              !isNaN(task.end.getTime())
            );
    
            if (validGroupTasks.length === 0) {
              console.warn(`No valid tasks found for group ${groupIndex}`);
              return [];
            }
    
            const groupStart = new Date(Math.min(...validGroupTasks.map(task => task.start.getTime())));
            const groupEnd = new Date(Math.max(...validGroupTasks.map(task => task.end.getTime())));
            groupStart.setHours(0, 0, 0, 0);
            groupEnd.setHours(23, 59, 0, 0);
            const groupProgress = groupTasks.reduce((sum, task) => sum + task.progress, 0) / groupTasks.length;
      
            const groupTask: Task = {
              id: `group-${groupIndex}`,
              name: group.agrupador,
              start: groupStart,
              end: groupEnd,
              progress: groupProgress,
              type: 'project',
              hideChildren: !expandedGroups.has(`group-${groupIndex}`),
              isDisabled: false,
              project: undefined,
              styles: {
                progressColor: groupColor,
                progressSelectedColor: darkenColor(groupColor),
                backgroundColor: groupColor,
                backgroundSelectedColor: darkenColor(groupColor),
              },
              dependencies: [],
            };
      
            return expandedGroups.has(`group-${groupIndex}`) ? [groupTask, ...groupTasks] : [groupTask];
          });
        } else {
          return tasks.groups.flatMap((group, groupIndex) =>
            group.tasks.map((task): Task => {
              // Ensure valid dates
              let start = parseLocalDate(task.start);
              let end = parseLocalDate(task.end);
              
                
          // Aplicar la misma lógica de duración mínima
          start.setHours(0, 0, 0, 0);
          end = ensureMinimumDuration(start, end);

              // Ensure minimum duration
              const duration = end.getTime() - start.getTime();
              if (duration < 0) {
                end.setTime(start.getTime() + (24 * 60 * 60 * 1000)); // Set minimum 1 day duration
              }
    
              const groupColor = group.color || getDefaultColor(groupIndex);



              let taskColor = getTaskColor(task, group.tasks, groupColor);

      
              // Comprobar el progreso de las dependencias
              task.dependsOn.forEach(dep => {
                const progresoDependencia = getTaskProgress(groupIndex, dep.taskId);
                if (progresoDependencia !== null && progresoDependencia !== undefined && progresoDependencia < 100) {
                  taskColor = 'gray'; // Cambiar color a gris si alguna dependencia no está completa
                }
              });
      
              return {
                id: `${groupIndex}-${task.id}`,
                name: task.name,
                start,
                end,
                progress: task.progress,
                type: 'task',
                project: group.agrupador,
                dependencies: task.dependsOn.map(dep => `${groupIndex}-${dep.taskId}`),
             styles:
  typeof taskColor === 'string'
    ? {
        progressColor: taskColor,
        progressSelectedColor: taskColor,
        backgroundColor: `${taskColor}66`,
        backgroundSelectedColor: `${taskColor}aa`,
      }
    : {
        ...(taskColor && typeof taskColor === 'object' ? taskColor : {}), // Validación adicional
        progressColor: 'transparent',
        progressSelectedColor: 'transparent',
      },

              };
            })
          );
        }
      }, [tasks, isGrouped, expandedGroups, expandedSubgroups, colors]);
      
    
    
    const handleZoomChange = useCallback((value: number | number[]) => {
      if (Array.isArray(value)) {
        setZoom(value[0]);  // Si es un array, usamos el primer valor
      } else {
        setZoom(value);  // Si es un número, lo usamos directamente
      }
    }, []);
    

    const handleViewModeChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
      setViewMode(e.target.value as ViewMode);
    }, []);

    

    const toggleAllGroups = () => {
      if (areAllGroupsExpanded) {
        setExpandedGroups(new Set()); // Contrae todos los grupos
      } else {
        const allGroupIds = tasks.groups.map((_, index) => `group-${index}`);
        setExpandedGroups(new Set(allGroupIds)); // Expande todos los grupos
      }
      setAreAllGroupsExpanded(!areAllGroupsExpanded);
    };
    const toggleFullScreen = () => {
      if (!document.fullscreenElement) {
        containerRef.current?.requestFullscreen();
      } else {
        document.exitFullscreen();
      }
    };

// In the handleTaskSelect function
const handleTaskSelect = (task: Task) => {
  if (task.type === 'project' && task.name !== 'TRAMITACIÓN') {
    handleExpanderClick(task);
    return;
  }
  
  const [groupIndex, taskIndex, subtaskIndex] = task.id.split('-').map(Number);
  
  setIsModalOpen(false);
  setIsSubtaskModalOpen(false);
  setSelectedSubtask(null);
  setSelectedTask(null);

  if (typeof subtaskIndex !== 'undefined') {
    const mainTask = tasks.groups[groupIndex]?.tasks[taskIndex - 1];
    if (mainTask?.subtasks) {
      const subtask = mainTask.subtasks[subtaskIndex - 1];
      
      const extendedMainTask: ExtendedTask = {
        id: `${groupIndex}-${taskIndex}`,
        type: 'project',
        name: mainTask.name,
        start: dateUtils.parseLocalDate(mainTask.start),
        end: dateUtils.parseLocalDate(mainTask.end),
        progress: mainTask.progress,
        dependencies: task.dependencies || [],
        styles: task.styles,
        globalId: Number(mainTask.id),
        comments: '',
        description: mainTask.name,
        responsible: task.project || '',
        isClosed: task.progress === 100,
        semaphoreStatus: getSemaphoreFromColor(task.styles?.progressColor),
        reminder_code: '',
        reminder_type: "",
        reminder_value: "",
        reminder_calculated_date: "",
        reminder_active: true,
        reminder_triggered: false,
        subtasks: mainTask.subtasks?.map(st => ({
          ...st,
          id: st.id?.toString() || '', // Convert to string and provide default empty string
          start: new Date(st.start),
          end: new Date(st.end),
          progress: st.progress || 0,
          type: st.type,
          duration: st.duration,
          enabled: st.enabled,
          orden: st.orden,
          comments: st.comments || ''
        })) || []
      };

      const extendedSubtask: ExtendedSubtask = {
        ...subtask,
        id: subtask.id,
        name: task.name,
        start: task.start.toString(),
        end: task.end.toString(),
        progress: task.progress,
        comments: subtask.comments || '',
        isClosed: task.progress === 100,
        type: subtask.type,
        dependsOn: subtask.dependsOn || [],
        enabled: subtask.enabled,
        duration: subtask.duration,
        orden: subtask.orden,
        organism: subtask.organism,
        responsible: subtask.responsible,
        semaphoreStatus: subtask.semaphoreStatus,
      };
      
      setSelectedTask(extendedMainTask);
      setSelectedSubtask(extendedSubtask);
      setIsSubtaskModalOpen(true);
      return;
    }
  }

  // Handle main task selection
  const globalId = tasks.groups[groupIndex]?.tasks[taskIndex - 1]?.id;
  const extendedTask: ExtendedTask = {
    ...task,
    globalId: Number(globalId),
    comments: '',
    description: task.name,
    responsible: task.project || '',
    isClosed: task.progress === 100,
    semaphoreStatus: getSemaphoreFromColor(task.styles?.progressColor),
    reminder_code: '',
    reminder_type: '',
    reminder_value: '',
    reminder_calculated_date: '',
    reminder_active: true,
    reminder_triggered: false
  };

  setSelectedTask(extendedTask);
    if(task.name!=='TRAMITACIÓN'){
    setIsModalOpen(true);
  } 
  setSelectedTaskId(task.id);
};

const getSemaphoreFromColor = (color?: string): SemaphoreStatus => {
  switch (color) {
    case '#EF4444': return SemaphoreStatus.Red;
    case '#FB923C': return SemaphoreStatus.Orange;
    case '#FDE047': return SemaphoreStatus.Yellow;
    case '#4ADE80': return SemaphoreStatus.Green;
    default: return SemaphoreStatus.Gray;
  }
};



const createExtendedMainTask = (mainTask: ExtendedTask, groupIndex: number, taskIndex: number, taskWithStyles: ExtendedTask): ExtendedTask => ({
  id: `${groupIndex}-${taskIndex}`,
  type: 'project',
  name: mainTask.name,
  start: new Date(mainTask.start),
  end: new Date(mainTask.end),
  progress: mainTask.progress,
  dependencies: mainTask.dependsOn?.map(dep => `${groupIndex}-${dep.taskId}`) || [],
  styles: taskWithStyles.styles,
  globalId: mainTask.globalId,
  comments: '',
  description: mainTask.name,
  responsible: taskWithStyles.project || '',
  isClosed: taskWithStyles.progress === 100,
  semaphoreStatus: 'Gray',
  reminder_code: '',
  reminder_type: '',
  reminder_value: '',
  reminder_calculated_date: '',
  reminder_active: true,
  reminder_triggered: false,
  subtasks: mainTask.subtasks?.map(st => ({
    ...st,
    id: st.id.toString(),
    start: st.start,
    end: st.end,
    type: st.type || 'task'
  }))
});

const createExtendedSubtask = (subtask: SubTask, task: Task): ExtendedSubtask => ({
  ...subtask,
  id: subtask.id,
  name: task.name,
  start: task.start.toISOString().split('T')[0],
  end: task.end.toISOString().split('T')[0],
  progress: task.progress,
  comments: '',
  isClosed: task.progress === 100,
  type: subtask.type,
  dependsOn: subtask.dependsOn || [],
  enabled: subtask.enabled,
  duration: subtask.duration,
  orden: subtask.orden,
  organism: subtask.organism,
  responsible: subtask.responsible,
  st_type: subtask.st_type,
  resolutionType: subtask.resolutionType,
  semaphoreStatus: subtask.semaphoreStatus
});



    useEffect(() => {
      // Escuchar cambios en el tamaño del gráfico y redibujarlo
      const handleResize = () => {
        if (ganttRef.current) {
          ganttRef.current.scrollTop = 0; // Reajusta el scroll vertical
          ganttRef.current.scrollLeft = 0; // Reajusta el scroll horizontal
          window.dispatchEvent(new Event('resize')); // Forza un evento de resize
        }
      };
    
      // Escuchar evento de redibujado o expansión/colapso
      const observer = new MutationObserver(handleResize);
      if (ganttRef.current) {
        observer.observe(ganttRef.current, { childList: true, subtree: true });
      }
    
      return () => observer.disconnect();
    }, [expandedGroups, expandedSubgroups]);
    
  
    const handleExpanderClick = (task: Task) => {
      if (task.type === 'project') {
          if (task.name === 'TRAMITACIÓN') {
              setExpandedSubgroups(prev => {
                  const newSet = new Set(prev);
                  if (newSet.has(task.id)) {
                      newSet.delete(task.id);
                  } else {
                      newSet.add(task.id);
                  }
                  return newSet;
              });
  
              // Forzar un redibujo
              requestAnimationFrame(() => {
                  if (ganttRef.current) {
                      ganttRef.current.style.transform = 'translateZ(0)';
                      setTimeout(() => {
                          if (ganttRef.current) {
                              ganttRef.current.style.transform = '';
                          }
                      }, 10);
                  }
              });
          } else {
              setExpandedGroups(prev => {
                  const newSet = new Set(prev);
                  if (newSet.has(task.id)) {
                      newSet.delete(task.id);
                  } else {
                      newSet.add(task.id);
                  }
                  return newSet;
              });
          }
      }
  };

  
const scrollToBottom = () => {
  if (ganttRef.current) {
      ganttRef.current.scrollTop = ganttRef.current.scrollHeight;
  }
};

useEffect(() => {
  scrollToBottom();
}, [expandedGroups, expandedSubgroups]);

    

    const toggleGrouping = () => {
      setIsGrouped(prev => !prev);
      setExpandedGroups(new Set());
    };

    const toggleGroup = (groupId: string) => {
      setExpandedGroups(prev => {
        const newSet = new Set(prev);
        if (newSet.has(groupId)) {
          newSet.delete(groupId);
        } else {
          newSet.add(groupId);
        }
        return newSet;
      });
    };

    // We no longer need handleExpanderClick as the expansion is handled by the chevron click

    useEffect(() => {
      const handleFullScreenChange = () => {
        setIsFullScreen(!!document.fullscreenElement);
      };

      document.addEventListener('fullscreenchange', handleFullScreenChange);

      return () => {
        document.removeEventListener('fullscreenchange', handleFullScreenChange);
      };
    }, []);

    
    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
      setIsDragging(true);
      setDragStart({ x: e.clientX, y: e.clientY });
    };

    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
      if (isDragging && ganttRef.current) {
        const dx = e.clientX - dragStart.x;
        const dy = e.clientY - dragStart.y;
        ganttRef.current.scrollLeft -= dx;
        ganttRef.current.scrollTop -= dy;
        setDragStart({ x: e.clientX, y: e.clientY });
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    const handleWheel = useCallback((e: WheelEvent) => {
      if (e.ctrlKey) {
        e.preventDefault();
        const delta = e.deltaY < 0 ? 10 : -10; // Invertimos la dirección para un zoom más intuitivo
        setZoom(prevZoom => {
          const newZoom = Math.min(Math.max(prevZoom + delta, 50), 200);
          return newZoom;
        });
      }
    }, []);
  
    // Cambiamos el useEffect para usar el callback memorizado
    useEffect(() => {
      const ganttElement = ganttRef.current;
      
      const wheelHandler = (e: WheelEvent) => {
        if (e.ctrlKey) {
          e.preventDefault();
          handleWheel(e);
        }
      };
  
      if (ganttElement) {
        // Usamos 'wheel' en lugar de 'mousewheel' para mejor compatibilidad
        ganttElement.addEventListener('wheel', wheelHandler, { passive: false });
      }
  
      return () => {
        if (ganttElement) {
          ganttElement.removeEventListener('wheel', wheelHandler);
        }
      };
    }, [handleWheel]);
    const formatDate = (date: Date): string => {
      return date.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' });
    };

    const getWeekNumber = (date: Date): number => {
      const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
      const dayNum = d.getUTCDay() || 7;
      d.setUTCDate(d.getUTCDate() + 4 - dayNum);
      const yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
      return Math.ceil((((d.getTime() - yearStart.getTime()) / 86400000) + 1)/7);
    };


    const columnWidth = useMemo(() => {
      return viewMode === ViewMode.Month ? 300 * (zoom / 100) : 65 * (zoom / 100);
  }, [zoom, viewMode]);

    const formatTimeTick = (date: Date): string => {
      const formatted = formatDate(date);
      if (viewMode === ViewMode.Week) {
        const weekNumber = getWeekNumber(date);
        return `S${String(weekNumber).padStart(2, '0')} - ${formatted}`;
      }
      return formatted;
    };

    useEffect(() => {
      const style = document.createElement('style');
      style.textContent = `
        .gantt-today-highlight {
          position: absolute;
          top: 0;
          bottom: 0;
          width: 2px !important;
          background-color: rgba(255, 0, 0, 0.5) !important;
          z-index: 1;
        }
      `;
      document.head.appendChild(style);
    
      return () => {
        document.head.removeChild(style);
      };
    }, []);

    const totalTasks = useMemo(() => {
      return tasks.groups.reduce((total, group) => total + group.tasks.length + 1, 0);
  }, [tasks]);


  const FloatingActionBar = () => (
    <div className="fixed right-4 bottom-0 transform -translate-y-1/2 flex flex-col space-y-2 z-50">
      
    
    </div>
  );


  const immobileBarStyles = `
    .gantt-task-bar {
      pointer-events: none !important;
      user-select: none !important;
      -webkit-user-select: none !important;
      -moz-user-select: none !important;
      -ms-user-select: none !important;
    }
    .gantt-task-milestone {
      pointer-events: none !important;
    }
    .gantt-task-info {
      pointer-events: auto !important;
      cursor: pointer !important;
    }
    .gantt-task-handle {
      display: none !important;
    }
    .gantt-task-resize {
      display: none !important;
    }
    .gantt-task-progress-handle {
      display: none !important;
    }
    .gantt-task-bar:hover {
      transform: none !important;
    }
    .gantt-task-bar-main {
      cursor: default !important;
    }
  `;


    return (
      <div 
      ref={containerRef} 
      className={`gantt-container flex flex-col bg-white rounded-lg shadow-lg ${
        isFullScreen ? 'fixed inset-0 z-50' : ''
      }`}
    >
            <style>{immobileBarStyles}</style>

      <div className="flex justify-between items-center mb-4 p-4 border-b">
      <div className="flex items-center space-x-4">
  <ZoomOut 
    className="w-6 h-6 cursor-pointer" 
    onClick={() => handleZoomChange(zoom - 10)} 
  />
  <Slider
    value={zoom}
    min={50}
    max={200}
    step={10}
    onChange={handleZoomChange}
    className="w-40"
  />
  <ZoomIn 
    className="w-6 h-6 cursor-pointer" 
    onClick={() => handleZoomChange(zoom + 10)} 
  />
  <div className="ml-10 flex space-x-2">
  <Button onClick={toggleAllGroups} variant="outline" size="sm">
            {areAllGroupsExpanded ? <Shrink className="w-4 h-4 mr-2" /> : <Expand className="w-4 h-4 mr-2" />}
            {areAllGroupsExpanded ? 'Colapsar Todos' : 'Expandir Todos'}
          </Button>
          <Button onClick={toggleFullScreen} variant="outline" size="sm">
            {isFullScreen ? <Minimize className="w-4 h-4 mr-2" /> : <Maximize className="w-4 h-4 mr-2" />}
            {isFullScreen ? 'Salir de Pantalla Completa' : 'Pantalla Completa'}
          </Button>
  </div>


        </div>
        <select
          value={viewMode}
          onChange={handleViewModeChange}
          className="border p-2 rounded-md bg-white shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value={ViewMode.Day}>Día</option>
          <option value={ViewMode.Week}>Semana</option>
          <option value={ViewMode.Month}>Mes</option>
          <option value={ViewMode.Year}>Año</option>
        </select>
      </div>
      <div 
        ref={ganttRef}
        className="flex-grow overflow-auto"
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        <div style={{ width: '100%', height: '100%' }}>
        <style>
        {`
          .custom-gantt-container .gantt-task-row {
            height: 36px;
          }
          
          .custom-gantt-container .project {
            padding-left: 4px !important;
          }
          
          .custom-gantt-container .project .expansion-btn {
            margin-left: 4px !important;
          }
          
          .custom-gantt-container .project[data-project-id="TRAMITACION"] {
            padding-left: 16px !important;
          }
          
          .custom-gantt-container .project[data-project-id="TRAMITACION"] .expansion-btn {
            margin-left: 16px !important;
          }
        `}
      </style>
      
          <Gantt
                  key={renderKey} // Se fuerza el re-render aquí

            tasks={chartData.map(task => ({
            ...task,
  
              isDisabled: true, // Deshabilitar cada tarea individualmente
              }))}
                         viewMode={viewMode}
            locale="es"
            listCellWidth="250px"
            columnWidth={columnWidth}
            ganttHeight={Math.max(isFullScreen ? window.innerHeight - 150 : 391)}
            headerHeight={50}
            rowHeight={40}
            barCornerRadius={5}
            projectProgressColor="#3B82F6"
            barProgressColor="#F59E0B"
            barProgressSelectedColor="#FBBF24"
            barBackgroundColor="#E5E7EB"
            barBackgroundSelectedColor="#D1D5DB"
            handleWidth={0} 
            timeStep={86400000} 
            arrowIndent={50}
            arrowColor={"#6B7280"} // Use global color as fallback
            fontFamily="Inter, sans-serif"
            fontSize="14px"
            weekPrefix=""
            todayColor="rgba(239, 68, 68, 0.1)"
            todayLineColor="rgba(239, 68, 68, 0.9)" 
            TaskListHeader={CustomHeader}
            TaskListTable={CustomTaskListTable}
            TooltipContent={({ task }) => (
              <div className="bg-white p-3 rounded-lg shadow-lg">
                <h3 className="font-bold text-lg mb-2">{task.name}</h3>
                <p className="mb-1"><span className="font-semibold">Inicio:</span> {formatDate(task.start)}</p>
                <p className="mb-1"><span className="font-semibold">Fin:</span> {formatDate(task.end)}</p>
                <p className="mb-1"><span className="font-semibold">Progreso:</span> {task.progress.toFixed(2)}%</p>
                <p><span className="font-semibold">Estado:</span> {
                  task.styles?.progressColor === '#AEB8C2' ? 'No iniciada' :
                  task.styles?.progressColor === '#4ADE80' ? 'En progreso' :
                  task.styles?.progressColor === '#FDE047' ? 'Por vencer' :
                  task.styles?.progressColor === '#FB923C' ? 'A punto de vencer' :
                  task.styles?.progressColor === '#EF4444' ? 'Vencida' :
                  task.progress === 100 ? 'Completada' : ''
                }</p>
              </div>
            )}
           // Deshabilitar la edición de las barras
           onDateChange={() => {}}
           onProgressChange={() => {}}
           onDoubleClick={() => {}}
           onSelect={handleTaskSelect}
           onExpanderClick={handleExpanderClick}
           
          />
        </div> 
      </div>
      {selectedTask && !isSubtask(selectedTask) && (
  <TaskModal
    isOpen={isModalOpen}
    onClose={() => {
      setIsModalOpen(false);
      setSelectedTask(null);
    }}
    task={selectedTask}
    onChangeTask={handleTaskChange}
    backgroundColor={selectedTask.styles?.backgroundColor || '#3B82F6'}
    daysDelayed={
      selectedTask.end < new Date() && selectedTask.progress < 100
        ? Math.ceil((new Date().getTime() - selectedTask.end.getTime()) / (1000 * 60 * 60 * 24))
        : 0
    }
    processName={processName}
    subprocessName={subprocessName}
    groups={tasks.groups}
  />
)}

{selectedSubtask && (
  <SubtaskModal
    isOpen={isSubtaskModalOpen}
    onClose={() => {
      setIsSubtaskModalOpen(false);
      setSelectedSubtask(null);
    }}
    subtask={selectedSubtask}
    onChangeTask={handleTaskChange}

    onChangeSubtask={(updatedSubtask) => {
      if (selectedTask) {  // Añadimos verificación
        const updatedTask = {
          ...selectedTask,
          progress: updatedSubtask.progress,
          start: new Date(updatedSubtask.start),
          end: new Date(updatedSubtask.end),
          name: updatedSubtask.name,
          comments: updatedSubtask.comments
        };
        handleTaskChange(updatedTask);
      }
    }}
    onAddNewSubtask={(option) => handleSubtaskSelection(selectedSubtask, option)}

    backgroundColor={selectedTask?.styles?.backgroundColor || '#3B82F6'}
    mainTask={selectedTask as ExtendedTask}
    processName={processName}
    subprocessName={subprocessName}
    forceTableRerender={() => setRenderKey(prev => prev + 1)} // Nueva prop

    agrupadorName={tasks.groups.find(g => 
      g.tasks.some(t => t.id === selectedTask?.id)
    )?.name}
  />
)}

    </div>
  );
}; 
export default GanttChartView;
