import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, Tooltip, ResponsiveContainer } from 'recharts';
import { FileQuestion, Maximize2, Minimize2 } from 'lucide-react';

interface Process {
  nombreProceso: string;
  codigo: string;
  comuna: string;
  subprocesos?: string[];
  plantilla: string;
}

interface SubTask {
  id: number;
  nombre: string;
  tipo: 'INGRESO' | 'OBSERVACIÓN' | 'RESPUESTA' | 'RESOLUCIÓN' | 'OTROS';
  resolutionType?: 'APROBADO' | 'RECHAZADO' | 'DESISTIMIENTO' | 'SILENCIO ADMINISTRATIVO POSITIVO' | 'SILENCIO ADMINISTRATIVO NEGATIVO' | 'OTRO';
  fecha_inicio: string;
  fecha_termino: string;
  isClosed: boolean;
}

interface Task {
  nombre: string;
  fecha_inicio: string;
  fecha_termino: string;
  subtasks: SubTask[];
  isClosed: boolean;
}

interface Agrupador {
  nombre: string;
  enabled: boolean;
  descriptor: string;
  tareas: Task[];
  organismo: string;
}

interface Subproceso {
  nombre: string;
  agrupadores: Agrupador[];
}

interface ProcessData {
  subprocesos: Subproceso[];
}

interface TramitacionDashboardProps {
  selectedProcess: string;
  processes: Process[];
}

interface ProcessedDataItem {
  subproceso: string;
  agrupador: string;
  permiso: string;
  ingreso: number;
  observacion: number;
  respuestaTitular: number;
  resolucion: number;
  plazoTotal: number;
  estado: string;
}

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  isSquare?: boolean;  // Prop para definir si el modal es cuadrado o rectangular
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children, isSquare = false }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div
        className={`bg-white p-4 rounded-lg shadow-lg relative ${
          isSquare ? 'w-[60vh] h-[60vh]' : 'w-[80vw] h-[50vh]'
        } overflow-hidden`}
      >
        <button
          onClick={onClose}
          className="absolute top-2 right-2 p-1 bg-gray-200 rounded-full hover:bg-gray-300"
        >
          <Minimize2 size={20} />
        </button>
        {children}
      </div>
    </div>
  );
};



const EmptyState = () => (
  <div className="flex flex-col items-center justify-center p-10 bg-gradient-to-br from-gray-50 to-gray-100 rounded-xl border-2 border-dashed border-gray-200">
    <FileQuestion className="w-20 h-20 text-gray-400 mb-6" />
    <div className="text-center">
      <h3 className="text-2xl font-bold text-gray-900 mb-3">
        No hay datos disponibles
      </h3>
      <p className="text-gray-600 max-w-md">
        No se encontraron registros para mostrar en este momento.
      </p>
    </div>
  </div> 
);


const TramitacionDashboard: React.FC<TramitacionDashboardProps> = ({ selectedProcess, processes }) => {
  const [showWeeks, setShowWeeks] = useState<boolean>(false);
  const [isZoomed, setIsZoomed] = useState({ pie: false, bar: false });
  const [processData, setProcessData] = useState<ProcessedDataItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const convertToWeeks = (days: number): string => (days / 7).toFixed(1);

  const loadProcessData = async (processName: string): Promise<void> => {
    if (!processName || !processes) {
      setProcessData([]);
      return;
    }
  
    setIsLoading(true);
    try {
      const selectedProcess = processes.find(p => p.nombreProceso === processName);
      if (!selectedProcess?.codigo) {
        setProcessData([]);
        return;
      }
  
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/proceso/get_process_data.php?codigo=${selectedProcess.codigo}`);
      if (!response.ok) throw new Error('Network response was not ok');
      
      const data: ProcessData = await response.json();
      
      const processedData = data.subprocesos
        .flatMap(sp => sp.agrupadores
          .filter(agr => agr?.enabled && agr.descriptor === "TRAMITOLOGÍA")
          .map(agr => {
            const tramitacionTask = agr.tareas?.find(tarea => tarea?.nombre === "TRAMITACIÓN");
            if (!tramitacionTask?.subtasks?.length) return null;
  
            // Verificar si existe una resolución cerrada
            const tieneResolucionCerrada = tramitacionTask.subtasks.some(
              subtask => subtask.tipo === 'RESOLUCIÓN' && subtask.isClosed
            );
  
            // Si no hay resolución cerrada, no incluir esta tramitación
            if (!tieneResolucionCerrada) return null;
  
            const tiempos = tramitacionTask.subtasks.reduce((acc: Record<string, number>, subtask) => {
              if (!subtask.fecha_inicio || !subtask.fecha_termino || !subtask.isClosed) return acc;
              
              const tiempo = calculateDaysBetween(subtask.fecha_inicio, subtask.fecha_termino);
  
              switch (subtask.tipo) {
                case 'INGRESO': acc.ingreso = (acc.ingreso || 0) + tiempo; break;
                case 'OBSERVACIÓN': acc.observacion = (acc.observacion || 0) + tiempo; break;
                case 'RESPUESTA': acc.respuestaTitular = (acc.respuestaTitular || 0) + tiempo; break;
                case 'RESOLUCIÓN': acc.resolucion = (acc.resolucion || 0) + tiempo; break;
              }
              return acc;
            }, { ingreso: 0, observacion: 0, respuestaTitular: 0, resolucion: 0 });
  
            const resolucionSubtask = tramitacionTask.subtasks
              .find(st => st.tipo === 'RESOLUCIÓN' && st.isClosed);
            
            const estado = resolucionSubtask?.resolutionType || 'En Proceso';
  
            return {
              subproceso: sp.nombre,
              agrupador: agr.nombre,
              permiso: agr.organismo || 'Sin organismo',
              ...tiempos,
              plazoTotal: Object.values(tiempos).reduce((a, b) => a + b, 0),
              estado
            };
          })
        )
        .filter((item): item is ProcessedDataItem => item !== null);
  
      setProcessData(processedData);
    } catch (error) {
      console.error('Error loading process data:', error);
      setProcessData([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedProcess) {
      loadProcessData(selectedProcess);
    }
  }, [selectedProcess]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center p-8">
        <div className="animate-spin rounded-full h-10 w-10 border-4 border-cyan-400 border-t-transparent"></div>
      </div>
    );
  }

  if (!processData.length) {
    return (
      <EmptyState />

    );
  }

  const COLORS: Record<string, string> = {
    'APROBADO': '#1D9E4E',  // bg-green-400
    'RECHAZADO': '#f87171',  // bg-red-400
    'DESISTIMIENTO': '#fb923c',  // bg-orange-400
    'SILENCIO ADMINISTRATIVO POSITIVO': '#1D9E4E',  // bg-green-400 (SAP)
    'SILENCIO ADMINISTRATIVO NEGATIVO': '#f87171',  // bg-red-400 (SAN)
    'NO ADMITIDO': '#f87171'  // bg-red-400
  };
  
  
  

  const pieData = Object.entries(
    processData.reduce((acc: Record<string, number>, curr) => {
      acc[curr.estado] = (acc[curr.estado] || 0) + 1;
      return acc;
    }, {})
  ).map(([name, value]) => ({ name, value }));

  const barData = processData
  .sort((a, b) => a.plazoTotal - b.plazoTotal)
  .map(item => ({
    permiso: `${item.agrupador} / ${item.permiso}`, // ✅ Etiqueta combinada
    'Ingreso (Días)': showWeeks ? convertToWeeks(item.ingreso) : item.ingreso,
    'Observaciones (Días)': showWeeks ? convertToWeeks(item.observacion) : item.observacion,
    'Respuesta Titular (Días)': showWeeks ? convertToWeeks(item.respuestaTitular) : item.respuestaTitular,
    'Resolución (Días)': showWeeks ? convertToWeeks(item.resolucion) : item.resolucion
  }));


    return (
      <div className="p-4 bg-white">
        <div className="flex justify-between items-center mb-4 ">
          <h1 className="text-xl font-medium">Resumen de Tramitación</h1>
          <button
            onClick={() => setShowWeeks(!showWeeks)}
            className="px-3 py-1 text-sm bg-cyan-400 text-white rounded"
          >
            Mostrar en {showWeeks ? 'Días' : 'Semanas'}
          </button>
        </div>
    
        {/* Tabla de datos */}
        <div className="mb-4 overflow-hidden border border-gray-200">
          <table className="w-full text-sm">
            <thead className="bg-cyan-400 text-white">
              <tr>
                <th className="px-3 py-2 text-left">Subproceso</th>
                <th className="px-3 py-2 text-left">Agrupador</th>
                <th className="px-3 py-2 text-left">Organismo</th>
                <th className="px-3 py-2 text-left">Ingreso ({showWeeks ? 'Semanas' : 'Días'})</th>
                <th className="px-3 py-2 text-left">Observaciones ({showWeeks ? 'Semanas' : 'Días'})</th>
                <th className="px-3 py-2 text-left">Respuesta Titular ({showWeeks ? 'Semanas' : 'Días'})</th>
                <th className="px-3 py-2 text-left">Resolución ({showWeeks ? 'Semanas' : 'Días'})</th>
                <th className="px-3 py-2 text-left">Plazo total</th>
                <th className="px-3 py-2 text-left">Estado</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {processData.map((row, index) => (
                <tr key={index} className="hover:bg-gray-50">
                  <td className="px-3 py-2">{row.subproceso}</td>
                  <td className="px-3 py-2">{row.agrupador}</td>
                  <td className="px-3 py-2">{row.permiso}</td>
                  <td className="px-3 py-2">{showWeeks ? convertToWeeks(row.ingreso) : row.ingreso}</td>
                  <td className="px-3 py-2">{showWeeks ? convertToWeeks(row.observacion) : row.observacion}</td>
                  <td className="px-3 py-2">{showWeeks ? convertToWeeks(row.respuestaTitular) : row.respuestaTitular}</td>
                  <td className="px-3 py-2">{showWeeks ? convertToWeeks(row.resolucion) : row.resolucion}</td>
                  <td className="px-3 py-2">{showWeeks ? convertToWeeks(row.plazoTotal) : row.plazoTotal}</td>
                  <td className="px-3 py-2">
                    <span className="px-2 py-1 rounded text-xs text-white"
                      style={{ backgroundColor: COLORS[row.estado] || '#757575' }}>
                      {row.estado}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
    
        {/* Gráficos */}
        <div className="flex gap-4">
          {/* Gráfico de torta */}
          <div className="relative border border-gray-200 p-4 w-1/2">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-sm font-medium">Distribución de Estados</h3>
              <button onClick={() => setIsZoomed({ ...isZoomed, pie: true })}
                      className="p-1 hover:bg-gray-100 rounded">
                <Maximize2 size={20} />
              </button>
            </div>
            <div className="w-full h-72">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={pieData}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={({ percent }) => `${(percent * 100).toFixed(0)}%`}
                    outerRadius="80%"
                    dataKey="value"
                  >
                    {pieData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[entry.name] || '#757575'} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend verticalAlign="bottom" height={36} />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>
    
          {/* Gráfico de barras */}
          <div className="relative border border-gray-200 p-4 w-1/2">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-sm font-medium">Resumen de Tramitación por Permiso</h3>
              <button onClick={() => setIsZoomed({ ...isZoomed, bar: true })}
                      className="p-1 hover:bg-gray-100 rounded">
                <Maximize2 size={20} />
              </button>
            </div>
            <div className="w-full h-72">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={barData}
                  layout="vertical"
                  margin={{ top: 5, right: 30, left: 50, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" />
                  <YAxis
  dataKey="permiso"
  type="category"
  width={160}
  tick={({ x, y, payload }) => (
    <text
      x={x}
      y={y}
      dy={4}
      textAnchor="end"
      fill="#666"
      fontSize={15}
      style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}
    >
      {payload.value}
    </text>
  )}
    tickFormatter={(value: string) => value.replace(' / ', ' / ')} // mantiene en una sola línea
/>
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="Ingreso (Días)" stackId="a" fill="#5DCED4" />
<Bar dataKey="Observaciones (Días)" stackId="a" fill="#E8B75E" />
<Bar dataKey="Respuesta Titular (Días)" stackId="a" fill="#E87C74" />
<Bar dataKey="Resolución (Días)" stackId="a" fill="#6AC6B3" />


                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
    
        {/* Modales para gráficos */}
        <Modal isOpen={isZoomed.pie} onClose={() => setIsZoomed({ ...isZoomed, pie: false })} isSquare={true}>
  <h3 className="text-lg font-medium mb-4">Distribución de Estados</h3>
  <div className="w-full h-full ">
    <ResponsiveContainer width="100%" height="90%">
      <PieChart>
        <Pie
          data={pieData}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={({ percent }) => `${(percent * 100).toFixed(0)}%`}
          outerRadius="85%"
          dataKey="value"
        >
          {pieData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[entry.name] || '#757575'} />
          ))}
        </Pie>
        <Tooltip />
        <Legend verticalAlign="bottom" height={36} />
      </PieChart>
    </ResponsiveContainer>
  </div>
        </Modal>


        <Modal isOpen={isZoomed.bar} onClose={() => setIsZoomed({ ...isZoomed, bar: false })}>
  <h3 className="text-lg font-medium mb-4 ">Resumen de Tramitación por Permiso</h3>
  <div className="w-full h-full overflow-hidden">
    <ResponsiveContainer width="100%" height="90%">
      <BarChart
        data={barData}
        layout="vertical"
        margin={{ top: 5, right: 30, left: 50, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" />
        <YAxis dataKey="permiso" type="category" width={100} />
        <Tooltip />
        <Legend />
        <Bar dataKey="Ingreso (Días)" stackId="a" fill="#2196F3" />
        <Bar dataKey="Observaciones (Días)" stackId="a" fill="#757575" />
        <Bar dataKey="Respuesta Titular (Días)" stackId="a" fill="#FFC107" />
        <Bar dataKey="Resolución (Días)" stackId="a" fill="#1976D2" />
      </BarChart>
    </ResponsiveContainer>
  </div>
        </Modal>


      </div>
    );
  }

const calculateDaysBetween = (startDate: string, endDate: string): number => {
  if (!startDate || !endDate) return 0;

  const start = new Date(startDate);
  const end = new Date(endDate);

  if (isNaN(start.getTime()) || isNaN(end.getTime())) return 0;

  start.setHours(0, 0, 0, 0);
  end.setHours(23, 59, 59, 999);

  const diffTime = end.getTime() - start.getTime();
  return Math.max(1, Math.ceil(diffTime / (1000 * 60 * 60 * 24)));
};

export default TramitacionDashboard;