import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, Send, User, Mail, MessageSquare, CheckCircle, AlertCircle, Loader, Phone } from 'lucide-react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

interface ContactFormProps {
  onClose: () => void;
}

const ContactForm: React.FC<ContactFormProps> = ({ onClose }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState<'idle' | 'sending' | 'success' | 'error'>('idle');
  const [errors, setErrors] = useState<{name?: string; email?: string; phone?: string; message?: string}>({});

  const MAX_PHONE_LENGTH = 15; // O el máximo que necesites

  const handlePhoneChange = (value: string) => {
    if (value.replace(/[^0-9]/g, '').length > MAX_PHONE_LENGTH) {
      setErrors(prev => ({ ...prev, phone: `Máximo ${MAX_PHONE_LENGTH} dígitos` }));
    } else {
      setErrors(prev => ({ ...prev, phone: undefined }));
    }
    setPhone(value);
  };
  

  const validateForm = () => {
    const newErrors: {name?: string; phone?: string; email?: string; message?: string} = {};
    let isValid = true;
    
    if (!name.trim()) {
      newErrors.name = 'El nombre es requerido';
      isValid = false;
    }
    
    if (!email.trim()) {
      newErrors.email = 'El email es requerido';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = 'Email inválido';
      isValid = false;
    }
    
    if (!phone.trim() || phone.replace(/[^0-9]/g, '').length < 8) {
      newErrors.phone = 'El teléfono es inválido';
      isValid = false;
    }

    if (!message.trim()) {
      newErrors.message = 'El mensaje es requerido';
      isValid = false;
    } else if (message.trim().length < 10) {
      newErrors.message = 'El mensaje debe tener al menos 10 caracteres';
      isValid = false;
    }
    
    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }
    
    setStatus('sending');

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/mail/send_email.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, phone, email, message }),
      }); 

      if (response.ok) {
        setStatus('success');
        setTimeout(() => {
          onClose();
        }, 2000);
      } else {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Error al enviar el formulario');
      }
    } catch (error) {
      console.error('Error detallado:', error);
      setStatus('error');
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[1000]"
        
      >
        <motion.div
          initial={{ scale: 0.9, opacity: 0, y: 20 }}
          animate={{ scale: 1, opacity: 1, y: 0 }}
          exit={{ scale: 0.9, opacity: 0, y: 20 }}
          transition={{ type: 'spring', damping: 25, stiffness: 300 }}
          className="bg-white p-8 rounded-2xl shadow-2xl max-w-md w-full relative overflow-hidden"
          onClick={(e) => e.stopPropagation()}
        >
          {/* Background decorativo */}
          <div className="absolute top-0 left-0 w-full h-24 bg-gradient-to-r from-cyan-600 to-teal-500 rounded-t-2xl"></div>
          
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={onClose}
            className="absolute top-4 right-4 text-white hover:text-gray-200 focus:outline-none z-10"
          >
            <X size={24} />
          </motion.button>

          <div className="relative z-10 pt-6">
            <h2 className="text-3xl font-bold mb-2 text-center text-white">
              Contáctanos
            </h2>
            <p className="text-center text-white text-opacity-90 mb-6">
              Estamos aquí para responder tus preguntas
            </p>
          </div>

          <div className="relative mt-8">
            <form onSubmit={handleSubmit} className="space-y-5">
              <div>
                <label htmlFor="name" className="flex items-center text-md font-medium text-gray-700 mb-1">
                  <User className="h-4 w-4 mr-2 text-cyan-500" />
                  Nombre
                </label>
                <input
                  type="text"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className={`w-full px-4 py-3 rounded-lg border-2 text-gray-700 ${
                    errors.name ? 'border-red-300 bg-red-50' : 'border-gray-200'
                  } focus:border-cyan-500 focus:ring-2 focus:ring-cyan-200 transition duration-200 ease-in-out`}
                />
                {errors.name && (
                  <p className="mt-1 text-red-500 text-sm flex items-center">
                    <AlertCircle className="h-3 w-3 mr-1" />
                    {errors.name}
                  </p>
                )}
              </div>
              
              <div>
                <label htmlFor="email" className="flex items-center text-md font-medium text-gray-700 mb-1">
                  <Mail className="h-4 w-4 mr-2 text-cyan-500" />
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className={`w-full px-4 py-3 rounded-lg border-2 ${
                    errors.email ? 'border-red-300 bg-red-50' : 'border-gray-200'
                  } text-gray-700 focus:border-cyan-500 focus:ring-2 focus:ring-cyan-200 transition duration-200 ease-in-out`}
                />
                {errors.email && (
                  <p className="mt-1 text-red-500 text-sm flex items-center">
                    <AlertCircle className="h-3 w-3 mr-1" />
                    {errors.email}
                  </p>
                )}
              </div>

              {/* Teléfono con react-phone-input-2 */}
              <div>
              <label className="flex items-center mb-1">
                <Phone className="h-4 w-4 mr-2 text-cyan-500" /> Teléfono
              </label>
              <PhoneInput
                country={'cl'}
                value={phone}
                onChange={handlePhoneChange}
                inputClass="!w-full !pl-14 !pr-4 !py-3 !rounded-lg !border-2 !border-gray-200 focus:!border-cyan-500 focus:!ring-2 focus:!ring-cyan-200 !text-gray-700 !bg-white !outline-none"
                containerClass="!w-full"
                buttonClass="!bg-white !border-none !p-0"
                specialLabel=""
            />
            {errors.phone && (
                <p className="text-red-500 text-sm mt-1">{errors.phone}</p>
            )}

              {errors.phone && <p className="text-red-500 text-sm mt-1">{errors.phone}</p>}
            </div>
              
              <div>
                <label htmlFor="message" className="flex items-center text-md font-medium text-gray-700 mb-1">
                  <MessageSquare className="h-4 w-4 mr-2 text-cyan-500" />
                  Mensaje
                </label>
                <textarea
                  id="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  className={`w-full px-4 py-3 rounded-lg border-2 ${
                    errors.message ? 'border-red-300 bg-red-50' : 'border-gray-200'
                  } text-gray-700 focus:border-cyan-500 focus:ring-2 focus:ring-cyan-200 transition duration-200 ease-in-out`}
                  rows={4}
                ></textarea>
                {errors.message && (
                  <p className="mt-1 text-red-500 text-sm flex items-center">
                    <AlertCircle className="h-3 w-3 mr-1" />
                    {errors.message}
                  </p>
                )}
              </div>
              
              <div className="flex justify-end space-x-3 pt-2">
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  type="button"
                  onClick={onClose}
                  className="px-5 py-3 rounded-lg text-gray-600 hover:text-gray-800 border-2 border-gray-300 hover:border-gray-400 transition duration-200 ease-in-out font-medium"
                >
                  Cancelar
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.05, boxShadow: "0 10px 15px -3px rgba(59, 130, 246, 0.3)" }}
                  whileTap={{ scale: 0.95 }}
                  type="submit"
                  className={`px-5 py-3 rounded-lg text-white transition duration-200 ease-in-out font-medium flex items-center justify-center min-w-[100px] ${
                    status === 'sending'
                      ? 'bg-gray-400 cursor-not-allowed'
                      : 'bg-gradient-to-r from-cyan-600 to-teal-500 hover:from-cyan-700 hover:to-teal-600 shadow-md'
                  }`}
                  disabled={status === 'sending'}
                >
                  {status === 'sending' ? (
                    <>
                      <Loader className="h-5 w-5 mr-2 animate-spin" />
                      Enviando...
                    </>
                  ) : (
                    <>
                      <Send className="h-5 w-5 mr-2" />
                      Enviar
                    </>
                  )}
                </motion.button>
              </div>
            </form>

            <AnimatePresence>
              {status === 'success' && (
                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  className="mt-4 p-4 rounded-lg bg-green-50 border border-green-200 text-green-700 flex items-center"
                >
                  <CheckCircle className="h-5 w-5 mr-2 text-green-500" />
                  <span>¡Mensaje enviado con éxito! Nos pondremos en contacto contigo pronto.</span>
                </motion.div>
              )}
              {status === 'error' && (
                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  className="mt-4 p-4 rounded-lg bg-red-50 border border-red-200 text-red-700 flex items-center"
                >
                  <AlertCircle className="h-5 w-5 mr-2 text-red-500" />
                  <span>Error al enviar el mensaje. Por favor, inténtalo de nuevo más tarde.</span>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default ContactForm;