import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from '../ui/dialog';
import React, { useState, useEffect, useRef } from 'react';
import { Input } from '../ui/input';

import { Card, CardHeader, CardContent } from '../ui/card';
import { ScrollArea } from '../ui/scroll-area';
import { PlusCircle, FileDown, FileText, Trash2, Sheet, LucideFileType, LucideFileArchive, LucideFileType2, File, Copy, Edit, Type, FileType, Keyboard } from 'lucide-react';
import Modal from '../ui/ModalUI'; // Asegúrate de importar el componente Modal
interface Template {
  id: number;
  nombre: string;
  descripcion: string;
  fecha_creacion: string;
  fecha_modificacion: string;
}

interface TemplateSelectionProps {
  onTemplateSelected: (selection: 
    { type: 'new'; templateName: string; templateDescription: string } | 
    { type: 'template'; templateName: string; templateType: 'SIMAGI' | 'custom' }
  ) => void;
}

const ErrorBox = ({ message }: { message: string }) => (
  <div className="bg-red-100 border mt-3 border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
    <strong className="font-bold mr-2">Error:</strong>
    <span className="block sm:inline">{message}</span>
  </div>
);
const TemplateSelectionInterface: React.FC<TemplateSelectionProps> = ({ onTemplateSelected }) => {
  const [selectedSimagiTemplate, setSelectedSimagiTemplate] = useState<Template | null>(null);
  const [selectedCustomTemplate, setSelectedCustomTemplate] = useState<Template | null>(null);
  const [simagiTemplates, setSimagiTemplates] = useState<Template[]>([]);
  const [customTemplates, setCustomTemplates] = useState<Template[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [templateToDelete, setTemplateToDelete] = useState<Template | null>(null);
   // Correct state variables for SIMAGI template copying
   const [isSaveAsOpen, setIsSaveAsOpen] = useState(false);
   const [newTemplateName, setNewTemplateName] = useState('');
   const [newTemplateDescription, setNewTemplateDescription] = useState('');
   const [error, setError] = useState<string | null>(null);
   const [saveAsMode, setSaveAsMode] = useState<'new' | 'simagi'>('new');
   const tableRef = useRef<HTMLDivElement>(null);
   const [isRenameDialogOpen, setIsRenameDialogOpen] = useState(false);
   const [templateToRename, setTemplateToRename] = useState<Template | null>(null);
   const [newName, setNewName] = useState('');
 

  useEffect(() => {
    const getTemplates = async () => {
      const fetchedSimagiTemplates = await fetchTemplates('SIMAGI');
      setSimagiTemplates(fetchedSimagiTemplates);
      const fetchedCustomTemplates = await fetchTemplates('custom');
      setCustomTemplates(fetchedCustomTemplates);
    };
    getTemplates();
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSaveAsClose = () => {
    setIsSaveAsOpen(false);
    setNewTemplateName('');
    setNewTemplateDescription('');
    setError(null);
  };




  
  const handleClickOutside = (event: MouseEvent) => {
    if (tableRef.current && !tableRef.current.contains(event.target as Node)) {
     // setSelectedCustomTemplate(null);
    }
  };

  const handleCopySimagiTemplate = async () => {
    try {
      if (!selectedSimagiTemplate) {
        throw new Error('No SIMAGI template selected');
      }

      if (!newTemplateName.trim()) {
        setError('El nombre de la plantilla no puede estar vacío');
        return;
      }

      const copyData = {
        originalName: selectedSimagiTemplate.nombre,
        newName: newTemplateName,
        newDescription: newTemplateDescription
      };

      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/copy_simagi_to_custom.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(copyData),
      });

      const result = await response.json();

      if (result.success) {
        console.log('Plantilla SIMAGI copiada como nueva plantilla custom:', result);
        handleSaveAsClose();
        // Refresh the custom templates list
        const fetchedCustomTemplates = await fetchTemplates('custom');
        setCustomTemplates(fetchedCustomTemplates);
        // Load the newly created custom template
        onTemplateSelected({ 
          type: 'template', 
          templateName: newTemplateName,
          templateType: 'custom'
        });
      } else {
        if (result.error === 'Template name already exists') {
          setError('Ya existe este nombre de archivo');
        } else {
          throw new Error(result.error || 'Error al copiar la plantilla SIMAGI como nueva plantilla');
        }
      }
    } catch (error) {
      console.error('Error:', error);
      setError(error instanceof Error ? error.message : 'An unknown error occurred');
    }
  };

  const saveNewTemplate = async (name: string, description: string) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/save_new_template.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, description }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();

      if (result.success) {
        return { success: true };
      } else {
        return { success: false, error: result.error || 'Failed to save new template' };
      }
    } catch (error) {
      console.error('Error saving new template:', error);
      return { success: false, error: 'Error saving new template' };
    }
  };



  const handleSaveAs = async () => {
    try {
      if (!newTemplateName.trim()) {
        setError('El nombre de la plantilla no puede estar vacío');
        return;
      }

      if (saveAsMode === 'new') {
        // Guardar nueva plantilla en la base de datos
        const result = await saveNewTemplate(newTemplateName, newTemplateDescription);
        if (result.success) {
          console.log('Nueva plantilla guardada:', result);
          const fetchedCustomTemplates = await fetchTemplates('custom');
          setCustomTemplates(fetchedCustomTemplates);
          onTemplateSelected({
            type: 'new',
            templateName: newTemplateName,
            templateDescription: newTemplateDescription
          });
        } else {
          throw new Error(result.error || 'Error al guardar la nueva plantilla');
        }
      } else if (saveAsMode === 'simagi' && selectedSimagiTemplate) {
        // Logic for copying SIMAGI template
        const copyData = {
          originalName: selectedSimagiTemplate.nombre,
          newName: newTemplateName,
          newDescription: newTemplateDescription
        };

        const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/copy_simagi_to_custom.php`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(copyData),
        });

        const result = await response.json();

        if (result.success) {
          console.log('Plantilla SIMAGI copiada como nueva plantilla custom:', result);
          const fetchedCustomTemplates = await fetchTemplates('custom');
          setCustomTemplates(fetchedCustomTemplates);
          onTemplateSelected({ 
            type: 'template', 
            templateName: newTemplateName,
            templateType: 'custom'
          });
        } else {
          if (result.error === 'Template name already exists') {
            setError('Ya existe este nombre de archivo');
            return;
          } else {
            throw new Error(result.error || 'Error al copiar la plantilla SIMAGI como nueva plantilla');
          }
        }
      }

      handleSaveAsClose();
    } catch (error) {
      console.error('Error:', error);
      setError(error instanceof Error ? error.message : 'An unknown error occurred');
    }
  };

  const fetchTemplates = async (type: 'SIMAGI' | 'custom'): Promise<Template[]> => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/get_plantilla.php?type=2&tipoPlantilla=${type}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (Array.isArray(data)) {
        return data.map((item: any) => ({
          id: parseInt(item.id),
          nombre: item.nombre,
          descripcion: item.descripcion,
          fecha_creacion: item.fecha_creacion,
          fecha_modificacion: item.fecha_modificacion
        }));
      } else {
        throw new Error(data.error || 'Unexpected response format');
      }
    } catch (error) {
      console.error(`Error fetching ${type} templates:`, error);
      return [];
    }
  };



  const handleRename = (template: Template) => {
    setTemplateToRename(template);
    setNewName(template.nombre);
    setIsRenameDialogOpen(true);
  };

  const closeRenameDialog = () => {
    setIsRenameDialogOpen(false);
    setTemplateToRename(null);
    setNewName('');
  };

  const handleRenameConfirm = async () => {
    if (!templateToRename || !newName.trim()) return;

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/rename_plantilla.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: templateToRename.id, newName: newName }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      if (result.success) {
        setCustomTemplates(customTemplates.map(template => 
          template.id === templateToRename.id ? { ...template, nombre: newName } : template
        ));
        if (selectedCustomTemplate?.id === templateToRename.id) {
          setSelectedCustomTemplate({ ...selectedCustomTemplate, nombre: newName });
        }
        closeRenameDialog();
      } else {
        throw new Error(result.error || 'Failed to rename template');
      }
    } catch (error) {
      console.error('Error renaming template:', error);
      setError(error instanceof Error ? error.message : 'An unknown error occurred');
    }
  };

  const handleCopy = async (template: Template) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/copy_plantilla.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: template.id }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      if (result.success) {
        const fetchedCustomTemplates = await fetchTemplates('custom');
        setCustomTemplates(fetchedCustomTemplates);
      } else {
        throw new Error(result.error || 'Failed to copy template');
      }
    } catch (error) {
      console.error('Error copying template:', error);
      setError(error instanceof Error ? error.message : 'An unknown error occurred');
    }
  };

  const handleEdit = (template: Template) => {
    onTemplateSelected({ 
      type: 'template', 
      templateName: template.nombre,
      templateType: 'custom'
    });
  };



  const handleNewProject = async () => {
    setSaveAsMode('new');

    // Crear fecha actual en zona horaria local
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Formatear la fecha a YYYYMMDD
    const formattedDate = 
        String(today.getDate()).padStart(2, '0') +
        String(today.getMonth() + 1).padStart(2, '0') +
        today.getFullYear();

    let templateName = `Nueva Plantilla-${formattedDate}`;

    // Verificar si existe una plantilla con el mismo nombre
    let existingTemplateNames = [...simagiTemplates.map(t => t.nombre), ...customTemplates.map(t => t.nombre)];
    let suffix = 1;

    while (existingTemplateNames.includes(templateName)) {
        templateName = `Nueva Plantilla-${formattedDate} (${suffix})`;
        suffix++;
    }

    setNewTemplateName(templateName);
    setNewTemplateDescription('');
    setIsSaveAsOpen(true);
};



  const handleLoadSimagiTemplate = () => {
    if (selectedSimagiTemplate) {
      setSaveAsMode('simagi');
      setNewTemplateName(selectedSimagiTemplate.nombre + ' (Copia)');
      setNewTemplateDescription('');
      setIsSaveAsOpen(true);
    }
  };

  const handleLoadCustomTemplate = () => {
    if (selectedCustomTemplate) {
      onTemplateSelected({ 
        type: 'template', 
        templateName: selectedCustomTemplate.nombre,
        templateType: 'custom'
      });
    }
  };

  const openDeleteModal = (template: Template) => {
    setTemplateToDelete(template);
    setIsModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsModalOpen(false);
    setTemplateToDelete(null);
  };

  const handleDeleteTemplate = async () => {
    if (!templateToDelete) return;

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/delete_plantilla.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: templateToDelete.id }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      if (result.success) {
        setCustomTemplates(customTemplates.filter(template => template.id !== templateToDelete.id));
        if (selectedCustomTemplate?.id === templateToDelete.id) {
          setSelectedCustomTemplate(null);
        }
        closeDeleteModal();
      } else {
        throw new Error(result.error || 'Failed to delete template');
      }
    } catch (error) {
      console.error('Error deleting template:', error);
      // Here you might want to show an error message to the user
    }
  };

  return (
    <Card className="w-full mt-6 border-none shadow-lg rounded-lg bg-white">
      <CardContent className="flex flex-col space-y-6 p-6">
        <div className="flex items-center space-x-6">
          <button
            onClick={handleNewProject}
            className="bg-teal-500 p-2 flex hover:bg-teal-600 transition-colors text-white"
            
          >
            <File className="mr-2 h-5 w-5" />
            Nueva plantilla en blanco
          </button>

          <div className="flex items-center pl-12 space-x-2">
            <button
              onClick={handleLoadSimagiTemplate}
              className="bg-teal-500 p-2 flex hover:bg-teal-600 transition-colors text-white"
              
              disabled={!selectedSimagiTemplate}
            >
              <FileDown className="mr-2 h-5 w-5" />
              Crear desde plantilla SIMAGI
            </button>
            
            <select
              value={selectedSimagiTemplate?.nombre ?? ''}
              onChange={(e) => {
                const template = simagiTemplates.find(t => t.nombre === e.target.value);
                setSelectedSimagiTemplate(template || null);
              }}
              className="border rounded-md p-2"
            >
              <option value="">Seleccionar plantilla SIMAGI</option>
              {simagiTemplates.map((template) => (
                <option key={template.id} value={template.nombre}>
                  {template.nombre}
                </option>
              ))}
            </select>
          </div>
        </div>
        
        <div className="w-full">
          <h3 className="text-lg font-semibold mb-2">Mis Plantillas</h3>
          <Card className="border border-gray-200">
            <ScrollArea className="h-48 rounded-md">
              <div className="p-2" ref={tableRef}>
                <table className="w-full ">
                  <thead>
                    <tr className="text-left text-sm font-medium text-gray-500">
                      <th className="p-2">Nombre</th>
                      <th className="p-2">Descripción</th>
                      <th className="p-2">Creado</th>
                      <th className="p-2">Modificado</th>
                      <th className="p-2">Acciones</th>
                    </tr>
                  </thead>
                  <tbody className='cursor-pointer'>
                    {customTemplates.map((template) => (
                      <tr
                        key={template.id}
                        className={`hover:bg-gray-100 ${
                          selectedCustomTemplate?.id === template.id ? 'bg-teal-100 hover:bg-teal-200' : ''
                        }`}
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedCustomTemplate(template);
                        }}                      >
                        <td className="p-2">
                          <div className="flex items-center">
                            <FileText className="mr-2 h-4 w-4 text-gray-500" />
                            {template.nombre}
                          </div>
                        </td>
                        <td className="p-2">{template.descripcion}</td>
                        <td className="p-2">{new Date(template.fecha_creacion).toLocaleString()}</td>
                        <td className="p-2">{new Date(template.fecha_modificacion).toLocaleString()}</td>
                        <td className="p-2">
                          <div className="flex space-x-2">
                            <button 
                               
                               
                              onClick={(e) => {
                                e.stopPropagation();
                                handleEdit(template);
                              }}
                            >
                              <Edit className="h-5 w-5 text-blue-500" />
                            </button>
                            <button 
                               
                               
                              onClick={(e) => {
                                e.stopPropagation();
                                handleCopy(template);
                              }}
                            >
                              <Copy className="h-5 w-5 text-green-500" />
                            </button>
                            <button
                              
                              
                              onClick={(e) => {
                                e.stopPropagation();
                                handleRename(template);
                              }}
                            >
                              <Keyboard className="h-5 w-5 text-yellow-500" />
                            </button>
                            <button
                              
                              
                              onClick={(e) => {
                                e.stopPropagation();
                                openDeleteModal(template);
                              }}
                            >
                              <Trash2 className="h-5 w-5 text-red-500" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              
            </ScrollArea>
          </Card>
          <div className="mt-4">
            <button
              onClick={handleLoadCustomTemplate}
              className="bg-teal-500 p-2 flex hover:bg-teal-600 transition-colors text-white"
              
              disabled={!selectedCustomTemplate}
            >
              <FileDown className="mr-2 h-5 w-5" />
              Cargar Mi Plantilla
            </button>
          </div>
        </div>


        


        <Dialog width="500px" isOpen={isSaveAsOpen} onClose={handleSaveAsClose}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>
                {saveAsMode === 'new' ? 'Crear nueva plantilla' : 'Guardar plantilla como...'}
              </DialogTitle>
            </DialogHeader>
            <Input
              placeholder="Nombre de la nueva plantilla"
              value={newTemplateName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewTemplateName(e.target.value)}
              className='mb-2'
            />
            <Input
              placeholder="Descripción de la nueva plantilla"
              value={newTemplateDescription}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewTemplateDescription(e.target.value)}
            />
            {error && <ErrorBox message={error} />}
            <DialogFooter>
              <button onClick={handleSaveAsClose} className='border border-black p-2' >Cancelar</button>
              <button onClick={handleSaveAs} className="bg-teal-500 ml-3 p-2 border text-white hover:bg-teal-800" >
                {saveAsMode === 'new' ? 'Crear' : 'Crear nueva plantilla'}
              </button>
            </DialogFooter>
          </DialogContent>
        </Dialog>





        <Modal
          isOpen={isModalOpen}
          onClose={closeDeleteModal}
          onConfirm={handleDeleteTemplate}
          title="Confirmar eliminación"
          message={`¿Estás seguro de que quieres eliminar la plantilla "${templateToDelete?.nombre}"? Esta acción no se puede deshacer.`}
        />
           <Dialog width="300px" isOpen={isRenameDialogOpen} onClose={closeRenameDialog}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Renombrar plantilla</DialogTitle>
            </DialogHeader>
            <Input
              placeholder="Nuevo nombre de la plantilla"
              value={newName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewName(e.target.value)}
              className='mb-2'
            />
            {error && <ErrorBox message={error} />}
            <DialogFooter>
              <button onClick={closeRenameDialog} >Cancelar</button>
              <button onClick={handleRenameConfirm} className="bg-teal-500 ml-3 border text-white hover:bg-teal-800" >
                Renombrar
              </button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </CardContent>
    </Card>
  );
};
export default TemplateSelectionInterface;
