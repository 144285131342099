import React from 'react';
import { Info, CheckCircle, FileText, BookOpen, Database } from 'lucide-react';

import imgTeam from '../img/team.jpg';


// Componente para el modal de inicio con imagen
const HomeModalContent = () => {
  return (
    <div className="space-y-6">
      <div className="text-center">
        <h2 className="text-2xl font-bold text-cyan-600 mb-2">
          Bienvenido a SIMAGI
        </h2>
        <p className="text-gray-600 mb-6"> 
          Sistema Maestro de Gestión Integrado
        </p>
        
        {/* Imagen principal - Usando la imagen del carrusel */}
        <div className="mx-auto my-6 rounded-lg overflow-hidden shadow-lg border border-gray-200">
          <img 
            src={imgTeam} 
            alt="SIMAGI Dashboard" 
            className="w-full h-full object-cover"
            />
         
        </div>
      </div>
      
      <div className="bg-cyan-50 p-5 rounded-lg border border-cyan-100">
        <h3 className="font-semibold text-cyan-800 mb-3 flex items-center">
          <Info className="h-5 w-5 text-cyan-600 mr-2" />
          Características principales
        </h3>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="flex items-start">
            <div className="flex-shrink-0 bg-cyan-100 rounded-full p-2 mr-3">
              <FileText className="h-5 w-5 text-cyan-600" />
            </div>
            <div>
              <h4 className="font-medium text-gray-800">Control Documental</h4>
              <p className="text-sm text-gray-600">Gestión centralizada de toda la documentación</p>
            </div>
          </div>
          
          <div className="flex items-start">
            <div className="flex-shrink-0 bg-cyan-100 rounded-full p-2 mr-3">
              <Database className="h-5 w-5 text-cyan-600" />
            </div>
            <div>
              <h4 className="font-medium text-gray-800">Planificación</h4>
              <p className="text-sm text-gray-600">Herramientas para planificar proyectos con precisión</p>
            </div>
          </div>
          
          <div className="flex items-start">
            <div className="flex-shrink-0 bg-cyan-100 rounded-full p-2 mr-3">
              <BookOpen className="h-5 w-5 text-cyan-600" />
            </div>
            <div>
              <h4 className="font-medium text-gray-800">Biblioteca Normativa</h4>
              <p className="text-sm text-gray-600">Acceso a normativas actualizadas</p>
            </div>
          </div>
          
          <div className="flex items-start">
            <div className="flex-shrink-0 bg-cyan-100 rounded-full p-2 mr-3">
              <Info className="h-5 w-5 text-cyan-600" />
            </div>
            <div>
              <h4 className="font-medium text-gray-800">Inteligencia Artificial</h4>
              <p className="text-sm text-gray-600">IA integrada para agilizar procesos</p>
            </div>
          </div>
        </div>
      </div>
      


    </div>
  );
};

export default HomeModalContent;