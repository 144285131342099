import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { Gantt, Task, ViewMode, EventOption, StylingOption, DisplayOption } from 'gantt-task-react-lite';
import "gantt-task-react-lite/dist/index.css";
import { ZoomIn, ZoomOut, Maximize, Minimize, Layers, ChevronRight, ChevronDown, Shrink, Expand } from 'lucide-react';
import Slider from 'rc-slider';
import { group } from 'console';

// Función para convertir fecha de string a Date en la zona horaria local y establecer hora a medianoche
const parseLocalDate = (dateString: string): Date => {
  const [year, month, day] = dateString? dateString.split('-').map(Number) : [0, 0 ,0];

  const localDate = new Date(year, month - 1, day); // Mes en JavaScript es 0-11
  localDate.setHours(0, 0, 0, 0); // Configura hora a medianoche
  return localDate;
};


interface TaskInput {
  id: number;
  name: string;
  responsible: User[] | string;
  progress: number;
  start: string;
  end: string;
  dependsOn: { groupId: number; taskId: number }[];
  color?: string; // Añadido para el color de la tarea
  subtasks?: SubTask[];
  enabled?: boolean;
  isTramitacion?: boolean;
}

// Definir interfaces basadas en la estructura de datos proporcionada
interface SubTask {
  id: number;
  name: string;
  responsible: User[] | string;
  progress: number;
  start: string;
  end: string;
  duration: number;
  organism: string;
  dependsOn: { groupId: number; taskId: number; subtaskId?: number }[];
  enabled: boolean;
}


interface User {
  id: number;
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  is_admin: boolean;
  is_active: boolean;
  departamento: string;
}




interface Group {
  agrupador: string;
  tasks: TaskInput[];
  subprocess: string; // Añadido para el nombre del subproceso
  color?: string; // Añadido para el color del grupo/subproceso
}

interface TasksState {
  groups: Group[];
  
}

interface GanttChartViewProps {
  tasks: TasksState;
  colors?: { [key: string]: string };  // Nuevo prop para colores personalizados
}

const CustomHeader: React.FC<{
  headerHeight: number;
  rowWidth: string;
  fontFamily: string;
  fontSize: string;
}> = ({ headerHeight, rowWidth, fontFamily, fontSize }) => {
  
  return (
    <div
      className="gantt-task-list-header"
      style={{
        height: headerHeight,
        fontFamily: fontFamily,
        fontSize: fontSize,
      }}
    >
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr style={{ height: `${headerHeight}px` }}>
           {/* Columna de posición */}
           <th style={{
              width: '40px',  // Ancho de la columna de posición
              textAlign: 'right',
              paddingLeft: '5px',
              paddingRight: '5px',
              borderBottom: '2px solid #e0e0e0',
              fontWeight: 'bold',
            }}>
              #
            </th>
            {/* Columna de agrupador/tarea con espacio extra para el chevron */}
            <th style={{
              width: `calc(${rowWidth} - 340px)`, // Ajuste para espacio extra del chevron
              textAlign: 'center',
              paddingLeft: '5px',
              paddingRight: '20px', // Alineación igual que en las celdas
              borderBottom: '2px solid #e0e0e0',
              fontWeight: 'bold',
            }}>
              Agrupador/Tarea
            </th>
            {/* Columna de dependencia */}
            <th style={{
              width: '120px',
              textAlign: 'left',
              paddingLeft: '5px',
              borderBottom: '2px solid #e0e0e0',
              fontWeight: 'bold',
            }}>
              Dependencia
            </th>
            {/* Columna desde */}
            <th style={{
              width: '80px',
              textAlign: 'left',
              paddingLeft: '5px',
              borderBottom: '2px solid #e0e0e0',
              fontWeight: 'bold',
            }}>
              Desde
            </th>
            {/* Columna hasta */}
            <th style={{
              width: '120px',
              textAlign: 'left',
              paddingLeft: '25px',
              borderBottom: '2px solid #e0e0e0',
              fontWeight: 'bold',
            }}>
              Hasta
            </th>
          </tr>
        </thead>
      </table>
    </div>
  );
};



interface DateSetup {
  dates: Date[];
  // Añade otras propiedades si son necesarias
}

const CustomTaskListTable: React.FC<{
  rowHeight: number;
  rowWidth: string;
  fontFamily: string;
  fontSize: string;
  locale: string;
  tasks: Task[];
  selectedTaskId: string;
  setSelectedTask: (taskId: string) => void;
  onExpanderClick: (task: Task) => void;
}> = ({
  rowHeight,
  rowWidth,
  fontFamily,
  fontSize,
  locale,
  tasks,
  selectedTaskId,
  setSelectedTask,
  onExpanderClick,
}) => {
  const [hoveredTaskId, setHoveredTaskId] = useState<string | null>(null);

  // Función para obtener el número de tarea dentro de su grupo
  const getTaskNumberInGroup = (task: Task): number => {
    if (!task.project?.startsWith('group-')) return 0;
    
    const groupId = task.project;
    let currentNumber = 0;
    
    // Recorrer todas las tareas hasta encontrar la actual
    for (let i = 0; i < tasks.length; i++) {
      const currentTask = tasks[i];
      
      // Si encontramos la tarea que estamos buscando, devolver el número actual
      if (currentTask.id === task.id) {
        return currentNumber + 1;
      }
      
      // Incrementar el contador solo para tareas regulares del mismo grupo
      if (currentTask.project === groupId && 
          !currentTask.type?.includes('project') && 
          !currentTask.project?.includes('-')) {
        currentNumber++;
      }
    }
    return currentNumber;
  };

  // Función para generar la numeración jerárquica
  const getTaskNumber = (task: Task, index: number): string => {
    // Si es un grupo principal (agrupador), no mostrar número
    if (task.type === 'project' && !task.project) {
      return '';
    }
  
    // Para subtareas de TRAMITACIÓN
    if (task.project && task.project.includes('-')) {
      const [groupId, taskId, subtaskId] = task.id.split('-').map(Number);
      const parentTask = tasks.find(t => t.id === task.project);
      if (parentTask && parentTask.name === 'TRAMITACIÓN') {
        // Obtener el número de la tarea TRAMITACIÓN dentro de su grupo
        return `${taskId}.${subtaskId}`;
      }
    }
  
    // Para tareas regulares dentro de un grupo (incluyendo TRAMITACIÓN)
    if (task.project) {
      const [_, taskId] = task.id.split('-').map(Number);
      return `${taskId}`;
    }
  
    return '';
  };
  
  // Función simplificada para formatear dependencias
  const formatDependencies = (dependencies: string[] | undefined): string => {
    if (!dependencies || dependencies.length === 0) return '';
    
    return dependencies.map(dep => {
      const parts = dep.split('-');
      
      // Si es una subtarea (tiene 3 partes)
      if (parts.length === 3) {
        return `${parts[1]}.${parts[2]}`;
      }
      
      // Si es una tarea regular (tiene 2 partes)
      if (parts.length === 2) {
        return parts[1];
      }
      
      return '';
    }).filter(Boolean).join(', ');
  };

  const formatDate = (date: Date): string => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // Función para generar colores para los agrupadores
  const getGroupColor = (index: number): string => {
    const colors = ['#FFD700', '#98FB98', '#87CEFA', '#FFA07A', '#DDA0DD'];
    return colors[index % colors.length];
  };

  const darkenColor = (color: string, amount: number = 0.2): string => {
    let [r, g, b] = color.match(/\w\w/g)?.map((c) => parseInt(c, 16)) || [0, 0, 0];
    r = Math.max(0, Math.min(255, r - Math.round(255 * amount)));
    g = Math.max(0, Math.min(255, g - Math.round(255 * amount)));
    b = Math.max(0, Math.min(255, b - Math.round(255 * amount)));
    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
  };

  const lightenColor = (color: string, amount: number = 0.2): string => {
    let [r, g, b] = color.match(/\w\w/g)?.map((c) => parseInt(c, 16)) || [0, 0, 0];
    r = Math.max(0, Math.min(255, r + Math.round(255 * amount)));
    g = Math.max(0, Math.min(255, g + Math.round(255 * amount)));
    b = Math.max(0, Math.min(255, b + Math.round(255 * amount)));
    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
  };


  const getTaskBackgroundColor = (task: Task, isHovered: boolean, isSelected: boolean): string => {
    let color;
    
    if (task.name === 'TRAMITACIÓN') {
      color = '#FFFFFF';
    } else if (task.type === 'project') {
      color = task.styles?.backgroundColor || getGroupColor(parseInt(task.id.split('-')[1]));
    } else {
      color = '#FFFFFF';
    }

    if (task.type !== 'project' && task.name !== 'TRAMITACIÓN') {
      if (isSelected) {
        return darkenColor(lightenColor(color, 0.2), 0.1);
      } else if (isHovered) {
        return darkenColor(lightenColor(color, 0.3), 0.1);
      }
    }

    return color;
  };

  return (
    <div className="gantt-task-list-wrapper" style={{ fontFamily, fontSize }}>
      {tasks.map((task, index) => {
        const isHovered = hoveredTaskId === task.id;
        const isSelected = selectedTaskId === task.id;
        const isTramitacion = task.name === 'TRAMITACIÓN';
        const taskNumber = getTaskNumber(task, index);
        const isGroupTask = task.type === 'project' && !task.project;
        
        let paddingLeft = '5px';
        if (task.project?.includes('-') || task.name === 'TRAMITACIÓN') {
          paddingLeft = '45px';
        } else if (task.type !== 'project') {
          paddingLeft = '25px';
        }

        return (
          <div
            key={task.id}
            className="gantt-task-list-row"
            style={{
              height: rowHeight,
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid #e0e0e0',
              backgroundColor: getTaskBackgroundColor(task, isHovered, isSelected),
              transition: 'background-color 0.3s ease',
              cursor: 'pointer',
            }}
            onClick={(e) => {
              const clickedExpander = (e.target as HTMLElement).closest('.gantt-task-list-expander');
              if (isTramitacion && clickedExpander) {
                onExpanderClick(task);
              } else {
                setSelectedTask(task.id);
                if (task.type === 'project' && !isTramitacion) {
                  onExpanderClick(task);
                }
              }
            }}
            onMouseEnter={() => setHoveredTaskId(task.id)}
            onMouseLeave={() => setHoveredTaskId(null)}
          >
            <div
              className="gantt-task-list-cell"
              style={{
                width: rowWidth,
                display: 'flex',
                alignItems: 'center',
                paddingLeft,
                paddingRight: '20px',
                position: 'relative',
                overflow: 'hidden',
              }}
            >
              {(task.type === 'project' || task.name === 'TRAMITACIÓN') && (
                <div
                  className="gantt-task-list-expander"
                  style={{ 
                    marginRight: '5px', 
                    cursor: 'pointer',
                    flexShrink: 0
                  }}
                >
                  {task.hideChildren ? <ChevronRight size={16} /> : <ChevronDown size={16} />}
                </div>
              )}
              <div
                className="gantt-task-list-cell"
                style={{
                  width: '40px',
                  textAlign: 'left',
                  fontWeight: 'bold',
                  paddingLeft: '5px',
                  paddingRight: '5px',
                  flexShrink: 0
                }}
              >
                {taskNumber}
              </div>
              <div
                style={{ 
                  fontWeight: isGroupTask ? 'bold' : 'normal',
                  marginLeft: isGroupTask ? '0' : '10px',
                  whiteSpace: isGroupTask ? 'nowrap' : 'normal',
                  overflow: 'hidden',
                  textOverflow: isGroupTask ? 'ellipsis' : 'clip',
                  flex: 1,
                  display: isGroupTask ? 'block' : '-webkit-box',
                  WebkitLineClamp: isGroupTask ? 1 : 2,
                  WebkitBoxOrient: 'vertical',
                  lineHeight: isGroupTask ? `${rowHeight}px` : '1.2em',
                  maxHeight: isGroupTask ? rowHeight : (rowHeight - 10),
                }}
                title={task.name}
              >
                {task.name}
              </div>
            </div>
            <div 
              className="gantt-task-list-cell" 
              style={{ 
                width: '120px',
                flexShrink: 0,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
            >
              {formatDependencies(task.dependencies)}
            </div>
            <div 
              className="gantt-task-list-cell" 
              style={{ 
                width: '120px',
                flexShrink: 0,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
            >
              {formatDate(task.start)}
            </div>
            <div 
              className="gantt-task-list-cell" 
              style={{ 
                width: '120px',
                flexShrink: 0,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
            >
              {formatDate(task.end)}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const CustomTimelineHeader: React.FC<{
  headerHeight: number;
  rowWidth: string;
  fontFamily: string;
  fontSize: string;
  tickWidth: number;
  startDate: Date;
  endDate: Date;
  dateSetup: DateSetup;
  locale: string;
  headerColor: string;
}> = ({
  headerHeight,
  fontFamily,
  fontSize,
  tickWidth,
  startDate,
  endDate,
  dateSetup,
  locale,
}) => {

  const getWeekNumber = (date: Date): number => {
    const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    const dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    return Math.ceil((((d.getTime() - yearStart.getTime()) / 86400000) + 1)/7);
  };
  
  const getWeekNumberLabel = (date: Date) => {
    const weekNumber = getWeekNumber(date);
    return `${weekNumber}`;
  };

  return (
    <div
      className="gantt-timeline-header"
      style={{
        fontFamily,
        fontSize,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#f5f5f5',
        borderBottom: '1px solid #e0e0e0',
      }}
    >
      {/* Semanas */}
      <div style={{ display: 'flex', height: headerHeight / 2 }}>
        {dateSetup.dates.map((date: Date, i: number) => (
          <div
            key={date.getTime()}
            style={{
              width: tickWidth,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRight: '1px solid #e0e0e0',
              fontWeight: 'bold',
            }}
          >
            {i === 0 || date.getDay() === 1 ? getWeekNumberLabel(date) : ''}
          </div>
        ))}
      </div>
      {/* Días */}
      <div style={{ display: 'flex', height: headerHeight / 2 }}>
        {dateSetup.dates.map((date: Date) => (
          <div
            key={date.getTime()}
            style={{
              width: tickWidth,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRight: '1px solid #e0e0e0',
              color: date.getDay() === 0 || date.getDay() === 6 ? '#999' : '#000',
            }}
          >
            {date.getDate()}
          </div>
        ))}
      </div>
    </div>
  );
};


const getTodayStyle = () => {
  const todayStart = new Date();
  todayStart.setHours(0, 0, 0, 0);
  const todayEnd = new Date(todayStart);
  todayEnd.setDate(todayEnd.getDate() + 1);

  return {
    backgroundColor: 'rgba(255, 0, 0, 0.3)',
    width: '2px',
    height: '100%',
    position: 'absolute' as 'absolute',
    zIndex: 1,
    left: 'calc(50% - 1px)',
  };
};


const GanttChartView: React.FC<GanttChartViewProps> = ({ tasks, colors = {} }) => {
  const [zoom, setZoom] = useState(100);
    const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.Week);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
    const [isGrouped, setIsGrouped] = useState(true);


       // Inicializa expandedGroups con todos los grupos expandidos desde el inicio
  const initialExpandedGroups = useMemo(() => {
    return new Set(tasks.groups.map((_, index) => `group-${index}`));
  }, [tasks.groups]);
  
  const [expandedGroups, setExpandedGroups] = useState<Set<string>>(() => {
    // Inicializar con todos los grupos expandidos
    return new Set(tasks.groups.map((_, index) => `${index}`));
  });


    //const [expandedGroups, setExpandedGroups] = useState<Set<string>>(new Set());
    const ganttRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const [selectedTaskId, setSelectedTaskId] = useState<string>('');
    const [expandedSubgroups, setExpandedSubgroups] = useState<Set<string>>(new Set());


  // Update the date parsing function to handle invalid dates
  const parseLocalDate = (dateString: string): Date => {
    if (!dateString) {
      // Return a default date if dateString is undefined or empty
      return new Date();
    }

    const [year, month, day] = dateString.split('-').map(Number);
    
    // Validate date components
    if (!year || !month || !day || isNaN(year) || isNaN(month) || isNaN(day)) {
      console.warn(`Invalid date string: ${dateString}, using current date`);
      return new Date();
    }

    const localDate = new Date(year, month - 1, day);
    
    // Check if date is valid
    if (isNaN(localDate.getTime())) {
      console.warn(`Invalid date created from: ${dateString}, using current date`);
      return new Date();
    }

    return localDate;
  };
    

    const darkenColor = (color: string, amount: number = 0.2): string => {
      // Convertir el color hexadecimal a RGB
      let [r, g, b] = color.match(/\w\w/g)?.map((c) => parseInt(c, 16)) || [0, 0, 0];
      
      // Oscurecer los valores RGB
      r = Math.max(0, Math.min(255, r - Math.round(255 * amount)));
      g = Math.max(0, Math.min(255, g - Math.round(255 * amount)));
      b = Math.max(0, Math.min(255, b - Math.round(255 * amount)));
      
      // Convertir de vuelta a hexadecimal
      return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
    };


    
const ensureMinimumDuration = (start: Date, end: Date): Date => {
  // Si las fechas son iguales o la duración es menor a un día
  const duration = end.getTime() - start.getTime();
  if (duration <= 0) {
    // Crear una nueva fecha de fin que sea el mismo día pero algunas horas después
    const newEnd = new Date(start);
    newEnd.setHours(start.getHours() + 20); // Añadir 8 horas para que sea visible
    return newEnd;
  }
  return end;
};


    const chartData: Task[] = useMemo(() => {
      const processTask = (task: TaskInput, groupId: string, taskIndex: number): Task => {
        let start = parseLocalDate(task.start);
        let end = parseLocalDate(task.end);
               
          // Aplicar la misma lógica de duración mínima
          start.setHours(0, 0, 0, 0);
          end = ensureMinimumDuration(start, end);

              // Ensure minimum duration
              const duration = end.getTime() - start.getTime();
              if (duration < 0) {
                end.setTime(start.getTime() + (24 * 60 * 60 * 1000)); // Set minimum 1 day duration
              }
    
  
        return {
          id: `${groupId}-${taskIndex + 1}`,
          type: task.isTramitacion ? 'project' : 'task',
          name: task.name,
          start,
          end,
          progress: task.progress,
          project: groupId,
          dependencies: task.dependsOn.map(dep => `${groupId}-${dep.taskId}`),
          isDisabled: !task.enabled,
          styles: {
            progressColor: task.color || '#ff9e0d',
            progressSelectedColor: task.color || '#ff9e0d',
            backgroundColor: task.color ? `${task.color}` : undefined,
            backgroundSelectedColor: task.color ? `${task.color}aa` : undefined,
            // Agregar estilos específicos para tareas de duración cero
            ...(duration === 0 && {
              barWrapper: {
                minWidth: '8px',
                width: '8px !important',
                opacity: 0.8
              },
              bar: {
                minWidth: '8px',
                width: '8px !important',
                borderRadius: '4px'
              }
            })
          },
          hideChildren: task.isTramitacion ? expandedSubgroups.has(`${groupId}-${taskIndex + 1}`) : undefined,
        };
      };

      // Función para buscar el color de una tarea por taskId
const findTaskColorById = (taskId: string): string | undefined => {
  const task = tasks.groups
    .flatMap(group => group.tasks)
    .find(task => `${task.id}` === taskId);  // Asegúrate de que `task.id` coincide con el `taskId`

  return task?.color;  // Devuelve el color de la tarea o undefined si no se encuentra
};

const processSubtask = (subtask: SubTask, taskId: string, subtaskIndex: number, groupColor: string): Task => {
  const start = parseLocalDate(subtask.start);
  const end = parseLocalDate(subtask.end);
  const duration = end.getTime() - start.getTime();

  if (duration === 0) {
    const newEnd = new Date(start);
    newEnd.setHours(start.getHours() + 10);
    end.setTime(newEnd.getTime());
  }

  return {
    id: `${taskId}-${subtaskIndex + 1}`,
    type: 'task',
    name: subtask.name,
    start,
    end,
    progress: 99,
    project: taskId,
    dependencies: Array.isArray(subtask.dependsOn)
      ? subtask.dependsOn.map(dep => dep.subtaskId ? `${taskId}-${dep.taskId}-${dep.subtaskId}` : `${taskId}-${dep.taskId}`)
      : [],
    isDisabled: !subtask.enabled,
    styles: {
      progressColor: `${groupColor}88`,
      progressSelectedColor: `${groupColor}99`,
      backgroundColor: `${groupColor}33`, // Aplicar `groupColor` con opacidad para fondo
      backgroundSelectedColor: `${groupColor}66`, // Aplicar `groupColor` con opacidad para fondo seleccionado
      ...(duration === 0 && {
        barWrapper: {
          minWidth: '8px',
          width: '8px !important',
          opacity: 0.8,
        },
        bar: {
          minWidth: '8px',
          width: '8px !important',
          borderRadius: '4px',
        },
      }),
    },
  };
};

  
      const allTasks: Task[] = [];
      
      tasks.groups.forEach((group, groupIndex) => {
        const groupId = `${groupIndex}`;
        const groupColor = group.color || '#0044ff'; // Color del grupo o color predeterminado
        
        // Determinar fechas de inicio y fin del grupo
        const groupStart = new Date(Math.min(...group.tasks.map(task => parseLocalDate(task.start).getTime())));
        const groupEnd = new Date(Math.max(...group.tasks.map(task => parseLocalDate(task.end).getTime())));
        groupStart.setHours(0, 0, 0, 0);
        groupEnd.setHours(23, 59, 0, 0);
      
        // Procesar agrupador como una "tarea" principal
        allTasks.push({
          id: groupId,
          type: 'project',
          name: group.agrupador,
          start: groupStart,
          end: groupEnd,
          progress: 99,
          hideChildren: !expandedGroups.has(groupId),
          styles: {
            progressColor: groupColor,
            progressSelectedColor: darkenColor(groupColor, 0.2),
            backgroundColor: `${groupColor}99`,
            backgroundSelectedColor: `${darkenColor(groupColor, 0.2)}66`,
          },
        });
      
        // Procesar tareas
        group.tasks.forEach((task, taskIndex) => {
          const processedTask = processTask(task, groupId, taskIndex);
          allTasks.push(processedTask);
      
          // Procesar subtareas si existen
          if (task.isTramitacion && task.subtasks) {
            task.subtasks.forEach((subtask, subtaskIndex) => {
              const processedSubtask = processSubtask(subtask, processedTask.id, subtaskIndex, groupColor); // Pasar groupColor
              allTasks.push(processedSubtask);
            });
          }
        });
      });
      
  
      return allTasks;
    }, [tasks, expandedGroups, expandedSubgroups, colors]);
    
    const handleZoomChange = useCallback((value: number | number[]) => {
      if (Array.isArray(value)) {
        setZoom(value[0]);  // Si es un array, usamos el primer valor
      } else {
        setZoom(value);  // Si es un número, lo usamos directamente
      }
    }, []);
    

    const handleViewModeChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
      setViewMode(e.target.value as ViewMode);
    }, []);


   
    const toggleFullScreen = () => {
      if (!document.fullscreenElement) {
        containerRef.current?.requestFullscreen();
      } else {
        document.exitFullscreen();
      }
    };

    
    const handleTaskSelect = (task: Task) => {
      setSelectedTaskId(task.id);
    };
  /*
    const handleExpanderClick = (task: Task) => {
      if (task.type === 'project') {
        toggleGroup(task.id);
      }
    };*/
   
    


    const toggleGrouping = () => {
      setIsGrouped(prev => !prev);
      setExpandedGroups(new Set());
    };

    const toggleGroup = (groupId: string) => {
      setExpandedGroups(prev => {
        const newSet = new Set(prev);
        if (newSet.has(groupId)) {
          newSet.delete(groupId);
        } else {
          newSet.add(groupId);
        }
        return newSet;
      });
    };



    const handleExpanderClick = (task: Task) => {
      if (task.type === 'project') {
        if (task.project) {
          // Es una tarea de TRAMITACIÓN
          setExpandedSubgroups(prev => {
            const newSet = new Set(prev);
            if (newSet.has(task.id)) {
              newSet.delete(task.id);
            } else {
              newSet.add(task.id);
            }
            return newSet;
          });
        } else {
          // Es un grupo principal
          setExpandedGroups(prev => {
            const newSet = new Set(prev);
            if (newSet.has(task.id)) {
              newSet.delete(task.id);
            } else {
              newSet.add(task.id);
            }
            return newSet;
          });
        }
      }
    };

    // We no longer need handleExpanderClick as the expansion is handled by the chevron click

    useEffect(() => {
      const handleFullScreenChange = () => {
        setIsFullScreen(!!document.fullscreenElement);
      };

      document.addEventListener('fullscreenchange', handleFullScreenChange);

      return () => {
        document.removeEventListener('fullscreenchange', handleFullScreenChange);
      };
    }, []);

        
    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
      setIsDragging(true);
      setDragStart({ x: e.clientX, y: e.clientY });
    };

    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
      if (isDragging && ganttRef.current) {
        const dx = e.clientX - dragStart.x;
        const dy = e.clientY - dragStart.y;
        ganttRef.current.scrollLeft -= dx;
        ganttRef.current.scrollTop -= dy;
        setDragStart({ x: e.clientX, y: e.clientY });
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    const handleWheel = useCallback((e: WheelEvent) => {
      if (e.ctrlKey) {
        e.preventDefault();
        const delta = e.deltaY < 0 ? 10 : -10; // Invertimos la dirección para un zoom más intuitivo
        setZoom(prevZoom => {
          const newZoom = Math.min(Math.max(prevZoom + delta, 50), 200);
          return newZoom;
        });
      }
    }, []);
  
    // Cambiamos el useEffect para usar el callback memorizado
    useEffect(() => {
      const ganttElement = ganttRef.current;
      
      const wheelHandler = (e: WheelEvent) => {
        if (e.ctrlKey) {
          e.preventDefault();
          handleWheel(e);
        }
      };
  
      if (ganttElement) {
        // Usamos 'wheel' en lugar de 'mousewheel' para mejor compatibilidad
        ganttElement.addEventListener('wheel', wheelHandler, { passive: false });
      }
  
      return () => {
        if (ganttElement) {
          ganttElement.removeEventListener('wheel', wheelHandler);
        }
      };
    }, [handleWheel]);


    const formatDate = (date: Date): string => {
      return date.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' });
    };

    const getWeekNumber = (date: Date): number => {
      const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
      const dayNum = d.getUTCDay() || 7;
      d.setUTCDate(d.getUTCDate() + 4 - dayNum);
      const yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
      return Math.ceil((((d.getTime() - yearStart.getTime()) / 86400000) + 1)/7);
    };


    const columnWidth = useMemo(() => {
      return viewMode === ViewMode.Month ? 300 * (zoom / 100) : 65 * (zoom / 100);
  }, [zoom, viewMode]);

    const formatTimeTick = (date: Date): string => {
      const formatted = formatDate(date);
      if (viewMode === ViewMode.Week) {
        const weekNumber = getWeekNumber(date);
        return `S${String(weekNumber).padStart(2, '0')} - ${formatted}`;
      }
      return formatted;
    };

    useEffect(() => {
      const style = document.createElement('style');
      style.textContent = `
        .gantt-today-highlight {
          position: absolute;
          top: 0;
          bottom: 0;
          width: 2px !important;
          background-color: rgba(255, 0, 0, 0.5) !important;
          z-index: 1;
        }
      `;
      document.head.appendChild(style);
    
      return () => {
        document.head.removeChild(style);
      };
    }, []);

    const [areAllGroupsExpanded, setAreAllGroupsExpanded] = useState(true);
    const totalTasks = useMemo(() => {
      return tasks.groups.reduce((total, group) => total + group.tasks.length + 1, 0);
  }, [tasks]);


  const toggleAllGroups = useCallback(() => {
    if (areAllGroupsExpanded) {
      // Colapsar todos los grupos
      setExpandedGroups(new Set());
    } else {
      // Expandir todos los grupos
      setExpandedGroups(new Set(tasks.groups.map((_, index) => `${index}`)));
    }
    setAreAllGroupsExpanded(!areAllGroupsExpanded);
  }, [areAllGroupsExpanded, tasks.groups]);



    return (
      <div ref={containerRef} className={`gantt-container flex flex-col ${isFullScreen ? 'fixed inset-0 z-50 bg-white' : ''}`}>
        <div className="flex justify-between items-center mb-4 p-2">
        <div className="flex items-center space-x-4">
        <button onClick={() => handleZoomChange(zoom - 10)}  className="mr-2">
        <ZoomOut className="w-5 h-5 mr-" />
            </button>
            <Slider
    value={zoom}
    min={50}
    max={200}
    step={10}
    onChange={(value) => handleZoomChange(value)}

    className="w-40"
  />
          <button onClick={() => handleZoomChange(zoom + 10)}  className="mr-2">
          <ZoomIn className="w-5 h-5 mr-2" /> 
            </button>
  <div className="ml-10 flex space-x-2">
  <button className='p-2 flex items-center border border-black' onClick={toggleAllGroups}>
  {areAllGroupsExpanded ? <Shrink className="w-4 h-4 mr-2" /> : <Expand className="w-4 h-4 mr-2" />}
  {areAllGroupsExpanded ? 'Colapsar Todos' : 'Expandir Todos'}
</button>

<button className='p-2 flex items-center border border-black' onClick={toggleFullScreen}>
  {isFullScreen ? <Minimize className="w-4 h-4 mr-2" /> : <Maximize className="w-4 h-4 mr-2" />}
  {isFullScreen ? 'Salir de Pantalla Completa' : 'Pantalla Completa'}
</button>

  </div>
</div>
          <select
            value={viewMode}
            onChange={handleViewModeChange}
            className="border p-2 rounded"
          >
            <option value={ViewMode.Day}>Día</option>
            <option value={ViewMode.Week}>Semana</option>
            <option value={ViewMode.Month}>Mes</option>
            <option value={ViewMode.Year}>Año</option>
          </select>
        </div>
        <div 
          ref={ganttRef}
          className="flex-grow overflow-auto"
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
                <div style={{ width: '100%', height: '460px' }}>
                <Gantt
            tasks={chartData}
            viewMode={viewMode}
            locale="es"
            listCellWidth="250px"
            columnWidth={columnWidth}
            ganttHeight={Math.max(isFullScreen ? window.innerHeight - 150 : 390, totalTasks)}
            headerHeight={50}
            rowHeight={40}
            barCornerRadius={5}
            projectProgressColor="#0044ff"
            barProgressColor="#ff9e0d"
            barProgressSelectedColor="#ff9e0d"
            barBackgroundColor="#b8c2cc"
            barBackgroundSelectedColor="#aeb8c2"
            handleWidth={0}
            timeStep={86400000}
            arrowColor="#888"
            fontFamily="Arial, sans-serif"
            fontSize="14px"
            weekPrefix=""
            todayColor="rgba(255, 0, 0, 0.1)"
            todayLineColor="rgba(255, 0, 0, 0.9)"
            TaskListHeader={CustomHeader}
            TaskListTable={CustomTaskListTable}
            TooltipContent={({ task }) => (
              <div className="bg-white p-2 rounded shadow">
                <h3 className="font-bold">{task.name}</h3>
                <p>Inicio: {formatDate(task.start)}</p>
                <p>Fin: {formatDate(task.end)}</p>
                <p>Progreso: {task.progress.toFixed(2)}%</p>
                {task.project && task.project !== task.id}
              </div>
            )}
            onDateChange={() => {}}
            onProgressChange={() => {}}
            onDoubleClick={() => {}}
            onSelect={handleTaskSelect}
            onExpanderClick={handleExpanderClick}
          />
            
          </div>
        </div>
      </div>
    );
};

export default GanttChartView;

