import React from "react";

interface BadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: "default" | "secondary";
}

export const Badge: React.FC<BadgeProps> = ({ className, variant = "default", children, ...props }) => {
  const base = "inline-flex items-center px-2 py-1 text-sm rounded";
  const variants = {
    default: "bg-gray-200 text-gray-800",
    secondary: "bg-blue-100 text-blue-800",
  };

  return (
    <div className={`${base} ${variants[variant]} ${className ?? ""}`} {...props}>
      {children}
    </div>
  );
};
