import React, { FocusEvent, useRef, useState, useEffect,ChangeEvent, DragEvent, useCallback} from 'react';
import { Download, Edit, Trash, Eye, Flag, PlusCircle, MinusCircle, File, Paperclip, Split, Mail, X, Loader, Undo, ChevronLeft, ChevronRight, UserIcon, Loader2, Search, MessageCircle } from 'lucide-react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "../ui/dialog";
import { Input } from "../ui/input";
import axios from 'axios';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { match } from 'assert';
import toast from 'react-hot-toast';
import { ArrowDown, ArrowUp, Filter, ChevronDown } from 'lucide-react';


import { motion, AnimatePresence, progress } from 'framer-motion';

interface User {
  id: number;
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  is_admin: boolean;
  is_active: boolean;
  departamento: string;
}



interface UserSelectorIconProps {
  selectedUsers: User[]; // Lista de usuarios seleccionados
  enabled?: boolean; // Por defecto, `true`
  isClosed?: boolean; // Por defecto, `false`
  subprocessColor: string; // Color del subproceso
  currentUserId?: string | null; // ID del usuario actual, puede ser null si no está disponible
  onClick?: () => void; // Función opcional para manejar el clic
}


const UserSelectorIcon: React.FC<UserSelectorIconProps> = ({
  selectedUsers,
  enabled = true,
  isClosed = false,
  subprocessColor,
  currentUserId = sessionStorage.getItem('userId'),
  onClick
}) => {
  const darkenColor = (color: string, percentage: number): string => {
    const num = parseInt(color.replace('#', ''), 16);
    const amt = Math.round(2.55 * percentage);
    const R = (num >> 16) - amt;
    const G = ((num >> 8) & 0x00ff) - amt;
    const B = (num & 0x0000ff) - amt;
    return `#${(0x1000000 + (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 + (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 + (B < 255 ? (B < 1 ? 0 : B) : 255)).toString(16).slice(1)}`;
  };
  const getIconColor = (): string => {
    if (isClosed) {
      return '#8EA6A6'; // Very light gray for closed tasks
    }
  
    if (!selectedUsers || selectedUsers.length === 0) {
      return '#A9A9A9'; // Gray for no users
    }
    
    const isCurrentUserResponsible = selectedUsers.some(
      user => user.id.toString() === currentUserId
    );
    
    if (isCurrentUserResponsible) {
      return '#23C25F'; // Green if current user is responsible
    }
    
    return '#4B5563'; // Normal gray if has users but current user isn't one
  };

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent event bubbling
    if (enabled && onClick) {
      onClick();
    }
  };

  const tooltipText = selectedUsers.length > 0
    ? `Responsables: ${selectedUsers.map(u => `${u.firstname} ${u.lastname}`).join(', ')}`
    : 'Sin responsables asignados';

  return (
    <div 
      onClick={handleClick}
      className={`cursor-pointer flex items-center ${enabled ? 'hover:opacity-80' : 'opacity-50 cursor-not-allowed'}`}
      title={tooltipText}
    >
      <UserIcon 
        className="w-6 h-6 mr-2 transition-colors duration-200"
        style={{ color: getIconColor() }}
      />
    </div>
  );
};



const overlayVariants = {
  hidden: { opacity: 0 },
  visible: { 
    opacity: 1,
    transition: { duration: 0.2 }
  },
  exit: { 
    opacity: 0,
    transition: { duration: 0.2 }
  }
};

// First, let's define the modalVariants
const modalVariants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { 
    opacity: 1, 
    scale: 1,
    transition: { type: 'spring', stiffness: 500, damping: 25 }
  },
  exit: { 
    opacity: 0, 
    scale: 0.8,
    transition: { duration: 0.2 }
  }
};

const contentVariants = {
  hidden: { opacity: 0, x: 20 },
  visible: { 
    opacity: 1, 
    x: 0,
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 25
    }
  },
  exit: { 
    opacity: 0, 
    x: -20,
    transition: { duration: 0.2 }
  }
};

const tabVariants = {
  hidden: { opacity: 0, y: -10 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { type: "spring", stiffness: 500, damping: 30 }
  }
};

const buttonVariants = {
  hover: { scale: 1.05 },
  tap: { scale: 0.95 },
  initial: { scale: 1 }
};



interface UserSelectorModalProps {
  isOpen: boolean; // Controla si el modal está abierto
  onClose: () => void; // Callback para cerrar el modal
  users: User[]; // Lista de todos los usuarios disponibles
  selectedUsers: User[]; // Usuarios seleccionados actualmente
  onUsersChange: (users: User[]) => void; // Callback para actualizar los usuarios seleccionados
  enabled?: boolean; // Indica si el modal está habilitado
  taskId: number; // ID de la tarea asociada
  groupId: number; // ID del grupo asociado
  isClosed?: boolean; // Indica si la tarea está cerrada
  subprocessColor?: string; // Color del subproceso asociado
}

// Implementación correcta del UserSelectorModal para un solo usuario

const UserSelectorModal: React.FC<UserSelectorModalProps> = ({
  isOpen,
  onClose,
  users: initialUsers = [],
  selectedUsers: initialSelectedUsers = [],
  onUsersChange,
  enabled = true,
  taskId,
  groupId,
  isClosed = false,
  subprocessColor,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [localSelectedUsers, setLocalSelectedUsers] = useState<User[]>([]);
  const [allUsers, setAllUsers] = useState<User[]>(initialUsers);
  const isNewTask = taskId < 0;
  const currentUserId = sessionStorage.getItem('userId');

  // Efecto para inicializar usuarios cuando se abre el modal
  useEffect(() => {
    if (isOpen) {
      console.log("Modal abierto con usuarios iniciales:", initialSelectedUsers);
      // Importante: hace r una copia para asegurar que es un nuevo array
     setLocalSelectedUsers([...initialSelectedUsers]);
      loadAllUsers();
    }
  }, [isOpen, initialSelectedUsers]);

  // Cargar todos los usuarios
  const loadAllUsers = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/users/get_users.php`);
      if (!response.ok) throw new Error('Error fetching users');
      const data = await response.json();
      
      // Transformar los usuarios para asegurar IDs consistentes
      const processedUsers = data.map((user: User) => {
        // Si el usuario tiene email, generar un ID basado en el email para consistencia
        if (user.email) {
          const emailBasedId = parseInt(user.email.replace(/[^a-z0-9]/gi, '').substring(0, 8), 36);
          return {
            ...user,
            id: emailBasedId || user.id // Usar el ID basado en email si está disponible
          };
        }
        return user;
      });
      
      // Buscar y agregar los usuarios seleccionados si no existen
      let combinedUsers = [...processedUsers];
      
      initialSelectedUsers.forEach(selectedUser => {
        // Verificar por ID y también por email para mayor seguridad
        const exists = combinedUsers.some(user => 
          user.id === selectedUser.id || 
          (user.email && selectedUser.email && user.email === selectedUser.email)
        );
        
        if (!exists) {
          console.log("Añadiendo usuario seleccionado que no está en la lista:", selectedUser);
          combinedUsers.push(selectedUser);
        }
      });
      
      console.log("Usuarios cargados:", combinedUsers);
      console.log("Usuarios seleccionados:", initialSelectedUsers);
      
      setAllUsers(combinedUsers);
    } catch (error) {
      console.error('Error loading users:', error);
      toast.error('Error al cargar la lista de usuarios');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = async () => {
    try {
      setIsSaving(true);
      
      // Enviar los usuarios seleccionados al componente padre
      onUsersChange(localSelectedUsers);
      onClose();
      
    } catch (error) {
      console.error('Error saving users:', error);
      toast.error(error instanceof Error ? error.message : 'Error al guardar el emisor');
    } finally {
      setIsSaving(false);
    }
  };

  const filteredUsers = allUsers.filter(user => {
    const searchString = `${user.firstname} ${user.lastname} ${user.email} ${user.departamento}`.toLowerCase();
    const searchTerms = searchTerm.toLowerCase().split(' ');
    return searchTerms.every(term => searchString.includes(term));
  });

  return(
    <AnimatePresence>
    {isOpen && (
      <motion.div 
        className="fixed inset-0 bg-black/50 flex items-center justify-center z-[70]"
        variants={overlayVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <motion.div 
          className="bg-white rounded-lg w-full max-w-2xl overflow-hidden"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <div className="bg-gradient-to-r from-teal-500 to-teal-600 p-6">
            <div className="flex justify-between items-center">
              <DialogTitle>
                <span className="text-xl font-semibold text-white">Seleccionar Emisor</span>
              </DialogTitle>
              <button
                onClick={onClose}
                className="text-white/80 hover:text-white transition-colors"
              >
                <X size={24} />
              </button>
            </div>
            <p className="text-teal-100 mt-2 text-sm">
              {localSelectedUsers.length > 0 ? "1 emisor seleccionado" : "Ningún emisor seleccionado"}
            </p>
          </div>

          <motion.div
            className="p-6"
            variants={contentVariants}
            initial="hidden"
            animate="visible"
          >
            {isLoading ? (
              <div className="flex items-center justify-center py-8">
                <Loader2 className="w-8 h-8 animate-spin text-teal-500" />
                <span className="ml-2">Cargando usuarios...</span>
              </div>
            ) : (
              <>
                <div className="mb-6">
                  <div className="relative">
                    <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                    <input
                      type="text"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      placeholder="Buscar por nombre, email o departamento..."
                      className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                    />
                  </div>
                </div>

                <div className="bg-teal-50 p-4 rounded-lg border border-teal-100 mb-4">
                  <h3 className="text-sm font-medium text-teal-800 mb-2">Emisor Seleccionado</h3>
                  <div className="flex flex-wrap gap-2">
                    {localSelectedUsers.length > 0 ? (
                      <div
                        key={localSelectedUsers[0].id}
                        className="inline-flex items-center gap-2 bg-white text-teal-700 px-3 py-1 rounded-full text-sm border border-teal-200 hover:bg-teal-50 transition-colors"
                      >
                        <UserIcon className="w-4 h-4" />
                        <span>{`${localSelectedUsers[0].firstname} ${localSelectedUsers[0].lastname}`}</span>
                        <button
                          onClick={() => setLocalSelectedUsers([])}
                          className="ml-1 text-teal-400 hover:text-teal-600"
                        >
                          <X className="w-4 h-4" />
                        </button>
                      </div>
                    ) : (
                      <p className="text-sm text-teal-600 italic">No hay emisor seleccionado</p>
                    )}
                  </div>
                </div>

                <div className="space-y-2">
                  <h3 className="text-sm font-medium text-gray-700 mb-2">
                    {filteredUsers.length} usuario(s) encontrado(s)
                  </h3>
                  <div className="grid grid-cols-1 gap-2 max-h-[300px] overflow-y-auto overflow-x-hidden">
                    {filteredUsers.map((user) => (
                      <motion.button
                        key={user.id}
                        onClick={() => {
                          // Si este usuario ya está seleccionado, deseleccionarlo
                          if (localSelectedUsers.some(u => u.id === user.id)) {
                            setLocalSelectedUsers([]);
                          } else {
                            // Si no está seleccionado o es otro usuario, seleccionar solo este
                            setLocalSelectedUsers([user]);
                          }
                        }}
                        className={`flex items-center justify-between w-full p-3 rounded-lg border transition-all ${
                          localSelectedUsers.some(u => u.id === user.id)
                            ? 'bg-teal-50 border-teal-200 text-teal-700'
                            : 'bg-white border-gray-200 hover:bg-gray-50'
                        }`}
                        
                        whileHover={{ scale: 1.01 }}
                        whileTap={{ scale: 0.99 }}
                      >
                        <div className="flex items-center gap-3">
                          <div className="w-8 h-8 rounded-full bg-teal-100 flex items-center justify-center">
                            <UserIcon className="w-4 h-4 text-teal-600" />
                          </div>
                          <div className="flex flex-col items-start">
                            <span className="font-medium">{`${user.firstname} ${user.lastname}`}</span>
                            <span className="text-sm text-gray-500">{user.email}</span>
                          </div>
                        </div>
                        <span className="text-sm text-gray-500">{user.departamento}</span>
                      </motion.button>
                    ))}
                  </div>
                </div>

                <div className="flex justify-end space-x-3 mt-6 pt-4 border-t">
                  <button onClick={onClose}>
                    Cancelar
                  </button>
                  <button 
                    onClick={handleSave} 
                    className="bg-teal-500 text-white hover:bg-teal-600"
                  >
                    {isSaving ? (
                      <>
                        <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                        Guardando...
                      </>
                    ) : (
                      'Confirmar Selección'
                    )}
                  </button>
                </div>
              </>
            )}
          </motion.div>
        </motion.div>
      </motion.div>
    )}
  </AnimatePresence>
);
};











interface InputFieldProps {
  label: string;
  className?: string;
  inputClassName?: string;
  disabled?: boolean;
  [x: string]: any; // Para aceptar otras propiedades del input
}

interface Option {
  id: number;
  label: string;
}

interface ComboBoxProps {
  label: string;
  options: string[] | Option[] | Subproceso[];
}

const ComboBox: React.FC<ComboBoxProps> = ({ label, options }) => {
  // Función para determinar si options es un array de objetos
  const isArrayOfObjects = (
    options: string[] | Option[] | Subproceso[]
  ): options is Option[] | Subproceso[] => {
    return (
      options.length > 0 &&
      typeof options[0] === 'object' &&
      ('id' in options[0]) &&
      ('label' in options[0] || 'subproceso' in options[0])
    );
  };

  return (
    <div>
      <label className="block text-sm font-medium text-gray-700 mb-2">{label}</label>
      <select className="w-full border border-gray-300 rounded-md p-2 focus:ring-cyan-500 focus:border-cyan-500">
        <option value="">Seleccione una opción</option>
        {isArrayOfObjects(options)
          ? options.map((option) =>
              'label' in option ? (
                <option key={option.id} value={option.id}>
                  {option.label}
                </option>
              ) : (
                <option key={option.id} value={option.id}>
                  {option.subproceso}
                </option>
              )
            )
          : options.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
      </select>
    </div>
  );
};






const InputField: React.FC<InputFieldProps> = ({
  label,
  className,
  inputClassName,
  disabled,
  ...props
}) => (
  <div className={className}>
    <label className="block text-sm font-medium text-gray-700 mb-2">{label}</label>
    <input 
      type="text"
      disabled={disabled}
      className={`w-full border border-gray-300 rounded-md p-2 focus:ring-cyan-500 focus:border-cyan-500 ${inputClassName} ${disabled ? 'bg-gray-100 cursor-not-allowed' : ''}`}
      {...props} 
    />
  </div>
);


interface Subproceso {
  id: number;
  subproceso: string;
}

interface SubprocesoSelectProps {
  processId: number; // ID del proceso para obtener los subprocesos
}

interface TransmittalModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  documents: Document[]; // Documentos que se pasan al modal
  setPurposeSelected?: (value: boolean) => void; // Función para controlar si se ha seleccionado un propósito

}

const options = [
  "Preliminar",
  "Para Información",
  "Para Coordinación",
  "Para Aprobación",
  "Para Licitación",
  "Para Contrato",
  "Para Cotización"
];

interface TransmittalComboBoxProps {
  detalleTransmittal: { [key: string]: boolean };
  setDetalleTransmittal: (value: { [key: string]: boolean }) => void; 
  setPurposeSelected?: (value: boolean) => void; // Función para controlar si se ha seleccionado un propósito

}


const TransmittalComboBox: React.FC<TransmittalComboBoxProps> = ({ detalleTransmittal, setDetalleTransmittal, setPurposeSelected }) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  // Efecto para actualizar el estado de habilitación de los botones
  useEffect(() => {
    if (setPurposeSelected) {
      setPurposeSelected(selectedItems.length > 0);
    }
  }, [selectedItems, setPurposeSelected]);

  const handleSelectionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    // Obtener valores seleccionados
    const selectedValues = Array.from(event.target.selectedOptions, (option) => option.value);
    
    // Ignorar la opción placeholder vacía si está presente y se seleccionan otras opciones
    const filteredValues = selectedValues.filter(val => val !== "");
    
    setSelectedItems(filteredValues);
    
    // Actualizar el estado para habilitar/deshabilitar botones
    if (setPurposeSelected) {
      setPurposeSelected(filteredValues.length > 0);
    }

    // Actualizar el estado detalleTransmittal
    setDetalleTransmittal(
      options.reduce((acc, item) => {
        acc[item] = filteredValues.includes(item);
        return acc;
      }, {} as { [key: string]: boolean })
    );
  };

  return (
    <div className="w-full">
      <select
        className="w-full border border-gray-300 rounded-md p-2 focus:ring-cyan-500 focus:border-cyan-500"
        value={selectedItems}
        onChange={handleSelectionChange}
      >
        <option value="" disabled={selectedItems.length > 0}>
          Seleccionar propósito
        </option>
        {options.map((item) => (
          <option key={item} value={item}>
            {item}
          </option>
        ))}
      </select>
    </div>
  );
};


const createUserObject = (username: string | null, userEmail: string | null, userTitle: string | null): User => {
  // Generar un ID consistente basado en el email para poder encontrarlo después
  const userId = userEmail 
    ? parseInt(userEmail.replace(/[^a-z0-9]/gi, '').substring(0, 8), 36) 
    : parseInt(sessionStorage.getItem('userId') || '0', 10);
  
  return {
    id: userId,
    username: username || '',
    firstname: (username || '').split(' ')[0] || '',
    lastname: (username || '').split(' ').slice(1).join(' ') || '',
    email: userEmail || '',
    is_admin: false,
    is_active: true,
    departamento: userTitle || ''
  };
};



const TransmittalModal: React.FC<TransmittalModalProps> = ({ isOpen, onClose, onSubmit, documents }) => {
  const [projectFilter, setProjectFilter] = useState('');
  const [projectOptions, setProjectOptions] = useState<string[]>([]);
  const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const [processes, setProcesses] = useState<Process[]>([]);

  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [isUserSelectorOpen, setIsUserSelectorOpen] = useState(false);


  const [codigo, setCodigo] = useState<string[]>([]);
  const [nombreProceso, setNombreProceso] = useState<string[]>([]);
  const [comuna, setComuna] = useState<string[]>([]);
  const [selectedNombreProyecto, setSelectedNombreProyecto] = useState<string>('');
  const [selectedComuna, setSelectedComuna] = useState<string>('');
  const [processFilter, setProcessFilter] = useState<string>('');
  const [processOptions, setProcessOptions] = useState<string[]>([]);
  //const [processes, setProcesses] = useState<string[]>([]);
  const [username, setUsername] = useState<string | null>(null);
  const [emisor, setEmisor] = useState<string | null>(null);
  const [emisorMail, setEmisorMail] = useState<string | null>(null);
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [userTitle, setUserTitle] = useState<string | null>(null);
  const [userCargo, setUserCargo] = useState<string | null>(null);
  const [transmittalNumber, setTransmittalNumber] = useState<string>('');
  const [nombreProyecto, setNombreProyecto] = useState<string>('');
  const [codigoProyecto, setCodigoProyecto] = useState<string>('');


  const [subprocesos, setSubprocesos] = useState<Subproceso[]>([]);
  const [selectedCodigoProyecto, setSelectedCodigoProyecto] = useState<string>('');
  const [selectedSubproceso, setSelectedSubproceso] = useState<string>('');


  const [codigoTransmittal, setCodigoTransmittal] = useState<string>('');

  const [toEmail, setToEmail] = useState('');
  const [ccEmail, setCcEmail] = useState('');
  
  const [niveles, setNiveles] = useState<Nivel[]>([]);
  const [selectedValues, setSelectedValues] = useState<{ [key: string]: string }>({});

  const [message, setMessage] = useState<{ text: string, type: 'success' | 'error' } | null>(null);

  const [isbuttonDisabled, setIsbuttonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  // Nuevos estados para los campos del formulario
  const [asunto, setAsunto] = useState<string>('');
  const [referencia, setReferencia] = useState<string>('');
  const [descripcion, setDescripcion] = useState<string>('');
  const [subcarpeta, setSubcarpeta] = useState<string>('');
  const [detalleTransmittal, setDetalleTransmittal] = useState<{ [key: string]: boolean }>({});
  const [isPurposeSelected, setIsPurposeSelected] = useState<boolean>(false);
  
  const [comentarios, setComentarios] = useState<string>('');
  const [data, setData] = useState<Data[]>([]);
  const showToastError = (message:string) => {
    // Crear un div para el toast
    const toastContainer = document.createElement('div');
    toastContainer.style.position = 'fixed';
    toastContainer.style.top = '20px';
    toastContainer.style.right = '20px';
    toastContainer.style.zIndex = '100000';
    toastContainer.style.backgroundColor = '#ef4444';
    toastContainer.style.color = 'white';
    toastContainer.style.padding = '12px 20px';
    toastContainer.style.borderRadius = '4px';
    toastContainer.style.boxShadow = '0 4px 8px rgba(0,0,0,0.2)';
    toastContainer.textContent = message;
    
    // Añadir al body
    document.body.appendChild(toastContainer);
    
    // Remover después de un tiempo
    setTimeout(() => {
      document.body.removeChild(toastContainer);
    }, 4000);
  };
/*
  const emailOptions = [
    { name: 'Francis Serrano', email: 'f.serrano@correo.cl' },
    { name: 'Ana Pérez', email: 'ana.perez@correo.cl' },
    { name: 'Luis Martínez', email: 'luis.martinez@correo.cl' },
    // Agrega más opciones según sea necesario
  ];*/
  // Sort states
  const [emailOptions, setEmailOptions] = useState<EmailOption[]>([]);
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedComment, setSelectedComment] = useState("");

  const openModal = (comment: string) => {
    setSelectedComment(comment);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedComment("");
  };

  

// Función para verificar si hay errores de validación
const hasValidationErrors = () => {
  return (
    !isPurposeSelected ||
    !toEmail ||
    !asunto ||
    !processFilter ||
    !selectedSubproceso
  );
};

// Función para obtener texto para el tooltip
const getTooltipText = () => {
  const errors = [];
  
  if (!isPurposeSelected) errors.push("Propósito");
  if (!processFilter) errors.push("Proceso");
  if (!selectedSubproceso) errors.push("Subproceso");
  if (!toEmail) errors.push("Correo válido");
  if (!asunto) errors.push("Asunto");
  
  if (errors.length === 0) return "";
  if (errors.length === 1) return `Falta seleccionar ${errors[0]}`;
  
  const lastError = errors.pop();
  return `Falta: ${errors.join(', ')} y ${lastError}`;
};

// Usar en los tooltips
{hasValidationErrors() && (
  <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:block bg-gray-800 text-white text-xs rounded py-1 px-2 whitespace-nowrap z-10">
    {getTooltipText()}
  </div>
)}

// Función para mostrar mensaje cuando se intenta hacer clic en un botón deshabilitado
const handleDisabledButtonClick = () => {
  toast.error(getErrorMessage());
};

// Función para obtener una lista de todos los campos que faltan
const getMissingFields = () => {
  const missing = [];
  
  if (!isPurposeSelected) missing.push("Propósito");
  if (!processFilter) missing.push("Proceso");
  if (!selectedSubproceso) missing.push("Subproceso");
  if (!toEmail) missing.push("Correo electrónico");
  if (!asunto) missing.push("Asunto");
  
  return missing;
};

// Función para construir el mensaje de error
const getErrorMessage = () => {
  const missing = getMissingFields();
  
  if (missing.length === 0) return "";
  if (missing.length === 1) return `Debes completar el campo: ${missing[0]}`;
  
  const lastField = missing.pop();
  return `Debes completar los siguientes campos: ${missing.join(', ')} y ${lastField}`;
};

  const handleCancelTransmittal = () => {
    // Restablecer usuario al logueado (misma lógica del useEffect)
    const storedFirstName = sessionStorage.getItem('firstName');
    const storedLastName = sessionStorage.getItem('lastName');
    const storedEmail = sessionStorage.getItem('email');
    const storedTitle = sessionStorage.getItem('title');
    const storedCargo = sessionStorage.getItem('cargo');
    
    if (storedFirstName && storedLastName) {
      setUsername(`${storedFirstName} ${storedLastName}`);
      setEmisor(`${storedFirstName} ${storedLastName} <${storedEmail}>`);
      setUserEmail(storedEmail || '');
      setUserTitle(storedTitle || '');
      setUserCargo(storedCargo || '');
    }
    
    // Cerrar el modal
    onClose();
  };

  useEffect(() => {
    if (isOpen && username) {
      // Crear un objeto de usuario con los datos disponibles
      const currentUser = createUserObject(username, userEmail, userTitle);
      
      // Establecer el usuario actual como seleccionado por defecto
      setSelectedUsers([currentUser]);
      
      // Asegurarse de que este usuario está en las opciones de email
      const userExists = emailOptions.some(option => 
        option.email === currentUser.email || 
        option.name === username
      );
      
      if (!userExists && userEmail) {
        setEmailOptions(prev => [
          ...prev,
          {
            name: username || '',
            email: userEmail || ''
          }
        ]);
      }
    }
  }, [isOpen, username, userEmail]);
  useEffect(() => {
    if (isOpen && username) {
      // Crear un ID único y consistente basado en el nombre y email del usuario
      // en lugar de obtenerlo de la sesión
      const userId = userEmail 
        ? parseInt(userEmail.replace(/[^a-z0-9]/gi, '').substring(0, 8), 36) 
        : Math.floor(Math.random() * 10000);
        
      // Crear un objeto de usuario con los datos disponibles
      const currentUser: User = {
        id: userId,            // ID basado en el email o un número aleatorio
        username: username,
        firstname: (username || '').split(' ')[0] || '',
        lastname: (username || '').split(' ').slice(1).join(' ') || '',
        email: userEmail || '',
        is_admin: false,
        is_active: true,
        departamento: userTitle || ''
      };
      
      // Establecer como usuario seleccionado (independientemente del usuario logueado)
      setSelectedUsers([currentUser]);
      
      console.log("Usuario inicializado:", currentUser);
    }
  }, [isOpen, username, userEmail, userTitle]);
  const openUserSelector = () => {
    // Si hay un usuario seleccionado, asegurarse de que está en las opciones
    if (selectedUsers.length > 0) {
      const currentUser = selectedUsers[0];
      
      // Verificar si el usuario ya existe en las opciones
      const userExists = emailOptions.some(option => 
        option.email === currentUser.email ||
        option.name === username
      );
      
      // Si no existe, añadirlo a las opciones
      if (!userExists && userEmail) {
        setEmailOptions(prev => [
          ...prev,
          {
            name: username || '',
            email: userEmail || ''
          }
        ]);
      }
    }
    
    // Ahora abrir el selector
    setIsUserSelectorOpen(true);
  };

  const handleAsuntoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAsunto(e.target.value.toUpperCase());
  };
  

  const handleReferenciaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReferencia(e.target.value);
  };

  const handleDescripcionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescripcion(e.target.value);
  };

  const handleSubprocesoChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = event.target.value;
    const selectedSubproceso = subprocesos.find(sub => sub.id.toString() === selectedId);
    
    if (selectedSubproceso) {
      setSelectedSubproceso(selectedSubproceso.subproceso); // O cualquier estado en el que quieras guardar el nombre
    }
  };

  const handleComentariosChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length <= 300) {
        setComentarios(e.target.value.toUpperCase());
    }
};


  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;
    setDetalleTransmittal(prevState => ({
      ...prevState,
      [id]: checked
    }));
  };


  useEffect(() => {
    const shouldDisablebutton = 
      !processFilter || 
      !toEmail || 
      !asunto || 
      !selectedSubproceso;
  
    setIsbuttonDisabled(shouldDisablebutton);
  }, [processFilter, toEmail, asunto, selectedSubproceso]);
  



  

  useEffect(() => {
    // Reset all fields when the modal is closed
    if (!isOpen) {
      //setProjectFilter('');
     // setProjectOptions([]);
     // setSelectedDocuments([]);
      //setCodigo([]);
      //setNombreProceso([]);
     // setComuna([]);
      setSelectedNombreProyecto('');
      setSelectedCodigoProyecto('');
      setSelectedComuna('');
      setProcessFilter('');
     setIsbuttonDisabled(false);
     setIsLoading(false);
     // setProcesses([]);
     // setUsername(null);
     // setTransmittalNumber('');
     // setCodigoProyecto('');
      setCodigoTransmittal('');
      setToEmail('');
      setCcEmail('');
    //  setNiveles([]);
      //setSelectedValues({});
      setAsunto('');
      setReferencia('');
      setDescripcion('');
      setSelectedSubproceso('');
      setDetalleTransmittal({
        Preliminar: false,
        'Para Información': false,
        'Para Coordinación': false,
        'Para Aprobación': false,
        'Para Construcción': false,
      });
      setComentarios('');
      //setData([]);
    //  setEmailOptions([]);
      setSortColumn(null);
      setSortDirection('asc');
      setMessage(null);
      fetchNextTransmittalNumber();

    }
  }, [isOpen]);



  useEffect(() => {
    // Función para obtener los datos desde el backend
    const fetchEmails = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/php/pages/transmittal/get_emails.php`);
        setEmailOptions(response.data);
        console.log(response.data);
      } catch (error) {
        console.error('Error al obtener correos:', error);
      }
    };

    fetchEmails();
  }, []);


  useEffect(() => {
    fetchProcesses();
    fetchNextTransmittalNumber();
  }, []);

  useEffect(() => {
    const storedFirstName = sessionStorage.getItem('firstName');
    const storedLastName = sessionStorage.getItem('lastName');
    const storedEmail = sessionStorage.getItem('email');
    const storedCargo = sessionStorage.getItem('cargo');

    
    
    if (storedFirstName && storedLastName) {
      setUsername(`${storedFirstName} ${storedLastName}`);
      setEmisor(`${storedFirstName} ${storedLastName} <${storedEmail}>`);
    }
     
    if (storedEmail) {
      setUserEmail(`${storedEmail} `);
    }
     
    if (storedCargo ) {
      setUserTitle(`${storedCargo}`);
    }

  }, []);

  useEffect(() => {
    if (transmittalNumber && selectedCodigoProyecto) {
      setCodigoTransmittal(`${selectedCodigoProyecto}-P-TRL-AI-${transmittalNumber}`);
    }
  }, [transmittalNumber, selectedCodigoProyecto]);

  useEffect(() => {
    const fetchSubprocesos = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/transmittal/get_subprocesos.php?codigo=${selectedCodigoProyecto}`);
        const data = await response.json();

        if (data && typeof data === 'object' && Array.isArray(data.subprocesos)) {
          setSubprocesos(data.subprocesos);
          console.log(data);
        } else {
          console.error('Error al obtener los subprocesos', data);
        }
      } catch (error) {
        console.error('Error de red', error);
      }
    };

    if (selectedCodigoProyecto.length > 0) {
      fetchSubprocesos();
    }
  }, [selectedCodigoProyecto]);


  const fetchNextTransmittalNumber = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/transmittal/get_next_transmittal_number.php`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setTransmittalNumber(data.transmittal_number.toString().padStart(3, '0'));
    } catch (error) {
      console.error('Error fetching next transmittal number:', error);
    }
  };




  const handleSubmit = async () => {

    setIsLoading(true);
    setIsbuttonDisabled(true);



    const detalleArray = Object.keys(detalleTransmittal).map(
      key => detalleTransmittal[key]
    );
  
    try {
      
      
      
      await saveTransmittal(); // Asume que saveTransmittal es la función que realiza el guardado
  
      setMessage({
        text: 'Transmittal enviado exitosamente.',
        type: 'success'
      });
  
      // Cierra el modal después de 3 segundos
      setTimeout(() => {
        onClose();
      }, 3000);
    } catch (error) {
      setMessage({
        text: 'Error al enviar el transmittal.',
        type: 'error'
      });
      setIsLoading(false);
      setIsbuttonDisabled(false);
    }
  };

   // Extraer IDs de los documentos
   const extractDocumentIds = (docs: { id: string }[]) => {
    return docs.map(doc => doc.id);
};





  const handlePreview = async () => {
    setLoading(true);

    try {
      //alert(selectedSubproceso)

        // Extrae los IDs de los documentos
        const documentIds = extractDocumentIds(documents);
      //  alert(documentIds);
         // Solicitar URLs de documentos
         const responseDownloads = await axios.post(
          `${process.env.REACT_APP_API_URL}/php/pages/controldocument/busquedarapida/download_documents.php?type=type2`,
          { documentIds },
          { responseType: 'json' } // Esperar JSON con URLs en lugar de blob
      );
      
      const documentUrls = responseDownloads.data.url; // Asume que `urls` es el campo en la respuesta
   
      // Mostrar URLs en una alerta o en la consola
      console.log('Document URLs:', documentUrls);
    //  alert('Document URLs have been logged in the console.');
  
  
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/controldocument/busquedarapida/generar_transmital.php?type=type1`, {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          transmittal_number: parseInt(transmittalNumber, 10),
          codigo_proyecto: selectedCodigoProyecto,
          nombre_proyecto: selectedNombreProyecto,
          comuna: selectedComuna,
          emisor: username,
          emisorCorreo: userEmail,
          emisorTitle: userTitle !== null && userTitle !== undefined ? userTitle : "",
          emisorCargo: userCargo,
          destinatarios: toEmail,
          cc: ccEmail,
          asunto: asunto,
          referencia,
          descripcion,
         // subcarpeta: selectedValues[1],
          subproceso: selectedSubproceso,
          detalle_transmittal: detalleTransmittal,
          documentos_seleccionados: currentDocuments,
          zipUrl: documentUrls,
          comentarios
        })
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const blob = await response.blob();
      const pdfUrl = URL.createObjectURL(blob);
      window.open(`${pdfUrl}`);
      setLoading(false);

    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };
  
  const saveTransmittal = async () => {
    try {

      
        // Extrae los IDs de los documentos
        const documentIds = extractDocumentIds(documents);
      //  alert(documentIds);
         // Solicitar URLs de documentos
         const responseDownloads = await axios.post(
          `${process.env.REACT_APP_API_URL}/php/pages/controldocument/busquedarapida/download_documents.php?type=type2`,
          { documentIds },
          { responseType: 'json' } // Esperar JSON con URLs en lugar de blob
      );
      
      const documentUrls = responseDownloads.data.url; // Asume que `urls` es el campo en la respuesta
   
      // Mostrar URLs en una alerta o en la consola
      console.log('Document URLs:', documentUrls);
    //  alert('Document URLs have been logged in the console.');
  
 


    // Enviar datos para generar el transmittal
    const responseFileTRL = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/controldocument/busquedarapida/generar_transmital.php?type=type2`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        transmittal_number: parseInt(transmittalNumber, 10),
        codigo_proyecto: selectedCodigoProyecto,
        nombre_proyecto: selectedNombreProyecto,
        comuna: selectedComuna,
        emisor: username,
        emisorCorreo: userEmail,
        emisorTitle: userTitle !== null && userTitle !== undefined ? userTitle : "",
        destinatarios: toEmail,
        cc: ccEmail,
        asunto: asunto,
        referencia,
        descripcion,
        //subcarpeta: selectedValues[1],
        subproceso: selectedSubproceso,
        detalle_transmittal: detalleTransmittal,
        documentos_seleccionados: currentDocuments,
        zipUrl: documentUrls,
        comentarios
      })
    });

    if (!responseFileTRL.ok) {
      throw new Error(`HTTP error! Status: ${responseFileTRL.status}`);
    }

    // Obtener la URL del archivo PDF generado
    const fileTRLResponse = await responseFileTRL.json();
    if (fileTRLResponse.pdfUrl) {
      console.log('URL del archivo PDF generado:', fileTRLResponse.pdfUrl);
    } else {
      console.error('No se recibió una URL de PDF en la respuesta.');
    }


    const transmittalLink = fileTRLResponse.pdfUrl;
    //alert(selectedSubproceso);
  
    const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/transmittal/save_transmittal.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        transmittal_number: parseInt(transmittalNumber, 10),
        pdfUrl: transmittalLink,
        codigo_proyecto: selectedCodigoProyecto,
        nombre_proyecto: selectedNombreProyecto,
        comuna: selectedComuna,
        emisor: username,
        destinatarios: toEmail,
        cc: ccEmail,
        asunto:asunto,
        referencia,
        descripcion,
        subproceso: selectedSubproceso,
        detalle_transmittal: detalleTransmittal,
        documentos_seleccionados: currentDocuments,
        zipUrl: documentUrls,
        comentarios
      })
    });

      const data = await response.json();
      if (data.success) {
        console.log('Transmittal guardado con éxito:', data.transmittal_code);
      } else {
        console.error('Error al guardar el transmittal:', data.error);
      }
    } catch (error) {
      console.error('Error de red:', error);
    }
  };


//   const fetchProcesses = async () => {
//     try {
//         const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/proceso/get_processes.php?type=type4`);
//         if (!response.ok) {
//             throw new Error('Network response was not ok');
//         }
//         const data = await response.json();

//         // Almacenar los datos completos
//         setProcesses(data.map((item: { codigo: string, nombreProceso: string, comuna: string }) => ({
//             codigo: item.codigo,
//             nombreProceso: item.nombreProceso,
//             comuna: item.comuna
//         })));
//     } catch (error) {
//         console.error('Error fetching processes:', error);
//     }
// };
const fetchProcesses = async () => {
  try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/proceso/get_processes.php?type=type4`);
      if (!response.ok) {
          throw new Error('Network response was not ok');
      }
      const data: Process[] = await response.json();

      // Almacenar los datos completos
      setProcesses(data);
  } catch (error) {
      console.error('Error fetching processes:', error);
  }
};


// const handleProjectFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
//   const selectedCodigo = e.target.value;

//   // Buscar el proceso seleccionado
//   const selectedProcess = processes.find(process => process.codigo === selectedCodigo);

//   if (selectedProcess) {
//       setProcessFilter(selectedCodigo);
//       setSelectedNombreProyecto(selectedProcess.nombreProceso);
//       setSelectedCodigoProyecto(selectedProcess.codigo);
//       setSelectedComuna(selectedProcess.comuna);
//   } else {
//       setProcessFilter('');
//       setSelectedNombreProyecto('');
//       setSelectedCodigoProyecto('');
//       setSelectedComuna('');
//   }
// };

const handleProjectFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
  const selectedCodigo = e.target.value;

  // Buscar el proceso seleccionado
  const selectedProcess = processes.find(process => process.codigo === selectedCodigo);

  if (selectedProcess) {
      setProcessFilter(selectedCodigo);
      setSelectedNombreProyecto(selectedProcess.nombreProceso);
      setSelectedCodigoProyecto(selectedProcess.codigo);
      setSelectedComuna(selectedProcess.comuna);
  } else {
      setProcessFilter('');
      setSelectedNombreProyecto('');
      setSelectedCodigoProyecto('');
      setSelectedComuna('');
  }
};

const handleSelectedUsersChange = (users: User[]) => {
  setSelectedUsers(users);
  
  // Si hay al menos un usuario seleccionado, actualizar el emisor
  if (users.length > 0) {
    const user = users[0];
    setUsername(`${user.firstname} ${user.lastname}`);
    setEmisor(`${user.firstname} ${user.lastname} <${user.email}>`);
    setUserEmail(user.email);
    if (user.departamento) {
      setUserTitle(user.departamento);
    }
    
    console.log("Usuario seleccionado cambiado:", user);
  }
};

  const handleSort = (column: string) => {
    setSortDirection(sortColumn === column && sortDirection === 'asc' ? 'desc' : 'asc');
    setSortColumn(column);
  };


  const handleSelectChange = (nivelId: number, value: string) => {
    setSelectedValues(prevValues => ({
      ...prevValues,
      [nivelId]: value,
      
      
    }));

};

 // Function to get options based on nivel_id
    const getOptionsForNivel = (nivelId: number) => {
      return data
        .filter(item => item.nivel_id === nivelId)
        .map(item => (
          <option key={item.concepto} value={item.nomenclatura}>
            {`${item.nomenclatura} - ${item.concepto}`}
          </option>
        ));
    };




  const toggleDocumentSelection = (id: string) => {
    setSelectedDocuments(prev =>
      prev.includes(id) ? prev.filter(docId => docId !== id) : [...prev, id]
    );
  };

  /*const saveTransmittal = async (
    transmittal_number: number,
    codigo_proyecto: string,
    comuna: string,
    asunto: string,
    referencia: string,
    descripcion: string
  ) => {
    try {
      const response = await fetch('http://localhost:3000/php/pages/transmittal/save_transmittal.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          transmittal_number,
          codigo_proyecto,
          comuna,
          asunto,
          referencia,
          descripcion
        })
      });

      const data = await response.json();

      if (data.success) {
        console.log('Transmittal guardado con éxito:', data.transmittal_code);
      } else {
        console.error('Error al guardar el transmittal:', data.error);
      }
    } catch (error) {
      console.error('Error de red:', error);
    }*/



    //   const viewFile = (path: string) => {
    //     if (!path) {
    //         console.error('El path es undefined o vacío.');
    //         return;
    //     }
    
    //     const baseUrl = process.env.REACT_APP_DOCS_URL; // Asegúrate de tener esto en el .env
    //     if (!baseUrl) {
    //         console.error('REACT_APP_DOCS_URL no está definido en el .env');
    //         return;
    //     }
    
    //     const fullUrl = `${baseUrl}/uploads/${path}`;
    //     console.log('Abriendo URL:', fullUrl);
    //     window.open(fullUrl, '_blank');
    // };
    
    const viewFile = (path: string, type: 'document' | 'transmittal') => {
      if (!path) {
          console.error('El path es undefined o vacío.');
          return;
      }
  
      let fullUrl = path;
  
      if (type === 'document') {
          const baseUrl = process.env.REACT_APP_DOCS_URL; // Asegúrate de tener esto en el .env
          if (!baseUrl) {
              console.error('REACT_APP_DOCS_URL no está definido en el .env');
              return;
          }
  
          fullUrl = `${baseUrl}/uploads/${path}`;
      } else if (type === 'transmittal') {
          // Si es transmittal, el path ya es la URL completa.
          fullUrl = path;
      }
  
      console.log('Abriendo URL:', fullUrl);
      window.open(fullUrl, '_blank');
  };

  useEffect(() => {
    // Fetch handleselec from the backend
    const fetchNiveles = async () => {
      try {
        const response = await axios.get<Nivel[]>(`${process.env.REACT_APP_API_URL}/php/pages/controldocument/glosario/get_levels.php`);
        setNiveles(response.data);
        
      } catch (error) {
        console.error('Error fetching niveles:', error);
      }
    };

    const fetchData = async () => {
      try {
        const response = await axios.get<Data[]>(`${process.env.REACT_APP_API_URL}/php/pages/controldocument/glosario/get_levels_data.php`);
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchNiveles();
    fetchData();
  }, []);



  const getRowClassName = (id: string) => {
    return selectedDocuments.includes(id) ? 'bg-blue-100' : '';
  };

  const currentDocuments = [...documents]; // Puedes aplicar filtros o manipular documentos si es necesario

  return (
 
    <Dialog width="auto" height='full' isOpen={isOpen} onClose={onClose} className=" overflow-y-auto">
      <DialogContent className="max-h-screen mx-auto pr-12 pl-12 pt-7 overflow-y-auto bg-white rounded-lg">
       
        <h1 className="text-2xl font-bold mb-4 text-cyan-700">Formulario de Transmittal</h1>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">
          <InputField 
            label="N° Transmittal" 
            placeholder="N° Transmittal" 
            disabled
            value={transmittalNumber} 
            readOnly 
            className="text-sm"
          />
           <InputField 
            label="Código Transmittal" 
            placeholder="Código Transmittal" 
            value={codigoTransmittal} 
            readOnly 
            disabled
            className="text-sm"
          />
          <div>
        
          </div>
          
         
{/*           
          <InputField 
            label="Código de Proyecto" 
            placeholder="Código Proyecto" 
            value={selectedCodigoProyecto} 
            readOnly 
            disabled
            className="text-sm"
          />
          <InputField 
            label="Comuna" 
            placeholder="Comuna" 
            disabled
            value={selectedComuna} 
            readOnly 
            
            className="text-sm cursor-not-allowed"
          />  */}
     
        </div>
        <h2 className="text-xl font-semibold  text-cyan-600">Proceso</h2>

        {/* <select 
  id="processFilter"
  className="w-full border border-gray-300 rounded-md p-2 focus:ring-cyan-500 focus:border-cyan-500"
  value={processFilter}
  onChange={handleProjectFilterChange}
  aria-label="Filtro de Procesos"
>
  <option value="">Seleccionar proceso</option>
  {processes.length > 0 ? (
    processes.map((process) => (
      <option key={process.codigo} value={process.codigo}>
        {`${process.codigo} - ${process.nombreProceso} - ${process.comuna}`}
      </option>
    ))
  ) : (
    <option disabled>No hay procesos disponibles</option>
  )}
</select> */}

<select 
  id="processFilter"
  className="w-full border border-gray-300 rounded-md p-2 focus:ring-cyan-500 focus:border-cyan-500"
  value={processFilter}
  onChange={handleProjectFilterChange}
  aria-label="Filtro de Procesos"
>
  <option value="">Seleccionar proceso</option>
  {processes.length > 0 ? (
    processes.map((process) => (
      <option key={process.codigo} value={process.codigo}>
        {`${process.codigo} - ${process.nombreProceso} - ${process.comuna}`}
      </option>
    ))
  ) : (
    <option disabled>No hay procesos disponibles</option>
  )}
</select>


        <br></br> 
        <br></br>
        
        <div>
        <h2 className="text-xl font-semibold  text-cyan-600">Subproceso</h2>
            <select
        className="w-full border border-gray-300 rounded-md p-2 focus:ring-cyan-500 focus:border-cyan-500"
        onChange={handleSubprocesoChange}
        value={subprocesos.find(sub => sub.subproceso === selectedSubproceso)?.id || ''}
      >
          <option value="">Seleccione un subproceso</option>
        {subprocesos.map((subproceso) => (
          <option key={subproceso.id} value={subproceso.id}>
            {subproceso.subproceso}
          </option>
        ))}
      </select>
        </div>
        <br></br>
        <h2 className="text-xl font-semibold  text-cyan-600">Asunto</h2>

        <InputField 
          label="" 
          placeholder="Asunto" 
          className="mb-4 text-sm"
          value={asunto}
          onChange={handleAsuntoChange}
          inputClassName="w-full border-2 border-cyan-500 rounded-md p-2 focus:ring-2 focus:ring-cyan-500 uppercase"
        />
        <div className="mb-8 mt-5">
          <h2 className="text-xl font-semibold mb-4 text-cyan-600">Propósito Transmittal</h2>
          <div className="bg-white rounded-md">
          <TransmittalComboBox
  detalleTransmittal={detalleTransmittal}
  setDetalleTransmittal={setDetalleTransmittal}
  setPurposeSelected={setIsPurposeSelected} // Esta línea falta
/>

          </div>
        </div>
        <div className="mb-4">
          <h2 className="text-xl font-semibold mb-2 text-cyan-600">Destinatarios</h2>
         {/* <div className="grid grid-cols-1 md:grid-cols-3 gap-2"> */}
            {/* <InputField label="De:" value={emisor} disabled readOnly className="text-sm" /> */}
            <div className="flex items-center">
 
  <InputField 
    label="De:" 
    value={emisor} 
    disabled 
    readOnly 
    className="text-sm flex-1 mr-2 mb-5" 
  />
<UserSelectorIcon
  selectedUsers={selectedUsers}
  enabled={true}
  isClosed={false}
  subprocessColor="#23C25F"
  onClick={openUserSelector}
/>
</div>
          <AutoCompleteEmailInput
        label="Para:"
        emailOptions={emailOptions}
        onChange={setToEmail}
      /><br></br>
       <AutoCompleteEmailInput
        label="CC:"
        emailOptions={emailOptions}
        onChange={setCcEmail}
      />
        </div>

        
      
        
      {/*  <InputField 
          label="Referencia" 
          placeholder="Referencia" 
          className="mb-4 text-sm"
          value={referencia}
          onChange={handleReferenciaChange}
          inputClassName="w-full border-2 border-cyan-500 rounded-md p-3 focus:ring-2 focus:ring-cyan-300 focus:border-cyan-500 transition duration-300"
        />
        
        <div className="mb-8">
          <label className="block text-sm font-medium text-gray-700 mb-2">Descripción Transmittal</label>
          <textarea 
            className="w-full border-2 border-cyan-500 rounded-md p-3 focus:ring-2 focus:ring-cyan-300 focus:border-cyan-500 transition duration-300" 
            rows={4} 
            placeholder="Descripción"
            value={descripcion}
            onChange={handleDescripcionChange}
          ></textarea>
        </div>*/}
 
            <div className='grid grid-cols-1 block '>
           
           
        {/*niveles.slice(0,1).map(nivel => (
          <div key={nivel.nivel_id} className='mb-6'>
            <label htmlFor={`select-${nivel.nivel_id}`} className="text-xl font-semibold mb-6 text-cyan-600 ">
              {nivel.titulo}
            </label>
            <select
              id={`select-${nivel.nivel_id}`}
              
              value={selectedValues[nivel.nivel_id] || ''}
              onChange={(e) => handleSelectChange(nivel.nivel_id, e.target.value)}
             
              className="p-2 border border-gray-300 rounded w-full"
            >
              <option value="">Seleccione {nivel.titulo}</option>
              {getOptionsForNivel(nivel.nivel_id)}
            </select>
          </div>
        ))*/}
        
          

      
        </div>



        <div className="overflow-x-auto">
        <h2 className="text-xl font-semibold mb-4 text-cyan-600">Documentos seleccionados</h2>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Documento</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <button onClick={() => handleSort('title')} className="uppercase flex items-center">
                    Título
                    {sortColumn === 'title' ? (sortDirection === 'asc' ? ' 🔼' : ' 🔽') : ''}
                  </button>
                </th>
            
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Fecha</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Emitido Por</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Comentario</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Documento</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {currentDocuments.sort((a, b) => {
                if (sortColumn) {
                  const aVal = a[sortColumn as keyof Document] || '' ?.toString().toLowerCase();
                  const bVal = b[sortColumn as keyof Document] || '' ?.toString().toLowerCase();
                  if (aVal < bVal) return sortDirection === 'asc' ? -1 : 1;
                  if (aVal > bVal) return sortDirection === 'asc' ? 1 : -1;
                }
                return 0;
              }).map((doc) => (
                <tr key={doc.id} className={getRowClassName(doc.id)}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{`${doc.document}-REV${doc.revision}-v${doc.version}`}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{doc.title}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"><td className="px-2 py-1 text-sm text-gray-500 whitespace-pre">
  {doc.date.split(' ')[0] + '\n' + doc.date.split(' ')[1]}
</td>
</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{doc.issuedBy}</td>
                  {/* <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{doc.comment}</td> */}
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {doc.comment ? (
                        <button
                          onClick={() => openModal(doc.comment)}
                          className="text-gray-500 hover:text-gray-700"
                        >
                          <MessageCircle className="w-5 h-5" />
                        </button>
                      ) : (
                        <span className="text-gray-300">—</span>
                      )}
                    </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
                  <button className='text-gray-500' onClick={() => viewFile(doc.path, doc.itemType)}>
                      <Paperclip />
                  
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        
        <div className="mt-8">
          <label className="block text-sm font-medium text-gray-700 mb-2">Comentarios</label>
          <textarea 
            className="w-full border-2 border-cyan-500 rounded-md p-3 focus:ring-2 focus:ring-cyan-300 focus:border-cyan-500 transition duration-300 uppercase" 
            rows={4} 
            value={comentarios}
            onChange={handleComentariosChange}
            placeholder="Ingrese sus comentarios aquí"
          ></textarea>
        </div>

        {!message && (
          <div className="mt-6 flex items-center justify-between">
  <div className="flex-grow">
  <button 
  onClick={handleCancelTransmittal} 
  disabled={isLoading}
  className={`p-3 text-white rounded 
    ${isLoading ? 'bg-teal-300 cursor-not-allowed' : 'bg-teal-900 hover:bg-teal-700'}`}
>
  Cancelar
</button>

  </div>
  <div className="flex space-x-4">
    <div className="relative group">
    <button
  className={`font-semibold py-2 px-4 rounded flex items-center gap-2 ${
    isLoading 
      ? 'bg-teal-300 cursor-not-allowed' 
      : (getMissingFields().length === 0 && !loading
          ? 'bg-teal-500 hover:bg-teal-700 text-white' 
          : 'bg-teal-300 text-gray-100 cursor-not-allowed')
  }`}
  
  disabled={isLoading}

  onClick={(event) => {
    event.preventDefault();
    
    const missing = getMissingFields();
    if (missing.length > 0) {
      showToastError(getErrorMessage());
      return;
    }
    
    handlePreview();
  }}
>
  {loading ? (
    <>
      <Loader className="animate-spin w-5 h-5" /> Cargando...
    </>
  ) : (
    "Previsualizar"
  )}
</button>
    
    </div>
    <div className="relative group">
    <button 
  className={`font-semibold py-2 px-4 rounded flex items-center ${
    getMissingFields().length === 0 && !isLoading && !isbuttonDisabled
      ? 'bg-teal-500 hover:bg-teal-700 text-white' 
      : 'bg-teal-300 text-gray-100 cursor-not-allowed'
  }`}
  onClick={(event) => {
    event.preventDefault();
    
    const missing = getMissingFields();
    if (missing.length > 0) {
      showToastError(getErrorMessage());
      return;
    }
    
    handleSubmit();
  }}
>
        {isLoading ? (
          <>
            <svg
              className="animate-spin h-5 w-5 mr-3 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8v8H4z"
              ></path>
            </svg>
            Enviando...
          </>
        ) : (
          'Guardar y enviar'
        )}
      </button>
   
    </div>
  </div>
</div>
       )}

  {/* Modal para Mostrar Comentario */}
  {isModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-96">
              <h2 className="text-lg font-semibold text-gray-900 mb-4">Comentario</h2>
              <p className="text-gray-700">{selectedComment}</p>
              <button
                onClick={closeModal}
                className="mt-4 px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700"
              >
                Cerrar
              </button>
            </div>
          </div>
        )}

         {/* Mostrar el mensaje */}
    {message && (
      <div
        className={`mt-4 p-2 text-white text-center rounded ${
          message.type === 'success' ? 'bg-green-700 text-white' : 'bg-red-700 text-white'
        }`}
      >
        {message.text}
      </div>
    )}
<UserSelectorModal
  isOpen={isUserSelectorOpen}
  onClose={() => setIsUserSelectorOpen(false)}
  users={[
    // Convertir las opciones de email a objetos User con IDs consistentes
    ...emailOptions.map(option => {
      // Generar un ID determinístico basado en el email para poder identificarlo consistentemente
      const id = parseInt(option.email.replace(/[^a-z0-9]/gi, '').substring(0, 8), 36) || 
                 Math.floor(Math.random() * 1000);
                 
      return {
        id,
        username: option.name,
        firstname: option.name.split(' ')[0] || '',
        lastname: option.name.split(' ').slice(1).join(' ') || '',
        email: option.email,
        is_admin: false,
        is_active: true,
        departamento: ''
      };
    })
  ]}
  selectedUsers={selectedUsers}
  onUsersChange={handleSelectedUsersChange}
  enabled={true}
  taskId={-1}
  groupId={-1}
  isClosed={false}
  subprocessColor="#23C25F"
/>


      </DialogContent>
    </Dialog>

);

};


interface EmailOption {
  name: string;
  email: string;
}

interface AutoCompleteEmailInputProps {
  label: string;
  emailOptions: EmailOption[];
  onChange: (value: string) => void;
}



const AutoCompleteEmailInput: React.FC<AutoCompleteEmailInputProps> = ({ label, emailOptions, onChange }) => {
  const [query, setQuery] = useState('');
  const [filteredEmails, setFilteredEmails] = useState<EmailOption[]>([]);
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isFocused) {
      const lastEmail = query.split(',').pop()?.trim() || '';
      if (lastEmail === '') {
        setFilteredEmails(emailOptions);
      } else {
        setFilteredEmails(
          emailOptions.filter(option => 
            option.email.toLowerCase().includes(lastEmail.toLowerCase()) || 
            option.name.toLowerCase().includes(lastEmail.toLowerCase())
          )
        );
      }
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  }, [query, emailOptions, isFocused]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setQuery(value);
    onChange(value);
    
    // Si el último carácter es una coma, abrimos las sugerencias
    if (value.endsWith(',')) {
      setShowSuggestions(true);
      setIsFocused(true);
    }
  };

  const handleSuggestionClick = (option: EmailOption) => {
    const emails = query.split(',');
    emails.pop();
    const newEmail = `${option.name} <${option.email}>`;
    const updatedQuery = [...emails, newEmail].join(', ').trim();
    setQuery(updatedQuery);
    onChange(updatedQuery);

    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        const length = updatedQuery.length;
        inputRef.current.setSelectionRange(length, length);
        setIsAnimating(true);
        setTimeout(() => setIsAnimating(false), 300);
      }
    }, 0);

    // Cerramos las sugerencias después de seleccionar una
    setShowSuggestions(false);
  };


  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const emails = query.split(',');
      const lastEmail = emails.pop()?.trim() || '';
      if (lastEmail) {
        const updatedQuery = [...emails, lastEmail].join(', ').trim();
        setQuery(updatedQuery);
        onChange(updatedQuery);
        setShowSuggestions(true);
        setIsFocused(true);
      }
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setTimeout(() => {
      setIsFocused(false);
    }, 200);
  };




  return (
    <div className="relative">
      <label className="block text-sm font-semibold mb-2">{label}</label>
      <input
        ref={inputRef}
        type="text"
        value={query}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className={`p-2 border border-gray-300 rounded w-full ${isAnimating ? 'animate-pulse' : ''}`}
        placeholder="Ingrese correos electrónicos separados por comas"
      />
      {showSuggestions && filteredEmails.length > 0 && (
        <ul className="absolute top-full left-0 mt-2 w-full bg-white border border-gray-300 rounded shadow-lg z-10 max-h-60 overflow-y-auto">
          {filteredEmails.map(option => (
            <li
              key={option.email}
              onMouseDown={() => handleSuggestionClick(option)}
              className="p-2 cursor-pointer hover:bg-gray-200"
            >
              {`${option.name} <${option.email}>`}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};


interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  comment: string;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, comment }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-4 rounded shadow-lg w-1/3">
        <button onClick={onClose} className="absolute top-2 right-2 text-gray-500">
          ✖
        </button>
        <h2 className="text-xl mb-2">Comentario</h2>
        <p>{comment}</p>
      </div>
    </div>
  );
};


const Dropzone: React.FC<{
  label: string;
  files: File[];
  onFilesChange: (files: File[]) => void;
  onRemoveFile: (fileName: string, type: 'principal' | 'complementario') => void;
  type: 'principal' | 'complementario';
}> = ({ label, files, onFilesChange, onRemoveFile, type }) => {
  const fileInputRef = React.createRef<HTMLInputElement>();
  const [isDragging, setIsDragging] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const handleDrop = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
    const droppedFiles = Array.from(event.dataTransfer.files);
    if (droppedFiles.length > 0) {
      setIsUploading(true);
      setTimeout(() => {
        onFilesChange([...files, ...droppedFiles]);
        setIsUploading(false);
      }, 1000);
    }
  }, [files, onFilesChange]);


  

 

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const handleFileSelect = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(event.target.files || []);
    if (selectedFiles.length > 0) {
      setIsUploading(true);
      setTimeout(() => {
        onFilesChange([...files, ...selectedFiles]);
        setIsUploading(false);
      }, 1000);
    }
  };


  return (
    <div className="flex flex-col items-center">
      {files.length === 0 && (
        <div
          className={`border-2 ${isDragging ? 'border-blue-500' : 'border-dashed border-gray-300'} rounded-lg p-6 w-full max-w-4xl flex flex-col items-center justify-center ${isUploading ? 'bg-gray-100' : ''}`}
          style={{ minHeight: '100px' }} // Ajusta la altura mínima según tus necesidades
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onClick={handleFileSelect}
        >
          {isUploading ? (
            <div className="flex flex-col items-center">
              <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-500 mb-2"></div>
              <p>Cargando...</p>
            </div>
          ) : (
            <>
              <p className="text-center text-lg font-semibold mb-2">{label}</p>
              <p className="text-center text-sm text-gray-400">Arrastra y suelta los archivos aquí o haz clic para seleccionar</p>
              <input
                ref={fileInputRef}
                type="file"
                className="hidden"
                multiple
                onChange={handleFileChange}
              />
            </>
          )}
        </div>
      )}
      {files.length > 0 && (
        <div className="mt-4 w-full max-w-4xl">
          <h4 className="font-medium mb-2 text-center">Archivos cargados:</h4>
          <div className="space-y-2">
            {files.map((file) => (
              <div key={file.name} className="flex items-center justify-between p-2 border rounded bg-gray-50">
                <span className="text-sm">{file.name}</span>
                <div className="flex items-center">
                 
               
                 
                  <button
                    onClick={() => onRemoveFile(file.name, type)}
                    className="text-red-500 hover:text-red-600 mr-2"
                  >
                    <Trash className="w-5 h-5" />
                  </button>
                  <Paperclip className="w-5 h-5" />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};





interface Option {
  value: string;
  label: string;
}


interface Nivel {
  nivel_id: number;
  titulo: string;
}

interface Data {
  nivel_id: number;
  concepto: string;
  nomenclatura: string;
}



interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-10 z-[500]">
      <div className="bg-white p-4 rounded shadow-lg">
        <h2 className="text-lg font-bold mb-4">Confirmar eliminación</h2>
        <p className="mb-4">¿Estás seguro de que deseas eliminar este documento?</p>
        <div className="flex justify-end">
          <button 
            className="bg-blue-500 text-white px-4 py-2 rounded mr-2" 
            onClick={onConfirm}
          >
            Confirmar
          </button>
          <button 
            className="bg-gray-500 text-white px-4 py-2 rounded" 
            onClick={onClose}
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  );
};
interface Process {
  codigo: string;
  nombreProceso: string;
  comuna: string;
}


interface Proceso {
  id: string;
  nombreProceso: string;
  codigo: string;
  comuna: string;
}


interface Transmittal {
  numero: number;
  codigo_proyecto: string;
  subprocesos: string[];
  comuna: string;
  emisor: string;
  destinatarios: string;
  cc: string;
  asunto: string;
  referencia: string;
  descripcion: string;
  subproceso: string;
  detalle_transmittal: string;
  documentos_seleccionados: string; // JSON en bruto
  estado: string;
  comentarios: string;
  codigo_transmittal: string;
  fecha: string;
  ruta: string;
}

// Componente para el modal de selección de procesos
const ProcessSelectionModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  onSelectProcess: (procesoId: string) => void;
  procesos: Proceso[];
  currentProcesoId: string;
}> = ({ isOpen, onClose, onSelectProcess, procesos, currentProcesoId }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProcesos, setFilteredProcesos] = useState<Proceso[]>([]);


  // Filtrar procesos basados en el término de búsqueda
  useEffect(() => {
    const filtered = procesos.filter(
      (proceso) =>
        proceso.nombreProceso.toLowerCase().includes(searchTerm.toLowerCase()) ||
        proceso.codigo.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (proceso.comuna && proceso.comuna.toLowerCase().includes(searchTerm.toLowerCase()))
    );
    setFilteredProcesos(filtered);
  }, [searchTerm, procesos]);

  return (
    <Dialog width="700px" isOpen={isOpen} onClose={onClose}>
      <DialogContent className="p-6">
        <DialogHeader>
          <DialogTitle>Seleccionar Proceso</DialogTitle>
        </DialogHeader>

        {/* Campo de búsqueda */}
        <div className="mb-4">
          <input
            type="text"
            placeholder="Buscar proceso por nombre, código o comuna..."
            className="w-full p-2 border border-gray-300 rounded"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        {/* Lista de procesos */}
        <div className="max-h-96 overflow-y-auto border rounded">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50 sticky top-0">
              <tr>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Código</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Nombre</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Comuna</th>
                <th className="px-4 py-2 text-center text-xs font-medium text-gray-500 uppercase">Seleccionar</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredProcesos.map((proceso) => (
                <tr key={proceso.id} className={(`${proceso.codigo}  ${proceso.nombreProceso}`) === currentProcesoId ? 'bg-teal-50' : 'hover:bg-gray-50'}>
                  <td className="px-4 py-2 text-sm">{proceso.codigo}</td>
                  <td className="px-4 py-2 text-sm">{proceso.nombreProceso}</td>
                  <td className="px-4 py-2 text-sm">{proceso.comuna}</td>
                  <td className="px-4 py-2 text-center">
                    <button
                      onClick={() => onSelectProcess((`${proceso.codigo}  ${proceso.nombreProceso}`))}
                      className={`px-3 py-1 rounded text-white ${
                        (`${proceso.codigo}  ${proceso.nombreProceso}`) === currentProcesoId ? 'bg-teal-700' : 'bg-teal-500 hover:bg-teal-600'
                      }`}
                      disabled={(`${proceso.codigo}  ${proceso.nombreProceso}`) === currentProcesoId}
                    >
                      {(`${proceso.codigo}  ${proceso.nombreProceso}`) === currentProcesoId ? 'Seleccionado' : 'Seleccionar'}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="mt-6 flex justify-end">
          <button className="border border-black bg-gray-300 hover:bg-gray-400 text-gray-800" onClick={onClose}>
            Cancelar
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

type Column = {
  key: string;
  label: string;
  filterable?: boolean;
  options?: { label: string; value: string }[];
};

type TableHeaderProps = {
  column: Column;
  sortColumn: string | null;
  sortDirection: 'asc' | 'desc';
  onSort: (key: string) => void;
  onFilter: (key: string, value: string) => void;
  filterValue: string;
  onFilterChange: (key: string, value: string) => void;
  onFilterClear: (key: string) => void;
};


// Main TableHeader component with sorting and filtering capabilities
const TableHeader: React.FC<TableHeaderProps> = ({
  column, 
  sortColumn, 
  sortDirection, 
  onSort, 
  onFilter,
  filterValue,
  onFilterChange,
  onFilterClear
}) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  // Determine the current sort state for this column
  const isSorted = sortColumn === column.key;
  const sortIcon = isSorted ? (
    sortDirection === 'asc' ? <ArrowUp size={14} /> : <ArrowDown size={14} />
  ) : null;

  // Handle click on column header for sorting
  const handleSortClick = () => {
    onSort(column.key);
  };

  // Handle filter toggle
  const toggleFilter = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsFilterOpen(!isFilterOpen);
  };

  // Handle filter input change

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onFilterChange(column.key, e.target.value);
  };

  // Handle filter clear
  const handleFilterClear = (e: React.MouseEvent) => {
    e.stopPropagation();
    onFilterClear(column.key);
  };



  

  // Handle clicking outside to close filter dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        isFilterOpen &&
        !(event.target as HTMLElement).closest(`.filter-dropdown-${column.key}`)
      ) {
        setIsFilterOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isFilterOpen, column.key]);

  return (
    <th className="border-b p-2 text-left">
      <div className="flex items-center justify-between">
        <div 
          className="flex items-center cursor-pointer select-none hover:text-cyan-700" 
          onClick={handleSortClick}
        >
          <span className="mr-1">{column.label}</span>
          {sortIcon}
        </div>
        
        <div className={`relative inline-block filter-dropdown-${column.key}`}>
          <button 
            className={`p-1 rounded-full hover:bg-cyan-100 focus:outline-none ${filterValue ? 'bg-cyan-200' : ''}`}
            onClick={toggleFilter}
          >
            <Filter size={14} className={filterValue ? 'text-cyan-700' : 'text-gray-500'} />
          </button>
          
          {isFilterOpen && (
            <div className="absolute z-10 right-0 mt-1 w-64 bg-white rounded-md shadow-lg border">
              <div className="p-3">
                <div className="flex items-center justify-between mb-2">
                  <div className="text-sm font-medium">Filtrar {column.label}</div>
                  <button onClick={() => setIsFilterOpen(false)} className="text-gray-400 hover:text-gray-600">
                    <X size={16} />
                  </button>
                </div>
                
                <div className="relative">
                  <input
                    type="text"
                    className="w-full border border-gray-300 rounded-md px-3 py-2 pl-8 text-sm"
                    placeholder={`Buscar ${column.label.toLowerCase()}...`}
                    value={filterValue || ''}
                    onChange={handleFilterChange}
                    autoFocus
                  />
                  <Search size={14} className="absolute left-2.5 top-2.5 text-gray-400" />
                  {filterValue && (
                    <button 
                      className="absolute right-2.5 top-2.5 text-gray-400 hover:text-gray-600"
                      onClick={handleFilterClear}
                    >
                      <X size={14} />
                    </button>
                  )}
                </div>
                
                {column.options && (
                  <div className="mt-3 max-h-40 overflow-y-auto">
                    {column.options.map(option => (
                      <div key={option.value} className="flex items-center mb-1 text-sm">
                        <input
                          type="checkbox"
                          id={`${column.key}-${option.value}`}
                          className="mr-2"
                        />
                        <label htmlFor={`${column.key}-${option.value}`}>
                          {option.label}
                        </label>
                      </div>
                    ))}
                  </div>
                )}
                
                <div className="mt-3 flex justify-end">
                  <button 
                    className="bg-cyan-500 text-white px-3 py-1 rounded-md text-sm hover:bg-cyan-600"
                    onClick={toggleFilter}
                  >
                    Aplicar
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </th>
  );
};


interface Document {
  id: string;
  project: string;
  projectName: string;
  comuna: string;
  projectCode: string;
  document: string;
  title: string;
  itemType: 'document' | 'transmittal';
  correlativo: string;
  revision: string;
  version: number;
  date: string;
  issuedBy: string;
  comment: string;
  path: string;
  flagged: boolean;
  subVersions?: Document[];
  mergeSortKey?: number;

}


const DocumentSearch: React.FC = () => {
  const [isProcessModalOpen, setIsProcessModalOpen] = useState<boolean>(false);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [filteredDocuments, setFilteredDocuments] = useState<Document[]>([]);
  const [clickedDocuments, setClickedDocuments] = useState<Document[]>([]);
  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentDocument, setCurrentDocument] = useState<Document | null>(null);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isFileLoaded, setIsFileLoaded] = useState(false);
  const [isTransmittalModalOpen, setIsTransmittalModalOpen] = useState(false);
  const [keepCurrentVersion, setKeepCurrentVersion] = useState<boolean>(false);
  const [currentRevision, setCurrentRevision] = useState<string>('');
  const [currentVersion, setCurrentVersion] = useState<number>(1);
  //Sort states
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [transmittals, setTransmittals] = useState<Transmittal[]>([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [selectedProcesoName, setSelectedProcesoName] = useState<string>('');

  const [projectDisplayOptions, setProjectDisplayOptions] = useState<{value: string, display: string}[]>([]);
  
  // Estados adicionales
const [procesos, setProcesos] = useState<Proceso[]>([]);
  
  // Filter states
  const [projectFilter, setProjectFilter] = useState('');
  const [titleFilter, setTitleFilter] = useState('');
  const [subFolderFilter, setSubFolderFilter] = useState('');
  const [specialtyFilter, setSpecialtyFilter] = useState('');
  const [docTypeFilter, setDocTypeFilter] = useState('');
  const [commentFilter, setCommentFilter] = useState('');

  const [projectOptions, setProjectOptions] = useState<string[]>([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [documentsPerPage, setDocumentsPerPage] = useState(25);

  const [proyectoSeleccionado, setProyectoSeleccionado] = useState<string>('');
  const [proyectos, setProyectos] = useState<Option[]>([]);
  
   // Estado para almacenar el nombre del usuario
  const [username, setUsername] = useState<string | null>(null);
  const [emisor, setEmisor] = useState<string | null>(null);
  const [tituloDocumento, setTituloDocumento] = useState('');
  const [codigo, setCodigo] = useState<string>('');
  const [correlativo, setCorrelativo] = useState<string>('');
  const [revision, setRevision] = useState<string>('');
  const [version, setVersion] = useState<number>(1);
  const [comentarios, setComentarios] = useState('');
  const [archivos, setArchivos] = useState<File[]>([]);
  const [isFilesLoaded, setIsFilesLoaded] = useState(false);
  const [archivosComplementarios, setArchivosComplementarios] = useState<File[]>([]);
  const [documentosCargados, setDocumentosCargados] = useState<any[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [editIndex, setEditIndex] = useState<number | null>(null);

  const [niveles, setNiveles] = useState<Nivel[]>([]);
  const [data, setData] = useState<Data[]>([]);
  const [selectedValues, setSelectedValues] = useState<{ [key: string]: string }>({});
  const [modalValues, setModalValues] = useState<{ [key: string]: string }>({});;
  const [modalProject, setModalProject] = useState('');
 

  const [checkedDocuments, setCheckedDocuments] = useState<Document[]>([]);

  const [labelMessage, setLabelMessage] = useState<string>(''); // Cambiado a estado
  const [existsRevision, setExistsRevision] = useState<boolean>(false);
  const [existsDocument, setExistsDocument] = useState<boolean>(false);
  const [isSubVersion, setIsSubVersion] = useState<number>(0);
  const [newRevision, setNewRevision] = useState<string>('');
  const [newVersion, setNewVersion] = useState<number>(1);



// Estados para controlar cambios y valores originales
const [selectedProcesoId, setSelectedProcesoId] = useState<string>('');
const [originalProcesoId, setOriginalProcesoId] = useState('');

useEffect(() => {
  // Verificar si hay al menos un nivel seleccionado para activar el filtrado
  const hayNivelesSeleccionados = Object.values(selectedValues).some(value => value !== '');
  
  if (!hayNivelesSeleccionados) {
    // Si no hay niveles seleccionados, no aplicamos este filtro
    return;
  }
  
  // Aplicar filtro por niveles a los documentos ya filtrados por otros criterios
  const filtradoPorNiveles = filteredDocuments.filter(doc => {
    // Extraer los niveles del código del documento
    let nivelesDocumento: string[] = [];
    if (doc.document) {
      const partes = doc.document.split('-');
      if (partes.length > 2) {
        // Excluir la primera parte (código del proyecto) y la última (correlativo)
        nivelesDocumento = partes.slice(1, -1);
      }
    }
    
    // Verificar si cada nivel seleccionado coincide con el documento
    return Object.entries(selectedValues).every(([nivelId, valorSeleccionado]) => {
      // Si no hay valor seleccionado para este nivel, se considera coincidencia
      if (!valorSeleccionado) return true;
      
      // Convertir el ID del nivel (base 1) a índice de array (base 0)
      const indiceNivel = parseInt(nivelId) - 1;
      
      // Verificar si el nivel del documento coincide con el valor seleccionado
      // También verificamos que el índice sea válido para el array de niveles del documento
      return indiceNivel >= 0 && 
             indiceNivel < nivelesDocumento.length && 
             nivelesDocumento[indiceNivel] === valorSeleccionado;
    });
  });
  
  // Actualizar el estado con los documentos filtrados
  setFilteredDocuments(filtradoPorNiveles);
  
  // Resetear a la primera página cuando cambia un filtro
  setCurrentPage(1);
  
}, [selectedValues, filteredDocuments]); // Dependencias: valores seleccionados y documentos ya filtrados

useEffect(() => {
  if (currentDocument?.project) {
      const procesoEncontrado = procesos.find(proc => proc.nombreProceso === currentDocument.project);
      if (procesoEncontrado) {
          setSelectedProcesoId(procesoEncontrado.id.toString());
      } else {
          setSelectedProcesoId("");  // Si no lo encuentra, selecciona vacío
      }
  }
}, [currentDocument, procesos]);


// Función para cargar la lista de procesos
const fetchProcesos = async (): Promise<void> => {
  try {
    const response = await axios.get<Proceso[]>(`${process.env.REACT_APP_API_URL}/php/pages/proceso/get_processes.php?type=type4`);
    setProcesos(response.data);
  } catch (error) {
    console.error('Error al cargar procesos:', error);
  }
};

// Ejecutar al montar el componente
useEffect(() => {
  fetchProcesos();
}, []);

// Al abrir el modal, guardar valores originales
useEffect(() => {
  if (isEditModalOpen && currentDocument) {
    setOriginalRevision(currentDocument.revision);
    setOriginalVersion(currentDocument.version);
    setSelectedProcesoId(currentDocument.project);
    setOriginalProcesoId(currentDocument.project);
    const procesoEncontrado = procesos.find(proc => proc.nombreProceso === currentDocument.project);
    if (procesoEncontrado) {
      setSelectedProcesoName(`${procesoEncontrado.codigo} - ${procesoEncontrado.nombreProceso}`);
    }
    // Resto de la inicialización...
  }
}, [isEditModalOpen, currentDocument]);




// Estados adicionales para validación
const [isRevisionValid, setIsRevisionValid] = useState<boolean>(true);
const [isVersionValid, setIsVersionValid] = useState<boolean>(true);
const [originalRevision, setOriginalRevision] = useState<string>('');
const [originalVersion, setOriginalVersion] = useState<number>(1);
const [prohibitedRevisions, setProhibitedRevisions] = useState<string[]>([]);
const [allowedRevisions, setAllowedRevisions] = useState<string[]>([]);
// Al cargar el documento actual
useEffect(() => {
  if (currentDocument) {
    setOriginalRevision(currentDocument.revision);
    setOriginalVersion(currentDocument.version);
  }
}, [currentDocument]);

// Función para validar revisión localmente
const validateRevision = (value: string): void => {
  if (prohibitedRevisions.includes(value)) {
    setIsRevisionValid(false);
    setLabelMessage(`Revisión ${value} no está permitida. Revisiones permitidas: ${allowedRevisions.join(', ')}`);
  } else {
    setIsRevisionValid(true);
    // Si la validación local pasa, verificar con el backend
    checkRevisionWithBackend(value);
  }
};
// Función para validar versión localmente
const validateVersion = (value: number): void => {
  if (value < 1) {
    setIsVersionValid(false);
    setLabelMessage('La versión debe ser mayor o igual a 1');
  } else if (revision === originalRevision && value < originalVersion) {
    setIsVersionValid(false);
    setLabelMessage(`Al mantener la misma revisión, la versión debe ser mayor que ${originalVersion}`);
  } else {
    setIsVersionValid(true);
    setLabelMessage('');
  }
};


// Función para consultar al backend para validación de revisión

const checkRevisionWithBackend = async (revisionValue: string): Promise<void> => {
  if (!revisionValue) return;
  
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/php/pages/controldocument/cargardocumento/compararCodigo.php`,
      {
        codigo: codigo,
        revision: revisionValue,
        version: version
      }
    );
    
    const data = response.data;
    setExistsDocument(data.existsDocument || false);
    setExistsRevision(data.existsRevision || false);
    setProhibitedRevisions(data.prohibited_revisions || []);
    setAllowedRevisions(data.allowed_revisions || []);
    setNewRevision(data.new_revision || revisionValue);
    setNewVersion(data.new_version || 1);
    
    // Guardamos la revisión y versión actual para permitir mantenerla si el usuario lo desea
    if (data.existsDocument && data.existsRevision) {
      setCurrentRevision(data.revision || revisionValue);
      setCurrentVersion(data.version || 1);
    }
    
    if (data.revision_allowed === false) {
      setIsRevisionValid(false);
    }
    
    // Actualizar mensaje con opciones para el usuario
    if (data.existsDocument) {
      if (data.existsRevision) {
        setLabelMessage(`El documento ya existe y se encuentra en la revisión ${data.revision} versión ${data.version}. Puede mantener la revisión y versión actual o cambiar a revisión ${data.new_revision} versión ${data.new_version}`);
      } else {
        setLabelMessage(`El documento ya existe y se encuentra en la revisión ${data.revision} versión ${data.version}. Si continúa, el documento cambiará la revisión a ${data.new_revision} versión ${data.new_version}`);
      }
    } else {
      setLabelMessage(`El documento se ingresará con revisión ${data.new_revision} versión ${data.new_version}`);
    }
  } catch (error) {
    console.error('Error al verificar la revisión:', error);
    setLabelMessage('Error al verificar la revisión');
  }
};

// Función para manejar la decisión del usuario de mantener la versión actual
const handleKeepCurrentVersion = (keep: boolean): void => {
  setKeepCurrentVersion(keep);
  
  if (keep) {
    // Si el usuario decide mantener la versión actual
    setLabelMessage(`Se mantendrá el documento con la revisión ${currentRevision} versión ${currentVersion}`);
  } else {
    // Si el usuario decide usar la nueva versión sugerida
    setLabelMessage(`El documento cambiará a revisión ${newRevision} versión ${newVersion}`);
  }
};

// Función para obtener la revisión y versión a usar en el guardado final
const getFinalRevisionAndVersion = (): { revision: string, version: number } => {
  if (existsDocument && existsRevision && keepCurrentVersion) {
    return { 
      revision: currentRevision, 
      version: currentVersion 
    };
  } else {
    return { 
      revision: newRevision, 
      version: newVersion 
    };
  }
};

// Componente de UI para permitir al usuario elegir si mantener la versión actual
const RevisionOptions = () => {
  if (!existsDocument || !existsRevision) return null;
  
  return (
    <div className="revision-options">
      <h4>Opciones de revisión:</h4>
      <label>
        <input
          type="radio"
          name="revisionOption"
          checked={!keepCurrentVersion}
          onChange={() => handleKeepCurrentVersion(false)}
        />
        Usar revisión {newRevision} versión {newVersion} (recomendada)
      </label>
      <label>
        <input
          type="radio"
          name="revisionOption"
          checked={keepCurrentVersion}
          onChange={() => handleKeepCurrentVersion(true)}
        />
        Mantener revisión {currentRevision} versión {currentVersion} actual
      </label>
    </div>
  );
};










useEffect(() => {
  // Filtra los documentos principales que están seleccionados
  const selectedMainDocuments = filteredDocuments.filter(doc => selectedDocuments.includes(doc.id));

  // Filtra las subversiones de los documentos seleccionados
  const selectedSubVersions = filteredDocuments.flatMap(doc => 
    doc.subVersions ? doc.subVersions.filter(subDoc => selectedDocuments.includes(subDoc.id)) : []
  );

  // Combina los documentos principales y las subversiones seleccionadas
  const updatedCheckedDocuments = [...selectedMainDocuments, ...selectedSubVersions];
  
  setCheckedDocuments(updatedCheckedDocuments);
}, [filteredDocuments, selectedDocuments]);
  
  const openDeleteModal = (doc: Document) => {
    // Guardar el documento completo para tener acceso a todos sus datos
    setCurrentDocument(doc);
    
    // También establecer el ID en selectedDocuments para mantener compatibilidad
    setSelectedDocuments([doc.id]);
    
    // Abrir el modal de confirmación
    setIsDeleteModalOpen(true);
  };
  
  // Para eliminar directamente desde la vista de subdocumentos
  const openSubdocumentDeleteModal = (mainDoc: Document, subDoc: Document) => {
    // Guardar el subdocumento para eliminarlo
    setCurrentDocument(subDoc);
    
    // También establecer el ID en selectedDocuments para mantener compatibilidad
    setSelectedDocuments([subDoc.id]);
    
    // Abrir el modal de confirmación
    setIsDeleteModalOpen(true);
  };
  
  
  useEffect(() => {
    if (archivos.length > 0) {
      setIsFilesLoaded(true);
      


    } else {
      setIsFilesLoaded(false);
    }
  }, [archivos]);
  
  
    // Función para cerrar el modal de confirmación
    const closeDeleteModal = () => {
      setIsDeleteModalOpen(false);
    };
  
    // Función para manejar la confirmación de eliminación
 // Modificar la función handleConfirmDelete para actualizar el estado al eliminar subdocumentos

const handleConfirmDelete = async () => {
  if (currentDocument) {
    try {
      // Determinar si es una subversión
      const isSubversion = (currentDocument.subVersions && currentDocument.subVersions.length > 0) || false;
      
      // Enviar el ID y el estado de subversión al backend
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/php/pages/controldocument/busquedarapida/delete_document.php`, 
        {
          documentId: currentDocument.id,
          isSubversion: isSubversion ? 1 : 0
        }
      );

      if (response.data.success) {
        // Identificar si estamos eliminando un documento principal o una subversión
        const isMainDocument = !documents.find(d => 
          d.subVersions && d.subVersions.some(s => s.id === currentDocument.id)
        );
        
        if (isMainDocument) {
          // Si es un documento principal, simplemente lo eliminamos del array
          setDocuments(prev => prev.filter(doc => doc.id !== currentDocument.id));
        } else {
          // Si es una subversión, necesitamos encontrar el documento principal y actualizar sus subversiones
          setDocuments(prev => 
            prev.map(doc => {
              // Si este documento principal tiene la subversión que estamos eliminando
              if (doc.subVersions && doc.subVersions.some(s => s.id === currentDocument.id)) {
                // Crear una copia con las subversiones actualizadas (sin la que eliminamos)
                return {
                  ...doc,
                  subVersions: doc.subVersions.filter(s => s.id !== currentDocument.id)
                };
              }
              return doc;
            })
          );
        }
        
        toast.success('Documento eliminado correctamente');
      } else {
        toast.error(response.data.message || 'Error al eliminar el documento');
      }
    } catch (error) {
      console.error('Error al eliminar el documento:', error);
      toast.error('Ocurrió un error al intentar eliminar el documento');
    } finally {
      // Cerrar el modal y limpiar la selección
      setIsDeleteModalOpen(false);
      setSelectedDocuments([]);
      setCurrentDocument(null);
    }
  }
};
const resetSort = () => {
  setSortColumn(null);
  setSortDirection('asc');

  const originalSorted = [...filteredDocuments].sort((a, b) => {
    return new Date(b.date).getTime() - new Date(a.date).getTime();
  });

  setFilteredDocuments(originalSorted);
};


const handleSort = (column: string) => {
  if (!column) return;

  const newDirection = sortColumn === column && sortDirection === 'asc' ? 'desc' : 'asc';
  
  setSortColumn(column);
  setSortDirection(newDirection);

  const sortedDocuments = [...filteredDocuments].sort((a, b) => {
    // Handle nested sorting for project/projectName
    const valueA = column === 'project' 
      ? (a.project || a.projectName || '').toLowerCase() 
      : (a[column as keyof Document] || '').toString().toLowerCase();
    
    const valueB = column === 'project'
      ? (b.project || b.projectName || '').toLowerCase()
      : (b[column as keyof Document] || '').toString().toLowerCase();

    // Date comparison for special handling
    if (column === 'date') {
      return newDirection === 'asc' 
        ? new Date(valueA).getTime() - new Date(valueB).getTime()
        : new Date(valueB).getTime() - new Date(valueA).getTime();
    }

    // Standard string comparison
    return newDirection === 'asc'
      ? valueA.localeCompare(valueB)
      : valueB.localeCompare(valueA);
  });

  setFilteredDocuments(sortedDocuments);
};

  useEffect(() => {
    // Fetch handleselec from the backend
    const fetchNiveles = async () => {
      try {
        const response = await axios.get<Nivel[]>(`${process.env.REACT_APP_API_URL}/php/pages/controldocument/glosario/get_levels.php`);
        setNiveles(response.data);
      } catch (error) {
        console.error('Error fetching niveles:', error);
      }
    };

    const fetchData = async () => {
      try {
        const response = await axios.get<Data[]>(`${process.env.REACT_APP_API_URL}/php/pages/controldocument/glosario/get_levels_data.php`);
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchNiveles();
    fetchData();
  }, []);

  useEffect(() => {
    // Resetea la selección general cuando cambias de página
    setIsAllSelected(false);
  }, [currentPage]);
  


  const handleDeleteDocument = async (docId: string) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/php/pages/controldocument/busquedarapida/delete_document.php`, {
        documentId: docId,
      });
  
      // Actualiza el estado para reflejar la eliminación
      setDocuments(prev => prev.filter(doc => doc.id !== docId));
      setSelectedDocuments(prev => prev.filter(id => id !== docId)); // Elimina de la selección si estaba seleccionado
  
    } catch (error) {
      console.error('Error al eliminar documento:', error);
      alert('Ocurrió un error al intentar eliminar el documento.');
    } finally {
      closeDeleteModal();
    }
  };
  const handleDeleteSelectedDocuments = () => {
    if (selectedDocuments.length === 0) {
      toast.error('No hay documentos seleccionados para eliminar');
      return;
    }
  
    // Abrir el modal para confirmar la eliminación
    setIsDeleteModalOpen(true);
  };
  
  const handleConfirmMultipleDelete = async () => {
    if (selectedDocuments.length > 0) {
      try {
        // Para cada documento seleccionado
        for (const docId of selectedDocuments) {
          // Encontrar el documento en la lista
          const doc = documents.find(d => d.id === docId);
          
          if (doc) {
            // Determinar si es una subversión
            const isSubversion = (doc.subVersions && doc.subVersions.length > 0) || false;
            
            // Enviar petición de eliminación
            await axios.post(
              `${process.env.REACT_APP_API_URL}/php/pages/controldocument/busquedarapida/delete_document.php`, 
              {
                documentId: docId,
                isSubversion: isSubversion ? 1 : 0
              }
            );
          }
        }
        
        // Actualizar el estado para reflejar las eliminaciones
        setDocuments(prev => prev.filter(doc => !selectedDocuments.includes(doc.id)));
        toast.success('Documentos eliminados correctamente');
      } catch (error) {
        console.error('Error al eliminar documentos:', error);
        toast.error('Ocurrió un error al intentar eliminar los documentos');
      } finally {
        // Cerrar el modal y limpiar la selección
        setIsDeleteModalOpen(false);
        setSelectedDocuments([]);
      }
    }
  };

  
  const toggleSelectAll = () => {
    // Verifica si todos los documentos están seleccionados
    if (isAllSelected) {
      // Deselecciona todos los documentos
      setSelectedDocuments([]);
    } else {
      // Obtiene todos los documentos principales visibles en la página actual
      const currentDocuments = filteredDocuments.slice(indexOfFirstDocument, indexOfLastDocument);
  
      // Extrae los IDs de los documentos principales y sus subversiones si están expandidas
      const allVisibleDocumentIds = currentDocuments.flatMap(doc => {
        // Incluye el ID del documento principal
        const docIds = [doc.id];
  
        // Verifica si el documento está expandido y sus subversiones también deben ser incluidas
        if (expandedRows.includes(doc.id) && doc.subVersions) {
          docIds.push(...doc.subVersions.map(subDoc => subDoc.id));
        }
  
        return docIds;
      });
  
      // Actualiza la lista de documentos seleccionados con todos los IDs visibles
      setSelectedDocuments(allVisibleDocumentIds);
    }
  
    // Alterna el estado de selección
    setIsAllSelected(prevState => !prevState);
  };
  
  const handleRemoveFile = (fileName: string, type: 'principal' | 'complementario') => {
    if (type === 'principal') {
      setArchivos(archivos.filter(file => file.name !== fileName));
    }
  };

  const handleSelectChange = (nivelId: number, value: string) => {
    setSelectedValues(prevValues => ({
      ...prevValues,
      [nivelId]: value,
      
    }));
};




const handleBlurRevision = async () => {
 
  if (!revision) {
    return; // No hacer nada si ya se ha validado
  }
  
 // setIsRevisionFocused(true); // Marcar como validado
  try {
   
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/controldocument/cargardocumento/compararCodigo.php`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({ codigo: codigo, revision: revision })
      });
   
        const data = await response.json();
      
      const {
        titulo,
        existsDocument,
        existsRevision,
        revision: oldRevision,
        version: oldVersion,
        prohibited_revisions: prohibitedRevisions,
        new_revision: newRevision,
        new_version: newVersion,
        revision_allowed: revisionAllowed
    } = data;
    //alert(JSON.stringify(data));
    setExistsDocument(existsDocument);
    setTituloDocumento(existsDocument?titulo:tituloDocumento);
    setExistsRevision(existsDocument);
    setLabelMessage(existsDocument?existsRevision?`El documento ya existe y se encuentra en la revisión ${oldRevision} versión ${oldVersion}. Si continúa, el documento mantendrá su revisión en ${newRevision} y aumentará su versión a ${newVersion}`:`El documento ya existe y se encuentra en la revisión ${oldRevision} versión ${oldVersion}. Si continúa, el documento cambiará la revisión a ${newRevision} versión ${newVersion}`:`El documento se ingresará con revisión ${newRevision} versión ${newVersion}`)
    setNewRevision(newRevision);
    setNewVersion(newVersion);
    setIsSubVersion(0);
    
   
  } catch (error) {
      console.error('Error al verificar el código:', error);
      //alert('Error al verificar el código.');
  }
};


const generarCodigo = () => {
  // Aquí puedes construir tu código basado en el correlativo y otros valores si es necesario
  if (codigo && correlativo) {
    let nivelesTrama = ''; // Usa let para poder modificar el valor

    niveles.forEach(nivel => {
      // Asegúrate de que selectedValues[nivel.nivel_id] esté definido
      if (selectedValues[nivel.nivel_id]) {
        nivelesTrama += `${selectedValues[nivel.nivel_id]}-`; // Usa template literals para concatenar strings
      }
    });

    // Elimina el guion final si existe
    if (nivelesTrama.endsWith('-')) {
      nivelesTrama = nivelesTrama.slice(0, -1); // Elimina el último carácter
    }
    
    // Retorna el código como una cadena
    return `${codigo}-${nivelesTrama}-${correlativo}`;
  }
  
  // Retorna un valor predeterminado o vacío si las condiciones no se cumplen
  return '';
};


const handleModalValueChange = (nivelId: number, value: string) => {
  // Crear una copia actualizada de modalValues
  const updatedModalValues = {
    ...modalValues,
    [nivelId]: value
  };
  
  // Actualizar el estado
  setModalValues(updatedModalValues);
  
  // Usar la función específica para niveles
  updateCodigoFromNiveles(updatedModalValues);
};


    // Function to get options based on nivel_id
    const getOptionsForNivel = (nivelId: number) => {
      return data
        .filter(item => item.nivel_id === nivelId)
        .map(item => (
          <option key={item.concepto} value={item.nomenclatura}>
            {`${item.nomenclatura} - ${item.concepto}`}
          </option>
        ));
    };
  

// Helper function to format transmittal dates
const formatTransmittalDate = (dateString: string): string => {
  if (!dateString) return new Date().toISOString();

  try {
    // Handle various date formats
    const dateParts = dateString.split(/[\s-]/);
    
    // If format is DD-MM-YYYY or MM-DD-YYYY
    if (dateParts.length >= 3) {
      let year, month, day;
      
      // Check if first part is a year (YYYY format)
      if (dateParts[0].length === 4) {
        [year, month, day] = dateParts;
      } else {
        // Assume DD-MM-YYYY or MM-DD-YYYY
        [day, month, year] = dateParts;
      }

      // Create date with validated parts
      const formattedDate = new Date(`${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`);
      
      return formattedDate.toISOString();
    }

    // Fallback to parsing with Date constructor
    return new Date(dateString).toISOString();
  } catch (error) {
    console.error('Error parsing date:', dateString, error);
    return new Date().toISOString();
  }
};

// Helper function to extract project details
const extractProjectDetails = (transmittal: any, processes: Proceso[]): 
  { projectName: string, projectCode: string, comuna: string } => {
  let projectName = transmittal.nombre_proyecto || transmittal.projectName || '';
  let projectCode = '';
  let comuna = transmittal.comuna || '';

  // Try to extract project code
  if (transmittal.document) {
    projectCode = transmittal.document.split('-')[0];
  } else if (transmittal.codigo_transmittal) {
    projectCode = transmittal.codigo_transmittal.split('-')[0];
  }

  // Find matching process
  const matchingProcess = processes.find(proceso => 
    proceso.codigo === projectCode || 
    proceso.nombreProceso === projectName
  );

  if (matchingProcess) {
    projectName = matchingProcess.nombreProceso;
    comuna = matchingProcess.comuna || comuna;
  }

  // Clean up project name (remove brackets/parentheses)
  projectName = projectName.replace(/\s*[\[\(].*?[\]\)]$/, '').trim();

  return { projectName, projectCode, comuna };
};


useEffect(() => {
  fetchDocuments();
}, []);

// Modify fetchDocuments to ensure more robust transmittal processing
// Modify fetchDocuments to explicitly handle transmittals
const fetchDocuments = async () => {
  try {
    // Fetch documents
    const docResponse = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/controldocument/busquedarapida/get_docs.php`);
    const docData = await docResponse.json();

    // Fetch transmittals
    const transmResponse = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/transmittal/get_transmittals.php?type=type2`);
    const transmData = await transmResponse.json();

    // Prepare merged document array
    const mergedDocuments: Document[] = [];

    // Process documents with unique keys
    if (Array.isArray(docData) && docData.length > 0) {
      const processedDocs = docData.map((doc, index) => ({
        ...doc,
        id: `doc-${doc.id || index}`, // Ensure unique ID
        itemType: 'document',
        mergeSortKey: new Date(doc.date).getTime(),
        correlativo: doc.correlativo || '',
        project: doc.project || doc.projectName || '',
        projectName: doc.projectName || doc.project || ''
      } as Document));
      mergedDocuments.push(...processedDocs);
    }

    // Process transmittals with unique keys
    if (Array.isArray(transmData) && transmData.length > 0) {
      const processedTransmittals = transmData.map((transmittal, index) => {
        // Extract project details
        let projectName = transmittal.nombre_proyecto || 
                          transmittal.projectName || 
                          transmittal.project || 
                          'Sin Nombre';
        
        let projectCode = transmittal.codigo_proyecto || 
                          transmittal.projectCode || 
                          (transmittal.document ? transmittal.document.split('-')[0] : '');
        
        let comuna = transmittal.comuna || '';

        // Attempt to find matching process
        const matchingProcess = procesos.find(proceso => 
          proceso.codigo === projectCode || 
          proceso.nombreProceso === projectName
        );

        if (matchingProcess) {
          projectName = matchingProcess.nombreProceso;
          comuna = matchingProcess.comuna || comuna;
        }

        // Format date
        const formattedDate = (() => {
          try {
            if (!transmittal.date) return new Date().toISOString();
            const date = new Date(transmittal.date);
            return date.toISOString();
          } catch (error) {
            console.error('Date parsing error:', error);
            return new Date().toISOString();
          }
        })();

        const transmittalDocument: Document = {
          id: `transmittal-${transmittal.id || index}`, // Ensure unique ID
          date: formattedDate,
          document: transmittal.document || transmittal.codigo_transmittal || '',
          projectName: projectName,
          project: projectName,
          projectCode: projectCode,
          path: transmittal.path || transmittal.ruta || '',
          revision: 'A',
          version: 1,
          title: transmittal.title || transmittal.asunto || 'Sin Título',
          subVersions: [],
          flagged: false,
          issuedBy: transmittal.issuedBy || transmittal.emisor || 'Sin Emisor',
          itemType: 'transmittal',
          comment: transmittal.comment || transmittal.comentarios || '',
          comuna: comuna,
          correlativo: transmittal.codigo_transmittal || transmittal.document || '',
          mergeSortKey: new Date(formattedDate).getTime()
        };

        return transmittalDocument;
      });
      
      mergedDocuments.push(...processedTransmittals);
    }

    // Sort documents
    const sortedDocuments = mergedDocuments.sort((a, b) => {
      const sortKeyA = a.mergeSortKey ?? new Date(a.date).getTime();
      const sortKeyB = b.mergeSortKey ?? new Date(b.date).getTime();
      return sortKeyB - sortKeyA;
    });

    setDocuments(sortedDocuments);
    setFilteredDocuments(sortedDocuments);
    setIsFileLoaded(true);

  } catch (error) {
    console.error('Error fetching documents:', error);
    setIsFileLoaded(false);
  }
};







// Modify the filtering logic to be more inclusive
useEffect(() => {
  // Obtener solo los proyectos que tienen documentos asociados con información completa
  const projectsWithDocuments = documents
    .map(doc => {
      // Si el documento ya tiene un projectName válido, usarlo
      if (doc.projectName && doc.projectName.trim() !== '') {
        // Buscar el proceso correspondiente para obtener información completa
        const matchingProcess = procesos.find(proceso => proceso.nombreProceso === doc.projectName.trim());
        
        if (matchingProcess) {
          // Retornar información completa para mostrar
          return {
            value: doc.projectName.trim(),
            display: `${matchingProcess.codigo} - ${doc.projectName.trim()} (${matchingProcess.comuna})`
          };
        }
        return { value: doc.projectName.trim(), display: doc.projectName.trim() };
      }
      
      // Si no tiene projectName pero sí projectCode, buscar en procesos
      if (doc.projectCode) {
        const foundProcess = procesos.find(proceso => proceso.codigo === doc.projectCode);
        if (foundProcess) {
          doc.projectName = foundProcess.nombreProceso;
          return {
            value: foundProcess.nombreProceso,
            display: `${foundProcess.codigo} - ${foundProcess.nombreProceso} (${foundProcess.comuna})`
          }
     
        }
      }
      
      // Valor por defecto si no se encuentra
      return { 
        value: doc.projectCode ? `Proyecto ${doc.projectCode}` : '',
        display: doc.projectCode ? `${doc.projectCode} - Proyecto ${doc.projectCode}` : ''
      };
    })
    .filter(item => item.value !== ''); // Eliminar valores vacíos

  // Obtener proyectos únicos
  const uniqueValues = Array.from(new Set(projectsWithDocuments.map(p => p.value)));
  const uniqueProjects = uniqueValues.map(value => {
    const matchingProject = projectsWithDocuments.find(p => p.value === value);
    return matchingProject ? matchingProject : { value, display: value };
  });
  
  setProjectOptions(uniqueProjects.map(p => p.value));
  setProjectDisplayOptions(uniqueProjects);

  const allFiltersEmpty =
    !projectFilter &&
    !titleFilter &&
    !commentFilter &&
    Object.values(selectedValues).every(value => !value);

  if (allFiltersEmpty) {
    setFilteredDocuments([]);
    return;
  }

  const filtered = documents.filter(doc => {
    const docParts = splitDocument(doc.document);

    // Para el projectMatch, primero determinar el nombre del proyecto a usar
    let projectName = doc.project;
    if (!projectName || projectName.trim() === '') {
      // Si project está vacío, intentar usar projectName
      projectName = doc.projectName;
      
      // Si projectName también está vacío pero hay projectCode, buscar en procesos
      if ((!projectName || projectName.trim() === '') && doc.projectCode) {
        const foundProcess = procesos.find(proceso => proceso.codigo === doc.projectCode);
        if (foundProcess) {
          projectName = foundProcess.nombreProceso;
        }
      }
    }

    const projectMatch = projectName.toLowerCase().includes(projectFilter.toLowerCase());
    const titleMatch = doc.title.toLowerCase().includes(titleFilter.toLowerCase());
    const commentMatch = doc.comment.toLowerCase().includes(commentFilter.toLowerCase());

    // Verifica el match para las partes del documento
    const selectedValuesMatch = Object.entries(selectedValues).every(([nivelId, value]) => {
      if (!value) return true; // Si no hay valor a filtrar, es una coincidencia
      
      if (!docParts) return false;
      
      const index = parseInt(nivelId, 10) - 1; // Ajusta el índice basado en el nivel_id
      
      // Asegúrate de que el índice esté dentro del rango de partes
      const parts = docParts.split('-');
      if (index < 0 || index >= parts.length) {
        return false;
      }
      
      // Maneja la lógica específica para cada índice
      const part = parts[index];
      if (index === 0 && part.match(/[A-Za-z]/)) {
        return part.toLowerCase().includes(value.toLowerCase());
      } else if (index === 1 && part.length === 3) {
        return part.toLowerCase().includes(value.toLowerCase());
      } else if (index === 2 && part.length === 2) {
        return part.toLowerCase().includes(value.toLowerCase());
      }
      
      return false;
    });

    return projectMatch && titleMatch && commentMatch && selectedValuesMatch;
  });

  setFilteredDocuments(filtered);
  setCurrentPage(1); // Reset to first page on filter change
}, [projectFilter, titleFilter, subFolderFilter, specialtyFilter, docTypeFilter, commentFilter, documents, selectedValues, procesos]);
 

// Update the useEffect to generate project display options
useEffect(() => {
  console.log('Generating project display options');
  console.log('Current documents:', documents);
  console.log('Current processes:', procesos);

  // Helper function to clean and normalize project names
  const normalizeProjectName = (projectName: string, comuna?: string) => {
    // Remove bracketed or parenthetical information
    let cleanName = projectName.replace(/\s*[\[\(].*?[\]\)]$/, '').trim();
    
    // If a separate commune is provided, do not append it
    return cleanName;
  };

  // Obtain unique projects from all documents
  const projectsWithDocuments = documents
    .map(doc => {
      // Attempt to extract project details
      let projectName = doc.projectName || doc.project || '';
      let comuna = doc.comuna || '';
      let projectCode = doc.projectCode || '';

      // Normalize project name
      projectName = normalizeProjectName(projectName, comuna);

      // If project is empty, use a fallback
      if (!projectName) {
        projectName = doc.itemType === 'transmittal' 
          ? `Transmittal ${doc.id}` 
          : 'Proyecto Sin Nombre';
      }

      // Try to find matching process
      const matchingProcess = procesos.find(proceso => 
        proceso.nombreProceso === projectName || 
        proceso.codigo === projectCode ||
        (projectName && proceso.nombreProceso && 
         projectName.startsWith(proceso.nombreProceso.trim()))
      );

      // Use process information if available
      if (matchingProcess) {
        return {
          key: matchingProcess.nombreProceso,
          value: matchingProcess.nombreProceso,
          display: `${matchingProcess.codigo} - ${matchingProcess.nombreProceso}${matchingProcess.comuna ? ` - ${matchingProcess.comuna}` : ''}`,
          projectName: matchingProcess.nombreProceso,
          projectCode: matchingProcess.codigo,
          comuna: matchingProcess.comuna || comuna,
          documentCount: 1 // Track document count
        };
      }

      // Fallback for documents without matching process
      return {
        key: projectName,
        value: projectName,
        display: projectCode ? 
          `${projectCode} - ${projectName}${comuna ? ` - ${comuna}` : ''}` : 
          projectName,
        projectName: projectName,
        projectCode: projectCode,
        comuna: comuna,
        documentCount: 1
      };
    })
    .filter(item => item.key !== ''); // Remove empty entries

  // Unify projects based on their key with more sophisticated merging
  const uniqueProjectsMap = new Map();
  
  projectsWithDocuments.forEach(project => {
    const existingProject = uniqueProjectsMap.get(project.key);

    if (!existingProject) {
      // First occurrence of this project
      uniqueProjectsMap.set(project.key, project);
    } else {
      // Merge projects with preference rules
      const mergedProject = {
        key: project.key,
        value: project.value,
        // Prefer display with more information (code + name)
        display: project.display.includes('-') && !existingProject.display.includes('-') 
          ? project.display 
          : existingProject.display,
        // Prefer non-empty project code
        projectCode: project.projectCode || existingProject.projectCode,
        // Prefer non-empty commune
        comuna: project.comuna || existingProject.comuna,
        // Combine document counts
        documentCount: (existingProject.documentCount || 0) + 1
      };

      uniqueProjectsMap.set(project.key, mergedProject);
    }
  });

  // Convert Map back to array and sort
  const uniqueProjects = Array.from(uniqueProjectsMap.values());
  uniqueProjects.sort((a, b) => {
    // Primary sort by document count (descending)
    if (b.documentCount !== a.documentCount) {
      return b.documentCount - a.documentCount;
    }
    
    // Secondary sort by project code
    if (a.projectCode && b.projectCode) {
      return a.projectCode.localeCompare(b.projectCode);
    }
    
    // Tertiary sort by project name
    return a.value.localeCompare(b.value);
  });
  
  console.log('Unique projects:', uniqueProjects);
  
  // Update states
  setProjectOptions(uniqueProjects.map(p => p.value));
  setProjectDisplayOptions(uniqueProjects);

}, [documents, procesos]); // Depend on documents and processes
// Ensure processes are fetched if not already loaded
useEffect(() => {
  const fetchProcesses = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/proceso/get_processes.php?type=type4`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log('Fetched processes:', data);
      setProcesos(data);
    } catch (error) {
      console.error('Error fetching processes:', error);
    }
  };

  // Only fetch if processes are not already loaded
  if (procesos.length === 0) {
    fetchProcesses();
  }
}, []); // Empty dependency array ensures this runs only once on component mount





useEffect(() => {
  // Obtener solo los proyectos que tienen documentos asociados con información completa
  const projectsWithDocuments = documents
    .map(doc => {
      // Si el documento ya tiene un projectName válido, usarlo
    //esto me daba ele rror 
      // if (doc.projectName && doc.projectName.trim() !== '') {
      //   // Buscar el proceso correspondiente para obtener información completa
      //   const matchingProcess = procesos.find(proceso => proceso.nombreProceso === doc.projectName.trim());
        
      //   if (matchingProcess) {
      //     // Retornar información completa para mostrar
      //     return {
      //       value: doc.projectName.trim(),
      //       display: `${matchingProcess.codigo} - ${doc.projectName.trim()} (${matchingProcess.comuna})`
      //     };
      //   }
      //   return { value: doc.projectName.trim(), display: doc.projectName.trim() };
      // }
      
      // Si no tiene projectName pero sí projectCode, buscar en procesos
      if (doc.projectCode) {
        const foundProcess = procesos.find(proceso => proceso.codigo === doc.projectCode);
        if (foundProcess) {
          doc.projectName = foundProcess.nombreProceso;
          return {
            value: foundProcess.nombreProceso,
            display: `${foundProcess.codigo} - ${foundProcess.nombreProceso} (${foundProcess.comuna})`
          }
     
        }
      }
      
      // Valor por defecto si no se encuentra
      return { 
        value: doc.projectCode ? `Proyecto ${doc.projectCode}` : '',
        display: doc.projectCode ? `${doc.projectCode} - Proyecto ${doc.projectCode}` : ''
      };
    })
    .filter(item => item.value !== ''); // Eliminar valores vacíos

  // Obtener proyectos únicos
  const uniqueValues = Array.from(new Set(projectsWithDocuments.map(p => p.value)));
  const uniqueProjects = uniqueValues.map(value => {
    const matchingProject = projectsWithDocuments.find(p => p.value === value);
    return matchingProject ? matchingProject : { value, display: value };
  });
  
  setProjectOptions(uniqueProjects.map(p => p.value));
  setProjectDisplayOptions(uniqueProjects);

  const allFiltersEmpty =
    !projectFilter &&
    !titleFilter &&
    !commentFilter &&
    Object.values(selectedValues).every(value => !value);

  if (allFiltersEmpty) {
    setFilteredDocuments([]);
    return;
  }

  const filtered = documents.filter(doc => {
    const docParts = splitDocument(doc.document);

    // Para el projectMatch, primero determinar el nombre del proyecto a usar
    let projectName = doc.project;
    if (!projectName || projectName.trim() === '') {
      // Si project está vacío, intentar usar projectName
      projectName = doc.projectName;
      
      // Si projectName también está vacío pero hay projectCode, buscar en procesos
      if ((!projectName || projectName.trim() === '') && doc.projectCode) {
        const foundProcess = procesos.find(proceso => proceso.codigo === doc.projectCode);
        if (foundProcess) {
          projectName = foundProcess.nombreProceso;
        }
      }
    }

    const projectMatch = projectName.toLowerCase().includes(projectFilter.toLowerCase());
    const titleMatch = doc.title.toLowerCase().includes(titleFilter.toLowerCase());
    const commentMatch = doc.comment.toLowerCase().includes(commentFilter.toLowerCase());

    // Verifica el match para las partes del documento
    const selectedValuesMatch = Object.entries(selectedValues).every(([nivelId, value]) => {
      if (!value) return true; // Si no hay valor a filtrar, es una coincidencia
      
      if (!docParts) return false;
      
      const index = parseInt(nivelId, 10) - 1; // Ajusta el índice basado en el nivel_id
      
      // Asegúrate de que el índice esté dentro del rango de partes
      const parts = docParts.split('-');
      if (index < 0 || index >= parts.length) {
        return false;
      }
      
      // Maneja la lógica específica para cada índice
      const part = parts[index];
      if (index === 0 && part.match(/[A-Za-z]/)) {
        return part.toLowerCase().includes(value.toLowerCase());
      } else if (index === 1 && part.length === 3) {
        return part.toLowerCase().includes(value.toLowerCase());
      } else if (index === 2 && part.length === 2) {
        return part.toLowerCase().includes(value.toLowerCase());
      }
      
      return false;
    });

    return projectMatch && titleMatch && commentMatch && selectedValuesMatch;
  });

  setFilteredDocuments(filtered);
  setCurrentPage(1); // Reset to first page on filter change
}, [projectFilter, titleFilter, subFolderFilter, specialtyFilter, docTypeFilter, commentFilter, documents, selectedValues, procesos]);
 

// Modify the fetchDocuments function to ensure transmittals are included

const splitDocument = (document: string) => {
  // Divide el documento por el guion
  const parts = document.split('-');
  
  // Devuelve solo las partes relevantes
  return parts.slice(1, -1).join('-'); // Excluye la primera y la última parte
};

// useEffect(() => {
//   // Obtener solo los proyectos que tienen documentos asociados con información completa
//   const projectsWithDocuments = documents
//     .map(doc => {
//       // Extraer el nombre del proyecto y su código
//       let projectName = '';
//       let comuna = '';
//       let projectCode = '';
      
//       if (doc.projectName) {
//         // Si tiene projectName, usarlo como base
//         projectName = doc.projectName.trim();
//       }
      
   
//       // Determinar el código del proyecto
//       if (doc.projectCode) {
//         projectCode = doc.projectCode;
//       } else if (doc.document) {
//         // Intentar extraer el código desde el documento
//         const parts = doc.document.split('-');
//         if (parts.length > 0) {
//           projectCode = parts[0];
//         }
//       }
      
//       // Buscar el proceso correspondiente para obtener información completa
//       const matchingProcess = procesos.find(proceso => 
//         proceso.nombreProceso === projectName || 
//         proceso.codigo === projectCode ||
//         // También intentar coincidir si el nombre del proyecto comienza con el nombre del proceso
//         (projectName && proceso.nombreProceso && 
//          projectName.startsWith(proceso.nombreProceso.trim()))
//       );
      
//       if (matchingProcess) {
//         // Usar la información del proceso encontrado
//         return {
//           // Clave para unificar: nombre del proceso
//           key: matchingProcess.nombreProceso,
//           // Valor para filtrado: nombre del proceso
//           value: matchingProcess.nombreProceso,
//           // Visualización: código + nombre + comuna
//           display: `${matchingProcess.codigo} - ${matchingProcess.nombreProceso}${matchingProcess.comuna ? ` - ${matchingProcess.comuna}` : ''}`,
//           projectName: matchingProcess.nombreProceso,
//           projectCode: matchingProcess.codigo,
//           comuna: matchingProcess.comuna || comuna
//         };
//       }
      
//       // Si no encontramos un proceso coincidente pero tenemos información
//       if (projectName) {
//         return {
//           key: projectName,
//           value: projectName,
//           display: projectCode ? 
//             `${projectCode} - ${projectName}${comuna ? ` - ${comuna}` : ''}` : 
//             projectName,
//           projectName: projectName,
//           projectCode: projectCode,
//           comuna: comuna
//         };
//       }
      
//       // Valor por defecto si no se encuentra información útil
//       return { 
//         key: '',
//         value: '',
//         display: '',
//         projectName: '',
//         projectCode: '',
//         comuna: ''
//       };
//     })
//     .filter(item => item.key !== ''); // Eliminar valores vacíos

//   // Unificar proyectos basados en su nombre real
//   const uniqueProjectsMap = new Map();
  
//   projectsWithDocuments.forEach(project => {
//     // Solo agregar si no existe o actualizar si tiene mejor información
//     if (!uniqueProjectsMap.has(project.key) || 
//         (uniqueProjectsMap.get(project.key).projectCode === '' && project.projectCode !== '')) {
//       uniqueProjectsMap.set(project.key, project);
//     }
//   });

//   // Convertir el Map de vuelta a un array
//   const uniqueProjects = Array.from(uniqueProjectsMap.values());
  
//   // Ordenar las opciones por código para mejor usabilidad
//   uniqueProjects.sort((a, b) => {
//     if (a.projectCode && b.projectCode) {
//       return a.projectCode.localeCompare(b.projectCode);
//     }
//     return a.value.localeCompare(b.value);
//   });
  
//   // Actualizar los estados
//   setProjectOptions(uniqueProjects.map(p => p.value));
//   setProjectDisplayOptions(uniqueProjects);

//   // Continuar con la lógica de filtrado
//   const allFiltersEmpty =
//     !projectFilter &&
//     !titleFilter &&
//     !commentFilter &&
//     Object.values(selectedValues).every(value => !value);

//   if (allFiltersEmpty) {
//     setFilteredDocuments([]);
//     return;
//   }

//   const filtered = documents.filter(doc => {
//     // Para project y projectName, usar cualquiera que esté disponible
//     const docProject = doc.project || doc.projectName || '';
    
//     // Buscar coincidencias en el proyecto
//     const projectMatch = !projectFilter || docProject.toLowerCase().includes(projectFilter.toLowerCase());
//     const titleMatch = !titleFilter || doc.title.toLowerCase().includes(titleFilter.toLowerCase());
//     const commentMatch = !commentFilter || doc.comment.toLowerCase().includes(commentFilter.toLowerCase());

//     // Mantener la lógica existente para selectedValues
//     const selectedValuesMatch = Object.entries(selectedValues).every(([nivelId, value]) => {
//       if (!value) return true; // Si no hay valor a filtrar, es una coincidencia
      
//       // Implementar aquí la lógica existente para verificar selectedValues
      
//       return true; // Reemplazar con tu lógica actual
//     });

//     return projectMatch && titleMatch && commentMatch && selectedValuesMatch;
//   });

//   setFilteredDocuments(filtered);
//   setCurrentPage(1); // Reset to first page on filter change
// }, [projectFilter, titleFilter, commentFilter, documents, selectedValues, procesos]);


// Añadir esta función al componente
const resetFilters = () => {
  // Resetear filtros básicos
  setProjectFilter('');
  setTitleFilter('');
  setCommentFilter('');

  // Resetear niveles
  const initialSelectedValues: { [key: string]: string } = {};
  niveles.forEach(nivel => {
    initialSelectedValues[nivel.nivel_id] = '';
  });
  setSelectedValues(initialSelectedValues);

  // 🔁 Resetear ordenamiento
  setSortColumn(null); // o '' si usas string
  setSortDirection('asc');

  // Resetear la página
  setCurrentPage(1);
};



//este es el conflictivo
// Lógica de filtrado mejorada para documentos y transmittals
useEffect(() => {
  
  // Función de filtrado más completa
  const filterDocuments = () => {
    const noFiltersActive = !projectFilter && !titleFilter && !commentFilter &&
    Object.values(selectedValues).every(value => value === '');

  if (noFiltersActive) {
    setFilteredDocuments([]); // ← deja la tabla vacía
    setCurrentPage(1);
    return;
  }
    // Copiar documentos originales
    let filteredDocs = [...documents];

    // Filtros básicos
    if (projectFilter) {
      filteredDocs = filteredDocs.filter(doc => {
        // Comparar con el nombre del proyecto, considerando múltiples formatos
        const projectMatch = doc.projectName.toLowerCase().includes(projectFilter.toLowerCase()) ||
                             doc.project.toLowerCase().includes(projectFilter.toLowerCase()) ||
                             (doc.projectCode && doc.projectCode.toLowerCase().includes(projectFilter.toLowerCase()));
        
        return projectMatch;
      });
    }

    // Filtro por título
    if (titleFilter) {
      filteredDocs = filteredDocs.filter(doc => 
        doc.title.toLowerCase().includes(titleFilter.toLowerCase())
      );
    }

    // Filtro por comentario
    if (commentFilter) {
      filteredDocs = filteredDocs.filter(doc => 
        doc.comment.toLowerCase().includes(commentFilter.toLowerCase())
      );
    }

    // Filtro por niveles
    const selectedLevelsExist = Object.values(selectedValues).some(value => value !== '');
    
    if (selectedLevelsExist) {
      filteredDocs = filteredDocs.filter(doc => {
        // Verificar coincidencia de niveles solo para documentos (no transmittals)
        if (doc.itemType === 'transmittal') return true;

        return Object.entries(selectedValues).every(([nivelId, value]) => {
          if (!value) return true; // Si no hay valor para este nivel, es una coincidencia
          
          // Dividir el documento para extraer niveles
          const docParts = doc.document.split('-');
          
          // Ajustar índice considerando que el primer elemento es el código de proyecto
          const levelIndex = parseInt(nivelId, 10) - 1;
          
          // Verificar si el índice del nivel existe y coincide
          return levelIndex >= 0 && levelIndex < docParts.length - 1 
            ? docParts[levelIndex + 1].toLowerCase().includes(value.toLowerCase())
            : false;
        });
      });
    }

    // Filtro especial para transmittals
    const transmittals = filteredDocs.filter(doc => doc.itemType === 'transmittal');
    const regularDocs = filteredDocs.filter(doc => doc.itemType === 'document');

    // Combinar documentos, manteniendo el orden original de los transmittals
    let finalFilteredDocs = [...regularDocs, ...transmittals];

    // Ordenar por fecha (más reciente primero)
    finalFilteredDocs.sort((a, b) => {
      const dateA = new Date(a.date).getTime();
      const dateB = new Date(b.date).getTime();
      return dateB - dateA;
    });

    // Actualizar estados
    setFilteredDocuments(finalFilteredDocs);
    setCurrentPage(1); // Reiniciar a la primera página
  };

  // Ejecutar filtrado
  filterDocuments();
}, [
  documents, 
  projectFilter, 
  titleFilter, 
  commentFilter, 
  selectedValues
]);
const toggleRow = (id: string) => {
    setExpandedRows(prev =>
      prev.includes(id) ? prev.filter(rowId => rowId !== id) : [...prev, id]
    );
  };

  
  

  const getRowClassName = (id: string) => {
    return selectedDocuments.includes(id) ? 'bg-blue-100' : '';
  };
  
  const toggleDocumentSelection = (id: string) => {
    const updatedSelected = selectedDocuments.includes(id)
      ? selectedDocuments.filter(docId => docId !== id)
      : [...selectedDocuments, id];
  
    setSelectedDocuments(updatedSelected);
    setIsAllSelected(updatedSelected.length === filteredDocuments.length);
  };


// Función para separar el documento_id
  function separarDocumentoId(documentoId: string): { codigo: string, niveles: string[], correlativo: string } {
    
    // Dividir el documento_id en partes usando el delimitador '-'
      const partes = documentoId.split('-');

     // alert(partes);
      // Asegurarse de que el documento_id tiene al menos tres partes
      if (partes.length < 3) {
          //throw new Error('El documento_id no tiene el formato esperado.');
      }
      
      // Extraer las partes del documento_id
      const codigo = partes[0];
      const correlativo = partes[partes.length - 1];
      const niveles = partes.slice(1, -1);
      // Devolver las partes separadas
      return { codigo, niveles, correlativo };
  }

  const openEditModal = (doc: Document) => {
   
    setCurrentDocument(doc);
   
 // Separar el documento_id en partes
    const tramaDocArray = separarDocumentoId(doc.document);
   
    //setProyectoSeleccionado(doc.project);
    
     // Separar el documento_id en partes (cadena 'A,EDI,IN')
  const nivelDocArray = tramaDocArray.niveles;
 
     // Establecer los valores en los comboboxes
  const newSelectedValues: { [key: string]: string } = {
    1: nivelDocArray[0] || '',
    2: nivelDocArray[1] || '',
    3: nivelDocArray[2] || '',
  };
  
  setModalValues(newSelectedValues);

    setModalProject(doc.projectName);
    setTituloDocumento(doc.title);
    setCodigo(doc.document);
    setUsername(doc.issuedBy);
    setCorrelativo(tramaDocArray.correlativo);
    setRevision(doc.revision);
    setVersion(doc.version);
   // alert(`${revision}${version}`)
    setComentarios(doc.comment);
    // Agrega cualquier otro estado necesario aquí
    setIsEditModalOpen(true);
};

  

  const getCurrentDateTimeString = (): string => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Los meses empiezan desde 0
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    return `${year}${month}${day}_${hours}${minutes}${seconds}`;
};

const getDocumentById = (id: string): { path: string, itemType: 'document' | 'transmittal' } | null => {
  // Busca en los documentos principales
  const doc = documents.find(doc => doc.id === id);
  
  // Si el documento se encuentra en los documentos principales, retorna su path y tipo
  if (doc) {
    return {
      path: doc.path,
      itemType: doc.itemType || 'document' // Default to 'document' if missing
    };
  }

  // Si el documento no se encuentra en los documentos principales, busca en las subversiones
  for (const doc of documents) {
    const subdoc = doc.subVersions?.find(subdoc => subdoc.id === id);
    if (subdoc) {
      return {
        path: subdoc.path,
        itemType: subdoc.itemType || 'document' // Default to 'document' for subdocs
      };
    }
  }

  // Si el documento o subdocumento no se encuentra, retorna null
  console.error('Documento no encontrado:', id);
  return null;
};

// Estado de los documentos, enviado una vez
const docStates = {
  "preliminary": 1,
  "information": 0,
  "approval": 1,
  "construction": 0
};




const handleTransmittal = () => {
  // Aquí irá la lógica para generar y enviar el transmittal
  setIsTransmittalModalOpen(true);


};



const handleCloseTransmittalModal = () => {
  setIsTransmittalModalOpen(false);
  setSelectedDocuments([]);
  setIsAllSelected(false);

};

const handleSubmitTransmittal = () => {
  // Aquí irá la lógica para enviar el transmittal
  setIsTransmittalModalOpen(false);
};

  const handleDownload = async (documentIds: string[]) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/php/pages/controldocument/busquedarapida/download_documents.php?type=type1`,
            { documentIds },
            { responseType: 'blob' }
        );
        



        const contentDisposition = response.headers['content-disposition'];
        
        
        let filename = 'download.zip'; // Default filename




        if (documentIds.length === 1) {
          // For single document downloads, use the document name
          const docInfo = getDocumentById(documentIds[0]);
          if (docInfo) {
            if (docInfo.itemType === 'document') {
              filename = docInfo.path || 'document.pdf';
            } else {
              filename = 'transmittal.pdf';
            }
          }
        } else {
          filename = `Simagi_Docs_${getCurrentDateTimeString()}.zip`;
        }

        // Guarda el archivo con el nombre recibido desde el backend
        saveAs(response.data, filename);
    } catch (error) {
        console.error('Error downloading documents:', error);
        if (axios.isAxiosError(error)) {
            if (error.response) {
                console.error('Error response:', error.response.data);
                alert(`Error: ${error.response.data.message || 'Unknown error occurred'}`);
            } else if (error.request) {
                console.error('No response received:', error.request);
                alert('No se recibió respuesta del servidor. Por favor, intente de nuevo más tarde.');
            } else {
                console.error('Error', error.message);
                alert('Ocurrió un error al procesar la solicitud. Por favor, intente de nuevo.');
            }
        } else {
            console.error('Non-Axios error:', error);
            alert('Ocurrió un error inesperado. Por favor, intente de nuevo.');
        }
    }
};

// Función para actualizar el código basado en proceso y niveles
// const updateCodigo = (newCorrelativo?: string): void => {
//   if (!selectedProcesoId || !newCorrelativo) return;

//   const correlativoToUse = newCorrelativo !== undefined ? newCorrelativo : correlativo;

//   if (selectedProcesoId && correlativo) {
//     // Obtener el código del proceso seleccionado
//     const selectedProceso = procesos.find((proc: Proceso) => (`${proc.codigo}  ${proc.nombreProceso}`) === selectedProcesoId);
//     const procesoCode = selectedProceso?.codigo || '';
//     //alert(`${procesos[0].codigo}  ${procesos[0].nombreProceso}`) 
    
//     if (procesoCode) {
//       // Construir string de niveles ordenados
//       let nivelString = '';
      
//       // Obtener niveles ordenados por ID
//       const orderedNiveles = Object.entries(modalValues)
//         .sort(([a], [b]) => parseInt(a) - parseInt(b))
//         .map(([_, value]) => value)
//         .filter(value => value); // Eliminar valores vacíos
      
//       // Si hay niveles seleccionados, crear string
//       if (orderedNiveles.length > 0) {
//         nivelString = `-${orderedNiveles.join('-')}`;
//       }
//       // Generar código completo
//       const newCodigo = `${procesoCode}${nivelString}-${correlativoToUse}`;
//       setCodigo(newCodigo);
//     }
//   }
// };










// Función para verificar si el nuevo código ya existe cuando cambia el proceso, correlativo o niveles
const checkNewDocumentCode = async (newCode: string): Promise<any> => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/php/pages/controldocument/cargardocumento/compararCodigo.php`,
      {
        nuevoDocumentoCodigo: newCode // Enviar el nuevo código para verificación
      }
    );
    
    const data = response.data;
    
    if (data.existsDocument) {
      // Si ya existe un documento con el nuevo código
      setLabelMessage(`Ya existe un documento con este código. Se creará una nueva entrada con revisión ${data.new_revision} versión ${data.new_version}`);
      setNewRevision(data.new_revision);
      setNewVersion(data.new_version);
      setVersion(data.new_version);
    } else {
      // Si es un código nuevo
      setLabelMessage(`Se creará un nuevo documento con revisión A y versión 1`);
      setNewRevision('A');
      setNewVersion(1);
    }
    
    // Establecer el tipo de actualización adecuado
    setIsSubVersion(0); // No es subversión, es un documento nuevo
    
    return data;
  } catch (error) {
    console.error('Error al verificar el nuevo código:', error);
    setLabelMessage('Error al verificar el nuevo código de documento');
    return null;
  }
};

// Modificar las funciones de actualización de código existentes

// Para actualizar desde proceso
const updateCodigoFromProceso = (newProcesoId: string) => {
   if (!newProcesoId || !correlativo) return;
  
  console.log("Updating code from proceso:", newProcesoId);
  
  // Find process based on the formatted ID
  const selectedProceso = procesos.find(
    (proc) => `${proc.codigo}  ${proc.nombreProceso}` === newProcesoId
  );
  
  if (selectedProceso) { 
    console.log("Found process:", selectedProceso);
    const procesoCode = selectedProceso.codigo;
    
    // Build niveles string
    let nivelString = '';
    
    // Get ordered niveles
    const orderedNiveles = Object.entries(modalValues)
      .sort(([a], [b]) => parseInt(a) - parseInt(b))
      .map(([_, value]) => value)
      .filter(value => value);
    
    if (orderedNiveles.length > 0) {
      nivelString = `-${orderedNiveles.join('-')}`;
    }
    
    // Generate the new document code
    const newCodigo = `${procesoCode}${nivelString}-${correlativo}`;
    
    console.log("Generated new document code:", newCodigo);
    setCodigo(newCodigo);

    // Si el código ha cambiado, verificar si ya existe
    if (newCodigo !== currentDocument?.document) {
      checkNewDocumentCode(newCodigo);
    }
  } else {
    console.error("Could not find process with ID:", newProcesoId);
  }
};

// Para actualizar desde niveles

const updateCodigoFromNiveles = (newNiveles: { [key: string]: string }): void => {
  if (!selectedProcesoId || !correlativo) return;
  
  // Obtener el código del proceso seleccionado
  const selectedProceso = procesos.find((proc) => (`${proc.codigo}  ${proc.nombreProceso}`) === selectedProcesoId);
  const procesoCode = selectedProceso?.codigo || '';
  
  if (procesoCode) {
    // Construir string de niveles
    let nivelString = '';
    
    // Obtener niveles ordenados con los nuevos valores
    const orderedNiveles = Object.entries(newNiveles)
      .sort(([a], [b]) => parseInt(a) - parseInt(b))
      .map(([_, value]) => value)
      .filter(value => value);
    
    if (orderedNiveles.length > 0) {
      nivelString = `-${orderedNiveles.join('-')}`;
    }
    
    // Actualizar el código completo
    const newCodigo = `${procesoCode}${nivelString}-${correlativo}`;
    setCodigo(newCodigo);
    
    // Si el código ha cambiado, verificar si ya existe
    if (newCodigo !== currentDocument?.document) {
      checkNewDocumentCode(newCodigo);
    }
  }
};

// Para actualizar desde correlativo
const updateCodigoFromCorrelativo = (newCorrelativo: string): void => {
  if (!selectedProcesoId || !newCorrelativo) return;
  
  // Obtener el código del proceso seleccionado
  const selectedProceso = procesos.find((proc) => (`${proc.codigo}  ${proc.nombreProceso}`) === selectedProcesoId);
  const procesoCode = selectedProceso?.codigo || '';
  
  if (procesoCode) {
    // Construir string de niveles
    let nivelString = '';
    
    // Obtener niveles ordenados
    const orderedNiveles = Object.entries(modalValues)
      .sort(([a], [b]) => parseInt(a) - parseInt(b))
      .map(([_, value]) => value)
      .filter(value => value);
    
    if (orderedNiveles.length > 0) {
      nivelString = `-${orderedNiveles.join('-')}`;
    }
    
    // Actualizar el código completo
    const newCodigo = `${procesoCode}${nivelString}-${newCorrelativo}`;
    setCodigo(newCodigo);
    
    // Si el código ha cambiado, verificar si ya existe
    // Primero verificamos que currentDocument y document existan
    if (currentDocument && currentDocument.document) {
      const originalCorrelativo = separarDocumentoId(currentDocument.document).correlativo;
      if (newCorrelativo !== originalCorrelativo) {
        checkNewDocumentCode(newCodigo);
      }
    } else {
      // Si no hay documento actual, siempre verificar el nuevo código
      checkNewDocumentCode(newCodigo);
    }
  }
};  



// const updateCodigoFromCorrelativo = (newCorrelativo: string): void => {
//   if (!selectedProcesoId || !newCorrelativo) return;
  
//   // Obtener el código del proceso seleccionado
// const selectedProceso = procesos.find((proc: Proceso) => (`${proc.codigo}  ${proc.nombreProceso}`) === selectedProcesoId);
// const procesoCode = selectedProceso?.codigo || '';
  
//   if (procesoCode) {
//     // Construir string de niveles
//     let nivelString = '';
    
//     // Obtener niveles ordenados
//     const orderedNiveles = Object.entries(modalValues)
//       .sort(([a], [b]) => parseInt(a) - parseInt(b))
//       .map(([_, value]) => value)
//       .filter(value => value);
    
//     if (orderedNiveles.length > 0) {
//       nivelString = `-${orderedNiveles.join('-')}`;
//     }
    
//     // Actualizar el código completo
//     const newCodigo = `${procesoCode}${nivelString}-${newCorrelativo}`;
//     setCodigo(newCodigo);
//   }
// };

// // Función para actualizar el código cuando cambia el proceso
// const updateCodigoFromProceso = (newProcesoId: string) => {
//   if (!newProcesoId || !correlativo) return;
  
//   console.log("Updating code from proceso:", newProcesoId);
  
//   // Find process based on the formatted ID
//   const selectedProceso = procesos.find(
//     (proc) => `${proc.codigo}  ${proc.nombreProceso}` === newProcesoId
//   );
//   if (selectedProceso) { 
//     console.log("Found process:", selectedProceso);
//     const procesoCode = selectedProceso.codigo;
    
//     // Build niveles string
//     let nivelString = '';
    
//     // Get ordered niveles
//     const orderedNiveles = Object.entries(modalValues)
//       .sort(([a], [b]) => parseInt(a) - parseInt(b))
//       .map(([_, value]) => value)
//       .filter(value => value);
    
//     if (orderedNiveles.length > 0) {
//       nivelString = `-${orderedNiveles.join('-')}`;
//     }
    
//     // Update the full code
//     const newCodigo = `${procesoCode}${nivelString}-${correlativo}`;
   

//     console.log("Generated new document code:", newCodigo);
//     setCodigo(newCodigo);

//   } else {
//     console.error("Could not find process with ID:", newProcesoId);
//   }
// };
// // Función para actualizar el código cuando cambian los niveles
// const updateCodigoFromNiveles = (newNiveles: { [key: string]: string }): void => {
//   if (!selectedProcesoId || !correlativo) return;
  
//   // Obtener el código del proceso seleccionado
// const selectedProceso = procesos.find((proc: Proceso) => (`${proc.codigo}  ${proc.nombreProceso}`) === selectedProcesoId);
// const procesoCode = selectedProceso?.codigo || '';
  
//   if (procesoCode) {
//     // Construir string de niveles
//     let nivelString = '';
    
//     // Obtener niveles ordenados con los nuevos valores
//     const orderedNiveles = Object.entries(newNiveles)
//       .sort(([a], [b]) => parseInt(a) - parseInt(b))
//       .map(([_, value]) => value)
//       .filter(value => value);
    
//     if (orderedNiveles.length > 0) {
//       nivelString = `-${orderedNiveles.join('-')}`;
//     }
    
//     // Actualizar el código completo
//     const newCodigo = `${procesoCode}${nivelString}-${correlativo}`;
//     setCodigo(newCodigo);
//   }
// }; 






// Función para manejar la selección de un proceso desde el modal
const handleProcessSelect = (procesoId: string) => {
  const selectedProceso = procesos.find(proc => 
    `${proc.codigo}  ${proc.nombreProceso}` === procesoId
  );
  
  if (selectedProceso) {
    
    setSelectedProcesoId(procesoId);
    setSelectedProcesoName(`${selectedProceso.codigo}  ${selectedProceso.nombreProceso}`);

    // Actualizar el código basado en el nuevo proceso
    updateCodigoFromProceso(procesoId);

    // Indicar que este cambio requiere crear un nuevo documento
    if (selectedProceso.codigo !== originalProcesoId) {
      setIsSubVersion(0);
    }
  }
  
  // Cerrar el modal
  setIsProcessModalOpen(false);
};

// Función para manejar cambio de proceso
const handleProcesoChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
  const newProcesoId = e.target.value;
  setSelectedProcesoId(newProcesoId);
  
  const selectedProceso = procesos.find(proc => proc.id === newProcesoId);
  if (selectedProceso) {
    setModalProject(selectedProceso.nombreProceso);
  }
  
  // Usar la función específica para proceso
  updateCodigoFromProceso(newProcesoId);

};





// Asegurarnos de que los valores finales de revisión y versión se apliquen al guardar
const handleEdit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
  e.preventDefault();
  
  if (!currentDocument) return;

  // Verificar validaciones
  if (!isRevisionValid || !isVersionValid) {
    toast.error('Por favor corrija los errores antes de guardar');
    return;
  }

  // Determinar si hubo cambios en proceso, correlativo o niveles
  const procesoChanged = selectedProcesoId !== originalProcesoId;
  
  const originalCorrelativo = separarDocumentoId(currentDocument.document).correlativo;
  const correlativoChanged = correlativo !== originalCorrelativo;
  
  // Verificar si algún nivel ha cambiado
  const originalNiveles = separarDocumentoId(currentDocument.document).niveles;
  let nivelesChanged = false;
  
  // Comparar niveles originales con los actuales
  Object.entries(modalValues).forEach(([key, value], index) => {
    if (originalNiveles[index] !== value) {
      nivelesChanged = true;
    }
  });

  // Si cambió proceso, correlativo o niveles, marcar para crear nueva entrada
  const shouldCreateNewEntry = procesoChanged || correlativoChanged || nivelesChanged;
  
  // Variables para almacenar la revisión y versión finales
  let finalRevision = revision;
  let finalVersion = version;
  let documentoExiste = false;
  let newDocumentoId = currentDocument.document; // Inicializar con el documento original
  
  // Si hay cambios que requieren un nuevo documento, verificar si el nuevo código ya existe
  if (shouldCreateNewEntry) {
    try {
      // Construir el nuevo código para verificar
      const selectedProceso = procesos.find(proc => 
        `${proc.codigo}  ${proc.nombreProceso}` === selectedProcesoId
      );
      
      if (selectedProceso) {
        const procesoCode = selectedProceso.codigo;
        
        // Construir string de niveles
        let nivelString = '';
        const orderedNiveles = Object.entries(modalValues)
          .sort(([a], [b]) => parseInt(a) - parseInt(b))
          .map(([_, value]) => value)
          .filter(value => value);
        
        if (orderedNiveles.length > 0) {
          nivelString = `-${orderedNiveles.join('-')}`;
        }
        
        // Construir el nuevo código de documento
        newDocumentoId = `${procesoCode}${nivelString}-${correlativo}`;
        console.log("[DEBUG] Nuevo código de documento generado:", newDocumentoId);
        
        // Verificar si el nuevo código ya existe
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/php/pages/controldocument/cargardocumento/compararCodigo.php`,
          {
            nuevoDocumentoCodigo: newDocumentoId
          }
        );
        
        const data = response.data;
        console.log("[DEBUG] Respuesta de verificación de código:", data);
        
        if (data.existsDocument) {
          // Si ya existe un documento con el nuevo código, usar la revisión y versión recomendadas
          finalRevision = data.new_revision;
          finalVersion = data.new_version;
          documentoExiste = true;
          console.log(`[DEBUG] Documento existente encontrado. Usando rev:${finalRevision} ver:${finalVersion}`);
        } else {
          // Si es un código nuevo, usar revisión A y versión 1
          finalRevision = 'A';
          finalVersion = 1;
          documentoExiste = false;
          console.log("[DEBUG] Código nuevo. Usando rev:A ver:1");
        }
      }
    } catch (error) {
      console.error('[ERROR] Error al verificar el nuevo código:', error);
      // En caso de error, continuar con los valores actuales
    }
  } else if (existsDocument && existsRevision && keepCurrentVersion) {
    finalRevision = currentRevision;
    finalVersion = currentVersion;
  }

  // Determinar el tipo de modificación
  let modificationType = 'update_existing';
  
  // Si se subió un nuevo archivo, se cambia la revisión o la versión, o cambios en proceso/correlativo/niveles, crear nueva entrada
  const isNewVersionOrRevision = 
    archivos.length > 0 || 
    finalRevision !== originalRevision || 
    finalVersion !== originalVersion ||
    shouldCreateNewEntry;

  if (isNewVersionOrRevision) {
    modificationType = 'create_new_entry';
  }
  
  // Preparar datos para el backend
  const formData = new FormData();
  const index = 0; // Para compatibilidad con el endpoint guardar_documento.php
  const selectedProceso = procesos.find(
    (proc) => (`${proc.codigo}  ${proc.nombreProceso}`) === selectedProcesoId
  );
  const procesoCode = selectedProceso?.codigo || '';

  if (modificationType === 'create_new_entry') {
    console.log(`[DEBUG] Guardando nueva entrada con rev:${finalRevision} ver:${finalVersion}`);
    
    // Actualizar el código visible en el modal si es necesario
    setCodigo(shouldCreateNewEntry ? newDocumentoId : codigo);
    
    // Datos del documento para una nueva versión/revisión
    formData.append(`documentos[${index}][id]`, currentDocument.id);
    formData.append(`documentos[${index}][proyecto]`, selectedProcesoId);
    formData.append(`documentos[${index}][codigo]`, procesoCode || '');
    formData.append(`documentos[${index}][username]`, username || '');
    formData.append(`documentos[${index}][titulo]`, tituloDocumento);
    formData.append(`documentos[${index}][correlativo]`, correlativo);
    
    // ⚠️ Usar explícitamente los valores finales para revisión y versión
    formData.append(`documentos[${index}][revision]`, finalRevision);
    formData.append(`documentos[${index}][version]`, finalVersion.toString());
    
    formData.append(`documentos[${index}][comentarios]`, comentarios);
    
    // IMPORTANTE: Usar el nuevo ID de documento en caso de cambios estructurales
    formData.append(`documentos[${index}][document_id]`, shouldCreateNewEntry ? newDocumentoId : codigo);
    console.log(`[DEBUG] Enviando document_id: ${shouldCreateNewEntry ? newDocumentoId : codigo}`);
    
    // Actualizar el documento completo con los valores finales correctos
    formData.append(
      `documentos[${index}][documento_completo]`, 
      `${shouldCreateNewEntry ? newDocumentoId : codigo} ${tituloDocumento} REV${finalRevision} V${finalVersion}`
    );
    
    // Indicar si el documento ya existe con este código
    formData.append(`documentos[${index}][documento_existe]`, documentoExiste ? 'true' : 'false');
    console.log(`[DEBUG] documento_existe: ${documentoExiste ? 'true' : 'false'}`);
    
    formData.append(`documentos[${index}][crear_nuevo_documento]`, 'true');
    formData.append(`documentos[${index}][no_marcar_subversion]`, 'true');
    
    // Solo enviamos el ID de referencia del documento original
    formData.append(`documentos[${index}][documento_original_id]`, currentDocument.id);
    
    // Enviar indicador de que no es subversión
    formData.append(`documentos[${index}][no_marcar_subversion]`, shouldCreateNewEntry ? 'true' : 'false');

    // Indicar que es una actualización (nueva versión/revisión)

    // Indicar que es una actualización (nueva versión/revisión)
    formData.append(`documentos[${index}][actualizar]`, 'true');
    
    // Definir el tipo de actualización basado en los cambios
    let tipoActualizacion = '';
    if (procesoChanged) {
      tipoActualizacion = 'cambio_proceso';
    } else if (nivelesChanged) {
      tipoActualizacion = 'cambio_niveles';
    } else if (correlativoChanged) {
      tipoActualizacion = 'cambio_correlativo';
    } else if (finalRevision !== originalRevision) {
      tipoActualizacion = 'nueva_revision';
    } else {
      tipoActualizacion = 'nueva_version';
    }
    
    formData.append(`documentos[${index}][tipo_actualizacion]`, tipoActualizacion);
    console.log(`[DEBUG] tipo_actualizacion: ${tipoActualizacion}`);
    
    // Agregar niveles seleccionados
    formData.append(`documentos[${index}][selectedValues]`, JSON.stringify(modalValues || {}));
    
    // Estructura de niveles
    const nivelesArray = niveles.slice(0, 3).map(nivel => ({
      nivel_id: nivel.nivel_id,
      titulo: nivel.titulo
    }));
    formData.append(`documentos[${index}][niveles]`, JSON.stringify(nivelesArray));
    
    // Si hay un nuevo archivo
    if (archivos.length > 0) {
      console.log("[DEBUG] Uploading new file:", archivos[0].name);
      
      formData.append('archivo', archivos[0]); 
      
      formData.append(`documentos[${index}][nuevo_archivo]`, 'true');
      formData.append(`documentos[${index}][archivo_nombre]`, archivos[0].name);
    }
    
    // Si el usuario decidió mantener la versión actual, agregar bandera para el backend
    if (existsDocument && existsRevision && keepCurrentVersion) {
      formData.append(`documentos[${index}][mantener_version_actual]`, 'true');
    }
   
    try {
      // Depurar los valores que estamos enviando
      console.log("[DEBUG] Enviando form data:");
      const formDataEntries = Array.from(formData.entries());
      formDataEntries.forEach(([key, value]) => {
        console.log(`${key}: ${value}`);
      });
      
      // Usar el endpoint guardar_documento.php para nueva versión/revisión
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/php/pages/controldocument/cargardocumento/guardar_documento.php`,
        {
          method: 'POST',
          body: formData
        }
      );
      
      if (!response.ok) {
        throw new Error(`Error al guardar documento: ${response.statusText}`);
      }
      
      const result = await response.json();
      console.log('[DEBUG] Respuesta del servidor:', result);
      
      if (Array.isArray(result) && result.some((r) => r.status === 'error')) {
        toast.error('Algunos documentos no se pudieron guardar correctamente.');
      } else {
        toast.success('Documento actualizado correctamente.');
        // Recargar documentos para mostrar la nueva versión
        await fetchDocuments();
      }
      
    } catch (error) {
      console.error('[ERROR] Error al guardar documento:', error);
      toast.error('Error al comunicarse con el servidor.');
    }
    
  } else {
    // Si solo se modifica título, comentarios o proceso, usar updateDocument.php
    formData.append('document_id', currentDocument.id);
    formData.append('title', tituloDocumento);
    formData.append('revision', finalRevision);
    formData.append('version', finalVersion.toString());
    formData.append('comment', comentarios);
    formData.append('process_id', selectedProcesoId);
    formData.append('document_code', codigo);
    formData.append('correlativo', correlativo);
    formData.append('niveles', JSON.stringify(modalValues));

    // Para el caso de actualizaciones simples
    if (isSubVersion === 1) {
      // Indicamos que el documento actual debe ser marcado como subversión en la base de datos
      formData.append('subversion', '1');
      formData.append('documento_original_id', currentDocument.id);
    }
    
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/php/pages/controldocument/busquedarapida/updateDocumento.php`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      
      if (response.data.success) {
        toast.success('Documento actualizado correctamente');
        
        // Actualizar el documento en el estado local
        const updatedDoc = {
          ...currentDocument,
          project: selectedProcesoId,
          projectName: currentDocument?.projectName || '',
          document: codigo,
          title: tituloDocumento,
          comment: comentarios,
          revision: finalRevision,
          version: finalVersion,
          // También incluir subversion en el estado local
          subversion: isSubVersion
        };
        
        setDocuments(prev => 
          prev.map(doc => doc.id === updatedDoc.id ? updatedDoc : doc)
        );
        
        setFilteredDocuments(prev => 
          prev.map(doc => doc.id === updatedDoc.id ? updatedDoc : doc)
        );
      } else {
        toast.error(response.data.message || 'Error al actualizar el documento');
      }
    } catch (error) {
      console.error('[ERROR] Error al actualizar documento:', error);
      toast.error('Error al comunicarse con el servidor');
    }
  }

  // Cerrar modal y limpiar
  setIsEditModalOpen(false);
  setArchivos([]);
  setIsFilesLoaded(false);
  setLabelMessage('');
  setKeepCurrentVersion(false);
  setIsSubVersion(0); // Resetear el estado de subversión
};







// const handleEdit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
//   e.preventDefault();
  
//   if (!currentDocument) return;

//   // Verificar validaciones
//   if (!isRevisionValid || !isVersionValid) {
//     toast.error('Por favor corrija los errores antes de guardar');
//     return;
//   }

//   // Determinar si hubo cambios en proceso, correlativo o niveles
//   const procesoChanged = selectedProcesoId !== originalProcesoId;

//   const correlativoChanged = correlativo !== separarDocumentoId(currentDocument.document).correlativo;
  
//   // Verificar si algún nivel ha cambiado
//   const originalNiveles = separarDocumentoId(currentDocument.document).niveles;
//   let nivelesChanged = false;
  
//   // Comparar niveles originales con los actuales
//   Object.entries(modalValues).forEach(([key, value], index) => {
//     if (originalNiveles[index] !== value) {
//       nivelesChanged = true;
//     }
//   });

//   // Si cambió proceso, correlativo o niveles, marcar para crear nueva entrada
//   const shouldCreateNewEntry = procesoChanged || correlativoChanged || nivelesChanged;
  
//   if (shouldCreateNewEntry) {
//     // Forzar creación de nueva entrada pero NO marcar original como subversión
//     // El original se mantendrá como documento independiente
//     setIsSubVersion(0);
//   }

//   // Obtener la revisión y versión final según la elección del usuario
//   let finalRevision = revision;
//   let finalVersion = version;
  
//   if (existsDocument && existsRevision && keepCurrentVersion) {
//     finalRevision = currentRevision;
//     finalVersion = currentVersion;
//   }

//   // Determinar el tipo de modificación
//   let modificationType = 'update_existing';
  
//   // Si se subió un nuevo archivo, se cambia la revisión o la versión, o cambios en proceso/correlativo/niveles, crear nueva entrada
//   const isNewVersionOrRevision = 
//     archivos.length > 0 || 
//     finalRevision !== originalRevision || 
//     finalVersion !== originalVersion ||
//     shouldCreateNewEntry;

//   if (isNewVersionOrRevision) {
//     modificationType = 'create_new_entry';
//   }
  
//   // Preparar datos para el backend
//   const formData = new FormData();
//   const index = 0; // Para compatibilidad con el endpoint guardar_documento.php
//   const selectedProceso = procesos.find((proc: Proceso) => (`${proc.codigo}  ${proc.nombreProceso}`) === selectedProcesoId);
// const procesoCode = selectedProceso?.codigo || '';

//   if (modificationType === 'create_new_entry') {
//     // Datos del documento para una nueva versión/revisión
//     formData.append(`documentos[${index}][id]`, currentDocument.id);
//     formData.append(`documentos[${index}][proyecto]`, selectedProcesoId);
//     formData.append(`documentos[${index}][codigo]`, procesoCode || '');
//     formData.append(`documentos[${index}][username]`, username || '');
//     formData.append(`documentos[${index}][titulo]`, tituloDocumento);
//     formData.append(`documentos[${index}][correlativo]`, correlativo);
//     formData.append(`documentos[${index}][revision]`, finalRevision);
//     formData.append(`documentos[${index}][version]`, finalVersion.toString());
//     formData.append(`documentos[${index}][comentarios]`, comentarios);
//     formData.append(`documentos[${index}][document_id]`, codigo);
//     formData.append(`documentos[${index}][documento_completo]`, `${codigo} ${tituloDocumento} REV${finalRevision} V${finalVersion}`);
//     // Dentro de la función handleEdit, cuando se detecta un cambio de proceso
//     //alert(`${currentDocument?.projectCode} - ${ currentDocument.id} - ${codigo}`)
//     formData.append(`documentos[${index}][crear_nuevo_documento]`, 'true');
//     formData.append(`documentos[${index}][no_marcar_subversion]`, 'true');
//     // Para cambios en proceso/niveles/correlativo, NO se marca el original como subversión
//     // Solo enviamos el ID de referencia del documento original
//     formData.append(`documentos[${index}][documento_original_id]`, currentDocument.id);
//     // Enviar indicador de que no es subversión
//     formData.append(`documentos[${index}][no_marcar_subversion]`, shouldCreateNewEntry ? 'true' : 'false');

//     // Indicar que es una actualización (nueva versión/revisión)
//     formData.append(`documentos[${index}][actualizar]`, 'true');
    
//     // Definir el tipo de actualización basado en los cambios
//     let tipoActualizacion = '';
//     if (procesoChanged) {
//       tipoActualizacion = 'cambio_proceso';
//     } else if (nivelesChanged) {
//       tipoActualizacion = 'cambio_niveles';
//     } else if (correlativoChanged) {
//       tipoActualizacion = 'cambio_correlativo';
//     } else if (finalRevision !== originalRevision) {
//       tipoActualizacion = 'nueva_revision';
//     } else {
//       tipoActualizacion = 'nueva_version';
//     }
    
//     formData.append(`documentos[${index}][tipo_actualizacion]`, tipoActualizacion);
//      // IMPORTANT: If there is a new file, add it to the formData
   
//     // Agregar niveles seleccionados
//     formData.append(`documentos[${index}][selectedValues]`, JSON.stringify(modalValues || {}));
    
//     // Estructura de niveles
//     const nivelesArray = niveles.slice(0, 3).map(nivel => ({
//       nivel_id: nivel.nivel_id,
//       titulo: nivel.titulo
//     }));
//     formData.append(`documentos[${index}][niveles]`, JSON.stringify(nivelesArray));
    
//     // Si hay un nuevo archivo
//     // If there are files to upload, append them directly with the key the backend expects
//     if (archivos.length > 0) {
//       console.log("Uploading new file:", archivos[0].name);
      
//       // IMPORTANT: Send file as "archivo" directly, not as documentos[index][archivo]
//       formData.append('archivo', archivos[0]); 
      
//       // Still keep the file metadata in the documentos array
//       formData.append(`documentos[${index}][nuevo_archivo]`, 'true');
//       formData.append(`documentos[${index}][archivo_nombre]`, archivos[0].name);
//     }
//     // Si el usuario decidió mantener la versión actual, agregar bandera para el backend
//     if (existsDocument && existsRevision && keepCurrentVersion) {
//       formData.append(`documentos[${index}][mantener_version_actual]`, 'true');
//     }
   
    
//     try {
//       // Usar el endpoint guardar_documento.php para nueva versión/revisión
//       const response = await fetch(
//         `${process.env.REACT_APP_API_URL}/php/pages/controldocument/cargardocumento/guardar_documento.php`,
//         {
//           method: 'POST',
//           body: formData
//         }
//       );
      
//       if (!response.ok) {
//         throw new Error(`Error al guardar documento: ${response.statusText}`);
//       }
      
//       const result = await response.json();
//       console.log('Respuesta del servidor:', result);
      
//       if (Array.isArray(result) && result.some((r) => r.status === 'error')) {
//         toast.error('Algunos documentos no se pudieron guardar correctamente.');
//       } else {
//         toast.success('Documento actualizado correctamente.');
//         // Recargar documentos para mostrar la nueva versión
//         await fetchDocuments();
//       }
      
//     } catch (error) {
//       console.error('Error al guardar documento:', error);
//       toast.error('Error al comunicarse con el servidor.');
//     }
    
//   } else {
//     // Si solo se modifica título, comentarios o proceso, usar updateDocument.php
//     formData.append('document_id', currentDocument.id);
//     formData.append('title', tituloDocumento);
//     formData.append('revision', finalRevision);
//     formData.append('version', finalVersion.toString());
//     formData.append('comment', comentarios);
//     formData.append('process_id', selectedProcesoId);
//     formData.append('document_code', codigo);
//     formData.append('correlativo', correlativo);
//     formData.append('niveles', JSON.stringify(modalValues));

//     // Para el caso de actualizaciones simples
//     if (isSubVersion === 1) {
//       // Indicamos que el documento actual debe ser marcado como subversión en la base de datos
//       formData.append('subversion', '1');
//       formData.append('documento_original_id', currentDocument.id);
//     }
    
//     try {
      
//       const response = await axios.post(
//         `${process.env.REACT_APP_API_URL}/php/pages/controldocument/busquedarapida/updateDocumento.php`,
//         formData,
//         {
//           headers: {
//             'Content-Type': 'multipart/form-data'
//           }
//         }
//       );
      
//       if (response.data.success) {
//         toast.success('Documento actualizado correctamente');
        
//         // Actualizar el documento en el estado local
//         const updatedDoc = {
//           ...currentDocument,
//           project: selectedProcesoId,
//           projectName: currentDocument?.projectName || '',
//           document: codigo,
//           title: tituloDocumento,
//           comment: comentarios,
//           revision: finalRevision,
//           version: finalVersion,
//           // También incluir subversion en el estado local
//           subversion: isSubVersion
//         };
        
//         setDocuments(prev => 
//           prev.map(doc => doc.id === updatedDoc.id ? updatedDoc : doc)
//         );
        
//         setFilteredDocuments(prev => 
//           prev.map(doc => doc.id === updatedDoc.id ? updatedDoc : doc)
//         );
//       } else {
//         toast.error(response.data.message || 'Error al actualizar el documento');
//       }
//     } catch (error) {
//       console.error('Error al actualizar documento:', error);
//       toast.error('Error al comunicarse con el servidor');
//     }
//   }
 

//   // Cerrar modal y limpiar
//   setIsEditModalOpen(false);
//   setArchivos([]);
//   setIsFilesLoaded(false);
//   setLabelMessage('');
//   setKeepCurrentVersion(false);
//   setIsSubVersion(0); // Resetear el estado de subversión
// };
























// const handleEdit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
//   e.preventDefault(); // Prevenir el comportamiento predeterminado del formulario
  
//   if (!currentDocument) return;

//   // Verificar si hay errores de validación
//   if (!isRevisionValid || !isVersionValid) {
//     toast.error('Por favor corrija los errores antes de guardar');
//     return;
//   }

//   // Determinar tipo de actualización
//   let updateType = '';
  
//   // Si cambió la revisión
//   if (revision !== originalRevision) {
//     updateType = 'nueva_revision';
//   } 
//   // Si cambió la versión pero no la revisión
//   else if (version !== originalVersion) {
//     updateType = 'nueva_version';
//   }
//   // Si solo cambió metadata (título o comentarios)
//   else if (tituloDocumento !== currentDocument.title || comentarios !== currentDocument.comment) {
//     updateType = 'metadatos';
//   }
//   // No hay cambios estructurales
//   else {
//     updateType = 'sin_cambios';
//   }

//   // Crear un objeto actualizado con los valores actuales
//   const updatedDoc = {
//     ...currentDocument,
//     title: tituloDocumento,
//     revision: revision,
//     version: version,
//     comment: comentarios,
//     issuedBy: username || currentDocument.issuedBy
//   };

//   // Actualizar el estado local primero
//   setDocuments(prev =>
//     prev.map(doc =>
//       doc.id === updatedDoc.id
//         ? { ...doc, ...updatedDoc } // Actualiza el documento en el state
//         : doc
//     )
//   );
  
//   // También actualizar filteredDocuments para mantener la sincronización
//   setFilteredDocuments(prev =>
//     prev.map(doc =>
//       doc.id === updatedDoc.id
//         ? { ...doc, ...updatedDoc }
//         : doc
//     )
//   );

//   // Construir el nombre completo del documento con el formato correcto
//   const documentoCompleto = `${codigo} ${tituloDocumento} REV${revision} V${version}`;

//   // Preparar los datos para el backend
//   const formData = new FormData();
//   const index = 0;

//   // Mantener exactamente los mismos nombres de campo que el código original
//   formData.append(`documentos[${index}][id]`, currentDocument.id);
//   formData.append(`documentos[${index}][proyecto]`, currentDocument.project);
//   formData.append(`documentos[${index}][codigo]`, currentDocument.projectCode);
//   formData.append(`documentos[${index}][username]`, username || currentDocument.issuedBy);
//   formData.append(`documentos[${index}][titulo]`, tituloDocumento);
//   formData.append(`documentos[${index}][correlativo]`, correlativo);
//   formData.append(`documentos[${index}][revision]`, revision);
//   formData.append(`documentos[${index}][version]`, version.toString());
//   formData.append(`documentos[${index}][comentarios]`, comentarios);
//   formData.append(`documentos[${index}][subversion]`, String(currentDocument.subVersions?.length || 0));
  
//   // Agregar el nombre completo del documento
//   formData.append(`documentos[${index}][documento_completo]`, documentoCompleto);
   
//   // Agregar campo para indicar que es una actualización 
//   formData.append(`documentos[${index}][actualizar]`, 'true');
  
//   // Agregar campo para indicar el tipo de actualización
//   formData.append(`documentos[${index}][tipo_actualizacion]`, updateType);

//   // Agregar los valores de niveles
//   formData.append(`documentos[${index}][selectedValues]`, JSON.stringify(modalValues || {}));
  
//   // Luego la estructura de los niveles
//   const nivelesArray = niveles.slice(0, 3).map(nivel => ({
//     nivel_id: nivel.nivel_id,
//     titulo: nivel.titulo
//   }));
//   formData.append(`documentos[${index}][niveles]`, JSON.stringify(nivelesArray));

//   // Si hay archivos nuevos, agregarlos al FormData
//   if (archivos.length > 0) {
//     formData.append(`documentos[${index}][archivo]`, archivos[0]);
//   }
  
//   try {
//     const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/controldocument/cargardocumento/guardar_documento.php`, {
//       method: 'POST',
//       body: formData,
//     });

//     if (!response.ok) {
//       throw new Error(`Error al enviar documentos: ${response.statusText}`);
//     }

//     const result = await response.json();
//     console.log('Respuesta del servidor:', result);

//     if (Array.isArray(result) && result.some((r) => r.status === 'error')) {
//       toast.error('Algunos documentos no se pudieron guardar correctamente.');
//     } else {
//       toast.success('Documento actualizado correctamente.');
//       // Recargar todos los documentos para asegurar sincronización
//       fetchDocuments();
//     }

//   } catch (error) {
//     console.error('Error al enviar datos:', error);
//     toast.error('Error al enviar datos al servidor.');
//   }

//   // Limpiar estados y cerrar modal
//   setIsEditModalOpen(false);
//   setArchivos([]);
//   setIsFilesLoaded(false);
//   setLabelMessage('');
// };

// const handleEdit = async (updatedDoc: Document) => {
//   setDocuments(prev =>
//     prev.map(doc =>
//       doc.id === updatedDoc.id
//         ? { ...doc, ...updatedDoc } // Actualiza el documento en el state
//         : doc
//     )
//   );

//   // Aquí preparas los datos para el backend
//   const formData = new FormData();
//   const index = 0; // Solo hay un documento actualizado en este caso (el editado)

//   formData.append(`documentos[${index}][proyecto]`, updatedDoc.project);
//   formData.append(`documentos[${index}][codigo]`, updatedDoc.projectCode);
//   formData.append(`documentos[${index}][username]`, updatedDoc.issuedBy);
//   formData.append(`documentos[${index}][titulo]`, updatedDoc.title);
//   formData.append(`documentos[${index}][correlativo]`, updatedDoc.correlativo);
//   formData.append(`documentos[${index}][revision]`, updatedDoc.revision);
//   formData.append(`documentos[${index}][subversion]`, String(updatedDoc.subVersions));


//   try {
//     const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/controldocument/cargardocumento/guardar_documento.php`, {
//       method: 'POST',
//       body: formData,
//     });

//     if (!response.ok) {
//       throw new Error(`Error al enviar documentos: ${response.statusText}`);
//     }

//     const result = await response.json();
//     console.log('Respuesta del servidor:', result);

//     if (result.some((r: any) => r.status === 'error')) {
//       alert('Algunos documentos no se pudieron guardar correctamente.');
//     } else {
//       alert('Documento actualizado correctamente.');
//     }

//   } catch (error) {
//     console.error('Error al enviar datos:', error);
//     alert('Error al enviar datos al servidor.');
//   }

//   setIsEditModalOpen(false);
// };



  const handleDelete = () => {
    if (currentDocument) {
      setDocuments(prev => prev.map(doc => 
        doc.id === currentDocument.id
          ? { ...doc, subVersions: doc.subVersions?.filter(subDoc => subDoc.id !== currentDocument.id) }
          : doc
      ).filter(doc => doc.id !== currentDocument.id));
    }
    setIsDeleteModalOpen(false);
  };
  const TextArea: React.FC<{
    label: string;
    value: string;
    onChange: (value: string) => void;
  }> = ({ label, value, onChange }) => (
    <div className="mb-2">
      <label className="block text-sm font-medium mb-1">{label}</label>
      <textarea
        className="border rounded px-2 py-1 w-full h-16 resize-none text-sm"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );


const Select: React.FC<{
  label: string;
  value: string;
  onChange: (value: string) => void;
  options: { value: string; label: string }[];
}> = ({ label, value, onChange, options }) => (
  <div className="mb-2">
    <label className="block text-sm font-medium mb-1">{label}</label>
    <select
      className="border rounded px-2 py-1 w-full text-sm"
      value={value}
      onChange={(e) => onChange(e.target.value)}
    >
      <option value="">Seleccione una opción</option>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  </div>
);






  const downloadSelectedDocuments = () => {
    if (selectedDocuments.length > 0) {
      handleDownload(selectedDocuments);
      
    } else {
      alert('Por favor, seleccione al menos un documento para descargar.');
    }
  };


  const transmittalSelectedDocuments = () => {
    if (selectedDocuments.length > 0) {
   //  alert(selectedDocuments)
      handleTransmittal();
      
    } else {
      alert('Por favor, seleccione al menos un documento para generar el transmittal.');
    }
  };
  // Pagination logic
  const indexOfLastDocument = currentPage * documentsPerPage;
  const indexOfFirstDocument = indexOfLastDocument - documentsPerPage;
  const currentDocuments = filteredDocuments.slice(indexOfFirstDocument, indexOfLastDocument);

  const totalPages = Math.ceil(filteredDocuments.length / documentsPerPage);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  //transmital


  const handleProjectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setProyectoSeleccionado(e.target.value);
  };

  const handleRecordsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setDocumentsPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to first page
  };

  const viewFile = (path: string, type: 'document' | 'transmittal') => {
    if (!path) {
        console.error('El path es undefined o vacío.');
        return;
    }

    let fullUrl = path;

    if ((type === 'document') ){
        const baseUrl = process.env.REACT_APP_DOCS_URL; // Asegúrate de tener esto en el .env
        if (!baseUrl) {
            console.error('REACT_APP_DOCS_URL no está definido en el .env');
            return;
        }

        fullUrl = `${baseUrl}/uploads/${path}`;
    } else if (type === 'transmittal') {
        // Si es transmittal, el path ya es la URL completa.
        fullUrl = path;
    }else{
      const baseUrl = process.env.REACT_APP_DOCS_URL; // Asegúrate de tener esto en el .env
      if (!baseUrl) {
          console.error('REACT_APP_DOCS_URL no está definido en el .env');
          return;
      }

      fullUrl = `${baseUrl}/uploads/${path}`;
    }

    console.log('Abriendo URL:', fullUrl);
    window.open(fullUrl, '_blank');
};

/* 
  useEffect(() => {
    // Sincroniza projectFilter con proyectoSeleccionado cuando se monta el componente
    if (proyectoSeleccionado) {
      setProjectFilter(proyectoSeleccionado);
    } else {
      setProjectFilter('');
    }
  }, [proyectoSeleccionado]);
*/





//   return (
//     <div className="p-4">
//       <h1 className="text-2xl font-bold mb-4">Búsqueda de documentos</h1>
      
//       <div className="mb-4 overflow-x-auto">
//         <button 
          
//           onClick={downloadSelectedDocuments}
//           disabled={selectedDocuments.length === 0}
//           className={`px-4 py-2 text-white rounded-md ${
//             selectedDocuments.length > 0
//               ? 'bg-cyan-500 hover:bg-cyan-600 cursor-pointer'
//               : 'bg-gray-400 cursor-not-allowed'
//           }`}
//         >
//           <Download className="inline-block mr-2" />
//          <b> Descargar Documentos Seleccionados</b>
//         </button>
       
//         <button 
           
//           onClick={transmittalSelectedDocuments}
//           disabled={selectedDocuments.length === 0}
//           className={`ml-3 px-4 py-2 text-white rounded-md ${
//             selectedDocuments.length > 0
//               ? 'bg-yellow-400 hover:bg-yellow-500 cursor-pointer'
//               : 'bg-gray-400 cursor-not-allowed'
//           }`}
//         >
//           <Mail className="inline-block mr-2" />
//          <b> Generar Transmittal</b>
//         </button>

        
//       </div>
     
//       <div className="grid grid-cols-3 gap-4 mb-4 ">
//      <select
//   id="projectFilter"
//   className="p-2 border border-gray-300 rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
//   value={projectFilter}
//   onChange={(e) => setProjectFilter(e.target.value)}
//   aria-label="Filtro de Procesos"
// >
//   <option value="">Seleccionar proceso</option>
// {projectDisplayOptions.length > 0 ? (
//   Array.from(
//     new Set(projectDisplayOptions.map(project => project.display))
//   ).map(display => {
//     const project = projectDisplayOptions.find(p => p.display === display);
//     return project ? (
//       <option key={project.value} value={project.value}>
//         {project.display}
//       </option>
//     ) : null;
//   })
// ) : (
//   <option disabled>No hay procesos disponibles</option>
// )}

// </select>
//     <input 
//           className="border p-2 rounded" 
//           placeholder="Filtro por Título" 
//           value={titleFilter}
//           onChange={(e) => setTitleFilter(e.target.value)}
//         />
//         <input 
//           className="border p-2 rounded" 
//           placeholder="Comentario" 
//           value={commentFilter}
//           onChange={(e) => setCommentFilter(e.target.value)}
//         />
       
//         {niveles.slice(0, 3).map(nivel => (
//           <div key={nivel.nivel_id}>
//             <label htmlFor={`select-${nivel.nivel_id}`} className="block text-lg font-semibold mb-2 ">
//               {nivel.titulo}
//             </label>
//             <select
//               id={`select-${nivel.nivel_id}`}
              
//               value={selectedValues[nivel.nivel_id] || ''}
//               onChange={(e) => handleSelectChange(nivel.nivel_id, e.target.value)}
             
//               className="p-2 border border-gray-300 rounded w-full"
//             >
//               <option value="">Seleccione {nivel.titulo}</option>
//               {getOptionsForNivel(nivel.nivel_id)}
//             </select>
//           </div>
//         ))}
      
//       </div>

//       <TransmittalModal
        
//         isOpen={isTransmittalModalOpen}
//         onClose={handleCloseTransmittalModal}
//         onSubmit={handleSubmitTransmittal}
//         documents={checkedDocuments}
//       />

//       {filteredDocuments.length > 0 ?  (
//     <>
      
//       <div className="mb-4 flex items-center ">
//         <span className="mr-2">Mostrar</span>
//         <select 
//           className="border p-2 rounded mr-4"
//           value={documentsPerPage}
//           onChange={handleRecordsPerPageChange}
//         >
//           <option value={5}>5</option>
//           <option value={10}>10</option>
//           <option value={25}>25</option>
//           <option value={50}>50</option>
//           <option value={100}>100</option>
//           <option value={250}>250</option>
//           <option value={500}>500</option>
//         </select>
//         <span>registros por página</span>
//         {


// /*


// {selectedDocuments.length > 0 && (
//         <div className="ml-auto">
//           <button 
//             onClick={handleDeleteSelectedDocuments} 
//             className="bg-red-500 text-white px-4 py-2 rounded"
//           >
//             Eliminar Documentos Seleccionados
//           </button>
//         </div>
//       )}

// */

//         }
//       </div>
      
     
//       <table className="min-w-full border shadow-md w-full">
//         <thead className="sticky top-0 bg-cyan-500 text-white"> 
//           <tr className="bg-cyan-500 text-white">
//             <th className="p-2">      <input
//         type="checkbox"
//         checked={isAllSelected}
//         onChange={toggleSelectAll}
//       /></th>
//           <th className="p-2">#</th> {/* Nueva columna para el ID secuencial */}

//             <th className="p-2"></th>
//             <th className="p-2"></th>
//    <th className="border-b p-2" onClick={() => handleSort('project')} style={{ cursor: 'pointer' }}>
//     Proceso {sortColumn === 'project' && (sortDirection === 'asc' ? '▲' : '▼')}
// </th>
// <th className="border-b p-2" onClick={() => handleSort('document')} style={{ cursor: 'pointer' }}>
//     Documento {sortColumn === 'document' && (sortDirection === 'asc' ? '▲' : '▼')}
// </th>


// <th className="border-b p-2" onClick={() => handleSort('date')} style={{ cursor: 'pointer' }}>
//     Fecha{sortColumn === 'date' && (sortDirection === 'asc' ? '▲' : '▼')}
// </th>
// <th className="border-b p-1" onClick={() => handleSort('issuedBy')} style={{ cursor: 'pointer' }}>
//     Emisor {sortColumn === 'issuedBy' && (sortDirection === 'asc' ? '▲' : '▼')}
// </th>
// <th className="border-b" onClick={() => handleSort('comment')} style={{ cursor: 'pointer' }}>
//     Comentario {sortColumn === 'comment' && (sortDirection === 'asc' ? '▲' : '▼')}
// </th>

//             <th className="border-b p-2">Acciones</th>
//           </tr>
//         </thead>
//         <tbody>
          
//         {currentDocuments.map((doc, index) => (
//             <React.Fragment key={doc.id}>
//                <tr key={doc.id} className={getRowClassName(doc.id)}>
//                 <td className="border-b p-2">
//                   <input 
                  
//                     type="checkbox" 
//                     checked={selectedDocuments.includes(doc.id)}
//                      onChange={() => toggleDocumentSelection(doc.id)}
//                   />
//                 </td>
//                 <td className="border-b p-2 text-center">
//                   {indexOfFirstDocument + index + 1} {/* Número secuencial basado en la paginación */}
//                 </td>
//                 <td className="border-b p-2">
//                   {doc.subVersions && doc.subVersions.length > 0 && (
//                     <button 
//                       onClick={() => toggleRow(doc.id)}
//                       className="w-6 h-6 flex items-center justify-center rounded"
//                     >
//                       {expandedRows.includes(doc.id) ? <MinusCircle /> : <PlusCircle />}
//                     </button>
//                   )}              
//                 </td>
//                 <td className="border-b p-2">
//                   {doc.flagged && <Flag className="text-red-500" />}
//                 </td>
//                 <td className="border-b p-2">{doc.projectName}</td>
//                 <td className="border-b p-2 text-left">
//   {doc.document} {doc.title} &nbsp;
//   {`REV${doc.revision} V${doc.version}`}
   
// </td>

//                 <td className="border-b p-2">{doc.date}</td>
//                 <td className="border-b p-2">{doc.issuedBy}</td>
//                 <td className="border-b p-2">
//     {doc.comment.length > 30 ? `${doc.comment.substring(0, 30)}...` : doc.comment}
// </td>
//                 <td className="border-b p-2">
//                   <div className="flex ">
//                   <button className='text-blue-500' onClick={() => viewFile(doc.path, doc.itemType)}><Eye size={20}/> </button>
//                   <button 
//   className={`${doc.itemType === 'document' ? 'text-yellow-500' : 'text-gray-400'}`} 
//   disabled={doc.itemType === 'transmittal'} 
//   onClick={() => openEditModal(doc)}
// >  
//   <Edit size={20} />
// </button>

                  
//                     <button onClick={() => openDeleteModal(doc)}   disabled={doc.itemType === 'transmittal'} 
//  className={`${doc.itemType === 'document' ? 'text-red-600' : 'text-gray-400'}`}>
        
      
//         <Trash size={20} />
//       </button>
          
//       {/* Modal de confirmación */}
//       <ConfirmationModal
//         isOpen={isDeleteModalOpen}
//         onClose={closeDeleteModal}
//         onConfirm={handleConfirmDelete}
//       />
//                   </div>
//                 </td>
//               </tr>
                     
// {doc.subVersions && expandedRows.includes(doc.id) && 
//   // Sort subversions by date in ascending order
//   [...doc.subVersions]
//   .filter(subDoc => subDoc.id !== doc.id) // Eliminar el documento actual de las subversiones
//   .sort((b, a) => {
//       // Convert dates to comparable format (timestamp)
//       const dateA = new Date(a.date).getTime();
//       const dateB = new Date(b.date).getTime();
//       return dateA - dateB; // Ascending order (oldest first)
//       // For descending order (newest first), use: return dateB - dateA;
//     })
//     .map((subDoc, subIndex) => (
//       <tr key={subDoc.id} className="bg-gray-100">
//         <td className="border-b p-2">
//           <input 
//             type="checkbox" 
//             checked={selectedDocuments.includes(subDoc.id)}
//             onChange={() => toggleDocumentSelection(subDoc.id)}
//           />
//         </td>
//         <td className="border-b p-2 text-center">
//                       {`${indexOfFirstDocument + index + 1}.${subIndex + 1}`} {/* Numeración para subversiones */}
//                     </td>
//         <td className="border-b p-2"></td>
//         <td className="border-b p-2">
//           {subDoc.flagged && <Flag className="text-red-500" />}
//         </td>
//         <td></td>
        
//         <td className="border-b p-2 text-left">
//         {subDoc.document} {subDoc.title} REV{subDoc.revision} V{subDoc.version}</td>
//         <td className="border-b p-2">{subDoc.date}</td>
//         <td className="border-b p-2">{subDoc.issuedBy}</td>
//         <td className="border-b p-2">
//     {subDoc.comment.length > 30 ? `${subDoc.comment.substring(0, 30)}...` : subDoc.comment}
// </td>
//         <td className="border-b p-2">
//           <div className="flex space-x-2">
//             <button className='text-yellow-500' onClick={() => viewFile(subDoc.path, subDoc.itemType)}><Eye size={20}/> </button>
//             <button 
//   className="text-red-500" 
//   onClick={(e) => {
//     e.stopPropagation(); // Evitar que se expanda/colapse la fila
//     openSubdocumentDeleteModal(doc, subDoc);
//   }}
// >
//   <Trash size={20} />
// </button>
//           </div>
//         </td> 
//       </tr>
//               ))}
//             </React.Fragment>
//           ))}
//         </tbody>
//       </table>

//       {/* Pagination Controls */}
//       <div className="flex justify-between items-center mt-4">
//         {/* <span>
//           Página {currentPage} de {totalPages}
//         </span> */}
//         {/* <div>
//           {Array.from({ length: totalPages }, (_, index) => index + 1).map(pageNumber => (
//             <button 
//               key={pageNumber}
//               className={`px-3 py-1 mx-1 rounded ${currentPage === pageNumber ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
//               onClick={() => handlePageChange(pageNumber)}
//             >
//               {pageNumber}
//             </button>
//           ))}
//         </div> */}
//       </div>
//       {/* Pagination Controls */}
// <div className="mt-6 relative flex items-center">
//   <div className="text-sm text-gray-600">
//     Mostrando {currentPage} de {totalPages} documentos
//   </div>

//   <div className="absolute left-1/2 transform -translate-x-1/2 flex items-center space-x-1">
//     <button
//       onClick={() => handlePageChange(currentPage - 1)}
//       disabled={currentPage === 1}
//       className="p-2 border rounded hover:bg-gray-100 disabled:opacity-50"
//     >
//       <ChevronLeft size={18} />
//     </button>

//     {totalPages <= 5 ? (
//       [...Array(totalPages)].map((_, index) => {
//         const page = index + 1;
//         return (
//           <button
//             key={page}
//             onClick={() => handlePageChange(page)}
//             className={`w-9 h-9 text-sm border rounded ${page === currentPage ? 'bg-cyan-500 text-white' : 'hover:bg-gray-100'}`}
//           >
//             {page}
//           </button>
//         );
//       })
//     ) : (
//       <>
//         {[...Array(Math.min(3, totalPages))].map((_, index) => {
//           const page = index + 1;
//           return (
//             <button
//               key={page}
//               onClick={() => handlePageChange(page)}
//               className={`w-9 h-9 text-sm border rounded ${page === currentPage ? 'bg-cyan-500 text-white' : 'hover:bg-gray-100'}`}
//             >
//               {page}
//             </button>
//           );
//         })}
//         {currentPage > 3 && <span className="px-1">...</span>}
//         {currentPage > 3 && currentPage < totalPages - 1 && (
//           <button
//             onClick={() => handlePageChange(currentPage)}
//             className="w-9 h-9 text-sm border rounded bg-cyan-500 text-white"
//           >
//             {currentPage}
//           </button>
//         )}
//         {currentPage < totalPages - 1 && <span className="px-1">...</span>}
//         {currentPage < totalPages && (
//           <button
//             onClick={() => handlePageChange(totalPages)}
//             className={`w-9 h-9 text-sm border rounded ${totalPages === currentPage ? 'bg-cyan-500 text-white' : 'hover:bg-gray-100'}`}
//           >
//             {totalPages}
//           </button>
//         )}
//       </>
//     )}

//     <button
//       onClick={() => handlePageChange(currentPage + 1)}
//       disabled={currentPage === totalPages}
//       className="p-2 border rounded hover:bg-gray-100 disabled:opacity-50"
//     >
//       <ChevronRight size={18} />
//     </button>
//   </div>
// </div>

//       </>
//       ): (
//         <p></p>
//       )}
// {/* Edit Modal */}
// {isEditModalOpen && currentDocument && (
//   <Dialog width="auto" height='auto' className='overflow-y-auto' isOpen={isEditModalOpen} onClose={() => { 
//     setIsEditModalOpen(false); 
//     setLabelMessage(''); 
//   }}>
//     <DialogContent>
//       <label className="text-md font-medium overflow-y-auto mb-1">Editar Documento</label>
//       <form onSubmit={(e) => handleEdit(e)}>
//         <div className="bg-white rounded-lg p-4">
//           {/* Barra de acciones superior */}
//           <div className='mb-4 items-end w-full flex'>
//             <button 
//               className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
//               onClick={() => {
//                 setIsEditModalOpen(false);
//                 setLabelMessage('');
//                 setIsSubVersion(0);
//               }}
//             >
//               <Undo className='inline-block mr-2' />
//               Cancelar
//             </button>

//             {/* <button 
//               className="bg-gray-500 ml-3 text-white px-4 py-2 rounded hover:bg-gray-600"
//               onClick={() => resetForm()}
//             >
//               <TrashIcon className='inline-block mr-2' />
//               Limpiar
//             </button> */}
//           </div>

//           {/* Primera fila - campos principales */}
//           <div className="flex flex-wrap gap-2 w-full mb-4">
//             <div className="flex w-full gap-2">
//               {/* Selector de proceso */}
//               <div className="min-w-[120px]">
//                 <label className="block text-sm font-medium mb-1">Proceso</label>
//                 <div className="flex items-center space-x-2">
//                   <input
//                     className={`border rounded px-2 py-1 h-[34px] text-sm text-center ${selectedProcesoId !== originalProcesoId ? 'border-blue-500 bg-blue-50' : ''}`}
//                     type="text"
//                     value={selectedProcesoId || ''}
//                     readOnly
//                   />
//                   <button
//                     type="button"
//                     className="bg-teal-500 hover:bg-teal-700 text-white h-[34px]"
//                     onClick={() => setIsProcessModalOpen(true)}
//                   >
//                     Cambiar proceso
//                   </button>
//                 </div>
//                 <div className="min-w-[120px]">
//               {selectedProcesoId !== originalProcesoId && (
//                 <p className="text-xs text-blue-600">
//                   Al cambiar el proceso se creará una nueva entrada
//                 </p>
//               )}
//             </div>
//               </div>

//               {/* Código documento */}
//               <div className="min-w-[120px]">
//                 <label className="block text-sm font-medium mb-1">Documento</label>
//                 <input
//                   className="border rounded px-2 py-1 text-sm text-center h-[34px] w-full"
//                   type="text"
//                   value={codigo}
//                   readOnly
//                 />
//               </div>

//               {/* Niveles en selectores */}
//               {niveles.slice(0, 3).map(nivel => (
//                 <div key={nivel.nivel_id} className="min-w-[120px]">
//                   <label htmlFor={`select-${nivel.nivel_id}`} className="block text-sm font-medium mb-1">
//                     {nivel.titulo}
//                   </label>
//                   <select
//                     id={`select-${nivel.nivel_id}`}
//                     value={modalValues[nivel.nivel_id] || ''}
//                     onChange={(e) => handleModalValueChange(nivel.nivel_id, e.target.value)}
//                     className="border rounded px-2 py-1 text-sm text-center h-[34px] w-full"
//                   >
//                     <option value="">Seleccione</option>
//                     {getOptionsForNivel(nivel.nivel_id)}
//                   </select>
//                 </div>
//               ))}

//               {/* Correlativo */}
//               <div className="w-[100px]">
//                 <label className="block text-sm font-medium mb-1">Correlativo</label>
//                 <input
//                   className="border rounded px-2 py-1 text-sm text-center h-[34px] w-full"
//                   type="text"
//                   value={correlativo}
//                   onChange={(e) => {
//                     const newCorrelativo = e.target.value.replace(/\D/g, "").slice(0, 3);
//                     setCorrelativo(newCorrelativo);
//                     updateCodigoFromCorrelativo(newCorrelativo);
//                   }}
//                 />
//               </div>
//             </div>
//           </div>

//           {/* Segunda fila - campos adicionales */}
//           <div className="flex flex-wrap gap-2 w-full">
//             <div className="flex w-full gap-2">
//               {/* Usuario emisor */}
//               <div className="min-w-[150px]">
//                 <label className="block text-sm font-medium mb-1">Emitido por*</label>
//                 <input
//                   className="border rounded px-2 py-1 text-sm text-center h-[34px] w-full cursor-not-allowed"
//                   type="text"
//                   value={username || ''}
//                   readOnly
//                 />
//               </div>

//               {/* Título del documento */}
//               <div className="flex-1 min-w-[200px]">
//                 <label className="block text-sm font-medium mb-1">Título del documento*</label>
//                 <textarea
//                   id="titulo-textarea"
//                   className="border rounded px-2 py-1 text-sm text-center resize-none h-[34px] w-full"
//                   value={tituloDocumento}
//                   onChange={(e) => setTituloDocumento(e.target.value.toUpperCase())}
//                   style={{ overflow: 'hidden', minHeight: '34px' }}
//                   required
//                 />
//               </div>

//               {/* Revisión */}
//               <div className="w-[100px]">
//                 <label className="block text-sm font-medium mb-1">Revisión</label>
//                 <input
//                   className={`border rounded px-2 py-1 text-sm text-center h-[34px] w-full ${!isRevisionValid ? 'border-red-500' : ''}`}
//                   type="text"
//                   value={revision}
//                   onChange={(e) => {
//                     const input = e.target.value.toUpperCase();
//                     const hasLetters = /[A-Z]/.test(input);
//                     const hasNumbers = /\d/.test(input);
                    
//                     let newValue = input;
                    
//                     if (hasLetters) {
//                       newValue = input.replace(/[^A-Z]/g, "").slice(0, 1);
//                     }
                    
//                     if (hasNumbers) {
//                       newValue = input.replace(/[^0-9]/g, "");
//                     }
                    
//                     setRevision(newValue);
//                     validateRevision(newValue);
                    
//                     if (newValue !== originalRevision) {
//                       setIsSubVersion(1);
//                       setVersion(1);
//                     } else if (version === originalVersion) {
//                       setIsSubVersion(0);
//                     }
//                   }}
//                   onBlur={() => checkRevisionWithBackend(revision)}
//                 />
//               </div>

//               {/* Versión */}
//               <div className="w-[100px]">
//                 <label className="block text-sm font-medium mb-1">Versión</label>
//                 <input
//                   className={`border rounded px-2 py-1 text-sm text-center h-[34px] w-full ${!isVersionValid ? 'border-red-500' : ''}`}
//                   type="number"
//                   min="1"
//                   value={version}
//                   onChange={(e) => {
//                     const newValue = parseInt(e.target.value);
//                     setVersion(newValue);
//                     validateVersion(newValue);
                    
//                     if (newValue !== originalVersion) {
//                       setIsSubVersion(1);
//                     } else {
//                       setIsSubVersion(0);
//                     }
//                   }}
//                   onBlur={archivos.length === 0 ? () => checkRevisionWithBackend(revision) : undefined}
//                   disabled={archivos.length > 0}
//                 />
//               </div>

//               {/* Comentarios */}
//               <div className="flex-1 min-w-[200px]">
//                 <label className="block text-sm font-medium mb-1">Comentarios</label>
//                 <textarea
//                   id="comentario-textarea"
//                   className="border rounded px-2 py-1 text-sm text-center resize-none h-[34px] w-full"
//                   value={comentarios}
//                   onChange={(e) => setComentarios(e.target.value.toUpperCase())}
//                   style={{ overflow: 'hidden', minHeight: '34px' }}
//                 />
//               </div>
//             </div>
//           </div>
          
//           {/* Notas informativas */}
//           <div className="flex w-full gap-2 mt-1">
           
//             <div className="min-w-[120px]"></div>
//             <div className="min-w-[120px]"></div>
//             <div className="min-w-[120px]"></div>
//             <div className="min-w-[120px]"></div>
//             <div className="w-[100px]">
//               {revision !== originalRevision && (
//                 <p className="text-xs text-gray-500">
//                   Al cambiar la revisión se creará una nueva entrada
//                 </p>
//               )}
//             </div>
//             <div className="w-[100px]">
//               {archivos.length > 0 ? (
//                 <p className="text-xs text-gray-500">
//                   Versión aumentada automáticamente
//                 </p>
//               ) : version !== originalVersion ? (
//                 <p className="text-xs text-gray-500">
//                   Al cambiar la versión se creará una nueva entrada
//                 </p>
//               ) : null}
//             </div>
//           </div>
          
//           {/* Mensajes de validación */}
//           {labelMessage && (
//             <div 
//               className={`mt-5 p-4 inline-block rounded mb-4 ${
//                 !isRevisionValid || !isVersionValid
//                   ? 'bg-red-200 text-red-800'
//                   : existsRevision
//                     ? 'bg-yellow-200 text-yellow-800'
//                     : 'bg-green-200 text-green-800'
//               }`}
//             >
//               {labelMessage}
//             </div>
//           )}
          
//           {/* Dropzone para subir archivo */}
//           <div className="mt-10 w-full">
//             <Dropzone
//               label="Agregar documento (creará una nueva versión)"
//               files={archivos}
//               onFilesChange={(files) => {
//                 setArchivos(files);
//                 if (files.length > 0) {
//                   if (revision !== originalRevision) {
//                     setVersion(1);
//                   } else {
//                     setVersion(prevVersion => parseInt(String(prevVersion)) + 1);
//                   }
//                   setIsSubVersion(1);
//                 }
//               }}
//               onRemoveFile={(fileName, type) => {
//                 if (type === 'principal') {
//                   setArchivos([]);
//                   if (currentDocument) {
//                     setVersion(currentDocument.version);
//                     setIsSubVersion(0);
//                   }
//                 }
//               }}
//               type="principal"
//             /> 
//             {archivos.length > 0 && (
//               <p className="text-xs text-gray-500 mt-1">
//                 Al subir un nuevo archivo se creará una nueva entrada con subversión activada
//               </p>
//             )}
//           </div>
          
//           {/* Botones de acción */}
//           <div className="flex justify-center mt-4 space-x-2">
//             <button 
//               onClick={() => {
//                 setIsEditModalOpen(false);
//                 setLabelMessage('');
//                 setIsSubVersion(0);
//               }}
//             >
//               Cancelar
//             </button>
//             <button 
//               className="bg-teal-500 hover:bg-teal-900 text-white" 
//               type="submit"
//               disabled={!isRevisionValid || !isVersionValid}
//             >
//               <PlusCircle className='inline-block mr-2' />
//               Guardar
//             </button>
//           </div>
//         </div>
//       </form>
//     </DialogContent>
//   </Dialog>
// )}


// {/* Modal para seleccionar proceso */}
// {/* Añadir esto justo antes del modal de edición */}
// <ProcessSelectionModal
//   isOpen={isProcessModalOpen}
//   onClose={() => setIsProcessModalOpen(false)}
//   onSelectProcess={handleProcessSelect}
//   procesos={procesos}
//   currentProcesoId={selectedProcesoId}
// />
//     </div>
    
//   );
  
// };

// export default DocumentSearch;


return (
  <div className="p-4 bg-gray-50">
    <h1 className="text-2xl font-bold mb-4 text-cyan-600">Búsqueda de documentos</h1>
    
    <div className="mb-4 overflow-x-auto flex space-x-3">
      <button 
        onClick={downloadSelectedDocuments}
        disabled={selectedDocuments.length === 0}
        className={`px-4 py-2 text-white rounded-md shadow transition-colors duration-200 flex items-center ${
          selectedDocuments.length > 0
            ? 'bg-cyan-500 hover:bg-cyan-600 cursor-pointer'
            : 'bg-gray-400 cursor-not-allowed'
        }`}
      >
        <Download className="inline-block mr-2 h-5 w-5" />
        <span className="font-medium">Descargar Documentos Seleccionados</span>
      </button>
     
      <button 
        onClick={transmittalSelectedDocuments}
        disabled={selectedDocuments.length === 0}
        className={`px-4 py-2 text-white rounded-md shadow transition-colors duration-200 flex items-center ${
          selectedDocuments.length > 0
            ? 'bg-teal-500 hover:bg-teal-600 cursor-pointer'
            : 'bg-gray-400 cursor-not-allowed'
        }`}
      >
        <Mail className="inline-block mr-2 h-5 w-5" />
        <span className="font-medium">Generar Transmittal</span>
      </button>
    </div>
   
    <div className="bg-white p-5 rounded-lg shadow-md mb-6">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
<select
  id="projectFilter"
  className="p-2 border border-gray-300 rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-cyan-500 text-gray-700 bg-white"
  value={projectFilter}
  onChange={(e) => setProjectFilter(e.target.value)}
  aria-label="Filtro de Procesos"
>

  <option value="">Seleccionar proceso</option>
  {(() => {
    // Create a map to store unique projects
    const uniqueProjects = new Map();

    // Iterate through project display options
    projectDisplayOptions.forEach(project => {
      // Use the project's value (normalized name) as the key
      const key = project.value;

    //  alert(JSON.stringify(key))
      // If this project isn't in the map, or the new one has a more detailed display
      if (!uniqueProjects.has(key) || 
          (project.display.includes('-') && !uniqueProjects.get(key).display.includes('-'))) {
        uniqueProjects.set(key, project);
      }
    });

    
    // Convert the map to sorted array
    return Array.from(uniqueProjects.values())
      .sort((a, b) => {
        // Prioritize sorting by project code if available
        const codeA = a.display.split('-')[0]?.trim() || '';
        const codeB = b.display.split('-')[0]?.trim() || '';
        return codeA.localeCompare(codeB);
      })
      .map(project => (
        <option key={project.value} value={project.value}>
          {project.display}
        </option>
      ));
  })()}
</select>

        <input 
          className="border p-2 rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-cyan-500" 
          placeholder="Filtro por Título" 
          value={titleFilter}
          onChange={(e) => setTitleFilter(e.target.value)}
        />
        <input 
          className="border p-2 rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-cyan-500" 
          placeholder="Comentario" 
          value={commentFilter}
          onChange={(e) => setCommentFilter(e.target.value)}
        />
       
        {niveles.slice(0, 3).map(nivel => (
          <div key={nivel.nivel_id}>
            <label htmlFor={`select-${nivel.nivel_id}`} className="block text-sm font-medium mb-1 text-gray-700">
              {nivel.titulo}
            </label>
            <select
              id={`select-${nivel.nivel_id}`}
              value={selectedValues[nivel.nivel_id] || ''}
              onChange={(e) => handleSelectChange(nivel.nivel_id, e.target.value)}
              className="p-2 border border-gray-300 rounded w-full shadow-sm focus:outline-none focus:ring-2 focus:ring-cyan-500"
            >
              <option value="">Seleccione {nivel.titulo}</option>
              {getOptionsForNivel(nivel.nivel_id)}
            </select>
          </div>
        ))}
      </div>
      <button 
  onClick={resetFilters}
  className="bg-gray-200 hover:bg-gray-300 text-gray-800 px-3 py-2 rounded-md flex items-center"
>
  <Undo className="mr-2 h-4 w-4" />
  Resetear Filtros
</button>
    </div>

    <TransmittalModal
      isOpen={isTransmittalModalOpen}
      onClose={handleCloseTransmittalModal}
      onSubmit={handleSubmitTransmittal}
      documents={checkedDocuments}
    />

{filteredDocuments.length > 0 ?  (
      <>
        <div className="mb-4 flex items-center justify-between bg-white p-3 rounded-lg shadow-md">
          <div className="flex items-center space-x-2">
             <h2 className="text-xl font-semibold text-cyan-700">
              {projectDisplayOptions.find(p => p.value === projectFilter)?.display?.split('-')[0]?.trim()}
            </h2>
            <h2 className="text-xl font-semibold text-cyan-700">
              {projectDisplayOptions.find(p => p.value === projectFilter)?.display?.split('-')[1]?.trim()}
            </h2>
            <span className="text-sm text-teal-600 font-medium">
              {projectDisplayOptions.find(p => p.value === projectFilter)?.display?.split('-')[2]?.trim()}
            </span>
          </div>
          
          <div className="flex items-center space-x-2">
            <span className="mr-2 text-gray-700">Mostrar</span>
            <select 
              className="border p-2 rounded mr-4 focus:outline-none focus:ring-2 focus:ring-cyan-500"
              value={documentsPerPage}
              onChange={handleRecordsPerPageChange}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={250}>250</option>
              <option value={500}>500</option>
            </select>
            <span className="text-gray-700">registros por página</span>
          </div>
        </div>
        <div className="overflow-hidden rounded-lg shadow-md">
          <table className="min-w-full border-collapse bg-white">
            <thead className="bg-cyan-600 text-white sticky top-0"> 
              <tr>
                <th className="p-2 text-left">
                  <input
                    type="checkbox"
                    checked={isAllSelected}
                    onChange={toggleSelectAll}
                    className="rounded text-cyan-500 focus:ring-cyan-400"
                  />
                </th>
                <th className="p-2 text-left"   onClick={resetSort}
                >#</th>
                <th className="p-2 text-center"></th>
                <th className="p-2 text-center"></th>
                {/* <th className="p-2 text-left cursor-pointer hover:bg-cyan-700" onClick={() => handleSort('project')}>
                  Proceso {sortColumn === 'project' && (sortDirection === 'asc' ? '▲' : '▼')}
                </th> */}
                <th className="p-2 text-left cursor-pointer hover:bg-cyan-700" onClick={() => handleSort('document')}>
                  Documento {sortColumn === 'document' && (sortDirection === 'asc' ? '▲' : '▼')}
                </th>
                <th className="p-2 text-left cursor-pointer hover:bg-cyan-700" onClick={() => handleSort('date')}>
                  Fecha {sortColumn === 'date' && (sortDirection === 'asc' ? '▲' : '▼')}
                </th>
                <th className="p-2 text-left cursor-pointer hover:bg-cyan-700" onClick={() => handleSort('issuedBy')}>
                  Emisor {sortColumn === 'issuedBy' && (sortDirection === 'asc' ? '▲' : '▼')}
                </th>
                <th className="p-2 text-left cursor-pointer hover:bg-cyan-700" onClick={() => handleSort('comment')}>
                  Comentario {sortColumn === 'comment' && (sortDirection === 'asc' ? '▲' : '▼')}
                </th>
                <th className="p-2 text-center">Acciones</th>
              </tr>
            </thead>
            <tbody>
            {filteredDocuments.map((doc, index) => (
  <React.Fragment key={doc.id}>
    <tr className={`${getRowClassName(doc.id)} hover:bg-cyan-50 transition-colors`}>
      <td className="p-2 border-b border-gray-200">
        <input 
          type="checkbox" 
          checked={selectedDocuments.includes(doc.id)}
          onChange={() => toggleDocumentSelection(doc.id)}
          className="rounded text-cyan-500 focus:ring-cyan-400"
        />
      </td>
      <td className="p-2 border-b border-gray-200 text-center text-gray-600">
      <td>{index + 1}</td>
      </td>
      <td className="p-2 border-b border-gray-200">
        {doc.subVersions && doc.subVersions.length > 0 && (
          <button 
            onClick={() => toggleRow(doc.id)}
            className="w-6 h-6 flex items-center justify-center rounded text-cyan-600 hover:text-cyan-800"
          >
            {expandedRows.includes(doc.id) ? <MinusCircle size={18} /> : <PlusCircle size={18} />}
          </button>
        )}              
      </td>
      <td className="p-2 border-b border-gray-200">
        {doc.flagged && <Flag className="text-red-500" />}
      </td>
      {/* <td className="p-2 border-b border-gray-200 font-medium text-gray-700 whitespace-nowrap">
        {(() => {
          let projectName = doc.projectName || doc.project || '';
          let comuna = doc.comuna || '';
          
          // Intentar obtener comuna del código del proyecto si existe
          if (!comuna && doc.document) {
            // Buscar el proceso correspondiente por el código
            const projectCode = doc.document.split('-')[0];
            const matchingProcess = procesos.find(proceso => proceso.codigo === projectCode);
            if (matchingProcess && matchingProcess.comuna) {
              comuna = matchingProcess.comuna;
            }
          }
          
          // Quitar la comuna del nombre del proyecto si está al final
          if (comuna && projectName.endsWith(comuna)) {
            projectName = projectName.substring(0, projectName.length - comuna.length).trim();
          }
          
          // Si la comuna está entre paréntesis al final
          const comunaParenMatch = projectName.match(/(.*?)\s*\(\s*([^)]+)\s*\)\s*$/);
          if (comunaParenMatch) {
            projectName = comunaParenMatch[1].trim();
            if (!comuna) comuna = comunaParenMatch[2].trim();
          }
          
          // Si la comuna está entre corchetes al final
          const comunaBracketMatch = projectName.match(/(.*?)\s*\[\s*([^\]]+)\s*\]\s*$/);
          if (comunaBracketMatch) {
            projectName = comunaBracketMatch[1].trim();
            if (!comuna) comuna = comunaBracketMatch[2].trim();
          }
          
          return (
            <div className="flex items-center space-x-2">
              <span>{projectName}</span>
              {comuna && (
                <span className="px-2 py-0.5 bg-teal-100 text-teal-800 text-xs rounded-full border border-teal-200 whitespace-nowrap">
                  {comuna}
                </span>
              )}
            </div>
          );
        })()}
      </td> */}
      <td className="p-2 border-b border-gray-200 text-left text-gray-700 whitespace-nowrap overflow-hidden">
        <div className="flex items-center">
          <span className="font-medium text-gray-700">{doc.document} {doc.title}</span>
          <span className="ml-2 px-2 py-0.5 bg-cyan-100 text-cyan-800 text-xs rounded-md border border-cyan-200 whitespace-nowrap">
            REV{doc.revision} V{doc.version}
          </span>
        </div>
      </td>
      <td className="p-2 border-b border-gray-200 text-gray-600 whitespace-nowrap">{doc.date}</td>
      <td className="p-2 border-b border-gray-200 text-gray-600 whitespace-nowrap">{doc.issuedBy}</td>
      <td className="p-2 border-b border-gray-200 text-center">
        {doc.comment ? (
          <div className="relative group inline-block">
            <MessageCircle size={18} className="text-gray-500 group-hover:text-cyan-600 transition-colors" />
            <div className="absolute z-10 invisible group-hover:visible bg-gray-800 text-white p-2 rounded shadow-lg text-xs max-w-xs -translate-x-1/2 left-1/2 mt-1 whitespace-normal">
              {doc.comment}
            </div>
          </div>
        ) : (
          <span className="text-gray-300">—</span>
        )}
      </td>
      <td className="p-2 border-b border-gray-200">
        <div className="flex justify-center space-x-2">
          <button 
            className="text-blue-600 hover:text-blue-800 transition-colors" 
            onClick={() => viewFile(doc.path, doc.itemType)}
            title="Ver documento"
          >
            <Eye size={18} />
          </button>
          <button 
            className={`${doc.itemType === 'document' ? 'text-yellow-600 hover:text-yellow-800' : 'text-gray-400'} transition-colors`} 
            disabled={doc.itemType === 'transmittal'} 
            onClick={() => openEditModal(doc)}
            title="Editar documento"
          >  
            <Edit size={18} />
          </button>
          <button 
            onClick={() => openDeleteModal(doc)}   
            disabled={doc.itemType === 'transmittal'} 
            className={`${doc.itemType === 'document' ? 'text-red-600 hover:text-red-800' : 'text-gray-400'} transition-colors`}
            title="Eliminar documento"
          >
            <Trash size={18} />
          </button>
        </div>
      </td>
    </tr>
           
    {doc.subVersions && expandedRows.includes(doc.id) && 
      doc.subVersions.map((subDoc, subIndex) => (
        <tr key={subDoc.id}>
            <td className="p-2 border-b border-gray-200">
              <input 
                type="checkbox" 
                checked={selectedDocuments.includes(subDoc.id)}
                onChange={() => toggleDocumentSelection(subDoc.id)}
                className="rounded text-cyan-500 focus:ring-cyan-400"
              />
            </td>
            <td>{`${index + 1}.${subIndex + 1}`}</td>

            <td className="p-2 border-b border-gray-200">
              {subDoc.flagged && <Flag className="text-red-500" />}
            </td>
            <td className="p-2 border-b border-gray-200"></td>
            <td className="p-2 border-b border-gray-200 text-left pl-8 text-gray-700 whitespace-nowrap overflow-hidden">
              <div className="flex items-center">
                <span className="font-medium text-gray-700">{subDoc.document} {subDoc.title}</span>
                <span className="ml-2 px-2 py-0.5 bg-cyan-100 text-cyan-800 text-xs rounded-md border border-cyan-200 whitespace-nowrap">
                  REV{subDoc.revision} V{subDoc.version}
                </span>
              </div>
            </td>
            <td className="p-2 border-b border-gray-200 text-gray-600 whitespace-nowrap">{subDoc.date}</td>
            <td className="p-2 border-b border-gray-200 text-gray-600 whitespace-nowrap">{subDoc.issuedBy}</td>
            <td className="p-2 border-b border-gray-200 text-center">
              {subDoc.comment ? (
                <div className="relative group inline-block">
                  <MessageCircle size={18} className="text-gray-500 group-hover:text-cyan-600 transition-colors" />
                  <div className="absolute z-10 invisible group-hover:visible bg-gray-800 text-white p-2 rounded shadow-lg text-xs max-w-xs -translate-x-1/2 left-1/2 mt-1 whitespace-normal">
                    {subDoc.comment}
                  </div>
                </div>
              ) : (
                <span className="text-gray-300">—</span>
              )}
            </td>
            <td className="p-2 border-b border-gray-200">
              <div className="flex justify-center space-x-2">
                <button 
                  className="text-blue-600 hover:text-blue-800 transition-colors" 
                  onClick={() => viewFile(subDoc.path, subDoc.itemType)}
                  title="Ver documento"
                >
                  <Eye size={18} />
                </button>
                <button 
                  className="text-red-600 hover:text-red-800 transition-colors" 
                  onClick={(e) => {
                    e.stopPropagation();
                    openSubdocumentDeleteModal(doc, subDoc);
                  }}
                  title="Eliminar subdocumento"
                >
                  <Trash size={18} />
                </button>
              </div>
            </td> 
          </tr>
    ))}
  </React.Fragment>
))}

              {/* {currentDocuments.map((doc, index) => (
                <React.Fragment key={doc.id}>
                  <tr className={`${getRowClassName(doc.id)} hover:bg-cyan-50 transition-colors`}>
                    <td className="p-2 border-b border-gray-200">
                      <input 
                        type="checkbox" 
                        checked={selectedDocuments.includes(doc.id)}
                        onChange={() => toggleDocumentSelection(doc.id)}
                        className="rounded text-cyan-500 focus:ring-cyan-400"
                      />
                    </td>
                    <td className="p-2 border-b border-gray-200 text-center text-gray-600">
                      {indexOfFirstDocument + index + 1}
                    </td>
                    <td className="p-2 border-b border-gray-200">
                      {doc.subVersions && doc.subVersions.length > 0 && (
                        <button 
                          onClick={() => toggleRow(doc.id)}
                          className="w-6 h-6 flex items-center justify-center rounded text-cyan-600 hover:text-cyan-800"
                        >
                          {expandedRows.includes(doc.id) ? <MinusCircle size={18} /> : <PlusCircle size={18} />}
                        </button>
                      )}              
                    </td>
                    <td className="p-2 border-b border-gray-200">
                      {doc.flagged && <Flag className="text-red-500" />}
                    </td>
                    <td className="p-2 border-b border-gray-200 font-medium text-gray-700">{doc.projectName}</td>
                    <td className="p-2 border-b border-gray-200 text-left text-gray-700">
                      {doc.document} {doc.title} &nbsp;
                      <span className="text-cyan-700 font-medium">{`REV${doc.revision} V${doc.version}`}</span>
                    </td>
                    <td className="p-2 border-b border-gray-200 text-gray-600">{doc.date}</td>
                    <td className="p-2 border-b border-gray-200 text-gray-600">{doc.issuedBy}</td>
                    <td className="p-2 border-b border-gray-200 text-gray-600">
                      {doc.comment.length > 30 ? `${doc.comment.substring(0, 30)}...` : doc.comment}
                    </td>
                    <td className="p-2 border-b border-gray-200">
                      <div className="flex justify-center space-x-2">
                        <button 
                          className="text-blue-600 hover:text-blue-800 transition-colors" 
                          onClick={() => viewFile(doc.path, doc.itemType)}
                          title="Ver documento"
                        >
                          <Eye size={18} />
                        </button>
                        <button 
                          className={`${doc.itemType === 'document' ? 'text-yellow-600 hover:text-yellow-800' : 'text-gray-400'} transition-colors`} 
                          disabled={doc.itemType === 'transmittal'} 
                          onClick={() => openEditModal(doc)}
                          title="Editar documento"
                        >  
                          <Edit size={18} />
                        </button>
                        <button 
                          onClick={() => openDeleteModal(doc)}   
                          disabled={doc.itemType === 'transmittal'} 
                          className={`${doc.itemType === 'document' ? 'text-red-600 hover:text-red-800' : 'text-gray-400'} transition-colors`}
                          title="Eliminar documento"
                        >
                          <Trash size={18} />
                        </button>
                      </div>
                    </td>
                  </tr>
                         
                  {doc.subVersions && expandedRows.includes(doc.id) && 
                    [...doc.subVersions]
                    .filter(subDoc => subDoc.id !== doc.id)
                    .sort((b, a) => {
                        const dateA = new Date(a.date).getTime();
                        const dateB = new Date(b.date).getTime();
                        return dateA - dateB;
                      })
                      .map((subDoc, subIndex) => (
                        <tr key={subDoc.id} className="bg-cyan-50 hover:bg-cyan-100 transition-colors">
                          <td className="p-2 border-b border-gray-200">
                            <input 
                              type="checkbox" 
                              checked={selectedDocuments.includes(subDoc.id)}
                              onChange={() => toggleDocumentSelection(subDoc.id)}
                              className="rounded text-cyan-500 focus:ring-cyan-400"
                            />
                          </td>
                          <td className="p-2 border-b border-gray-200 text-center text-gray-600">
                            {`${indexOfFirstDocument + index + 1}.${subIndex + 1}`}
                          </td>
                          <td className="p-2 border-b border-gray-200"></td>
                          <td className="p-2 border-b border-gray-200">
                            {subDoc.flagged && <Flag className="text-red-500" />}
                          </td>
                          <td className="p-2 border-b border-gray-200"></td>
                          <td className="p-2 border-b border-gray-200 text-left pl-8 text-gray-700">
                            {subDoc.document} {subDoc.title} 
                            <span className="text-cyan-700 font-medium"> REV{subDoc.revision} V{subDoc.version}</span>
                          </td>
                          <td className="p-2 border-b border-gray-200 text-gray-600">{subDoc.date}</td>
                          <td className="p-2 border-b border-gray-200 text-gray-600">{subDoc.issuedBy}</td>
                          <td className="p-2 border-b border-gray-200 text-gray-600">
                            {subDoc.comment.length > 30 ? `${subDoc.comment.substring(0, 30)}...` : subDoc.comment}
                          </td>
                          <td className="p-2 border-b border-gray-200">
                            <div className="flex justify-center space-x-2">
                              <button 
                                className="text-blue-600 hover:text-blue-800 transition-colors" 
                                onClick={() => viewFile(subDoc.path, subDoc.itemType)}
                                title="Ver documento"
                              >
                                <Eye size={18} />
                              </button>
                              <button 
                                className="text-red-600 hover:text-red-800 transition-colors" 
                                onClick={(e) => {
                                  e.stopPropagation();
                                  openSubdocumentDeleteModal(doc, subDoc);
                                }}
                                title="Eliminar subdocumento"
                              >
                                <Trash size={18} />
                              </button>
                            </div>
                          </td> 
                        </tr>
                  ))}
                </React.Fragment>
              ))} */}
            </tbody>
          </table>
        </div>

        {/* Pagination Controls */}
        <div className="mt-6 relative flex items-center justify-between bg-white p-3 rounded-lg shadow-md">
          <div className="text-sm text-gray-600">
            Mostrando {currentPage} de {totalPages} páginas
          </div>

          <div className="flex items-center space-x-1">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="p-2 border rounded hover:bg-gray-100 disabled:opacity-50 focus:outline-none focus:ring-2 focus:ring-cyan-500"
            >
              <ChevronLeft size={18} />
            </button>

            {totalPages <= 5 ? (
              [...Array(totalPages)].map((_, index) => {
                const page = index + 1;
                return (
                  <button
                    key={page}
                    onClick={() => handlePageChange(page)}
                    className={`w-9 h-9 text-sm border rounded focus:outline-none focus:ring-2 focus:ring-cyan-500 ${page === currentPage ? 'bg-cyan-500 text-white' : 'hover:bg-gray-100 text-gray-700'}`}
                  >
                    {page}
                  </button>
                );
              })
            ) : (
              <>
                {[...Array(Math.min(3, totalPages))].map((_, index) => {
                  const page = index + 1;
                  return (
                    <button
                      key={page}
                      onClick={() => handlePageChange(page)}
                      className={`w-9 h-9 text-sm border rounded focus:outline-none focus:ring-2 focus:ring-cyan-500 ${page === currentPage ? 'bg-cyan-500 text-white' : 'hover:bg-gray-100 text-gray-700'}`}
                    >
                      {page}
                    </button>
                  );
                })}
                {currentPage > 3 && <span className="px-1">...</span>}
                {currentPage > 3 && currentPage < totalPages - 1 && (
                  <button
                    onClick={() => handlePageChange(currentPage)}
                    className="w-9 h-9 text-sm border rounded bg-cyan-500 text-white focus:outline-none focus:ring-2 focus:ring-cyan-500"
                  >
                    {currentPage}
                  </button>
                )}
                {currentPage < totalPages - 1 && <span className="px-1">...</span>}
                {currentPage < totalPages && (
                  <button
                    onClick={() => handlePageChange(totalPages)}
                    className={`w-9 h-9 text-sm border rounded focus:outline-none focus:ring-2 focus:ring-cyan-500 ${totalPages === currentPage ? 'bg-cyan-500 text-white' : 'hover:bg-gray-100 text-gray-700'}`}
                  >
                    {totalPages}
                  </button>
                )}
              </>
            )}

            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="p-2 border rounded hover:bg-gray-100 disabled:opacity-50 focus:outline-none focus:ring-2 focus:ring-cyan-500"
            >
              <ChevronRight size={18} />
            </button>
          </div>
        </div>
      </>
    ): (
      <p className="text-center py-6 text-gray-500">No se encontraron documentos con los criterios de búsqueda actuales.</p>
    )}

    {/* Confirmation Modal */}
    <ConfirmationModal
      isOpen={isDeleteModalOpen}
      onClose={closeDeleteModal}
      onConfirm={handleConfirmDelete}
    />

    {/* Edit Modal */}
    {isEditModalOpen && currentDocument && (
      <Dialog width="auto" height='auto' className='overflow-y-auto' isOpen={isEditModalOpen} onClose={() => { 
        setIsEditModalOpen(false); 
        setLabelMessage(''); 
      }}>
        <DialogContent>
          <div className="mb-3 text-lg font-medium text-cyan-700 border-b pb-2">Editar Documento</div>
          <form onSubmit={(e) => handleEdit(e)}>
            <div className="bg-white rounded-lg p-4">
              {/* Barra de acciones superior */}
              <div className='mb-4 items-end w-full flex'>
                <button 
                  className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 transition-colors flex items-center"
                  onClick={() => {
                    setIsEditModalOpen(false);
                    setLabelMessage('');
                    setIsSubVersion(0);
                  }}
                  type="button"
                >
                  <Undo className='mr-2 h-4 w-4' />
                  Cancelar
                </button>
              </div>

              {/* Primera fila - campos principales */}
              <div className="flex flex-wrap gap-2 w-full mb-4">
                <div className="flex w-full gap-2">
                  {/* Selector de proceso */}
                  <div className="min-w-[120px]">
                    <label className="block text-sm font-medium mb-1 text-gray-700">Proceso</label>
                    <div className="flex items-center space-x-2">
                      <input
                        className={`border rounded px-2 py-1 h-[34px] text-sm text-center ${selectedProcesoId !== originalProcesoId ? 'border-blue-500 bg-blue-50' : ''} focus:ring-2 focus:ring-cyan-500 focus:outline-none`}
                        type="text"
                        value={selectedProcesoId || ''}
                        readOnly
                      />
                      <button
                        type="button"
                        className="bg-teal-500 hover:bg-teal-600 text-white h-[34px] px-2 rounded transition-colors"
                        onClick={() => setIsProcessModalOpen(true)}
                      >
                        Cambiar proceso
                      </button>
                    </div>
                    <div className="min-w-[120px]">
                      {selectedProcesoId !== originalProcesoId && (
                        <p className="text-xs text-blue-600">
                          Al cambiar el proceso se creará una nueva entrada
                        </p>
                      )}
                    </div>
                  </div>

                  {/* Código documento */}
                  <div className="min-w-[120px]">
                    <label className="block text-sm font-medium mb-1 text-gray-700">Documento</label>
                    <input
                      className="border rounded px-2 py-1 text-sm text-center h-[34px] w-full bg-gray-50 focus:ring-2 focus:ring-cyan-500 focus:outline-none"
                      type="text"
                      value={codigo}
                      readOnly
                    />
                  </div>

                  {/* Niveles en selectores */}
                  {niveles.slice(0, 3).map(nivel => (
                    <div key={nivel.nivel_id} className="min-w-[120px]">
                      <label htmlFor={`select-${nivel.nivel_id}`} className="block text-sm font-medium mb-1 text-gray-700">
                        {nivel.titulo}
                      </label>
                      <select
                        id={`select-${nivel.nivel_id}`}
                        value={modalValues[nivel.nivel_id] || ''}
                        onChange={(e) => handleModalValueChange(nivel.nivel_id, e.target.value)}
                        className="border rounded px-2 py-1 text-sm text-center h-[34px] w-full focus:ring-2 focus:ring-cyan-500 focus:outline-none"
                      >
                        <option value="">Seleccione</option>
                        {getOptionsForNivel(nivel.nivel_id)}
                      </select>
                    </div>
                  ))}

                  {/* Correlativo */}
                  <div className="w-[100px]">
                    <label className="block text-sm font-medium mb-1 text-gray-700">Correlativo</label>
                    <input
                      className="border rounded px-2 py-1 text-sm text-center h-[34px] w-full focus:ring-2 focus:ring-cyan-500 focus:outline-none"
                      type="text"
                      value={correlativo}
                      onChange={(e) => {
                        const newCorrelativo = e.target.value.replace(/\D/g, "").slice(0, 3);
                        setCorrelativo(newCorrelativo);
                        updateCodigoFromCorrelativo(newCorrelativo);
                      }}
                    />
                  </div>
                </div>
              </div>

              {/* Segunda fila - campos adicionales */}
              <div className="flex flex-wrap gap-2 w-full">
                <div className="flex w-full gap-2">
                  {/* Usuario emisor */}
                  <div className="min-w-[150px]">
                    <label className="block text-sm font-medium mb-1 text-gray-700">Emitido por*</label>
                    <input
                      className="border rounded px-2 py-1 text-sm text-center h-[34px] w-full cursor-not-allowed bg-gray-50 focus:ring-2 focus:ring-cyan-500 focus:outline-none"
                      type="text"
                      value={username || ''}
                      readOnly
                    />
                  </div>

                  {/* Título del documento */}
                  <div className="flex-1 min-w-[200px]">
                    <label className="block text-sm font-medium mb-1 text-gray-700">Título del documento*</label>
                    <textarea
                      id="titulo-textarea"
                      className="border rounded px-2 py-1 text-sm text-center resize-none h-[34px] w-full focus:ring-2 focus:ring-cyan-500 focus:outline-none"
                      value={tituloDocumento}
                      onChange={(e) => setTituloDocumento(e.target.value.toUpperCase())}
                      style={{ overflow: 'hidden', minHeight: '34px' }}
                      required
                    />
                  </div>

                  {/* Revisión */}
                  <div className="w-[100px]">
                    <label className="block text-sm font-medium mb-1 text-gray-700">Revisión</label>
                    <input
                      className={`border rounded px-2 py-1 text-sm text-center h-[34px] w-full ${!isRevisionValid ? 'border-red-500' : ''} focus:ring-2 focus:ring-cyan-500 focus:outline-none`}
                      type="text"
                      value={revision}
                      onChange={(e) => {
                        const input = e.target.value.toUpperCase();
                        const hasLetters = /[A-Z]/.test(input);
                        const hasNumbers = /\d/.test(input);
                        
                        let newValue = input;
                        
                        if (hasLetters) {
                          newValue = input.replace(/[^A-Z]/g, "").slice(0, 1);
                        }
                        
                        if (hasNumbers) {
                          newValue = input.replace(/[^0-9]/g, "");
                        }
                        
                        setRevision(newValue);
                        validateRevision(newValue);
                        
                        if (newValue !== originalRevision) {
                          setIsSubVersion(1);
                          setVersion(1);
                        } else if (version === originalVersion) {
                          setIsSubVersion(0);
                        }
                      }}
                      onBlur={() => checkRevisionWithBackend(revision)}
                    />
                  </div>

                  {/* Versión */}
                  <div className="w-[100px]">
                    <label className="block text-sm font-medium mb-1 text-gray-700">Versión</label>
                    <input
                      className={`border rounded px-2 py-1 text-sm text-center h-[34px] w-full ${!isVersionValid ? 'border-red-500' : ''} focus:ring-2 focus:ring-cyan-500 focus:outline-none`}
                      type="number"
                      min="1"
                      value={version}
                      onChange={(e) => {
                        const newValue = parseInt(e.target.value);
                        setVersion(newValue);
                        validateVersion(newValue);
                        
                        if (newValue !== originalVersion) {
                          setIsSubVersion(1);
                        } else {
                          setIsSubVersion(0);
                        }
                      }}
                      onBlur={archivos.length === 0 ? () => checkRevisionWithBackend(revision) : undefined}
                      disabled={archivos.length > 0}
                    />
                  </div>

                  {/* Comentarios */}
                  <div className="flex-1 min-w-[200px]">
                    <label className="block text-sm font-medium mb-1 text-gray-700">Comentarios</label>
                    <textarea
                      id="comentario-textarea"
                      className="border rounded px-2 py-1 text-sm text-center resize-none h-[34px] w-full focus:ring-2 focus:ring-cyan-500 focus:outline-none"
                      value={comentarios}
                      onChange={(e) => setComentarios(e.target.value.toUpperCase())}
                      style={{ overflow: 'hidden', minHeight: '34px' }}
                    />
                  </div>
                </div>
              </div>
              
              {/* Notas informativas */}
              <div className="flex w-full gap-2 mt-1">
                <div className="min-w-[120px]"></div>
                <div className="min-w-[120px]"></div>
                <div className="min-w-[120px]"></div>
                <div className="min-w-[120px]"></div>
                <div className="w-[100px]">
                  {revision !== originalRevision && (
                    <p className="text-xs text-gray-500">
                      Al cambiar la revisión se creará una nueva entrada
                    </p>
                  )}
                </div>
                <div className="w-[100px]">
                  {archivos.length > 0 ? (
                    <p className="text-xs text-gray-500">
                      Versión aumentada automáticamente
                    </p>
                  ) : version !== originalVersion ? (
                    <p className="text-xs text-gray-500">
                      Al cambiar la versión se creará una nueva entrada
                    </p>
                  ) : null}
                </div>
              </div>
              
          

{/* Mensajes de validación (continuación) */}
{labelMessage && (
  <div 
    className={`mt-5 p-4 inline-block rounded mb-4 ${
      !isRevisionValid || !isVersionValid
        ? 'bg-red-100 text-red-800 border border-red-300'
        : existsRevision
          ? 'bg-yellow-100 text-yellow-800 border border-yellow-300'
          : 'bg-green-100 text-green-800 border border-green-300'
    }`}
  >
    {labelMessage}
  </div>
)}

{/* Dropzone para subir archivo */}
<div className="mt-10 w-full">
  <Dropzone
    label="Agregar documento (creará una nueva versión)"
    files={archivos}
    onFilesChange={(files) => {
      setArchivos(files);
      if (files.length > 0) {
        if (revision !== originalRevision) {
          setVersion(1);
        } else {
          setVersion(prevVersion => parseInt(String(prevVersion)) + 1);
        }
        setIsSubVersion(1);
      }
    }}
    onRemoveFile={(fileName, type) => {
      if (type === 'principal') {
        setArchivos([]);
        if (currentDocument) {
          setVersion(currentDocument.version);
          setIsSubVersion(0);
        }
      }
    }}
    type="principal"
  /> 
  {archivos.length > 0 && (
    <p className="text-xs text-gray-500 mt-1">
      Al subir un nuevo archivo se creará una nueva entrada con subversión activada
    </p>
  )}
</div>

{/* Botones de acción */}
<div className="flex justify-center mt-6 space-x-4">
  <button 
    type="button"
    onClick={() => {
      setIsEditModalOpen(false);
      setLabelMessage('');
      setIsSubVersion(0);
    }}
    className="px-5 py-2 rounded-md bg-gray-200 text-gray-800 hover:bg-gray-300 transition-colors focus:outline-none focus:ring-2 focus:ring-gray-500"
  >
    Cancelar
  </button>
  <button 
    className="flex items-center px-5 py-2 rounded-md bg-cyan-600 hover:bg-cyan-700 text-white transition-colors focus:outline-none focus:ring-2 focus:ring-cyan-500 disabled:opacity-50 disabled:cursor-not-allowed" 
    type="submit"
    disabled={!isRevisionValid || !isVersionValid}
  >
    <PlusCircle className='inline-block mr-2 h-4 w-4' />
    Guardar
  </button>
</div>
</div>
</form>
</DialogContent>
</Dialog>
)}

{/* Modal para seleccionar proceso */}
<ProcessSelectionModal
isOpen={isProcessModalOpen}
onClose={() => setIsProcessModalOpen(false)}
onSelectProcess={handleProcessSelect}
procesos={procesos}
currentProcesoId={selectedProcesoId}
/>
</div>


  );
  
};

export default DocumentSearch;