// components/custom/Switch.tsx
import React from 'react';

export const Switch = ({
  checked,
  onCheckedChange,
  disabled
}: {
  checked: boolean;
  onCheckedChange: (value: boolean) => void;
  disabled?: boolean;
}) => (
  <label className="inline-flex items-center cursor-pointer">
    <input
      type="checkbox"
      checked={checked}
      onChange={(e) => onCheckedChange(e.target.checked)}
      disabled={disabled}
      className="sr-only"
    />
    <span className={`w-10 h-5 flex items-center bg-gray-300 rounded-full p-1 ${checked ? 'bg-teal-400' : ''}`}>
      <span className={`bg-white w-4 h-4 rounded-full shadow transform transition ${checked ? 'translate-x-5' : ''}`} />
    </span>
  </label>
);
