import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight, Check, Settings, FileText, Zap, BookOpen, Info } from 'lucide-react';

const SIMAGICarousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [direction, setDirection] = useState('next');
  const [transitioning, setTransitioning] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [activeModuleIndex, setActiveModuleIndex] = useState(0);
  const [showModuleInfo, setShowModuleInfo] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isPortrait, setIsPortrait] = useState(false);
  const [screenWidth, setScreenWidth] = useState(0);
  const [screenHeight, setScreenHeight] = useState(0);

  // Detector de vista móvil y orientación mejorado
  useEffect(() => {
    const checkDevice = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      setScreenWidth(width);
      setScreenHeight(height);
      setIsMobile(width < 768);
      setIsPortrait(height > width);
    };
    
    checkDevice();
    window.addEventListener('resize', checkDevice);
    
    return () => window.removeEventListener('resize', checkDevice);
  }, []);

  useEffect(() => {
    setMounted(true);
  }, []);
  
  const nextSlide = () => {
    if (transitioning) return;
    setTransitioning(true);
    setDirection('next');
    setCurrentSlide((prev) => (prev === 4 ? 0 : prev + 1));
    setTimeout(() => setTransitioning(false), 500);
  };
  
  const prevSlide = () => {
    if (transitioning) return;
    setTransitioning(true);
    setDirection('prev');
    setCurrentSlide((prev) => (prev === 0 ? 4 : prev - 1));
    setTimeout(() => setTransitioning(false), 500);
  };
  
  // Autoplay solo cuando no se está mostrando la información del módulo
  useEffect(() => {
    const interval = setInterval(() => {
      if (!showModuleInfo) {
        nextSlide();
      }
    }, 8000);
    
    return () => clearInterval(interval);
  }, [transitioning, showModuleInfo]);

  // Cálculo de tamaños dinámicos basados en el ancho de la pantalla y orientación
  const titleFontSize = isMobile ? (isPortrait ? "16px" : "18px") : "32px";
  const subtitleFontSize = isMobile ? (isPortrait ? "14px" : "16px") : "24px";
  const iconSize = isMobile ? (isPortrait ? "h-4 w-4" : "h-5 w-5") : "h-8 w-8";
  const buttonSize = isMobile ? (isPortrait ? "w-7 h-7" : "w-8 h-8") : "w-12 h-12";
  const navButtonSize = isMobile ? (isPortrait ? "h-4 w-4" : "h-5 w-5") : "h-8 w-8";
  const indicatorSize = isMobile ? (isPortrait ? "h-1.5 w-1.5" : "h-2 w-2") : "h-4 w-4";
  
  // Componente FunctionalitiesTimeline optimizado para móvil
  const FunctionalitiesTimeline = () => {
    const [hoveredItem, setHoveredItem] = useState<number | null>(null);  
       
    const timelineItems = [
      {
        id: 1,
        title: "Biblioteca Normativa integrada con IA",
        color: "#f28d8c", // Rojo salmón
        circleColor: "#70C4CC", // Turquesa
      },
      {
        id: 2,
        title: "Matrices de Riesgo",
        color: "#70C4CC", // Turquesa
        circleColor: "#fce3a1", // Amarillo
      },
      {
        id: 3,
        title: "Cumplimiento Ambiental",
        color: "#fce3a1", // Amarillo
        circleColor: "#70C4CC", // Turquesa
      },
      {
        id: 4,
        title: "Seguimiento Mitigaciones IMIV",
        color: "#d9d9d9", // Gris
        circleColor: "#f28d8c", // Rojo salmón
      },
      {
        id: 5,
        title: "Seguimiento de Condiciones de Promesa",
        color: "#f28d8c", // Rojo salmón
        circleColor: "#fce3a1", // Amarillo
      },
      {
        id: 6,
        title: "Seguimiento Documentos Bancarios",
        color: "#fce3a1", // Amarillo
        circleColor: "#70C4CC", // Turquesa
      },
      {
        id: 7,
        title: "Inspección Técnica y Libro Digital Obra",
        color: "#70C4CC", // Turquesa
        circleColor: "#f28d8c", // Rojo salmón
      }
    ];
    return (
      <div className="relative w-full h-full bg-white flex items-center justify-center overflow-hidden">
        <div className="w-full h-full flex flex-col justify-center items-center px-2 sm:px-4">
          
          {/* Lista de elementos con círculos */}
          <div className="flex flex-col justify-between h-full max-w-4xl w-full">
            {timelineItems.map((item, index) => (
              <div 
                key={item.id} 
                className="flex items-center transform transition-all duration-500 opacity-0"
                onMouseEnter={() => setHoveredItem(item.id)}
                onMouseLeave={() => setHoveredItem(null)}
                style={{
                  opacity: mounted ? 1 : 0,
                  transform: mounted ? `translateX(0)` : `translateX(-20px)`,
                  transitionDelay: `${index * 150}ms`
                }}
              >
                {/* Círculo del timeline */}
                <div 
                  className="relative z-10 transform transition-all duration-300 flex-shrink-0"
                  style={{ 
                    transform: hoveredItem === item.id ? 'scale(1.15)' : 'scale(1)'
                  }}
                >
                  <div 
                    className={`${isMobile ? (isPortrait ? 'w-6 h-6' : 'w-8 h-8') : 'w-12 h-12 md:w-14 md:h-14'} rounded-full flex items-center justify-center transition-all duration-500`}
                    style={{ 
                      backgroundColor: item.circleColor,
                      boxShadow: hoveredItem === item.id
                        ? `0px 8px 15px rgba(0,0,0,0.2), inset 0px -3px 6px rgba(0,0,0,0.1)`
                        : `0px 4px 6px rgba(0,0,0,0.15), inset 0px -2px 4px rgba(0,0,0,0.05)`
                    }}
                  ></div>
                </div>
                
                {/* Caja con texto */}
                <div 
                  className="ml-2 sm:ml-4 py-1 sm:py-2 px-2 sm:px-4 rounded-lg flex-grow transform transition-all duration-300 cursor-pointer"
                  style={{ 
                    backgroundColor: item.color,
                    transform: hoveredItem === item.id 
                      ? 'scale(1.03) translateX(5px)' 
                      : 'scale(1) translateX(0)',
                    boxShadow: hoveredItem === item.id
                      ? `0px 10px 15px -3px rgba(0,0,0,0.15), 0px 8px 8px -4px rgba(0,0,0,0.1), 8px 8px 15px -3px rgba(0,0,0,0.05)`
                      : `0px 4px 6px -1px rgba(0,0,0,0.1), 0px 2px 4px -1px rgba(0,0,0,0.06)`
                  }}
                >
                  <h3 className={`${isMobile ? (isPortrait ? 'text-xs leading-tight' : 'text-sm') : 'text-sm sm:text-lg md:text-xl lg:text-2xl'} font-medium text-gray-700`}>
                    {item.title}
                  </h3>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  // Componente de WorkflowDiagram optimizado para móvil
// Componente de WorkflowDiagram mejorado para móvil manteniendo engranajes
// Componente de WorkflowDiagram mejorado para móvil con engranajes más grandes
const WorkflowDiagram = () => {
    // Tamaños de fuente y elementos escalados según el ancho de pantalla y orientación
    const mainTitleSize = isMobile ? (isPortrait ? "16" : "22") : "32";
    const subTitleSize = isMobile ? (isPortrait ? "14" : "16") : "24";
    
    return (
      <svg
        viewBox="0 0 800 800"
        className="w-full h-full"
        preserveAspectRatio="xMidYMid meet"
        style={{ background: '#fcfcfc' }}
      >      
        {/* Reorganización específica para móvil vertical */}
        {isMobile && isPortrait ? (
          // Versión móvil vertical con engranajes más grandes
          <>
            {/* GESTIÓN (arriba) */}
            <g transform="translate(400, 180) scale(0.85)">
              {/* Base del engranaje */}
              <circle cx="0" cy="0" r="110" fill="#70C4CC" />
              
              {/* 6 dientes trapezoidales más sutiles */}
              {Array.from({ length: 6 }).map((_, i) => {
                const angle = (i * Math.PI * 2) / 6;
                
                // Ancho de diente: borde superior 20% más estrecho
                const innerWidthHalf = Math.PI / 12;   
                const outerWidthHalf = Math.PI / 24;
                
                // Longitud del diente
                const innerRadius = 110;
                const outerRadius = 145.2;
                
                // Puntos para el diente trapezoidal
                const innerStartAngle = angle - innerWidthHalf;
                const innerEndAngle = angle + innerWidthHalf;
                const outerStartAngle = angle - outerWidthHalf;
                const outerEndAngle = angle + outerWidthHalf;
                
                const innerX1 = 0 + innerRadius * Math.cos(innerStartAngle);
                const innerY1 = 0 + innerRadius * Math.sin(innerStartAngle);
                const innerX2 = 0 + innerRadius * Math.cos(innerEndAngle);
                const innerY2 = 0 + innerRadius * Math.sin(innerEndAngle);
                const outerX2 = 0 + outerRadius * Math.cos(outerEndAngle);
                const outerY2 = 0 + outerRadius * Math.sin(outerEndAngle);
                const outerX1 = 0 + outerRadius * Math.cos(outerStartAngle);
                const outerY1 = 0 + outerRadius * Math.sin(outerStartAngle);
                
                return <polygon 
                  key={`gestion-${i}`} 
                  points={`${innerX1},${innerY1} ${innerX2},${innerY2} ${outerX2},${outerY2} ${outerX1},${outerY1}`} 
                  fill="#70C4CC" 
                />
              })}
              
              <circle cx="0" cy="0" r="82.5" fill="#70C4CC" />
              
              <text
                x="0"
                y="0"
                textAnchor="middle"
                dominantBaseline="middle"
                fill="#444"
                fontSize={mainTitleSize}
                fontWeight="bold"
                fontFamily="Arial, sans-serif"
              >
                GESTIÓN
              </text>
            </g>
            
            {/* PLANIFICACIÓN (izquierda abajo) */}
            <g transform="translate(200, 500) scale(0.75)">
              {/* Base del engranaje */}
              <circle cx="0" cy="0" r="145" fill="#F28D8C" />
              
              {/* 9 dientes trapezoidales */}
              {Array.from({ length: 9 }).map((_, i) => {
                const angle = (i * Math.PI * 2) / 9;
                
                const innerWidthHalf = Math.PI / 16.8;  
                const outerWidthHalf = Math.PI / 33;
                
                const innerRadius = 145;
                const outerRadius = 188.8;
                
                const innerStartAngle = angle - innerWidthHalf;
                const innerEndAngle = angle + innerWidthHalf;
                const outerStartAngle = angle - outerWidthHalf;
                const outerEndAngle = angle + outerWidthHalf;
                
                const innerX1 = 0 + innerRadius * Math.cos(innerStartAngle);
                const innerY1 = 0 + innerRadius * Math.sin(innerStartAngle);
                const innerX2 = 0 + innerRadius * Math.cos(innerEndAngle);
                const innerY2 = 0 + innerRadius * Math.sin(innerEndAngle);
                const outerX2 = 0 + outerRadius * Math.cos(outerEndAngle);
                const outerY2 = 0 + outerRadius * Math.sin(outerEndAngle);
                const outerX1 = 0 + outerRadius * Math.cos(outerStartAngle);
                const outerY1 = 0 + outerRadius * Math.sin(outerStartAngle);
                
                return <polygon 
                  key={`planificacion-${i}`} 
                  points={`${innerX1},${innerY1} ${innerX2},${innerY2} ${outerX2},${outerY2} ${outerX1},${outerY1}`} 
                  fill="#F28D8C" 
                />
              })}
              
              <circle cx="0" cy="0" r="111" fill="#F28D8C" />
              
              <text
                x="0"
                y="0"
                textAnchor="middle"
                dominantBaseline="middle"
                fill="#444"
                fontSize={mainTitleSize}
                fontWeight="bold"
                fontFamily="Arial, sans-serif"
              >
                PLANIFICACIÓN
              </text>
            </g>
            
            {/* CONTROL DOCUMENTAL (derecha abajo) */}
            <g transform="translate(600, 500) scale(0.75)">
              {/* Base del engranaje */}
              <circle cx="0" cy="0" r="110" fill="#FCE3A1" />
              
              {/* 6 dientes trapezoidales */}
              {Array.from({ length: 6 }).map((_, i) => {
                const angle = (i * Math.PI * 2) / 6;
                
                // Ancho de diente y otros cálculos...
                const innerWidthHalf = Math.PI / 12;   
                const outerWidthHalf = Math.PI / 24;
                
                const innerRadius = 110;
                const outerRadius = 145.2;
                
                const innerStartAngle = angle - innerWidthHalf;
                const innerEndAngle = angle + innerWidthHalf;
                const outerStartAngle = angle - outerWidthHalf;
                const outerEndAngle = angle + outerWidthHalf;
                
                const innerX1 = 0 + innerRadius * Math.cos(innerStartAngle);
                const innerY1 = 0 + innerRadius * Math.sin(innerStartAngle);
                const innerX2 = 0 + innerRadius * Math.cos(innerEndAngle);
                const innerY2 = 0 + innerRadius * Math.sin(innerEndAngle);
                const outerX2 = 0 + outerRadius * Math.cos(outerEndAngle);
                const outerY2 = 0 + outerRadius * Math.sin(outerEndAngle);
                const outerX1 = 0 + outerRadius * Math.cos(outerStartAngle);
                const outerY1 = 0 + outerRadius * Math.sin(outerStartAngle);
                
                return <polygon 
                  key={`control-${i}`} 
                  points={`${innerX1},${innerY1} ${innerX2},${innerY2} ${outerX2},${outerY2} ${outerX1},${outerY1}`} 
                  fill="#FCE3A1" 
                />
              })}
              
              <circle cx="0" cy="0" r="82.5" fill="#FCE3A1" />
              
              <text
                x="0"
                y="-15"
                textAnchor="middle"
                dominantBaseline="middle"
                fill="#444"
                fontSize={subTitleSize}
                fontWeight="bold"
                fontFamily="Arial, sans-serif"
              >
                CONTROL
              </text>
              <text
                x="0"
                y="15"
                textAnchor="middle"
                dominantBaseline="middle"
                fill="#444"
                fontSize={subTitleSize}
                fontWeight="bold"
                fontFamily="Arial, sans-serif"
              >
                DOCUMENTAL
              </text>
            </g>
            
            {/* Texto "+IA EXPERIMENTAL" */}
            <g transform="translate(400, 350) scale(0.9)">
              {/* Fondo para destacar */}
              <rect x="-100" y="-50" width="200" height="100" rx="8" ry="8" fill="#f8f8f8" stroke="#eaeaea" strokeWidth="1" />
              
              {/* Texto más grande y prominente */}
              <text
                x="0"
                y="0"
                textAnchor="middle"
                fontFamily="Arial, sans-serif"
                fontSize="34"
                fontWeight="bold"
              >
                <tspan fill="#F28D8C">+</tspan>
                <tspan fill="#70C4CC">IA</tspan>
              </text>
              <text
                x="0"
                y="30"
                textAnchor="middle"
                fontFamily="Arial, sans-serif"
                fill="#555"
                fontSize="17"
                fontWeight="bold"
                letterSpacing="1px"
              >
                EXPERIMENTAL
              </text>
            </g>
          </>
        ) : (
          // Versión horizontal/desktop original
          <>
            {/* GESTION (superior-izquierda) - Ajustado */}
            <g transform={isMobile ? `translate(-50,-50) scale(0.8)` : ""}>
              {/* Base del engranaje */}
              <circle cx="250" cy="250" r="110" fill="#70C4CC" />
              
              {/* 6 dientes trapezoidales más sutiles */}
              {Array.from({ length: 6 }).map((_, i) => {
                const angle = (i * Math.PI * 2) / 6;
                
                // Ancho de diente: borde superior 20% más estrecho
                const innerWidthHalf = Math.PI / 12;   
                const outerWidthHalf = Math.PI / 24;
                
                // Longitud del diente
                const innerRadius = 110;
                const outerRadius = 145.2;
                
                // Puntos para el diente trapezoidal
                const innerStartAngle = angle - innerWidthHalf;
                const innerEndAngle = angle + innerWidthHalf;
                const outerStartAngle = angle - outerWidthHalf;
                const outerEndAngle = angle + outerWidthHalf;
                
                const innerX1 = 250 + innerRadius * Math.cos(innerStartAngle);
                const innerY1 = 250 + innerRadius * Math.sin(innerStartAngle);
                const innerX2 = 250 + innerRadius * Math.cos(innerEndAngle);
                const innerY2 = 250 + innerRadius * Math.sin(innerEndAngle);
                const outerX2 = 250 + outerRadius * Math.cos(outerEndAngle);
                const outerY2 = 250 + outerRadius * Math.sin(outerEndAngle);
                const outerX1 = 250 + outerRadius * Math.cos(outerStartAngle);
                const outerY1 = 250 + outerRadius * Math.sin(outerStartAngle);
                
                return <polygon 
                  key={`gestion-${i}`} 
                  points={`${innerX1},${innerY1} ${innerX2},${innerY2} ${outerX2},${outerY2} ${outerX1},${outerY1}`} 
                  fill="#70C4CC" 
                />
              })}
              
              <circle cx="250" cy="250" r="82.5" fill="#70C4CC" />
              
              <text
                x="250"
                y="250"
                textAnchor="middle"
                dominantBaseline="middle"
                fill="#444"
                fontSize={mainTitleSize}
                fontWeight="bold"
                fontFamily="Arial, sans-serif"
              >
                GESTIÓN
              </text>
            </g>
            
            {/* CONTROL DOCUMENTAL (inferior-izquierda) */}
            <g transform={isMobile ? "translate(-40,20) scale(0.75)" : ""}>
              {/* Base del engranaje */}
              <circle cx="150" cy="580" r="110" fill="#FCE3A1" />
              
              {/* 6 dientes trapezoidales */}
              {Array.from({ length: 6 }).map((_, i) => {
                const angle = (i * Math.PI * 2) / 6;
                
                // Ancho de diente y otros cálculos...
                const innerWidthHalf = Math.PI / 12;   
                const outerWidthHalf = Math.PI / 24;
                
                const innerRadius = 110;
                const outerRadius = 145.2;
                
                const innerStartAngle = angle - innerWidthHalf;
                const innerEndAngle = angle + innerWidthHalf;
                const outerStartAngle = angle - outerWidthHalf;
                const outerEndAngle = angle + outerWidthHalf;
                
                const innerX1 = 150 + innerRadius * Math.cos(innerStartAngle);
                const innerY1 = 580 + innerRadius * Math.sin(innerStartAngle);
                const innerX2 = 150 + innerRadius * Math.cos(innerEndAngle);
                const innerY2 = 580 + innerRadius * Math.sin(innerEndAngle);
                const outerX2 = 150 + outerRadius * Math.cos(outerEndAngle);
                const outerY2 = 580 + outerRadius * Math.sin(outerEndAngle);
                const outerX1 = 150 + outerRadius * Math.cos(outerStartAngle);
                const outerY1 = 580 + outerRadius * Math.sin(outerStartAngle);
                
                return <polygon 
                  key={`control-${i}`} 
                  points={`${innerX1},${innerY1} ${innerX2},${innerY2} ${outerX2},${outerY2} ${outerX1},${outerY1}`} 
                  fill="#FCE3A1" 
                />
              })}
              
              <circle cx="150" cy="580" r="82.5" fill="#FCE3A1" />
              
              <text
                x="150"
                y="565"
                textAnchor="middle"
                dominantBaseline="middle"
                fill="#444"
                fontSize={subTitleSize}
                fontWeight="bold"
                fontFamily="Arial, sans-serif"
              >
                CONTROL
              </text>
              <text
                x="150"
                y="595"
                textAnchor="middle"
                dominantBaseline="middle"
                fill="#444"
                fontSize={subTitleSize}
                fontWeight="bold"
                fontFamily="Arial, sans-serif"
              >
                DOCUMENTAL
              </text>
            </g>
            
            {/* PLANIFICACIÓN */}
            <g transform={isMobile ? "translate(20,0) scale(0.75)" : ""}>
              {/* Base del engranaje */}
              <circle cx="580" cy="500" r="145" fill="#F28D8C" />
              
              {/* 9 dientes trapezoidales */}
              {Array.from({ length: 9 }).map((_, i) => {
                const angle = (i * Math.PI * 2) / 9;
                
                const innerWidthHalf = Math.PI / 16.8;  
                const outerWidthHalf = Math.PI / 33;
                
                const innerRadius = 145;
                const outerRadius = 188.8;
                
                const innerStartAngle = angle - innerWidthHalf;
                const innerEndAngle = angle + innerWidthHalf;
                const outerStartAngle = angle - outerWidthHalf;
                const outerEndAngle = angle + outerWidthHalf;
                
                const innerX1 = 580 + innerRadius * Math.cos(innerStartAngle);
                const innerY1 = 500 + innerRadius * Math.sin(innerStartAngle);
                const innerX2 = 580 + innerRadius * Math.cos(innerEndAngle);
                const innerY2 = 500 + innerRadius * Math.sin(innerEndAngle);
                const outerX2 = 580 + outerRadius * Math.cos(outerEndAngle);
                const outerY2 = 500 + outerRadius * Math.sin(outerEndAngle);
                const outerX1 = 580 + outerRadius * Math.cos(outerStartAngle);
                const outerY1 = 500 + outerRadius * Math.sin(outerStartAngle);
                
                return <polygon 
                  key={`planificacion-${i}`} 
                  points={`${innerX1},${innerY1} ${innerX2},${innerY2} ${outerX2},${outerY2} ${outerX1},${outerY1}`} 
                  fill="#F28D8C" 
                />
              })}
              
              <circle cx="580" cy="500" r="111" fill="#F28D8C" />
              
              <text
                x="580"
                y="500"
                textAnchor="middle"
                dominantBaseline="middle"
                fill="#444"
                fontSize={mainTitleSize}
                fontWeight="bold"
                fontFamily="Arial, sans-serif"
              >
                PLANIFICACIÓN
              </text>
            </g>
            
            {/* Texto "+IA EXPERIMENTAL" */}
            <g transform={isMobile ? "translate(25,-60) scale(0.7)" : ""}>
              {/* Fondo para destacar */}
              <rect x="490" y="120" width="200" height="100" rx="8" ry="8" fill="#f8f8f8" stroke="#eaeaea" strokeWidth="1" />
              
              {/* Texto más grande y prominente */}
              <text
                x="590"
                y="165"
                textAnchor="middle"
                fontFamily="Arial, sans-serif"
                fontSize={isMobile ? "36" : "44"}
                fontWeight="bold"
              >
                <tspan fill="#F28D8C">+</tspan>
                <tspan fill="#70C4CC">IA</tspan>
              </text>
              <text
                x="590"
                y="195"
                textAnchor="middle"
                fontFamily="Arial, sans-serif"
                fill="#555"
                fontSize={isMobile ? "16" : "20"}
                fontWeight="bold"
                letterSpacing="1px"
              >
                EXPERIMENTAL
              </text>
            </g>
          </>
        )}
      </svg>
    );
  };
  // ModulesInfo optimizado para móvil
  const ModulesInfo = () => {
    const modules = [
      {
        id: 'planificacion',
        title: "PLANIFICACIÓN",
        description: "Convierte ideas en estrategias ganadoras con SIMAGI. Integra datos en tiempo real, asigna responsables y define tu matriz de riesgos para planificar con precisión y agilidad. Optimiza cada paso, anticipa desafíos y lleva tu empresa al siguiente nivel.",
        color: "#F28D8C", // Rojo salmón
        icon: <Settings className={isMobile ? (isPortrait ? "w-5 h-5" : "w-6 h-6") : "w-8 h-8 md:w-10 md:h-10"} />
      },
      {
        id: 'documentos',
        title: "CONTROL DE DOCUMENTOS",
        description: "Optimiza la gestión documental con un sistema digital que organiza archivos intuitivamente, elimina retrasos y mejora la colaboración. Olvídate de archivos físicos y errores costosos; integra todas tus herramientas en un entorno seguro y eficiente.",
        color: "#FCE3A1", // Amarillo
        icon: <FileText className={isMobile ? (isPortrait ? "w-5 h-5" : "w-6 h-6") : "w-8 h-8 md:w-10 md:h-10"} />
      },
      {
        id: 'gestion',
        title: "GESTIÓN",
        description: "Centraliza y optimiza la administración y gestión en tiempo real. Con SIMAGI, automatiza tareas, integra información clave y toma decisiones precisas, reduciendo errores y aumentando la productividad.",
        color: "#70C4CC", // Turquesa
        icon: <Settings className={isMobile ? (isPortrait ? "w-5 h-5" : "w-6 h-6") : "w-8 h-8 md:w-10 md:h-10"} />
      },
      {
        id: 'inteligencia',
        title: "INTEGRACIÓN CON INTELIGENCIA ARTIFICIAL",
        description: "SIMAGI potencia su ecosistema con inteligencia artificial (IA) para optimizar la gestión y acceso a la información clave. A través de la integración con una biblioteca normativa, los usuarios pueden realizar consultas rápidas sobre regulaciones actualizadas, evitando errores y agilizando la toma de decisiones. Además, la IA se conecta directamente con la base de datos interna de SIMAGI, permitiendo búsquedas avanzadas en lenguaje natural, lo que facilita la recuperación de información específica sin necesidad de conocimientos técnicos.",
        color: "#70C4CC", // Turquesa
        icon: <Zap className={isMobile ? (isPortrait ? "w-5 h-5" : "w-6 h-6") : "w-8 h-8 md:w-10 md:h-10"} />
      }
    ];

    return (
      <div className="w-full h-full flex flex-col justify-center items-center bg-white bg-opacity-95 backdrop-blur-sm p-2 sm:p-4 overflow-y-auto">
        <div className="max-w-5xl w-full">
          {/* Selector de módulos - diseño en fila o columna según orientación */}
          <div className={`flex ${isPortrait ? 'flex-col gap-1' : 'flex-wrap justify-center gap-2 sm:gap-3'} mb-2 sm:mb-4`}>
            {modules.map((module, index) => (
              <button
                key={module.id}
                className={`flex items-center gap-1 sm:gap-2 px-2 sm:px-4 py-1 sm:py-2 ${isPortrait ? 'rounded-md' : 'rounded-full'} transition-all duration-300 transform hover:scale-105`}
                style={{
                  backgroundColor: activeModuleIndex === index ? module.color : '#f0f0f0',
                  color: activeModuleIndex === index ? '#444' : '#666',
                  fontWeight: activeModuleIndex === index ? 'bold' : 'normal',
                  boxShadow: activeModuleIndex === index ? '0 4px 12px rgba(0,0,0,0.15)' : 'none'
                }}
                onClick={() => setActiveModuleIndex(index)}
              >
                {module.icon}
                <span className={`${isMobile ? (isPortrait ? 'text-xs' : 'text-sm') : 'text-sm'} ${isPortrait ? 'inline' : 'hidden md:inline'}`}>{module.title}</span>
              </button>
            ))}
          </div>

          {/* Información del módulo seleccionado */}
          <div className="bg-white rounded-xl shadow-lg overflow-hidden transition-all duration-500 transform hover:scale-105">
            <div 
              className="h-1 sm:h-2 w-full" 
              style={{ backgroundColor: modules[activeModuleIndex].color }}
            ></div>
            <div className="p-2 sm:p-4 md:p-6">
              <h2 className={`text-base sm:text-xl md:text-2xl font-bold mb-1 sm:mb-2 flex items-center gap-1 sm:gap-2`}>
                {modules[activeModuleIndex].icon}
                {modules[activeModuleIndex].title}
              </h2>
              <p className={`text-gray-700 ${isMobile && isPortrait ? 'text-xs leading-tight' : 'text-sm sm:text-base'} leading-relaxed`}>
                {modules[activeModuleIndex].description}
              </p>
            </div>
          </div>

          {/* Información adicional - Reseña y Permisos Sectoriales - formato responsivo */}
          {activeModuleIndex === 0 && (
            <div className={`mt-2 sm:mt-4 grid grid-cols-1 ${isPortrait ? '' : 'md:grid-cols-2'} gap-2 sm:gap-4`}>
              <div className="bg-white rounded-xl shadow-lg p-2 sm:p-4 transition-all duration-500 transform hover:scale-105">
                <h3 className="text-sm sm:text-lg font-bold mb-1 flex items-center gap-1">
                  <Info className="w-3 h-3 sm:w-4 sm:h-4" />
                  RESEÑA
                </h3>
                <p className={`text-gray-700 ${isMobile && isPortrait ? 'text-xs leading-tight' : 'text-xs sm:text-sm'}`}>
                  SIMAGI SPA (Sistema Maestro de Gestión Integrado SpA) es una empresa innovadora fundada el 6 de febrero de 2025, especializada en software especializado en Tramitología mediante herramientas para la Gestión documental, Planificación y Monitoreo. Su tecnología sin duda impactará sectores como la construcción, planificación urbana, banca e industria, integrando ERP e inteligencia artificial.
                </p>
              </div>
              
              <div className="bg-white rounded-xl shadow-lg p-2 sm:p-4 transition-all duration-500 transform hover:scale-105">
                <h3 className="text-sm sm:text-lg font-bold mb-1 flex items-center gap-1">
                  <BookOpen className="w-3 h-3 sm:w-4 sm:h-4" />
                  PERMISOS SECTORIALES
                </h3>
                <p className={`text-gray-700 ${isMobile && isPortrait ? 'text-xs leading-tight' : 'text-xs sm:text-sm'}`}>
                  La gestión de permisos sectoriales involucra a arquitectos, consultoras, oficinas de ingeniería, inmobiliarias, constructoras y fondos de inversión, junto con organismos regulatorios como MINVU y DOM. SIMAGI centraliza la documentación y automatiza los procesos, facilitando una coordinación eficiente entre estos actores agilizando la aprobación de proyectos y facilitando el cumplimiento normativo.
                </p>
              </div>
            </div>
          )}
        </div>

        {/* Botón para cerrar vista detallada - más pequeño en móvil vertical */}
        <button 
          className={`mt-2 sm:mt-4 px-2 sm:px-4 py-1 sm:py-2 bg-gray-800 text-white rounded-full flex items-center gap-1 transition-all duration-300 hover:bg-gray-700 transform hover:scale-105 ${isPortrait ? 'text-xs' : ''}`}
          onClick={() => setShowModuleInfo(false)}
        >
          <span className={`${isMobile && isPortrait ? 'text-xs' : 'text-xs sm:text-sm'}`}>Volver al carrusel</span>
          <ChevronRight className="w-3 h-3 sm:w-4 sm:h-4" />
        </button>
      </div>
    );
  };
  
  // ModulesOverview optimizado para móvil vertical
  const ModulesOverview = () => {
    const modules = [
      {
        title: "PLANIFICACIÓN",
        color: "#F28D8C", // Rojo salmón
        icon: <Settings className={isMobile ? (isPortrait ? "w-5 h-5" : "w-6 h-6") : "w-10 h-10"} />
      },
      {
        title: "CONTROL DE DOCUMENTOS",
        color: "#FCE3A1", // Amarillo
        icon: <FileText className={isMobile ? (isPortrait ? "w-5 h-5" : "w-6 h-6") : "w-10 h-10"} />
      },
      {
        title: "GESTIÓN",
        color: "#70C4CC", // Turquesa
        icon: <Settings className={isMobile ? (isPortrait ? "w-5 h-5" : "w-6 h-6") : "w-10 h-10"} />
      },
      {
        title: "IA INTEGRADA",
        color: "#70C4CC", // Turquesa
        icon: <Zap className={isMobile ? (isPortrait ? "w-5 h-5" : "w-6 h-6") : "w-10 h-10"} />
      }
    ];

    return (
      <div className="w-11/12 aspect-square flex flex-col items-center justify-center p-2 sm:p-4">
        <h2 className={`${isMobile && isPortrait ? 'text-lg' : 'text-xl sm:text-3xl md:text-4xl'} font-bold mb-2 sm:mb-6 text-gray-800`}>MÓDULOS SIMAGI</h2>
        
        <div className={`grid ${isPortrait ? 'grid-cols-1 gap-2' : 'grid-cols-2 gap-2 sm:gap-4'} w-full max-w-4xl`}>
          {modules.map((module, index) => (
            <div 
              key={index}
              className="rounded-xl shadow-lg overflow-hidden transition-all duration-300 transform hover:scale-105 hover:shadow-xl cursor-pointer"
              onClick={() => {
                setActiveModuleIndex(index);
                setShowModuleInfo(true);
              }}
            >
              <div 
                className={`h-full flex ${isPortrait ? 'flex-row items-center' : 'flex-col items-center justify-center'} p-2 sm:p-4`}
                style={{ backgroundColor: module.color }}
              >
                <div className="bg-white bg-opacity-20 rounded-full p-2 sm:p-3 mb-0 sm:mb-2 flex-shrink-0 mr-2 sm:mr-0">
                  {module.icon}
                </div>
                <div className="flex flex-col">
                  <h3 className={`${isMobile && isPortrait ? 'text-sm' : 'text-sm sm:text-lg md:text-xl'} font-bold text-center`} style={{ color: module.color === "#FCE3A1" ? "#444" : "white" }}>
                    {module.title}
                  </h3>
                  {!isPortrait && (
                    <button 
                      className="mt-1 sm:mt-2 px-2 py-1 bg-white bg-opacity-30 rounded-full text-gray-800 font-medium flex items-center mx-auto gap-1 transition-all duration-300 hover:bg-opacity-50"
                    >
                      <span className="text-xs">Más info</span>
                      <ChevronRight className="w-3 h-3" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
            
        <button 
          className={`mt-4 px-3 py-2 bg-gray-800 text-white rounded-full flex items-center gap-1 transition-all duration-300 hover:bg-gray-700 transform hover:scale-105 ${isMobile && isPortrait ? 'text-xs' : 'text-sm'}`}
          onClick={() => {
            setActiveModuleIndex(0);
            setShowModuleInfo(true);
          }}
        >
          Ver detalles de todos los módulos
          <Info className={`${isMobile && isPortrait ? 'w-3 h-3' : 'w-4 h-4'}`} />
        </button>
      </div>
    );
  };
      
  // Componentes de contenido con ajustes para orientación vertical en móvil
  const CaracteristicasContent = () => (
    <div className={`w-11/12 h-full flex flex-col ${isPortrait ? '' : 'md:grid md:grid-cols-3 md:grid-rows-3'} gap-2 sm:gap-4`}>
      {/* Versión móvil más simple en modo vertical */}
      {isPortrait && (
        <>
          <div className="mb-2 bg-amber-300 rounded-lg shadow-md flex items-center justify-center group transition-transform duration-300 hover:scale-105 hover:shadow-lg p-2">
            <div className="flex items-center justify-center w-full h-full text-center">
              <Check className="mr-2 h-5 w-5 text-white group-hover:scale-110 transition-transform duration-300" />
              <h2 className="text-base font-bold text-white">Software especializado en Tramitología</h2>
            </div>
          </div>
          
          <div className="mb-2 bg-red-300 rounded-lg shadow-md flex items-center justify-center group transition-transform duration-300 hover:scale-105 hover:shadow-lg p-2">
            <div className="flex items-center justify-center w-full h-full text-center">
              <Check className="mr-2 h-5 w-5 text-white group-hover:scale-110 transition-transform duration-300" />
              <h2 className="text-base font-bold text-white">Registra, monitorea e investiga tus Procesos</h2>
            </div>
          </div>
          
          <div className="mb-2 bg-teal-400 rounded-lg shadow-md flex items-center justify-center group transition-transform duration-300 hover:scale-105 hover:shadow-lg p-2">
            <div className="flex items-center justify-center w-full h-full text-center">
              <Check className="mr-2 h-5 w-5 text-white group-hover:scale-110 transition-transform duration-300" />
              <h2 className="text-base font-bold text-white">Todo en una única plataforma integrada</h2>
            </div>
          </div>
        </>
      )}
      
      {/* Versión para orientación horizontal y desktop */}
      {!isPortrait && (
        <>
          {/* Primera fila */}
          <div className="hidden md:block md:col-span-1 md:row-span-1 bg-teal-300 rounded-lg shadow-md flex items-center justify-center transition-transform duration-300 hover:scale-105 hover:shadow-lg"></div>
          
          <div className="mb-4 md:mb-0 md:col-span-2 md:row-span-1 bg-amber-300 rounded-lg shadow-md flex items-center justify-center group transition-transform duration-300 hover:scale-105 hover:shadow-lg">
            <div className="flex items-center justify-center w-full h-full p-4 text-center">
              <Check className="mr-2 sm:mr-4 h-6 w-6 sm:h-8 sm:w-8 md:h-10 md:w-10 text-white group-hover:scale-110 transition-transform duration-300" />
              <h2 className="text-lg sm:text-xl md:text-2xl lg:text-4xl font-bold text-white">Software especializado en Tramitología</h2>
            </div>
          </div>
          
          {/* Segunda fila */}
          <div className="col-span-2 row-span-1 bg-red-300 rounded-lg shadow-md flex items-center justify-center group transition-transform duration-300 hover:scale-105 hover:shadow-lg">
            <div className="flex items-center justify-center w-full h-full p-4 text-center">
              <Check className="mr-4 h-8 w-8 md:h-10 md:w-10 text-white group-hover:scale-110 transition-transform duration-300" />
              <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold text-white">Registra, monitorea e investiga tus Procesos</h2>
            </div>
          </div>
          <div className="col-span-1 row-span-1 bg-teal-300 rounded-lg shadow-md flex items-center justify-center transition-transform duration-300 hover:scale-105 hover:shadow-lg"></div>
          
          {/* Tercera fila */}
          <div className="col-span-1 row-span-1 bg-amber-300 rounded-lg shadow-md flex items-center justify-center transition-transform duration-300 hover:scale-105 hover:shadow-lg"></div>
          <div className="col-span-2 row-span-1 bg-teal-400 rounded-lg shadow-md flex items-center justify-center group transition-transform duration-300 hover:scale-105 hover:shadow-lg">
            <div className="flex items-center justify-center w-full h-full p-4 text-center">
              <Check className="mr-4 h-8 w-8 md:h-10 md:w-10 text-white group-hover:scale-110 transition-transform duration-300" />
              <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold text-white">Todo en una única plataforma integrada</h2>
            </div>
          </div>
        </>
      )}
    </div>
  );

  // Componente PermisosSectoriales Optimizado
  const PermisosSectorialesContent = () => (
    <div className="w-11/12 aspect-square grid grid-cols-4 grid-rows-7 gap-1 sm:gap-4">
      {/* Barra lateral - más compacta en móvil vertical */}
      <div className={`${isPortrait ? 'col-span-4 row-span-1' : 'col-span-1 row-span-7'} bg-teal-300 border-2 border-red-300 rounded-lg shadow-md flex items-center justify-center transition-transform duration-300 hover:scale-105 hover:shadow-lg`}>
        <h3 className={`text-gray-700 font-bold ${isPortrait ? 'text-sm' : 'text-sm md:text-lg'}`} style={{ writingMode: isPortrait ? 'horizontal-tb' : 'vertical-rl', transform: isPortrait ? 'none' : 'rotate(180deg)' }}>
          {isPortrait ? 'PERMISOS SECTORIALES Y DOM' : 'Permisos Sectoriales y Permisos DOM'}
        </h3>
      </div>
      
      {/* Contenido de la tabla - reordanado para móvil vertical */}
      {isPortrait ? (
        <div className="col-span-4 row-span-6 grid grid-cols-1 gap-1">
          <div className="bg-teal-300 rounded-lg shadow-md flex items-center justify-center p-1">
            <h3 className="text-xs font-bold text-gray-700">ARQUITECTOS O PROFESIONALES</h3>
          </div>
          <div className="bg-amber-200 rounded-lg shadow-md flex items-center justify-center p-1">
            <h3 className="text-xs font-bold text-gray-700">MINVU - DOM</h3>
          </div>
          <div className="bg-teal-300 rounded-lg shadow-md flex items-center justify-center p-1">
            <h3 className="text-xs font-bold text-gray-700">CONSULTORAS (M, L, XL)</h3>
          </div>
          <div className="bg-red-300 rounded-lg shadow-md flex items-center justify-center p-1">
            <h3 className="text-xs font-bold text-gray-700">OFICINAS DE INGENIERÍA</h3>
          </div>
          <div className="bg-amber-200 rounded-lg shadow-md flex items-center justify-center p-1">
            <h3 className="text-xs font-bold text-gray-700">INMOBILIARIAS / CONSTRUCTORAS</h3>
          </div>
          <div className="bg-teal-300 rounded-lg shadow-md flex items-center justify-center p-1">
            <h3 className="text-xs font-bold text-gray-700">FONDOS DE INVERSIÓN</h3>
          </div>
          <div className="bg-red-300 rounded-lg shadow-md flex items-center justify-center p-1">
            <h3 className="text-xs font-bold text-gray-700">OTRAS INDUSTRIAS</h3>
          </div>
        </div>
      ) : (
        <>
          {/* ARQUITECTOS O PROFESIONALES INDEPENDIENTES */}
          <div className="col-span-3 row-span-1 bg-teal-300 rounded-lg shadow-md flex items-center justify-center transition-transform duration-300 hover:scale-105 hover:shadow-lg">
            <h3 className="text-base md:text-xl lg:text-2xl font-bold text-gray-700">ARQUITECTOS O PROFESIONALES INDEPENDIENTES</h3>
          </div>
          
          {/* DIRECCIONES DE OBRA MUNICIPALES (DOM) */}
          <div className="col-span-3 row-span-1 bg-amber-200 rounded-lg shadow-md flex items-center justify-center transition-transform duration-300 hover:scale-105 hover:shadow-lg">
            <h3 className="text-base md:text-xl lg:text-2xl font-bold text-gray-700">MINVU - DOM</h3>
          </div>
          
          {/* CONSULTORAS (M, L, XL) */}
          <div className="col-span-3 row-span-1 bg-teal-300 rounded-lg shadow-md flex items-center justify-center transition-transform duration-300 hover:scale-105 hover:shadow-lg">
            <h3 className="text-base md:text-xl lg:text-2xl font-bold text-gray-700">CONSULTORAS (M, L, XL)</h3>
          </div>
          
          {/* OFICINAS DE INGENIERÍA (M, L, XL) */}
          <div className="col-span-3 row-span-1 bg-red-300 rounded-lg shadow-md flex items-center justify-center transition-transform duration-300 hover:scale-105 hover:shadow-lg">
            <h3 className="text-base md:text-xl lg:text-2xl font-bold text-gray-700">OFICINAS DE INGENIERÍA (M, L, XL)</h3>
          </div>
          
          {/* INMOBILIARIAS / CONSTRUCTORAS */}
          <div className="col-span-3 row-span-1 bg-amber-200 rounded-lg shadow-md flex items-center justify-center transition-transform duration-300 hover:scale-105 hover:shadow-lg">
            <h3 className="text-base md:text-xl lg:text-2xl font-bold text-gray-700">INMOBILIARIAS / CONSTRUCTORAS</h3>
          </div>
          
          {/* FONDOS DE INVERSIÓN DESARROLLO INMOBILIARIO */}
          <div className="col-span-3 row-span-1 bg-teal-300 rounded-lg shadow-md flex items-center justify-center transition-transform duration-300 hover:scale-105 hover:shadow-lg">
            <h3 className="text-base md:text-xl lg:text-2xl font-bold text-gray-700">FONDOS DE INVERSIÓN DESARROLLO INMOBILIARIO</h3>
          </div>
          
          {/* OTRAS INDUSTRIAS */}
          <div className="col-span-3 row-span-1 bg-red-300 rounded-lg shadow-md flex items-center justify-center transition-transform duration-300 hover:scale-105 hover:shadow-lg">
            <h3 className="text-base md:text-xl lg:text-2xl font-bold text-gray-700">OTRAS INDUSTRIAS</h3>
          </div>
        </>
      )}
    </div>
  );

  // Componente ProcesosContent optimizado
// Componente ProcesosContent rediseñado pero manteniendo el estilo original
const ProcesosContent = () => {
    // Versión específica para móvil vertical
    if (isMobile && isPortrait) {
      return (
        <div className="w-11/12 h-full flex flex-col px-1 py-2 bg-white rounded-lg shadow-sm overflow-hidden">
          <h2 className="text-lg font-bold text-center text-gray-800 mb-2 bg-teal-400 py-1 rounded-md">PROCESOS</h2>
          
          <div className="flex flex-col space-y-1 flex-grow overflow-y-auto">
            {/* Subprocesos */}
            <div className="space-y-1">
              {Array.from({ length: 3 }).map((_, index) => (
                <div 
                  key={`sub-${index}`}
                  className="bg-teal-300 rounded-md p-1 flex items-center justify-center"
                >
                  <span className="text-xs font-medium text-gray-700">Subproceso {index + 1}</span>
                </div>
              ))}
            </div>
            
            {/* Agrupadores */}
            <div className="grid grid-cols-3 gap-1">
              {Array.from({ length: 5 }).map((_, index) => (
                <div 
                  key={`agrup-${index}`}
                  className={`${index > 2 ? 'col-span-3' : ''} bg-amber-200 rounded-md p-1 flex items-center justify-center`}
                >
                  <span className="text-xs font-medium text-gray-700">
                    {index > 2 ? `Agrupador ${index + 1}` : `Agrup. ${index + 1}`}
                  </span>
                </div>
              ))}
            </div>
            
            {/* Tareas */}
            <div className="grid grid-cols-3 gap-1">
              {Array.from({ length: 5 }).map((_, index) => (
                <div 
                  key={`tarea-${index}`}
                  className={`${index > 2 ? 'col-span-3' : ''} bg-red-300 rounded-md p-1 flex items-center justify-center`}
                >
                  <span className="text-xs font-medium text-gray-700">Tarea{index + 1}</span>
                </div>
              ))}
            </div>
            
            {/* Responsables */}
            <div className="grid grid-cols-3 gap-1">
              {Array.from({ length: 5 }).map((_, index) => (
                <div 
                  key={`resp-${index}`}
                  className={`${index > 2 ? 'col-span-3' : ''} bg-white border border-gray-300 rounded-md p-1 flex items-center justify-center`}
                >
                  <span className="text-xs font-medium text-gray-700">Resp. {index + 1}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }
    
    // Versión para orientación horizontal y desktop
    return (
      <div className="w-11/12 aspect-square flex flex-col justify-center space-y-3">
        {/* Título - Procesos */}
        <div className="w-full bg-teal-400 rounded-lg p-3 flex items-center justify-center transition-transform duration-300 hover:scale-105 hover:shadow-lg">
          <h2 className="text-2xl sm:text-3xl font-medium text-gray-700">Procesos</h2>
        </div>
        
        {/* Fila de subprocesos */}
        <div className="flex gap-2">
          <div className="flex-1 flex-grow bg-teal-300 rounded-lg p-3 flex items-center justify-center transition-transform duration-300 hover:scale-105 hover:shadow-lg">
            <span className="text-sm sm:text-lg text-gray-700">Subproceso 1</span>
          </div>
          <div className="flex-1 flex-grow bg-teal-300 rounded-lg p-3 flex items-center justify-center transition-transform duration-300 hover:scale-105 hover:shadow-lg">
            <span className="text-sm sm:text-lg text-gray-700">Subproceso 2</span>
          </div>
          <div className="flex-1 flex-grow bg-teal-300 rounded-lg p-3 flex items-center justify-center transition-transform duration-300 hover:scale-105 hover:shadow-lg">
            <span className="text-sm sm:text-lg text-gray-700">Subproceso 3</span>
          </div>
        </div>
        
        {/* Fila de agrupadores */}
        <div className="flex gap-2">
          <div className="flex-1 flex-grow bg-amber-200 rounded-lg p-2 flex items-center justify-center transition-transform duration-300 hover:scale-105 hover:shadow-lg">
            <span className="text-xs sm:text-base text-gray-700">Agrupador 1</span>
          </div>
          <div className="flex-1 flex-grow bg-amber-200 rounded-lg p-2 flex items-center justify-center transition-transform duration-300 hover:scale-105 hover:shadow-lg">
            <span className="text-xs sm:text-base text-gray-700">Agrupador 2</span>
          </div>
          <div className="flex-1 flex-grow bg-amber-200 rounded-lg p-2 flex items-center justify-center transition-transform duration-300 hover:scale-105 hover:shadow-lg">
            <span className="text-xs sm:text-base text-gray-700">Agrupador 3</span>
          </div>
          <div className="flex-1 flex-grow bg-amber-200 rounded-lg p-2 flex items-center justify-center transition-transform duration-300 hover:scale-105 hover:shadow-lg">
            <span className="text-xs sm:text-base text-gray-700">Agrupador 4</span>
          </div>
          <div className="flex-1 flex-grow bg-amber-200 rounded-lg p-2 flex items-center justify-center transition-transform duration-300 hover:scale-105 hover:shadow-lg">
            <span className="text-xs sm:text-base text-gray-700">Agrupador 5</span>
          </div>
        </div>
        
        {/* Fila de tareas */}
        <div className="flex gap-2">
          {Array.from({ length: 5 }).map((_, index) => (
            <div 
              key={`tarea-${index}`} 
              className="flex-1 flex-grow bg-red-300 rounded-lg p-2 flex items-center justify-center transition-transform duration-300 hover:scale-105 hover:shadow-lg"
            >
              <span className="text-xs sm:text-sm text-gray-700 text-center">
                Tarea<br />(Actividades)
              </span>
            </div>
          ))}
        </div>
        
        {/* Fila de responsables */}
        <div className="flex gap-2">
          {Array.from({ length: 5 }).map((_, index) => (
            <div 
              key={`resp-${index}`} 
              className="flex-1 flex-grow bg-white border border-gray-300 rounded-lg flex items-center justify-center transition-transform duration-300 hover:scale-105 hover:shadow-lg"
            >
              <span className="text-xs sm:text-sm text-gray-700 text-center">
                Responsable<br />(Departamento<br />o servicio)
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  };
      
  // Contenido de las slides con optimización para orientación vertical
  const slides = [
    {
      id: 'caracteristicas',
      title: 'Características principales',
      content: <CaracteristicasContent />
    },
    {
      id: 'permisos',
      title: 'Permisos Sectoriales',
      content: <PermisosSectorialesContent />
    },
    {
      id: 'workflow',
      title: 'Workflow Diagrama',
      content: (
        <div className="w-11/12 aspect-square flex items-center justify-center">
          <WorkflowDiagram />
        </div>
      )
    },
    {
      id: 'procesos',
      title: 'Procesos',
      content: <ProcesosContent />
    },
    {
      id: 'funcionalidades',
      title: 'Funcionalidades',
      content: (
        <div className="w-11/12 aspect-square flex items-center justify-center bg-white rounded-lg shadow-sm">
          <FunctionalitiesTimeline />
        </div>
      )
    },
    {
      id: 'modulos',
      title: 'Módulos',
      content: <ModulesOverview />
    }
  ];
    
  return (
    <div className="relative w-full bg-white">
      {/* Mostrar la información detallada del módulo o el carrusel principal */}
      {showModuleInfo ? (
        <ModulesInfo />
      ) : (
        <>
          {/* Contenedor principal con proporción 1:1 */}
          <div className="relative w-full aspect-square overflow-hidden flex items-center justify-center">
            {/* Slides */}
            {slides.map((slide, index) => (
              <div 
                key={slide.id}
                className={`absolute inset-0 transition-all duration-500 flex items-center justify-center ${
                  currentSlide === index 
                    ? 'opacity-100 z-10 transform scale-100' 
                    : 'opacity-0 -z-10 transform ' + 
                      (direction === 'next' ? 'scale-90 translate-x-full' : 'scale-90 -translate-x-full')
                }`}
              >
                {slide.content}
              </div>
            ))}
          </div>
          
          {/* Navegación optimizada para móvil */}
          <div className={`w-full flex justify-between items-center mt-2 mb-3 ${isPortrait ? 'px-1' : 'px-4'}`}>
            {/* Botón anterior */}
            <button
              onClick={prevSlide}
              className={`${isPortrait ? 'w-8 h-8' : 'w-10 h-10 sm:w-12 sm:h-12'} flex items-center justify-center bg-white rounded-full shadow-md hover:bg-red-300 transition-colors duration-300`}
              aria-label="Anterior"
            >
              <ChevronLeft className={`${isPortrait ? 'h-4 w-4' : 'h-6 w-6 sm:h-8 sm:w-8'} text-gray-600 hover:text-white transition-colors duration-300`} />
            </button>
            
            {/* Indicadores de slide - versión compacta para móvil vertical */}
            <div className="flex space-x-1 sm:space-x-2">
              {slides.map((_, index) => (
                <button
                  key={index}
                  className={`${isPortrait ? 'h-2 w-2' : 'h-3 w-3 sm:h-4 sm:w-4'} rounded-full transition-all duration-300 ${
                    currentSlide === index ? 'bg-teal-500 scale-110' : 'bg-gray-300 hover:bg-gray-400'
                  }`}
                  onClick={() => setCurrentSlide(index)}
                  aria-label={`Ir a slide ${index + 1}`}
                />
              ))}
            </div>
            
            {/* Botón siguiente */}
            <button
              onClick={nextSlide}
              className={`${isPortrait ? 'w-8 h-8' : 'w-10 h-10 sm:w-12 sm:h-12'} flex items-center justify-center bg-white rounded-full shadow-md hover:bg-red-300 transition-colors duration-300`}
              aria-label="Siguiente"
            >
              <ChevronRight className={`${isPortrait ? 'h-4 w-4' : 'h-6 w-6 sm:h-8 sm:w-8'} text-gray-600 hover:text-white transition-colors duration-300`} />
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default SIMAGICarousel;