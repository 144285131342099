import React, { ReactNode, useState, useEffect, useCallback, useRef, ChangeEvent, FormEvent } from 'react';
import { 
  PlusCircle, Save, MinusCircle, RotateCw, Trash, List, XCircle, 
  ChevronUp, ChevronDown, ArrowDown, ArrowUp, X, Search, RefreshCw, 
  Filter, ChevronLeft, ChevronRight, ArrowUpDown, Eye, Edit
} from 'lucide-react';
import { debounce } from 'lodash';
import toast from 'react-hot-toast';
import { TemplatesManager, PlantillaSubprocesos, Subproceso as TemplateSubproceso } from './TemplateManager';
import ConfirmDialog from '../ui/confirmDialog';  // Ajusta la ruta según la estructura de tu proyecto

// ===========================================
// Types and Interfaces
// ===========================================

interface User {
  id: number;
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  is_admin: boolean;
  is_active: boolean;
  departamento: string;
} 

interface Subproceso {
  id: number;
  nombre: string;
  color: string;
  orden: number;
  globalId?: number;
  subproceso?: string;
}

interface FormData {
  id: number;
  codigo: string;
  nombreProceso: string;
  anio: string;
  region: string;
  numeroRegion: string;
  comuna: string;
  numeroProceso: string;
  etapa: string;
  responsables_ids: number[];
  subprocesos: Subproceso[];
  originalNumeroProceso: string;
  isActive?: boolean;
}

interface Region {
  region: string;
  numero: string;
  comunas: string[];
}

interface LabelMessageProps {
  type: 'success' | 'error';
  message: string;
}

interface Process {
  id: number;
  codigo: string;
  nombreProceso: string;
  anio: string;
  region: string;
  numeroRegion: string;
  comuna: string;
  numeroProceso: string;
  etapa: string;
  isActive: boolean;
  subprocesos: Subproceso[];
}

interface ComunaAutocompleteProps {
  allComunas: string[];
  selectedComuna: string;
  onSelect: (comuna: string) => void;
  placeholder?: string;
}

interface MultiUserAutocompleteProps {
  users: User[];
  selectedUsers: User[];
  onSelect: (users: User[]) => void;
  placeholder?: string;
}

// ===========================================
// Helper Components
// ===========================================

const LabelMessage: React.FC<LabelMessageProps> = ({ type, message }) => {
  const [progress, setProgress] = useState(100);
  
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress <= 0) {
          clearInterval(timer);
          return 0;
        }
        return prevProgress - 1;
      });
    }, 31);

    return () => clearInterval(timer);
  }, []);

  const bgColor = type === 'success' ? 'bg-green-500' : 'bg-red-500';
  const borderColor = type === 'success' ? 'border-green-600' : 'border-red-600';
  const textColor = 'text-white';

  return (
    <div className={`${bgColor} ${borderColor} ${textColor} px-4 py-3 rounded relative mb-4 border-l-4 overflow-hidden`} role="alert">
      <span className="block sm:inline">{message}</span>
      <div 
        className={`absolute bottom-0 left-0 h-1 ${type === 'success' ? 'bg-green-700' : 'bg-red-700'}`} 
        style={{ width: `${progress}%`, transition: 'width 0.1s linear' }}
      ></div>
    </div>
  );
};

const ComunaAutocomplete: React.FC<ComunaAutocompleteProps> = ({
  allComunas,
  selectedComuna,
  onSelect,
  placeholder = "Buscar comuna...",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState(selectedComuna || "");
  const [filteredComunas, setFilteredComunas] = useState<string[]>([]);
  const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const uniqueComunas = Array.from(new Set(allComunas));

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!isOpen) {
      if (e.key === 'ArrowDown' || e.key === 'Enter') {
        setIsOpen(true);
      }
      return;
    }

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setHighlightedIndex(prev => 
          prev < filteredComunas.length - 1 ? prev + 1 : prev
        );
        break;
      case 'ArrowUp':
        e.preventDefault();
        setHighlightedIndex(prev => prev > 0 ? prev - 1 : -1);
        break;
      case 'Enter':
        e.preventDefault();
        if (highlightedIndex >= 0 && highlightedIndex < filteredComunas.length) {
          handleSelect(filteredComunas[highlightedIndex]);
        }
        break;
      case 'Escape':
        setIsOpen(false);
        setHighlightedIndex(-1);
        break;
      default:
        break;
    }
  };

  const handleMouseEnter = (index: number) => {
    setHighlightedIndex(index);
  };

  // Update inputValue when selectedComuna changes
  useEffect(() => {
    setInputValue(selectedComuna || "");
  }, [selectedComuna]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!inputValue?.trim()) {
      setFilteredComunas(allComunas);
      return;
    }
    const normalizedInput = inputValue.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    setFilteredComunas(
      allComunas.filter(comuna =>
        comuna.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(normalizedInput)
      )
    );
  }, [inputValue, allComunas]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    setIsOpen(true);
    setHighlightedIndex(-1);
    if (newValue === '') {
      onSelect('');
    }
  };

  const handleSelect = (comuna: string) => {
    onSelect(comuna);
    setInputValue(comuna);
    setIsOpen(false);
    setHighlightedIndex(-1);
  };

  return (
    <div className="relative w-full" ref={wrapperRef}>
      <div className="relative">
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onFocus={(e) => {
            setIsOpen(true);
            e.target.select();
          }}
          placeholder={placeholder}
          className="w-full p-2 border border-gray-300 rounded text-sm"
          autoComplete="off"
        />
        {isOpen && filteredComunas.length > 0 && (
          <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto">
            {filteredComunas.map((comuna, index) => (
              <div
                key={comuna}
                onClick={() => handleSelect(comuna)}
                onMouseEnter={() => handleMouseEnter(index)}
                className={`p-2 cursor-pointer text-sm ${
                  index === highlightedIndex
                    ? 'bg-blue-100'
                    : 'hover:bg-gray-100'
                }`}
              >
                {comuna}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

// ===========================================
// Utility Functions
// ===========================================

const decimalToRoman = (num: number): string => {
  const romanNumerals = [
    { value: 1000, symbol: 'M' },
    { value: 900, symbol: 'CM' },
    { value: 500, symbol: 'D' },
    { value: 400, symbol: 'CD' },
    { value: 100, symbol: 'C' },
    { value: 90, symbol: 'XC' },
    { value: 50, symbol: 'L' },
    { value: 40, symbol: 'XL' },
    { value: 10, symbol: 'X' },
    { value: 9, symbol: 'IX' },
    { value: 5, symbol: 'V' },
    { value: 4, symbol: 'IV' },
    { value: 1, symbol: 'I' }
  ];

  let roman = '';
  for (const { value, symbol } of romanNumerals) {
    while (num >= value) {
      roman += symbol;
      num -= value;
    }
  }
  return roman;
};

function obtenerCodigo(cadena: string): string {
  const puntoPos: number = cadena.indexOf('.');
  if (puntoPos === -1) {
    return cadena;
  }
  const parteAntesDelPunto: string = cadena.substring(0, puntoPos);
  const subcadenaDespuesDelPunto: string = cadena.slice(puntoPos + 1);
  const espacioPos: number = subcadenaDespuesDelPunto.search(/\s/);
  let parteDespuesDelPunto: string;
  if (espacioPos === -1) {
    parteDespuesDelPunto = subcadenaDespuesDelPunto.trim();
  } else {
    parteDespuesDelPunto = subcadenaDespuesDelPunto.substring(0, espacioPos).trim();
  }
  return parteAntesDelPunto + '.' + parteDespuesDelPunto;
}

// Helper function to filter out "Sin subproceso"
const filterSubprocesos = (subprocesos: Subproceso[]): Subproceso[] => {
  return subprocesos.filter(sp => 
    (sp.nombre !== "Sin subproceso" && sp.nombre.trim() !== "") || 
    (sp.subproceso !== "Sin subproceso" && sp.subproceso?.trim() !== "")
  );
};
// Componente Modal

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center overflow-y-auto">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-6xl mx-4 max-h-[90vh] overflow-y-auto">
        <div className="px-6 py-4 border-b border-gray-200 flex justify-between items-center">
          <h3 className="text-xl font-semibold text-gray-800">{title}</h3>
          <button 
            onClick={onClose} 
            className="text-gray-400 hover:text-gray-600 transition-colors duration-200"
          >
            <X className="w-6 h-6" />
          </button>
        </div>
        <div className="p-6">
          {children}
        </div>
      </div>
    </div>
  );
};

// ===========================================
// Main Component
// ===========================================

const ProcessManagementSystem: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    id: 0,
    codigo: '',
    nombreProceso: '',
    anio: '',
    region: '',
    numeroRegion: '',
    comuna: '',
    numeroProceso: '',
    originalNumeroProceso: '',
    responsables_ids: [],
    etapa: '',
    subprocesos: [{ id: 1, nombre: '', color: '#000000', orden: 1 }],
  });
  const [hoveredProcessId, setHoveredProcessId] = useState<number | null>(null);
  const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });
  const modalRef = useRef<HTMLDivElement>(null);
  const [regions, setRegions] = useState<Region[]>([]);
  const [loadingRegions, setLoadingRegions] = useState(true);
  const [selectedRegion, setSelectedRegion] = useState<string>('');
  const [selectedComuna, setSelectedComuna] = useState<string>('');
  const [processes, setProcesses] = useState<string[]>([]);
  const [selectedProcess, setSelectedProcess] = useState<string>('');
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [message, setMessage] = useState<{ type: 'success' | 'error', message: string } | null>(null);
  const [isLoadingNumeroProceso, setIsLoadingNumeroProceso] = useState(false);
  const [originalFormData, setOriginalFormData] = useState<FormData | null>(null);
  const [loading, setLoading] = useState(false);
  const [viewMode, setViewMode] = useState<'form' | 'list'>('list');
  const [processList, setProcessList] = useState<Process[]>([]);
  
  // Pagination state for list view
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [sortField, setSortField] = useState<string>('nombreProceso');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState<'create' | 'edit'>('create');
  
  const [showTemplates, setShowTemplates] = useState(false);

  const [selectedProcessIds, setSelectedProcessIds] = useState<number[]>([]);
  const [isBulkProcessing, setIsBulkProcessing] = useState(false);
  
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    message: '',
    onConfirm: () => {}
  });


  // =========================================
  // List View Functions
  // =========================================
  
  const fetchProcessList = async () => {
    setLoading(true);
    try {
      // Paso 1: Obtener la lista de códigos de procesos
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/proceso/get_processes.php`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const processCodes = await response.json();
      
      // Paso 2: Para cada código, obtener los detalles completos
      const processesDetails = await Promise.all(
        processCodes.map(async (processString:string) => {
          // Extraer solo el código del string (antes de espacio o del primer espacio)
          const codigo = obtenerCodigo(processString);
          
          try {
            // Obtener detalles completos del proceso
            const detailResponse = await fetch(
              `${process.env.REACT_APP_API_URL}/php/pages/proceso/get_process_details.php?codigo=${codigo}`
            );
            
            if (!detailResponse.ok) {
              throw new Error(`Error fetching details for ${codigo}`);
            }
            
            const detailData = await detailResponse.json();
            
            // Si tenemos datos válidos
            if (detailData && detailData.data) {
              return {
                id: detailData.data.id || 0,
                codigo: detailData.data.codigo || codigo,
                nombreProceso: detailData.data.nombreProceso || processString.replace(codigo, '').trim(),
                anio: detailData.data.anio || '',
                region: detailData.data.region || '',
                numeroRegion: detailData.data.numeroRegion || '',
                comuna: detailData.data.comuna || '',
                numeroProceso: detailData.data.numeroProceso || '',
                etapa: detailData.data.etapa || '',
                isActive: detailData.data.isActive ?? true,
                subprocesos: Array.isArray(detailData.data.subprocesos) ? detailData.data.subprocesos : []
              };
            }
            
            // Si no hay datos válidos, crear un objeto con valores predeterminados
            return {
              id: Date.now() + Math.random(),
              codigo: codigo,
              nombreProceso: processString.replace(codigo, '').trim(),
              anio: '',
              region: '',
              numeroRegion: '',
              comuna: '',
              numeroProceso: '',
              etapa: '',
              isActive: true,
              subprocesos: []
            };
          } catch (error) {
            console.error(`Error fetching details for ${codigo}:`, error);
            // En caso de error, devolver objeto con valores predeterminados
            return {
              id: Date.now() + Math.random(),
              codigo: codigo,
              nombreProceso: processString.replace(codigo, '').trim(),
              anio: '',
              region: '',
              numeroRegion: '',
              comuna: '',
              numeroProceso: '',
              etapa: '',
              isActive: true,
              subprocesos: []
            };
          }
        })
      );
      
      console.log('Datos procesados completos:', processesDetails);
      setProcessList(processesDetails);
      setTotalItems(processesDetails.length);
    } catch (error) {
      console.error('Error fetching processes:', error);
      showLabelMessage('error', 'Error al cargar la lista de procesos');
    } finally {
      setLoading(false);
    }
  };


  

  const handleSelectTemplate = (subprocesos: TemplateSubproceso[]) => {
    setFormData(prevData => ({
      ...prevData,
      subprocesos: subprocesos.map(sp => ({
        ...sp,
        id: sp.id
      }))
    }));
    setShowTemplates(false);
    showLabelMessage('success', 'Plantilla aplicada correctamente');
  };
  
  // Función para cargar colores predefinidos en los subprocesos
  const handleLoadDefaultColors = () => {
    const predefinedColors = [
      '#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8', '#F7DC6F', '#C39BD3'
    ];
    
    setFormData(prevData => {
      let colorIndex = 0;
      const updatedSubprocesos = prevData.subprocesos.map(sp => {
        if (sp.color === '#000000' || sp.color === '') {
          let newColor;
          if (colorIndex < predefinedColors.length) {
            newColor = predefinedColors[colorIndex];
            colorIndex++;
          } else {
            // Si se acabaron los colores predefinidos, generar uno aleatorio
            const hue = Math.floor(Math.random() * 360);
            newColor = `hsl(${hue}, 70%, 80%)`;
          }
          return { ...sp, color: newColor };
        }
        return sp;
      });
  
      return {
        ...prevData,
        subprocesos: updatedSubprocesos
      };
    });
  };
  
  // Función para limpiar todos los colores
  const handleClearAllColors = () => {
    setFormData(prevData => ({
      ...prevData,
      subprocesos: prevData.subprocesos.map(sp => ({
        ...sp,
        color: '#000000'
      }))
    }));
  };
  const handleToggleSelectProcess = (processId: number) => {
    setSelectedProcessIds(prev => {
      if (prev.includes(processId)) {
        return prev.filter(id => id !== processId);
      } else {
        return [...prev, processId];
      }
    });
  };
  
  
  const handleSelectAllProcesses = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      // Seleccionar todos los procesos de la página actual
      const currentPageIds = paginatedProcesses.map(process => process.id);
      setSelectedProcessIds(currentPageIds);
    } else {
      // Deseleccionar todos
      setSelectedProcessIds([]);
    }
  };
  
  const handleBulkToggleStatus = async (newStatus: boolean) => {
    if (selectedProcessIds.length === 0) return;
    
    const actionText = newStatus ? 'habilitar' : 'deshabilitar';
    const confirmMessage = `¿Estás seguro de que deseas ${actionText} ${selectedProcessIds.length > 1 ? 
      'estos ' + selectedProcessIds.length + ' procesos' : 'este proceso'}?`;
    
    // Open confirm dialog instead of window.confirm
    setConfirmDialog({
      isOpen: true,
      title: `Confirmar ${actionText} procesos`,
      message: confirmMessage,
      onConfirm: async () => {
        setIsBulkProcessing(true);
        
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/proceso/toggle_process_status.php`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
              ids: selectedProcessIds,
              status: newStatus 
            })
          });
          
          const data = await response.json();
          
          if (data.success) {
            // Actualizar la lista de procesos
            setProcessList(prevProcesses => 
              prevProcesses.map(process => 
                selectedProcessIds.includes(process.id) ? { ...process, isActive: newStatus } : process
              )
            );
            
            // Notificar al usuario
            toast.success(`${data.count || selectedProcessIds.length} ${selectedProcessIds.length === 1 ? 
              'proceso' : 'procesos'} ${newStatus ? 'habilitados' : 'deshabilitados'} exitosamente`);
            
            // Limpiar selección
            setSelectedProcessIds([]);
          } else {
            toast.error(`Error: ${data.error || 'No se pudieron procesar los cambios'}`);
          }
        } catch (error) {
          console.error('Error processing bulk action:', error);
          toast.error('Error al procesar la solicitud');
        } finally {
          setIsBulkProcessing(false);
        }
      }
    });
  };
  
  
  const handleToggleProcessStatus = async (id: number, currentStatus: boolean) => {
    // Open confirm dialog instead of window.confirm
    setConfirmDialog({
      isOpen: true,
      title: currentStatus ? 'Deshabilitar proceso' : 'Habilitar proceso',
      message: `¿Estás seguro de que deseas ${currentStatus ? 'deshabilitar' : 'habilitar'} este proceso?`,
      onConfirm: async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/proceso/toggle_process_status.php`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id, status: !currentStatus })
          });
  
          const data = await response.json();
  
          if (data.success) {
            setProcessList(prevProcesses =>
              prevProcesses.map(process =>
                process.id === id ? { ...process, isActive: !currentStatus } : process
              )
            );
            toast.success('Estado del proceso actualizado exitosamente');
          } else {
            throw new Error(data.error || 'Error al actualizar el estado del proceso');
          }
        } catch (error) {
          console.error('Error:', error);
          toast.error('Hubo un error al actualizar el estado del proceso');
        }
      }
    });
  };
  
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleSort = (field: string) => {
    if (field === sortField) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const handleEditProcess = (codigo: string, isActive: boolean) => {
    if (!isActive) {
      toast.error('No se puede editar un proceso inactivo. Actívalo primero.');
      return;
    }
    
    const processCode = obtenerCodigo(codigo);
    fetchProcessDetails(processCode);
    setModalMode('edit');
    setIsModalOpen(true);
  };
  
  // Modifica la función handleNewProcess
  const handleNewProcess = () => {
    resetForm();
    setModalMode('create');
    setIsModalOpen(true);
  };
  
  // Función para cerrar el modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
    if (modalMode === 'create') {
      resetForm();
    }
  };
  


  // =========================================
  // Form View Functions
  // =========================================

  const handleDragStart = (e: React.DragEvent, fromIndex: number) => {
    e.dataTransfer.setData('text/plain', fromIndex.toString());
  };
  
  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };
  
  const handleDrop = (e: React.DragEvent, toIndex: number) => {
    e.preventDefault();
    const fromIndex = parseInt(e.dataTransfer.getData('text/plain'));
    
    setFormData(prevData => {
      const newSubprocesos = [...prevData.subprocesos];
      const [movedItem] = newSubprocesos.splice(fromIndex, 1);
      newSubprocesos.splice(toIndex, 0, movedItem);
      
      return {
        ...prevData,
        subprocesos: newSubprocesos.map((sp, idx) => ({ ...sp, orden: idx + 1 }))
      };
    });
  };
  
  const handleCancel = () => {
    if (originalFormData) {
      setFormData(originalFormData);
      setSelectedComuna(originalFormData.comuna);
      setSelectedRegion(originalFormData.numeroRegion);
      setSelectedUsers([]);
    } else {
      setViewMode('list');
    }
  };

  const resetForm = () => {
    setFormData({
      id: 0, 
      codigo: '',
      nombreProceso: '',
      anio: '',
      region: '',
      numeroRegion: '',
      comuna: '',
      numeroProceso: '',
      originalNumeroProceso: '',
      etapa: '',
      responsables_ids: [],
      subprocesos: [{ id: 1, nombre: '', color: '#000000', orden: 1 }],
    });
    setSelectedComuna('');
    setSelectedRegion('');
    setSelectedProcess('');
    setOriginalFormData(null);
  };

  const generateCodigo = useCallback((data: FormData): string => {
    const { nombreProceso, anio, numeroRegion, numeroProceso, etapa } = data;
    const nombreProcesoAbreviada = nombreProceso ? nombreProceso.substring(0, 3).toUpperCase() : '';
    const anioAbreviado = anio ? anio.substring(2) : '';
    const etapaRoman = decimalToRoman(parseInt(etapa, 10) || 0);
    return `${anioAbreviado}${numeroRegion}${numeroProceso}.${etapaRoman}`;
  }, []);
  
  const debouncedFetchNumeroProceso = useCallback(
    debounce(async (region: string, anio: string) => {
      if (!region || !anio) return null;
  
      try {
        setIsLoadingNumeroProceso(true);
        const needsNewNumber = formData.id && region !== formData.region;
  
        const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/proceso/consultarnoproceso.php`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ 
            region: parseInt(region, 10), 
            anio,
            isEditing: !needsNewNumber && Boolean(formData.id)
          }),
          
        });
  
        const result = await response.json();
        if (result.success) {
          const newNumeroProceso = "0" + result.numeroProceso;
          setFormData((prevData) => {
            if (formData.id && region === formData.region) {
              return {
                ...prevData,
                anio,
                codigo: generateCodigo({ ...prevData, anio })
              };
            }
            return {
              ...prevData,
              numeroProceso: newNumeroProceso,
              codigo: generateCodigo({ ...prevData, numeroProceso: newNumeroProceso, anio })
            };
          });
        }
      } catch (error) {
        console.error('Error fetching numeroProceso:', error);
      } finally {
        setIsLoadingNumeroProceso(false);
      }
    }, 500),
    [formData.id, formData.region, generateCodigo]
  );
  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
  
    setFormData(prevData => {
      const updatedData = { 
        ...prevData, 
        [name]: name === 'nombreProceso' ? value.toUpperCase() : value 
      };
  
      if (name === 'anio') {
        if (prevData.region === updatedData.region) {
          updatedData.codigo = generateCodigo(updatedData);
        } 
        if (updatedData.region) {
          debouncedFetchNumeroProceso(updatedData.region, value);
        }
      } else if (name === 'region' && updatedData.anio) {
        debouncedFetchNumeroProceso(value, updatedData.anio);
      }
  
      if (
        name === 'nombreProceso' || 
        name === 'numeroRegion' || 
        name === 'numeroProceso' || 
        name === 'etapa'
      ) {
        updatedData.codigo = generateCodigo(updatedData);
      }
  
      return updatedData;
    });
  }, [debouncedFetchNumeroProceso, generateCodigo]);
  
  const showLabelMessage = (type: 'success' | 'error', message: string, duration = 3500) => {
    setMessage({ type, message });
  
    // Mostrar toast
    if (type === 'success') {
      toast.success(message, { duration });
    } else {
      toast.error(message, { duration });
    }
  
    // Limpiar mensaje del label después de cierto tiempo
    setTimeout(() => {
      setMessage(null);
    }, duration);
  };

  const fetchProcessDetails = async (codigo: string) => {
    setLoading(true);
    setOriginalFormData(null);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/proceso/get_process_details.php?codigo=${codigo}`);
      
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      
      const originalNumeroProceso = data.data?.numeroProceso || '';
      const numeroProceso = originalNumeroProceso.startsWith('0') 
        ? originalNumeroProceso 
        : `0${originalNumeroProceso}`;

      const region = regions.find(r => r.comunas.includes(data.data?.comuna));
      const numeroRegion = region?.numero || data.data?.numeroRegion || '';

      const newFormData: FormData = {
        id: data.data?.id || 0,
        codigo: data.data?.codigo || '',
        nombreProceso: data.data?.nombreProceso || '',
        anio: data.data?.anio || '',
        comuna: data.data?.comuna || '',
        region: data.data?.region || '',
        numeroRegion: numeroRegion,
        numeroProceso: numeroProceso,
        originalNumeroProceso: numeroProceso,
        etapa: data.data?.etapa || '',
        responsables_ids: data.data?.responsables_ids ? data.data.responsables_ids.split(',').map(Number) : [],
        subprocesos: (data.data?.subprocesos || [])
          .map((subproceso: { id: number, subproceso: string, color: string, orden: number }, index: number) => ({
            id: index + 1,
            globalId: subproceso.id,
            nombre: subproceso.subproceso || '',
            color: subproceso.color || '#000000',
            orden: subproceso.orden || index + 1
          }))
          .sort((a: Subproceso, b: Subproceso) => a.orden - b.orden),
        isActive: data.data?.isActive
      };

      setFormData(newFormData);
      setOriginalFormData(newFormData);
      
      setSelectedComuna(data.data?.comuna || '');
      setSelectedRegion(numeroRegion);
      setSelectedUsers([]);

    } catch (error) {
      console.error('Error fetching process details:', error);
      showLabelMessage('error', 'Error al cargar los detalles del proceso');
    } finally {
      setLoading(false);
    }
  };

  const handleProcessSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    const process = event.target.value;
    setSelectedProcess(process);
    const codigo = obtenerCodigo(process);
    fetchProcessDetails(codigo);
  };

  const handleComunaChange = useCallback((comuna: string) => {
    setSelectedComuna(comuna);
    
    if (comuna === '') {
      return;
    }

    const region = regions.find(r => r.comunas.includes(comuna));
    if (region) {
      setSelectedRegion(region.numero);
      
      if (formData.id) {
        if (originalFormData && region.region === originalFormData.region) {
          setFormData(prevData => ({
            ...prevData,
            comuna: comuna,
            region: region.region,
            numeroRegion: region.numero,
            numeroProceso: originalFormData.numeroProceso,
            codigo: generateCodigo({ 
              ...prevData, 
              numeroProceso: originalFormData.numeroProceso,
              region: region.region,
              numeroRegion: region.numero 
            })
          }));
        } else {
          setFormData(prevData => {
            const newData = {
              ...prevData,
              comuna: comuna,
              region: region.region,
              numeroRegion: region.numero,
            };
            
            if (formData.anio) {
              debouncedFetchNumeroProceso(region.numero, formData.anio);
            }
            
            return newData;
          });
        }
      } else {
        setFormData(prevData => {
          const newData = {
            ...prevData,
            comuna: comuna,
            region: region.region,
            numeroRegion: region.numero,
          };

          if (formData.anio) {
            debouncedFetchNumeroProceso(region.numero, formData.anio);
          }

          return newData;
        });
      }
    }
  }, [regions, formData.id, formData.anio, originalFormData, generateCodigo, debouncedFetchNumeroProceso]);

  const handleAddSubproceso = () => {
    setFormData(prevData => ({
      ...prevData,
      subprocesos: [
        ...prevData.subprocesos,
        { 
          id: prevData.subprocesos.length + 1, 
          nombre: '', 
          color: '#000000', 
          orden: prevData.subprocesos.length + 1
        }
      ],
    }));
  };
  
  const handleRemoveSubproceso = (id: number) => {
    setFormData(prevData => ({
      ...prevData,
      subprocesos: prevData.subprocesos.filter(sp => sp.id !== id),
    }));
  };
  
  const handleSubprocesoChange = (id: number, field: 'nombre' | 'color', value: string) => {
    setFormData(prevData => ({
      ...prevData,
      subprocesos: prevData.subprocesos.map(sp =>
        sp.id === id ? { ...sp, [field]: value } : sp
      ),
    }));
  };

  const handleSubmit = useCallback(async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  
    if (!formData.nombreProceso || !formData.anio || !formData.region || !formData.numeroRegion || !formData.numeroProceso || !formData.etapa) {
      showLabelMessage('error', 'Por favor, complete todos los campos requeridos.');
      return;
    }
  
    const dataToSend = {
      ...formData,
      responsables_ids: selectedUsers.map(user => user.id),
      subprocesos: formData.subprocesos
        .filter(sp => sp.nombre.trim() !== '')
        .map((sp, index) => ({ 
          id: sp.globalId,
          nombre: sp.nombre,
          color: sp.color,
          orden: index + 1 
        }))
    };
    
    // Determine the endpoint based on the modal mode
    const endpoint = modalMode === 'create' 
      ? `${process.env.REACT_APP_API_URL}/php/pages/proceso/crearproceso.php` 
      : `${process.env.REACT_APP_API_URL}/php/pages/proceso/update_process.php`;
    
    setIsSaving(true);
  
    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
      });
  
      const data = await response.json();
  
      if (data.success) {
       // showLabelMessage('success', modalMode === 'create' ? 'Proceso creado exitosamente' : 'Datos actualizados exitosamente');
       toast.success(modalMode === 'create' ? 'Proceso creado exitosamente' : 'Datos actualizados exitosamente');
        resetForm();
        setIsSaving(false);
        setSelectedUsers([]);
        fetchProcessList();
        setViewMode('list');
        setIsModalOpen(false); // Cerrar el modal después de guardar
      } else {
        showLabelMessage('error', `Hubo un error al ${modalMode === 'create' ? 'crear' : 'actualizar'} los datos: ${data.error}`);
      }
    } catch (error) {
      console.error('Error:', error);
      showLabelMessage('error', `Hubo un error al ${modalMode === 'create' ? 'crear' : 'actualizar'} los datos`);
    } finally {
      setIsSaving(false);
    }
  }, [formData, resetForm, selectedUsers, modalMode]);
  
  const handleMoveSubproceso = (index: number, direction: 'up' | 'down') => {
    setFormData(prevData => {
      const newSubprocesos = [...prevData.subprocesos];
      if (direction === 'up' && index > 0) {
        [newSubprocesos[index - 1], newSubprocesos[index]] = [newSubprocesos[index], newSubprocesos[index - 1]];
      } else if (direction === 'down' && index < newSubprocesos.length - 1) {
        [newSubprocesos[index], newSubprocesos[index + 1]] = [newSubprocesos[index + 1], newSubprocesos[index]];
      }
      return { 
        ...prevData, 
        subprocesos: newSubprocesos.map((sp, idx) => ({ ...sp, orden: idx + 1 }))
      };
    });
  };

  const predefinedColors = [
    '#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8', '#F7DC6F', '#C39BD3'
  ];

  const generatePastelColor = () => {
    const hue = Math.floor(Math.random() * 360);
    return `hsl(${hue}, 70%, 80%)`;
  };



  // =========================================
  // Effects and Initialization
  // =========================================

  useEffect(() => {
    setSelectedProcessIds([]);
  }, [currentPage, searchTerm, sortField, sortDirection]);
  

  useEffect(() => {
    fetchProcessList();
    fetchRegions();
    fetchUsers();
    fetchProcesses();
  }, []);

  useEffect(() => {
    if (formData.region && formData.anio) {
      if (!formData.id && !loading) {
        debouncedFetchNumeroProceso(formData.region, formData.anio);
      }
    }
  }, [formData.region, formData.anio, formData.id, debouncedFetchNumeroProceso, loading]);

  useEffect(() => {
    if (selectedComuna === '') {
      if (!loading && !formData.id) {
        setFormData(prevData => ({
          ...prevData,
          comuna: '',
          region: '',
          numeroRegion: '',
        }));
      }
      return;
    }
  
    const region = regions.find(region => region.comunas.includes(selectedComuna));
    if (region) {
      if (formData.id) {
        if (originalFormData && region.region === originalFormData.region) {
          setFormData(prevData => ({
            ...prevData,
            comuna: selectedComuna,
            region: region.region,
            numeroRegion: region.numero,
            numeroProceso: originalFormData.numeroProceso,
            codigo: generateCodigo({
              ...prevData,
              numeroProceso: originalFormData.numeroProceso,
              region: region.region,
              numeroRegion: region.numero
            })
          }));
        } else {
          setFormData(prevData => {
            const newData = {
              ...prevData,
              comuna: selectedComuna,
              region: region.region,
              numeroRegion: region.numero,
            };
  
            if (prevData.anio) {
              debouncedFetchNumeroProceso(region.numero, prevData.anio);
            }
  
            return newData;
          });
        }
      } else {
        setFormData(prevData => {
          const newData = {
            ...prevData,
            comuna: selectedComuna,
            region: region.region,
            numeroRegion: region.numero,
          };
  
          if (prevData.anio && !loading) {
            debouncedFetchNumeroProceso(region.numero, prevData.anio);
          }
  
          return newData;
        });
      }
    }
  }, [selectedComuna, regions, loading, formData.id, originalFormData, generateCodigo, debouncedFetchNumeroProceso]);

  useEffect(() => {
    if (selectedRegion) {
      const region = regions.find(r => r.numero === selectedRegion);
      if (region) {
        setFormData(prevData => ({
          ...prevData,
          region: region.region,
          numeroRegion: region.numero,
        }));
        if (!formData.id && !loading && formData.anio) {
          debouncedFetchNumeroProceso(region.numero, formData.anio);
        }
      }
    }
  }, [selectedRegion, regions, formData.anio, formData.id, debouncedFetchNumeroProceso, loading]);

  // =========================================
  // Fetch Data Functions
  // =========================================

  const fetchRegions = async () => {
    try {
      const response = await import('../../data/regiones.json');
      const data = response.default as { regiones: Region[] };
      setRegions(data.regiones);
      setLoadingRegions(false);
    } catch (error) {
      console.error('Error fetching regions:', error);
    }
  };

  const fetchProcesses = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/proceso/get_processes.php`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log('Datos para selector de procesos:', data);
      setProcesses(data);
    } catch (error) {
      console.error('Error fetching processes:', error);
      showLabelMessage('error', 'Error al cargar la lista de procesos para el selector');
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/users/get_users.php`);
      if (!response.ok) throw new Error('Error fetching users');
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.error('Error fetching users:', error);
      showLabelMessage('error', 'Error al cargar la lista de usuarios');
    }
  };

  const handleRegionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const regionNumero = event.target.value;
    setSelectedRegion(regionNumero);
    
    const region = regions.find(r => r.numero === regionNumero);
    if (region) {
      setFormData((prevData) => {
        const newData = {
          ...prevData,
          region: region.region,
          numeroRegion: region.numero,
        };
        
        if (!prevData.id && !loading && prevData.anio) {
          debouncedFetchNumeroProceso(regionNumero, prevData.anio);
        }
        
        return newData;
      });
    } else {
      setFormData((prevData) => ({
        ...prevData,
        region: '',
        numeroRegion: '',
        numeroProceso: '',
        codigo: '',
      }));
    }
  };
  
  // =========================================
  // Render Helpers
  // =========================================
  
// Modifica solo el efecto para calcular la posición
// Esto posicionará el modal ENCIMA de la celda en lugar de debajo
useEffect(() => {
  if (hoveredProcessId !== null) {
    // Busca la fila por atributo de datos
    const processRow = document.querySelector(`tr[data-process-id="${hoveredProcessId}"]`);
    if (!processRow) return;
    
    // Encuentra la celda de subprocesos (ajusta el índice si es necesario)
    const subprocessCell = processRow.querySelector('td:nth-child(10)');
    if (!subprocessCell) return;
    
    // Obtiene la posición relativa al viewport
    const rect = subprocessCell.getBoundingClientRect();
    
    // Posiciona el modal debajo de la celda
    setModalPosition({
      x: rect.left,
      y: rect.top + 5 // 5px de espacio
    });
  }
}, [hoveredProcessId]);
// Para asegurar que el modal se ajuste si se muestra muy cerca de la parte superior de la pantalla,
// modifica la función renderSubprocessModal:

const renderSubprocessModal = () => {
  if (hoveredProcessId === null) return null;
  
  // Busca los datos del proceso
  const process = processList.find(p => p.id === hoveredProcessId);
  if (!process || !process.subprocesos || process.subprocesos.length === 0) return null;
  
  // Determina si el modal debe mostrarse debajo en lugar de arriba
  // si está muy cerca de la parte superior de la ventana
  const isTooCloseToTop = modalPosition.y < 10;
  
  // Encuentra la celda para calcular su posición si necesitamos ajustar
  const processRow = document.querySelector(`tr[data-process-id="${hoveredProcessId}"]`);
  const subprocessCell = processRow?.querySelector('td:nth-child(10)');
  const rect = subprocessCell?.getBoundingClientRect();
  
  const finalY = isTooCloseToTop && rect 
    ? rect.bottom + 5 // Si está muy arriba, mostrar debajo en su lugar
    : modalPosition.y;
  
    return (
      <div 
        ref={modalRef}
        className="fixed bg-white rounded-md shadow-xl p-3 border border-gray-300 min-w-[250px] max-w-[350px]"
        style={{
          zIndex: 9999,
          left: `${modalPosition.x}px`,
          top: `${finalY}px`,
          pointerEvents: 'auto'
        }}
        onMouseEnter={() => {
          // Mantiene el modal abierto cuando el mouse está dentro
          if (hoveredProcessId) setHoveredProcessId(hoveredProcessId);
        }}
        onMouseLeave={() => setHoveredProcessId(null)} // Cierre inmediato sin setTimeout
      >
        <h3 className="text-sm font-semibold mb-2 pb-1 border-b">Subprocesos</h3>
        <div className="space-y-1 max-h-60 overflow-y-auto">
          {process.subprocesos &&
          process.subprocesos.filter(
            sp => sp.nombre !== "Sin subproceso" && sp.subproceso !== "Sin subproceso"
          ).length > 0 ? (
            process.subprocesos
              .filter(sp => sp.nombre !== "Sin subproceso" && sp.subproceso !== "Sin subproceso")
              .map((sp, index) => (
                <div key={index} className="flex items-center text-sm py-1">
                  <div
                    className="w-4 h-4 rounded-full mr-3 flex-shrink-0"
                    style={{ backgroundColor: sp.color || '#000000' }}
                  ></div>
                  <span className="truncate">{sp.nombre || sp.subproceso || '-'}</span>
                </div>
              ))
          ) : (
            <div className="text-xs text-gray-500">Sin subprocesos</div>
          )}
        </div>
      </div>
    );
    
};

const renderSubprocesoFields = () => {
  return formData.subprocesos
    .filter(sp => sp.nombre !== "Sin subproceso")
    .map((subproceso, index) => (
    
      <div 
        key={subproceso.id} 
        className="flex items-center mb-2"
        draggable={true}
        onDragStart={(e) => handleDragStart(e, index)}
        onDragOver={(e) => handleDragOver(e)}
        onDrop={(e) => handleDrop(e, index)}
      >
        <div className="flex ml-2">
          <div className="flex space-x-2 items-center">
            <List className="w-5 h-5 cursor-move text-gray-400 hover:text-gray-600 mr-2" />
            <button 
              type="button" 
              onClick={() => handleMoveSubproceso(index, 'up')}
              disabled={index === 0}
              className={`flex items-center justify-center w-10 h-10 border border-gray-300 rounded-md bg-white text-gray-500 hover:text-gray-700 transition-colors duration-200 ${index === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              <ArrowUp className="w-5 h-5" />
            </button>
            <button 
              type="button" 
              onClick={() => handleMoveSubproceso(index, 'down')}
              disabled={index === formData.subprocesos.length - 1}
              className={`flex items-center justify-center w-10 h-10 border border-gray-300 rounded-md bg-white text-gray-500 hover:text-gray-700 transition-colors duration-200 ${index === formData.subprocesos.length - 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              <ArrowDown className="w-5 h-5" />
            </button>
          </div>
        </div>
        <div className="relative ml-5">
          <input
            type="color"
            value={subproceso.color}
            onChange={e => handleSubprocesoChange(subproceso.id, 'color', e.target.value)}
            className="absolute top-0 left-0 opacity-0 w-6 h-6 cursor-pointer"
          />
          <div 
            className="w-6 h-6 rounded-full mr-2 border border-gray-300 cursor-pointer" 
            style={{ backgroundColor: subproceso.color }}
          ></div>
        </div>
        <input
          type="text"
          value={subproceso.nombre}
          onChange={e => handleSubprocesoChange(subproceso.id, 'nombre', e.target.value)}
          className="flex-grow p-2 border border-gray-300 rounded text-sm"
          placeholder="Nombre del subproceso"
        />
        <button 
          type="button" 
          onClick={() => handleRemoveSubproceso(subproceso.id)} 
          className="ml-2 text-red-500 hover:text-red-700 transition-colors duration-200"
        >
          <MinusCircle className="w-5 h-5" />
        </button>
      </div>
    ));
  };

  const renderNumeroProceso = () => (
    <div className="flex flex-col">
      <label htmlFor="numeroProceso" className="text-sm font-semibold mb-2">
        Número de proceso
      </label>
      <div className="relative">
        <input
          id="numeroProceso"
          name="numeroProceso"
          type="text"
          value={formData.numeroProceso}
          onChange={handleInputChange}
          className="p-2 w-full border border-gray-300 rounded bg-gray-100 cursor-not-allowed"
          disabled
        />
        {isLoadingNumeroProceso && (
          <div className="absolute right-2 top-1/2 transform -translate-y-1/2">
            <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-blue-500"></div>
          </div>
        )}
      </div>
    </div>
  );

  // Filter and paginate processes for list view
  const filteredProcesses = processList.filter(process => {
    const searchRegex = new RegExp(searchTerm, 'i');
    return (
      searchRegex.test(process.codigo) ||
      searchRegex.test(process.nombreProceso) ||
      searchRegex.test(process.comuna) ||
      searchRegex.test(process.region)
    );
  });

  // Sort filtered processes
  const sortedProcesses = [...filteredProcesses].sort((a, b) => {
    const aValue = a[sortField as keyof Process];
    const bValue = b[sortField as keyof Process];
    
    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return sortDirection === 'asc'
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    }
    
    // Fallback for non-string comparisons
    return sortDirection === 'asc'
      ? String(aValue).localeCompare(String(bValue))
      : String(bValue).localeCompare(String(aValue));
  });

  // Paginate sorted processes
  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedProcesses = sortedProcesses.slice(startIndex, startIndex + itemsPerPage);
  const totalPages = Math.ceil(filteredProcesses.length / itemsPerPage);
  

  // Al inicio del componente
  useEffect(() => {
    console.log('Estado actual del componente:', {
      viewMode,
      loadingState: loading,
      processListLength: processList.length,
      paginatedProcessesLength: paginatedProcesses.length,
      filteredProcessesLength: filteredProcesses.length
    });
  }, [viewMode, loading, processList, paginatedProcesses, filteredProcesses]);
  
  // =========================================
  // Main Render
  // =========================================
  

  const BulkActionBar = () => {
    if (selectedProcessIds.length === 0) return null;
    
    return (
      <div className="p-3 bg-gray-100 border-b flex justify-between items-center mb-2 rounded-t">
        <div className="text-sm">
          <span className="font-medium">{selectedProcessIds.length}</span> {selectedProcessIds.length === 1 ? 'proceso seleccionado' : 'procesos seleccionados'}
        </div>
        <div className="flex space-x-2">
          <button 
            onClick={() => handleBulkToggleStatus(true)}
            disabled={isBulkProcessing}
            className="bg-green-500 hover:bg-green-700 text-white text-sm py-1 px-3 rounded flex items-center transition-colors duration-300"
          >
            <Eye className="w-4 h-4 mr-1" />
            Habilitar
          </button>
          <button 
            onClick={() => handleBulkToggleStatus(false)}
            disabled={isBulkProcessing}
            className="bg-red-500 hover:bg-red-700 text-white text-sm py-1 px-3 rounded flex items-center transition-colors duration-300"
          >
            <X className="w-4 h-4 mr-1" />
            Deshabilitar
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="max-w-7xl mx-auto p-6 bg-white rounded-lg shadow-md">
    {/* Header with navigation */}


    {message && <LabelMessage type={message.type} message={message.message} />}

    {/* Loading indicator */}
    {/* {loading && (
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
        <div className="bg-white p-4 rounded-lg shadow-lg flex items-center space-x-3">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
          <span className="text-gray-700">Cargando información...</span>
        </div>
      </div>
    )} */}

    {/* List View */}
    <div>
{/* Barra superior con búsqueda y botones alineados */}
<div className="mb-6 flex flex-col md:flex-row justify-between items-center gap-4">
  <div className="flex w-full md:w-auto justify-between items-center gap-4">
    {/* Buscador */}
    <div className="relative flex-grow max-w-md">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <Search className="h-5 w-5 text-gray-400" />
      </div>
      <input
        type="text"
        placeholder="Buscar procesos..."
        className="pl-10 pr-4 py-2 w-full border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        value={searchTerm}
        onChange={handleSearch}
      />
    </div>
    
    {/* Botón crear */}
    <button 
      onClick={() => {
        resetForm();
        setModalMode('create');
        setIsModalOpen(true);
      }}
      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg flex items-center transition-colors duration-300 whitespace-nowrap"
    >
      <PlusCircle className="w-5 h-5 mr-2" /> Crear Nuevo Proceso
    </button>
  </div>
  
  {/* Selector de items por página */}
  <div className="flex gap-2 mt-2 md:mt-0">
    <select 
      className="px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
      value={itemsPerPage.toString()}
      onChange={(e) => setItemsPerPage(Number(e.target.value))}
    >
      <option value="10">10 por página</option>
      <option value="25">25 por página</option>
      <option value="50">50 por página</option>
      <option value="100">100 por página</option>
    </select>
  </div>
</div>

{/* Procesos table - Modificado con subprocesos verticales y región con salto de línea */}
{/* Procesos table con numeración y checkboxes */}
<div className="overflow-x-auto bg-white rounded-lg shadow w-full">
<BulkActionBar />


  <table className="w-full table-fixed divide-y divide-gray-200">
  <thead className="bg-cyan-400">
  <tr>
 
  <th scope="col" className="px-2 py-3 text-center text-xs font-medium text-white uppercase tracking-wider w-[3%]">
      <input
        type="checkbox"
        className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
        checked={selectedProcessIds.length > 0 && selectedProcessIds.length === paginatedProcesses.length}
        onChange={handleSelectAllProcesses}
      />
    </th>
    <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-white uppercase tracking-wider w-[5%]">
      #
    </th>
        <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-white uppercase tracking-wider w-[9%]">
          <div 
            className="flex items-center cursor-pointer"
            onClick={() => handleSort('codigo')}
          >
            Código
            <ArrowUpDown className="ml-1 h-4 w-4" />
          </div>
        </th>
        <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-white uppercase tracking-wider w-[18%]">
          <div 
            className="flex items-center cursor-pointer"
            onClick={() => handleSort('nombreProceso')}
          >
            Nombre
            <ArrowUpDown className="ml-1 h-4 w-4" />
          </div>
        </th>
        <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-white uppercase tracking-wider w-[6%]">
          <div 
            className="flex items-center cursor-pointer"
            onClick={() => handleSort('anio')}
          >
            Año
            <ArrowUpDown className="ml-1 h-4 w-4" />
          </div>
        </th>
        <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-white uppercase tracking-wider w-[9%]">
          <div 
            className="flex items-center cursor-pointer"
            onClick={() => handleSort('comuna')}
          >
            Comuna
            <ArrowUpDown className="ml-1 h-4 w-4" />
          </div>
        </th>
        <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-white uppercase tracking-wider w-[10%]">
          <div 
            className="flex items-center cursor-pointer"
            onClick={() => handleSort('region')}
          >
            Región
            <ArrowUpDown className="ml-1 h-4 w-4" />
          </div>
        </th>
        <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-white uppercase tracking-wider w-[7%]">
          <div 
            className="flex items-center cursor-pointer"
            onClick={() => handleSort('numeroProceso')}
          >
            N° Proc.
            <ArrowUpDown className="ml-1 h-4 w-4" />
          </div>
        </th>
        <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-white uppercase tracking-wider w-[4%]">
          <div 
            className="flex items-center cursor-pointer"
            onClick={() => handleSort('etapa')}
          >
            Etapa
            <ArrowUpDown className="ml-1 h-4 w-4" />
          </div>
        </th>
        <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-white uppercase tracking-wider w-[14%]">
          Subprocesos
        </th>
        <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-white uppercase tracking-wider w-[7%]">
          Estado
        </th>
        <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-white uppercase tracking-wider w-[8%]">
          Acciones
        </th>
      </tr>
    </thead>
    {/* animacion de carga */}
    <tbody className="bg-white divide-y divide-gray-200">
      {loading ? (
        Array(5).fill(0).map((_, idx) => (
          <tr key={idx} className="animate-pulse">
            <td className="px-2 py-4">
              <div className="h-4 bg-gray-200 rounded w-4"></div>
            </td>
            <td className="px-2 py-4">
              <div className="h-4 bg-gray-200 rounded w-6"></div>
            </td>
            <td className="px-3 py-4">
              <div className="h-4 bg-gray-200 rounded w-20"></div>
            </td>
            <td className="px-3 py-4">
              <div className="h-4 bg-gray-200 rounded w-40"></div>
            </td>
            <td className="px-3 py-4">
              <div className="h-4 bg-gray-200 rounded w-12"></div>
            </td>
            <td className="px-3 py-4">
              <div className="h-4 bg-gray-200 rounded w-24"></div>
            </td>
            <td className="px-3 py-4">
              <div className="h-4 bg-gray-200 rounded w-32"></div>
            </td>
            <td className="px-3 py-4">
              <div className="h-4 bg-gray-200 rounded w-12"></div>
            </td>
            <td className="px-3 py-4">
              <div className="h-4 bg-gray-200 rounded w-8"></div>
            </td>
            <td className="px-3 py-4">
              <div className="flex space-x-1">
                <div className="h-6 bg-gray-200 rounded w-16"></div>
                <div className="h-6 bg-gray-200 rounded w-16"></div>
              </div>
            </td>
            <td className="px-3 py-4">
              <div className="h-6 bg-gray-200 rounded w-16"></div>
            </td>
            <td className="px-3 py-4">
              <div className="flex space-x-2">
                <div className="h-8 bg-gray-200 rounded w-8"></div>
                <div className="h-8 bg-gray-200 rounded w-8"></div>
              </div>
            </td>
          </tr>
        ))
      ) : paginatedProcesses.length === 0 ? (
        <tr>
          <td colSpan={12} className="px-3 py-10 text-center text-gray-500">
            No se encontraron procesos
          </td>
        </tr>
      ) : (
        paginatedProcesses.map((process, index) => (
<tr 
  key={process.id} 
  data-process-id={process.id} 

  className={`
    hover:bg-gray-50 
    ${selectedProcessIds.includes(process.id) ? 'bg-blue-50' : ''}
    ${!process.isActive ? 'opacity-60 bg-gray-100' : ''}
  `}
>
  <td className="px-2 py-4 text-center">
    <input
      type="checkbox"
      className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
      checked={selectedProcessIds.includes(process.id)}
      onChange={() => handleToggleSelectProcess(process.id)}
    />
  </td>
  <td className="px-2 py-4 text-sm text-gray-500 font-medium">
    {startIndex + index + 1}
  </td>
  <td className="px-3 py-4 truncate">
    <div className="text-sm font-medium text-gray-900">{process.codigo || '-'}</div>
  </td>
  <td className="px-3 py-4 truncate">
    <div className="text-sm text-gray-900">{process.nombreProceso || '-'}</div>
  </td>
  <td className="px-3 py-4 truncate">
    <div className="text-sm text-gray-900">{process.anio || '-'}</div>
  </td>
  <td className="px-3 py-4 truncate">
    <div className="text-sm text-gray-900">{process.comuna || '-'}</div>
  </td>
  <td className="px-3 py-4">
  <div className="text-sm text-gray-900 break-words">
    {regions.find(r => r.numero === process.region)?.region || process.region || '-'}
  </div>  </td>
  <td className="px-3 py-4 truncate">
    <div className="text-sm text-gray-900">{process.numeroProceso || '-'}</div>
  </td>
  <td className="px-3 py-4">
    <div className="text-sm text-gray-900">{process.etapa ? decimalToRoman(parseInt(process.etapa)) : '-'}</div>
  </td>
  <td className="px-3 py-4">
  <div 
    className="flex items-center space-x-1 overflow-x-auto max-w-full"
    onMouseEnter={() => setHoveredProcessId(process.id)}
    onMouseLeave={() => setHoveredProcessId(null)} // Cierre inmediato sin setTimeout
  >
      {process.subprocesos && process.subprocesos.filter(
      sp => sp.nombre !== "Sin subproceso" && sp.subproceso !== "Sin subproceso"
    ).length > 0 ? (
      process.subprocesos
        .filter(sp => sp.nombre !== "Sin subproceso" && sp.subproceso !== "Sin subproceso")
        .map((sp) => (
        <div
          key={sp.id || sp.globalId || Math.random()}
          className="w-3 h-3 rounded-full cursor-pointer"
          style={{ backgroundColor: sp.color || '#000000' }}
          title={sp.nombre || sp.subproceso || '-'}
        ></div>
      ))
    ) : (
      <div className="text-xs text-gray-500">Sin subprocesos</div>
    )}
  </div>
</td>


  <td className="px-3 py-4">
    <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${
      process.isActive 
        ? 'bg-green-100 text-green-800' 
        : 'bg-gray-200 text-gray-800'
    }`}>
      {process.isActive ? 'Activo' : 'Inactivo'}
    </span>
  </td>
  <td className="px-3 py-4 text-sm font-medium">
    <div className="flex space-x-1">
      {/* Botón de editar - deshabilitado si el proceso está inactivo */}
      <button
        onClick={() => {
          if (process.isActive) {
            const processCode = obtenerCodigo(process.codigo);
            fetchProcessDetails(processCode);
            setModalMode('edit');
            setIsModalOpen(true);
          } else {
            toast.error('No se puede editar un proceso inactivo. Actívalo primero.');
          }
        }}
        className={`p-2 rounded-lg transition-colors duration-300 ${
          process.isActive 
            ? 'bg-blue-100 hover:bg-blue-200 text-blue-600' 
            : 'bg-gray-200 text-gray-500 cursor-not-allowed'
        }`}
        title={process.isActive ? "Editar proceso" : "No se puede editar un proceso inactivo"}
      >
        <Edit className="w-4 h-4" />
      </button>
      
      {/* Botón de activar/desactivar */}
      <button
        onClick={() => handleToggleProcessStatus(process.id, process.isActive)}
        className={`p-2 rounded-lg transition-colors duration-300 ${
          process.isActive 
            ? 'bg-red-100 hover:bg-red-200 text-red-600' 
            : 'bg-green-100 hover:bg-green-200 text-green-600'
        }`}
        title={process.isActive ? 'Deshabilitar proceso' : 'Habilitar proceso'}
      >
        {process.isActive 
          ? <X className="w-4 h-4" /> 
          : <Eye className="w-4 h-4" />}
      </button>
    </div>
  </td>
</tr>
        ))
      )}
    </tbody>
  </table>
</div>
{renderSubprocessModal()}
      {/* Pagination controls */}
      {!loading && paginatedProcesses.length > 0 && (
        <div className="py-3 flex items-center justify-between border-t border-gray-200 mt-4">
          <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
              <p className="text-sm text-gray-700">
                Mostrando <span className="font-medium">{startIndex + 1}</span> a <span className="font-medium">{Math.min(startIndex + itemsPerPage, filteredProcesses.length)}</span> de <span className="font-medium">{filteredProcesses.length}</span> resultados
              </p>
            </div>
            <div>
              <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                <button
                  onClick={() => setCurrentPage(1)}
                  disabled={currentPage === 1}
                  className={`relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium ${
                    currentPage === 1 
                      ? 'text-gray-300 cursor-not-allowed' 
                      : 'text-gray-500 hover:bg-gray-50'
                  }`}
                >
                  <span className="sr-only">Primera página</span>
                  <ChevronLeft className="h-5 w-5" aria-hidden="true" />
                  <ChevronLeft className="h-5 w-5 -ml-3" aria-hidden="true" />
                </button>
                <button
                  onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                  disabled={currentPage === 1}
                  className={`relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium ${
                    currentPage === 1 
                      ? 'text-gray-300 cursor-not-allowed' 
                      : 'text-gray-500 hover:bg-gray-50'
                  }`}
                >
                  <span className="sr-only">Anterior</span>
                  <ChevronLeft className="h-5 w-5" aria-hidden="true" />
                </button>
                
                {/* Page numbers */}
                {Array.from({ length: Math.min(5, totalPages) }).map((_, i) => {
                  let pageNumber;
                  if (totalPages <= 5) {
                    pageNumber = i + 1;
                  } else if (currentPage <= 3) {
                    pageNumber = i + 1;
                  } else if (currentPage >= totalPages - 2) {
                    pageNumber = totalPages - 4 + i;
                  } else {
                    pageNumber = currentPage - 2 + i;
                  }
                  
                  return (
                    <button
                      key={pageNumber}
                      onClick={() => setCurrentPage(pageNumber)}
                      className={`relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium ${
                        currentPage === pageNumber
                          ? 'z-10 bg-blue-50 border-blue-500 text-blue-600'
                          : 'bg-white text-gray-500 hover:bg-gray-50'
                      }`}
                    >
                      {pageNumber}
                    </button>
                  );
                })}
                
                <button
                  onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                  disabled={currentPage === totalPages}
                  className={`relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium ${
                    currentPage === totalPages 
                      ? 'text-gray-300 cursor-not-allowed' 
                      : 'text-gray-500 hover:bg-gray-50'
                  }`}
                >
                  <span className="sr-only">Siguiente</span>
                  <ChevronRight className="h-5 w-5" aria-hidden="true" />
                </button>
                
                <button
                  onClick={() => setCurrentPage(totalPages)}
                  disabled={currentPage === totalPages}
                  className={`relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium ${
                    currentPage === totalPages 
                      ? 'text-gray-300 cursor-not-allowed' 
                      : 'text-gray-500 hover:bg-gray-50'
                  }`}
                >
                  <span className="sr-only">Última página</span>
                  <ChevronRight className="h-5 w-5" aria-hidden="true" />
                  <ChevronRight className="h-5 w-5 -ml-3" aria-hidden="true" />
                </button>
              </nav>
            </div>
          </div>
        </div>
      )}
    </div>

{/* Add this at the end of your JSX, before the final closing div */}
<ConfirmDialog
  isOpen={confirmDialog.isOpen}
  onClose={() => setConfirmDialog(prev => ({ ...prev, isOpen: false }))}
  onConfirm={() => {
    confirmDialog.onConfirm();
    setConfirmDialog(prev => ({ ...prev, isOpen: false }));
  }}
  title={confirmDialog.title}
  message={confirmDialog.message}
/>



    {/* Modal para edición/creación */}
    <Modal 
      isOpen={isModalOpen} 
      onClose={handleCloseModal} 
      title={modalMode === 'create' ? 'Crear Nuevo Proceso' : 'Editar Proceso'}
    >
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Basic Information */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div className="flex flex-col">
            <label htmlFor="nombreProceso" className="text-sm font-semibold mb-2 flex items-center">
              Nombre del Proceso <span className="text-red-500 ml-1">*</span>
            </label>
            <input
              id="nombreProceso"
              name="nombreProceso"
              type="text"
              value={formData.nombreProceso}
              onChange={handleInputChange}
              className="p-2 border border-gray-300 rounded uppercase"
              required
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="anio" className="text-sm font-semibold mb-2 flex items-center">
              Año <span className="text-red-500 ml-1">*</span>
            </label>
            <input
              id="anio"
              name="anio"
              type="text"
              value={formData.anio}
              onChange={handleInputChange}
              className="p-2 border border-gray-300 rounded"
              maxLength={4}
              pattern="[0-9]{4}"
              required
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="comuna" className="text-sm font-semibold mb-2 flex items-center">
              Comuna <span className="text-red-500 ml-1">*</span>
            </label>
            <ComunaAutocomplete
              allComunas={regions.flatMap(r => r.comunas)}
              selectedComuna={selectedComuna}
              onSelect={handleComunaChange}
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="region" className="text-sm font-semibold mb-2 flex items-center">
              Región <span className="text-red-500 ml-1">*</span>
            </label>
            <select
              id="region"
              name="region"
              value={selectedRegion}
              onChange={handleRegionChange}
              className="p-2 border border-gray-300 rounded bg-gray-100 cursor-not-allowed"
              disabled
            >
              <option value="">Seleccione una región</option>
              {regions.map((region) => (
                <option key={region.numero} value={region.numero}>
                  {region.region}
                </option>
              ))}
            </select>
          </div>

          {renderNumeroProceso()}

          <div className="flex flex-col">
          <label htmlFor="etapa" className="text-sm font-semibold mb-2">Etapa</label>
          <input
              id="etapa"
              name="etapa"
              min={1}
              type="number"
              value={formData.etapa}
              onChange={handleInputChange}
              className="p-2 border border-gray-300 rounded"
            />
       
          </div>

          <div className="flex flex-col">
            <label htmlFor="codigo" className="text-sm font-semibold mb-2">
              Código
            </label>
            <input
              id="codigo"
              name="codigo"
              type="text"
              value={formData.codigo}
              className="p-2 border border-gray-300 rounded bg-gray-100 cursor-not-allowed"
              disabled
            />
          </div>
        </div>

        {/* Subprocesos Section */}
        <div className="mt-8">
  <div className="flex items-center justify-between mb-4">
    <h2 className="text-lg font-semibold text-gray-800">Subprocesos</h2>
    <div className="flex space-x-2">
      <button 
        type="button"
        onClick={() => setShowTemplates(!showTemplates)}
        className="text-blue-500 hover:text-blue-700 flex items-center transition-colors duration-200"
      >
        <List className="w-4 h-4 mr-1" /> 
        {showTemplates ? "Ocultar listas personalizadas" : "Mostrar listas personalizadas"}
      </button>
      {/* <button
        type="button"
        onClick={handleLoadDefaultColors}
        className="bg-blue-500 hover:bg-blue-700 text-white py-1 px-3 rounded-lg flex items-center text-sm transition-colors duration-300"
      >
        <RotateCw className="w-4 h-4 mr-1" /> Generar Colores
      </button>
      <button
        type="button"
        onClick={handleClearAllColors}
        className="bg-gray-500 hover:bg-gray-700 text-white py-1 px-3 rounded-lg flex items-center text-sm transition-colors duration-300"
      >
        <XCircle className="w-4 h-4 mr-1" /> Limpiar Colores
      </button> */}
      <button
        type="button"
        onClick={handleAddSubproceso}
        className="bg-green-500 hover:bg-green-700 text-white py-1 px-3 rounded-lg flex items-center text-sm transition-colors duration-300"
      >
        <PlusCircle className="w-4 h-4 mr-1" /> Agregar Subproceso
      </button>
    </div>
  </div>
  
  {/* Contenedor de subprocesos */}
  <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
    <div className="space-y-2">
      {renderSubprocesoFields()}
    </div>
  </div>
  
  {/* Utiliza el componente TemplatesManager ya existente cuando showTemplates es true */}
  {showTemplates && (
    <div className="mt-4 border-t pt-4">
      <TemplatesManager onSelectTemplate={handleSelectTemplate} />
    </div>
  )}
</div>

        {/* Form Actions */}
        <div className="flex justify-end space-x-4 mt-8">
          <button
            type="button"
            onClick={handleCloseModal}
            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-6 rounded-lg transition-colors duration-300"
            disabled={isSaving}
          >
            Cancelar
          </button>
          <button
            type="submit"
            className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-lg flex items-center transition-colors duration-300 ${
              isSaving ? 'opacity-75 cursor-not-allowed' : ''
            }`}
            disabled={isSaving}
          >
            {isSaving ? (
              <>
                <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-white mr-2"></div>
                Guardando...
              </>
            ) : (
              <>
                <Save className="w-5 h-5 mr-2" /> Guardar
              </>
            )}
          </button>
        </div>
      </form>
    </Modal>
  </div>
  );
};

export default ProcessManagementSystem;