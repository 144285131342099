import React from 'react';
import { X, Calendar, Info, CheckCircle, FileText, BookOpen, Database } from 'lucide-react';
import imgTeam from '../img/team.jpg'; // Ajusta la ruta según la estructura de tu proyecto

interface AboutModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const AboutModal: React.FC<AboutModalProps> = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-50 overflow-y-auto">
      <div className="relative w-full max-w-3xl max-h-[90vh] overflow-auto bg-white rounded-lg shadow-lg">
        {/* Encabezado */}
        <div className="sticky z-[200] top-0 flex items-center justify-between p-4 border-b border-gray-200 bg-custom-header text-white">
          <h2 className="text-xl font-semibold">Acerca de SIMAGI</h2>
          <button
            onClick={onClose}
            className="p-1 rounded-full hover:bg-white hover:bg-opacity-20 transition-colors"
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        {/* Contenido */}
        <div className="p-5 space-y-6">
          {/* Sección de Bienvenida */}
          <div className="text-center">
            <h2 className="text-2xl font-bold text-cyan-600 mb-2">
              SIMAGI SPA
            </h2>
            <p className="text-gray-600 mb-4"> 
              Sistema Maestro de Gestión Integrado
            </p>
            
            {/* Imagen del equipo */}
            <div className="mx-auto my-6  rounded-lg overflow-hidden shadow-lg border border-gray-200">
              <img 
                src={imgTeam} 
                alt="Equipo SIMAGI" 
                className="w-full h-full object-cover"
              />
            </div>
          </div>
          
          {/* Sección sobre la empresa */}
          <div>
            <p className="mb-4">
              <span className="font-bold text-cyan-600">SIMAGI SPA</span> (Sistema Maestro de Gestión Integrado SpA) es una empresa innovadora fundada el 6 de febrero de 2025, especializada en software para Tramitología mediante herramientas para la Gestión documental, Planificación y Monitoreo.
            </p>
            <p className="mb-4">
              Su tecnología sin duda impactará los sectores inmobiliario, construcción e industria en general, integrando ERP e inteligencia artificial.
            </p>
          </div>
          
          {/* Línea de tiempo */}
          <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
            <h3 className="font-semibold text-gray-800 flex items-center mb-2">
              <Calendar className="h-5 w-5 text-cyan-500 mr-2" />
              Línea de Tiempo
            </h3>
            <div className="space-y-2">
              <div className="flex">
                <div className="w-24 font-medium text-gray-700">Feb 2025:</div>
                <div className="flex-1 text-gray-600">Fundación de SIMAGI SPA</div>
              </div>
              <div className="flex">
                <div className="w-24 font-medium text-gray-700">Mar 2025:</div>
                <div className="flex-1 text-gray-600">Lanzamiento de plataforma beta</div>
              </div>
              <div className="flex">
                <div className="w-24 font-medium text-gray-700">Jun 2025:</div>
                <div className="flex-1 text-gray-600">Implementación con primeros clientes</div>
              </div>
            </div>
          </div>
          
          {/* Características principales */}
          <div className="bg-cyan-50 p-5 rounded-lg border border-cyan-100">
            <h3 className="font-semibold text-cyan-800 mb-3 flex items-center">
              <Info className="h-5 w-5 text-cyan-600 mr-2" />
              Características principales
            </h3>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="flex items-start">
                <div className="flex-shrink-0 bg-cyan-100 rounded-full p-2 mr-3">
                  <FileText className="h-5 w-5 text-cyan-600" />
                </div>
                <div>
                  <h4 className="font-medium text-gray-800">Control Documental</h4>
                  <p className="text-sm text-gray-600">Gestión centralizada de toda la documentación</p>
                </div>
              </div>
              
              <div className="flex items-start">
                <div className="flex-shrink-0 bg-cyan-100 rounded-full p-2 mr-3">
                  <Database className="h-5 w-5 text-cyan-600" />
                </div>
                <div>
                  <h4 className="font-medium text-gray-800">Planificación</h4>
                  <p className="text-sm text-gray-600">Herramientas para planificar proyectos con precisión</p>
                </div>
              </div>
              
              <div className="flex items-start">
                <div className="flex-shrink-0 bg-cyan-100 rounded-full p-2 mr-3">
                  <BookOpen className="h-5 w-5 text-cyan-600" />
                </div>
                <div>
                  <h4 className="font-medium text-gray-800">Biblioteca Normativa</h4>
                  <p className="text-sm text-gray-600">Acceso a normativas actualizadas</p>
                </div>
              </div>
              
              <div className="flex items-start">
                <div className="flex-shrink-0 bg-cyan-100 rounded-full p-2 mr-3">
                  <Info className="h-5 w-5 text-cyan-600" />
                </div>
                <div>
                  <h4 className="font-medium text-gray-800">Inteligencia Artificial</h4>
                  <p className="text-sm text-gray-600">IA integrada para agilizar procesos</p>
                </div>
              </div>
            </div>
          </div>
          
          {/* Información técnica */}
          <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
            <h3 className="font-semibold text-gray-800 flex items-center mb-2">
              <Info className="h-5 w-5 text-gray-600 mr-2" />
              Información técnica
            </h3>
            <div className="space-y-2">
              <div className="flex">
                <div className="w-24 font-medium text-gray-700">Versión:</div>
                <div className="flex-1 text-gray-600">1.0.0 (Build 2024.03.15)</div>
              </div>
              <div className="flex">
                <div className="w-24 font-medium text-gray-700">Soporte:</div>
                <div className="flex-1 text-gray-600">
                  <a href="mailto:soporte@simagi.cl" className="text-blue-600 hover:underline">soporte@simagi.cl</a>
                </div>
              </div>
              <div className="flex">
                <div className="w-24 font-medium text-gray-700">Web:</div>
                <div className="flex-1 text-gray-600">
                  <a href="https://simagi.cl" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">www.simagi.cl</a>
                </div>
              </div>
            </div>
          </div>
          
          {/* Pie de página con derechos de autor */}
          <div className="text-center text-gray-500 text-sm pt-2">
            © 2024-2025 SIMAGI SPA. Todos los derechos reservados.
          </div>
        </div>

        {/* Pie del modal */}
        <div className="flex justify-end p-4 border-t border-gray-200">
          <button
            onClick={onClose}
            className="px-4 py-2 text-white bg-custom-sidebar hover:bg-opacity-90 rounded transition-colors"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  );
};

export default AboutModal;