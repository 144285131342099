const dateUtils = {
  // Convert a date string (YYYY-MM-DD or DD-MM-YYYY) to local Date object
  parseLocalDate(dateString: string): Date {
    if (!dateString) return new Date();

    // Detect format DD-MM-YYYY and convert
    if (/^\d{2}-\d{2}-\d{4}$/.test(dateString)) {
      const [day, month, year] = dateString.split('-').map(Number);
      const date = new Date(year, month - 1, day);
      date.setHours(0, 0, 0, 0);
      return date;
    }

    // Assume format YYYY-MM-DD by default
    if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
      const [year, month, day] = dateString.split('-').map(Number);
      const date = new Date(year, month - 1, day);
      date.setHours(0, 0, 0, 0);
      return date;
    }

    // Return an invalid date if format does not match
    console.warn("Unrecognized date format:", dateString);
    return new Date(NaN);
  },

    // Nueva función para convertir a DD/MM/YYYY
    formatToReadableDate(date: Date): string {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
  
    // Convertir una cadena como "Thu Nov 28 2024 00:00:00 GMT-0300" a un formato legible
    parseAndFormatReadable(dateString: string): string {
      const date = new Date(dateString); // Convertir cadena a Date
      if (isNaN(date.getTime())) {
        console.warn("Fecha inválida:", dateString);
        return "Fecha inválida";
      }
      return this.formatToReadableDate(date); // Formatear como DD/MM/YYYY
    },
    
    parseAndFormatReadableYYYY(dateString: string): string {
      const date = new Date(dateString); // Convertir cadena a Date
      if (isNaN(date.getTime())) {
        console.warn("Fecha inválida:", dateString);
        return "Fecha inválida";
      }
      // Formatear como YYYY/MM/DD
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },

    
  // Format a Date object to YYYY-MM-DD string in local timezone
  formatLocalDate(date: Date): string {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
  },

  // Get today's date at midnight in local timezone
  getTodayLocal(): Date {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // This ensures midnight in local timezone
    return today;
  },

  // Get today's date as YYYY-MM-DD string in local timezone
  getTodayString(): string {
    return this.formatLocalDate(this.getTodayLocal()); // Use getTodayLocal directly
  },

  // Validate if a date string is today or in the future
  isValidDate(date: string): boolean {
    if (!date) return true;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const selectedDate = new Date(date);
    return selectedDate >= today;
  },

  // Calculate days difference between two dates in local timezone
  calculateDaysDifference(endDateStr: string, startDateStr: string): number {
    const endDate = this.parseLocalDate(endDateStr);
    const startDate = this.parseLocalDate(startDateStr);
    const diffTime = endDate.getTime() - startDate.getTime();
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  },

  // Calculate days delayed from today
  calculateDaysDelayed(endDateStr: string): number {
    const endDate = this.parseLocalDate(endDateStr);
    const today = this.getTodayLocal();
    if (endDate >= today) return 0;

    const diffTime = today.getTime() - endDate.getTime();
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  },

  // Add days to a date and return YYYY-MM-DD string
  addDaysToDate(dateStr: string, days: number): string {
    const date = this.parseLocalDate(dateStr);
    date.setDate(date.getDate() + days);
    return this.formatLocalDate(date);
  },
};

export default dateUtils;
