// components/custom/Avatar.tsx
import React from 'react';

export const Avatar = ({ children, className }: { children: React.ReactNode; className?: string }) => (
  <div className={`rounded-full overflow-hidden bg-gray-200 ${className}`}>{children}</div>
);

export const AvatarImage = ({ src, alt }: { src?: string; alt?: string }) => (
  <img src={src} alt={alt} className="w-full h-full object-cover" />
);

export const AvatarFallback = ({ children, className }: { children: React.ReactNode; className?: string }) => (
  <div className={`flex items-center justify-center w-full h-full text-white bg-gray-500 ${className}`}>
    {children}
  </div>
);
