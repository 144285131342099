import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import { Table, AlertCircle, CheckCircle, ChevronDown, ChevronUp, X, Bell, Check, Loader2, Search, UserIcon, Users, Clock, Calendar, ChevronRight, Info, AlertTriangle, XCircle } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from '../ui/dialog';
import toast, { Toaster } from 'react-hot-toast';
import { Button } from '../ui/button';
import { MdBusiness, MdKeyboardArrowDown, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdKeyboardArrowUp } from 'react-icons/md'; // Importing a Material Design icon
import dateUtils from './dateUtils';



interface User { 
  id: number;
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  is_admin: boolean;
  is_active: boolean;
  departamento: string;
}

interface UserSelectorModalProps {
  users: User[];
  selectedUsers: User[];
  onUsersChange: (users: User[]) => void;
  enabled?: boolean;
  taskId: number;
  groupId: number;
  responsable: string;
}



interface User {
  id: number;
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  departamento: string;
}

interface UserDisplayModalProps {
  taskId: number;
  responsable: string;
}

const UserDisplayModal: React.FC<UserDisplayModalProps> = ({ 
  taskId,
  responsable
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  // Función para parsear los responsables
  const parseResponsibles = (responsibleString: string): number[] => {
    if (!responsibleString) return [];
    
    try {
      // Intentar primero como JSON array
      if (responsibleString.startsWith('[')) {
        return JSON.parse(responsibleString);
      }
      // Si tiene comas, tratar como lista separada por comas
      if (responsibleString.includes(',')) {
        return responsibleString.split(',').map(id => parseInt(id.trim())).filter(id => !isNaN(id));
      }
      // Si es un solo número
      const id = parseInt(responsibleString);
      return isNaN(id) ? [] : [id];
    } catch (error) {
      console.error('Error parsing responsibles:', responsibleString, error);
      return [];
    }
  };

  const loadUsers = async () => {
    try {
      setIsLoading(true);
      
      // Cargar todos los usuarios
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/users/get_users.php`);
      if (!response.ok) throw new Error('Error fetching users');
      
      const allUsers = await response.json();
      console.log('All users loaded:', allUsers);
      
      // Obtener IDs de responsables del string
      const responsibleIds = parseResponsibles(responsable);
      console.log('Responsible IDs:', responsibleIds);
      
      // Filtrar usuarios responsables
      const responsibleUsers = allUsers.filter((user: User) => 
        responsibleIds.includes(user.id)
      );
      
      console.log('Filtered responsible users:', responsibleUsers);
      setUsers(responsibleUsers);

    } catch (error) {
      console.error('Error loading users:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      loadUsers();
    }
  }, [isOpen, responsable]);

  const filteredUsers = users.filter(user => {
    const searchString = `${user.firstname} ${user.lastname} ${user.email} ${user.departamento}`.toLowerCase();
    const searchTerms = searchTerm.toLowerCase().split(' ');
    return searchTerms.every(term => searchString.includes(term));
  });

  // Preparar el texto del tooltip
  const tooltipText = users.length > 0
    ? `Responsables: ${users.map(u => `${u.firstname} ${u.lastname}`).join(', ')}`
    : 'Ver responsables';

  return (
    <>
      <div 
        onClick={() => setIsOpen(true)} 
        className="cursor-pointer flex items-center"
        title={tooltipText}
      >
        <UserIcon className="w-5 h-5 mr-2 text-green-500" />
      </div>

      <Dialog isOpen={isOpen} onClose={() => setIsOpen(false)} width="600px">
        <div className="overflow-hidden">
          <div className="bg-gradient-to-r from-teal-500 to-teal-600 p-6">
            <div className="flex items-center space-x-2">
              <Users className="w-6 h-6 text-white" />
              <DialogTitle>
                <span className="text-xl font-semibold text-white">
                  Responsables de la Tarea
                </span>
              </DialogTitle>
            </div>
            <p className="text-teal-100 mt-2 text-sm">
              {users.length} responsable(s)
            </p>
          </div>

          <DialogContent className="px-6 py-4">
            {isLoading ? (
              <div className="flex items-center justify-center py-8">
                <Loader2 className="w-8 h-8 animate-spin text-teal-500" />
                <span className="ml-2">Cargando responsables...</span>
              </div>
            ) : (
              <>
                {users.length > 0 && (
                  <div className="mb-6">
                    <div className="relative">
                      <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                      <input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Buscar responsable..."
                        className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                      />
                    </div>
                  </div>
                )}

                <div className="space-y-2">
                  <div className="grid grid-cols-1 gap-2 max-h-[300px] overflow-y-auto">
                    {filteredUsers.map((user) => (
                      <div
                        key={user.id}
                        className="flex items-center justify-between w-full p-3 rounded-lg border bg-white"
                      >
                        <div className="flex items-center gap-3">
                          <div className="w-8 h-8 rounded-full bg-green-100 flex items-center justify-center">
                            <UserIcon className="w-4 h-4 text-green-600" />
                          </div>
                          <div className="flex flex-col items-start">
                            <span className="font-medium">{`${user.firstname} ${user.lastname}`}</span>
                            <span className="text-sm text-gray-500">{user.email}</span>
                          </div>
                        </div>
                        <span className="text-sm text-gray-500">{user.departamento}</span>
                      </div>
                    ))}

                    {users.length === 0 && !isLoading && (
                      <div className="text-center py-8 text-gray-500">
                        Sin responsables asignados
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </DialogContent>

          <div className="flex justify-end px-6 py-4 bg-gray-50 border-t border-gray-200">
            <button
              onClick={() => setIsOpen(false)}
              className="bg-teal-500 text-white hover:bg-teal-600 px-4 py-2"
            >
              Cerrar
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
};


type SemaphoreCalculator = (
  start: string,
  end: string,
  taskId: number,
  dependencia: string | number | null,
  isClosed: boolean,
  allTasks?: Task[],
  parentTask?: Task
) => SemaphoreStatus;
interface User {
  id: number;
  firstname: string;
  lastname: string;
}
interface TaskModalProps {
  isOpen: boolean;
  onClose: () => void;
  task: Task;
  onChangeTask: (updatedTask: Task) => void;
  backgroundColor: string;
  allTasks: Task[];
  agrupadorId: number;
  daysDelayed: number;
  groupProgress: number;
  processName: string | undefined;
  subprocessName: string | undefined;
  agrupadorName: string | undefined;
  // Añadir estas nuevas props
  calculateTaskStatus: SemaphoreCalculator;
  onSelectNextTask: (task: Task) => void; // Para manejar la selección de la siguiente tarea
}


// Actualizamos SubtaskModal para incluir la funcionalidad de fecha de seguimiento
interface SubtaskModalProps {
  isOpen: boolean;
  onClose: () => void;
  subtask: SubTask;
  onChangeTask: (task: Task) => void;
  onChangeSubtask: (updatedSubtask: SubTask) => void;
  onAddNewSubtask: (option: NewSubtaskOption) => void;
  backgroundColor: string;
  mainTask: Task;
  groupProgress?: number;
  processName?: string;
  subprocessName?: string;
  agrupadorName?: string;
  daysDelayed?: number; // Agregar esta prop
    forceTableRerender?: () => void;  // Agregamos esta línea

}


export interface NewSubtaskOption {
  type: 'INGRESO' | 'OBSERVACIÓN' | 'RESPUESTA' | 'RESOLUCIÓN' | 'OTROS';
  name: string;
  resolutionType: string;
  responsable: User[];
  organismo: string;
}


interface TaskUpdateData {
  id: number;
  tarea_id?: number;
  isClosed: boolean;
  progreso: number;
  fecha_inicio: string;
  fecha_termino: string;
  followUpDate: string;
  comments: string;
  semaphoreStatus: SemaphoreStatus;
  delayInDays?: number;
}



interface SubtaskUpdateData {
  id: number;
  tarea_id: number;
  nombre: string;
  tipo: string;
  responsible: User[] | string;
  organismo: string;
  progreso: number;
  fecha_inicio: string;
  fecha_termino: string;
  duracion: number;
  dependencias: string;
  enabled: boolean;
  resolucion_tipo?: string;
  orden: number;
  comments: string;
  isClosed: boolean;
  followUpDate: string;
  semaphoreStatus: SemaphoreStatus;
}

interface CommentData {
  id: number;          // ID de la tarea o subtarea
  comments: string;    // Comentarios en formato JSON string
  type: 'task' | 'subtask'; // Indica si es tarea principal o subtarea
  tarea_id?: number;   // ID de la tarea padre (solo para subtareas)
}


interface CommentResponse {
  success: boolean;
  comments?: string[];
  message?: string;
}

interface TaskStatusCalculator {
  (task: Task): SemaphoreStatus;
}

interface ReminderData {
  task_id: number;
  reminder_type: 'specific-date' | 'relative-time' | 'progress-based' | 'task-expired' | 'task-closed' | null;
  reminder_value: string | null;
  reminder_code: string | null;
  reminder_calculated_date: string | null;
  reminder_active: boolean;
  reminder_triggered: boolean;
}

type ReminderType = 'specific-date' | 'relative-time' | 'progress-based' | 'task-expired' | 'task-closed';


interface Task {
  id: number;
  proceso: string;
  codigo: string; // New field
  comuna: string; // New field
  subproceso: string;
  agrupador: string;
  nombre: string;
  responsable: string;
  fecha_inicio: string;
  fecha_termino: string;
  progreso: number;
  descriptor: string;
  organismo: string;
  duracion: number;
  dependencia: string | number | null;
  enabled: boolean;
  isClosed: boolean;
  comments: string;
  orden: number;
  followUp: boolean;
  followUpDate: string;
  semaphoreStatus: SemaphoreStatus;
  delayInDays?: number;

  subtasks?: SubTask[];
  subproceso_color?: string;
  subproceso_orden: number; // Añadido este campo

  styles?: {
    progressColor?: string;
    progressSelectedColor?: string;
    backgroundColor?: string;
    backgroundSelectedColor?: string;
  };
    reminder_code?: string;
    reminder_type?: string;
    reminder_value?: string;
    reminder_calculated_date?: string;
    reminder_active?: true,
    reminder_triggered?: false
  
}



const resolutionTypes = [
  {
    value: 'APROBADO',
    label: 'Aprobado',
    icon: <Check className="h-5 w-5" />,
    color: 'text-green-700',
    bgColor: 'bg-green-100'
  },
  {
    value: 'RECHAZADO',
    label: 'Rechazado',
    icon: <X className="h-5 w-5" />,
    color: 'text-red-700',
    bgColor: 'bg-red-100'
  },
  {
    value: 'DESISTIMIENTO',
    label: 'Desistimiento',
    icon: <AlertTriangle className="h-5 w-5" />,
    color: 'text-orange-700',
    bgColor: 'bg-orange-100'
  },
  {
    value: 'SILENCIO ADMINISTRATIVO POSITIVO',
    label: 'SILENCIO ADMINISTRATIVO POSITIVO',
    icon: <div className="flex items-center">
            <CheckCircle className="h-5 w-5 mr-1" />
            <span className="text-xs font-medium">SAP</span>
          </div>,
    color: 'text-green-700',
    bgColor: 'bg-green-50'
  },
  {
    value: 'SILENCIO ADMINISTRATIVO NEGATIVO',
    label: 'SILENCIO ADMINISTRATIVO NEGATIVO',
    icon: <div className="flex items-center"> 
            <XCircle className="h-5 w-5 mr-1" />
            <span className="text-xs font-medium">SAN</span>
          </div>,
    color: 'text-red-700',
    bgColor: 'bg-red-50'
  },
  {
    value: 'NO ADMITIDO',
    label: 'No Admitido',
    icon: <div className="flex items-center">
            <svg viewBox="0 0 24 24" className="h-5 w-5 mr-1 text-red-700">
              <circle cx="12" cy="12" r="10" fill="none" stroke="currentColor" strokeWidth="2"/>
              <line x1="4" y1="4" x2="20" y2="20" stroke="currentColor" strokeWidth="2"/>
            </svg>
            
          </div>,
    color: 'text-red-700',
    bgColor: 'bg-red-50'
  }

] as const;

export interface SubTask {
  id?: number;
  name: string;
  responsible: User[] | string; // Puede ser un array de Users o un string
  progreso: number;
  start: string;
  end: string;
  duration: number;
  organismo: string;
  dependsOn?: { groupId: number; taskId: number; subtaskId: number }[];
  enabled: boolean;
  type: 'INGRESO' | 'OBSERVACIÓN' | 'RESPUESTA' | 'RESOLUCIÓN' | 'OTROS';
  resolutionType?: 'APROBADO' | 'RECHAZADO' | 'DESISTIMIENTO' | 'SILENCIO ADMINISTRATIVO POSITIVO' | 'SILENCIO ADMINISTRATIVO NEGATIVO' | 'NO ADMITIDO' | 'OTRO';
  orden: number;
  comments: string;
  followUpDate: string;
  followUp: boolean;
  semaphoreStatus: SemaphoreStatus;
  isClosed: boolean;
  delayInDays: number;
  styles?: {
    progressColor?: string;
    progressSelectedColor?: string;
    backgroundColor?: string;
    backgroundSelectedColor?: string;
  };
  reminder_code?: string;
  reminder_type?: string;
  reminder_value?: string;
  reminder_calculated_date?: string;
  reminder_active?: boolean;
  reminder_triggered?: boolean;
  tarea_id?: number;
}

enum SemaphoreStatus {
  Red = "red",
  Orange = "orange",
  Yellow = "yellow",
  Green = "green",
  Gray = "gray"
}

const lightenColor = (color: string, amount: number): string => {
  return '#' + color.replace(/^#/, '').replace(/../g, color => 
    ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2)
  );
};


const getResolutionTypeStyle = (resolutionType: string) => {
  switch (resolutionType) {
    case 'APROBADO':
      return 'bg-green-100 text-green-800';
    case 'RECHAZADO':
      return 'bg-red-100 text-red-800';
    case 'DESISTIMIENTO':
      return 'bg-orange-100 text-orange-800';
    case 'SILENCIO ADMINISTRATIVO POSITIVO':
      return 'bg-green-100 text-green-800';
    case 'SILENCIO ADMINISTRATIVO NEGATIVO':
      return 'bg-red-100 text-red-800';
    default:
      return 'bg-gray-100 text-gray-800';
  }
};








const TaskModal: React.FC<TaskModalProps> = ({ 
  isOpen, 
  onClose, 
  task, 
  onChangeTask, 
  backgroundColor,
  allTasks,
  agrupadorId,
  daysDelayed,
  groupProgress,
  processName,         // Prop: Nombre del proceso
  subprocessName,      // Prop: Nombre del subproceso
  agrupadorName, 
  calculateTaskStatus,
  onSelectNextTask 
}) => {
  const [newComment, setNewComment] = useState('');
  const [showComments, setShowComments] = useState(true); // Set to true by default for better visibility
  const [followUpDate, setFollowUpDate] = useState<string>(task.followUpDate || '');
  const [showReminderPicker, setShowReminderPicker] = useState(false);
  const storedFirstName = sessionStorage.getItem('firstName');
  const storedLastName = sessionStorage.getItem('lastName');
  const commentsEndRef = useRef<HTMLDivElement>(null);
  const [activeTab, setActiveTab] = useState<'details' | 'followup'>('details');
  const [editedName, setEditedName] = useState(task.nombre);
  const storedUserId = sessionStorage.getItem('userId');
  const [localStartDate, setLocalStartDate] = useState<string>(task.fecha_inicio || '');
  const [localEndDate, setLocalEndDate] = useState<string>(task.fecha_termino || '');
  const [isLoading, setIsLoading] = useState(false);
  const [reminderType, setReminderType] = useState<'date' | 'relative' | 'progress'>('progress');
  const [selectedDate, setSelectedDate] = useState('');
  const [timeValue, setTimeValue] = useState(1);
  const [timeUnit, setTimeUnit] = useState<'days' | 'weeks' | 'months'>('days');
  const [progressValue, setProgressValue] = useState(50);
  const [isLoadingReminder, setIsLoadingReminder] = useState(false);
  const [modalKey, setModalKey] = useState(0);
  const commentsContainerRef = useRef<HTMLDivElement>(null);
  const [comments, setComments] = useState<string>(task.comments || '');
  const [isLoadingComments, setIsLoadingComments] = useState(false);
  const [isCommentsInitialized, setIsCommentsInitialized] = useState(false);

  
const [localTask, setLocalTask] = useState<Task>(task);

  const modalVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { 
      opacity: 1, 
      scale: 1,
      transition: { type: 'spring', stiffness: 500, damping: 25 }
    },
    exit: { 
      opacity: 0, 
      scale: 0.8,
      transition: { duration: 0.2 }
    }
  };
  
  const contentVariants = {
    hidden: { opacity: 0, x: 20 },
    visible: { 
      opacity: 1, 
      x: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 25
      }
    },
    exit: { 
      opacity: 0, 
      x: -20,
      transition: { duration: 0.2 }
    }
  };
  
  useEffect(() => {
    if (isOpen) {
      setLocalStartDate(task.fecha_inicio);
      setLocalEndDate(task.fecha_termino);
      setEditedName(task.nombre);
      // Asegurarnos de mantener el estado del semáforo
      setLocalTask({
        ...task,
        semaphoreStatus: task.semaphoreStatus,
        comments: task.comments,
      });
    }
  }, [isOpen, task]);

  



// Función para cargar los recordatorios de una tarea
const loadTaskReminders = async (taskId: number): Promise<ReminderData | null> => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/get_task_reminders.php?taskId=${taskId}`);
    if (!response.ok) {
      throw new Error('Error loading task reminders');
    }
    const data = await response.json();
    return data.reminder || null;
  } catch (error) {
    console.error('Error loading reminders for task:', taskId, error);
    return null;
  }
};

// Función para cargar los recordatorios de una subtarea
const loadSubtaskReminders = async (taskId: number, subtaskId: number): Promise<ReminderData | null> => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/get_subtask_reminders.php?taskId=${taskId}&subtaskId=${subtaskId}`
    );
    if (!response.ok) {
      throw new Error('Error loading subtask reminders');
    }
    const data = await response.json();
    return data.reminder || null;
  } catch (error) {
    console.error('Error loading reminders for subtask:', subtaskId, error);
    return null;
  }
};



  const formatDate = (date: string) => {
    if (!date) return '';
    const parsedDate = new Date(date);
    return parsedDate.toISOString().split('T')[0]; // Formato YYYY-MM-DD
  };

  
    // Handle task name change
    const handleNameChange = async () => {
      if (editedName.trim() === '') {
        toast.error('El nombre no puede estar vacío');
        return;
      }
    
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/update_task_name.php`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: task.id, name: editedName }),
        });
    
        if (!response.ok) {
          throw new Error('Error al actualizar el nombre de la tarea');
        }
    
        // Actualizar el estado local de la tarea
        onChangeTask({ ...task, nombre: editedName });
        toast.success('Nombre de la tarea actualizado');
      } catch (error) {
        console.error('Error al actualizar el nombre:', error);
        toast.error('Hubo un error al guardar el nombre. Por favor, inténtalo nuevamente.');
      }
    };

    const handleLocalDateChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      
      // First update local state for immediate UI feedback
      if (name === 'start') {
        setLocalStartDate(value);
      } else if (name === 'end') {
        setLocalEndDate(value);
      }
    
      try {
        // Sync with backend
        const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/update_task_dates.php`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            taskId: task.id,
            start: name === 'start' ? value : localStartDate,
            end: name === 'end' ? value : localEndDate
          })
        });
    
        if (!response.ok) {
          throw new Error('Error updating dates');
        }
    
        // Update parent state only after successful backend update
        const updatedTask = {
          ...task,
          start: name === 'start' ? value : localStartDate,
          end: name === 'end' ? value : localEndDate
        };
        
     //  onChangeTask(updatedTask);
        setLocalTask(updatedTask);
    
      } catch (error) {
        // Revert local state on error
        if (name === 'start') {
          setLocalStartDate(task.fecha_inicio);
        } else {
          setLocalEndDate(task.fecha_termino);
        }
        toast.error('Error al actualizar las fechas');
      }
    };


    

    const handleLocalNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setEditedName(e.target.value);
    };

    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (task.isClosed) return;
      const { name, value } = e.target;
      
      // Simplemente actualizar el estado mientras se escribe
      if (name === 'start') {
        setLocalStartDate(value);
      } else if (name === 'end') {
        setLocalEndDate(value);
      }
    };
    
    const handleDateBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
    
      if (!value || !/^\d{4}-\d{2}-\d{2}$/.test(value)) {
        if (name === 'start') {
          setLocalStartDate(task.fecha_inicio);
        } else {
          setLocalEndDate(task.fecha_termino);
        }
        return;
      }
    
      const [year, month, day] = value.split('-').map(Number);
      const date = new Date(year, month - 1, day);
      
      if (
        isNaN(date.getTime()) ||
        date.getFullYear() !== year ||
        date.getMonth() + 1 !== month ||
        date.getDate() !== day
      ) {
        if (name === 'start') {
          setLocalStartDate(task.fecha_inicio);
        } else {
          setLocalEndDate(task.fecha_termino);
        }
        toast.error('Fecha inválida');
        return;
      }
    
      if (name === 'start') {
        if (new Date(value) > new Date(localEndDate)) {
          setLocalStartDate(task.fecha_inicio);
          toast.error('La fecha de inicio no puede ser posterior a la fecha de fin');
        }
      } else if (name === 'end') {
        if (new Date(value) < new Date(localStartDate)) {
          setLocalEndDate(task.fecha_termino);
          toast.error('La fecha de fin no puede ser anterior a la fecha de inicio');
        }
      }
    };

  const formatDateDDMMYYYY = (dateString: string) => {
    const [year, month, day] = dateString.split('-');
    return `${day}-${month}-${year}`;
  };




  
  // Efectos para manejo de comentarios
  useEffect(() => {
    if (commentsContainerRef.current) {
      commentsContainerRef.current.scrollTop = commentsContainerRef.current.scrollHeight;
    }
  }, [task.comments, isOpen]);
 
  useEffect(() => {
    if (commentsEndRef.current) {
      commentsEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [comments]);



  // Función para cargar comentarios de una tarea
const loadTaskComments = async (taskId: number): Promise<string[]> => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/get_task_comments.php?taskId=${taskId}`);
    
    if (!response.ok) {
      throw new Error('Error loading task comments');
    }

    const data: CommentResponse = await response.json();
    if (data.success && data.comments) {
      return data.comments;
    }
    return [];
  } catch (error) {
    console.error('Error loading task comments:', error);
    throw error;
  }
};


const loadComments = useCallback(async () => {
  if (!task.id || isCommentsInitialized) return;
  
  try {
    setIsLoadingComments(true);
    const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/get_task_comments.php?taskId=${task.id}`);
    
    if (!response.ok) {
      throw new Error('Error loading task comments');
    }

    const data = await response.json();
    if (data.success && data.comments) {
      onChangeTask({
        ...task,
        comments: JSON.stringify(data.comments)
      });
    }
    setIsCommentsInitialized(true);
  } catch (error) {
    console.error('Error loading comments:', error);
    toast.error('Error al cargar los comentarios');
  } finally {
    setIsLoadingComments(false);
  }
}, [task.id, isCommentsInitialized]);


  const handleTaskUpdate = async (task: Task, updatedData: Partial<Task>): Promise<void> => {
    try {
      // Actualizar la tarea principal
      const taskUpdateData: TaskUpdateData = {
        id: task.id,
        isClosed: updatedData.isClosed ?? task.isClosed,
        progreso: updatedData.progreso ?? task.progreso,
        fecha_inicio: updatedData.fecha_inicio ?? task.fecha_inicio,
        fecha_termino: updatedData.fecha_termino ?? task.fecha_termino,
        followUpDate: updatedData.followUpDate ?? task.followUpDate,
        comments: updatedData.comments ?? task.comments,
        semaphoreStatus: updatedData.semaphoreStatus ?? task.semaphoreStatus,
        delayInDays: updatedData.delayInDays
      };
  
      await updateTaskInDatabase(taskUpdateData);
  
      // Si hay subtareas actualizadas, procesarlas
      if (updatedData.subtasks && task.subtasks) {
        for (const subtask of updatedData.subtasks) {
          const subtaskUpdateData: SubtaskUpdateData = {
            id: subtask.id!,
            tarea_id: task.id,
            nombre: subtask.name,
            tipo: subtask.type,
            responsible: subtask.responsible,
            organismo: subtask.organismo,
            progreso: subtask.progreso,
            fecha_inicio: subtask.start,
            fecha_termino: subtask.end,
            duracion: subtask.duration,
            dependencias: JSON.stringify(subtask.dependsOn),
            enabled: subtask.enabled,
            resolucion_tipo: subtask.resolutionType,
            orden: subtask.orden,
            comments: subtask.comments,
            isClosed: subtask.isClosed,
            followUpDate: subtask.followUpDate,
            semaphoreStatus: subtask.semaphoreStatus
          };
          if (task.subtasks) {
            for (const subtask of task.subtasks) {
              await updateSubtaskInDB(subtask);
            } 
          }
        }
      }
    } catch (error) {
      console.error('Error in handleTaskUpdate:', error);
      throw error;
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    onChangeTask({ ...task, [name]: value });
  };



// Función para calcular los días entre dos fechas
const calculateDurationInDays = (startDate: string, endDate: string): number => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const diffTime = end.getTime() - start.getTime();
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

// Modificar la parte del renderizado del selector de progreso
const renderProgressSelector = () => {
  const totalDays = calculateDurationInDays(task.fecha_inicio, task.fecha_termino);
  
  return (
    <div className="space-y-4">
      <div className="bg-white rounded-lg">
        <h4 className="font-medium mb-2">Progreso basado en duración</h4>
        <p className="text-sm text-gray-600 mb-4">
          La tarea tiene una duración de {totalDays} días. 
          El recordatorio se activará cuando se alcance el porcentaje seleccionado del tiempo total.
        </p>
        
        <select
          value={progressValue}
          onChange={e => setProgressValue(parseInt(e.target.value))}
          className="w-full rounded-md border-gray-300"
          disabled={task.isClosed}
        >
          <option value={25}>25% del tiempo (Día {Math.ceil(totalDays * 0.25)})</option>
          <option value={50}>50% del tiempo (Día {Math.ceil(totalDays * 0.5)})</option>
          <option value={75}>75% del tiempo (Día {Math.ceil(totalDays * 0.75)})</option>
          <option value={90}>90% del tiempo (Día {Math.ceil(totalDays * 0.9)})</option>
        </select>

        <div className="bg-blue-50 border-l-4 border-blue-400 p-4 mt-4">
          <div className="flex">
            <Info className="h-5 w-5 text-blue-400 mr-2" />
            <p className="text-sm text-blue-700">
              El recordatorio se activará en el día {Math.ceil(totalDays * (progressValue / 100))} 
              de los {totalDays} días totales de la tarea.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};


// Función para generar el reminder_code
const generateReminderCode = (type: ReminderType, value: string): string => {
  switch (type) {
    case 'specific-date':
      return `f-${value}`;
    case 'relative-time':
      return `rt-${value}`;
    case 'progress-based':
      return `p-${value}`;
    case 'task-expired':
      return `exp-${value}`;
    case 'task-closed':
      return `closed-${value}`;
    default:
      return '';
  }
};

// Handler de guardado actualizado
const handleReminderSave = async () => {
  try {
    setIsLoadingReminder(true);
    const currentUser = sessionStorage.getItem('userId');
    let reminder: {
      type: ReminderType;
      value: string;
      code: string;
    };

    switch (reminderType) {
      case 'date':
        reminder = {
          type: 'specific-date',
          value: selectedDate,
          code: generateReminderCode('specific-date', selectedDate)
        };
        break;
      case 'relative':
        const relativeValue = `${timeValue}${timeUnit === 'weeks' ? 'w' : 'd'}`;
        reminder = {
          type: 'relative-time',
          value: relativeValue,
          code: generateReminderCode('relative-time', relativeValue)
        };
        break;
      case 'progress':
        reminder = {
          type: 'progress-based',
          value: progressValue.toString(),
          code: generateReminderCode('progress-based', progressValue.toString())
        };
        break;
      default:
        throw new Error('Tipo de recordatorio no válido');
    }

    const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/update_task_reminder.php`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        task_id: task.id,
        reminder_code: reminder.code,
        reminder_type: reminder.type,
        reminder_value: reminder.value,
        user_id: currentUser
      })
    });

    if (!response.ok) throw new Error('Error al guardar recordatorio');

    const result = await response.json();
    onChangeTask({
      ...task,
      reminder_code: reminder.code,
      reminder_type: reminder.type,
      reminder_value: reminder.value,
      reminder_calculated_date: result.reminder_calculated_date,
      reminder_active: true,
      reminder_triggered: false
    });

    toast.success('Recordatorio configurado exitosamente');
    setShowReminderPicker(false);
    onClose()
  } catch (error) {
    console.error('Error:', error);
    toast.error('Error al configurar recordatorio');
  } finally {
    setIsLoadingReminder(false);
  }
};


  const renderTrackingContent = () => (
    <div className="p-6 space-y-6">
      <div className="bg-white p-4 rounded-lg border">
        <h4 className="font-medium mb-2">Configuración de Recordatorio</h4>
        
        <div className="flex gap-4 mb-6">
          {[
            { id: 'date', label: 'Fecha Específica', icon: Calendar },
            { id: 'relative', label: 'Tiempo Relativo', icon: Clock },
            { id: 'progress', label: 'Por Progreso', icon: AlertTriangle }
          ].map(({ id, label, icon: Icon }) => (
            <button
              key={id}
              onClick={() => setReminderType(id as typeof reminderType)}
              className={`flex-1 p-4 rounded-lg border-2 transition-all ${
                reminderType === id 
                  ? 'border-teal-500 bg-teal-50 text-teal-700' 
                  : 'border-gray-200 hover:border-teal-200'
              }`}
              disabled={task.isClosed}
            >
              <Icon className="w-6 h-6 mx-auto mb-2" />
              <span className="text-sm font-medium block text-center">{label}</span>
            </button>
          ))}
        </div>

        {reminderType === 'date' && (
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Seleccionar Fecha
            </label>
            <input
              type="date"
              value={selectedDate}
              onChange={e => setSelectedDate(e.target.value)}
              min={dateUtils.getTodayString()}
              className="w-full rounded-md border-gray-300"
              disabled={task.isClosed}
            />
          </div>
        )}

        {reminderType === 'relative' && (
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Repetir Cada
            </label>
            <div className="flex gap-4">
              <input
                type="number"
                min="1"
                value={timeValue}
                onChange={e => setTimeValue(parseInt(e.target.value))}
                className="w-24 rounded-md border-gray-300"
                disabled={task.isClosed}
              />
              <select
                value={timeUnit}
                onChange={e => setTimeUnit(e.target.value as 'days' | 'weeks' | 'months')}
                className="rounded-md border-gray-300"
                disabled={task.isClosed}
              >
                <option value="days">Días</option>
                <option value="weeks">Semanas</option>
                <option value="months">Meses</option>
              </select>
            </div>
          </div>
        )}

        {reminderType === 'progress' && (
          <div className="space-y-4">
            {renderProgressSelector ()}
          </div>
        )}
      </div>

      <div className="flex justify-end">
        <button
          onClick={handleReminderSave}
          disabled={isLoadingReminder || task.isClosed}
          className="bg-teal-600 text-white hover:bg-teal-700"
        >
          {isLoadingReminder ? (
            <>
              <Loader2 className="w-4 h-4 mr-2 animate-spin" />
              Guardando...
            </>
          ) : (
            'Guardar Recordatorio'
          )}
        </button>
      </div>
    </div>
  );



  const parseExistingReminder = (code: string) => {
    if (code.startsWith('f')) {
      setReminderType('date');
      setSelectedDate(code.substring(1));
    } else if (code.startsWith('rt')) {
      setReminderType('relative');
      const [value, unit] = code.substring(2).split(':');
      setTimeValue(parseInt(value));
      setTimeUnit(unit as 'days' | 'weeks' | 'months');
    } else if (code.startsWith('p')) {
      setReminderType('progress');
      setProgressValue(parseInt(code.substring(1)));
    }
  };








const parseReminderCode = (reminderCode: string | null): {
  type: string | null;
  value: string | null;
} => {
  if (!reminderCode) return { type: null, value: null };

  if (reminderCode.startsWith('f-')) {
    return {
      type: 'specific-date',
      value: reminderCode.substring(2)
    };
  }

  if (reminderCode.startsWith('rt-')) {
    return {
      type: 'relative-time',
      value: reminderCode.substring(3)
    };
  }

  if (reminderCode.startsWith('p-')) {
    return {
      type: 'progress-based',
      value: reminderCode.substring(2)
    };
  }

  if (reminderCode.startsWith('exp-')) {
    return {
      type: 'task-expired',
      value: reminderCode.substring(4)
    };
  }

  if (reminderCode.startsWith('closed-')) {
    return {
      type: 'task-closed',
      value: reminderCode.substring(7)
    };
  }

  return { type: null, value: null };
};

// Función para verificar el estado del recordatorio
const checkReminderStatus = (item: Task | SubTask): {
  isActive: boolean;
  isTriggered: boolean;
} => {
  if (!item.reminder_code) return { isActive: false, isTriggered: false };

  const { type, value } = parseReminderCode(item.reminder_code);
  if (!type || !value) return { isActive: false, isTriggered: false };

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  switch (type) {
    case 'specific-date': {
      const reminderDate = new Date(value);
      reminderDate.setHours(0, 0, 0, 0);
      return {
        isActive: true,
        isTriggered: today >= reminderDate
      };
    }

    case 'relative-time': {
      const [amount, unit] = value.split(/(\d+)/).filter(Boolean);
      const reminderDate = new Date();
      if (unit === 'w') {
        reminderDate.setDate(reminderDate.getDate() + (parseInt(amount) * 7));
      } else {
        reminderDate.setDate(reminderDate.getDate() + parseInt(amount));
      }
      return {
        isActive: true,
        isTriggered: today >= reminderDate
      };
    }

    case 'progress-based': {
      const targetProgress = parseInt(value);
      return {
        isActive: true,
        isTriggered: (item.progreso || 0) >= targetProgress
      };
    }

    case 'task-expired':
    case 'task-closed': {
      return {
        isActive: true,
        isTriggered: true
      };
    }

    default:
      return { isActive: false, isTriggered: false };
  }
};

const getReminderStatus = (item: Task | SubTask) => {
  // Si no hay recordatorio configurado
  if (!item.reminder_type || !item.reminder_value) {
    return {
      color: 'bg-gray-200',
      icon: null,
      tooltipText: 'Sin recordatorio'
    };
  }

  // Si el recordatorio está activo y se ha disparado
  if (item.reminder_active && item.reminder_triggered) {
    return {
      color: 'bg-red-500',
      icon: getReminderTypeIcon(item),
      tooltipText: getReminderTooltipText(item)
    };
  }

  // Si el recordatorio está activo pero no se ha disparado
  if (item.reminder_active && !item.reminder_triggered) {
    return {
      color: 'bg-yellow-500',
      icon: getReminderTypeIcon(item),
      tooltipText: getReminderTooltipText(item)
    };
  }

  // Si el recordatorio está inactivo
  return {
    color: 'bg-gray-200',
    icon: getReminderTypeIcon(item),
    tooltipText: 'Recordatorio inactivo'
  };
};



// Función para obtener el texto del tooltip
const getReminderTooltipText = (item: Task | SubTask): string => {
  if (!item.reminder_type || !item.reminder_value) {
    return 'Sin recordatorio';
  }

  switch (item.reminder_type) {
    case 'specific-date':
      return `Recordatorio para: ${formatDate(item.reminder_calculated_date || '')}`;
    case 'relative-time': {
      const value = item.reminder_value;
      const unit = value.endsWith('w') ? 'semanas' : 'días';
      const amount = value.slice(0, -1);
      return `Recordatorio en ${amount} ${unit}`;
    }
    case 'progress-based':
      return `Recordar al ${item.reminder_value}% de progreso`;
    case 'task-expired':
      return 'Tarea vencida';
    case 'task-closed':
      return 'Tarea cerrada';
    default:
      return 'Sin recordatorio';
  }
};



// Función para obtener el ícono
const getReminderTypeIcon = (item: Task | SubTask) => {
  if (!item.reminder_type) return null;

  switch (item.reminder_type) {
    case 'specific-date':
      return <Calendar className="w-4 h-4" />;
    case 'relative-time':
      return <Clock className="w-4 h-4" />;
    case 'progress-based':
      return <AlertTriangle className="w-4 h-4" />;
    case 'task-expired':
      return <AlertTriangle className="w-4 h-4" />;
    case 'task-closed':
      return <Check className="w-4 h-4" />;
    default:
      return null;
  }
};







  const [updateTrigger, setUpdateTrigger] = useState(0);
/*
  const handleCloseTask = async () => {
    if (task.semaphoreStatus === SemaphoreStatus.Gray) {
      alert("No se puede cerrar una tarea que aún no ha iniciado.");
      return;
    }
  
    // Crear fecha actual en zona horaria local
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    // Formatear la fecha a YYYY-MM-DD manteniendo la zona horaria local
    const formattedDate = today.getFullYear() + '-' + 
      String(today.getMonth() + 1).padStart(2, '0') + '-' + 
      String(today.getDate()).padStart(2, '0');
  
    const updatedTask = { 
      ...task, 
      isClosed: true, 
      progress: 100, 
      end: formattedDate 
    };
    
    try {
      // Actualizar la tarea en la base de datos
      await updateTaskInDatabase(updatedTask);
      onChangeTask(updatedTask);
      setUpdateTrigger(prev => prev + 1); // Forzar re-renderizado
  
      // Calcular y actualizar el progreso del agrupador
      // await updateAgrupadorProgress(task.id);
      
      onChangeTask(updatedTask);
    } catch (error) {
      console.error('Error al cerrar la tarea:', error);
      alert('Hubo un error al cerrar la tarea. Por favor, inténtelo de nuevo.');
    }
  };
*/


useEffect(() => {
  if (isOpen) {
    loadComments();
  } else {
    setIsCommentsInitialized(false);
  }
}, [isOpen, loadComments]);

useEffect(() => {
  if (commentsEndRef.current) {
    commentsEndRef.current.scrollIntoView({ behavior: 'smooth' });
  }
}, [comments]);


useEffect(() => {
  if (task.followUpDate) {
    parseExistingReminder(task.followUpDate);
  }
}, [task.followUpDate]);


useEffect(() => {
  const checkAndCloseMainTask = async () => {
    if (
      task.nombre === 'TRAMITACIÓN' && 
      task.subtasks && 
      task.subtasks.length > 0 && 
      !task.isClosed
    ) {
      const allSubtasksClosed = task.subtasks.every(subtask => subtask.isClosed);
      
      if (allSubtasksClosed) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        
        const formattedDate = today.getFullYear() + '-' + 
          String(today.getMonth() + 1).padStart(2, '0') + '-' + 
          String(today.getDate()).padStart(2, '0');

        const updatedTask = { 
          ...task, 
          isClosed: true, 
          progreso: 100, 
          fecha_termino: formattedDate 
        };
        
        try {
          
          await updateTaskInDatabase(updatedTask);
          onChangeTask(updatedTask);
          setUpdateTrigger(prev => prev + 1);
        } catch (error) {
          console.error('Error al cerrar la tarea automáticamente:', error);
        }
      }
    }
  };

  checkAndCloseMainTask();
}, [task.subtasks]);



useEffect(() => {
  if (isOpen) {
    refreshTaskData();
  }
}, [isOpen]);




  // Función para cargar los datos más recientes de la tarea
  const refreshTaskData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/get_task.php?taskId=${task.id}`);
      if (!response.ok) throw new Error('Error fetching task data');
      
      const updatedTaskData = await response.json();
      if (updatedTaskData.success) {
        const refreshedTask = {
          ...task,
          ...updatedTaskData.task,
          fecha_inicio: updatedTaskData.task.fecha_inicio,
          fecha_termino: updatedTaskData.task.fecha_termino
        };
        
        setLocalTask(refreshedTask);
        setLocalStartDate(refreshedTask.fecha_inicio);
        setLocalEndDate(refreshedTask.fecha_termino);
        setEditedName(refreshedTask.nombre);
        onChangeTask(refreshedTask); // Actualizar el estado global
      }
    } catch (error) {
      console.error('Error refreshing task data:', error);
    }
  };


  const getMaxEndDate = (dependencies: number[]): Date | null => {
    const dates = dependencies.map((depIndex: number) => {
      const predecessorTask = allTasks[depIndex - 1];
      if (predecessorTask) {
        const [year, month, day] = predecessorTask.fecha_termino?predecessorTask.fecha_termino.split('-').map(Number) : [0, 0 ,0];
        return new Date(year, month - 1, day);
      }
      return null;
    }).filter(Boolean) as Date[];

    return dates.length > 0 ? new Date(Math.max(...dates.map(date => date.getTime()))) : null;
  };

  const handleCloseTask = async () => {
    try {
      const today = dateUtils.getTodayString();
      const closedTaskId = task.id;
      const closedTaskOrden = task.orden;
  
      const updatedTask = {
        ...localTask,
        isClosed: true,
        progreso: 100,
        fecha_termino: today,
        comments: task.comments
      };
  
      // Actualizar en base de datos la tarea actual
      await updateTaskInDatabase({
        ...updatedTask,
        id: updatedTask.id,
        isClosed: true,
        progreso: 100,
        fecha_inicio: updatedTask.fecha_inicio,
        fecha_termino: today,
        followUpDate: updatedTask.followUpDate,
        semaphoreStatus: updatedTask.semaphoreStatus,
        comments: updatedTask.comments,
      });
  
      // Identificar tareas dependientes de la tarea cerrada
      const dependentTasks = allTasks.filter(task => {
        return task.orden === closedTaskOrden + 1;
      });
  
      // Procesar cada tarea dependiente
      for (const dependentTask of dependentTasks) {
        console.log(`Procesando tarea dependiente: ${dependentTask.nombre}`);
  
        // Calcular nueva fecha de término basada en duración
        const startDate = new Date(today);
        const endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + (dependentTask.duracion * 8));
  
        // Preparar datos de actualización para la tarea dependiente
        const dependentTaskUpdateData = {
          ...dependentTask,
          id: dependentTask.id,
          fecha_inicio: today,
          fecha_termino: dateUtils.formatLocalDate(endDate),
          isClosed: false,
          progreso: 0,  
          semaphoreStatus: SemaphoreStatus.Green // Establecer en verde
        };
  
        try {
          // Actualizar la tarea dependiente en la base de datos
          await updateTaskInDatabase(dependentTaskUpdateData);
      
          // Si es una tarea de TRAMITACIÓN, actualizar también su primera subtarea
          if (
            dependentTask.nombre === 'TRAMITACIÓN' &&
            Array.isArray(dependentTask.subtasks) &&
            dependentTask.subtasks.length > 0
          ) {
            const firstSubtask = dependentTask.subtasks[0];
          
            const subtaskUpdateData = {
              ...firstSubtask,
              id: firstSubtask.id,
              tarea_id: dependentTask.id,
              start: today,
              end: dateUtils.formatLocalDate(endDate),
              isClosed: false,
              progreso: 0,
              semaphoreStatus: SemaphoreStatus.Green
            };
          
            // Actualizar la subtarea en la base de datos
            await updateSubtaskInDB(subtaskUpdateData);
          }
          
      
          // Actualizar el estado en la interfaz
          onChangeTask({
            ...dependentTask,
            ...dependentTaskUpdateData
          });
      
  
          console.log(`Tarea ${dependentTask.nombre} actualizada:`);
          console.log(dependentTaskUpdateData);
        } catch (error) {
          console.error(`Error al actualizar tarea dependiente ${dependentTask.nombre}:`, error);
          toast.error(`Error al actualizar tarea ${dependentTask.nombre}`);
        }
      }
  
      // Actualizar estado global de la tarea cerrada
      onChangeTask(updatedTask);
  
      // Cerrar modal actual
      onClose();
  
      toast.success('Tarea cerrada exitosamente');
  
    } catch (error) {
      console.error('Error al cerrar la tarea:', error);
      toast.error('Error al cerrar la tarea');
      setLocalTask(task);
      setLocalEndDate(task.fecha_termino);
    }
  };

const canCloseTramitacionTask = (task: Task): boolean => {
  if (task.nombre !== 'TRAMITACIÓN') return true;
  if (!task.subtasks || task.subtasks.length === 0) return true;
  
  return task.subtasks.every(subtask => subtask.isClosed);
};

// Función para actualizar las fechas de las subtareas
/*const updateSubtasksDates = (subtasks: SubTask[]): SubTask[] => {
  const today = new Date();
  const formattedDate = today.toISOString().split('T')[0];

  return subtasks.map(subtask => ({
    ...subtask,
    end: subtask.isClosed ? subtask.end : formattedDate // Solo actualizar fechas de subtareas abiertas
  }));
};
*/

// Función para abrir una tarea
const handleOpenTask = async () => {
  try {
    // Usar dateUtils para parsear la fecha de inicio
    const startDate = dateUtils.parseLocalDate(task.fecha_inicio);
    
    // Calcular la nueva fecha de término usando dateUtils
    const newEndDate = new Date(startDate);
    newEndDate.setDate(startDate.getDate() + (task.duracion * 7));
    const formattedEndDate = dateUtils.formatLocalDate(newEndDate);

    const updatedTask = { 
      ...task, 
      isClosed: false,
      progreso: 0,
      fecha_termino: formattedEndDate
    };

    // Actualizar la tarea en la base de datos
    await updateTaskInDatabase(updatedTask);
    // Actualizar el estado local
    onChangeTask(updatedTask);
    setUpdateTrigger(prev => prev + 1);
    onChangeTask(updatedTask);

  } catch (error) {
    console.error('Error al abrir la tarea:', error);
    alert('Hubo un error al abrir la tarea. Por favor, inténtelo de nuevo.');
  }
};

  const handleResetFollowUpDate = () => {
    onChangeTask({ ...task, followUpDate: '' });
  };



  // Función para actualizar la subtarea en la base de datos
  const updateSubtaskInDB = async (subtaskData: any) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/subtask_operations.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'update',
          subtask: subtaskData,
          fecha_inicio: subtaskData.start,
          fecha_termino: subtaskData.end, // Asegurar que la fecha de término se envíe correctamente
          resolucion_tipo: subtaskData.resolucion_tipo || null // Ensure we always send the field

        })
      });
   //   console.log(JSON.stringify(subtaskData))
      if (!response.ok) {
        throw new Error('Error al actualizar la subtarea');
      }

      return await response.json();
    } catch (error) {
      console.error('Error en updateSubtaskInDB:', error);
      throw error;
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {

    if (e.key === 'Enter') {
      e.preventDefault();
      const isValidDate = (dateStr: string) => {
        if (!dateStr || !/^\d{4}-\d{2}-\d{2}$/.test(dateStr)) {
          return false;
        }
        const [year, month, day] = dateStr.split('-').map(Number);
        const date = new Date(year, month - 1, day);
        return !(
          isNaN(date.getTime()) ||
          date.getFullYear() !== year ||
          date.getMonth() + 1 !== month ||
          date.getDate() !== day
        );
      };
  
      // Validar fecha inicio
      if (!isValidDate(localStartDate)) {
        toast.error("La fecha de inicio no es válida");
        return;
      }
  
      // Validar fecha término
      if (!isValidDate(localEndDate)) {
        toast.error("La fecha de término no es válida");
        return;
      }
  
      // Validar orden de fechas
      if (new Date(localEndDate) < new Date(localStartDate)) {
        toast.error("La fecha de término no puede ser anterior a la fecha de inicio.");
        return;
      }
  
      handleSaveChanges();
    }
  };

  const handleAddComment = async () => {
    if (!newComment.trim()) return;
  
    try {
      setIsLoadingComments(true);
      
      const timestamp = new Date().toLocaleString('es-CL', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
      });
  
      const commentWithTimestamp = `[${timestamp}] ${storedFirstName} ${storedLastName}: ${newComment}`;
      
      // Parse existing comments
      let currentComments = [];
      try {
        currentComments = task.comments ? JSON.parse(task.comments) : [];
      } catch (error) {
        console.error('Error parsing existing comments:', error);
        currentComments = [];
      }
  
      // Add new comment
      const updatedComments = Array.isArray(currentComments) 
      ? [...currentComments, commentWithTimestamp]
      : [commentWithTimestamp];
      
    const newCommentsString = JSON.stringify(updatedComments);
      // Create updated task with new comments
      const updatedTask = {
        ...task,
        comments: newCommentsString
      };
  
      // Update database first
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/update_task.php`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          id: task.id,
          nombre: task.nombre,
          fecha_inicio: task.fecha_inicio,
          fecha_termino: task.fecha_termino,
          progreso: task.progreso,
          comments: newCommentsString,
          isClosed: task.isClosed,
          followUpDate: task.followUpDate,
          semaphoreStatus: task.semaphoreStatus
        })
      });
  
      if (!response.ok) {
        throw new Error('Error al guardar los comentarios');
      }
  
      // After successful database update, update local states
      setLocalTask(updatedTask);
      
      onChangeTask(updatedTask);
      setNewComment('');
      task.comments = newCommentsString;
      setComments(newCommentsString);
      // Auto scroll to latest comment
      if (commentsContainerRef.current) {
        commentsContainerRef.current.scrollTo({
          top: commentsContainerRef.current.scrollHeight,
          behavior: 'smooth'
        });
      }
  
      toast.success('Comentario agregado exitosamente');
    } catch (error) {
      console.error('Error adding comment:', error);
      toast.error('Error al agregar el comentario');
    } finally {
      setIsLoadingComments(false);
    }
  };
  
  
  const handleSaveChanges = async () => {
    try {
      setIsLoading(true);
  
      if (dateUtils.parseLocalDate(localEndDate) < dateUtils.parseLocalDate(localStartDate)) {
        toast.error('La fecha de término no puede ser anterior a la fecha de inicio');
        return;
      }
  
      // Create updated task object
      const updatedTask = {
        ...localTask,
        nombre: editedName,
        fecha_inicio: localStartDate,
        fecha_termino: localEndDate,
        // Preserve existing comments
        comments: localTask.comments
      };
  
      // Update in database
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/update_task.php`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          id: updatedTask.id,
          nombre: updatedTask.nombre,
          fecha_inicio: updatedTask.fecha_inicio,
          fecha_termino: updatedTask.fecha_termino,
          progreso: updatedTask.progreso,
          comments: updatedTask.comments,
          isClosed: updatedTask.isClosed,
          followUpDate: updatedTask.followUpDate,
          semaphoreStatus: updatedTask.semaphoreStatus
        })
      });
  
      if (!response.ok) {
        throw new Error('Error updating task');
      }
  
      // Update local state
      setLocalTask(updatedTask);
      // Update parent state
      onChangeTask(updatedTask);
      
      toast.success('Cambios guardados exitosamente');
      onClose();
    } catch (error) {
      console.error('Error al guardar los cambios:', error);
      toast.error('Error al guardar los cambios');
    } finally {
      setIsLoading(false);
    }
  };
  
  useEffect(() => {
    if (isOpen) {
      setLocalTask(task);
      setLocalStartDate(task.fecha_inicio);
      setLocalEndDate(task.fecha_termino);
      setEditedName(task.nombre);
    }
  }, [isOpen, task]);

  


// Función para actualizar una tarea principal
const updateTaskInDatabase = async (taskData: TaskUpdateData): Promise<void> => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/update_task.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(taskData),
      
    });

    if (!response.ok) {
      throw new Error('Error updating task');
    }

    const result = await response.json();
    if (!result.success) {
      throw new Error(result.message || 'Error updating task');
    }
  } catch (error) {
    console.error('Error in updateTaskInDatabase:', error);
    throw error;
  }
};




const calculateSemaphoreStatus = (
  start: string,
  end: string,
  taskId: number,
  dependencia: string | number | null,
  isClosed: boolean,
  allTasks?: Task[],
  parentTask?: Task
): SemaphoreStatus => {
  // Si la tarea está cerrada, retornar gris
  if (isClosed) {
    return SemaphoreStatus.Gray;
  }

  // Obtener fecha actual y establecer a medianoche
  const today = dateUtils.getTodayLocal();
  today.setHours(0, 0, 0, 0);

  // Convertir fechas de string a Date y establecer a medianoche
  const startDate = dateUtils.parseLocalDate(start);
  const endDate = dateUtils.parseLocalDate(end);
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(0, 0, 0, 0);

  // Validación de fechas
  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
    return SemaphoreStatus.Gray;
  }

  // Si la tarea aún no comienza
  if (startDate > today) {
    return SemaphoreStatus.Gray;
  }

  // Si ya pasó la fecha de fin o es el mismo día de fin
  if (today >= endDate) {
    return SemaphoreStatus.Red;
  }

  // Calcular duración total y días restantes
  const totalDuration = Math.max(1, Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)));
  const daysRemaining = Math.ceil((endDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
  const percentageCompleted = ((totalDuration - daysRemaining) / totalDuration) * 100;

  // Para debug


  // Enfoque híbrido: usar días restantes para tareas cortas y porcentajes para largas
  if (totalDuration <= 5) {
    // Lógica para tareas cortas (5 días o menos)
    if (daysRemaining === 0) {
      return SemaphoreStatus.Red;     // Mismo día = Rojo
    } else if (daysRemaining === 1) {
      return SemaphoreStatus.Orange;  // Un día restante = Naranja
    } else if (daysRemaining <= 3) {
      return SemaphoreStatus.Yellow;  // 2-3 días restantes = Amarillo
    }
    return SemaphoreStatus.Green;     // Más de 3 días = Verde
  } else {
    // Lógica para tareas largas (más de 5 días)
    if (percentageCompleted >= 90) {
      return SemaphoreStatus.Red;     // 90% o más completado = Rojo
    } else if (percentageCompleted >= 75) {
      return SemaphoreStatus.Orange;  // 75-89% completado = Naranja
    } else if (percentageCompleted >= 50) {
      return SemaphoreStatus.Yellow;  // 50-74% completado = Amarillo
    }
    return SemaphoreStatus.Green;     // Menos del 50% completado = Verde
  }
};

const semaphoreStatus = calculateSemaphoreStatus(
  task.fecha_inicio,
  task.fecha_termino,
  task.id,
  task.dependencia,
  task.isClosed,
  allTasks
);


  const formatFollowUpDate = (date: string): string => {
    if (!date) return 'No establecida';
    const followUpDate = new Date(date);
    const today = new Date();
    const diffTime = followUpDate.getTime() - today.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays < 0) {
      return `Hace ${Math.abs(diffDays)} días`;
    } else if (diffDays === 0) {
      return 'Hoy';
    } else if (diffDays === 1) {
      return 'Mañana';
    } else {
      return `En ${diffDays} días`;
    }
  };

/*
  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      // Simula la carga de datos (reemplaza esto con tu lógica real)
      setTimeout(() => {
        setIsLoading(false);
      }, 350); // Simula un retraso de 2 segundos
    }
  }, [isOpen]);
  
*/
  return (
    <AnimatePresence>
   {isOpen && (
     
         
         isLoading ? (
          <div className="fixed inset-0 flex justify-center items-center z-[10002]">
              <Loader2 className="animate-spin w-8 h-8 text-gray-500" />
            </div>
          ) : (

            <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            key={modalKey}
            variants={contentVariants}
            className="fixed inset-0 flex items-center justify-center  z-[10002] "
          >
          
            <motion.div className="rounded-xl bg-white rounded-lg w-full max-w-2xl shadow-xl z-[10001]">
         
         
          <Dialog isOpen={isOpen} onClose={onClose } width="600px" className=" z-[10001]">
          <motion.div 
            className="bg-white rounded-lg overflow-hidden shadow-xl"
            variants={contentVariants}
          >
     

            <div
              className="bg-teal-600 p-3 text-white"
             
            >
                     <div className="relative">
  {/* Botón de cierre */}
  <button
    onClick={onClose}
    className="absolute top-2 right-2 z-[500] text-white/80 hover:text-white focus:outline-none"
  >
    <X size={20} />
  </button>
</div>
    <h3 className="text-base font-semibold flex items-center space-x-2">
      <MdBusiness className="text-white w-5 h-5" /> 
      <span className="text-sm opacity-80 tracking-wide">
        {`${processName?.toUpperCase()} / ${subprocessName?.toUpperCase()} / ${agrupadorName?.toUpperCase()}`}
      </span>
    </h3>
    <div className="flex mt-4 mb-3">

    <div className="flex items-center">
      <span className="text-lg font-bold text-white">{task.nombre}</span>
    </div>
  {/* Estado de la tarea */}
<div className="ml-3 flex items-center">
  <div
    className={`px-3 py-1 rounded-full text-sm font-medium ${
      task.progreso === 100 && task.isClosed
        ? `${backgroundColor} text-gray-800` // Estilo para tareas cerradas
        : semaphoreStatus === SemaphoreStatus.Red
        ? 'bg-red-100 text-red-700'
        : semaphoreStatus === SemaphoreStatus.Orange
        ? 'bg-orange-100 text-orange-700'
        : semaphoreStatus === SemaphoreStatus.Yellow
        ? 'bg-yellow-100 text-yellow-700'
        : semaphoreStatus === SemaphoreStatus.Green
        ? 'bg-green-100 text-green-700'
        : 'bg-gray-100 text-gray-700'
    }`}
       style={
      task.progreso === 100 && task.isClosed
        ? {
            backgroundImage: `repeating-conic-gradient(
              ${lightenColor(backgroundColor, 100)} 0% 25%, 
              #ffffff 25% 50%
            )`,
            backgroundSize: '10px 10px', // Tamaño del damero
          }
        : undefined
    }
  > 
 
    {task.progreso === 100 && task.isClosed
      ? 'Cerrada'
      : semaphoreStatus === SemaphoreStatus.Red
      ? `Retrasada por ${daysDelayed} día(s)`
      : semaphoreStatus === SemaphoreStatus.Orange
      ? 'Próxima a vencer'
      : semaphoreStatus === SemaphoreStatus.Yellow
      ? 'A tiempo, pero próxima'
      : semaphoreStatus === SemaphoreStatus.Green
      ? 'A tiempo'
      : 'No iniciada'}
  </div>
</div>

    </div>
    <div className="flex items-center gap-4 text-white/70 text-xs mt-1">
      <span className="flex items-center gap-1">
        <Calendar className="w-4 h-4" />
        {formatDateDDMMYYYY(task.fecha_inicio)}
      </span>
      <span className="flex items-center gap-1">
        <Clock className="w-4 h-4" />
        {formatDateDDMMYYYY(task.fecha_termino)}
      </span>

    </div>
 
    </div>
  
  </motion.div>

<div className="border-b">
          <nav className="flex">
            <button
              onClick={() => setActiveTab('details')}
              className={`px-4 py-2 ${activeTab === 'details' ? 'border-b-2 border-teal-500' : ''}`}
            >
              Detalles
            </button>
            <button
              onClick={() => setActiveTab('followup')}
              className={`px-4 py-2 ${activeTab === 'followup' ? 'border-b-2 border-teal-500' : ''}`}
            >
              Seguimiento
            </button>
          </nav>
        </div>

        {activeTab === 'details' ? (
  <div className="p-4">
   

    {/* Grid de campos */}
    <div className="grid grid-cols-2 gap-3 mb-4">
      <div>
        <label className="block text-xs font-medium text-gray-700 mb-1">
          Nombre
        </label>
        <input
          type="text"
          value={editedName || ''}
          onChange={handleLocalNameChange}
          className="w-full text-sm rounded border-gray-300 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
          disabled={task.isClosed}
        />
      </div>

      <div></div>

      <div>
        <label className="block text-xs font-medium text-gray-700 mb-1">
          Fecha de Inicio
        </label>
        <input
          type="date"
          name="fecha_inicio"
          value={localStartDate}
          onChange={handleDateChange}
          onKeyDown={handleKeyDown}
          className="w-full text-sm rounded border-gray-300 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
          disabled={task.isClosed && task.progreso === 100}
        />
      </div>
      <div>
        <label className="block text-xs font-medium text-gray-700 mb-1">
          Fecha de Término
        </label>
        <input
          type="date"
          name="end"
          value={localEndDate}
          onChange={handleDateChange}
          onKeyDown={handleKeyDown}
          className="w-full text-sm rounded border-gray-300 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
          disabled={task.isClosed && task.progreso === 100}
        />
      </div>
    </div>

    {/* Actualizar sección de comentarios */}
    <div className="comments-section">
      <div className="flex items-center justify-between mb-2">
        <h3 className="text-lg font-medium">Comentarios</h3>
        {isLoadingComments && <Loader2 className="animate-spin h-4 w-4" />}
      </div>

      <div
        ref={commentsContainerRef}
        className="max-h-60 overflow-y-auto border rounded-md p-4 bg-gray-50"
      >
        {task.comments ? (
          (() => {
            try {
              const parsedComments = JSON.parse(task.comments);
              return Array.isArray(parsedComments) &&
                parsedComments.length > 0 ? (
                parsedComments.map((comment, index) => {
                  const [datePart, userAndContent] = comment.split('] ');
                  const date = datePart.replace('[', '').trim();
                  const [user, ...contentParts] = userAndContent.split(':');
                  const content = contentParts.join(':').trim();
                  const isCurrentUser =
                    user === `${storedFirstName} ${storedLastName}`;
                
                  return (
                    <div
                      key={index}
                      className={`mb-3 last:mb-0 flex ${
                        !isCurrentUser ? 'justify-end' : 'justify-start'
                      }`}
                    >
                      <div
                        className={`max-w-fit p-3 rounded-lg shadow-md break-words ${
                          isCurrentUser
                            ? 'bg-green-100 text-green-900'
                            : 'bg-blue-100 text-blue-900'
                        }`}
                        style={{
                          maxWidth: 'calc(100% - 20px)', // Asegura que el texto no exceda el ancho del contenedor principal
                          wordBreak: 'break-word', // Permite dividir palabras largas
                        }}
                      >
                        <div className="flex items-center gap-2 text-xs text-gray-600 mb-1">
                          <span className="font-medium">{user}</span>
                          <span className="text-gray-400">•</span>
                          <span className="text-gray-400">{date}</span>
                        </div>
                        <p className="text-sm whitespace-pre-wrap">{content}</p>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p className="text-gray-500 italic">No hay comentarios</p>
              );
            } catch (error) {
              return (
                <p className="text-red-500">Error al cargar comentarios</p>
              );
            }
          })()
        ) : (
          <p className="text-gray-500 italic">No hay comentarios</p>
        )}
      </div>

      <div className="mt-4 flex gap-2">
        <textarea
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          onKeyDown={async (e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              if (newComment.trim() && !task.isClosed && !isLoadingComments) {
                await handleAddComment();
                setNewComment('');
              }
            }
          }}
          placeholder="Escribir un comentario..."
          rows={2}
          className="w-full rounded-md border border-gray-300 px-3 py-2 resize-none overflow-y-auto"
          disabled={task.isClosed}
          maxLength={250}
        />
        <button
          onClick={async () => {
            await handleAddComment();
            setNewComment(''); // Limpiar input después de agregar el comentario
          }}
          disabled={task.isClosed || !newComment.trim() || isLoadingComments}
          className="px-4 py-2 bg-teal-600 text-white rounded-md hover:bg-teal-700 disabled:opacity-50"
        >
          Agregar
        </button>
      </div>
    </div>
     {/* Botones de acción */}
     <div className="flex justify-between mt-4 pt-2 border-t">
     <button
  type="button"
  className={`px-3 py-1.5 rounded text-sm font-medium ${
    task.isClosed
      ? 'bg-green-600 hover:bg-green-700 text-white'
      :  task.progreso === 100 || 
        (task.nombre === 'TRAMITACIÓN' && task.subtasks && task.subtasks.length > 0)
      ? 'bg-gray-400 text-white cursor-not-allowed'
      : 'bg-red-600 hover:bg-red-700 text-white'
  }`}
  onClick={task.isClosed ? handleOpenTask : handleCloseTask}
  disabled={
    !task.isClosed && 
    (task.progreso === 100 || 
     (task.nombre === 'TRAMITACIÓN' && task.subtasks && task.subtasks.length > 0))
  }
>
                  {task.isClosed ? "ABRIR TAREA" : "CERRAR TAREA"}
                </button>
                <button
  type="button"
  onClick={() => {
    // Función para validar si una fecha existe
    const isValidDate = (dateStr: string) => {
      if (!dateStr || !/^\d{4}-\d{2}-\d{2}$/.test(dateStr)) {
        return false;
      }
      const [year, month, day] = dateStr.split('-').map(Number);
      const date = new Date(year, month - 1, day);
      return !(
        isNaN(date.getTime()) ||
        date.getFullYear() !== year ||
        date.getMonth() + 1 !== month ||
        date.getDate() !== day
      );
    };

    // Validar fecha inicio
    if (!isValidDate(localStartDate)) {
      toast.error("La fecha de inicio no es válida");
      return;
    }

    // Validar fecha término
    if (!isValidDate(localEndDate)) {
      toast.error("La fecha de término no es válida");
      return;
    }

    // Validar orden de fechas
    if (new Date(localEndDate) < new Date(localStartDate)) {
      toast.error("La fecha de término no puede ser anterior a la fecha de inicio.");
      return;
    }

    handleSaveChanges();
  }}
  className="px-3 py-1.5 bg-blue-600 text-white text-sm font-medium rounded hover:bg-blue-700"
>
  Guardar Cambios
</button>
              </div>
  </div>
  
) :            
<motion.div
initial="hidden"
animate="visible"
exit="exit"
variants={modalVariants}
className="space-y-4 "
>  { 
renderTrackingContent()
}
      
</motion.div>
}
</Dialog>

</motion.div>    

</motion.div> 
)
             
          
)}
    </AnimatePresence>
  );
};

export type ResolutionType = 
  | 'APROBADO' 
  | 'RECHAZADO' 
  | 'DESISTIMIENTO' 
  | 'SILENCIO ADMINISTRATIVO POSITIVO' 
  | 'SILENCIO ADMINISTRATIVO NEGATIVO' 
  | 'NO ADMITIDO' 
  | 'OTRO';


const SubtaskModal: React.FC<SubtaskModalProps> = ({
  isOpen,
  onClose,
  subtask,
  onChangeTask,
  onChangeSubtask,
  onAddNewSubtask,
  backgroundColor,
  mainTask,
  groupProgress,
  processName,
  subprocessName,
  agrupadorName,
  daysDelayed
}) => {
  const [activeTab, setActiveTab] = useState<'details' | 'followup'>('details');
  const [editedName, setEditedName] = useState(subtask.name);
  const [comments, setComments] = useState<string[]>([]);
  const [newComment, setNewComment] = useState('');
  const [isLoadingComments, setIsLoadingComments] = useState(false);
  const [localStartDate, setLocalStartDate] = useState(subtask.start);
  const [localEndDate, setLocalEndDate] = useState(subtask.end);
  const [isLoading, setIsLoading] = useState(false);
  const commentsEndRef = useRef<HTMLDivElement>(null);
  const commentsContainerRef = useRef<HTMLDivElement>(null);
  const storedFirstName = sessionStorage.getItem('firstName');
  const storedLastName = sessionStorage.getItem('lastName');
  const [showResolutionTypeDialog, setShowResolutionTypeDialog] = useState(false);
  const [showNewSubtaskDialog, setShowNewSubtaskDialog] = useState(false);
  const [selectedResolutionType, setSelectedResolutionType] = useState('');
  const [selectedNewSubtaskType, setSelectedNewSubtaskType] = useState<NewSubtaskOption | null>(null);
  const formatDateDDMMYYYY = (dateInput: string | Date): string => {
    // Si es un string, parsearlo correctamente
    if (typeof dateInput === 'string') {
      // Asumiendo que el input viene en formato 'YYYY-MM-DD'
      const [year, month, day] = dateInput.split('-').map(Number);
      // Crear la fecha usando la zona horaria local
      return `${String(day).padStart(2, '0')}/${String(month).padStart(2, '0')}/${year}`;
    }
    
    // Si es un objeto Date
    const day = String(dateInput.getDate()).padStart(2, '0');
    const month = String(dateInput.getMonth() + 1).padStart(2, '0');
    const year = dateInput.getFullYear();
    return `${day}/${month}/${year}`;
  };


  // Función auxiliar para extraer el número de una observación o respuesta
const extractNumber = (name: string): number => {
  const match = name.match(/\d+$/);
  return match ? parseInt(match[0]) : 0;
};

const getNextSubtaskOptions = (
  currentType: string,
  parentTask: Task,
  currentSubtask: SubTask
): NewSubtaskOption[] => {
  // Helper function to generate correct name
  const generateName = (type: 'OBSERVACIÓN' | 'RESPUESTA' | 'RESOLUCIÓN'): string => {
    if (type === 'RESOLUCIÓN') return 'RESOLUCIÓN';
    
    const existingSubtasks = parentTask.subtasks || [];
    const currentNumber = extractNumber(currentSubtask.name);
    
    if (type === 'OBSERVACIÓN') {
      return `OBSERVACIÓN ${currentNumber + 1}`;
    } else {
      return `RESPUESTA ${currentNumber}`;
    }
  };

  switch (currentType) {
    case 'INGRESO':
      return [
        {
          type: 'OBSERVACIÓN',
          name: 'OBSERVACIÓN 1',
          resolutionType: '',
          responsable: [],
          organismo: mainTask.organismo || ''
        },
        {
          type: 'RESOLUCIÓN',
          name: generateName('RESOLUCIÓN'),
          resolutionType: '',
          responsable: [],
          organismo: mainTask.organismo || ''
        }
      ];

    case 'OBSERVACIÓN':
      const observationNumber = parseInt(subtask.name.split(' ')[1]) || 1;
      return [
        {
          type: 'RESPUESTA',
          name: `RESPUESTA ${observationNumber}`,
          resolutionType: '',
          responsable: Array.isArray(mainTask.responsable) ? mainTask.responsable : [],
          organismo: mainTask.organismo || ''
        },
        {
          type: 'RESOLUCIÓN',
          name: 'RESOLUCIÓN',
          resolutionType: '',
          responsable: [],
          organismo: mainTask.organismo || ''
        }
      ];

    case 'RESPUESTA':
      const responseNumber = parseInt(subtask.name.split(' ')[1]) || 1;
      return [
        {
          type: 'OBSERVACIÓN',
          name: `OBSERVACIÓN ${responseNumber + 1}`,
          resolutionType: '',
          responsable: [],
          organismo: mainTask.organismo || ''
        },
        {
          type: 'RESOLUCIÓN',
          name: 'RESOLUCIÓN',
          resolutionType: '',
          responsable: [],
          organismo: mainTask.organismo || ''
        }
      ];

    default:
      return [];
  }
};

const nextOptions = getNextSubtaskOptions(subtask.type, mainTask, subtask);

  const lightenColor = (color: string, amount: number = 0.2): string => {
    // Convertir el color hexadecimal a RGB
    let [r, g, b] = color.match(/\w\w/g)?.map((c) => parseInt(c, 16)) || [0, 0, 0];
    
    // Aumentar los valores RGB para aclarar el color
    r = Math.max(0, Math.min(255, r + Math.round(255 * amount)));
    g = Math.max(0, Math.min(255, g + Math.round(255 * amount)));
    b = Math.max(0, Math.min(255, b + Math.round(255 * amount)));
    
    // Convertir de vuelta a hexadecimal
    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
  };
  

  
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      const isValidDate = (dateStr: string) => {
        if (!dateStr || !/^\d{4}-\d{2}-\d{2}$/.test(dateStr)) {
          return false;
        }
        const [year, month, day] = dateStr.split('-').map(Number);
        const date = new Date(year, month - 1, day);
        return !(
          isNaN(date.getTime()) ||
          date.getFullYear() !== year ||
          date.getMonth() + 1 !== month ||
          date.getDate() !== day
        );
      };
  
      // Validar fecha inicio
      if (!isValidDate(localStartDate)) {
        toast.error("La fecha de inicio no es válida");
        return;
      }
  
      // Validar fecha término
      if (!isValidDate(localEndDate)) {
        toast.error("La fecha de término no es válida");
        return;
      }
  
      // Validar orden de fechas
      if (new Date(localEndDate) < new Date(localStartDate)) {
        toast.error("La fecha de término no puede ser anterior a la fecha de inicio.");
        return;
      }
      handleSaveChanges();
    }
  };


  const modalVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { 
      opacity: 1, 
      scale: 1,
      transition: { type: 'spring', stiffness: 500, damping: 25 }
    },
    exit: { 
      opacity: 0, 
      scale: 0.8,
      transition: { duration: 0.2 }
    }
  };
  

  const isEndDateValid = useMemo(() => {
    const startDate = dateUtils.parseLocalDate(localStartDate);
    const endDate = dateUtils.parseLocalDate(localEndDate);
    return endDate >= startDate;
  }, [localStartDate, localEndDate]);
  


  
  
  // Efectos para manejo de comentarios
  useEffect(() => {
    if (commentsContainerRef.current) {
      commentsContainerRef.current.scrollTop = commentsContainerRef.current.scrollHeight;
    }
  }, [subtask.comments, isOpen]);

  useEffect(() => {
    if (commentsEndRef.current) {
      commentsEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [comments]);


      


// Determina si una subtarea puede modificar su fecha de inicio manualmente
const canModifyStartDate = (subtask: SubTask): boolean => {
  return subtask.type === 'INGRESO' || 
         !subtask.dependsOn || 
         subtask.dependsOn.length === 0;
};


// Función para calcular la fecha de inicio más temprana entre todas las subtareas
const calculateEarliestStartDate = (subtasks: SubTask[]): string => {
  if (!subtasks || subtasks.length === 0) {
    return dateUtils.getTodayString();
  }

  // Encuentra la fecha más temprana entre todas las subtareas
  const earliestDate = subtasks.reduce((earliest, subtask) => {
    const subtaskStartDate = dateUtils.parseLocalDate(subtask.start);
    return subtaskStartDate < earliest ? subtaskStartDate : earliest;
  }, dateUtils.parseLocalDate(subtasks[0].start));

  return dateUtils.formatLocalDate(earliestDate);
};
   

// Calcula la fecha de término más tardía entre todas las subtareas
const calculateLatestEndDate = (subtasks: SubTask[]): string => {
  if (!subtasks || subtasks.length === 0) {
    return dateUtils.getTodayString();
  }

  // Encuentra la fecha más lejana entre todas las subtareas
  const latestDate = subtasks.reduce((latest, subtask) => {
    const subtaskEndDate = dateUtils.parseLocalDate(subtask.end);
    return subtaskEndDate > latest ? subtaskEndDate : latest;
  }, dateUtils.parseLocalDate(subtasks[0].end));

  return dateUtils.formatLocalDate(latestDate);
};

// Calcula las fechas de inicio para subtareas con dependencias
// Calcula las fechas de inicio para subtareas con dependencias
const calculateDependentStartDates = (subtasks: SubTask[]): SubTask[] => {
  const updatedSubtasks = [...subtasks];
  
  // Ordenar por orden para asegurarnos de procesar primero las dependencias
  updatedSubtasks.sort((a, b) => a.orden - b.orden);
  
  // Primero procesar INGRESO porque es la base para las demás
  const ingresoSubtask = updatedSubtasks.find(st => st.type === 'INGRESO');
  
  if (!ingresoSubtask) return updatedSubtasks; // Si no hay INGRESO, no hay cambios necesarios
  
  // Para cada subtarea que no sea INGRESO y tenga dependencias
  updatedSubtasks.forEach(subtask => {
    if (subtask.type !== 'INGRESO' && subtask.dependsOn && subtask.dependsOn.length > 0) {
      // Encontrar todas las subtareas de las que depende
      const dependencies = subtask.dependsOn
        .map(dep => updatedSubtasks.find(s => s.id === dep.subtaskId))
        .filter((dep): dep is SubTask => dep !== undefined); // Filtrar para asegurar que `dep` no sea `undefined`
      
      if (dependencies.length > 0) {
        // Encontrar la fecha de término más tardía entre las dependencias
        const latestDependencyEndDate = dependencies.reduce((latest, dep) => {
          if (!dep) return latest; // Verificar que `dep` no sea undefined
          const endDate = dateUtils.parseLocalDate(dep.end);
          return endDate > latest ? endDate : latest;
        }, dateUtils.parseLocalDate(ingresoSubtask.end)); // Si no hay dependencias, usar INGRESO como fallback
        
        // La fecha de inicio debe ser el día siguiente a la fecha de término más tardía
        const newStartDate = new Date(latestDependencyEndDate);
        newStartDate.setDate(newStartDate.getDate() + 1);
        
        // Actualizar la fecha de inicio de esta subtarea
        subtask.start = dateUtils.formatLocalDate(newStartDate);
        
        // Mantener la misma duración para la subtarea
        const oldStartDate = dateUtils.parseLocalDate(subtask.start);
        const oldEndDate = dateUtils.parseLocalDate(subtask.end);
        const durationDays = Math.round((oldEndDate.getTime() - oldStartDate.getTime()) / (24 * 60 * 60 * 1000));
        
        // Actualizar la fecha de término para mantener la misma duración
        const newEndDate = new Date(newStartDate);
        newEndDate.setDate(newStartDate.getDate() + durationDays);
        subtask.end = dateUtils.formatLocalDate(newEndDate);
      } else if (subtask.type === 'OBSERVACIÓN' || subtask.type === 'RESPUESTA') {
        // Si es OBSERVACIÓN o RESPUESTA y no tiene dependencias explícitas, usar INGRESO como base
        const newStartDate = new Date(dateUtils.parseLocalDate(ingresoSubtask.end));
        newStartDate.setDate(newStartDate.getDate() + 1);
        subtask.start = dateUtils.formatLocalDate(newStartDate);
      }
    }
  });
  
  return updatedSubtasks;
};


  // Manejadores de eventos
  // const handleLocalDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target;
  //   if (subtask.isClosed) return;

  //   const parsedDate = dateUtils.parseLocalDate(value);
  //   const today = dateUtils.getTodayLocal();

   

  //   if (name === 'start') {
  //     setLocalStartDate(value);
  //   } else if (name === 'end') {
  //     setLocalEndDate(value);
  //   }
  // };


  const handleLocalDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    
    // Restringir cambios si la subtarea está cerrada
    if (subtask.isClosed) return;
  
    // Para cambios en la fecha de inicio
    if (name === 'start') {
      // Verificar si esta subtarea puede modificar su fecha de inicio
      if (subtask.type !== 'INGRESO') {
        toast.custom((t) => (
          <div className={`bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-3 rounded shadow-md`} role="alert">
            <div className="flex items-center">
              <AlertTriangle className="w-5 h-5 mr-2 text-yellow-700" />
              <p className="text-sm">Solo la subtarea INGRESO puede modificar la fecha de inicio de TRAMITACIÓN.</p>
            </div>
          </div>
        ), {
          duration: 4000
        });
        return;
      }
      
      // Proceder con el cambio si es permitido
      setLocalStartDate(value);
      
      // Si es una subtarea INGRESO, este cambio afectará a todas las demás subtareas y a la tarea principal
      if (subtask.type === 'INGRESO') {
        // Si la tarea principal es TRAMITACIÓN, actualizar su fecha de inicio
        if (mainTask.nombre === 'TRAMITACIÓN') {
          // Necesitamos calcular la fecha de inicio más temprana
          if (mainTask.subtasks) {
            const simulatedSubtasks = mainTask.subtasks.map(st => 
              st.id === subtask.id ? { ...st, start: value } : st
            );
            
            // Calcular la fecha de inicio más temprana
            const earliestStartDate = calculateEarliestStartDate(simulatedSubtasks);
            
            // Notificar si cambiará la fecha de inicio de TRAMITACIÓN
            if (mainTask.fecha_inicio !== earliestStartDate) {
              toast.custom((t) => (
                <div className={`bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-3 rounded shadow-md`} role="alert">
                  <div className="flex items-center">
                    <Info className="w-5 h-5 mr-2 text-blue-700" />
                    <p className="text-sm">Al guardar, la fecha de inicio de TRAMITACIÓN se actualizará a {formatDateDDMMYYYY(earliestStartDate)}</p>
                  </div>
                </div>
              ), {
                duration: 4000
              });
            }
            
            // Recalcular fechas dependientes también afectará el término
            const recalculatedSubtasks = calculateDependentStartDates(simulatedSubtasks);
            const latestEndDate = calculateLatestEndDate(recalculatedSubtasks);
            
            // Notificar si cambiará la fecha de término de TRAMITACIÓN
            if (latestEndDate !== mainTask.fecha_termino) {
              toast.custom((t) => (
                <div className={`bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-3 rounded shadow-md`} role="alert">
                  <div className="flex items-center">
                    <Info className="w-5 h-5 mr-2 text-blue-700" />
                    <p className="text-sm">Al guardar, la fecha de término de TRAMITACIÓN se actualizará a {formatDateDDMMYYYY(latestEndDate)}</p>
                  </div>
                </div>
              ), {
                duration: 4000
              });
            }
          }
        }
      }
    } else if (name === 'end') {
      // Los cambios en fecha de término siempre están permitidos
      setLocalEndDate(value);
      
      // Lógica para actualizar fecha de término de TRAMITACIÓN si es necesario
      if (mainTask.nombre === 'TRAMITACIÓN' && mainTask.subtasks) {
        const updatedSubtasks = mainTask.subtasks.map(st => 
          st.id === subtask.id ? { ...st, end: value } : st
        );
        
        // Si es INGRESO, también recalcular las fechas dependientes
        let simulatedSubtasks = [...updatedSubtasks];
        if (subtask.type === 'INGRESO') {
          simulatedSubtasks = calculateDependentStartDates(updatedSubtasks);
        }
        
        const latestEndDate = calculateLatestEndDate(simulatedSubtasks);
        
        if (latestEndDate !== mainTask.fecha_termino) {
          toast.custom((t) => (
            <div className={`bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-3 rounded shadow-md`} role="alert">
              <div className="flex items-center">
                <Info className="w-5 h-5 mr-2 text-blue-700" />
                <p className="text-sm">Al guardar, la fecha de término de TRAMITACIÓN se actualizará a {formatDateDDMMYYYY(latestEndDate)}</p>
              </div>
            </div>
          ), {
            duration: 4000
          });
        }
      }
    }
  };
  

    
    const handleAddComment = async () => {
      if (!newComment.trim()) return;
  
      try {
        const timestamp = new Date().toLocaleString('es-CL', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: true
        });
  
        const commentWithTimestamp = `[${timestamp}] ${storedFirstName} ${storedLastName}: ${newComment}`;
        
        // Parsear comentarios existentes
        let currentComments = [];
        try {
          currentComments = subtask.comments ? JSON.parse(subtask.comments) : [];
        } catch (error) {
          console.error('Error parsing comments:', error);
          currentComments = [];
        }
  
        // Agregar nuevo comentario manteniendo los anteriores
        const updatedComments = Array.isArray(currentComments) 
          ? [...currentComments, commentWithTimestamp]
          : [commentWithTimestamp];
          
        const newCommentsString = JSON.stringify(updatedComments);
  
        // Actualizar subtarea
        const updatedSubtask = {
          ...subtask,
          id: subtask.id,
          name: subtask.name,
          tarea_id: mainTask.id,
          comments: newCommentsString
        };
  
        // Guardar en BD
        await updateSubtaskInDB(updatedSubtask);
  
        // Actualizar estados locales
        subtask.comments = newCommentsString;
        setComments(updatedComments);
        onChangeSubtask(updatedSubtask);
        setNewComment('');
  
        // Scroll al último comentario
        if (commentsContainerRef.current) {
          commentsContainerRef.current.scrollTop = commentsContainerRef.current.scrollHeight;
        }
  
      } catch (error) {
        console.error('Error al agregar comentario:', error);
        toast.error('Error al agregar el comentario');
      }
    };

// Modifica la función updateSubtaskInDB
const updateSubtaskInDB = async (subtaskData: any): Promise<void> => {
  try {

    // Process dependsOn array before sending
    const dependencies = Array.isArray(subtaskData.dependsOn) && subtaskData.dependsOn.length > 0
      ? subtaskData.dependsOn.map((dep: any) => dep.subtaskId)
      : [];
     // console.log("subtaskData:", subtaskData);
     console.trace();
   
      const formattedSubtaskData = {
        action: 'update',
        subtask: {
          id: subtaskData.id, 
          tarea_id: mainTask.id,
          name: subtaskData.name || subtaskData.nombre || 'Sin Nombre', // Prioridad a 'name' o 'nombre'
          type: subtaskData.type || subtaskData.tipo || subtaskData.type || 'Sin Tipo', // Prioridad a 'type', 'tipo', o 'type'
          responsible: mainTask.responsable,
          organism: subtaskData.organism || subtaskData.organismo,
          progress: subtaskData.progress,
          start: (subtaskData.start),
          end: (subtaskData.end),
          duration: subtaskData.duration,
          dependsOn: JSON.stringify(subtaskData.dependsOn),
          enabled: subtaskData.enabled,
          resolutionType: subtaskData.resolutionType || null,
          resolucion_tipo: subtaskData.resolutionType || null,
          orden: subtaskData.orden,
          comments: subtaskData.comments,
          isClosed: subtaskData.isClosed, 
          followUpDate: subtaskData.followUpDate,
          semaphoreStatus: subtaskData.semaphoreStatus,
        }
      };

      
 //   alert(JSON.stringify(formattedSubtaskData))
    const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/subtask_operations.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formattedSubtaskData)
    });

   
    if (!response.ok) {
      throw new Error('Error updating subtask');
    }

    const result = await response.json();
    if (!result.success) {

      throw new Error(result.message || 'Error updating subtask');
    }

  } catch (error) {
    console.error('Error in updateSubtaskInDB:', error);
    //alert(JSON.stringify(subtaskData))
    throw error;
  }
};
  
  // Función auxiliar para crear subtareas en la BD
const createSubtaskInDB = async (subtaskData: any) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/subtask_operations.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        action: 'create',
        subtask: subtaskData
      }),
    });

    if (!response.ok) {
      throw new Error('Error al crear la subtarea');
    }

    return await response.json();
  } catch (error) {
    console.error('Error en createSubtaskInDB:', error);
    throw error;
  }
};

const handleResolutionTypeSelection = async (resolutionType: string) => {
  try {
    setIsLoading(true);
    const today = dateUtils.getTodayString();

    // Crear la nueva subtarea (si aplica)
    const nextOptions = getNextSubtaskOptions(subtask.type, mainTask, subtask);
    const shouldCreateNextSubtask = nextOptions.length > 0;

    let newSubtask: SubTask | null = null;
    if (shouldCreateNextSubtask) {
      newSubtask = {
        id: mainTask.id,
        name: nextOptions[0].name,
        type: nextOptions[0].type,
        responsible: nextOptions[0].responsable,
        progreso: 0,
        start: today,
        end: dateUtils.addDaysToDate(today, 7),
        duration: 1,
        organismo: subtask.organismo,
        enabled: true,
        orden: subtask.orden + 1,
        comments: '',
        followUpDate: '',
        followUp: false,
        semaphoreStatus: SemaphoreStatus.Green,
        isClosed: false,
        delayInDays: 0,
        dependsOn: subtask.dependsOn
      };
    }

    // Crear la tarea actualizada
    const updatedTask = {
      ...mainTask,
      subtasks: mainTask.subtasks?.map(st => 
        st.id === subtask.id 
          ? { 
              ...st, 
              isClosed: true, 
              progress: 100, 
              end: today,
              resolutionType: subtask.name !== 'RESOLUCIÓN' ? undefined : resolutionType as 'APROBADO' | 'RECHAZADO' | 'DESISTIMIENTO' | 'SILENCIO ADMINISTRATIVO POSITIVO' | 'SILENCIO ADMINISTRATIVO NEGATIVO' | 'NO ADMITIDO' | 'OTRO',
              resolucion_tipo: subtask.name !== 'RESOLUCIÓN' ? undefined : resolutionType as 'APROBADO' | 'RECHAZADO' | 'DESISTIMIENTO' | 'SILENCIO ADMINISTRATIVO POSITIVO' | 'SILENCIO ADMINISTRATIVO NEGATIVO' | 'NO ADMITIDO' | 'OTRO'
            }
          : st
      )
    };

    if (mainTask.nombre === 'TRAMITACIÓN' && 
      updatedTask.subtasks?.every(subtask => subtask.isClosed)) {
    updatedTask.isClosed = true;
    updatedTask.progreso = 100;
    updatedTask.fecha_termino = today;

  }

    // Añadir nueva subtarea si existe
    if (newSubtask) {
      updatedTask.subtasks = updatedTask.subtasks?.concat(newSubtask);
    }

    console.log('Tarea actualizada:', updatedTask);
    if (mainTask.nombre === 'TRAMITACIÓN' && mainTask.subtasks) {
      const allSubtasksClosed = mainTask.subtasks.every(subtask => subtask.isClosed);
      if (allSubtasksClosed) {
        const updatedTask = {
          ...mainTask,
          isClosed: true,
          progress: 100,
          end: dateUtils.getTodayString()
        };
       
      }
    }
    // Actualizar en la base de datos
    await updateSubtaskInDB({
      ...subtask,
      id: subtask.id,
      tarea_id: mainTask.id,
      isClosed: true,
      progress: 100,
      end: today,
      resolutionType: resolutionType,
      resolucion_tipo: resolutionType
    });

    // Si hay nueva subtarea, crearla en la base de datos
    if (newSubtask) {
      await createSubtaskInDB({
        ...newSubtask,
        tarea_id: mainTask.id
      });
    }
  // Update the UI state
  onChangeSubtask(subtask);
  onChangeTask(updatedTask);



  // Show success message
  toast.success('Resolución actualizada exitosamente');



  } catch (error) {
    console.error('Error al cerrar resolución:', error);
    toast.error('Error al cerrar la resolución');
  } finally {
    setIsLoading(false);
      // Close all dialogs
  setShowResolutionTypeDialog(false);
  setShowNewSubtaskDialog(false);
  onClose();
  }
};






const handleCloseSubtask = async () => {
  try {
    setIsLoading(true);

    if (
      mainTask.nombre === 'TRAMITACION' &&
      mainTask.semaphoreStatus === SemaphoreStatus.Gray
    ) {
      toast.error('No se puede cerrar una subtarea cuando TRAMITACIÓN no ha iniciado');
      return;
    }
    if (subtask.type === 'RESOLUCIÓN') {
      setSelectedResolutionType('');
      setShowResolutionTypeDialog(true);
    } else {

    const today = dateUtils.getTodayString();
    const todayDate = dateUtils.getTodayLocal();
    //alert(`${today}   ${todayDate}`)
    const updatedSubtaskData: SubTask = {
      ...subtask, 
      id: subtask.id, 
      name: subtask.name,
      type: subtask.type,
      organismo: subtask.organismo,
      start: subtask.start,
      end: today,
      tarea_id: mainTask.id,
      isClosed: true,
      comments: subtask.comments, 
      followUpDate: subtask.followUpDate,
      semaphoreStatus: subtask.semaphoreStatus,
      progreso: 100,
      duration: subtask.duration,
      orden: subtask.orden,
      enabled: true,
    }; 

    await updateSubtaskInDB(updatedSubtaskData);

    // Si es una subtarea de tipo RESOLUCIÓN, cerrar TRAMITACIÓN
   

      setShowNewSubtaskDialog(true);
    }
    
  } catch (error) {
    console.error('Error:', error);
    toast.error('Error al cerrar la subtarea');
  } finally {
    setIsLoading(false);
  }
};


  const handleNewSubtaskSelection = async (option: NewSubtaskOption) => {
    try {
      setIsLoading(true);
      const formattedStartDate = dateUtils.getTodayString();
      const formattedEndDate = dateUtils.addDaysToDate(formattedStartDate, 7);

      const newSubtaskData = {
        tarea_id: mainTask?.id ?? -1, // Asegurar un ID válido
        name: option.name, // Changed from nombre
          type: option.type, // Changed from tipo
          responsible: option.responsable, // Changed from responsable
          organism: subtask.organismo, // Changed from organismo
          progress: 0, // Changed from progreso
          start: formattedStartDate, // Changed from fecha_inicio
          end: formattedEndDate, // Changed from fecha_termino
          duration: 1, // Changed from duracion
          isClosed: false,
          dependencies: JSON.stringify([{
            groupId: mainTask.id,
            taskId: mainTask.id,
            subtaskId: subtask.id
          }]),
          enabled: true,
          orden: subtask.orden + 1, 
          semaphoreStatus: SemaphoreStatus.Green,
          resolutionType: subtask.resolutionType,
      };

      const result = await createSubtaskInDB(newSubtaskData);
    //  alert(JSON.stringify(newSubtaskData))
      if (result.success) {
          onAddNewSubtask(option);
     //   onChangeSubtask({ ...subtask, ...newSubtaskData });
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Error al crear nueva subtarea');
    } finally {
      setIsLoading(false);
      setShowNewSubtaskDialog(false);
      onClose();
    }
  };


  
  const handleOpenSubtask = async () => {
    try {
      setIsLoading(true);
      
      const startDate = dateUtils.parseLocalDate(subtask.start);
      const newEndDate = new Date(startDate);
      newEndDate.setDate(startDate.getDate() + (subtask.duration * 7));
      const formattedEndDate = dateUtils.formatLocalDate(newEndDate);

      const updatedSubtaskData = {
        ...subtask,
        tarea_id: mainTask.id,
        id: subtask.id,
        isClosed: false,
        progress: 0,
        end: formattedEndDate,
        fecha_termino: formattedEndDate
      };

      await updateSubtaskInDB(updatedSubtaskData);
      onChangeSubtask(updatedSubtaskData);
      
      onChangeTask({...mainTask});
      toast.success('Subtarea abierta exitosamente');
      onClose();
    } catch (error) {
      console.error('Error al abrir subtarea:', error);
      toast.error('Error al abrir la subtarea');
    } finally {
      setIsLoading(false);
    }
  };

  
  
const contentVariants = {
  expanded: { 
    height: "auto",
    opacity: 1,
    transition: {
      height: {
        duration: 0.3,
        ease: [0.87, 0, 0.13, 1]
      },
      opacity: {
        duration: 0.25,
        ease: "easeInOut"
      }
    }
  },
  collapsed: { 
    height: 0,
    opacity: 0,
    transition: {
      height: {
        duration: 0.3,
        ease: [0.87, 0, 0.13, 1]
      },
      opacity: {
        duration: 0.25,
        ease: "easeInOut"
      }
    }
  }
};
  

  // const handleSaveChanges = async () => {
  //   try {
  //     setIsLoading(true);

  //     if (dateUtils.parseLocalDate(localEndDate) < dateUtils.parseLocalDate(localStartDate)) {
  //       toast.error('La fecha de término no puede ser anterior a la fecha de inicio');
  //       return;
  //     }

  //     const updatedSubtask = {
  //       ...subtask,
  //       nombre: subtask.name,
  //       tarea_id: mainTask.id,
  //       start: localStartDate,
  //       end: localEndDate,
  //       isClosed: subtask.isClosed,
  //       progress: subtask.progreso,
        
  //     };

  //     await updateSubtaskInDB(updatedSubtask);
  //     onChangeSubtask(updatedSubtask);
  //     toast.success('Cambios guardados exitosamente');
  //     onClose();
  //   } catch (error) {
  //     console.error('Error al guardar cambios:', error);
  //     toast.error('Error al guardar los cambios');
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };


  
  const handleSaveChanges = async () => {
    try {
      setIsLoading(true);
  
      if (dateUtils.parseLocalDate(localEndDate) < dateUtils.parseLocalDate(localStartDate)) {
        toast.error('La fecha de término no puede ser anterior a la fecha de inicio');
        setIsLoading(false);
        return;
      }
  
      // Primero actualizar la subtarea
      const updatedSubtask = {
        ...subtask,
        nombre: subtask.name,
        tarea_id: mainTask.id,
        start: localStartDate,
        end: localEndDate,
        isClosed: subtask.isClosed,
        progreso: subtask.progreso,
      };
  
      // Actualizar la subtarea en la base de datos
      await updateSubtaskInDB(updatedSubtask);
      
      // Actualizar la subtarea en el estado local
      let updatedSubtasks = mainTask.subtasks 
        ? mainTask.subtasks.map(st => st.id === subtask.id ? { ...st, start: localStartDate, end: localEndDate } : st)
        : [];
      
      // Crear copia de la tarea principal para actualizarla
      let updatedMainTask = { ...mainTask, subtasks: updatedSubtasks };
      
      if (mainTask.nombre === 'TRAMITACIÓN') {
        // Recalcular fechas dependientes si es necesario
        if (subtask.type === 'INGRESO') {
          updatedSubtasks = calculateDependentStartDates(updatedSubtasks);
          updatedMainTask.subtasks = updatedSubtasks;
          
          // Actualizar todas las subtareas modificadas en la base de datos
          for (const st of updatedSubtasks) {
            if (st.id !== subtask.id) { // Evitar actualizar la misma subtarea dos veces
              await updateSubtaskInDB({
                ...st,
                tarea_id: mainTask.id
              });
            }
          }
        }
        
        // Calcular fecha de inicio más temprana
        const earliestStartDate = calculateEarliestStartDate(updatedSubtasks);
        
        // Calcular fecha de término más tardía
        const latestEndDate = calculateLatestEndDate(updatedSubtasks);
        
        // Actualizar fechas de la tarea principal si han cambiado
        let fechasActualizadas = false;
        
        if (updatedMainTask.fecha_inicio !== earliestStartDate) {
          updatedMainTask.fecha_inicio = earliestStartDate;
          fechasActualizadas = true;
        }
        
        if (updatedMainTask.fecha_termino !== latestEndDate) {
          updatedMainTask.fecha_termino = latestEndDate;
          fechasActualizadas = true;
        } 
        // Si las fechas de la tarea principal han cambiado, actualizar en la base de datos
     
        
        // Mostrar mensaje de éxito apropiado
        if (fechasActualizadas) {
          toast.success('Fechas de subtarea y TRAMITACIÓN actualizadas exitosamente');
        } else {
          toast.success('Cambios guardados exitosamente');
        }
      } else {
        toast.success('Cambios guardados exitosamente');
      }
      
      // Actualizar la tarea en el componente padre
      onChangeTask(updatedMainTask);
     // onChangeSubtask(updatedSubtask); //ojo aca que quitandolo, funciona bien, pero si hay inconvenientes esta puede ser la causa
  
      // Forzar una segunda actualización para asegurar que la UI se refresque
      setTimeout(() => {
        onChangeTask(updatedMainTask);
      }, 0);
  
      
      onClose();
    } catch (error) {
      console.error('Error al guardar cambios:', error);
      toast.error('Error al guardar los cambios');
    } finally {
      setIsLoading(false);
    }
  };



  

  return (
    <AnimatePresence>
    {isOpen && (
     <>
       <div style={{ position: 'fixed', top: 0, right: 0, zIndex: 10002 }}>
       <Toaster
   position="top-right"
   toastOptions={{
     duration: 5000, // Duración más prolongada para mayor visibilidad
     style: {
   
       zIndex: 10002, // Asegúrate de que esté por encima de otros elementos
     },
     success: {
       style: {
         background: '#38A169', // Verde para mensajes de éxito
         color: '#FFFFFF',
       },
     },
     error: {
       style: {
         background: '#E53E3E', // Rojo para errores
         color: '#FFFFFF',
       },
     },
    
   }}
 />
           </div>
       <motion.div
         initial="hidden"
         animate="visible"
         exit="exit"
         variants={contentVariants}
         className="fixed inset-0 flex items-center justify-center  z-[10001] "
       >
           <motion.div className="rounded-xl bg-white rounded-lg w-full max-w-2xl shadow-xl z-[10001]">
           <Dialog isOpen={isOpen} onClose={onClose } width="600px" className=" z-[10002]">
           <motion.div 
             className="bg-white rounded-lg overflow-hidden shadow-xl"
             variants={contentVariants}
           >
      
 
             <div
               className="bg-teal-600 p-3 text-white"
              
             >
                      <div className="relative">
   {/* Botón de cierre */}
   <button
     onClick={onClose}
     className="absolute top-2 right-2 z-[500] text-white/80 hover:text-white focus:outline-none"
   >
     <X size={20} />
   </button>
 </div>
     <h3 className="text-base font-semibold flex items-center space-x-2">
       <MdBusiness className="text-white w-5 h-5" /> 
       <span className="text-sm opacity-80 tracking-wide">
  {`${processName?.toUpperCase()} / ${subprocessName?.toUpperCase()} / ${agrupadorName || ''}`}
</span>
     </h3>
     <div className="flex mt-4 mb-3">
 
     <div className="flex items-center">
       <span className="text-lg font-bold text-white">{subtask.name}</span>
     </div>
   {/* Estado de la tarea */}
 <div className="ml-3 flex items-center">
 <div
   className={`px-3 py-1 rounded-full text-sm font-medium ${
     subtask.progreso === 100 && subtask.isClosed
       ? `${backgroundColor} text-gray-800`
       : subtask.styles?.progressColor === '#EF4444' 
         ? 'bg-red-100 text-red-700'
         : subtask.styles?.progressColor === '#FB923C'
         ? 'bg-orange-100 text-orange-700'
         : subtask.styles?.progressColor === '#FDE047'
         ? 'bg-yellow-100 text-yellow-700'
         : subtask.styles?.progressColor === '#4ADE80'
         ? 'bg-green-100 text-green-700'
         : 'bg-gray-100 text-gray-700'
   }`}
   style={
     subtask.progreso === 100 && subtask.isClosed
       ? {
           backgroundImage: `repeating-conic-gradient(
             ${lightenColor(backgroundColor, 80)} 0% 25%, 
             #ffffff 25% 50%
           )`,
           backgroundSize: '10px 10px',
         }
       : undefined
   }
 >
   {subtask.progreso === 100 && subtask.isClosed
     ? 'Cerrada'
     : subtask.styles?.progressColor === '#EF4444'
     ? `Retrasada por ${daysDelayed} día(s)`
     : subtask.styles?.progressColor === '#FB923C'
     ? 'Próxima a vencer'
     : subtask.styles?.progressColor === '#FDE047'
     ? 'A tiempo, pero próxima'
     : subtask.styles?.progressColor === '#4ADE80'
     ? 'A tiempo'
     : 'No iniciada'}
 </div>
 </div>
 
     </div>
     <div className="flex items-center gap-4 text-white/70 text-xs mt-1">
       <span className="flex items-center gap-1">
         <Calendar className="w-4 h-4" />
         {formatDateDDMMYYYY(subtask.start)}
       </span>
       <span className="flex items-center gap-1">
         <Calendar className="w-4 h-4" />
         {formatDateDDMMYYYY(subtask.end)}
       </span>
 
     </div>
  
     </div>
   
   </motion.div>
 
 <div className="border-b">
           <nav className="flex">
             <button
               onClick={() => setActiveTab('details')}
               className={`px-4 py-2 ${activeTab === 'details' ? 'border-b-2 border-teal-500' : ''}`}
             >
               Detalles
             </button>
             <button
               onClick={() => setActiveTab('followup')}
               className={`px-4 py-2 ${activeTab === 'followup' ? 'border-b-2 border-teal-500' : ''}`}
             >
               Seguimiento
             </button>
           </nav>
         </div>
 
         {activeTab === 'details' ? (
   <div className="p-4">
    
 
     {/* Grid de campos */}
     <div className="grid grid-cols-2 gap-3 mb-4">
       <div>
         <label className="block text-xs font-medium text-gray-700 mb-1">
           Nombre
         </label>
         <input
           type="text"
           value={editedName || ''}
           className="w-full text-sm rounded border-gray-300 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
           disabled={subtask.isClosed}
         />
       </div>
 
       <div></div>
 
       <div>
         <label className="block text-xs font-medium text-gray-700 mb-1">
           Fecha de Inicio
         </label>
         <input
           type="date"
           name="start"
           value={localStartDate}
           onChange={handleLocalDateChange}
           onKeyDown={handleKeyDown}
           className="w-full text-sm rounded border-gray-300 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
           disabled={subtask.isClosed && subtask.progreso === 100}
         />
       </div>
       <div>
         <label className="block text-xs font-medium text-gray-700 mb-1">
           Fecha de Término
         </label>
         <input
           type="date"
           name="end"
           value={localEndDate}
           onChange={handleLocalDateChange}
           onKeyDown={handleKeyDown}
           className="w-full text-sm rounded border-gray-300 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
           disabled={subtask.isClosed && subtask.progreso === 100}
         />
       </div>
     </div>
 
     {/* Actualizar sección de comentarios */}
     <div className="comments-section">
       <div className="flex items-center justify-between mb-2">
         <h3 className="text-lg font-medium">Comentarios</h3>
         {isLoadingComments && <Loader2 className="animate-spin h-4 w-4" />}
       </div>
 
       <div
         ref={commentsContainerRef}
         className="max-h-60 overflow-y-auto border rounded-md p-4 bg-gray-50"
       >
 
 {subtask.comments ? (
   (() => {
     try {
       console.log('Contenido original de subtask.comments:', subtask.comments);
       
       // Parsear subtask.comments
       const parsedComments = JSON.parse(subtask.comments);
       console.log('Comentarios parseados:', parsedComments);
 
       // Validar que parsedComments sea un array con datos
       if (Array.isArray(parsedComments) && parsedComments.length > 0) {
         return parsedComments.map((comment, index) => {
           // Dividir y procesar cada comentario
           const [datePart, userAndContent] = comment.split('] ');
           const date = datePart.replace('[', '').trim();
           const [user, ...contentParts] = userAndContent.split(':');
           const content = contentParts.join(':').trim();
           const isCurrentUser = user === `${storedFirstName} ${storedLastName}`;
 
           return (
             <div
               key={index}
               className={`mb-3 last:mb-0 flex ${
                 isCurrentUser ? 'justify-start' : 'justify-end'
               }`}
             >
               <div
                 className={`max-w-fit p-3 rounded-lg shadow-md break-words ${
                   isCurrentUser
                     ? 'bg-green-100 text-green-900'
                     : 'bg-blue-100 text-blue-900'
                 }`}
                 style={{
                   maxWidth: 'calc(100% - 20px)',
                   wordBreak: 'break-word',
                 }}
               >
                 <div className="flex items-center gap-2 text-xs text-gray-600 mb-1">
                   <span className="font-medium">{user}</span>
                   <span className="text-gray-400">•</span>
                   <span className="text-gray-400">{date}</span>
                 </div>
                 <p className="text-sm whitespace-pre-wrap">{content}</p>
               </div>
             </div>
           );
         });
       } else {
         return <p className="text-gray-500 italic">No hay comentarios.</p>;
       }
     } catch (error) {
       console.error('Error al procesar comentarios:', error);
       return (
         <p className="text-red-500 italic">Error al cargar comentarios.</p>
       );
     }
   })()
 ) : (
   <p className="text-gray-500 italic">No hay comentarios disponibles.</p>
 )}
 
 
       </div>
 
       <div className="mt-4 flex gap-2">
         <textarea
           value={newComment}
           onChange={(e) => setNewComment(e.target.value)}
           onKeyDown={async (e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              if (newComment.trim() && !subtask.isClosed && !isLoadingComments) {
                await handleAddComment();
                setNewComment('');
              }
            }
          }}
           placeholder="Escribir un comentario..."
           rows={2}
           className="w-full rounded-md border border-gray-300 px-3 py-2 resize-none overflow-y-auto"
           disabled={subtask.isClosed}
           maxLength={250}
         />
         <button
           onClick={async () => {
             await handleAddComment();
             setNewComment(''); // Limpiar input después de agregar el comentario
           }}
           disabled={subtask.isClosed || !newComment.trim() || isLoadingComments}
           className="px-4 py-2 bg-teal-600 text-white rounded-md hover:bg-teal-700 disabled:opacity-50"
         >
           Agregar
         </button>
       </div>
     </div>
      {/* Botones de acción */}
      <div className="flex justify-between mt-4 pt-2 border-t">
    <button
 type="button"
 className={`px-3 py-1.5 rounded text-sm font-medium ${
   subtask.progreso === 100 && subtask.isClosed
     ? 'bg-green-600 hover:bg-green-700 text-white'
     : subtask.semaphoreStatus === SemaphoreStatus.Gray && subtask.progreso === 0  
       ? 'bg-gray-400 text-white cursor-not-allowed' 
       : 'bg-red-600 hover:bg-red-700 text-white'
 }`}
 onClick={subtask.progreso === 100 ? handleOpenSubtask : handleCloseSubtask}
 disabled={subtask.semaphoreStatus === SemaphoreStatus.Gray && subtask.progreso === 0}
>
 {subtask.progreso === 100 ? "ABRIR SUBTAREA" : "CERRAR SUBTAREA"}
</button>
                 <div className="relative">
                 <button
  type="button"
  onClick={() => {
    // Función para validar si una fecha existe
    const isValidDate = (dateStr: string) => {
      if (!dateStr || !/^\d{4}-\d{2}-\d{2}$/.test(dateStr)) {
        return false;
      }
      const [year, month, day] = dateStr.split('-').map(Number);
      const date = new Date(year, month - 1, day);
      return !(
        isNaN(date.getTime()) ||
        date.getFullYear() !== year ||
        date.getMonth() + 1 !== month ||
        date.getDate() !== day
      );
    };

    // Validar fecha inicio
    if (!isValidDate(localStartDate)) {
      toast.error("La fecha de inicio no es válida");
      return;
    }

    // Validar fecha término
    if (!isValidDate(localEndDate)) {
      toast.error("La fecha de término no es válida");
      return;
    }

    // Validar orden de fechas
    if (new Date(localEndDate) < new Date(localStartDate)) {
      toast.error("La fecha de término no puede ser anterior a la fecha de inicio.");
      return;
    }

    handleSaveChanges();
  }}
  className="px-3 py-1.5 bg-blue-600 text-white text-sm font-medium rounded hover:bg-blue-700"
>
  Guardar Cambios
</button>
 </div>
                 </div>
   </div>
   
 ) :            
 <motion.div
 initial="hidden"
 animate="visible"
 exit="exit"
 variants={modalVariants}
 className="space-y-4 "
 >  { 
 //renderTrackingContent()
 }
       
 </motion.div>
 }
 </Dialog>
 
 
 
                </motion.div>
                
           </motion.div>
           
           </>
       )}
       {showResolutionTypeDialog && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[10002]">
    <motion.div
      className="bg-white rounded-lg p-6 w-full max-w-md relative"
      variants={modalVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <button
        onClick={() => setShowResolutionTypeDialog(false)}
        className="absolute top-2 right-2 text-gray-300 hover:text-gray-400 z-10"
      >
        <X size={24} />
      </button>

      <h3 className="text-lg font-semibold text-gray-900 mb-4">
        Seleccionar tipo de resolución
      </h3>
      <div className="space-y-2">
        {resolutionTypes.map((type) => (
          <button
            key={type.value}
            onClick={() => handleResolutionTypeSelection(type.value)}
            className={`w-full flex items-center p-3 rounded-lg transition-all duration-200 ${type.bgColor} hover:opacity-90`}
          > 
            <div className={`mr-3 ${type.color}`}>{type.icon}</div>
            <span className={`font-medium ${type.color}`}>{type.label}</span>
          </button>
        ))}
      </div>
    </motion.div>
  </div>
)}

         {/* Modal para seleccionar nueva subtarea */}
       <AnimatePresence>
        {showNewSubtaskDialog && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[10002]">
            <motion.div
              className="bg-white rounded-lg p-6 w-full max-w-md"
              variants={modalVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              <h3 className="text-lg font-medium mb-4">Seleccionar siguiente subtarea</h3>
              <div className="space-y-4">
              {nextOptions.map((option) => (

                <button
                  key={option.type}
                  className="w-full px-4 py-2 bg-teal-600 text-white rounded hover:bg-teal-700"
                 onClick={() => handleNewSubtaskSelection(option)}
                >
                    {option.name}
                </button>
                            ))}

                <button
                  className="w-full px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700"
                  onClick={() => setShowNewSubtaskDialog(false)}
                >
                  Cancelar
                </button>
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
     </AnimatePresence>
  );
};




const renderSubtaskResponsible = (subtask: SubTask, users: User[]) => {
  return (
    <div className="flex justify-center">


    </div>
  );
};

const renderSubtaskFollowUp = (subtask: SubTask) => {
  return subtask.followUpDate ? (
    <div className="w-6 h-6 rounded-full bg-red-500 mx-auto flex items-center justify-center">
      <Bell className="w-4 h-4 text-white" />
    </div>
  ) : (
    <div className="w-6 h-6 rounded-full bg-gray-200 mx-auto" />
  );
};

const calculateSubtaskStatus = (
  start: string,
  end: string,
  subtaskId: number,
  isClosed: boolean
): SemaphoreStatus => {
  // Si la subtarea está cerrada, retornar gris
  if (isClosed) {
    return SemaphoreStatus.Gray;
  }

  // Obtener la fecha actual y establecer a medianoche
  const today = dateUtils.getTodayLocal();
  today.setHours(0, 0, 0, 0);

  // Convertir fechas de string a Date y establecer a medianoche
  const startDate = dateUtils.parseLocalDate(start);
  const endDate = dateUtils.parseLocalDate(end);
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(0, 0, 0, 0);

  // Validación de fechas
  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
    return SemaphoreStatus.Gray;
  }

  // Si la subtarea aún no comienza
  if (startDate > today) {
    return SemaphoreStatus.Gray;
  }

  // Si ya pasó la fecha de fin o es el mismo día de fin
  if (today >= endDate) {
    return SemaphoreStatus.Red;
  }

  // Calcular duración total y días restantes
  const totalDuration = Math.max(1, Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)));
  const daysRemaining = Math.ceil((endDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
  const percentageCompleted = ((totalDuration - daysRemaining) / totalDuration) * 100;

  // Para debug
  // console.log({
  //   subtaskId,
  //   start: startDate.toISOString(),
  //   end: endDate.toISOString(),
  //   today: today.toISOString(),
  //   totalDuration,
  //   daysRemaining,
  //   percentageCompleted
  // });

  // Enfoque híbrido: usar días restantes para subtareas cortas y porcentajes para largas
  if (totalDuration <= 5) {
    // Lógica para subtareas cortas (5 días o menos)
    if (daysRemaining === 0) {
      return SemaphoreStatus.Red; // Mismo día = Rojo
    } else if (daysRemaining === 1) {
      return SemaphoreStatus.Orange; // Un día restante = Naranja
    } else if (daysRemaining <= 3) {
      return SemaphoreStatus.Yellow; // 2-3 días restantes = Amarillo
    }
    return SemaphoreStatus.Green; // Más de 3 días = Verde
  } else {
    // Lógica para subtareas largas (más de 5 días)
    if (percentageCompleted >= 90) {
      return SemaphoreStatus.Red; // 90% o más completado = Rojo
    } else if (percentageCompleted >= 75) {
      return SemaphoreStatus.Orange; // 75-89% completado = Naranja
    } else if (percentageCompleted >= 50) {
      return SemaphoreStatus.Yellow; // 50-74% completado = Amarillo
    }
    return SemaphoreStatus.Green; // Menos del 50% completado = Verde
  }

};


interface SubprocessGroup {
  proceso: string;
  subproceso: string;
  color: string;
  tasks: Task[];
}


const ProcessStatusTable = () => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [groupedTasks, setGroupedTasks] = useState<{[key: string]: Task[]}>({});
  const [updateTrigger, setUpdateTrigger] = useState(0); // Agregar el estado updateTrigger
  const [users, setUsers] = useState<User[]>([]); // Initialize users state
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const loggedInUserId = sessionStorage.getItem('userId');
  const [expandedProcesses, setExpandedProcesses] = useState<{ [key: string]: boolean }>({});
  const [activeSubtask, setActiveSubtask] = useState<SubTask | null>(null);
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const [isSubtaskModalOpen, setIsSubtaskModalOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);
  const [selectedSubtask, setSelectedSubtask] = useState<SubTask | null>(null);
  const [collapsedProcesses, setCollapsedProcesses] = useState<{ [key: string]: boolean }>({});
  
  const getStatusCircle = (task: Task | SubTask, status: SemaphoreStatus, isSubtask: boolean = false) => {
    // Verificar si es una tarea de TRAMITACIÓN
    
    const isTramitacionTask = 'nombre' in task ? task.nombre === 'TRAMITACIÓN' : task.name === 'TRAMITACIÓN';
    
    const statusColors = {
      [SemaphoreStatus.Red]: '#EF4444',
      [SemaphoreStatus.Orange]: '#FB923C',
      [SemaphoreStatus.Yellow]: '#FDE047',
      [SemaphoreStatus.Green]: '#4ADE80',
      [SemaphoreStatus.Gray]: '#E5E7EB'
    };
  
    // Si es TRAMITACIÓN, usar el borde especial
    if (isTramitacionTask) {
      return (
        <div 
          className="w-6 h-6 rounded-full border-4 bg-gray-200"
          style={{ 
            borderColor: statusColors[status],
            backgroundColor: 'repeating-conic-gradient(#E5E7EB 0% 25%, #FFFFFF 25% 50%)'
          }}
        />
      );
    }
  
    // Para subtareas y tareas normales, usar círculo sólido
    return (
      <div
        className="w-6 h-6 rounded-full"
        style={{ 
          backgroundColor: statusColors[status]
        }}
      />
    );
  };
  const renderTasksByProcess = () => {
    const processGroups = tasks.reduce<Record<string, Task[]>>((acc, task) => {
      
      if (!task.isClosed && 
          calculateTaskStatus(
            task.fecha_inicio,
            task.fecha_termino,
            task.id,
            task.dependencia,
            task.isClosed,
            tasks
          ) !== SemaphoreStatus.Gray
      ) {
        if (!acc[task.proceso]) {
          acc[task.proceso] = [];
        }
        acc[task.proceso].push(task);
      }
      return acc;
    }, {});
  
    return Object.entries(processGroups)
      .filter(([_, processTasks]) => processTasks.length > 0)
      .map(([proceso, processTasks], index) => {
        const processInfo = processTasks[0];
        const isProcessCollapsed = collapsedProcesses[proceso] || false;
  
        return (
          <div key={proceso} className="mb-8">
            {/* Contenedor principal */}
            <div className="relative">
              {/* Pestaña superior (1/4 del ancho) */}
              <div 
                className={`relative w-1/4 h-12 bg-emerald-700
                           rounded-t-2xl shadow-md cursor-pointer
                           hover:brightness-105 transition-all
                           flex items-center px-4`}
                onClick={() => setCollapsedProcesses(prev => ({
                  ...prev,
                  [proceso]: !prev[proceso]
                }))}
              >
                {/* Borde derecho para simular continuidad */}
                <div className="absolute top-0 right-0 bottom-0 w-1 bg-white"></div>
  
                <div className="flex items-center justify-between w-full text-white">
                  <div className="flex items-center gap-2 min-w-0">
                    <svg className="w-5 h-5 flex-shrink-0" viewBox="0 0 24 24" fill="none">
                      <path d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM19 19H5V5H19V19Z" fill="currentColor"/>
                      <path d="M12 17H17V15H12V17ZM12 13H17V11H12V13ZM12 9H17V7H12V9ZM7 17H10V7H7V17Z" fill="currentColor"/>
                    </svg>
                    <div className="min-w-0">
                      <div className="font-medium truncate">
                        {processInfo.codigo} - {proceso}
                      </div>
                      <p className="text-sm text-white/80 truncate">
                        {processInfo.comuna}
                      </p>
                    </div>
                  </div>
                  <div className="flex-shrink-0 ml-2">
                    {!isProcessCollapsed ? (
                      <MdKeyboardArrowDown className="w-6 h-6" />
                    ) : (
                      <MdKeyboardArrowRight className="w-6 h-6" />
                    )}
                  </div>
                </div>
              </div>
  
              {/* Línea superior para completar el ancho */}
              <div 
                className="absolute top-0 left-1/4 right-0 h-12 rounded-tr-lg"
                style={{ backgroundColor: '#fff' }}
              ></div>
  
              {/* Contenido */}
              <AnimatePresence initial={false}>
                {!isProcessCollapsed && (
                  <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.3 }}
                  className="bg-white border-4 border-emerald-700
                           rounded-b-xl shadow-xl overflow-hidden"
                  >
                    <table className="min-w-full">
                      <thead>
                        <tr className="bg-gray-50 border-b border-emerald-700">
                          <th className="px-2 py-3 text-left text-xs font-medium uppercase tracking-wider">Subproceso</th>
                          <th className="px-2 py-3 text-left text-xs font-medium uppercase tracking-wider">Agrupador</th>
                          <th className="px-2 py-3 text-left text-xs font-medium uppercase tracking-wider">Tarea</th>
                          <th className="px-2 py-3 text-center text-xs font-medium uppercase tracking-wider">Responsable</th>
                          <th className="px-2 py-3 text-center text-xs font-medium uppercase tracking-wider">Inicio</th>
                          <th className="px-2 py-3 text-center text-xs font-medium uppercase tracking-wider">Término</th>
                          <th className="px-2 py-3 text-center text-xs font-medium uppercase tracking-wider">Seguimiento</th>
                          <th className="px-2 py-3 text-center text-xs font-medium uppercase tracking-wider">Estado</th>
                        </tr>
                      </thead>
                      <tbody>
  {Object.entries(processTasks.reduce<Record<string, Task[]>>((acc, task) => {
    const key = task.subproceso;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(task);
    return acc;
  }, {})).map(([subproceso, subprocessTasks]) => {
    const agrupadorGroups = subprocessTasks.reduce<Record<string, Task[]>>((acc, task) => {
      const key = task.agrupador;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(task);
      return acc;
    }, {});

    return Object.entries(agrupadorGroups).map(([agrupador, agrupadorTasks]) => (
      <React.Fragment key={`${subproceso}-${agrupador}`}>
        <tr 
          className="bg-gray-100"
          style={{ 
            backgroundColor: agrupadorTasks[0].subproceso_color || '#e5e7eb',
            color: isLightColor(agrupadorTasks[0].subproceso_color || '#e5e7eb') 
              ? 'black' 
              : 'white' 
          }}
        >
          <td colSpan={2} className="px-2 py-2 font-semibold">
            {subproceso}
          </td>
          <td colSpan={6} className="px-2 py-2 font-semibold">
            {agrupador}
          </td>
        </tr>
        
        {agrupadorTasks.map((task, taskIndex) => (
          <React.Fragment key={task.id}>
            {/* Fila de la tarea principal */}
            <tr 
              className={`${taskIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50'} hover:bg-gray-100 cursor-pointer border-b border-emerald-700/20`}
              onClick={() => {
                setSelectedTask(task);
               if(task.nombre!=='TRAMITACIÓN')
                 setIsTaskModalOpen(true);
              }}
            >
              <td className="px-2 py-3"></td>
              <td className="px-2 py-3"></td>
              <td className="px-2 py-3 font-medium">{task.nombre}</td>
              <td className="px-2 py-3">{renderResponsibleCell(task)}</td>
              <td className="px-2 py-3 text-center">{formatDate(task.fecha_inicio)}</td>
              <td className="px-2 py-3 text-center">{formatDate(task.fecha_termino)}</td>
              <td className="px-2 py-3 text-center">{renderFollowUpStatus(task)}</td>
              <td className="px-2 py-3 text-center">
                <div className="flex justify-center">
                  {getStatusCircle(task, 
                    calculateTaskStatus(
                      task.fecha_inicio,
                      task.fecha_termino,
                      task.id,
                      task.dependencia,
                      task.isClosed,
                      tasks
                    )
                  )}
                </div>
              </td>
            </tr>

            {/* Renderizar subtareas si es TRAMITACIÓN y tiene subtareas */}
            {task.nombre === 'TRAMITACIÓN' && task.subtasks && task.subtasks.length > 0 && (
                task.subtasks
                .filter(subtask => !subtask.isClosed && subtask.progreso !== 100) // Filtrar solo subtareas activas
                .map((subtask, subtaskIndex) => (
                <tr 
                  key={`${task.id}-subtask-${subtaskIndex}`}
                  className={`${taskIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50'} hover:bg-gray-100 cursor-pointer pl-8`}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSubtaskClick(task, subtask, e);
                  }}
                >
                  <td className="px-2 py-2"></td>
                  <td className="px-2 py-2"></td>
                  <td className="px-2 py-2 pl-8 font-medium text-gray-600">
                    <div className="flex items-center">
                      <ChevronRight className="w-4 h-4 mr-2" />
                      {subtask.name}
                      {subtask.type === 'RESOLUCIÓN' && subtask.resolutionType && (
                        <span className={`ml-2 px-2 py-1 text-xs rounded-full ${getResolutionTypeStyle(subtask.resolutionType)}`}>
                          {subtask.resolutionType}
                        </span>
                      )}
                    </div>
                  </td>
                  <td className="px-2 py-2">
                    {renderSubtaskResponsible(subtask, users)}
                  </td>
                  <td className="px-2 py-2 text-center">{formatDate(subtask.start)}</td>
                  <td className="px-2 py-2 text-center">{formatDate(subtask.end)}</td>
                  <td className="px-2 py-2 text-center">
                    {renderSubtaskFollowUp(subtask)}
                  </td>
                  <td className="px-2 py-2 text-center">
                    <div className="flex justify-center">
                      {getStatusCircle(subtask, 
                        calculateSubtaskStatus(
                          subtask.start,
                          subtask.end,
                          subtask.id || 0,
                          subtask.isClosed,

                        )
                      )}
                    </div>
                  </td>
                </tr>
              ))
            )}
          </React.Fragment>
        ))}
      </React.Fragment>
    ));
  })}
</tbody>
                    </table>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        );
      });
  };
 

  const isLightColor = (color: string): boolean => {
    try {
      const hex = color.replace('#', '');
      const r = parseInt(hex.substr(0, 2), 16);
      const g = parseInt(hex.substr(2, 2), 16);
      const b = parseInt(hex.substr(4, 2), 16);
      
      const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
      return yiq >= 180;
    } catch (e) {
      return false;
    }
  };


// Definir las variantes de animación para las filas
const tableRowVariants = {
  initial: { opacity: 0, y: -5 },
  animate: { 
    opacity: 1, 
    y: 0,
    transition: {
      duration: 0.2
    }
  },
  exit: { 
    opacity: 0,
    y: 5,
    transition: {
      duration: 0.2
    }
  },
  hover: {
    backgroundColor: "rgba(243, 244, 246, 1)",
    transition: {
      duration: 0.2
    }
  }
};

// Variantes para las secciones expandibles
const expandableVariants = {
  hidden: { 
    opacity: 0,
    height: 0,
    transition: {
      duration: 0.3,
      ease: [0.4, 0, 0.2, 1]
    }
  },
  visible: { 
    opacity: 1,
    height: "auto",
    transition: {
      duration: 0.3,
      ease: [0.4, 0, 0.2, 1]
    }
  }
};

// Variantes para el modal
const modalVariants = {
  hidden: { 
    opacity: 0,
    scale: 0.95,
    y: -10
  },
  visible: { 
    opacity: 1,
    scale: 1,
    y: 0,
    transition: {
      type: "spring",
      duration: 0.3,
      bounce: 0.25
    }
  },
  exit: { 
    opacity: 0,
    scale: 0.95,
    y: 10,
    transition: {
      duration: 0.2
    }
  }
};


// En tu CSS o estilos en línea
const taskModalStyle = {
  zIndex: 40
};

const subtaskModalStyle = {
  zIndex: 50 // Mayor que el TaskModal
};
  
  const [expandedTasks, setExpandedTasks] = useState<Set<number>>(() => {
    const expanded = new Set<number>();
    tasks.forEach(task => {
      if (task.nombre === 'TRAMITACIÓN') {
        expanded.add(task.id);
      }
    });
    return expanded;
  });

  useEffect(() => {
    const initialExpandedState = Object.keys(groupedTasks).reduce((acc, proceso) => {
      acc[proceso] = true;
      return acc;
    }, {} as { [key: string]: boolean });
    setExpandedProcesses(initialExpandedState);
  }, [groupedTasks]);
  
  useEffect(() => {
    setExpandedTasks(prev => {
      const newExpanded = new Set(prev);
      tasks.forEach(task => {
        if (task.nombre === 'TRAMITACIÓN') {
          newExpanded.add(task.id);
        }
      });
      return newExpanded;
    });
  }, [tasks]);
  

  // Función para filtrar las tareas activas relevantes
const filterActiveTasks = useCallback((tasks: Task[]) => {
  return tasks.filter(task => {
    // Solo procesar tareas de tipo TRAMITACIÓN
    if (task.nombre !== 'TRAMITACIÓN') return false;

    // Verificar si el usuario actual es responsable de la TRAMITACIÓN
    const isUserResponsible = (() => {
      try {
        const responsables: { id: number }[] = Array.isArray(task.responsable)
          ? task.responsable 
          : JSON.parse(task.responsable as string);

        return responsables.some((resp: { id: number }) => resp.id.toString() === loggedInUserId);
      } catch {
        return false;
      }
    })();

    if (!isUserResponsible) return false;

    // Verificar si hay subtareas activas
    return task.subtasks?.some(subtask => !subtask.isClosed) ?? false;
  });
}, [loggedInUserId]);

 

const fetchTasks = useCallback(async () => {
  const loggedInUserId = sessionStorage.getItem('userId');
  if (!loggedInUserId) {
    console.error('No user ID found in session');
    return;
  }

  try {
    setIsLoading(true);
    
    // Obtener todas las tareas
    const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/get_all_tasks.php`);
    if (!response.ok) throw new Error('Error fetching tasks');

    const data: Task[] = await response.json();
    console.log(data)
    // Para cada tarea de TRAMITACIÓN, cargar sus subtareas
    const tasksWithSubtasks = await Promise.all(data.map(async (task) => {
      // if (task.nombre === 'TRAMITACIÓN') {
      //   try {
      //     const subtasksResponse = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/get_subtasks.php?taskId=${task.id}`);
      //     if (subtasksResponse.ok) {
      //       const subtasksData = await subtasksResponse.json();
      //       return { ...task, subtasks: subtasksData };
      //     }
      //   } catch (error) {
      //     console.error(`Error loading subtasks for task ${task.id}:`, error);
      //   }
      // }
      return task;
    }));

    // Filtrar tareas del usuario y donde no todas las subtareas estén cerradas
    const userTasks = tasksWithSubtasks.filter(task => {
      // Verificar si el usuario es responsable
      try {
        let responsableIds: number[] = [];
        if (typeof task.responsable === 'string') {
          if (task.responsable.startsWith('[')) {
            responsableIds = JSON.parse(task.responsable);
          } else if (task.responsable.includes(',')) {
            responsableIds = task.responsable.split(',').map(id => parseInt(id.trim()));
          } else if (task.responsable) {
            responsableIds = [parseInt(task.responsable)];
          }
        }
        
        // Si es TRAMITACIÓN, verificar también que tenga subtareas activas
        if (task.nombre === 'TRAMITACIÓN') {
          return responsableIds.includes(parseInt(loggedInUserId)) && 
                 task.subtasks && 
                 task.subtasks.length > 0 && 
                 task.subtasks.some((subtask: SubTask) => !subtask.isClosed);
                }
        
        return responsableIds.includes(parseInt(loggedInUserId));
      } catch (error) {
        console.error('Error parsing responsables for task:', task.id, error);
        return false;
      }
    });

    // Ordenar las tareas
    const sortedTasks = userTasks.sort((a, b) => {
      // Primero ordenar por proceso
      const procesoCompare = a.proceso.localeCompare(b.proceso);
      if (procesoCompare !== 0) return procesoCompare;
      
      // Luego por orden de subproceso
      const subprocesoOrdenA = a.subproceso_orden || 0;
      const subprocesoOrdenB = b.subproceso_orden || 0;
      if (subprocesoOrdenA !== subprocesoOrdenB) {
        return subprocesoOrdenA - subprocesoOrdenB;
      }
      
      // Si tienen el mismo orden de subproceso, ordenar por nombre
      const subprocesoCompare = a.subproceso.localeCompare(b.subproceso);
      if (subprocesoCompare !== 0) return subprocesoCompare;
      
      // Finalmente, ordenar por orden dentro del subproceso
      return a.orden - b.orden;
    });

    // Agrupar por proceso
    const grouped = sortedTasks.reduce((acc, task) => {
      if (!acc[task.proceso]) acc[task.proceso] = [];
      acc[task.proceso].push(task);
      return acc;
    }, {} as { [key: string]: Task[] });

    setGroupedTasks(grouped);
    setTasks(sortedTasks);

  } catch (error) {
    console.error('Error fetching tasks:', error);
    toast.error('Error al cargar las tareas');
  } finally {
    setIsLoading(false);
  }
}, []);

  const refreshData = useCallback(async () => {
    try {
      await fetchTasks();
    } catch (error) {
      console.error('Error in refreshData:', error);
      toast.error('Error al actualizar los datos');
    }
  }, [fetchTasks]);
  
  // Cargar datos solo al montar el componente
  useEffect(() => {
    fetchTasks();
  }, []); // Sin dependencias
  
  // Opcional: actualizar cuando se necesite un refresh explícito
  useEffect(() => {
    if (refreshTrigger > 0) {
      refreshData();
    }
  }, [refreshTrigger, refreshData]);

  
  const renderFollowUpStatus = (task: Task) => {
    return task.followUpDate ? (
      <div className="w-6 h-6 rounded-full bg-red-500 mx-auto flex items-center justify-center">
        <Bell className="w-4 h-4 text-white" />
      </div>
    ) : (
      <div className="w-6 h-6 rounded-full bg-gray-200 mx-auto" />
    );
  };

  const getStatusColor = (status: SemaphoreStatus): string => {
    const colors = {
      [SemaphoreStatus.Red]: '#EF4444',
      [SemaphoreStatus.Orange]: '#FB923C',
      [SemaphoreStatus.Yellow]: '#FDE047',
      [SemaphoreStatus.Green]: '#4ADE80',
      [SemaphoreStatus.Gray]: '#E5E7EB'
    };
    return colors[status];
  };




  const calculateTaskStatus = (
    start: string,
    end: string,
    taskId: number,
    dependencia: string | number | null,
    isClosed: boolean,
    allTasks?: Task[],
    parentTask?: Task
  ): SemaphoreStatus => {
    // Si la tarea está cerrada, retornar gris
    if (isClosed) {
      return SemaphoreStatus.Gray;
    }
  
    // Obtener fecha actual y establecer a medianoche
    const today = dateUtils.getTodayLocal();
    today.setHours(0, 0, 0, 0);
  
    // Convertir fechas de string a Date y establecer a medianoche
    const startDate = dateUtils.parseLocalDate(start);
    const endDate = dateUtils.parseLocalDate(end);
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);
  
    // Validación de fechas
    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      return SemaphoreStatus.Gray;
    }
  
    // Si la tarea aún no comienza
    if (startDate > today) {
      return SemaphoreStatus.Gray;
    }
  
    // Si ya pasó la fecha de fin o es el mismo día de fin
    if (today >= endDate) {
      return SemaphoreStatus.Red;
    }
  
    // Calcular duración total y días restantes
    const totalDuration = Math.max(1, Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)));
    const daysRemaining = Math.ceil((endDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
    const percentageCompleted = ((totalDuration - daysRemaining) / totalDuration) * 100;
  
    // Para debug
    // console.log({
    //   taskId,
    //   start: startDate.toISOString(),
    //   end: endDate.toISOString(),
    //   today: today.toISOString(),
    //   totalDuration,
    //   daysRemaining,
    //   percentageCompleted
    // });
  
    // Enfoque híbrido: usar días restantes para tareas cortas y porcentajes para largas
    if (totalDuration <= 5) {
      // Lógica para tareas cortas (5 días o menos)
      if (daysRemaining === 0) {
        return SemaphoreStatus.Red;     // Mismo día = Rojo
      } else if (daysRemaining === 1) {
        return SemaphoreStatus.Orange;  // Un día restante = Naranja
      } else if (daysRemaining <= 3) {
        return SemaphoreStatus.Yellow;  // 2-3 días restantes = Amarillo
      }
      return SemaphoreStatus.Green;     // Más de 3 días = Verde
    } else {
      // Lógica para tareas largas (más de 5 días)
      if (percentageCompleted >= 90) {
        return SemaphoreStatus.Red;     // 90% o más completado = Rojo
      } else if (percentageCompleted >= 75) {
        return SemaphoreStatus.Orange;  // 75-89% completado = Naranja
      } else if (percentageCompleted >= 50) {
        return SemaphoreStatus.Yellow;  // 50-74% completado = Amarillo
      }
      return SemaphoreStatus.Green;     // Menos del 50% completado = Verde
    }
  };
  

 // Fetch users from the server
 const fetchUsers = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/users/get_users.php`);
    if (!response.ok) throw new Error('Error fetching users');
    const data: User[] = await response.json();
    setUsers(data);
  } catch (error) {
    console.error('Error fetching users:', error);
    alert('Error al cargar la lista de usuarios');
  }
};

const handleTaskUpdate = useCallback(async (updatedTask: Task) => {
  try {
    setTasks(prevTasks => 
      prevTasks.map(task => task.id === updatedTask.id ? updatedTask : task)
    );

    if (updatedTask.isClosed) {
      // Encontrar tareas dependientes SOLO en el mismo agrupador
      const dependentTasks = tasks.filter(task => {
        // Verificar que la tarea esté en el mismo agrupador
        if (task.agrupador !== updatedTask.agrupador) return false;

        // Verificar dependencias
        if (!task.dependencia) return false;
        
        const dependencies = typeof task.dependencia === 'string'
          ? task.dependencia.split(',').map(Number)
          : [Number(task.dependencia)];
        
        return dependencies.includes(updatedTask.id);
      });

      // Para cada tarea dependiente, verificar si todas sus dependencias están cerradas
      for (const dependentTask of dependentTasks) {
        const dependencies = typeof dependentTask.dependencia === 'string'
          ? dependentTask.dependencia.split(',').map(Number)
          : [Number(dependentTask.dependencia)];
        
        const allDependenciesClosed = dependencies.every(depId => {
          const depTask = tasks.find(t => t.id === depId);
          return depTask?.isClosed;
        });

        if (allDependenciesClosed) {
          // Calcular nueva fecha de inicio (día actual)
          const today = dateUtils.getTodayString();
          
          // Calcular nueva fecha de término basada en la duración de la tarea
          const endDate = dateUtils.addDaysToDate(today, dependentTask.duracion * 7);

          // Preparar los datos actualizados
          const taskUpdateData = {
            id: dependentTask.id,
            isClosed: false,
            fecha_inicio: today,
            fecha_termino: endDate,
            progreso: 0,
            comments: dependentTask.comments,
            semaphoreStatus: SemaphoreStatus.Green
          };

          // Actualizar en la base de datos
          try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/update_task.php`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(taskUpdateData),
            });

            if (!response.ok) throw new Error('Error updating dependent task');

            // Actualizar el estado local de la tarea
            const updatedTaskInState = {
              ...dependentTask,
              ...taskUpdateData
            };

            // Actualizar tareas
            setTasks(prevTasks => 
              prevTasks.map(task =>  
                task.id === updatedTaskInState.id ? updatedTaskInState : task
              )
            );

          } catch (error) { 
            console.error('Error updating dependent task:', error);
            toast.error('Error al actualizar la tarea dependiente');
          }
        }
      } 

      // Refrescar los datos después de todas las actualizaciones
      await fetchTasks();
    }
  } catch (error) {
    console.error('Error updating task:', error);
    toast.error('Error al actualizar la tarea');
  }
}, [tasks, fetchTasks]);




  // Función para actualizar la subtarea en la base de datos
  const updateSubtaskInDB = async (subtaskData: any) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/subtask_operations.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'update',
          subtask: subtaskData,
          fecha_inicio: subtaskData.start,
          fecha_termino: subtaskData.end, // Asegurar que la fecha de término se envíe correctamente
          resolucion_tipo: subtaskData.resolucion_tipo || null // Ensure we always send the field

        })
      });
    // console.log(JSON.stringify(subtaskData))
      if (!response.ok) {
        throw new Error('Error al actualizar la subtarea');
      }

      return await response.json();
    } catch (error) {
      console.error('Error en updateSubtaskInDB:', error);
      throw error;
    }
  };

  


// Función para actualizar una tarea principal
const updateTaskInDatabase = async (taskData: TaskUpdateData): Promise<void> => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/update_task.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(taskData),
      
    });

    if (!response.ok) {
      throw new Error('Error updating task');
    }

    const result = await response.json();
    if (!result.success) {
      throw new Error(result.message || 'Error updating task');
    }
  } catch (error) {
    console.error('Error in updateTaskInDatabase:', error);
    throw error;
  }
};




const filterVisibleTasks = useCallback((task: Task) => {
  if (task.isClosed) return false;
  
  const dependencies = String(task.dependencia || '')
    .split(',')
    .map(d => parseInt(d.trim()))
    .filter(Boolean);
    
  if (dependencies.length === 0) return true;
  
  return dependencies.every(depId => {
    const depTask = tasks.find(t => t.id === depId);
    return depTask?.isClosed;
  });
}, [tasks]);





  
  // Render component conditionally based on loading state
  if (isLoading) {
    return <div className="flex justify-center items-center h-64">Loading...</div>;
  }

  const parseResponsibles = (responsableString: string): number[] => {
    try {
      if (!responsableString) return [];
      
      if (responsableString.startsWith('[')) {
        return JSON.parse(responsableString);
      }
      if (responsableString.includes(',')) {
        return responsableString.split(',').map(id => parseInt(id.trim()));
      }
      return [parseInt(responsableString)];
    } catch (error) {
      console.error('Error parsing responsables:', error);
      return [];
    }
  };
  
  const renderResponsibleCell = (task: Task) => {
      const isActive = calculateTaskStatus(
        task.fecha_inicio,
        task.fecha_termino,
        task.id,
        task.dependencia,
        task.isClosed,
        tasks
      ) !== SemaphoreStatus.Gray && !task.isClosed;
      if (!isActive) return null;
  
      let responsableIds = parseResponsibles(task.responsable);
  
      return (
        <div 
          className="flex justify-center"
          onClick={(e) => {
            e.stopPropagation(); // Prevent TaskModal from opening
          }}
        >
<UserDisplayModal 
  taskId={task.id}
  responsable={task.responsable}
/>        </div>
      );
    };

  
  const handleRowClick = (task: Task, event: React.MouseEvent) => {
    // Check if the click originated from the UserSelector column
    const target = event.target as HTMLElement;
    if (!target.closest('.user-selector-column')) {
      setSelectedTask(task);
      setIsModalOpen(true);
    }
  };

  const handleTaskClick = (task: Task) => {
    if(task.nombre!=='TRAMITACIÓN'){
    setSelectedTask(task);
    setIsTaskModalOpen(true);
    }
  };
  
  const handleSubtaskClick = (task: Task, subtask: SubTask, event: React.MouseEvent) => {
    event.stopPropagation(); // Evitar que se propague al TaskModal
    setSelectedTask(task);
    setSelectedSubtask(subtask);
    setIsSubtaskModalOpen(true);
  };

  const formatDate = (dateString: string): string => {
    const [year, month, day] = dateString ? dateString.split('-').map(Number) : [0, 0, 0];
    const date = new Date(year, month - 1, day);
    return date.toLocaleDateString('es-CL', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }).replace(/\//g, '-');
  };

  const getRowStyle = (task: Task, index: number) => {
    const baseStyle = "transition-all duration-200 hover:bg-opacity-90";
    return task.nombre === 'TRAMITACIÓN' ? `${baseStyle} bg-teal-50` : `${baseStyle} ${index % 2 === 0 ? 'bg-opacity-5' : 'bg-opacity-10'}`;
  };

  if (isLoading) {
    return <div className="flex justify-center items-center h-64">Loading...</div>;
  }
  
  if (Object.keys(groupedTasks).length === 0 || 
  Object.values(groupedTasks).every(tasks => 
    tasks.every(task => task.isClosed)
  )) {
    return (
      <div className="flex flex-col items-center justify-center h-96 text-center text-gray-600">
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.3 }}
          className="mb-4"
        >
          <AlertCircle className="w-16 h-16 text-gray-400" />
        </motion.div>
        <h2 className="text-xl font-semibold mb-2">No hay tareas disponibles</h2>
        <p className="text-gray-500">Por favor, agregue nuevas tareas o revise los filtros aplicados.</p>
      </div>
    );
  }
  
  return (
    <div className="overflow-x-auto">
           {renderTasksByProcess()}

 {/* Task Modal */}
 <AnimatePresence>
        {selectedTask && isTaskModalOpen && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black bg-opacity-50 z-[10000]"
              onClick={() => setIsTaskModalOpen(false)}
            />
            <motion.div
              variants={modalVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              className="fixed inset-0 z-[10001]"
            >
              <TaskModal
                isOpen={isTaskModalOpen}
                onClose={() => {
                  setIsTaskModalOpen(false);
                  setSelectedTask(null);
                }}
                task={selectedTask}
                onChangeTask={handleTaskUpdate}
                backgroundColor="#4B5563"
                allTasks={tasks}
                agrupadorId={selectedTask.id}
                groupProgress={selectedTask.progreso}
                processName={selectedTask.proceso}
                subprocessName={selectedTask.subproceso}
                agrupadorName={selectedTask.agrupador}
                daysDelayed={0}
                calculateTaskStatus={calculateTaskStatus}
                onSelectNextTask={(nextTask: Task) => {
                  setSelectedTask(nextTask);
                  setIsModalOpen(true);
                }}
              />
            </motion.div>
          </>
        )}
      </AnimatePresence>

 {/* Subtask Modal */}
 <AnimatePresence>
        {selectedSubtask && selectedTask && isSubtaskModalOpen && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black bg-opacity-50 z-[10000]"
              onClick={() => setIsSubtaskModalOpen(false)}
            />
            <motion.div
              variants={modalVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              className="fixed inset-0 z-[10001]"
            >
      <SubtaskModal
        isOpen={isSubtaskModalOpen}
        onClose={() => {
          setIsSubtaskModalOpen(false);
          setSelectedSubtask(null);
        }}
        subtask={selectedSubtask}
        mainTask={selectedTask}
    onChangeTask={handleTaskUpdate}
    onChangeSubtask={async (updatedSubtask) => {
      try {
        if (!selectedTask) return; // Verificación de seguridad

        const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/subtask_operations.php`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            action: 'update',
            subtask: updatedSubtask
          })
        });

        if (!response.ok) {
          throw new Error('Error updating subtask');
        }

        // Construir la tarea actualizada asegurándonos de que tenga todos los campos requeridos
        const updatedTask: Task = {
          ...selectedTask,
          subtasks: selectedTask.subtasks?.map(st =>
            st.id === updatedSubtask.id ? updatedSubtask : st
          ) || []
        };

        handleTaskUpdate(updatedTask);
        toast.success('Subtarea actualizada exitosamente');
      } catch (error) {
        console.error('Error:', error);
        toast.error('Error al actualizar la subtarea');
      }
    }}
    onAddNewSubtask={async (option) => {
      try {
        if (!selectedTask) return; // Verificación de seguridad

        const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/subtask_operations.php`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            action: 'create',
            tarea_id: selectedTask.id,
            subtask: {
              ...option,
              start: dateUtils.getTodayString(),
              end: dateUtils.addDaysToDate(dateUtils.getTodayString(), 7),
              progress: 0,
              isClosed: false
            }
          })
        });

        if (!response.ok) {
          throw new Error('Error creating subtask');
        }

        await refreshData();
        toast.success('Nueva subtarea creada exitosamente');
        setIsSubtaskModalOpen(false);
      } catch (error) {
        console.error('Error:', error);
        toast.error('Error al crear la nueva subtarea');
      }
    }}
    backgroundColor={selectedTask.subproceso_color || '#10B981'}
    groupProgress={selectedTask.progreso}
    processName={selectedTask.proceso}
    subprocessName={selectedTask.subproceso}
    agrupadorName={selectedTask.agrupador}
  />
  </motion.div>
  </>
)}
</AnimatePresence>
</div>
);
};
export default ProcessStatusTable;
