import React, { useState, useEffect, useRef } from 'react';
import { Folder, File, ChevronDown, ChevronRight, X, Maximize2, Minimize2, Loader, Send, 
  Plus, Edit, Trash2, Upload, Save, AlertCircle, 
  Settings,
  BotIcon} from 'lucide-react';

// Constants for API URLs
const API_BASE_URL = process.env.REACT_APP_API_URL || '';
const FILES_API_URL = `${API_BASE_URL}/php/pages/ia/files.php`;
const CHAT_API_URL = `${API_BASE_URL}/php/pages/ia/chat.php`;
const CHAT_GPT_API_URL = `${API_BASE_URL}/php/pages/ia/chat_gpt.php`; // Nuevo endpoint para GPT
const CHAT_HISTORY_API_URL = `${API_BASE_URL}/php/pages/ia/chat_history.php`; // Nuevo endpoint para historial

// Helper function to check if user is admin
const isUserAdmin = () => {
  // Replace with your actual auth logic
  // For example, check local storage or context
  return localStorage.getItem('userRole') === 'admin' || true; // True for testing
};

// Helper function to get current user ID
const getCurrentUserId = () => {
    // Intenta obtener el userId de varias fuentes con prioridad
    const userIdFromLocal = localStorage.getItem('userId');
    const userIdFromSession = sessionStorage.getItem('userId');
    const userIdFromAdmin = sessionStorage.getItem('isAdmin') === '1' ? 'admin' : null;
    
    // Usar el primer valor disponible o un valor por defecto
    const userId = userIdFromLocal || userIdFromSession || userIdFromAdmin || '1';
    
 //   console.log('User ID obtenido:', userId); // Añadir log para depuración
    return userId;
  };
  

// File type interface
interface FileItem {
  id: string;
  name: string;
  type: 'file' | 'folder';
  url?: string;
  modificationDate?: string;
  parent_id?: string | null;
  children?: FileItem[];
}

// Message interface
interface Message {
    text: string;
    sender: 'user' | 'ai';
    loading?: boolean;
    timestamp?: string; // Añadido para mostrar la fecha/hora del mensaje
    model?: 'RAG' | 'ASIST'; // Add this property to track which model was used
  }

const BibliotecaNormativaChat: React.FC = () => {
  // Referencias para controlar el scroll y el tamaño
 // Añadir cerca de los demás estados
const [showAllMessages, setShowAllMessages] = useState(false); // Si es false, filtra por modelo actual

  // Nuevos estados para RAG
const [ogucEnabled, setOgucEnabled] = useState(true);
const [lgucEnabled, setLgucEnabled] = useState(true);
const [ragModel, setRagModel] = useState<'o3-mini-2025-01-31' | 'gpt-4o'>('o3-mini-2025-01-31');
const [showRagModelDropdown, setShowRagModelDropdown] = useState(false);
const ragModelDropdownRef = useRef<HTMLDivElement>(null);


  const chatContainerRef = useRef<HTMLDivElement>(null);
  const mainContainerRef = useRef<HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(() => {
    return sessionStorage.getItem('isAdmin') === '1';
  });
  const PDF_VIEWER_URL = `${API_BASE_URL}/php/pages/ia/view_pdf.php`;
  const [dividerHovered, setDividerHovered] = useState(false); 
  const [selectedModel, setSelectedModel] = useState<'RAG' | 'ASIST'>('RAG');
  const [showModelDropdown, setShowModelDropdown] = useState(false);

  const [containerHeight, setContainerHeight] = useState('calc(100vh - 120px)'); // Altura dinámica

  const [showScrollToBottom, setShowScrollToBottom] = useState(false);



  // Estado para los archivos y carpetas
  const [files, setFiles] = useState<FileItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Estado para el archivo a enviar con el mensaje
const [attachmentFile, setAttachmentFile] = useState<File | null>(null);
const attachmentInputRef = useRef<HTMLInputElement>(null);

  // Estado para modo edición
  const [editMode, setEditMode] = useState(false);
  const [editingFile, setEditingFile] = useState<FileItem | null>(null);
  const [newFileName, setNewFileName] = useState('');
  const [newFileUrl, setNewFileUrl] = useState('');
  const [showAddForm, setShowAddForm] = useState(false);
  const [selectedParentFolder, setSelectedParentFolder] = useState<string | null>(null);
  const [newItemType, setNewItemType] = useState<'file' | 'folder'>('file');

  // Nueva variable para rastrear la carpeta actualmente seleccionada con el mouse
  const [currentSelectedFolder, setCurrentSelectedFolder] = useState<FileItem | null>(null);
   // Estado para los nodos expandidos
   const [expandedNodes, setExpandedNodes] = useState<Record<string, boolean>>({});

   // Guardar el estado actual de expandedNodes y carpeta seleccionada
   const currentExpandedState = {...expandedNodes};
   const currentFolder = selectedParentFolder;
   

  // Estado para mensajes del chat
  const [messages, setMessages] = useState<Message[]>([
    { text: "Bienvenido a Simagi. Soy tu asistente IA. ¿En qué puedo ayudarte?", sender: 'ai' }
  ]);
  
  // Estado para el mensaje actual que se está escribiendo
  const [currentMessage, setCurrentMessage] = useState('');
  
  // Estado para manejar la maximización/minimización de las ventanas
  const [isExplorerMaximized, setIsExplorerMaximized] = useState(false);
  const [isChatMaximized, setIsChatMaximized] = useState(false);
  
  // Estado para el archivo PDF seleccionado actualmente
  const [selectedFile, setSelectedFile] = useState<FileItem | null>(null);
  
  // Estado para controlar la visualización del PDF
  const [showPDFViewer, setShowPDFViewer] = useState(false);
  
  // Estado para indicar que el bot está escribiendo
  const [ragBotTyping, setRagBotTyping] = useState(false);
  const [gptBotTyping, setGptBotTyping] = useState(false);

  // NUEVO: Estado para controlar el ancho del panel izquierdo
  const [leftPanelWidth, setLeftPanelWidth] = useState(50); // Porcentaje inicial (50%)
  const [isResizing, setIsResizing] = useState(false);



  const scrollbarStyles = `
  /* Custom scrollbar styles for RAG */
  .rag-scrollbar::-webkit-scrollbar {
    width: 10px;
  }
  
  .rag-scrollbar::-webkit-scrollbar-track {
    background: #ffeeee;
  }
  
  .rag-scrollbar::-webkit-scrollbar-thumb {
    background-color: #ef4444;
    border-radius: 6px;
    border: 2px solid #ffeeee;
  }
  
  .rag-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #dc2626;
  }
  
  /* Custom scrollbar styles for GPT */
  .gpt-scrollbar::-webkit-scrollbar {
    width: 10px;
  }
  
  .gpt-scrollbar::-webkit-scrollbar-track {
    background: #ecfdf5;
  }
  
  .gpt-scrollbar::-webkit-scrollbar-thumb {
    background-color: #14b8a6;
    border-radius: 6px;
    border: 2px solid #ecfdf5;
  }
  
  .gpt-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #0d9488;
  }
`;

  // Función para manejar la selección de archivos adjuntos
const handleAttachmentSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
  if (e.target.files && e.target.files.length > 0) {
    setAttachmentFile(e.target.files[0]);
  }
};

// Función para eliminar el archivo adjunto
const handleRemoveAttachment = () => {
  setAttachmentFile(null);
  if (attachmentInputRef.current) {
    attachmentInputRef.current.value = '';
  }
};

// Add a useEffect to scroll to the bottom when model changes
useEffect(() => {
  // When the model changes, scroll to the most recent messages
  if (chatContainerRef.current) {
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  }
}, [selectedModel]); // This will trigger whenever selectedModel changes

   // Calcular altura del contenedor cuando se monta el componente
   useEffect(() => {
    const updateContainerHeight = () => {
      // Ajusta este cálculo según tus necesidades específicas del diseño
      const windowHeight = window.innerHeight;
      const topOffset = mainContainerRef.current?.getBoundingClientRect().top || 0;
      const bottomMargin = 20; // margen inferior en pixeles
      
      const newHeight = windowHeight - topOffset - bottomMargin;
      setContainerHeight(`${newHeight}px`);
    };

    // Actualizar altura inicial
    updateContainerHeight();
    
    // Actualizar cuando cambia el tamaño de la ventana
    window.addEventListener('resize', updateContainerHeight);
    
    return () => {
      window.removeEventListener('resize', updateContainerHeight);
    };
  }, []);


  // Efecto para manejar clics fuera del dropdown
useEffect(() => {
  function handleClickOutside(event: MouseEvent) {
    if (ragModelDropdownRef.current && !ragModelDropdownRef.current.contains(event.target as Node)) {
      setShowRagModelDropdown(false);
    }
  }

  // Solo agregar el listener cuando el dropdown está abierto
  if (showRagModelDropdown) {
    document.addEventListener("mousedown", handleClickOutside);
  }
  
  return () => {
    document.removeEventListener("mousedown", handleClickOutside);
  };
}, [showRagModelDropdown]);

  // Comprobar si el usuario es administrador al cargar
  useEffect(() => {
    setIsAdmin(isUserAdmin());
  }, []);

   // Cargar el historial de chat al iniciar
   useEffect(() => {
    fetchChatHistory();
  }, []);


  // Hacer scroll automático cuando se añaden nuevos mensajes
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

// Modificación del useEffect para cargar archivos iniciales
useEffect(() => {
    const loadFiles = async () => {
      await fetchFilesKeepingState({}, null);
    };
    loadFiles();
  }, [isAdmin]);


  // NUEVO: Limpiar los event listeners cuando el componente se desmonte
  useEffect(() => {
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isResizing]); // Dependencia en isResizing para que se actualice correctamente



 // Función para obtener el historial de chat desde la API
  const fetchChatHistory = async () => {
    try {
      // Obtener el ID del usuario actual
      const userId = getCurrentUserId();
      
      // Si no hay ID de usuario, no intentar obtener el historial
      if (!userId) {
       // console.log('No hay ID de usuario disponible para obtener el historial');
        return;
      }
      
      const response = await fetch(`${CHAT_HISTORY_API_URL}?user_id=${userId}`);
      
      if (!response.ok) {
        throw new Error(`Error HTTP: ${response.status}`);
      }
      
      const result = await response.json();
      
      if (result.status === 'success' && Array.isArray(result.data) && result.data.length > 0) {
        // Formatear los mensajes desde el historial
        const historyMessages: Message[] = [];
        
        // Añadir el mensaje de bienvenida primero
        historyMessages.push({ 
          text: "Bienvenido a Simagi. Soy tu asistente IA. ¿En qué puedo ayudarte?", 
          sender: 'ai' 
        });
        
        // Luego añadir los mensajes del historial en orden cronológico
        result.data.forEach((item: any) => {
          // Añadir el mensaje del usuario
          historyMessages.push({
            text: item.user_message,
            sender: 'user',
            timestamp: item.timestamp,
            model: item.model || 'RAG' // Use the model from history or default to GPT

          });
          
          // Añadir la respuesta de la IA (si existe)
          if (item.ai_response) {
            try {
              // Intentar parsear la respuesta JSON
              const aiResponse = JSON.parse(item.ai_response);
              historyMessages.push({
                text: aiResponse.answer || "No hay respuesta disponible",
                sender: 'ai',
                timestamp: item.response_timestamp,
                model: item.model || 'RAG' // Use the model from history or default to GPT

              });
            } catch (e) {
              // Si no es JSON válido, usar directamente
              historyMessages.push({
                text: item.ai_response,
                sender: 'ai',
                timestamp: item.response_timestamp,
                model: item.model || 'RAG' // Use the model from history or default to GPT

              });
            }
          }
        });
        
        // Actualizar el estado de mensajes
        setMessages(historyMessages);
        
     //   console.log('Historial de chat cargado:', historyMessages);
      } else {
     //   console.log('No hay historial de chat disponible o formato incorrecto');
      }
    } catch (err) {
      console.error('Error al cargar el historial de chat:', err);
    }
  };


  // NUEVO: Funciones para manejar el redimensionamiento
  const handleMouseDown = (e: React.MouseEvent) => {
    // Asegurarse de que el evento se maneje correctamente
    e.stopPropagation();
    e.preventDefault();
    
   // console.log("MouseDown activado"); // Para depurar
    
    // Establecer estado de redimensionamiento inmediatamente
    setIsResizing(true);
    
    // Crear referencias a las funciones para poder eliminarlas después
    const moveHandler = (moveEvent: MouseEvent) => {
      moveEvent.preventDefault();
      
      if (mainContainerRef.current) {
        const containerRect = mainContainerRef.current.getBoundingClientRect();
        const containerWidth = containerRect.width;
        const mouseX = moveEvent.clientX - containerRect.left;
        
        // Calcular el nuevo ancho como porcentaje (con límites)
        const newWidthPercent = Math.min(Math.max((mouseX / containerWidth) * 100, 20), 80);
        setLeftPanelWidth(newWidthPercent);
      }
    };
    
    const upHandler = () => {
      setIsResizing(false);
      document.removeEventListener('mousemove', moveHandler);
      document.removeEventListener('mouseup', upHandler);
    };
    
    // Agregar los listeners directamente aquí en lugar de usar una referencia separada
    document.addEventListener('mousemove', moveHandler);
    document.addEventListener('mouseup', upHandler);
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (isResizing && mainContainerRef.current) {
      const containerRect = mainContainerRef.current.getBoundingClientRect();
      const containerWidth = containerRect.width;
      const mouseX = e.clientX - containerRect.left;
      
      // Calcular el nuevo ancho como porcentaje (con límites)
      const newWidthPercent = Math.min(Math.max((mouseX / containerWidth) * 100, 20), 80);
      setLeftPanelWidth(newWidthPercent);
    }
  };

  const handleMouseUp = () => {
    setIsResizing(false);
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  // Función para obtener archivos desde la API PHP
//   const fetchFiles = async () => {
//     try {
//       setLoading(true);
//       const response = await fetch(FILES_API_URL);
//       if (!response.ok) throw new Error(`Error HTTP: ${response.status}`);

//       const result = await response.json();
//       if (result.status === 'success') {
//         setFiles(result.data);
//         const initialExpanded: Record<string, boolean> = {};
//         initializeExpandedNodes(result.data, initialExpanded);
//         initialExpanded['1'] = true;
//         setExpandedNodes(initialExpanded);
//       } else {
//         throw new Error(result.message || 'Error al cargar los archivos');
//       }
//       setLoading(false);
//     } catch (err) {
//       console.error('Error al cargar archivos:', err);
//       setError('No se pudieron cargar los archivos. Por favor, intenta de nuevo más tarde.');
//       setLoading(false);
//     }
//   };

// Función para obtener archivos manteniendo el estado de expansión
const fetchFilesKeepingState = async (
  stateToKeep: Record<string, boolean>, 
  folderToExpand: string | null = null
) => {
        try {
      setLoading(true);
      const response = await fetch(FILES_API_URL);
      if (!response.ok) throw new Error(`Error HTTP: ${response.status}`);
  
      const result = await response.json();
      if (result.status === 'success') {
        setFiles(result.data);
        
        // Preparar el nuevo estado de expandedNodes
        const newExpandedState = {...stateToKeep};
        
        // Si hay un folderToExpand, asegurarse de que esté expandido
        if (folderToExpand) {
          newExpandedState[folderToExpand] = true;
          
          // También expandir todos los padres de la carpeta actual
          ensureParentsExpanded(result.data, folderToExpand, newExpandedState);
        }
        
        setExpandedNodes(newExpandedState);
      } else {
        throw new Error(result.message || 'Error al cargar los archivos');
      }
      setLoading(false);
    } catch (err) {
      console.error('Error al cargar archivos:', err);
      setError('No se pudieron cargar los archivos. Por favor, intenta de nuevo más tarde.');
      setLoading(false);
    }
  };
  
  // Función auxiliar para expandir todos los padres de una carpeta
  const ensureParentsExpanded = (
    items: FileItem[], 
    childId: string, 
    expandedState: Record<string, boolean>
  ) => {
    // Buscar recursivamente la carpeta padre del elemento dado
    const findAndExpandParents = (
        items: FileItem[], 
        targetId: string, 
        visited: Set<string> = new Set<string>()
      ): string | null => {      if (visited.has(targetId)) return null; // Evitar ciclos
      visited.add(targetId);
      
      for (const item of items) {
        // Si encontramos el elemento, retornar su ID
        if (item.id === targetId) {
          return item.parent_id || null;
        }
        // Si este elemento tiene hijos, buscar en ellos
        if (item.children && item.children.length > 0) {
          const parentId = findAndExpandParents(item.children, targetId, visited);
          if (parentId) {
            // Si encontramos el elemento en los hijos, expandir este elemento
            expandedState[item.id] = true;
            return parentId;
          }
        }
      }
      
      return null; // No encontrado
    };
    
    // Iniciar la búsqueda recursiva
    let parentId = findAndExpandParents(items, childId);
    
    // Continuar expandiendo padres hasta llegar a la raíz
    while (parentId) {
      expandedState[parentId] = true;
      parentId = findAndExpandParents(items, parentId);
    }
  };

  // Función auxiliar para inicializar los nodos expandidos recursivamente
  const initializeExpandedNodes = (items: FileItem[], result: Record<string, boolean>) => {
    items.forEach(item => {
      result[item.id] = false;
      if (item.children && item.children.length > 0) {
        initializeExpandedNodes(item.children, result);
      }
    });
  };

  // Función para alternar la expansión de carpetas
  const toggleFolder = (folder: FileItem, event?: React.MouseEvent) => {
    if (event) {
      event.stopPropagation();
    }
    
    setExpandedNodes(prev => ({
      ...prev,
      [folder.id]: !prev[folder.id]
    }));
  };

  // Función para seleccionar una carpeta con el mouse
  const handleFolderSelect = (folder: FileItem, event: React.MouseEvent) => {
    event.stopPropagation();
    setCurrentSelectedFolder(folder);
  };

  // Función para mostrar el formulario de agregar nuevo archivo/carpeta
  const handleShowAddForm = (parentId: string | null = null, type: 'file' | 'folder' = 'file') => {
    // Si hay una carpeta seleccionada con el mouse y no se especifica un parentId, usar la carpeta seleccionada
    const effectiveParentId = parentId || (currentSelectedFolder?.id || null);
    
    setSelectedParentFolder(effectiveParentId);
    setNewItemType(type);
    setNewFileName('');
    setNewFileUrl('');
    setEditingFile(null);
    setShowAddForm(true);
    setEditMode(false);
  };

  // Función para cancelar la adición/edición
  const handleCancelAdd = () => {
    setShowAddForm(false);
    setEditMode(false);
    setEditingFile(null);
  };

  // Función para mostrar el formulario de edición
  const handleShowEditForm = (file: FileItem) => {
    setEditingFile(file);
    setNewFileName(file.name);
    setNewFileUrl(file.url || '');
    setSelectedParentFolder(file.parent_id || null);
    setShowAddForm(true);
    setEditMode(true);
  };

  
  // Función para guardar un nuevo archivo/carpeta
  const handleSaveNewItem = async () => {
    if (!newFileName.trim()) {
      alert('Por favor ingrese un nombre válido');
      return;
    }
    
    // Si es un archivo, validar URL para archivo manualmente agregado
    if (newItemType === 'file' && !newFileUrl.trim() && !fileInputRef.current?.files?.length) {
      alert('Por favor ingrese una URL válida o seleccione un archivo');
      return;
    }
    
    try {
      setLoading(true);
      
      // Si hay un archivo seleccionado, subirlo
      if (newItemType === 'file' && fileInputRef.current?.files?.length) {
        const formData = new FormData();
        formData.append('file', fileInputRef.current.files[0]);
        formData.append('parent_id', selectedParentFolder || '');
        
        const response = await fetch(FILES_API_URL, {
          method: 'POST',
          body: formData
        });
        
        if (!response.ok) {
          throw new Error(`Error HTTP: ${response.status}`);
        }
        
        const result = await response.json();
        
        if (result.status !== 'success') {
          throw new Error(result.message || 'Error al subir el archivo');
        }
      } else {
        // Si no hay archivo seleccionado, crear entrada manual en la BD
        const data = {
          name: newFileName,
          type: newItemType,
          parent_id: selectedParentFolder,
          url: newItemType === 'file' ? newFileUrl : null
        };
        
        const response = await fetch(FILES_API_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        });
        
        if (!response.ok) {
          throw new Error(`Error HTTP: ${response.status}`);
        }
        
        const result = await response.json();
        
        if (result.status !== 'success') {
          throw new Error(result.message || 'Error al crear el elemento');
        }
      }
      
      // Recargar la lista de archivos
      //fetchFiles();
      await fetchFilesKeepingState(currentExpandedState, currentFolder);

      setShowAddForm(false);
    } catch (err) {
      console.error('Error al guardar:', err);
      alert('Error al guardar el elemento. Por favor, intenta de nuevo.');
    } finally {
      setLoading(false);
    }
  };

  // Función para actualizar un archivo/carpeta existente
  const handleUpdateItem = async () => {
    if (!editingFile || !newFileName.trim()) {
      alert('Información incompleta o inválida');
      return;
    }
    
    try {
      setLoading(true);
      
      const data = {
        name: newFileName,
        parent_id: selectedParentFolder,
        url: editingFile.type === 'file' ? newFileUrl : null
      };
      
      const response = await fetch(`${FILES_API_URL}/${editingFile.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
      
      if (!response.ok) {
        throw new Error(`Error HTTP: ${response.status}`);
      }
      
      const result = await response.json();
      
      if (result.status !== 'success') {
        throw new Error(result.message || 'Error al actualizar el elemento');
      }
      
      // Recargar la lista de archivos
      await fetchFilesKeepingState(currentExpandedState, selectedParentFolder);
      setShowAddForm(false);
      setEditMode(false);
      setEditingFile(null);
    } catch (err) {
      console.error('Error al actualizar:', err);
      alert('Error al actualizar el elemento. Por favor, intenta de nuevo.');
    } finally {
      setLoading(false);
    }
  };



// Función para manejar el scroll y mostrar/ocultar el botón de ir abajo
const handleScroll = () => {
  if (chatContainerRef.current) {
    const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
    // Mostrar el botón si el usuario ha subido al menos 300px desde el fondo
    const isScrolledUp = scrollHeight - scrollTop - clientHeight > 300;
    setShowScrollToBottom(isScrolledUp);
  }
};

// Añadir el listener de scroll cuando el componente se monta
useEffect(() => {
  const chatContainer = chatContainerRef.current;
  if (chatContainer) {
    chatContainer.addEventListener('scroll', handleScroll);
  }
  
  return () => {
    if (chatContainer) {
      chatContainer.removeEventListener('scroll', handleScroll);
    }
  };
}, []);

// Función actualizada para desplazarse suavemente al fondo del chat
const scrollToBottom = () => {
  if (chatContainerRef.current) {
    // Obtener la posición actual y la posición final
    const startPosition = chatContainerRef.current.scrollTop;
    const targetPosition = chatContainerRef.current.scrollHeight - chatContainerRef.current.clientHeight;
    
    // Duración de la animación en ms
    const duration = 500;
    const startTime = performance.now();
    
    // Función de animación
    const animateScroll = (currentTime: number) => {
      const elapsedTime = currentTime - startTime;
      
      if (elapsedTime < duration) {
        // Calcular la nueva posición usando una función de suavizado
        const progress = easeInOutCubic(elapsedTime / duration);
        const newPosition = startPosition + (targetPosition - startPosition) * progress;
        
        // Establecer la nueva posición
        chatContainerRef.current!.scrollTop = newPosition;
        
        // Continuar la animación
        requestAnimationFrame(animateScroll);
      } else {
        // Finalizar la animación en la posición exacta
        chatContainerRef.current!.scrollTop = targetPosition;
        setShowScrollToBottom(false);
      }
    };
    
    // Iniciar la animación
    requestAnimationFrame(animateScroll);
  }
};

// Función de suavizado para la animación (easing function)
const easeInOutCubic = (t: number): number => {
  return t < 0.5 
    ? 4 * t * t * t 
    : 1 - Math.pow(-2 * t + 2, 3) / 2;
};



  // Función para eliminar un archivo/carpeta
  const handleDeleteItem = async (file: FileItem) => {
    const confirmMessage = file.type === 'folder' 
      ? `¿Estás seguro de eliminar la carpeta "${file.name}" y todo su contenido?`
      : `¿Estás seguro de eliminar el archivo "${file.name}"?`;
    
    if (!window.confirm(confirmMessage)) {
      return;
    }
    
    try {
      setLoading(true);
      
      const response = await fetch(`${FILES_API_URL}/${file.id}`, {
        method: 'DELETE'
      });
      
      if (!response.ok) {
        throw new Error(`Error HTTP: ${response.status}`);
      }
      
      const result = await response.json();
      
      if (result.status !== 'success') {
        throw new Error(result.message || 'Error al eliminar el elemento');
      }
      
      // Si se eliminó el archivo que estaba siendo visualizado, cerrar el visor
      if (selectedFile?.id === file.id) {
        setSelectedFile(null);
        setShowPDFViewer(false);
      }
      
      // Si se eliminó la carpeta seleccionada, quitar la selección
      if (currentSelectedFolder?.id === file.id) {
        setCurrentSelectedFolder(null);
      }
      
      // Recargar la lista de archivos
      await fetchFilesKeepingState(currentExpandedState, file.parent_id);
    } catch (err) {
      console.error('Error al eliminar:', err);
      alert('Error al eliminar el elemento. Por favor, intenta de nuevo.');
    } finally {
      setLoading(false);
    }
  };

  // Función para mostrar el selector de archivos
  const handleShowFileSelector = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // Efecto de escritura del bot con simulación gradual
const simulateBotTyping = (finalMessage: string, model: 'RAG' | 'ASIST') => {
  // Set the appropriate typing state based on the model
  if (model === 'RAG') {
    setRagBotTyping(true);
  } else {
    setGptBotTyping(true);
  }
  
  // Tiempo total basado en la longitud del mensaje
  const totalTypingTime = Math.min(Math.max(finalMessage.length * 15, 1500), 4000);
  
  // Calcular los pasos de simulación (cada paso agrega un poco más de texto)
  const numSteps = 6; // Cantidad de actualizaciones parciales
  const stepTime = totalTypingTime / numSteps;
  
  let currentStep = 0;
  
  // Simulación de escritura gradual
  const typingInterval = setInterval(() => {
    currentStep++;
    
    // Mostrar una parte del mensaje basada en el paso actual
    if (currentStep < numSteps) {
      const partialLength = Math.floor((finalMessage.length * currentStep) / numSteps);
      const partialMessage = finalMessage.substring(0, partialLength);
      
      // Actualizar el mensaje con el texto parcial
      // Buscar si ya hay un mensaje de loading
      setMessages(prev => {
        const newMessages = [...prev];
        const lastMessage = newMessages[newMessages.length - 1];
          
        if (lastMessage.loading) {
          // Ya hay uno, no agregar otro
          return newMessages;
        }
      
        // Si no hay, agregamos uno nuevo
        return [...newMessages, { text: '', sender: 'ai', loading: true, model }];
      });

    } else {
      // Al final, mostrar el mensaje completo y detener la simulación
      clearInterval(typingInterval);
      setMessages(prev => {
        const newMessages = [...prev];
        newMessages[newMessages.length - 1] = {
          text: finalMessage,
          sender: 'ai',
          loading: false,
          model
        };
        return newMessages;
      });
      
      // Set the appropriate typing state based on the model
      if (model === 'RAG') {
        setRagBotTyping(false);
      } else {
        setGptBotTyping(false);
      }
    }
  }, stepTime);
};

  const handleSendMessage = async () => {
    if (!currentMessage.trim() && !attachmentFile) return;
    const model = selectedModel;

    // Texto para mostrar en el mensaje
    const displayText = attachmentFile 
      ? `${currentMessage || "He adjuntado un archivo:"} [Archivo: ${attachmentFile.name}]` 
      : currentMessage;
    
    // Añadir el mensaje del usuario
// Cuando añades el mensaje del usuario
setMessages(prev => [...prev, { 
  text: displayText, 
  sender: 'user',
  model: selectedModel // Agregar modelo al mensaje del usuario
}]);
    const messageToSend = currentMessage;
    setCurrentMessage('');
    
    // Iniciar simulación de escritura de inmediato con puntos suspensivos
    setMessages(prev => [...prev, { 
      text: 'Pensando...', 
      sender: 'ai',
      loading: true,
      model: selectedModel // Add the model here

    }]);
  // Set the appropriate typing state based on the model
  if (model === 'RAG') {
    setRagBotTyping(true);
  } else {
    setGptBotTyping(true);
  }
      
    // Animación de puntos suspensivos mientras espera respuesta
    let dots = 0;
    const thinkingInterval = setInterval(() => {
      dots = (dots + 1) % 4;
      const thinkingText = "Pensando" + ".".repeat(dots);
      
    // Actualizar solo el mensaje de "Pensando..." sin tocar el del usuario
    setMessages(prev => {
      const newMessages = [...prev];
      // Asegurarnos de actualizar solo el último mensaje (el de "Pensando...")
      if (newMessages.length > 0 && newMessages[newMessages.length - 1].loading) {
        newMessages[newMessages.length - 1] = {
          text: thinkingText,
          sender: 'ai',
          loading: true,
          model: selectedModel
        };
      }
      return newMessages;
    });
  }, 500);
  
    try {
      // Obtener el ID del usuario actual
      const userId = getCurrentUserId();
      
      // Crear objeto FormData si hay archivo adjunto
      let requestData;
      let fetchOptions: RequestInit = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      };


// Determinar qué documentos se deben consultar para RAG
    let ragDocs = '';
    if (selectedModel === 'RAG') {
    //  alert(`Current button states - OGUC: ${ogucEnabled ? 'Enabled' : 'Disabled'}, LGUC: ${lgucEnabled ? 'Enabled' : 'Disabled'}`);

      if (ogucEnabled && lgucEnabled) {
        ragDocs = 'OGUC, LGUC';
      } else if (ogucEnabled) {
        ragDocs = 'OGUC';
      } else if (lgucEnabled) {
        ragDocs = 'LGUC';
      } else {
        // Si ninguno está habilitado, habilitar uno por defecto
        ragDocs = 'OGUC, LGUC';
        setOgucEnabled(true);
        setLgucEnabled(true);
      }
      //alert(`Documents to query: ${ragDocs}`);

      requestData = {
        message: messageToSend,
        selectedFileId: selectedFile && Number.isInteger(parseInt(selectedFile.id)) ? parseInt(selectedFile.id) : null,
        user_id: userId,
        model: selectedModel,
        rag_model: ragModel,
        rag_docs: ragDocs
      };
    } else {
      // Para GPT sin archivo adjunto
      requestData = {
        message: messageToSend,
        selectedFileId: selectedFile && Number.isInteger(parseInt(selectedFile.id)) ? parseInt(selectedFile.id) : null,
        user_id: userId,
        model: selectedModel,
        rag_model: ragModel,
        rag_docs: ragDocs
      };
    }



      
      if (attachmentFile) {
        // Si hay archivo, usamos FormData en lugar de JSON
        const formData = new FormData();
        formData.append('message', messageToSend);
        formData.append('attachment', attachmentFile);
        formData.append('user_id', userId);
        formData.append('model', selectedModel);
        if (selectedFile) {
          formData.append('selectedFileId', selectedFile.id);
        }
        
        requestData = formData;
        // Quitar el Content-Type para que el navegador establezca el correcto para FormData
        fetchOptions = {
          method: 'POST',
          body: formData
        };
      } else {
        // Si no hay archivo, seguimos usando JSON
        requestData = {
          message: messageToSend,
          selectedFileId: selectedFile && Number.isInteger(parseInt(selectedFile.id)) ? parseInt(selectedFile.id) : null,
          user_id: userId,
          model: selectedModel,
          rag_model: ragModel,
          rag_docs: ragDocs
        };
        
        fetchOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(requestData)
        };
      }

      //console.log(`Enviando consulta al modelo ${selectedModel}:`, requestData);
      const endpointUrl = selectedModel === 'ASIST' ? CHAT_GPT_API_URL : CHAT_API_URL;
      
      // Realizar la petición POST
      const response = await fetch(endpointUrl, fetchOptions);
      
     // console.log(`Código de estado de respuesta de ${selectedModel}:`, response.status);
      
      // Limpiar el archivo adjunto después de enviarlo
      setAttachmentFile(null);
      if (attachmentInputRef.current) {
        attachmentInputRef.current.value = '';
      }
      
      // Verificar si la respuesta es exitosa
      if (!response.ok) {
        throw new Error(`Error HTTP: ${response.status}`);
      }
      
      // Obtener la respuesta como texto primero para depurar
      const responseText = await response.text();
    //  console.log('Respuesta texto del PHP:', responseText);
      
      let respuesta = '';
      let errorStatus = false;
      let errorMessage = '';
      
      
      // Intentar analizar la respuesta como JSON
      try {
        const data = JSON.parse(responseText);
     //   console.log('Respuesta JSON del PHP:', data);
     if (data.status === 'failed' || data.error || data.last_error) {
      errorStatus = true;
      errorMessage = data.last_error?.message || data.error?.message || 'Lo siento, ha ocurrido un error con la consulta.';
      throw new Error(errorMessage);
    }
        // Extraer el texto de la respuesta según su formato
        if (data.answer) {
          respuesta = data.answer;
        } else if (data.reply) {
          respuesta = data.reply;
        } else if (data.response) {
          respuesta = data.response;
        } else {
          // Si no hay campo específico, usar toda la respuesta como string
          respuesta = JSON.stringify(data);
        }
      } catch (jsonError) {
        // Si no es JSON válido, usar el texto directamente
        console.warn(`Respuesta de ${selectedModel} no es JSON válido, usando texto:`, responseText);
        respuesta = responseText;
      }
      
      // Verificar si tenemos una respuesta válida
      if (!respuesta || respuesta.trim() === '') {
        throw new Error('Respuesta vacía del servidor');
      }
      
      // Detener la animación de puntos suspensivos
      clearInterval(thinkingInterval);
      
      // Simulación de escritura para la respuesta
      simulateBotTyping(respuesta, model);
      
    } catch (error) {
      // Si ocurre un error, mostrar mensaje de error
      clearInterval(thinkingInterval);
      console.error('Error al enviar mensaje:', error);
      
      // Mensajes de error más específicos según el tipo de error
      let errorMessage = `Lo siento, no pude procesar tu consulta con el modelo ${selectedModel} en este momento.`;
      
      if (error instanceof Error) {
        if (error.message.includes('Respuesta vacía')) {
          errorMessage = `No se recibió una respuesta válida del servidor ${selectedModel}. Por favor, intenta reformular tu pregunta.`;
        } else if (error.message.includes('NetworkError') || error.message.includes('Failed to fetch')) {
          errorMessage = `No se pudo conectar con el servidor de ${selectedModel}. Por favor, verifica tu conexión a internet.`;
        } else if (error.message.includes('403')) {
          errorMessage = `No tienes permisos para acceder al modelo ${selectedModel}. Por favor, contacta al administrador.`;
        } else if (error.message.includes('404')) {
          errorMessage = `El servicio ${selectedModel} no está disponible en este momento.`;
        } else if (error.message.includes('500')) {
          errorMessage = `Ha ocurrido un error en el servidor de ${selectedModel}. El equipo técnico ha sido notificado.`;
        } else if (error.message.includes('timeout') || error.message.includes('Tiempo de espera')) {
          errorMessage = `La respuesta de ${selectedModel} está tardando demasiado. Por favor, intenta de nuevo o formula una pregunta más breve.`;
        }
        
        
        // Registrar el error específico para depuración
        console.error(`Tipo de error con ${selectedModel}: ${error.name}, Mensaje: ${error.message}`);
      }
      
      setMessages(prev => {
        const newMessages = [...prev];
        // Verificar si el último mensaje es el de carga
        const lastIndex = newMessages.length - 1;
        if (lastIndex >= 0 && newMessages[lastIndex].loading) {
          // Solo reemplazar el mensaje de carga, mantener todos los demás
          newMessages[lastIndex] = {
            text: errorMessage,
            sender: 'ai',
            loading: false,
            model: selectedModel
          };
        } else {
          // Si por alguna razón no hay un mensaje de carga, agregar el mensaje de error al final
          newMessages.push({
            text: errorMessage,
            sender: 'ai',
            loading: false,
            model: selectedModel
          });
        }
        return newMessages;
      });
      if (model === 'RAG') {
        setRagBotTyping(false);
      } else {
        setGptBotTyping(false);
      }    }
  };

  // Función para manejar la selección de archivos
  const handleFileSelect = (file: FileItem) => {
    if (file.type === 'file') {
      setSelectedFile(file);
      setShowPDFViewer(true);
      
      // // Informar al asistente sobre el archivo seleccionado
      // const respuesta = `Has seleccionado el archivo "${file.name}". ¿En qué puedo ayudarte con este documento?`;
      // simulateBotTyping(respuesta);
    }
  };

  // Función para volver a la vista de documentos
  const handleBackToDocuments = () => {
    setShowPDFViewer(false);
  };

  // Componente de loader para el chat
  const ChatLoader = () => (
    <div className="flex space-x-1 items-center">
      <div className="w-2 h-2 bg-[#72DBCA]  rounded-full animate-bounce" style={{ animationDelay: '0ms' }}></div>
      <div className="w-2 h-2 bg-[#F7D598]  rounded-full animate-bounce" style={{ animationDelay: '150ms' }}></div>
      <div className="w-2 h-2 bg-[#EF8A7E]  rounded-full animate-bounce" style={{ animationDelay: '300ms' }}></div>
    </div>
  );

  // NUEVO: Estilo para el cursor durante el redimensionamiento
  const resizeCursor = isResizing ? 'cursor-col-resize' : 'cursor-col-resize hover:cursor-col-resize';
  
  // Renderizar un archivo o carpeta recursivamente
  const renderFileItem = (item: FileItem, depth = 0) => {
    const paddingLeft = `${depth * 16}px`;
    
    if (item.type === 'folder') {
      const isExpanded = expandedNodes[item.id];
      const isSelected = currentSelectedFolder?.id === item.id;
      
      return (
        <div key={item.id}>
          <div 
            className={`flex items-center p-1 hover:bg-gray-100 cursor-pointer group ${isSelected ? 'bg-blue-50' : ''}`}
            style={{ paddingLeft }}
            onClick={(e) => handleFolderSelect(item, e)}
          >
            <div 
              className="flex items-center flex-grow"
            >
              <div 
                className="flex items-center cursor-pointer"
                onClick={(e) => toggleFolder(item, e)}
              >
                {isExpanded ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
              </div>
              <Folder size={16} className="text-yellow-500 ml-1 mr-2" />
              <span className="text-sm">{item.name}</span>
            </div>
            
            {isAdmin && (
              <div className="hidden group-hover:flex items-center">
                <button 
                  className="text-gray-500 hover:text-blue-500 p-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleShowAddForm(item.id, 'folder');
                  }}
                  title="Agregar carpeta"
                >
                  <Plus size={14} />
                </button>
                <button 
                  className="text-gray-500 hover:text-green-500 p-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleShowAddForm(item.id, 'file');
                  }}
                  title="Agregar archivo"
                >
                  <Upload size={14} />
                </button>
                <button 
                  className="text-gray-500 hover:text-yellow-500 p-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleShowEditForm(item);
                  }}
                  title="Editar carpeta"
                >
                  <Edit size={14} />
                </button>
                <button 
                  className="text-gray-500 hover:text-red-500 p-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteItem(item);
                  }}
                  title="Eliminar carpeta"
                >
                  <Trash2 size={14} />
                </button>
              </div>
            )}
          </div>
          
          {isExpanded && item.children && (
            <div className="folder-children">
              {item.children.map(child => renderFileItem(child, depth + 1))}
            </div>
          )}
        </div>
      );
    } else {
      // Es un archivo
      const isSelected = selectedFile?.id === item.id;
      
      return (
        <div 
          key={item.id}
          className={`flex items-center p-1 hover:bg-gray-100 cursor-pointer group ${isSelected ? 'bg-blue-50' : ''}`}
          style={{ paddingLeft: `${paddingLeft}` }}
          onClick={() => handleFileSelect(item)}
        >
          <div 
            className="flex items-center flex-grow"
          >
            <div className="w-4"></div> {/* Espacio para alinear con las carpetas */}
            <File size={16} className="text-red-500 ml-1 mr-2" />
            <span className="text-sm overflow-hidden text-ellipsis whitespace-nowrap">{item.name}</span>
            <span className="text-xs text-gray-400 ml-2">{item.modificationDate}</span>
          </div>
          
          {isAdmin && (
            <div className="hidden group-hover:flex items-center">
              <button 
                className="text-gray-500 hover:text-yellow-500 p-1"
                onClick={(e) => {
                  e.stopPropagation();
                  handleShowEditForm(item);
                }}
                title="Editar archivo"
              >
                <Edit size={14} />
              </button>
              <button 
                className="text-gray-500 hover:text-red-500 p-1"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteItem(item);
                }}
                title="Eliminar archivo"
              >
                <Trash2 size={14} />
              </button>
            </div>
          )}
        </div>
      );
    }
  };
  
  return (
    <>
    <style>{scrollbarStyles}</style>
    <div className="flex flex-col bg-white" ref={mainContainerRef}>
      {/* Contenedor principal que contiene todo el contenido */}
      <div className="flex-1 p-1 flex flex-col" style={{ height: containerHeight }}>
                {/* Contenedor flexible que se ajusta al espacio disponible */}
        <div className="flex flex-1 overflow-hidden relative">
          {/* Panel izquierdo: Explorador de archivos / Visor de PDF */}
          <div 
            className={`border rounded-md shadow-sm h-auto bg-white flex flex-col 
                     ${isExplorerMaximized ? 'w-full' : ''}`}
            style={!isExplorerMaximized ? {width: `${leftPanelWidth}%`} : {}}
          >
            <div className="flex justify-between items-center p-2 border-b bg-gray-50">
              <div className="font-medium">
                {showPDFViewer ? (
                  <div className="flex items-center">
                    <button 
                      className="mr-2 text-blue-600 hover:text-blue-800"
                      onClick={handleBackToDocuments}
                    >
                      ← Volver
                    </button>
                    <span className="truncate">
                      {selectedFile?.name || 'Documento'}
                    </span>
                  </div>
                ) : (
                  <div className="flex items-center">
                    <span>
                      {currentSelectedFolder 
                        ? `Documentos / ${currentSelectedFolder.name}` 
                        : "Documentos"}
                    </span>
                    {isAdmin && !showAddForm && (
                      <div className="ml-4 flex items-center space-x-2">
                        <button 
                          className="text-xs bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-600 flex items-center"
                          onClick={() => handleShowAddForm(currentSelectedFolder?.id || null, 'folder')}
                          title={`Crear carpeta ${currentSelectedFolder ? 'en ' + currentSelectedFolder.name : 'en raíz'}`}
                        >
                          <Plus size={12} className="mr-1" /> Carpeta
                        </button>
                        <button 
                          className="text-xs bg-green-500 text-white px-2 py-1 rounded hover:bg-green-600 flex items-center"
                          onClick={() => handleShowAddForm(null, 'file')}
                          title={`Subir archivo ${currentSelectedFolder ? 'en ' + currentSelectedFolder.name : 'en raíz'}`}
                        >
                          <Upload size={12} className="mr-1" /> Archivo
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
              {/* <button 
                className="text-gray-500 hover:text-gray-700"
                onClick={() => setIsExplorerMaximized(!isExplorerMaximized)}
              >
                {isExplorerMaximized ? <Minimize2 size={16} /> : <Maximize2 size={16} />}
              </button> */}
            </div>
            
            {/* Contenido del panel izquierdo */}
            <div className="flex-1 overflow-auto">
              {showPDFViewer ? (
                // Vista del visor de PDF
                <div className="h-full w-full">
                  {selectedFile?.url ? (
                   <iframe 
                   src={`${PDF_VIEWER_URL}?id=${selectedFile.id}`}
                   className="w-full h-full border-0"
                   title={selectedFile.name}
                   onError={(e) => {
                     console.error("Error al cargar el PDF:", e);
                   }}
                 />
                  ) : (
                    <div className="flex items-center justify-center h-full text-gray-400">
                      No se pudo cargar el documento
                    </div>
                  )}
                </div>
              ) : showAddForm ? (
                // Formulario para agregar/editar archivos o carpetas
                <div className="p-4">
                  <h3 className="text-lg font-medium mb-4">
                    {editMode ? 'Editar' : 'Agregar'} {newItemType === 'file' ? 'archivo' : 'carpeta'}
                    {!editMode && currentSelectedFolder && (
                      <span className="text-sm text-gray-500 ml-2">
                        en {currentSelectedFolder.name}
                      </span>
                    )}
                  </h3>
                  
                  <div className="space-y-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Nombre:
                      </label>
                      <input 
                        type="text"
                        className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-300"
                        value={newFileName}
                        onChange={(e) => setNewFileName(e.target.value)}
                        placeholder={`Nombre de ${newItemType === 'file' ? 'archivo' : 'carpeta'}`}
                      />
                    </div>
                    
                    {newItemType === 'file' && (
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Archivo:
                        </label>
                        <div className="flex space-x-2">
                          <button
                            type="button"
                            onClick={handleShowFileSelector}
                            className="bg-blue-500 text-white px-3 py-2 rounded hover:bg-blue-600 flex items-center"
                          >
                            <Upload size={16} className="mr-2" />
                            Seleccionar archivo
                          </button>
                          <input
                            ref={fileInputRef}
                            type="file"
                            className="hidden"
                            onChange={(e) => {
                              if (e.target.files && e.target.files[0]) {
                                setNewFileName(e.target.files[0].name);
                              }
                            }}
                          />
                        </div>
                        
                        <div className="mt-2">
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            O ingrese URL manualmente:
                          </label>
                          <input 
                            type="text"
                            className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-300"
                            value={newFileUrl}
                            onChange={(e) => setNewFileUrl(e.target.value)}
                            placeholder="URL del archivo (ej: uploads/documento.pdf)"
                          />
                        </div>
                      </div>
                    )}
                    
                    <div className="flex justify-end space-x-2 mt-6">
                      <button
                        type="button"
                        onClick={handleCancelAdd}
                        className="bg-gray-300 text-gray-800 px-4 py-2 rounded hover:bg-gray-400"
                      >
                        Cancelar
                      </button>
                      <button
                        type="button"
                        onClick={editMode ? handleUpdateItem : handleSaveNewItem}
                        className="bg-teal-500 text-white px-4 py-2 rounded hover:bg-teal-600 flex items-center"
                      >
                        <Save size={16} className="mr-2" />
                        {editMode ? 'Actualizar' : 'Guardar'}
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                // Vista de la lista de documentos
                <div className="p-1">
                  {loading ? (
                    <div className="flex items-center justify-center h-full">
                      <Loader className="animate-spin text-blue-500 mr-2" size={20} />
                      <span>Cargando archivos...</span>
                    </div>
                  ) : error ? (
                    <div className="text-red-500 p-4 flex items-center">
                      <AlertCircle size={20} className="mr-2" />
                      {error}
                    </div>
                  ) : files.length === 0 ? (
                    <div className="text-gray-500 p-4 text-center">
                      No hay documentos disponibles
                    </div>
                  ) : (
                    files.map(file => renderFileItem(file))
                  )}
                </div>
              )}
            </div>
          </div>
          
          {/* NUEVO: Divisor redimensionable - solo visible cuando no está maximizado */}
          {!isExplorerMaximized && (
  <div 
    className="w-6 absolute h-full cursor-col-resize z-10"
    style={{
      left: `calc(${leftPanelWidth}% - 3px)`,
      background: 'transparent'
    }}
    onMouseDown={handleMouseDown}
  >
   
  </div>
)}
          
          {/* Panel derecho: Chat IA */}
          {!isExplorerMaximized && (
            <div 
            className="flex flex-col bg-white border rounded-md shadow-sm ml-1"
            style={{ height: 'calc(100vh - 200px)', width: `${100 - leftPanelWidth}%` }}
            >
              {/* Panel de chat IA */}
              <div className={`flex justify-between items-center p-2 border-b ${selectedModel === 'RAG' ? 'bg-red-500' : 'bg-teal-500'} text-white`}>
              <div className="font-medium flex items-center justify-between w-full">
  <span>Chat IA</span>
  <button 
    className="text-xs px-2 py-1 rounded border flex items-center gap-1"
    onClick={() => setShowAllMessages(!showAllMessages)}
  >
    {showAllMessages ? "Mostrando todos" : `Mostrando solo ${selectedModel}`}
    <Settings size={12} />
  </button>
</div>

                {/* <button 
                  className="text-white hover:text-gray-200"
                  onClick={() => setIsChatMaximized(!isChatMaximized)}
                >
                  {isChatMaximized ? <Minimize2 size={16} /> : <Maximize2 size={16} />}
                </button> */}
              </div>

              {/* Área de mensajes con scroll interno */}
{/* Área de mensajes con scroll interno */}
<div 
  className={`flex-1 overflow-y-auto p-3 relative ${selectedModel === 'RAG' ? 'rag-scrollbar' : 'gpt-scrollbar'}`}
  ref={chatContainerRef}
  id="chatContainerRef"
>

  {/* Filtrar mensajes según la selección */}
  {messages
    .filter(msg => {
      // Siempre muestra el primer mensaje de bienvenida
      if (msg.sender === 'ai' && msg === messages[0]) return true;
      
      // Si se muestran todos los mensajes, no filtrar
      if (showAllMessages) return true;
      
      // Si es del usuario, buscar el siguiente mensaje de AI para determinar modelo
      if (msg.sender === 'user') {
        const index = messages.indexOf(msg);
        const nextAiMsg = messages.slice(index + 1).find(m => m.sender === 'ai');
        // Si hay un mensaje AI siguiente, usar su modelo, sino usar el modelo actual
        return !nextAiMsg || nextAiMsg.model === selectedModel;
      }
      
      // Para mensajes AI, verificar si coincide con el modelo seleccionado
      return msg.model === selectedModel || !msg.model;
    })
    .map((msg, idx) => (
      <div 
        key={idx} 
        className={`mb-3 ${msg.sender === 'ai' ? 'text-left' : 'text-right'}`}
      >
        <div 
          className={`inline-block p-2 rounded-lg max-w-xs md:max-w-md ${
            msg.sender === 'ai' 
              ? msg.model === 'RAG'
                  ? 'bg-red-100 text-red-800' // Red for RAG
                  : 'bg-teal-100 text-teal-800' // Teal for GPT/default
              : 'bg-blue-100 text-blue-800' // User messages stay the same
          }`}
        >
          {msg.loading ? (
            <ChatLoader />
          ) : (
            <>
              {/* Opcional: Mostrar etiqueta de modelo */}
              {msg.sender === 'ai' && msg.model && idx > 0 && (
                <div className="text-xs mb-1 opacity-70">
                  {msg.model}
                </div>
              )}
               {msg.sender === 'ai' ? (
      <div dangerouslySetInnerHTML={{ __html: msg.text }} />
    ) : (
      msg.text
    )}
            </>
          )}
        </div>
      </div>
      
    ))}
</div>
{/* Botón para desplazarse al último mensaje - posicionado en el círculo verde */}
{showScrollToBottom && (
  <div className="absolute" style={{
    // Esto lo posicionará donde está el círculo verde en tu imagen
    left: 'calc(85% + 130px)', // Ajustado para centrar en el área verde
    bottom: '150px',          // Posicionado justo sobre los controles de documentos/modelo
    zIndex: 50
  }}>
    <button 
      onClick={scrollToBottom}
      className={`${
        selectedModel === 'RAG' ? 'bg-red-500 hover:bg-red-600' : 'bg-green-500 hover:bg-green-600'
      } text-white p-1 rounded-full shadow-lg flex items-center justify-center w-9 h-9 animate-pulse transition-all duration-300`}
      aria-label="Ir al último mensaje"
    >
      <svg 
        xmlns="http://www.w3.org/2000/svg" 
        width="24" 
        height="24" 
        viewBox="0 0 24 24" 
        fill="none" 
        stroke="currentColor" 
        strokeWidth="2.5" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      >
        <polyline points="6 9 12 15 18 9"></polyline>
      </svg>
    </button>
  </div>
)}

{/* Input de chat con opciones según el modelo */}
<div className="p-2 border-t">
  {/* Si hay un archivo adjunto y NO es RAG, mostrarlo */}
  {attachmentFile && selectedModel !== 'RAG' && (
    <div className="mb-2 p-2 bg-blue-50 rounded-md flex items-center justify-between">
      <div className="flex items-center text-sm">
        <File size={16} className="mr-2 text-blue-500" />
        <span className="truncate max-w-[200px]">{attachmentFile.name}</span>
        <span className="ml-2 text-gray-500 text-xs">
          ({Math.round(attachmentFile.size / 1024)} KB)
        </span>
      </div>
      <button
        onClick={handleRemoveAttachment}
        className="text-gray-500 hover:text-red-500 ml-2"
        title="Eliminar adjunto"
      >
        <X size={16} />
      </button>
    </div>
  )}

  {/* Panel de opciones para RAG */}
{selectedModel === 'RAG' && (
  <div className="mb-3 p-2 bg-red-50 rounded-md">
    <div className="flex items-center flex-wrap gap-2">
      {/* Primera sección: Documentos */}
      <div className="flex items-center mr-3">
        <span className="text-sm font-medium text-red-800 mr-2">Documentos:</span>
       
<button
  className={`px-3 py-1 text-sm rounded-full border-2 ${
    ogucEnabled 
      ? 'bg-red-500 text-white border-red-600' 
      : 'bg-white text-red-700 border-red-300 hover:bg-red-100'
  }`}
  onClick={() => {
    // Toggle the state
    const newValue = !ogucEnabled;
    setOgucEnabled(newValue);
    
    // Debug alert
  //  alert(`OGUC Button clicked. New value: ${newValue ? 'Enabled' : 'Disabled'}`);
    
    // Optionally log current state of both buttons
    console.log('Button State:', {
      OGUC: newValue,
      LGUC: lgucEnabled
    });
  }}
>
  OGUC
</button>

<button
  className={`ml-1 px-3 py-1 text-sm rounded-full border-2 ${
    lgucEnabled 
      ? 'bg-red-500 text-white border-red-600' 
      : 'bg-white text-red-700 border-red-300 hover:bg-red-100'
  }`}
  onClick={() => {
    // Toggle the state
    const newValue = !lgucEnabled;
    setLgucEnabled(newValue);
    
    // Debug alert
   // alert(`LGUC Button clicked. New value: ${newValue ? 'Enabled' : 'Disabled'}`);
    
    // Optionally log current state of both buttons
    console.log('Button State:', {
      OGUC: ogucEnabled,
      LGUC: newValue
    });
  }}
>
  LGUC
</button>
      </div>
      
{/* Segunda sección: Modelo */}
<div className="flex items-center relative" ref={ragModelDropdownRef}>
  <span className="text-sm font-medium text-red-800 mr-2">Modelo:</span>
  <button
    className="bg-white text-red-800 px-3 py-1 text-sm rounded border-2 border-red-300 flex items-center gap-1"
    onClick={() => setShowRagModelDropdown(!showRagModelDropdown)}
  >
    {ragModel}
    <ChevronDown size={14} />
  </button>
  
  {showRagModelDropdown && (
    <div className="absolute top-full left-16 mt-1 bg-white border rounded-md shadow-lg z-10 w-48">
      <ul>
        <li 
          className={`px-3 py-2 cursor-pointer hover:bg-red-100 text-sm ${ragModel === 'o3-mini-2025-01-31' ? 'bg-red-50 text-red-800' : 'text-gray-800'}`}
          onClick={() => {
            setRagModel('o3-mini-2025-01-31');
            setShowRagModelDropdown(false);
          }}
        >
          o3-mini-2025-01-31
        </li>
        <li 
          className={`px-3 py-2 cursor-pointer hover:bg-red-100 text-sm ${ragModel === 'gpt-4o' ? 'bg-red-50 text-red-800' : 'text-gray-800'}`}
          onClick={() => {
            setRagModel('gpt-4o');
            setShowRagModelDropdown(false);
          }}
        >
          gpt-4o
        </li>
      </ul>
    </div>
  )}
</div>
    </div>
  </div>
)}

  <div className="flex">
    <div className="relative mr-2"> 
      
      <button
        className={`h-full ${selectedModel === 'RAG' ? 'bg-red-500' : 'bg-teal-500'}  text-white px-3 py-2 rounded-md flex items-center justify-center ${selectedModel === 'RAG' ? 'hover:bg-red-600' : 'hover:bg-teal-600'} `}
        onClick={() => setShowModelDropdown(!showModelDropdown)}
        title="Seleccionar modelo de IA"
      > 
        <BotIcon size={18} className="mr-2" />
        {selectedModel}
        <ChevronDown size={16} className="ml-1" />
      </button>
      
      {showModelDropdown && (
        <div className="absolute bottom-full left-0 mb-1 bg-white border rounded-md shadow-lg z-10 w-36">
          <ul>
            <li 
              className={`px-4 py-2 cursor-pointer hover:bg-gray-100 ${selectedModel === 'RAG' ? 'bg-red-100' : ''}`}
              onClick={() => {
                setSelectedModel('RAG');
                setShowModelDropdown(false);
              }}
            >
              RAG
            </li>
            <li 
              className={`px-4 py-2 cursor-pointer hover:bg-gray-100 ${selectedModel === 'ASIST' ? 'bg-teal-100' : ''}`}
              onClick={() => {
                setSelectedModel('ASIST');
                setShowModelDropdown(false);
              }}
            >
              ASIST
            </li>
          </ul>
        </div>
      )}
    </div>

    {/* Botón para adjuntar archivo - solo visible si NO es RAG */}
    {/* {selectedModel !== 'RAG' && (
      <button
        className="bg-teal-500 text-white px-2 rounded-l-md flex items-center justify-center hover:bg-teal-600"
        onClick={() => attachmentInputRef.current?.click()}
        title="Adjuntar archivo"
      >
        <Upload size={18} />
      </button>
    )} */}
    
    {/* Input oculto para seleccionar archivos */}
    <input
      type="file" name="attachment" accept=".pdf,.txt,.csv,.js,.html,.json,.docx,.xlsx,.pptx"      ref={attachmentInputRef}
      onChange={handleAttachmentSelect}
      className="hidden"
    />

<input
  type="text"
  className={`flex-1 p-2 ${selectedModel !== 'RAG' ? 'border-y' : 'rounded-l-md border'} focus:outline-none focus:ring-2  ${selectedModel === 'RAG' ? 'focus:ring-red-300' : 'focus:ring-teal-300'}`}
  placeholder="Escribe un mensaje..."
  value={currentMessage}
  onChange={(e) => setCurrentMessage(e.target.value)}
  onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
  disabled={selectedModel === 'RAG' ? ragBotTyping : gptBotTyping}
/>

<button 
  className={`${selectedModel === 'RAG' ? 'bg-red-500 hover:bg-red-600' : 'bg-teal-500 hover:bg-teal-600'} text-white px-4 rounded-r-md flex items-center justify-center ${(selectedModel === 'RAG' ? ragBotTyping : gptBotTyping) ? 'opacity-50 cursor-not-allowed' : ''}`}
  onClick={handleSendMessage}
  disabled={selectedModel === 'RAG' ? ragBotTyping : gptBotTyping}
>
      <Send size={18} />
    </button>
  </div>
  <p className="text-xs text-gray-500 text-center mt-2">
    ⚠️ Las respuestas del asistente IA deben ser supervisadas por un profesional competente.
  </p>
</div>

            </div>
          )}
        </div>
      </div>
    </div>
    </>
  );
};

export default BibliotecaNormativaChat;