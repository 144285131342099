import React, { useState, useEffect } from 'react';
import '../../css/FormularioProgramado.css'; // Crear un archivo CSS separado para estilos

const FormularioCurvas3: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>('Formulario1');
  const [form1Data, setForm1Data] = useState<any[]>([]);
  const [form2Data, setForm2Data] = useState<any[]>([]);
  const [summary1, setSummary1] = useState<{ sumaTotal: number; [key: string]: number }>({
    sumaTotal: 0,
  });
  
  const [summary2, setSummary2] = useState<{ sumaCubicacionTotal: number; [key: string]: number }>({
    sumaCubicacionTotal: 0,
  });

  const [summary3, setSummary3] = useState<{
    periodos: string[];
    viviendas: number[];
    urbanizacion: number[];
    total: number[];
  }>({
    periodos: [],
    viviendas: [],
    urbanizacion: [],
    total: [],
  });
  
  const [showModalProgramado, setShowModalProgramado] = useState<boolean>(true); // Control del modal programado

  useEffect(() => {
    if (activeTab === 'Formulario1') {
      loadExistingData1();
    } else if (activeTab === 'Formulario2') {
      loadExistingData2();
    } else if (activeTab === 'Resumen') {
      updateResumen();
    }
  }, [activeTab]);

 //const loadExistingData1 = async () => {
 //   try {
 //     const response = await fetch('http://localhost:3000/php/pages/gestion/curvas2/obtener_datos.php');
 //     const data = await response.json();
 //     console.log('Datos obtenidos de la base de datos:', data);
 //     const sanitizedData = data.map(item => {
 //       const dynamicWeeks = Object.keys(item)
 //         .filter(key => /^S\d+$/.test(key))
 //         .reduce((acc, key) => {
 //           acc[key] = item[key] || 0;
 //           return acc;
 //         }, {});
 //       return { ...item, ...dynamicWeeks };
 //     });
 //     setForm1Data(sanitizedData);
 //     updateSummary1(sanitizedData);
 //   } catch (error) {
 //     console.error('Error al cargar los datos del Formulario 1:', error);
 //   }
 // };

  const loadExistingData1 = () => {
    fetch(`${process.env.REACT_APP_API_URL}/php/pages/gestion/curvas2/obtener_datos.php`)
      .then(response => response.json())
      .then(data => {
        const transformedData = data.map((item: any) => ({


          ...item,
          dynamicColumns: JSON.parse(item.dynamicColumns) // Convertir string JSON a array
        }));

        console.log('Transformed Data:', transformedData);
        setForm1Data(transformedData);
        updateSummary1(transformedData);
      })
      .catch(error => console.error('Error al cargar los datos del Formulario 1:', error));
  };
  
  const loadExistingData2 = () => {
    fetch(`${process.env.REACT_APP_API_URL}/php/pages/gestion/curvas2/obtener_datos_2.php`)
      .then(response => response.json())
      .then(data => {
        const transformedData = data.map((item: any) => ({
          ...item,
          dynamicColumns: JSON.parse(item.dynamicColumns) // Convertir string JSON a array
        }));

        console.log('Transformed Data:', transformedData);
        setForm2Data(transformedData);
        updateSummary2(transformedData);
      })
      .catch(error => console.error('Error al cargar los datos del Formulario 1:', error));
  };

  //const updateSummary1 = (data: any[]) => {
  //  const sumaTotal = data.reduce((sum, item) => sum + (parseFloat(item.Total) || 0), 0);
  //  const semanas: { [key: string]: number } = {};
  //
  //  data.forEach(item => {
  //    Object.keys(item).forEach(key => {
  //      if (key.startsWith('S')) {
  //        if (!semanas[key]) semanas[key] = 0;
  //        semanas[key] += parseFloat(item[key]) || 0;
  //      }
  //    });
  //  });
  //
  //  // Crear un objeto dinámico con todas las semanas
  //  const summaryUpdate: { sumaTotal: number; [key: string]: number } = { sumaTotal };
  //  Object.keys(semanas).forEach(semana => {
  //    summaryUpdate[semana] = sumaTotal > 0 ? (semanas[semana] / sumaTotal) * 100 : 0; // Porcentaje
  //  });
  //
  //  setSummary1(summaryUpdate);
  //};
  
  const updateSummary1 = (data: any[]) => {
    const sumaTotal = data.reduce((sum, item) => sum + (parseFloat(item.Total) || 0), 0);
    const semanas: { [key: string]: number } = {};

    data.forEach(item => {

        if (item.dynamicColumns && Array.isArray(item.dynamicColumns)) {
            item.dynamicColumns.forEach((value: number, index: number) => {
                const key = `S${index + 1}`; // Asumiendo que las semanas se nombran como S1, S2, etc.
                if (!semanas[key]) semanas[key] = 0;
                semanas[key] += (value / sumaTotal) * 100 ; 
            });
        }
    });

    // Crear un objeto dinámico con todas las semanas
    const summaryUpdate: { sumaTotal: number; [key: string]: number } = { sumaTotal };
    Object.keys(semanas).forEach(semana => {
        summaryUpdate[semana] = semanas[semana];
    });

    setSummary1(summaryUpdate);
  };
  
  const updateSummary2 = (data: any[]) => {
    const sumaCubicacionTotal = data.reduce((sum, item) => sum + (parseFloat(item.CubicacionTotal) || 0), 0);
    const semanas: { [key: string]: number } = {};

    data.forEach(item => {
        const porcentaje = sumaCubicacionTotal > 0
            ? (parseFloat(item.CubicacionTotal) || 0) / sumaCubicacionTotal
            : 0;

        if (item.dynamicColumns && Array.isArray(item.dynamicColumns)) {
            item.dynamicColumns.forEach((value: number, index: number) => {
                const key = `S${index + 1}`; // Asumiendo que las semanas se nombran como S1, S2, etc.
                if (!semanas[key]) semanas[key] = 0;
                semanas[key] += value * porcentaje; // Producto PorcentajeRepresentativo * Semana
            });
        }
    });

    // Crear un objeto dinámico con todas las semanas
    const summaryUpdate: { sumaCubicacionTotal: number; [key: string]: number } = { sumaCubicacionTotal };
    Object.keys(semanas).forEach(semana => {
        summaryUpdate[semana] = semanas[semana];
    });

    setSummary2(summaryUpdate);
  };

  const updateResumen = async () => {
    const todasLasSemanas = new Set<string>();

    // Recopilar todas las semanas disponibles en los datos
    [...form1Data, ...form2Data].forEach(item => {
        if (item.dynamicColumns && Array.isArray(item.dynamicColumns)) {
          item.dynamicColumns.reduce((acc: {[key: string]: number}, value: number | null, index: number) => {

                todasLasSemanas.add(`S${index + 1}`);
            });
        }
    });

    const semanas = Array.from(todasLasSemanas).sort((a, b) => parseInt(a.slice(1)) - parseInt(b.slice(1)));

    // Calcular los valores para viviendas y urbanización
    const viviendas = semanas.map(semana => summary1[semana] || 0);
    const urbanizacion = semanas.map(semana => summary2[semana] || 0);

    // Calcular el total para cada semana
    //const total = semanas.map(semana => {
    //    const sumaViviendas = summary1[semana] || 0;
    //    const sumaUrbanizacion = summary2[semana] || 0;
    //    const sumaTotal = summary1.sumaTotal + summary2.sumaCubicacionTotal;
    //    return sumaTotal > 0 ? (sumaViviendas * summary1.sumaTotal + sumaUrbanizacion * summary2.sumaCubicacionTotal) / sumaTotal : 0;
    //});
    
    const total = semanas.map((semana, index) => {
      const sumaViviendas = viviendas[index];
      const sumaUrbanizacion = urbanizacion[index];
      const sumaTotal = summary1.sumaTotal + summary2.sumaCubicacionTotal;
  
      if (sumaViviendas >= 0 && sumaUrbanizacion >= 0) {
        return sumaTotal > 0
          ? (sumaViviendas * summary1.sumaTotal + sumaUrbanizacion * summary2.sumaCubicacionTotal) / sumaTotal
          : 0;
      } else if (sumaViviendas >= 0) {
        return sumaViviendas;
      } else if (sumaUrbanizacion >= 0) {
        return sumaUrbanizacion;
      } else {
        return 0;
      }
    });

    // Actualizar el resumen
    setSummary3({
        periodos: semanas,
        viviendas,
        urbanizacion,
        total,
    });

    // Preparar los datos para enviar a la base de datos
    const datosParaDB = semanas.map((semana, index) => ({
        Semana: semana,
        ID: index + 1, // Assuming IDs are sequential starting from 1
        Mes: parseInt(semana.slice(1)), // Assuming Mes is the week number
        Programado: typeof total[index] === "number" ? parseFloat(total[index].toFixed(2)) : 0,
        Viviendas: typeof viviendas[index] === "number" ? parseFloat(viviendas[index].toFixed(2)) : 0,
        Urbanizacion: typeof urbanizacion[index] === "number" ? parseFloat(urbanizacion[index].toFixed(2)) : 0,
    }));

    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/gestion/curvas2/actualizar_curva_1.php`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ datos: datosParaDB }),
        });

        const result = await response.json();
        if (!result.success) {
            console.error('Error al actualizar la curva:', result.error);
        }
    } catch (error) {
        console.error('Error al enviar los datos a la curva:', error);
    }
  };

  // CurvaSModerna.tsx
  const obtenerDatosProgramados = async () => {
    const todasLasSemanas = new Set<string>();

    // Recopilar todas las semanas disponibles en los datos
    [...form1Data, ...form2Data].forEach(item => {
        if (item.dynamicColumns && Array.isArray(item.dynamicColumns)) {
          item.dynamicColumns.forEach((_: number | null, index: number) => {

            todasLasSemanas.add(`S${index + 1}`);
            });
        }
    });

    const semanas = Array.from(todasLasSemanas).sort((a, b) => parseInt(a.slice(1)) - parseInt(b.slice(1)));

    // Calcular los valores para viviendas y urbanización
    const viviendas = semanas.map(semana => summary1[semana] || 0);
    const urbanizacion = semanas.map(semana => summary2[semana] || 0);

    // Calcular el total para cada semana
    const total = semanas.map((semana, index) => {
        const sumaViviendas = viviendas[index];
        const sumaUrbanizacion = urbanizacion[index];
        const sumaTotal = summary1.sumaTotal + summary2.sumaCubicacionTotal;

        if (sumaViviendas > 0 && sumaUrbanizacion > 0) {
            return sumaTotal > 0
                ? (sumaViviendas * summary1.sumaTotal + sumaUrbanizacion * summary2.sumaCubicacionTotal) / sumaTotal
                : 0;
        } else if (sumaViviendas > 0) {
            return sumaViviendas;
        } else if (sumaUrbanizacion > 0) {
            return sumaUrbanizacion;
        } else {
            return 0;
        }
    });

    // Preparar los datos para enviar a la base de datos
    const datosParaDB = semanas.map((semana, index) => ({
        Semana: semana,
        ID: index + 1, // Assuming IDs are sequential starting from 1
        Mes: parseInt(semana.slice(1)), // Assuming Mes is the week number
        Programado: typeof total[index] === "number" ? parseFloat(total[index].toFixed(2)) : 0,
    }));

    return datosParaDB;
  };

  //const handlePasteForm1 = async (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
  //  event.preventDefault();
  //  
  //  const clipboardData = event.clipboardData.getData('text');
  //  if (!clipboardData) {
  //    alert("No se detectaron datos en el portapapeles.");
  //    return;
  //  }
  //
  //  const rows = clipboardData.split('\n').filter(row => row.trim() !== '');
  //  
  //  // Procesar los datos
  //  const parsedData = rows.map(row => {
  //    const cols = row.split('\t');
  //    const dynamicColumns: { [key: string]: number } = {};
  //    
  //    // Procesar columnas dinámicas
  //    for (let i = 4; i < cols.length; i++) {
  //      const weekNum = i - 3;
  //      dynamicColumns[`S${weekNum}`] = parseFloat(cols[i]?.replace(',', '.') || '0');
  //    }
  //
  //    return {
  //      Item: cols[0]?.trim() || '',
  //      Partida: cols[1]?.trim() || '',
  //      Tipo: cols[2]?.trim() || '',
  //      Total: parseFloat(cols[3]?.replace(',', '.') || '0'),
  //      ...dynamicColumns
  //    };
  //  });
  //
  //  // Actualizar el estado local
  //  setForm1Data(parsedData);
  //
  //  try {
  //    const response = await fetch('http://localhost:3000/php/pages/gestion/curvas2/actualizar_semanas_1.php', {
  //      method: 'POST',
  //      headers: {
  //        'Content-Type': 'application/json',
  //      },
  //      body: JSON.stringify({ data: parsedData }),
  //    });
  //
  //    const result = await response.json();
  //    if (result.success) {
  //      alert('Base de datos actualizada correctamente');
  //      // Recargar los datos actualizados
  //      await loadExistingData1();
  //      await updateResumen();
  //    } else {
  //      console.error('Error:', result.error);
  //    }
  //  } catch (error) {
  //    console.error('Error:', error);
  //  }
  //};

  const handlePasteForm1 = async (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    const clipboardData = event.clipboardData.getData('text');
    const rows = clipboardData.split('\n').filter(row => row.trim() !== ''); // Ignorar filas vacías
    const parsedData = rows.map(row => {
      const cols = row.split('\t');
      const dynamicColumns: (number | null)[] = [];
  
      // Procesar columnas dinámicas a partir de la columna 7
      for (let i = 4; i < cols.length; i++) {
        const value = cols[i]?.trim();
        if (value === "") {
          dynamicColumns.push(null); // Si está vacío, lo convierte en null
        } else if (value.includes("%")) {
          // Si es porcentaje, elimina el "%" y convierte a número
          dynamicColumns.push(parseFloat(value.replace(',', '.').replace('%', '')));
        } else {
          // Convierte números normales con coma decimal a punto decimal
          dynamicColumns.push(parseFloat(value.replace(',', '.')));
        }
      }
  
      return {
        Item: cols[0]?.trim() || '',
        Partida: cols[1]?.trim() || '',
        Tipo: cols[2]?.trim() || '',
        Total: parseFloat(cols[3]?.replace(',', '.') || '0'),
        dynamicColumns
      };
    });
  
    setForm1Data(prev => {
      console.log("Previous form data:", prev);
      console.log("New data being added:", parsedData);
      return [...prev, ...parsedData];
    });
    //setForm2Data(prev => [...prev, ...parsedData]);
  
    try {
      console.log("Data sent to server:", JSON.stringify({ data: parsedData }, null, 2));
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/gestion/curvas2/actualizar_semanas_1.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data: parsedData }),
      });
  
      const result = await response.json();
      if (result.success) {
        //alert('Base de datos actualizada correctamente');
        // Recargar los datos actualizados
        await loadExistingData1();
        await updateResumen();
      } else {
        console.error('Error:', result.error);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };  

  const handlePasteForm2 = async (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    const clipboardData = event.clipboardData.getData('text');
    const rows = clipboardData.split('\n').filter(row => row.trim() !== ''); // Ignorar filas vacías
    const parsedData = rows.map(row => {
      const cols = row.split('\t');
      const dynamicColumns: (number | null)[] = [];
  
      // Procesar columnas dinámicas a partir de la columna 7
      for (let i = 6; i < cols.length; i++) {
        const value = cols[i]?.trim();
        if (value === "") {
          dynamicColumns.push(null); // Si está vacío, lo convierte en null
        } else if (value.includes("%")) {
          // Si es porcentaje, elimina el "%" y convierte a número
          dynamicColumns.push(parseFloat(value.replace(',', '.').replace('%', '')));
        } else {
          // Convierte números normales con coma decimal a punto decimal
          dynamicColumns.push(parseFloat(value.replace(',', '.')));
        }
      }
  
      return {
        Item: cols[2]?.trim() || '',
        CubicacionTotal: parseFloat(cols[0]?.replace(',', '.') || '0'),
        PorcentajeRepresentativo: parseFloat(cols[1]?.replace(',', '.') || '0'),
        Partida: cols[3]?.trim() || '',
        Tipo: cols[4]?.trim() || '',
        Unidad: cols[5]?.trim() || '',
        dynamicColumns
      };
    });
  
    setForm2Data(prev => {
      console.log("Previous form data:", prev);
      console.log("New data being added:", parsedData);
      return [...prev, ...parsedData];
    });
    //setForm2Data(prev => [...prev, ...parsedData]);
  
    try {
      console.log("Data sent to server:", JSON.stringify({ data: parsedData }, null, 2));
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/gestion/curvas2/actualizar_semanas_urbanizacion_1.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data: parsedData }),
      });
  
      const result = await response.json();
      if (result.success) {
        //alert('Base de datos actualizada correctamente');
        // Recargar los datos actualizados
        await loadExistingData2();
        await updateResumen();
      } else {
        console.error('Error:', result.error);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };  

  const handlePasteSingleColumn = async (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    const clipboardData = event.clipboardData.getData('text');
    const rows = clipboardData.split('\n').map(row => {
      const trimmedRow = row.trim();
      return trimmedRow !== '' ? trimmedRow : null; // Mantén las filas vacías como null
    });
    if (rows.length === 0) {
      console.error("No se han pegado datos.");
      return;
    }
    // La primera fila debe contener el número de la semana
    const weekHeader = rows[0];
    if (weekHeader === null) {
      console.error("El encabezado de la columna no es válido");
      return;
    }    const weekIndex = parseInt(weekHeader.slice(1)) - 1; // Convertir "S47" a índice 46
    if (isNaN(weekIndex)) {
      console.error("El encabezado de la columna no es válido:", weekHeader);
      return;
    }
    const parsedData = form1Data.map((row, i) => {
  
      if (!row.dynamicColumns) {
        console.error("row.dynamicColumns es null o undefined en la fila:", i);
        return row; // Evita que se intente asignar a un objeto nulo
      }
      const value = rows[i+1]!== undefined ? rows[i + 1] : null;
      if (value === "" || value === null) {
        row.dynamicColumns[weekIndex] = null;
      } else if (value.includes("%")) {
        // Si es porcentaje, elimina el "%" y convierte a número
        row.dynamicColumns[weekIndex] = parseFloat(value.replace(',', '.').replace('%', ''));
      } else {
        // Convierte números normales con coma decimal a punto decimal
        row.dynamicColumns[weekIndex] = parseFloat(value.replace(',', '.'));
      }
      return row;
    });
    // Actualizar el estado con los datos modificados
    setForm1Data(parsedData);
  
    // Preparar los datos para enviar al servidor
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/gestion/curvasreal/actualizar_semanas_1.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data: parsedData },),
      });
  
      const result = await response.json();
      if (result.success) {
        await loadExistingData1();
        await updateResumen();
      } else {
        console.error('Error:', result.error);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handlePasteSingleColumn2 = async (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    const clipboardData = event.clipboardData.getData('text');
    const rows = clipboardData.split('\n').map(row => {
      const trimmedRow = row.trim();
      return trimmedRow !== '' ? trimmedRow : null; // Mantén las filas vacías como null
    });
    if (rows.length === 0) {
      console.error("No se han pegado datos.");
      return;
    }
    // La primera fila debe contener el número de la semana
    const weekHeader = rows[0];
    if (weekHeader === null) {
      console.error("El encabezado de la columna no es válido");
      return;
    }    const weekIndex = parseInt(weekHeader.slice(1)) - 1; // Convertir "S47" a índice 46
    if (isNaN(weekIndex)) {
      console.error("El encabezado de la columna no es válido:", weekHeader);
      return;
    }
    const parsedData = form2Data.map((row, i) => {
  
      if (!row.dynamicColumns) {
        console.error("row.dynamicColumns es null o undefined en la fila:", i);
        return row; // Evita que se intente asignar a un objeto nulo
      }
      const value = rows[i+1]!== undefined ? rows[i + 1] : null;
      if (value === "" || value === null) {
        row.dynamicColumns[weekIndex] = null;
      } else if (value.includes("%")) {
        // Si es porcentaje, elimina el "%" y convierte a número
        row.dynamicColumns[weekIndex] = parseFloat(value.replace(',', '.').replace('%', ''));
      } else {
        // Convierte números normales con coma decimal a punto decimal
        row.dynamicColumns[weekIndex] = parseFloat(value.replace(',', '.'));
      }
      return row;
    });
    // Actualizar el estado con los datos modificados
    setForm2Data(parsedData);
  
    // Preparar los datos para enviar al servidor
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/gestion/curvasreal/actualizar_semanas_urbanizacion_1.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data: parsedData },),
      });
  
      const result = await response.json();
      if (result.success) {
        await loadExistingData2();
        await updateResumen();
      } else {
        console.error('Error:', result.error);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    const clipboardData = event.clipboardData.getData('text');
    const rows = clipboardData.split('\n').map(row => row.trim()).filter(row => row !== '');

    if (rows.length === 0) {
        console.error("No se han pegado datos.");
        return;
    }

    // Detectar si es formato de columna única o formato de tabla
    const firstRow = rows[0];

    if (/^S\d+$/.test(firstRow)) {
        // Si comienza con "S" seguido de un número, es una sola columna
        console.log("Detectado formato de una sola columna.");
        handlePasteSingleColumn(event);
    } else if (/^\d+\s+\D+/.test(firstRow)) {
        // Si comienza con un número seguido de texto, es formato de tabla
        console.log("Detectado formato de tabla.");
        handlePasteForm1(event);
    } else {
        console.error("Formato de datos no reconocido.");
    }
  };

  const handlePaste1 = (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    const clipboardData = event.clipboardData.getData('text');
    const rows = clipboardData.split('\n').map(row => row.trim()).filter(row => row !== '');

    if (rows.length === 0) {
        console.error("No se han pegado datos.");
        return;
    }

    // Detectar si es formato de columna única o formato de tabla
    const firstRow = rows[0];

    if (/^S\d+$/.test(firstRow)) {
        // Si comienza con "S" seguido de un número, es una sola columna
        console.log("Detectado formato de una sola columna.");
        handlePasteSingleColumn2(event);
    } else {
        // Si comienza con un número seguido de texto, es formato de tabla
        console.log("Detectado formato de tabla.");
        handlePasteForm2(event);
    } 
  };
  
  const handleSubmit = async (formId: string, url: string) => {
    try {
        console.log(`📤 Iniciando envío para: ${formId} -> ${url}`);

        const dataToSend = formId === 'viviendasForm' ? form1Data : form2Data;

        const transformedData = dataToSend.map(item => {
            console.log("🔍 Debug antes de procesar dynamicColumns:", item.dynamicColumns);

            if (!Array.isArray(item.dynamicColumns)) {
                console.error("❌ Error: dynamicColumns no es un array", item.dynamicColumns);
                throw new Error("dynamicColumns debe ser un array");
            }

            // Convertir array en objeto con claves S1, S2, S3...
            const dynamicColumnsObj = item.dynamicColumns.reduce((acc: {[key: string]: number}, value: number | null, index: number) => {

              acc[`S${index + 1}`] = value ?? 0; // Si es null, convertir a 0
                return acc;
            }, {});

            console.log("✅ dynamicColumns convertido a objeto:", dynamicColumnsObj);

            // 🔥 Convertir a string JSON manteniendo formato
            const dynamicColumnsString = JSON.stringify(dynamicColumnsObj);

            console.log("📝 dynamicColumns como string JSON:", dynamicColumnsString);

            // Crear objeto final con dynamicColumns en formato string JSON
            const { dynamicColumns, ...rest } = item;
            return { ...rest, dynamicColumns: dynamicColumnsString };
        });

        console.log('📤 Datos a enviar:', JSON.stringify({ data: transformedData }, null, 2));

        const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/gestion/curvas2/${url}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ data: transformedData }),
        });

        console.log("🔄 Esperando respuesta del servidor...");

        if (!response.ok) {
            throw new Error(`⚠️ Error en la respuesta HTTP: ${response.status}`);
        }

        const responseText = await response.text();
        console.log("📩 Respuesta cruda del servidor:", responseText);

        // 🔍 Limpiar espacios en blanco y convertir a JSON
        const cleanedResponse = JSON.parse(responseText.trim());

        console.log("✅ Respuesta limpia del servidor:", cleanedResponse);

        if (cleanedResponse.success) {
            if (formId === 'viviendasForm') {
                await loadExistingData1();
            } else if (formId === 'urbanizacionForm') {
                await loadExistingData2();
            }
            //alert('✅ Datos actualizados correctamente');
        } else {
            console.warn("⚠️ El servidor respondió con un error:", cleanedResponse.message);
            alert(cleanedResponse.message || '⚠️ Error al procesar el formulario');
        }
    } catch (error) {
        console.error('❌ Error en handleSubmit:', error);
        alert(`❌ Error al enviar el formulario: ${error}`);
    }
  };

  const handleSubmit1 = async (formId: string, url: string) => {
    try {
        console.log(`📤 Iniciando envío para: ${formId} -> ${url}`);

        const dataToSend = formId === 'viviendasForm' ? form1Data : form2Data;

        const transformedData = dataToSend.map(item => {
            console.log("🔍 Debug antes de procesar dynamicColumns:", item.dynamicColumns);

            if (!Array.isArray(item.dynamicColumns)) {
                console.error("❌ Error: dynamicColumns no es un array", item.dynamicColumns);
                throw new Error("dynamicColumns debe ser un array");
            }

            // Convertir array en objeto con claves S1, S2, S3...
            const dynamicColumnsObj = item.dynamicColumns.reduce((acc: {[key: string]: number}, value: number | null, index: number) => {

              acc[`S${index + 1}`] = value ?? 0; // Si es null, convertir a 0
                return acc;
            }, {});

            console.log("✅ dynamicColumns convertido a objeto:", dynamicColumnsObj);

            // 🔥 Convertir a string JSON manteniendo formato
            const dynamicColumnsString = JSON.stringify(dynamicColumnsObj);

            console.log("📝 dynamicColumns como string JSON:", dynamicColumnsString);

            // Crear objeto final con dynamicColumns en formato string JSON
            const { dynamicColumns, ...rest } = item;
            return { ...rest, dynamicColumns: dynamicColumnsString };
        });

        console.log('📤 Datos a enviar:', JSON.stringify({ data: transformedData }, null, 2));

        const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/gestion/curvas2/${url}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ data: transformedData }),
        });

        console.log("🔄 Esperando respuesta del servidor...");

        if (!response.ok) {
            throw new Error(`⚠️ Error en la respuesta HTTP: ${response.status}`);
        }

        const responseText = await response.text();
        console.log("📩 Respuesta cruda del servidor:", responseText);

        // 🔍 Limpiar espacios en blanco y convertir a JSON
        const cleanedResponse = JSON.parse(responseText.trim());

        console.log("✅ Respuesta limpia del servidor:", cleanedResponse);

        if (cleanedResponse.success) {
            if (formId === 'viviendasForm') {
                await loadExistingData1();
            } else if (formId === 'urbanizacionForm') {
                await loadExistingData2();
            }
            //alert('✅ Datos actualizados correctamente');
        } else {
            console.warn("⚠️ El servidor respondió con un error:", cleanedResponse.message);
            alert(cleanedResponse.message || '⚠️ Error al procesar el formulario');
        }
    } catch (error) {
        console.error('❌ Error en handleSubmit:', error);
        //alert(`❌ Error al enviar el formulario: ${error.message}`);
    }
  };
  
  const handleInputChange = async (index: number, key: string, value: string | number) => {
    if (activeTab === 'Formulario1') {
        const newData = [...form1Data];
        newData[index][key] = value;
        setForm1Data(newData);
        await handleSubmit('viviendasForm', 'formulario_1_curvas.php'); // Envía los datos al servidor
    } else if (activeTab === 'Formulario2') {
        const newData = [...form2Data];
        newData[index][key] = value;
        setForm2Data(newData);
        await handleSubmit('urbanizacionForm', 'formulario_2_curvas.php'); // Envía los datos al servidor
    }
  };

  const handleDynamicInputChange = async (index: number, key: string, value: string | number | (number | null)[]) => {
    try {
        const newData = activeTab === 'Formulario1' ? [...form1Data] : [...form2Data];

        if (key === 'dynamicColumns' && Array.isArray(value)) {
            newData[index][key] = value.map(v => 
                v === null || v === undefined || isNaN(Number(v)) ? null : Number(v) // Manejo seguro de valores
            );
        } else {
            newData[index][key] = value;
        }

        if (activeTab === 'Formulario1') {
            setForm1Data(newData);
            await handleSubmit('viviendasForm', 'formulario_1_curvas.php'); 
        } else if (activeTab === 'Formulario2') {
            setForm2Data(newData);
            await handleSubmit('urbanizacionForm', 'formulario_2_curvas.php'); 
        }
    } catch (error) {
        console.error('Error en handleDynamicInputChange:', error);
    }
  };

  const tableHeaderStyle = {
    backgroundColor: '#ff6b6b',
    color: 'white',
    padding: '12px',
    border: '1px solid #ddd'
  } as const;
  
  const tableCellStyle = {
    padding: '8px',
    border: '1px solid #ddd'
  } as const;

  const tableContainerStyle = {
    maxWidth: '100%',
    overflowX: 'auto',
    overflowY: 'hidden'
  } as const;

  return (
//    <div>
//      {showModalProgramado && (
//        <div style={{
//          position: 'fixed',
//          top: 0,
//          left: 0,
//          width: '100vw',
//          height: '100vh',
//          backgroundColor: 'rgba(0, 0, 0, 0.5)',
//          display: 'flex',
//          justifyContent: 'center',
//          alignItems: 'center',
//          zIndex: 1000,
//        }}>
//          <div style={{
//            backgroundColor: '#fff',
//            padding: '20px',
//            borderRadius: '8px',
//            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
//            width: '90%',
//            maxWidth: '1400px',
//            maxHeight: '80vh',
//            overflowY: 'auto',
//            position: 'relative',
//          }}>
//            <button
//              style={{
//                position: 'absolute',
//                top: '10px',
//                right: '10px',
//                background: 'transparent',
//                border: 'none',
//                fontSize: '20px',
//                cursor: 'pointer',
//              }}
//              onClick={() => {
//                setShowModalProgramado(false);
//                window.location.reload(); // Regresa a la página principal
//              }}
//            >
//              &times;
//            </button>
//            <div>
//              <div className={`tab-programado ${activeTab === 'Formulario1' ? 'scroll-programado' : ''}`}>
//                <td style={{ maxHeight: '60vh', overflowY: 'auto', padding: '10px' }}>Datos Programados</td>
//                <button className={activeTab === 'Formulario1' ? 'active' : ''} onClick={() => setActiveTab('Formulario1')}>
//                  Formulario Viviendas
//                </button>
//                <button className={activeTab === 'Formulario2' ? 'active' : ''} onClick={() => setActiveTab('Formulario2')}>
//                  Formulario Urbanización
//                </button>
//                <button className={activeTab === 'Resumen' ? 'active' : ''} onClick={() => setActiveTab('Resumen')}>
//                  Resumen
//                </button>
//              </div>
//
//              {activeTab === 'Formulario1' && (
//                <div className="scroll-programado" style={{ maxHeight: '60vh', overflowY: 'auto', padding: '10px' }}>
//                  <form id="viviendasForm">
//                  {form1Data && form1Data.map((row, index) => (
//                      <div key={index} className="form-group-programado">
//                        <input type="text" name={`Item-${index}`} value={row.Item || ''} onChange={e => handleInputChange(index, 'Item', e.target.value)} />
//                        <input type="text" name={`Partida-${index}`} value={row.Partida || ''} onChange={e => handleInputChange(index, 'Partida', e.target.value)} style={{width: '600px', minWidth: '280px'}} />
//                        <input type="text" name={`Tipo-${index}`} value={row.Tipo || ''} onChange={e => handleInputChange(index, 'Tipo', e.target.value)} style={{width: '200px', minWidth: '200px'}} />
//                        <input type="number" name={`Total-${index}`} value={row.Total || ''} onChange={e => handleInputChange(index, 'Total', e.target.value)} />
//                        {row.dynamicColumns && row.dynamicColumns.map((value, semanaIndex) => (
//                          <input
//                            key={semanaIndex}
//                            type="number"
//                            name={`S${semanaIndex + 1}[]`}
//                            placeholder={`S${semanaIndex + 1}`}
//                            value={value !== null ? value : ''}
//                            onChange={e => {
//                              const newValue = e.target.value === '' ? null : parseFloat(e.target.value);
//                              const newDynamicColumns = [...row.dynamicColumns];
//                              newDynamicColumns[semanaIndex] = newValue;
//                              handleDynamicInputChange(index, 'dynamicColumns', newDynamicColumns);
//                            }}
//                          />
//                        ))}
//                      </div>
//                    ))}
//                    <textarea
//                      placeholder="Pega aquí los datos copiados desde Excel"
//                      onPaste={handlePasteForm1}
//                      style={{ width: '100%', height: '100px', marginTop: '10px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px' }}
//                    />
//                  </form>
//                  <button 
//                    type="button" 
//                    onClick={async () => {await handleSubmit1('viviendasForm', 'formulario_1_curvas_1.php'); window.location.reload();
//                    }}
//                    style={{
//                      marginTop: '10px',
//                      padding: '8px 16px',
//                      backgroundColor: '#ff6b6b',
//                      color: 'white',
//                      border: 'none',
//                      borderRadius: '4px',
//                      cursor: 'pointer'
//                    }}
//                  >
//                    Enviar
//                  </button>
//                  <div className="summary" style={{marginTop: '20px'}}>
//                    <p>Suma Total: {summary1.sumaTotal.toFixed(2)}</p>
//                    {Object.entries(summary1)
//                      .filter(([key]) => key.startsWith('S'))
//                      .sort(([a], [b]) => parseInt(a.slice(1)) - parseInt(b.slice(1)))
//                      .map(([key, value]) => (
//                        <p key={key}>
//                          {key}: {value.toFixed(2)}%
//                        </p>
//                      ))}
//                  </div>
//                </div>
//              )}
//
//              {activeTab === 'Formulario2' && (
//                <div className="scroll-programado" style={{ maxHeight: '60vh', overflowY: 'auto', padding: '10px' }}>
//                  <form id="urbanizacionForm">
//                    {form2Data && form2Data.map((row, index) => (
//                      <div key={index} className="form-group-programado">
//                        <input type="text" name={`Item-${index}`} value={row.Item || ''} onChange={e => handleInputChange(index, 'Item', e.target.value)} />
//                        <input type="number" name={`CubicacionTotal-${index}`} value={row.CubicacionTotal || ''} onChange={e => handleInputChange(index, 'CubicacionTotal', e.target.value)} />
//                        <input type="number" name={`PorcentajeRepresentativo-${index}`} value={row.PorcentajeRepresentativo || ''} readOnly />
//                        <input type="text" name={`Partida-${index}`} value={row.Partida || ''} onChange={e => handleInputChange(index, 'Partida', e.target.value)} style={{width: '600px', minWidth: '280px'}} />
//                        <input type="text" name={`Tipo-${index}`} value={row.Tipo || ''} onChange={e => handleInputChange(index, 'Tipo', e.target.value)} style={{width: '200px', minWidth: '200px'}} />
//                        <input type="text" name={`Unidad-${index}`} value={row.Unidad || ''} onChange={e => handleInputChange(index, 'Unidad', e.target.value)} />
//                        {row.dynamicColumns && row.dynamicColumns.map((value, semanaIndex) => (
//                          <input 
//                            key={semanaIndex}
//                            type="number"
//                            name={`S${semanaIndex + 1}[]`}
//                            placeholder={`S${semanaIndex + 1}`}
//                            value={value !== null ? value : ''}
//                            onChange={e => {
//                              const newValue = e.target.value === '' ? null : parseFloat(e.target.value);
//                              const newDynamicColumns = [...row.dynamicColumns];
//                              newDynamicColumns[semanaIndex] = newValue;
//                              handleDynamicInputChange(index, 'dynamicColumns', newDynamicColumns);
//                            }}
//                          />
//                        ))}
//                      </div>
//                    ))}
//                    <textarea
//                      placeholder="Pega aquí los datos copiados desde Excel"
//                      onPaste={handlePasteForm2}
//                      style={{ width: '100%', height: '100px', marginTop: '10px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px' }}
//                    />
//                  </form>
//                  <button 
//                    type="button" 
//                    onClick={async () => {await handleSubmit1('urbanizacionForm', 'formulario_2_curvas_2.php'); window.location.reload();
//                    }}
//                    style={{
//                      marginTop: '10px',
//                      padding: '8px 16px',
//                      backgroundColor: '#ff6b6b',
//                      color: 'white',
//                      border: 'none',
//                      borderRadius: '4px',
//                      cursor: 'pointer'
//                    }}
//                  >
//                    Enviar
//                  </button>
//                  <div className="summary">
//                    <p>Cubicación Total: {summary2.sumaCubicacionTotal.toFixed(2)}</p>
//                    {Object.entries(summary2)
//                    .filter(([key]) => key !== 'sumaCubicacionTotal')  // Asegúrate de filtrar si no quieres mostrar 'sumaCubicacionTotal'
//                    .sort(([a], [b]) => {
//                      const aValue = parseInt(a.slice(1)); // Obtiene el valor numérico después de "S"
//                      const bValue = parseInt(b.slice(1)); // Obtiene el valor numérico después de "S"
//                      return aValue - bValue; // Ordena de menor a mayor
//                    })
//                    .map(([key, value], index) => {
//                      const semana = index + 1; // Generar S1, S2, S3, etc.
//                      return (
//                        <p key={key}>
//                          S{semana}: {value.toFixed(2)}%
//                        </p>
//                      );
//                    })}
//                  </div>
//                </div>
//              )}
//
//              {activeTab === 'Resumen' && (
//                <div className="scroll-programado" style={tableContainerStyle}>
//                  <table style={{
//                    width: '100%', // Cambiado de 100% a max-content
//                    borderCollapse: 'collapse',
//                    marginTop: '20px',
//                    textAlign: 'center'
//                  }}>
//                    <thead>
//                      <tr>
//                        <th style={tableHeaderStyle}>Semana</th>
//                        {summary3.periodos.map(periodo => (
//                          <th key={periodo} style={tableHeaderStyle}>{periodo}</th>
//                        ))}
//                      </tr>
//                    </thead>
//                    <tbody>
//                      <tr>
//                        <td style={tableCellStyle}>Viviendas</td>
//                        {summary3.viviendas.map((valor, index) => (
//                          <td key={index} style={tableCellStyle}>{valor.toFixed(2)}%</td>
//                        ))}
//                      </tr>
//                      <tr>
//                        <td style={tableCellStyle}>Urbanización</td>
//                        {summary3.urbanizacion.map((valor, index) => (
//                          <td key={index} style={tableCellStyle}>{valor.toFixed(2)}%</td>
//                        ))}
//                      </tr>
//                      <tr style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>
//                        <td style={tableCellStyle}>Total</td>
//                        {summary3.total.map((valor, index) => (
//                          <td key={index} style={tableCellStyle}>{valor.toFixed(2)}%</td>
//                        ))}
//                      </tr>
//                    </tbody>
//                  </table>
//                </div>
//              )}
//            </div>
//          </div>
//        </div>
//      )}
//    </div>
//  );
//};
//
<div>
  
        <div>
          <div className={`tab-programado ${activeTab === 'Formulario1' ? 'scroll-programado' : ''}`}>
            <td style={{ maxHeight: '60vh', overflowY: 'auto', padding: '10px', backgroundColor: '#fbdcdc', borderRadius: '0 0 8px 8px' }}>Datos Programados</td>
            <button className={activeTab === 'Formulario1' ? 'active' : ''} onClick={() => setActiveTab('Formulario1')}>
              Formulario Viviendas
            </button>
            <button className={activeTab === 'Formulario2' ? 'active' : ''} onClick={() => setActiveTab('Formulario2')}>
              Formulario Urbanización
            </button>
            <button className={activeTab === 'Resumen' ? 'active' : ''} onClick={() => setActiveTab('Resumen')}>
              Resumen
            </button>
          </div>

          {activeTab === 'Formulario1' && (
            <div className="scroll-programado" style={{ maxHeight: '60vh', overflow: 'auto', padding: '10px' }}>
              <div className="sticky-header-programado" style={{ display: 'flex', gap: '10px', padding: '10px', borderBottom: '1px solid #ddd' }}>
                <div style={{ flex: '0 0 150px' }}>Item</div>
                <div style={{ flex: '0 0 280px' }}>Partida</div>
                <div style={{ flex: '0 0 200px' }}>Tipo</div>
                <div style={{ flex: '0 0 150px' }}>Total</div>
                {summary3.periodos.map((periodo, index) => (
                  <div key={index} style={{ flex: '0 0 100px', backgroundColor: '#fbdcdc' }}>{periodo}</div>
                ))}
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '1px' }}>
                {form1Data.map((row, index) => (
                  <div key={index} className="form-group-programado" style={{ display: 'flex', gap: '10px', width: 'max-content', minWidth: '100%', boxSizing: 'border-box' }}>
                    <input type="text" name={`Item-${index}`} value={row.Item || ''} onChange={e => handleInputChange(index, 'Item', e.target.value)} style={{ flex: '0 0 150px' }} />
                    <input type="text" name={`Partida-${index}`} value={row.Partida || ''} onChange={e => handleInputChange(index, 'Partida', e.target.value)} style={{ flex: '0 0 280px' }} />
                    <input type="text" name={`Tipo-${index}`} value={row.Tipo || ''} onChange={e => handleInputChange(index, 'Tipo', e.target.value)} style={{ flex: '0 0 200px' }} />
                    <input type="number" name={`Total-${index}`} value={row.Total || ''} onChange={e => handleInputChange(index, 'Total', e.target.value)} style={{ flex: '0 0 100px' }} />
                    {row.dynamicColumns && row.dynamicColumns.map((value: number | null, semanaIndex: number) => (


                      <input
                        key={semanaIndex}
                        type="number"
                        name={`S${semanaIndex + 1}[]`}
                        placeholder={`S${semanaIndex + 1}`}
                        value={value !== null ? value : ''}
                        onChange={e => {
                          const newValue = e.target.value === '' ? null : parseFloat(e.target.value);
                          const newDynamicColumns = [...row.dynamicColumns];
                          newDynamicColumns[semanaIndex] = newValue;
                          handleDynamicInputChange(index, 'dynamicColumns', newDynamicColumns);
                        }}
                        style={{ flex: '0 0 100px' }}
                      />
                    ))}
                  </div>
                ))}
              </div>
              <textarea
                placeholder="Pega aquí los datos copiados desde Excel"
                onPaste={handlePasteForm1}
                style={{ width: '100%', height: '100px', marginTop: '10px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px' }}
              />
             <div className="summary" style={{marginTop: '20px'}}>
                    <p>Suma Total: {summary1.sumaTotal.toFixed(2)}</p>
                    {Object.entries(summary1)
                      .filter(([key]) => key.startsWith('S'))
                      .sort(([a], [b]) => parseInt(a.slice(1)) - parseInt(b.slice(1)))
                      .map(([key, value]) => (
                        <p key={key}>
                          {key}: {value.toFixed(2)}%
                        </p>
                      ))}
                  </div>
            </div>
          )}

          {activeTab === 'Formulario2' && (
            <div className="scroll-programado" style={{ maxHeight: '60vh', overflow: 'auto', padding: '10px' }}>
              <div className="sticky-header-programado" style={{ display: 'flex', gap: '10px', padding: '10px', borderBottom: '1px solid #ddd' }}>
                <div style={{ flex: '0 0 150px' }}>Item</div>
                <div style={{ flex: '0 0 150px' }}>CubicacionTotal</div>
                <div style={{ flex: '0 0 100px' }}>PorcentajeRepresentativo</div>
                <div style={{ flex: '0 0 280px' }}>Partida</div>
                <div style={{ flex: '0 0 200px' }}>Tipo</div>
                <div style={{ flex: '0 0 150px' }}>Unidad</div>
                {summary3.periodos.map((periodo, index) => (
                  <div key={index} style={{ flex: '0 0 100px', backgroundColor: '#fbdcdc' }}>{periodo}</div>
                ))}
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '1px' }}>
                {form2Data.map((row, index) => (
                  <div key={index} className="form-group-programado" style={{ display: 'flex', gap: '10px', width: 'max-content', minWidth: '100%', boxSizing: 'border-box' }}>
                    <input type="text" name={`Item-${index}`} value={row.Item || ''} onChange={e => handleInputChange(index, 'Item', e.target.value)} style={{ flex: '0 0 30px' }} />
                    <input type="number" name={`CubicacionTotal-${index}`} value={row.CubicacionTotal || ''} onChange={e => handleInputChange(index, 'CubicacionTotal', e.target.value)} style={{ flex: '0 0 100px' }} />
                    <input type="number" name={`PorcentajeRepresentativo-${index}`} value={row.PorcentajeRepresentativo || ''} readOnly style={{ flex: '0 0 100px' }} />
                    <input type="text" name={`Partida-${index}`} value={row.Partida || ''} onChange={e => handleInputChange(index, 'Partida', e.target.value)} style={{ flex: '0 0 280px' }} />
                    <input type="text" name={`Tipo-${index}`} value={row.Tipo || ''} onChange={e => handleInputChange(index, 'Tipo', e.target.value)} style={{ flex: '0 0 200px' }} />
                    <input type="text" name={`Unidad-${index}`} value={row.Unidad || ''} onChange={e => handleInputChange(index, 'Unidad', e.target.value)} style={{ flex: '0 0 100px' }} />
                    {row.dynamicColumns && row.dynamicColumns.map((value: number | null, semanaIndex: number) => (

                        <input
                        key={semanaIndex}
                        type="number"
                        name={`S${semanaIndex + 1}[]`}
                        placeholder={`S${semanaIndex + 1}`}
                        value={value !== null ? value : ''}
                        onChange={e => {
                          const newValue = e.target.value === '' ? null : parseFloat(e.target.value);
                          const newDynamicColumns = [...row.dynamicColumns];
                          newDynamicColumns[semanaIndex] = newValue;
                          handleDynamicInputChange(index, 'dynamicColumnsReal', newDynamicColumns);
                        }}
                        style={{ flex: '0 0 100px' }}
                      />
                    ))}
                  </div>
                ))}
              </div>
              <textarea
                placeholder="Pega aquí los datos copiados desde Excel"
                onPaste={handlePasteForm2}
                style={{ width: '100%', height: '100px', marginTop: '10px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px' }}
              />
              <div className="summary">
                    <p>Cubicación Total: {summary2.sumaCubicacionTotal.toFixed(2)}</p>
                    {Object.entries(summary2)
                    .filter(([key]) => key !== 'sumaCubicacionTotal')
                    .sort(([a], [b]) => {
                      const aValue = parseInt(a.slice(1));
                      const bValue = parseInt(b.slice(1));
                      return aValue - bValue;
                    })
                    .map(([key, value], index) => {
                      const semana = index + 1;
                      return (
                        <p key={key}>
                          S{semana}: {value.toFixed(2)}%
                        </p>
                      );
                    })}
                  </div>
            </div>
          )}

          {activeTab === 'Resumen' && (
                <div className="scroll-programado" style={tableContainerStyle}>
                  <table style={{
                    width: '100%', // Cambiado de 100% a max-content
                    borderCollapse: 'collapse',
                    marginTop: '20px',
                    textAlign: 'center'
                  }}>
                    <thead>
                      <tr>
                        <th style={tableHeaderStyle}>Semana</th>
                        {summary3.periodos.map(periodo => (
                          <th key={periodo} style={tableHeaderStyle}>{periodo}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={tableCellStyle}>Viviendas</td>
                        {summary3.viviendas.map((valor, index) => (
                          <td key={index} style={tableCellStyle}>{valor.toFixed(2)}%</td>
                        ))}
                      </tr>
                      <tr>
                        <td style={tableCellStyle}>Urbanización</td>
                        {summary3.urbanizacion.map((valor, index) => (
                          <td key={index} style={tableCellStyle}>{valor.toFixed(2)}%</td>
                        ))}
                      </tr>
                      <tr style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>
                        <td style={tableCellStyle}>Total Programado</td>
                        {summary3.total.map((valor, index) => (
                          <td key={index} style={tableCellStyle}>{valor.toFixed(2)}%</td>
                        ))}
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
);
}
export default FormularioCurvas3;

  //const handleSubmit = async (formId: string, url: string) => {
  //  try {
  //    //const form = document.getElementById(formId) as HTMLFormElement;
  //    //const formData = new FormData(form);
  //
  //    // Convertir dynamicColumns a un objeto para enviarlo
  //    const dataToSend = formId === 'viviendasForm' ? form1Data : form2Data;
  //    const transformedData = dataToSend.map(item => {
  //      const dynamicColumnsObj = item.dynamicColumns.reduce((acc, value, index) => {
  //        acc[`S${index + 1}`] = value;
  //        return acc;
  //      }, {});
  //      return { ...item, ...dynamicColumnsObj };
  //    });
  //
  //    const response = await fetch(`http://localhost:3000/php/pages/gestion/curvas2/${url}`, {
  //      method: 'POST',
  //      headers: { 'Content-Type': 'application/json' },
  //      body: JSON.stringify({ data: transformedData }),
  //    });
  //
  //    const data = await response.json();
  //    
  //    if (formId === 'viviendasForm') {
  //      await loadExistingData1();
  //    } else if (formId === 'urbanizacionForm') {
  //      await loadExistingData2();
  //    }
  //    
  //    if (data.success) {
  //      // Recargar los datos después de la actualización
  //      if (formId === 'viviendasForm') {
  //        await loadExistingData1();
  //      } else if (formId === 'urbanizacionForm') {
  //        await loadExistingData2();
  //      }
  //      alert('Datos actualizados correctamente');
  //    } else {
  //      alert(data.message || 'Error al procesar el formulario');
  //    }
  //  } catch (error) {
  //    console.error('Error:', error);
  //  }
  //};
  
  //const handleSubmit = async (formId: string, url: string) => {
  //  try {
  //    const form = document.getElementById(formId) as HTMLFormElement;
  //    const formData = new FormData(form);
  //
  //    // Añadir flag para indicar que queremos sobreescribir
  //    //formData.append('overwrite', 'true');
  //    // Debug: Ver los datos que se están enviando
  //    formData.forEach((value, key) => {
  //      console.log(`${key}: ${value}`);
  //    });
  //
  //    const response = await fetch(`http://localhost:3000/php/pages/gestion/curvas2/${url}`, {
  //      method: 'POST',
  //      body: formData
  //    });
  //
  //    const data = await response.json();
  //    
  //    if (data.success) {
  //      // Recargar los datos después de la actualización
  //      if (formId === 'viviendasForm') {
  //        await loadExistingData1();
  //      } else if (formId === 'urbanizacionForm') {
  //        await loadExistingData2();
  //      }
  //      alert('Datos actualizados correctamente');
  //    } else {
  //      alert(data.message || 'Error al procesar el formulario');
  //    }
  //  } catch (error) {
  //    console.error('Error:', error);
  //    //alert('Error al enviar el formulario');
  //  }
  //};
  
  //const handleDynamicInputChange = async (index: number, key: string, value: string | number | (number | null)[]) => {
  //  if (activeTab === 'Formulario1') {
  //      const newData = [...form1Data];
  //      if (key === 'dynamicColumns' && Array.isArray(value)) {
  //          newData[index][key] = value.map(v => {
  //              if (typeof v === 'string' && v === '') {
  //                  return null;
  //              }
  //              return parseFloat(String(v));
  //          });
  //      } else {
  //          newData[index][key] = value;
  //      }
  //      setForm1Data(newData);
  //      await handleSubmit('viviendasForm', 'formulario_1_curvas.php'); // Envía los datos al servidor
  //  } else if (activeTab === 'Formulario2') {
  //      const newData = [...form2Data];
  //      if (key === 'dynamicColumns' && Array.isArray(value)) {
  //          newData[index][key] = value.map(v => {
  //              if (typeof v === 'string' && v === '') {
  //                  return null;
  //              }
  //              return parseFloat(String(v));
  //          });
  //      } else {
  //          newData[index][key] = value;
  //      }
  //      setForm2Data(newData);
  //      await handleSubmit('urbanizacionForm', 'formulario_2_curvas.php'); // Envía los datos al servidor
  //  }
  //};

  //const handleInputChange = (index: number, key: string, value: string | number) => {
  //  if (activeTab === 'Formulario1') {
  //    const newData = [...form1Data];
  //    newData[index][key] = value;
  //    setForm1Data(newData);
  //  } else if (activeTab === 'Formulario2') {
  //    const newData = [...form2Data];
  //    newData[index][key] = value;
  //    setForm2Data(newData);
  //  }
  //};
//
  //const handleDynamicInputChange = (index: number, key: string, value: string | number | (number | null)[]) => {
  //  if (activeTab === 'Formulario1') {
  //      const newData = [...form1Data];
  //      if (key === 'dynamicColumns' && Array.isArray(value)) {
  //          newData[index][key] = value.map(v => {
  //              if (typeof v === 'string' && v === '') {
  //                  return null;
  //              }
  //              return parseFloat(String(v));
  //          });
  //      } else {
  //          newData[index][key] = value;
  //      }
  //      setForm1Data(newData);
  //  } else if (activeTab === 'Formulario2') {
  //      const newData = [...form2Data];
  //      if (key === 'dynamicColumns' && Array.isArray(value)) {
  //          newData[index][key] = value.map(v => {
  //              if (typeof v === 'string' && v === '') {
  //                  return null;
  //              }
  //              return parseFloat(String(v));
  //          });
  //      } else {
  //          newData[index][key] = value;
  //      }
  //      setForm2Data(newData);
  //  }
  //};

  //const handleDynamicInputChange = (index: number, key: string, value: string | number | (number | null)[]) => {
  //  console.log('Handling input change:', { index, key, value });
  //  if (activeTab === 'Formulario1') {
  //    const newData = [...form1Data];
  //    newData[index][key] = value;
  //    setForm1Data(newData);
  //  } else if (activeTab === 'Formulario2') {
  //    const newData = [...form2Data];
  //    newData[index][key] = value;
  //    setForm2Data(newData);
  //  }
  //};
  //const updateResumen = async () => {
  //  const todasLasSemanas = new Set<string>();
  //  [...form1Data, ...form2Data].forEach(item => {
  //    Object.keys(item).forEach(key => {
  //      if (key.startsWith('S')) todasLasSemanas.add(key);
  //    });
  //  });
//
  //  const semanas = Array.from(todasLasSemanas).sort((a, b) => parseInt(a.slice(1)) - parseInt(b.slice(1)));
//
  //  const viviendas = semanas.map(semana => summary1[semana] || 0);
  //  const urbanizacion = semanas.map(semana => summary2[semana] || 0);
  //  const total = semanas.map(semana => {
  //    const sumaViviendas = summary1[semana] || 0;
  //    const sumaUrbanizacion = summary2[semana] || 0;
  //    const sumaTotal = summary1.sumaTotal + summary2.sumaCubicacionTotal;
  //    return sumaTotal > 0 ? (sumaViviendas * summary1.sumaTotal + sumaUrbanizacion * summary2.sumaCubicacionTotal) / sumaTotal : 0;
  //  });
//
  //  setSummary3({
  //    periodos: semanas,
  //    viviendas,
  //    urbanizacion,
  //    total,
  //  });
//
  //  const datosParaDB = semanas.map((semana, index) => ({
  //    Semana: semana,
  //    Programado: typeof total[index] === "number" ? parseFloat(total[index].toFixed(2)) : 0,
  //  }));
//
  //  try {
  //    const response = await fetch('http://localhost:3000/php/pages/gestion/curvas2/actualizar_curva.php', {
  //      method: 'POST',
  //      headers: { 'Content-Type': 'application/json' },
  //      body: JSON.stringify({ datos: datosParaDB }),
  //    });
//
  //    const result = await response.json();
  //    if (!result.success) {
  //      console.error('Error al actualizar la curva:', result.error);
  //    }
  //  } catch (error) {
  //    console.error('Error al enviar los datos a la curva:', error);
  //  }
  //};
  
  //const updateResumen = async () => {
  //    const viviendas = [
  //        summary1.sumaS1,
  //        summary1.sumaS2,
  //        summary1.sumaS3,
  //        summary1.sumaS4,
  //        summary1.sumaSF
  //    ];
  //    
  //    const urbanizacion = [
  //        summary2.sumaS1,
  //        summary2.sumaS2,
  //        summary2.sumaS3,
  //        summary2.sumaS4,
  //        summary2.sumaSF
  //    ];
  //    
  //    const total = [
  //        (summary1.sumaS1 * summary1.sumaTotal + summary2.sumaS1 * summary2.sumaCubicacionTotal)/(summary1.sumaTotal + summary2.sumaCubicacionTotal),
  //        (summary1.sumaS2 * summary1.sumaTotal + summary2.sumaS2 * summary2.sumaCubicacionTotal)/(summary1.sumaTotal + summary2.sumaCubicacionTotal),
  //        (summary1.sumaS3 * summary1.sumaTotal + summary2.sumaS3 * summary2.sumaCubicacionTotal)/(summary1.sumaTotal + summary2.sumaCubicacionTotal),
  //        (summary1.sumaS4 * summary1.sumaTotal + summary2.sumaS4 * summary2.sumaCubicacionTotal)/(summary1.sumaTotal + summary2.sumaCubicacionTotal),
  //        (summary1.sumaSF * summary1.sumaTotal + summary2.sumaSF * summary2.sumaCubicacionTotal)/(summary1.sumaTotal + summary2.sumaCubicacionTotal),
  //    ];
  //    
  //    setSummary3({ periodos: ['S1', 'S2', 'S3', 'S4', 'SF'], viviendas, urbanizacion, total });
  //  
  //    // Enviar los valores directos (no acumulados) a la base de datos
  //    const datosParaDB = total.map((valor, index) => {
  //        return {
  //            ID: index + 1,
  //            Mes: index + 1,
  //            Programado: parseFloat(valor.toFixed(2)),
  //            AvanceReal: null,
  //            Proyección: null
  //        };
  //    });
//
  //    try {
  //        const response = await fetch('http://localhost:3000/php/pages/gestion/curvas/actualizar_curva.php', {
  //            method: 'POST',
  //            headers: {
  //                'Content-Type': 'application/json'
  //            },
  //            body: JSON.stringify({ datos: datosParaDB })
  //        });
//
  //        const result = await response.json();
  //        if (!result.success) {
  //            console.error('Error al actualizar la curva:', result.error);
  //        }
  //    } catch (error) {
  //        console.error('Error al enviar los datos a la curva:', error);
  //    }
  //};