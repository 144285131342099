import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SimagiNewsComponent from './pages/SimagiNewsComponent';
import Login from './pages/Login';
import SimagiInteractivePreview from './pages/SimagiInterface';
import { NavigationProvider } from './contexts/NavigationContext';
import ResetPassword from './pages/ResetPassword';
import RequestPassword from './pages/RequestPasswordReset';
import Register from './pages/Register';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import { Toaster } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';



// Componente wrapper para manejar la navegación
const NavigationWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = (to: string) => {
    navigate(to);
  };

  return (
    <NavigationProvider navigate={handleNavigation} currentPath={location.pathname}>
      {children}
    </NavigationProvider>
  );
};

const App = () => {
  return (
    <Router>
     {/* <NavigationWrapper>*/}
        <Toaster
          position="top-right"
          reverseOrder={false}
          toastOptions={{
            style: {
              zIndex: 99999
            }
          }}
        />

        <Routes>
          
          <Route path="/" element={<SimagiNewsComponent />} />
          <Route path="/mvp" element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          } />
          <Route path="/register" element={<Register />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/ui" element={
            <PrivateRoute>
              <SimagiInteractivePreview /> 
            </PrivateRoute>
          } />
        </Routes>
    {/*  </NavigationWrapper>*/}
    </Router>
  );
};

export default App;