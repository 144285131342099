import React, { useState, useRef, useEffect } from 'react';
import { ChevronDown, ChevronUp, Edit3, Save, PlusCircle, Trash2, ChevronRight, Palette } from 'lucide-react';
import { response } from 'express';
import { motion, AnimatePresence } from 'framer-motion';
import toast from 'react-hot-toast';



// Interface para los datos de cada item con soporte para color
interface ItemData {
  Nivel: string;
  Nomenclatura: string;
  Color?: string; // Campo para el color
}

interface TablaDesplegableProps {
  id: string;
  titulo: string;
  datos: ItemData[];
  nivel: number;
  onDragStart: (id: string) => void;
  onDragEnter: (id: string) => void;
  onDragEnd: () => void;
  onEliminarTabla: (id: string) => void;
}

// Lista de colores predeterminados estéticos
const COLORES_PREDETERMINADOS = [
  "#4E79A7", // Azul
  "#F28E2B", // Naranja
  "#E15759", // Rojo
  "#76B7B2", // Verde agua
  "#59A14F", // Verde
  "#EDC948", // Amarillo
  "#B07AA1", // Púrpura
  "#FF9DA7", // Rosa
  "#9C755F", // Marrón
  "#BAB0AC", // Gris
  "#5A6ACF", // Azul índigo
  "#D37295", // Rosa oscuro
  "#499894", // Verde azulado
  "#B1B156", // Verde oliva
  "#F1CE63", // Amarillo dorado
  "#D4A6C8", // Lila
  "#9D7660", // Marrón claro
  "#D7B5A6", // Canela
  "#566CA5", // Azul grisáceo
  "#3AA96A"  // Verde esmeralda
];




// Interface para los datos de cada item con soporte para color
interface ItemData {
  Nivel: string;
  Nomenclatura: string;
  Color?: string; // Campo para el color
}

interface TablaDesplegableProps {
  id: string;
  titulo: string;
  datos: ItemData[];
  nivel: number;
  onDragStart: (id: string) => void;
  onDragEnter: (id: string) => void;
  onDragEnd: () => void;
  onEliminarTabla: (id: string) => void;
}


// Función para asignar color predeterminado basado en la posición
const getDefaultColor = (index: number) => {
  return COLORES_PREDETERMINADOS[index % COLORES_PREDETERMINADOS.length];
};

const TablaDesplegable: React.FC<TablaDesplegableProps> = ({ id, titulo, datos, nivel, onDragStart, onDragEnter, onDragEnd, onEliminarTabla }) => {
  const [expandido, setExpandido] = useState(true);
  const [modoEdicion, setModoEdicion] = useState(false);
  const [nuevoTitulo, setNuevoTitulo] = useState(titulo);
  const [datosEditados, setDatosEditados] = useState<ItemData[]>(
    // Asignar colores predeterminados a los datos que no tengan color
    datos.map((item, index) => ({
      ...item,
      Color: item.Color || getDefaultColor(index)
    }))
  );
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [nuevoConcepto, setNuevoConcepto] = useState("");
  const [nuevaNomenclatura, setNuevaNomenclatura] = useState("");
  const [nuevoColor, setNuevoColor] = useState(COLORES_PREDETERMINADOS[0]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editConcept, setEditConcept] = useState("");
  const [editNomenclature, setEditNomenclature] = useState("");
  const [editColor, setEditColor] = useState("");
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const [mensaje, setMensaje] = useState('');
  const colorPickerRef = useRef<HTMLDivElement>(null);

  // Cerrar el color picker cuando se hace clic fuera
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (colorPickerRef.current && !colorPickerRef.current.contains(event.target as Node)) {
        setIsColorPickerOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleGuardarTitulo = () => {
    // Lógica para guardar el título actualizado
  };

  const handleInputChange = (setter: React.Dispatch<React.SetStateAction<string>>) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setter(e.target.value);
    setMensaje(''); // Resetear el mensaje de error al cambiar el texto
  };

  const handleAgregarNuevo = async () => {
    if (!nuevoConcepto || !nuevaNomenclatura) {
      setMensaje('Error: Debe completar ambos campos');
      return;
    }

    // Comprueba si ya existe un concepto con el mismo nombre
    if (datosEditados.some(dato => dato.Nivel === nuevoConcepto || dato.Nomenclatura === nuevaNomenclatura)) {
      setMensaje('Error: Ya existe un concepto o nomenclatura igual');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/controldocument/glosario/add_concept.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          nivel_id: id,
          concept: nuevoConcepto,
          nomenclature: nuevaNomenclatura,
          color: nuevoColor // Enviar el color al backend
        }),
      });

      const data = await response.json();
      
      if (data.success) {
        const nuevosDatos = [...datosEditados, { 
          Nivel: nuevoConcepto, 
          Nomenclatura: nuevaNomenclatura,
          Color: nuevoColor
        }];
        
        setDatosEditados(nuevosDatos);
        setNuevoConcepto('');
        setNuevaNomenclatura('');
        setNuevoColor(COLORES_PREDETERMINADOS[Math.floor(Math.random() * COLORES_PREDETERMINADOS.length)]); // Asignar otro color al azar para el próximo
        setMensaje('Concepto agregado correctamente');
        
        // Limpiar el mensaje después de 3 segundos
        setTimeout(() => setMensaje(''), 3000);
      } else {
        setMensaje(`Error: ${data.message}`);
      }
    } catch (error) {
      console.error('Error al agregar concepto:', error);
      setMensaje('Error: No se pudo conectar con el servidor');
    }
  };

  const handleEliminar = async (index: number) => {
    if (!window.confirm('¿Está seguro que desea eliminar este concepto?')) {
      return;
    }

    const conceptoAEliminar = datosEditados[index];
    
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/controldocument/glosario/delete_concept.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          nivel_id: id,
          concept: conceptoAEliminar.Nivel,
          nomenclature: conceptoAEliminar.Nomenclatura
        }),
      });

      const data = await response.json();
      
      if (data.success) {
        const nuevosDatos = datosEditados.filter((_, i) => i !== index);
        setDatosEditados(nuevosDatos);
        setMensaje('Concepto eliminado correctamente');
        
        // Limpiar el mensaje después de 3 segundos
        setTimeout(() => setMensaje(''), 3000);
      } else {
        setMensaje(`Error: ${data.message}`);
      }
    } catch (error) {
      console.error('Error al eliminar concepto:', error);
      setMensaje('Error: No se pudo conectar con el servidor');
    }
  };

  const handleEditClick = (index: number) => {
    setEditIndex(index);
    const conceptoAEditar = datosEditados[index];
    setEditConcept(conceptoAEditar.Nivel);
    setEditNomenclature(conceptoAEditar.Nomenclatura);
    setEditColor(conceptoAEditar.Color || getDefaultColor(index));
    setIsEditModalOpen(true);
  };

  const handleSaveEdit = async () => {
    if (!editConcept || !editNomenclature) {
      setMensaje('Error: Debe completar ambos campos');
      return;
    }

    if (editIndex === null) {
      setMensaje('Error: No se ha seleccionado ningún concepto para editar');
      return;
    }

    // Comprobar si ya existe otro concepto con el mismo nombre (excepto el actual)
    const conceptoExistente = datosEditados.findIndex((dato, index) => 
      dato.Nivel === editConcept && index !== editIndex
    );
    
    if (conceptoExistente !== -1) {
      setMensaje('Error: Ya existe otro concepto con ese nombre');
      return;
    }

    const conceptoOriginal = datosEditados[editIndex];

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/controldocument/glosario/update_concept.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          nivel_id: id,
          old_concept: conceptoOriginal.Nivel,
          old_nomenclature: conceptoOriginal.Nomenclatura,
          new_concept: editConcept,
          new_nomenclature: editNomenclature,
          color: editColor // Enviar el color actualizado
        }),
      });

      const data = await response.json();
      
      if (data.success) {
        const nuevosDatos = [...datosEditados];
        nuevosDatos[editIndex] = { 
          Nivel: editConcept, 
          Nomenclatura: editNomenclature,
          Color: editColor 
        };
        
        setDatosEditados(nuevosDatos);
        setIsEditModalOpen(false);
        setEditIndex(null);
        toast.success('Concepto actualizado correctamente');
        
        // Limpiar el mensaje después de 3 segundos
        setTimeout(() => setMensaje(''), 3000);
      } else {
        toast.error(`Error: ${data.message}`);
      }
    } catch (error) {
      console.error('Error al actualizar concepto:', error);
      toast.error('Error: No se pudo conectar con el servidor');
    }
  };

  // Componente ColorPicker mejorado con input de color personalizado
  const ColorPicker = ({ currentColor, onSelectColor, onClose }: { 
    currentColor: string, 
    onSelectColor: (color: string) => void,
    onClose: () => void 
  }) => {
    const [customColor, setCustomColor] = useState(currentColor);
    
    const handleCustomColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newColor = e.target.value;
      setCustomColor(newColor);
      onSelectColor(newColor);
    };

    return (
      <div 
        ref={colorPickerRef}
        className="absolute z-50 bg-white border border-gray-300 p-3 rounded shadow-lg mt-1"
        style={{ minWidth: '230px' }}
      >
        <div className="mb-3">
          <label className="block text-sm font-medium text-gray-700 mb-1">Color personalizado</label>
          <div className="flex items-center space-x-2">
            <input
              type="color"
              value={customColor}
              onChange={handleCustomColorChange}
              className="h-8 w-8 cursor-pointer border-0"
            />
            <input
              type="text"
              value={customColor}
              onChange={(e) => {
                setCustomColor(e.target.value);
                if (/^#([0-9A-F]{3}){1,2}$/i.test(e.target.value)) {
                  onSelectColor(e.target.value);
                }
              }}
              className="h-8 px-2 text-sm border border-gray-300 rounded w-20"
              placeholder="#RRGGBB"
            />
            <button 
              onClick={() => onSelectColor(customColor)}
              className="px-2 py-1 bg-blue-500 text-white rounded text-xs hover:bg-blue-600"
            >
              Aplicar
            </button>
          </div>
        </div>
        
        <div className="mb-2">
          <label className="block text-sm font-medium text-gray-700 mb-1">Colores predefinidos</label>
          <div className="grid grid-cols-5 gap-1">
            {COLORES_PREDETERMINADOS.map((color, index) => (
              <div 
                key={index}
                className={`w-6 h-6 rounded-full cursor-pointer hover:scale-110 transition-transform ${currentColor === color ? 'ring-2 ring-offset-1 ring-gray-500' : ''}`}
                style={{ backgroundColor: color }}
                onClick={() => {
                  setCustomColor(color);
                  onSelectColor(color);
                }}
              />
            ))}
          </div>
        </div>
        
        <div className="mt-3 flex justify-end">
          <button 
            onClick={onClose}
            className="text-xs text-gray-500 hover:text-gray-700 px-2 py-1 border border-gray-300 rounded"
          >
            Cerrar
          </button>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`${
        !expandido ? '' : 'border'
      } rounded-lg flex-shrink-0 overflow-hidden min-w-[400px] w-[400px]`}
      onDragEnter={() => onDragEnter(id)}
      onDragOver={(e) => e.preventDefault()}
      onDrop={() => onDragEnd()}
    >
      <div
        onClick={() => setExpandido(!expandido)}
        className="w-full bg-cyan-400 text-white p-2 flex justify-between items-center hover:bg-cyan-500 transition-colors cursor-pointer"
        draggable
        onDragStart={() => onDragStart(id)}
      >
         {!expandido ? <ChevronRight size={20} /> : <ChevronDown size={20} />}
        <h2 className="text-lg font-semibold truncate flex-grow">{titulo}</h2>
        <button 
          onClick={(e) => { 
            e.stopPropagation(); 
            if (window.confirm(`¿Está seguro que desea eliminar el nivel "${titulo}"?`)) {
              onEliminarTabla(id);
            }
          }}
          className="text-red-500 hover:text-red-700"
        >
          <Trash2 size={18} />
        </button>
      </div>
      <AnimatePresence>
        {expandido && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            className="p-2 bg-white"
          >
          <table className="w-full border-collapse text-sm">
  <thead>
    <tr className="bg-gray-200">
      <th className="border p-1 text-left">{`Nivel${nivel + 1}`}</th>
      {datos.length > 0 && Object.keys(datos[0])
        .filter(key => key !== `Nivel`)
        .filter(key => key !== 'Color') // Excluir la columna Color en todos los niveles
        .map((key, index) => (
          <th key={index} className="border p-1 text-left">
            {key}
          </th>
        ))}
      
      {/* Mostrar la columna de color solo si nivel === 2 */}
      {nivel === 1 && <th className="border p-1 text-left">Color</th>}
      
      <th className="border p-1 text-left">Acciones</th>
    </tr>
  </thead>
  <tbody>
    {datosEditados.map((fila, index) => (
      <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}>
        <td className="border p-1 text-left">{fila.Nivel}</td>
        <td className="border p-1 text-left">{fila.Nomenclatura}</td>

        {/* Mostrar el color solo en nivel 2 */}
        {nivel === 1 && ( 
          <td className="border p-1 text-center">
            <div  
              className="w-6 h-6 rounded-full mx-auto" 
              style={{ backgroundColor: fila.Color || getDefaultColor(index) }}
              title={fila.Color || getDefaultColor(index)}
            />
          </td> 
        )}

        <td className="border p-1 text-center flex justify-center space-x-2">
          <button onClick={() => handleEditClick(index)} className="mr-1">
            <Edit3 size={16} className="text-blue-500" />
          </button>
          <button onClick={() => handleEliminar(index)}>
            <Trash2 size={16} className="text-red-500" />
          </button>
        </td>
      </tr>
    ))}
  </tbody>
</table>


            <div className="p-2">
              <h3 className="text-sm font-semibold mb-1">Agregar concepto</h3>
              <div className="flex space-x-1">
                <input
                  type="text"
                  placeholder="Nuevo concepto"
                  value={nuevoConcepto}
                  onChange={(e) => setNuevoConcepto(e.target.value)}
                  className=" border border-gray-300 rounded flex-grow text-sm"
                />
                <input
                  type="text"
                  placeholder="Nomenclatura"
                  value={nuevaNomenclatura}
                  onChange={(e) => setNuevaNomenclatura(e.target.value)}
                  className="border border-gray-300 rounded flex-grow text-sm"
                />
                <div className="relative">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setIsColorPickerOpen(!isColorPickerOpen);
                    }}
                    className="p-1 border border-gray-300 rounded flex items-center justify-center"
                    style={{ backgroundColor: nuevoColor }}
                    title="Seleccionar color"
                  >
                    <Palette size={16} className={nuevoColor === '#FFFFFF' ? 'text-black' : 'text-white'} />
                  </button>
                  {isColorPickerOpen && (
                    <ColorPicker 
                      currentColor={nuevoColor}
                      onSelectColor={setNuevoColor}
                      onClose={() => setIsColorPickerOpen(false)}
                    />
                  )}
                </div>
                <button
                  onClick={handleAgregarNuevo}
                  className="p-1 bg-green-500 text-white rounded flex items-center justify-center"
                  title="Agregar concepto"
                >
                  <PlusCircle size={16} />
                </button>
              </div>
              {mensaje && (
                <div className={`mt-2 p-1 rounded text-sm ${mensaje.startsWith('Error') ? 'bg-red-200 text-red-800' : 'bg-green-200 text-green-800'}`}>
                  {mensaje}
                </div>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Edit Modal */}
      {isEditModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <motion.div 
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            className="bg-white p-4 rounded-lg shadow-lg w-96"
          >
            <h3 className="text-lg font-semibold mb-3">Editar Concepto</h3>
            <div className="space-y-3">
              <div>
                <label className="block text-sm font-medium text-gray-700">Concepto</label>
                <input
                  type="text"
                  value={editConcept}
                  onChange={(e) => setEditConcept(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded mt-1"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Nomenclatura</label>
                <input
                  type="text"
                  value={editNomenclature}
                  onChange={(e) => setEditNomenclature(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded mt-1"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Color</label>
                <div className="flex mt-1 items-center space-x-2">
                  <div 
                    className="w-8 h-8 rounded-full border border-gray-300"
                    style={{ backgroundColor: editColor }}
                  />
                  <input
                    type="color"
                    value={editColor}
                    onChange={(e) => setEditColor(e.target.value)}
                    className="h-8 w-8 cursor-pointer border-0"
                  />
                  <input
                    type="text"
                    value={editColor}
                    onChange={(e) => {
                      setEditColor(e.target.value);
                    }}
                    className="h-8 px-2 text-sm border border-gray-300 rounded flex-grow"
                    placeholder="#RRGGBB"
                  />
                  <button
                    onClick={() => setIsColorPickerOpen(true)}
                    className="px-2 py-1 bg-gray-100 text-gray-700 rounded hover:bg-gray-200 flex items-center"
                  >
                    <Palette size={14} className="mr-1" />
                    Más
                  </button>
                </div>
                {isColorPickerOpen && (
                  <div className="relative mt-2">
                    <ColorPicker 
                      currentColor={editColor}
                      onSelectColor={setEditColor}
                      onClose={() => setIsColorPickerOpen(false)}
                    />
                  </div>
                )}
              </div>
              
              {/* Error message if exists */}
              {mensaje && mensaje.startsWith('Error') && (
                <div className="p-2 bg-red-100 text-red-800 rounded text-sm">
                  {mensaje}
                </div>
              )}
              
              <div className="flex justify-end space-x-2 pt-2">
                <button
                  onClick={() => {
                    setIsEditModalOpen(false);
                    setMensaje('');
                  }}
                  className="px-3 py-1 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
                >
                  Cancelar
                </button>
                <button
                  onClick={handleSaveEdit}
                  className="px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 flex items-center"
                >
                  <Save size={16} className="mr-1" />
                  Guardar
                </button>
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </div>
  );
};



/*
const TablasNomenclaturaProyecto: React.FC = () => {
  const [tabs, setTabs] = useState([
    { id: 'subcarpetas', titulo: 'Subcarpetas', datos: [
      { Nivel: 'Terreno', Nomenclatura: 'T' },
      { Nivel: 'Arquitectura', Nomenclatura: 'A' },
      { Nivel: 'Estructuras', Nomenclatura: 'E' },
      { Nivel: 'Urbanización', Nomenclatura: 'U' },
      { Nivel: 'Ingreso DOM', Nomenclatura: 'D' },
      { Nivel: 'Ingreso SERVIU', Nomenclatura: 'S' },
      { Nivel: 'Licitación', Nomenclatura: 'L' },
      { Nivel: 'Contrato', Nomenclatura: 'C' },
      { Nivel: 'Obra', Nomenclatura: 'O' },
      { Nivel: 'Normativa', Nomenclatura: 'N' },
      { Nivel: 'Finanzas', Nomenclatura: 'F' },
      { Nivel: 'Control de Proyecto', Nomenclatura: 'P' },
    ]},
    { id: 'especialidades', titulo: 'Especialidades', datos: [
      { Nivel: 'Electricidad', Nomenclatura: 'E' },
      { Nivel: 'Fontanería', Nomenclatura: 'F' },
      { Nivel: 'Climatización', Nomenclatura: 'C' },
      { Nivel: 'Seguridad', Nomenclatura: 'S' },
    ]},
    { id: 'tipoDocumento', titulo: 'Tipo de documento', datos: [
      { Nivel: 'Planos', Nomenclatura: 'P' },
      { Nivel: 'Memoria Descriptiva', Nomenclatura: 'MD' },
      { Nivel: 'Especificaciones Técnicas', Nomenclatura: 'ET' },
      { Nivel: 'Presupuestos', Nomenclatura: 'PR' },
      // ... más datos
    ]}
  ]);

  const [draggedId, setDraggedId] = useState<string | null>(null);

  const handleDragStart = (id: string) => {
    setDraggedId(id);
  };

  const handleDragEnter = (id: string) => {
    if (draggedId && draggedId !== id) {
      const draggedIndex = tabs.findIndex(tab => tab.id === draggedId);
      const targetIndex = tabs.findIndex(tab => tab.id === id);
      const updatedTabs = [...tabs];
      [updatedTabs[draggedIndex], updatedTabs[targetIndex]] = [updatedTabs[targetIndex], updatedTabs[draggedIndex]];
      setTabs(updatedTabs);
    }
  };

  const handleDragEnd = () => {
    setDraggedId(null);
  };

  const handleEliminarTabla = (id: string) => {
    setTabs(tabs.filter(tab => tab.id !== id));  // Filtrar la tabla a eliminar
  };
  

  const handleAgregarTabla = () => {
    const nuevaTabla = {
      id: `tabla-${Date.now()}`,
      titulo: 'Nuevo Nivel',
      datos: []
    };
    setTabs([...tabs, nuevaTabla]);
  };

  return (
    <div>
      <button
        onClick={handleAgregarTabla}
        className="mb-4 p-2 bg-blue-500 text-white rounded flex items-center"
      >
        <PlusCircle size={24} className="mr-2" />
        Agregar Nivel
      </button>
      {tabs.map((tab, index) => (
        <TablaDesplegable
          key={tab.id}
          id={tab.id}
          titulo={tab.titulo}
          datos={tab.datos || []}
          nivel={index + 1}
          onDragStart={handleDragStart}
          onDragEnter={handleDragEnter}
          onDragEnd={handleDragEnd}
          onEliminarTabla={handleEliminarTabla}
        />
      ))}
    </div>
  );
};

export default TablasNomenclaturaProyecto;
*/

// TablasNomenclaturaProyecto Component
interface Tab {
  id: string;
  titulo: string;
  datos: { Nivel: string; Nomenclatura: string; Color?: string; }[];
  
}

const TablasNomenclaturaProyecto: React.FC = () => {
  const [tabs, setTabs] = useState<Tab[]>([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/php/pages/controldocument/glosario/get_data.php`)
      .then(response => response.json())
      .then(data => {
        // Asignar colores a los datos que no tengan
        const dataWithColors = data.map((tab: Tab) => {
          const datosConColores = tab.datos.map((item, index) => ({
            ...item,
            Color: item.Color || getDefaultColor(index)
          }));
          return {
            ...tab,
            datos: datosConColores
          };
        });
        setTabs(dataWithColors);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const [draggedId, setDraggedId] = useState<string | null>(null);

  const handleDragStart = (id: string) => {
    setDraggedId(id);
  };
  const handleDragEnter = async (id: string) => {
    if (draggedId && draggedId !== id) {
        const draggedIndex = tabs.findIndex(tab => tab.id === draggedId);
        const targetIndex = tabs.findIndex(tab => tab.id === id);

        const updatedTabs = [...tabs];
        [updatedTabs[draggedIndex], updatedTabs[targetIndex]] = [updatedTabs[targetIndex], updatedTabs[draggedIndex]];
        setTabs(updatedTabs);

        // Mostrar las posiciones finales de cada carpeta
        updatedTabs.forEach((tab, index) => {
            console.log(`Index: ${index}, Name: ${tab.titulo}`);
        });

        // Enviar la actualización al backend
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/controldocument/glosario/update_levels.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(
                    updatedTabs.map((tab, index) => ({
                        nivel_id: index + 1, // El nuevo nivel_id basado en el índice
                        titulo: tab.titulo, // Incluir el título del tab
                    }))
                ),
            });
            const result = await response.json();
            if (result.success) {
                console.log('Base de datos actualizada con éxito');
            } else {
                console.error('Error al actualizar la base de datos:', result.message);
            }
        } catch (error) {
            console.error('Error al enviar la solicitud:', error);
        }
    }
};

  
  




  const handleDragEnd = () => {
    setDraggedId(null);
  };
  const handleEliminarTabla = async (id: string) => {
    // Encuentra el registro a eliminar usando el ID
    const tabToDelete = tabs.find(tab => tab.id === id);
   
    if (tabToDelete) {
        const { id} = tabToDelete;
        
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/controldocument/glosario/delete_level.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ nivel_id: id}),
            });
            const data = await response.json();
            if (data.success) {
                // Actualizar el estado si la eliminación fue exitosa
                setTabs(tabs.filter(tab => tab.id !== id));
            } else {
                console.error('Error al eliminar la tabla:', data.message);
            }
        } catch (error) {
            console.error('Error en la solicitud:', error);
        }
    } else {
        console.error('No se encontró el registro con el ID proporcionado');
    }
};
  

/*
  const handleAgregarTabla = () => {
   const nuevaTabla = {
      id: `tabla-${Date.now()}`,
      titulo: 'Nuevo Nivel',
      datos: []
    };
    setTabs([...tabs, nuevaTabla]);
  };
*/
const handleAgregarTabla = () => {
  const randomColor = COLORES_PREDETERMINADOS[Math.floor(Math.random() * COLORES_PREDETERMINADOS.length)];


  const nuevaTabla = {
    titulo: 'Nuevo Nivel',
    datos: []
  };

  fetch(`${process.env.REACT_APP_API_URL}/php/pages/controldocument/glosario/add_level.php`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
   body: JSON.stringify({
        ...nuevaTabla,
        color: randomColor // Incluir un color predeterminado
      }),  })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        // Si la solicitud fue exitosa, agregar el nuevo nivel al estado con el id retornado
        setTabs(prevTabs => [
          ...prevTabs,
          {
           id: data.newId, // Usar el id retornado por el backend
            titulo: nuevaTabla.titulo,
            datos: nuevaTabla.datos
          }
        ]);

        // Mostrar mensaje de éxito
        setSuccessMessage('Nivel agregado exitosamente.');
        setErrorMessage('');

        // Cerrar el mensaje después de 3 segundos
        setTimeout(() => setSuccessMessage(''), 3000);
      } else {
        // Mostrar mensaje de error
        setErrorMessage(`Error al agregar el nuevo nivel: ${data.message}`);
        setSuccessMessage('');
      }
    })
    .catch(error => {
      console.error('Error en la solicitud:', error);
      setErrorMessage('Error en la solicitud.');
      setSuccessMessage('');
    });
};

return (
  <div className="p-4">
    {/* Mostrar mensajes de éxito o error en la parte superior */}
    {successMessage && (
      <div className="mb-4 p-2 rounded bg-green-200 text-green-800">
        {successMessage}
      </div>
    )}
    {errorMessage && (
      <div className="mb-4 p-2 rounded bg-red-200 text-red-800">
        {errorMessage}
      </div>
    )}

    {/* Botón para agregar un nuevo nivel, separado de los mensajes */}
    <div className="flex justify-start mb-4">
      <button
        onClick={handleAgregarTabla}
        className="p-2 bg-blue-500 text-white rounded flex items-center hover:bg-blue-600 transition-colors"
      >
        <PlusCircle size={24} className="mr-2" />
        Agregar Nivel
      </button>
    </div>

    {/* Contenedor de tablas en una fila horizontal */}
    <div className="flex flex-row overflow-x-auto space-x-4">
      {tabs.map((tab, index) => (
        <TablaDesplegable
          key={tab.id}
          id={tab.id}
          titulo={tab.titulo}
          datos={tab.datos}
          nivel={index + 1}
          onDragStart={handleDragStart}
          onDragEnter={handleDragEnter}
          onDragEnd={handleDragEnd}
          onEliminarTabla={handleEliminarTabla}
          // onEliminarTabla={() => {
          //   // Lógica para eliminar el nivel y mostrar el mensaje
          //   setTabs(tabs.filter((_, i) => i !== index));
          //   setSuccessMessage('Nivel eliminado exitosamente');
          //   setTimeout(() => setSuccessMessage(''), 3000); // Ocultar mensaje después de 3 segundos
          // }}
        />
      ))}
    </div>
    
  </div>
);
};
export default TablasNomenclaturaProyecto;