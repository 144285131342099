import React, { useState, useEffect, useRef, useCallback, createContext, useContext } from 'react';
import { ChevronDown, ChevronLeft, ChevronRight, Search, Bell, Eye, User, Home, Menu, X, Minimize2, Settings, HelpCircle, Info, LogOut, Calendar, FileText, BarChart2, Briefcase, Book, Send, DollarSign, Users, AreaChart, XCircle, RotateCcw, RefreshCw } from 'lucide-react';
import logoImage from '../img/logo3.png';
import bgImage from '../img/background2.png';
import { useNavigate } from 'react-router-dom';
import { motion } from "framer-motion";
import ConfirmDialog from '../components/ui/confirmDialog';  // Ajusta la ruta según la estructura de tu proyecto
import AboutModal from '../components/AboutModal'; 
import UserProfile from '../components/user_settings/UserProfile';

//import { useNavigation } from '../contexts/NavigationContext';
//Planificacion
import planificacionMisTareas from '../components/planificacion/misTareas';
import planificacionVistaGeneral from '../components/planificacion/vistaGeneral';
import planificacionGanttGeneral from '../components/planificacion/ganttGeneral';
import planificacionPermisologia from '../components/planificacion/permisologia';
import planificacionResumenPermisologia from '../components/planificacion/resumenPermisologia';
import planificacionPanel from '../components/planificacion/panel';
//Superbandeja
import matrizEisenhower from '../components/superbandeja/matrizEisenhower';
import todasLasTareas from '../components/superbandeja/todasLasTareas';
//Analiticas
import huellaDigital from '../components/analiticas/huellaDigital';
import riskMatrix from '../components/analiticas/riskMatrix';
import reporteDeProgreso from '../components/analiticas/reporteDeProgreso';
import reporteDeUsuarioYdepartamento from '../components/analiticas/reporteDeUsuarioYdepartamento';
import rentabilidad from '../components/analiticas/rentabilidad';
//Biblioteca Normativa
import bibliotecaNormativa from '../components/bibliotecaNormativa/bibliotecaNormativa';

//Gestión
import curvaS from '../components/gestion/curvas';
import curvaDeVelocidad from '../components/gestion/curvaDeVelocidad';
import eepp from '../components/gestion/eepp';
import adicionales from '../components/gestion/adicionales';
import reporteSemanal from '../components/gestion/reporteSemanal';
import requerimientosDeInformacion from '../components/gestion/requerimientosDeInformacion';

//Control de documentos
import glosario from '../components/controlDocumentos/glosario';
import reporte from '../components/controlDocumentos/reporte';
import busquedaRapida from '../components/controlDocumentos/busquedaRapida';

import cargaDocumentos from '../components/controlDocumentos/cargaDocumentos';
//Transmital 
import documentosObra from '../components/transmittal/documentosObra';
import transmittal from '../components/transmittal/transmittal';
import repTransmittal from '../components/transmittal/reporteTRL';


//Evaluación Técnico Comercial
import ETC from '../components/metodologiaETC/etc';
import EPIResumen from '../components/metodologiaETC/EPIResumen';
import calculoTIR from '../components/metodologiaETC/calculoTIR';
import resumenEvaluacionTC from '../components/metodologiaETC/resumenEvaluacionTC';
//Administracion
import crear from '../components/administracion/crear';
import InviteUser from '../components/administracion/InviteUser';
import editUser from '../components/administracion/editUser';
import editPlantilla from '../components/administracion/editPlantilla';
import editPlanif from '../components/administracion/editPlanif';
import editarProcesos from '../components/administracion/Procesos'

//Inicio
import inicio from '../components/inicio'
import estadisticas from '../components/estadisticas'

import useOutsideClick from '../hooks/useOutsideClick'; // Importa el hook personalizado
import { CommonComponentProps, ComponentWithOptionalProps } from '../types/common';


// Actualiza la definición de ComponentMap 
interface ComponentMap {
  [key: string]: {
    [key: string]: React.ComponentType<Partial<CommonComponentProps>>;
  };
}


const componentMap: ComponentMap = {
  'Planificación': {
    'Mis tareas': planificacionMisTareas as ComponentWithOptionalProps,
    'Carta Gantt': planificacionGanttGeneral as ComponentWithOptionalProps,
    'Vista General': planificacionVistaGeneral as ComponentWithOptionalProps,
    'Tramitología': planificacionPermisologia as ComponentWithOptionalProps,
    'Tablero': planificacionPanel as ComponentWithOptionalProps,
    },
  'SUPERBANDEJA': {
   /* 'Matriz de Eisenhower': matrizEisenhower,*/
   '': todasLasTareas, // Making it a single view
  },
  'ANALITICAS':{
    'Huella digital': huellaDigital as ComponentWithOptionalProps,
    'Reporte de progreso': reporteDeProgreso,
    'Reporte de usuario y Departamento': reporteDeUsuarioYdepartamento,
    // 'Rentabilidad': rentabilidad,
  }, 
  'MATRICES': {
    'Matriz de Riesgo': riskMatrix, // Creating a new top-level section
  },
  'RESUMEN TRAMITOLOGÍA': {
    '': planificacionResumenPermisologia as ComponentWithOptionalProps,
  },
  'Gestión':{
    'Curva S':curvaS,
    'Curva de Velocidad':curvaDeVelocidad,
    'EEPP': eepp,
    'Adicionales':adicionales,
    'Reporte semanal':reporteSemanal,
    'Requerimientos de información':requerimientosDeInformacion,
  },'Control de Documentos':{
    'Glosario':glosario,
    'Reporte':reporte,
    'Búsqueda rápida':busquedaRapida,
    'Carga de documentos':cargaDocumentos,
  },'TRANSMITTAL':{
    'Reporte de Transmittal':repTransmittal as ComponentWithOptionalProps,
    'Bandeja de Salida':transmittal,
  },'EVALUACIÓN TÉCNICO COMERCIAL':{
    'Metodología ETC':ETC,
    'EPI Resumen': EPIResumen,
    'Cálculo de TIR':calculoTIR,
    'Resumen Evaluación Técnico Comercial':resumenEvaluacionTC,
  },'ADMINISTRACIÓN':{
    // 'Crear proceso':crear,
    'Gestionar procesos':editarProcesos,
    // 'Crear usuarios':InviteUser,
    'Gestionar usuarios':editUser,
    'Mis plantillas': editPlantilla as ComponentWithOptionalProps,
    'Mis planificaciones': editPlanif as ComponentWithOptionalProps,
  },
'Biblioteca Normativa':{
  '': bibliotecaNormativa
},

  'Inicio':{
    '': inicio,
    'Volver al Inicio': inicio,
    'Estadisticas': estadisticas,
  },
 
};





// Crear un contexto para la navegación
const NavigationContext = createContext({
  handleNavigation: (direction: 'prev' | 'next') => {},
  getCurrentItems: () => [] as string[],
  activeSection: { main: '', sub: '' }
});

// Componente para las flechas de navegación
const NavigationArrows = () => {
  const [showArrows, setShowArrows] = useState(false);
  const hideTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const isMouseNearEdgeRef = useRef(false);
  const { handleNavigation, activeSection } = useContext(NavigationContext);


  const isSingleViewSection = (section: string): boolean => {
    const singleViewSections = [
      'SUPERBANDEJA',
      'RESUMEN TRAMITOLOGÍA',
      'Biblioteca Normativa'
    ];
    return singleViewSections.includes(section);
  };




  const handleMouseMove = useCallback((e: MouseEvent) => {
    const threshold = 100;
    const isNearEdge = e.clientX <= threshold || e.clientX >= window.innerWidth - threshold;
    
    isMouseNearEdgeRef.current = isNearEdge;

    if (isNearEdge) {
      setShowArrows(true);
      if (hideTimeoutRef.current) {
        clearTimeout(hideTimeoutRef.current);
      }
      hideTimeoutRef.current = setTimeout(() => {
        if (!isMouseNearEdgeRef.current) {
          setShowArrows(false);
        }
      }, 2000);
    }
  }, []);

  const handleMouseLeave = useCallback(() => {
    isMouseNearEdgeRef.current = false;
    if (hideTimeoutRef.current) {
      clearTimeout(hideTimeoutRef.current);
    }
    setShowArrows(false);
  }, []);



  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseleave', handleMouseLeave);
      if (hideTimeoutRef.current) {
        clearTimeout(hideTimeoutRef.current);
      }
    };
  }, [handleMouseMove, handleMouseLeave]);
  if (isSingleViewSection(activeSection.main)) {
    return null;
  }

  return (
    <div className="flex justify-between items-center w-full mt-4 mb-2">
      <button
        onClick={() => handleNavigation('prev')}
        className="shadow-md p-3 bg-gray-400 rounded-full text-white hover:bg-custom-sidebar hover:text-white transition-colors duration-200"
        aria-label="Previous"
      >
        <ChevronLeft className="h-6 w-6" />
      </button>
      <button
        onClick={() => handleNavigation('next')}
        className="shadow-md p-3 bg-gray-400 rounded-full text-white hover:bg-custom-sidebar hover:text-white transition-colors duration-200"
        aria-label="Next"
      >
        <ChevronRight className="h-6 w-6" />
      </button>
    </div>
  );
};

const isSingleViewSection = (section: string): boolean => {
  const singleViewSections = [
    'SUPERBANDEJA',
    'RESUMEN TRAMITOLOGÍA',
    'Biblioteca Normativa'
  ];
  return singleViewSections.includes(section);
};
 




const SimagiInteractivePreview = () => {

  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [isChatMinimized, setIsChatMinimized] = useState(false);
  const [isProcessActive, setIsProcessActive] = useState(true);

  const [isComponentVisible, setIsComponentVisible] = useState(true);

  const [selectedProcess, setSelectedProcess] = useState<string>('');
  const [processes, setProcesses] = useState<Process[]>([]);

  const navigate = useNavigate();

  const [openMenu, setOpenMenu] = useState<string | null>(null);
  const [openSidebar, setOpenSidebar] = useState<string | null>(null);
  const [openMenus, setOpenMenus] = useState<{ [key: string]: boolean }>({});
  const [openMobile, setOpenMobile] = useState<string | null>(null);
  const [activeSection, setActiveSection] = useState({ main: 'Inicio', sub: '' });
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isProcessOpen, setIsProcessOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false); // Correctly moved here
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isButtonSelected, setIsButtonSelected] = useState(false); // Nuevo estado
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);
  const [userId, setUserId] = useState<number | null>(null);

  const [firstName, setFirstName] = useState<string | null>(null);
  const [lastName, setLastName] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [title, setTitle] = useState<string | null>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [userProfile, setUserProfile] = useState<string | null>(null);
  const [hasRestrictedAccess, setHasRestrictedAccess] = useState(false);

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  //const { confirmNavigation } = useNavigation(); // Obtener confirmNavigation del contexto
  const [filteredProcesses, setFilteredProcesses] = useState<Process[]>([]);

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState<{main: string, sub: string, isRefresh: boolean} | null>(null);
  const userMenuRef  = useOutsideClick(() => {
   setIsUserMenuOpen(false);
  });
  const [contentKey, setContentKey] = useState(0); // Estado para forzar actualización
 
  const [isSpinning, setIsSpinning] = useState(false);

  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false);

  const [isUserProfileOpen, setIsUserProfileOpen] = useState(false);


  const canAccessSection = (main: string, sub: string = ''): boolean => {
    if (isAdmin) return true; // Administradores siempre tienen acceso
    
    if (hasRestrictedAccess) {
      return main === 'Inicio' || main === 'Biblioteca Normativa';
    }
    
    return true; // Otros usuarios tienen acceso completo
  };

  useEffect(() => {
    // Si el usuario tiene acceso restringido y no es admin
    if (hasRestrictedAccess && !isAdmin) {
      // Si están intentando acceder a algo diferente de Inicio o Biblioteca Normativa
      if (activeSection.main !== 'Inicio' && activeSection.main !== 'Biblioteca Normativa') {
        // Redirigirlos a Biblioteca Normativa
        setActiveSection({ main: 'Biblioteca Normativa', sub: '' });
      }
    }
  }, [activeSection.main, hasRestrictedAccess, isAdmin]);


  const handleConfirmNavigation = () => {
    if (pendingNavigation) {
      setHasUnsavedChanges(false);
      
      // Check if this is a refresh operation
      if (pendingNavigation.isRefresh) {
        performRefresh();
      } else {
        setActiveSection(pendingNavigation);
        setOpenMenu(null);
        setIsMobileMenuOpen(false);
      }
    }
    setIsConfirmDialogOpen(false);
    setPendingNavigation(null);
  };
  
  const performRefresh = () => {
    setHasUnsavedChanges(false);
    setIsSpinning(true);
    setContentKey((prev) => prev + 1);
    setTimeout(() => setIsSpinning(false), 1000);
  };
  
  const handleCancelNavigation = () => {
    setIsConfirmDialogOpen(false);
    setPendingNavigation(null);
  };
  const handleRefresh = () => {
    if (activeSection.sub === 'Mis planificaciones' && hasUnsavedChanges) {
      setIsConfirmDialogOpen(true);
      setPendingNavigation({ 
        main: activeSection.main, 
        sub: activeSection.sub,
        isRefresh: true // Add a flag to distinguish refresh from navigation
      });
      return;
    }
    
    // Perform refresh directly
    performRefresh();
  };


  const chatRef = useRef<HTMLDivElement>(null);
  //const notificationsRef = useRef<HTMLDivElement>(null);

  const notificationsRef  = useOutsideClick(() => {
  
    setIsNotificationsOpen(false);
    });
  
  const mobileRef = useOutsideClick(() => {
    setIsSidebarOpen(false);
    setIsNotificationsOpen(false);
  });

  const sidebarRef = useOutsideClick(() => {
   
    setIsNotificationsOpen(false);
    if (openSidebar) {
      setOpenSidebar(null);
    }
  });  

  const topMenuItems = [ 
    { 
      label: "Inicio", 
      icon: <Home className="h-5 w-5 text-white" />, 
      subItems: ["Volver al Inicio", "Estadisticas"] 
    },
    { label: "Planificación", icon: null, subItems: ["Mis tareas", "Carta Gantt", "Vista General", "Tablero", "Tramitología"] },
    { label: "Control de Documentos", icon: null, subItems: ["Carga de documentos", "Búsqueda rápida", "Reporte", "Glosario"] },
    { label: "Gestión", icon: null, subItems: ["Curva S", "Reporte semanal", "Adicionales", "Requerimientos de información"] },
    { label: "Biblioteca Normativa", icon: null, subItems: [] }

  ];



  
  const sidebarItems = [
    { label: 'SUPERBANDEJA', subItems: [] },
    /* label: 'SUPERBANDEJA', subItems: ['Todas las tareas', 'Matriz de Eisenhower'] */
    { 
      label: 'ANALITICAS',
      subItems: [
        'Huella digital',        // Por proceso
        'Reporte de usuario y Departamento',  // Global
        'Reporte de progreso',   // Global
        // 'Rentabilidad',          // Global
      ]
    }, { 
      label: 'MATRICES', 
      subItems: ['Matriz de Riesgo', 'Matriz de Cumplimiento Ambiental', 'Medidas de Mitigación IMIV', 'Documentos Bancarios', 'Condiciones de Promesa', 'Recepción Municipal Urbanización','Recepción Municipal Edificación','Requerimientos Post-Venta'] // No sub-items since it's a single view
    },{ 
      label: 'RESUMEN TRAMITOLOGÍA', 
      subItems: [] // No sub-items since it's a single view
    },
    { label: 'EVALUACIÓN TÉCNICO COMERCIAL', subItems: ['Metodología ETC', 'EPI Resumen','Resumen Evaluación Técnico Comercial'] },
    { label: 'TRANSMITTAL', subItems: ['Bandeja de Salida', 'Reporte de Transmittal'] },
    ...(isAdmin ? [{ label: 'ADMINISTRACIÓN', subItems: ['Gestionar procesos', 'Gestionar usuarios', 'Mis plantillas', 'Mis planificaciones'] }] : [])
  ];
  
/*
  const allMenuItems = [
    { label: "Inicio", icon: <Home className="h-5 w-5" />, subItems: [] },
    { label: "Planificación", icon: null, subItems: ["Mis tareas", "Vista General", "Panel", "Permisología"] },
    { label: "Control de Documentos", icon: null, subItems: ["Búsqueda rápida", "Glosario", "Reporte"] },
    { label: "Gestión", icon: null, subItems: ["Curva S", "Reporte semanal", "EEPP", "Curva de Velocidad", "Adicionales"] },
    { label: 'SUPERBANDEJA', subItems: ['Todas las tareas', 'Matriz de Eisenhower'] },
    { label: 'ANALITICAS', subItems: ['Reporte de usuario y Departamento', 'Reporte de progreso', 'Huella digital', 'Rentabilidad'] },
    { label: 'METODOLOGÍA ETC', subItems: ['Cálculo de TIR', 'Resumen Evaluación Técnico Comercial'] },
    { label: 'TRANSMITAL', subItems: ['Documentos emitidos a Obra', 'Transmital'] },
    { label: 'ADMINISTRACIÓN', subItems: ['Crear'] }
  ];
*/
  const allMenuItems = [
  ...topMenuItems,
  ...sidebarItems,
  ];

  interface Process {
    id?: number;
    codigo: string;
    nombreProceso: string;
    comuna: string;
    isActive?: number | boolean;
    plantilla?: string;
    subprocesos?: any[];
    [key: string]: any; // Para otros campos que puedan existir
  }

  interface ProcessSelectProps {
    isVisible: boolean;
    selectedProcess: string;
    processes: Process[];
    isLoading: boolean;
    isProcessActive: boolean; // New prop

    onChange: (value: string) => void;
    onRefresh?: () => void; // Nueva prop

  }
  
  const ProcessSelect: React.FC<ProcessSelectProps> = ({ 
    isVisible, 
    selectedProcess, 
    processes,
    isLoading,
    isProcessActive, // New prop
    onChange,
    onRefresh
  }) => {
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
    const [filteredProcesses, setFilteredProcesses] = useState<Process[]>([]);
    const [displayValue, setDisplayValue] = useState<string>('');
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);

    
    useEffect(() => {
      if (selectedProcess && processes.length > 0) {
        const currentProcess = processes.find((p: Process) => p.nombreProceso === selectedProcess);
        if (currentProcess) {
          const label = `${currentProcess.codigo} - ${currentProcess.nombreProceso} - ${currentProcess.comuna}`;
          setDisplayValue(label);
          setSearchTerm(label);
        }
      }
    }, [selectedProcess, processes]);
  
    useEffect(() => {
      const filtered = processes.filter((process: Process) => {
        const searchString = `${process.codigo} ${process.nombreProceso} ${process.comuna}`.toLowerCase();
        return searchTerm === '' || searchString.includes(searchTerm.toLowerCase());
      });
    
      if (selectedProcess) {
        const selectedProcessItem = filtered.find((p) => p.nombreProceso === selectedProcess);
        if (selectedProcessItem) {
          setFilteredProcesses([
            selectedProcessItem,
            ...filtered.filter((p) => p.nombreProceso !== selectedProcess),
          ]);
        } else {
          setFilteredProcesses(filtered);
        }
      } else {
        setFilteredProcesses(filtered);
      }
      setHighlightedIndex(-1);
    }, [searchTerm, selectedProcess, processes]);
    
    useEffect(() => {
      if (selectedProcess) {
        const currentProcess = processes.find(p => p.nombreProceso === selectedProcess);
        if (currentProcess) {
          checkProcessActive(currentProcess.codigo);
        }
      }
    }, [selectedProcess, processes, checkProcessActive]);
    
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
          setShowSuggestions(false);
          if (selectedProcess) {
            const currentProcess = processes.find((p: Process) => p.nombreProceso === selectedProcess);
            if (currentProcess) {
              const label = `${currentProcess.codigo} - ${currentProcess.nombreProceso} - ${currentProcess.comuna}`;
              setSearchTerm(label);
            }
          }
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [selectedProcess, processes]);
  
    const handleProcessSelect = (process: Process) => {
      const label = `${process.codigo} - ${process.nombreProceso} - ${process.comuna}`;
      setSearchTerm(label);
      setDisplayValue(label);
      onChange(process.nombreProceso);
      setShowSuggestions(false);
      localStorage.setItem('lastSelectedProcess', process.nombreProceso);
    };
  
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setSearchTerm(value);
      setShowSuggestions(true);
    };
  
    const handleFocus = () => {
      setShowSuggestions(true);
      if (inputRef.current) {
        inputRef.current.select(); // Selecciona todo el texto automáticamente
      }
    };


    
  

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        setHighlightedIndex((prev) => (prev < filteredProcesses.length - 1 ? prev + 1 : 0)); // Reinicia al primero
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        setHighlightedIndex((prev) => (prev > 0 ? prev - 1 : filteredProcesses.length - 1)); // Salta al último
      } else if (e.key === 'Enter') {
        if (highlightedIndex >= 0 && highlightedIndex < filteredProcesses.length) {
          handleProcessSelect(filteredProcesses[highlightedIndex]);
        }
      } else if (e.key === 'Escape') { 
        setShowSuggestions(false);
      }
    };
    

    const clearSearch = () => {
      setSearchTerm('');
      setShowSuggestions(false);
    };
  
    if (!isVisible) return null;
  
    return (
      <div className="w-full mb-3" ref={wrapperRef}>
        <div className="flex items-center gap-2 mb-2">
          <span className="text-sm font-medium text-gray-700 whitespace-nowrap">Proceso:</span>
    
          {/* Campo de búsqueda expandido */}
          <div className="relative flex-1">
            <input
              ref={inputRef}
              type="text"
              value={searchTerm}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              onFocus={handleFocus}
              placeholder="Buscar proceso..."
              className={`w-full p-2 pr-10 border ${isProcessActive ? 'border-gray-300' : 'border-red-300'} rounded-md shadow-sm focus:outline-none focus:ring-1 ${isProcessActive ? 'focus:ring-blue-500' : 'focus:ring-red-500'} text-sm font-normal`}
              disabled={isLoading}
            />
            <div className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5">
              <Search className="w-5 h-5" />
            </div>
            {searchTerm && (
              <div className="absolute right-8 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5 hover:scale-110 transition-transform cursor-pointer" onClick={clearSearch}>
                <XCircle className="text-gray-400 w-5 h-5" />
              </div>
            )}
            {showSuggestions && (
              <div className="absolute z-50 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-y-auto">
                {filteredProcesses.length > 0 ? (
                  filteredProcesses.map((process, index) => (
                    <div 
                      key={index}
                      className={`flex items-center p-2 cursor-pointer ${
                        highlightedIndex === index ? 'bg-blue-100' : 'hover:bg-gray-100'
                      }`}
                      onClick={() => handleProcessSelect(process)}
                    >
                      <div className="font-medium mr-2 pr-2 border-r border-gray-300">{process.codigo}</div>
                      <div className="text-sm text-gray-600 mr-2 pr-2 border-r border-gray-300">{process.nombreProceso}</div>
                      <div className="text-xs text-gray-500">{process.comuna}</div>
                    </div>
                  ))
                ) : (
                  <div className="p-2 text-gray-500">No se encontraron procesos</div>
                )}
              </div>
            )}
          </div>
    
          {/* Botón de recarga */}
          <button 
            onClick={() => onRefresh?.()}
            className="p-2 rounded-full hover:bg-gray-200 transition-colors"
            title="Actualizar lista de procesos"
            disabled={isLoading}
          >
            <RefreshCw className={`h-4 w-4 ${isLoading ? 'animate-spin text-gray-400' : 'text-gray-600'}`} />
          </button>
        </div>
    
        {/* Mensaje de proceso inactivo */}
        {!isProcessActive && selectedProcess && (
          <div className="text-red-600 text-xs mt-1">
            Este proceso está inactivo. Algunas funciones pueden estar limitadas.
          </div>
        )}
      </div>
    );
    
  };
  
  
  
  const [isInitialized, setIsInitialized] = useState(false);

  // useEffect(() => {
  //   const fetchProcesses = async () => {
  //     setIsLoadingProcesses(true);
  //     try {
  //       const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/proceso/get_processes.php?type=type3`);
  //       if (!response.ok) {
  //         throw new Error('Network response was not ok');
  //       }
        
  //       const data = await response.json();
  //       setProcesses(data);
  //       // Get the last selected process from localStorage
  //       const lastSelectedProcess = localStorage.getItem('lastSelectedProcess');
        
  //       if (!isInitialized) {
  //         // Check if the lastSelectedProcess exists in the current process list
  //         if (lastSelectedProcess && data.some((p:Process) => p.nombreProceso === lastSelectedProcess)) {
  //           // Process still exists in list, use it
  //           setSelectedProcess(lastSelectedProcess);
  //         } else if (lastSelectedProcess) {
  //           // Process no longer exists in the list, remove from localStorage
  //           localStorage.removeItem('lastSelectedProcess');
            
  //           // Select first available process if any
  //           if (data.length > 0) {
  //             setSelectedProcess(data[0].nombreProceso);
  //             localStorage.setItem('lastSelectedProcess', data[0].nombreProceso);
  //           } else {
  //             setSelectedProcess('');
  //           }
  //         } else if (data.length > 0) {
  //           // No process in localStorage, select first available
  //           setSelectedProcess(data[0].nombreProceso);
  //           localStorage.setItem('lastSelectedProcess', data[0].nombreProceso);
  //         }
          
  //         setIsInitialized(true);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching processes:', error);
  //     } finally {
  //       setIsLoadingProcesses(false);
  //     }
  //   };
  
  //   if (activeSection.main !== 'Inicio' && 
  //       activeSection.main !== 'Control de Documentos' && 
  //       activeSection.main !== 'ADMINISTRACIÓN') {
  //     fetchProcesses();
  //   }
  // }, [activeSection.main, activeSection.sub, isInitialized]);
  
  const handleUnsavedChanges = (hasChanges: boolean) => {
   // console.log("[DEBUG] Setting hasUnsavedChanges to:", hasChanges);
    setHasUnsavedChanges(hasChanges);
  };

  const [isLoadingProcesses, setIsLoadingProcesses] = useState<boolean>(true);

  
  const fetchProcesses = async () => {
    setIsLoadingProcesses(true);
    try {
      // Siempre cargar todos los procesos del servidor
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/proceso/get_processes.php?type=type3`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      
      const allProcessesData = await response.json();
      
      // Guardar todos los procesos
      setProcesses(allProcessesData);
      
      // Filtrar procesos activos
      const activeProcessesArray = [];
      for (const process of allProcessesData) {
        const isActive = await isProcessActiveCheck(process.codigo);
        if (isActive) {
          activeProcessesArray.push(process);
        }
      }
      
      // Actualizar la lista filtrada
      setFilteredProcesses(activeProcessesArray);
      
      // Resto de la lógica (igual que tienes ahora)
      if (activeProcessesArray.length > 0) {
        // Verificar si el último proceso seleccionado sigue activo
        const lastSelectedProcess = localStorage.getItem('lastSelectedProcess');
        
        if (lastSelectedProcess && activeProcessesArray.some(p => p.nombreProceso === lastSelectedProcess)) {
          setSelectedProcess(lastSelectedProcess);
        } else {
          setSelectedProcess(activeProcessesArray[0].nombreProceso);
          localStorage.setItem('lastSelectedProcess', activeProcessesArray[0].nombreProceso);
        }
      } else {
        setSelectedProcess('');
        localStorage.removeItem('lastSelectedProcess');
      }
    } catch (error) {
      console.error('Error fetching processes:', error);
      setFilteredProcesses([]);
      setSelectedProcess('');
    } finally {
      setIsLoadingProcesses(false);
    }
  };

  // Función auxiliar para verificar si un proceso está activo (sin cambiar el estado)
const isProcessActiveCheck = async (codigo:string) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/proceso/check_process_active.php?codigo=${codigo}`);
    if (!response.ok) {
      return false;
    }
    
    const data = await response.json();
    return data.success && data.isActive;
  } catch (error) {
    console.error('Error checking process active status:', error);
    return false;
  }
};

  // const fetchProcesses = async () => {

  //   setIsLoadingProcesses(true);
  //   try {
  //     const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/proceso/get_processes.php?type=type3`);
  //     if (!response.ok) {
  //       throw new Error('Network response was not ok');
  //     }
      
  //     const data = await response.json();
  //     setProcesses(data);
      
  //     // Get the last selected process from localStorage
  //     const lastSelectedProcess = localStorage.getItem('lastSelectedProcess');
      
  //     // Check if the lastSelectedProcess exists in the current process list
  //     if (lastSelectedProcess && data.some((p:Process) => p.nombreProceso === lastSelectedProcess)) {
  //       // Process still exists in list, use it
  //       setSelectedProcess(lastSelectedProcess);
  //     } else if (lastSelectedProcess) {
  //       // Process no longer exists in the list, remove from localStorage
  //       localStorage.removeItem('lastSelectedProcess');
        
  //       // Select first available process if any
  //       if (data.length > 0) {
  //         setSelectedProcess(data[0].nombreProceso);
  //         localStorage.setItem('lastSelectedProcess', data[0].nombreProceso);
  //       } else {
  //         setSelectedProcess('');
  //       }
  //     } else if (data.length > 0 && !selectedProcess) {
  //       // No process in localStorage or selected, select first available
  //       setSelectedProcess(data[0].nombreProceso);
  //       localStorage.setItem('lastSelectedProcess', data[0].nombreProceso);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching processes:', error);
  //   } finally {
  //     setIsLoadingProcesses(false);
  //   }
  useEffect(() => {
    // Recargar los procesos cuando cambia la sección activa
    if (activeSection.main !== 'Inicio' && 
        activeSection.main !== 'Control de Documentos' && 
        activeSection.main !== 'ADMINISTRACIÓN') {
      fetchProcesses();
    }
  }, [activeSection.main, activeSection.sub]);
  
  const checkProcessActive = useCallback(async (codigo:string) => {
    if (!codigo) return;
    
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/proceso/check_process_active.php?codigo=${codigo}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      
      const data = await response.json();
      if (data.success) {
        setIsProcessActive(data.isActive);
        
        // Si el proceso está inactivo, eliminarlo de la lista y seleccionar uno vacío
        if (!data.isActive) {
          console.warn(`El proceso ${codigo} está inactivo. Será eliminado de la lista.`);
          
          // Eliminar el proceso inactivo de la lista filtrada
          setFilteredProcesses(prevProcesses => 
            prevProcesses.filter(p => p.codigo !== codigo)
          );
          
          // Limpiar el proceso seleccionado
          setSelectedProcess('');
          
          // Eliminar del localStorage
          localStorage.removeItem('lastSelectedProcess');
          
          // Mostrar una notificación al usuario
         
        }
      } else {
        console.error('Error checking process status:', data.message);
        setIsProcessActive(false);
      }
    } catch (error) {
      console.error('Error checking process status:', error);
      setIsProcessActive(false);
    }
  }, []);

  const handleProcessChange = async (processName:string) => {
    // Si el nombre del proceso está vacío, limpiar la selección
    if (!processName) {
      setSelectedProcess('');
      localStorage.removeItem('lastSelectedProcess');
      return;
    }
    
    // Buscar el proceso en la lista filtrada (que ya contiene solo procesos activos)
    const selectedProcessObj = filteredProcesses.find(p => p.nombreProceso === processName);
    
    if (selectedProcessObj) {
      // Verificar nuevamente que el proceso esté activo (doble verificación)
      const isActive = await isProcessActiveCheck(selectedProcessObj.codigo);
      
      if (isActive) {
        // El proceso está activo, seleccionarlo normalmente
        setSelectedProcess(processName);
        localStorage.setItem('lastSelectedProcess', processName);
      } else {
        // El proceso no está activo a pesar de estar en la lista filtrada (podría haber cambiado)
        // Eliminarlo de la lista y no seleccionarlo
        setFilteredProcesses(prevProcesses => 
          prevProcesses.filter(p => p.codigo !== selectedProcessObj.codigo)
        );
        
        setSelectedProcess('');
        localStorage.removeItem('lastSelectedProcess');
        
      
        
        // Actualizar la lista de procesos
        fetchProcesses();
      }
    } else {
      // El proceso no está en la lista filtrada, puede ser que no sea válido
      console.warn(`Proceso no encontrado en la lista filtrada: ${processName}`);
      setSelectedProcess('');
      localStorage.removeItem('lastSelectedProcess');
    }
  };
  
  // const handleTopMobileSelect = (main: string) => {
  //   console.log('handleTopMobileSelect called with:', main);
  
  //   if (main === 'Inicio') {
  //     setActiveSection({ main: 'Inicio', sub: '' });
  //     setIsMobileMenuOpen(false);
  //   } else {
  //     const menuItem = allMenuItems.find(item => item.label === main);
  //     if (menuItem && menuItem.subItems.length > 0) {
  //       // Toggle the menu if the same item is clicked again
  //       setOpenMobile(prev => (prev === main ? null : main));
  //     } else {
  //       if (activeSection.sub === 'Mis planificaciones' && hasUnsavedChanges) {
  //         setIsConfirmDialogOpen(true);
  //         setPendingNavigation({ main, sub: '', isRefresh: false});
  //         return;
  //       }
  //       setHasUnsavedChanges(false);
  //       setActiveSection({ main, sub: '' });
  //       setIsMobileMenuOpen(false);
  //     }
  //   }
  
  //   console.log('After handleTopMobileSelect:', { main, openMobile: openMobile });
  // };
  // Modificar handleTopMobileSelect
const handleTopMobileSelect = (main: string) => {
  // Solo aplicar restricción si NO es admin Y tiene acceso restringido
  if (hasRestrictedAccess && !isAdmin && main !== 'Inicio' && main !== 'Biblioteca Normativa') {
    return; // No hacer nada si intenta acceder a secciones no permitidas
  }

  // El resto del código original sin cambios
  //console.log('handleTopMobileSelect called with:', main);
  
  if (main === 'Inicio') {
    setActiveSection({ main: 'Inicio', sub: '' });
    setIsMobileMenuOpen(false);
  } else {
    const menuItem = allMenuItems.find(item => item.label === main);
    if (menuItem && menuItem.subItems.length > 0) {
      // Toggle the menu if the same item is clicked again
      setOpenMobile(prev => (prev === main ? null : main));
    } else {
      if (activeSection.sub === 'Mis planificaciones' && hasUnsavedChanges) {
        setIsConfirmDialogOpen(true);
        setPendingNavigation({ main, sub: '', isRefresh: false});
        return;
      }
      setHasUnsavedChanges(false);
      setActiveSection({ main, sub: '' });
      setIsMobileMenuOpen(false);
    }
  }
  
  //console.log('After handleTopMobileSelect:', { main, openMobile: openMobile });
};


// const handleSubMobileSelect = (main: string, sub: string) => {
//   setActiveSection({ main, sub });
  
//   //setIsMobileMenuOpen(false);  // Always close mobile menu after selection
// };


const handleSubMobileSelect = (main: string, sub: string) => {
  // Solo aplicar restricción si NO es admin Y tiene acceso restringido
  if (hasRestrictedAccess && !isAdmin && main !== 'Inicio' && main !== 'Biblioteca Normativa') {
    return; // No hacer nada si intenta acceder a secciones no permitidas
  }

  // El resto del código original sin cambios
  setActiveSection({ main, sub });
};

/*


const handleSubMenuSelect = (main: string, sub: string) => {
  console.log('handleSubMenuSelect called with:', { main, sub });
  setActiveSection({ main, sub });
  setOpenMenu(null);
  setIsMobileMenuOpen(false);
  setIsSidebarOpen(false);
};
*/

// const handleTopMenuSelect = (main: string, fromLogo: boolean = false) => {
//   console.log('handleTopMenuSelect called with:', main);

//   // Si el click viene del logo, ir directamente al inicio
//   if (fromLogo) {
//     if (activeSection.sub === 'Mis planificaciones' && hasUnsavedChanges) {
//       setIsConfirmDialogOpen(true);
//       setPendingNavigation({ main: 'Inicio', sub: '', isRefresh: false});
//       return;
//     }
//     setHasUnsavedChanges(false);
//     setActiveSection({ main: 'Inicio', sub: '' });
//     setIsMobileMenuOpen(false);
//     return;
//   }

//   // Encuentra el elemento del menú
//   const menuItem = allMenuItems.find(item => item.label === main);

//   // Si el elemento tiene subitems, solo desplegar el menú
//   if (menuItem && menuItem.subItems.length > 0) {
//     setOpenMenu(prev => prev === main ? null : main);
 
//     return;
//   }

//   // Si no hay cambios sin guardar, proceder con la navegación
//   if (!hasUnsavedChanges) {
//     setActiveSection({ main, sub: '' });
//     setIsMobileMenuOpen(false);
//     return;
//   }else{  // Si hay cambios sin guardar y se está en 'Mis planificaciones', mostrar diálogo de confirmación
//   if (activeSection.sub === 'Mis planificaciones') {
//     setIsConfirmDialogOpen(true);
//     setPendingNavigation({ main, sub: '', isRefresh: false});
//   }
// }
// };
const handleTopMenuSelect = (main: string, fromLogo: boolean = false) => {
  // Solo aplicar restricción si NO es admin Y tiene acceso restringido
  if (hasRestrictedAccess && !isAdmin && main !== 'Inicio' && main !== 'Biblioteca Normativa') {
    return; // No hacer nada si intenta acceder a secciones no permitidas
  }

  // El resto del código original sin cambios
  if (fromLogo) {
    if (activeSection.sub === 'Mis planificaciones' && hasUnsavedChanges) {
      setIsConfirmDialogOpen(true);
      setPendingNavigation({ main: 'Inicio', sub: '', isRefresh: false});
      return;
    }
    setHasUnsavedChanges(false);
    setActiveSection({ main: 'Inicio', sub: '' });
    setIsMobileMenuOpen(false);
    return;
  }

  // Encuentra el elemento del menú
  const menuItem = allMenuItems.find(item => item.label === main);

  // Si el elemento tiene subitems, solo desplegar el menú
  if (menuItem && menuItem.subItems.length > 0) {
    setOpenMenu(prev => prev === main ? null : main);
 
    return;
  }

  // Si no hay cambios sin guardar, proceder con la navegación
  if (!hasUnsavedChanges) {
    setActiveSection({ main, sub: '' });
    setIsMobileMenuOpen(false);
    return;
  }else{  // Si hay cambios sin guardar y se está en 'Mis planificaciones', mostrar diálogo de confirmación
  if (activeSection.sub === 'Mis planificaciones') {
    setIsConfirmDialogOpen(true);
    setPendingNavigation({ main, sub: '', isRefresh: false});
  }
}
};

// const handleSubMenuSelect = (main: string, sub: string) => {
//   console.log('handleSubMenuSelect called with:', { main, sub });

//   // First, check if there are unsaved changes in 'Mis planificaciones'
//   if (activeSection.sub === 'Mis planificaciones' && hasUnsavedChanges) {
//     setIsConfirmDialogOpen(true);
//     setPendingNavigation({ main, sub, isRefresh: false});
//     return;
//   }

//   // If no unsaved changes, proceed with navigation
//   setHasUnsavedChanges(false);
  
//   // Special handling for 'Inicio' submenu items
//   if (main === 'Inicio') {
//     if (sub === 'Volver al Inicio') {
//       setActiveSection({ main: 'Inicio', sub: '' });
//     } else if (sub === 'Estadisticas') {
//       setActiveSection({ main: 'Inicio', sub: 'Estadisticas' });
//     }
//   } else {
//     // For other main menu items, set the active section
//     setActiveSection({ main, sub });
//   }
  
//   // Close menus
//   setOpenMenu(null);
//   setIsMobileMenuOpen(false);
// };

// Modificar handleSubMenuSelect
// Modificar handleSubMenuSelect
const handleSubMenuSelect = (main: string, sub: string) => {
  // Solo aplicar restricción si NO es admin Y tiene acceso restringido
  if (hasRestrictedAccess && !isAdmin && main !== 'Inicio' && main !== 'Biblioteca Normativa') {
    return; // No hacer nada si intenta acceder a secciones no permitidas
  }

  // El resto del código original sin cambios
  if (activeSection.sub === 'Mis planificaciones' && hasUnsavedChanges) {
    setIsConfirmDialogOpen(true);
    setPendingNavigation({ main, sub, isRefresh: false});
    return;
  }

  // If no unsaved changes, proceed with navigation
  setHasUnsavedChanges(false);
  
  // Special handling for 'Inicio' submenu items
  if (main === 'Inicio') {
    if (sub === 'Volver al Inicio') {
      setActiveSection({ main: 'Inicio', sub: '' });
    } else if (sub === 'Estadisticas') {
      setActiveSection({ main: 'Inicio', sub: 'Estadisticas' });
    }
  } else {
    // For other main menu items, set the active section
    setActiveSection({ main, sub });
  }
  
  // Close menus
  setOpenMenu(null);
  setIsMobileMenuOpen(false);
};
/*
  const handleSidebarSelect = (main: string, sub: string | null = null) => {
    setActiveSection({ main, sub: sub || '' });
  };
*/
// const handleSidebarSelect = (main: string) => {

//   console.log('handleSidebarSelect called with:', main);
//   if (main === 'Inicio') {
//     setActiveSection({ main: 'Inicio', sub: '' });
//     setIsMobileMenuOpen(false);
//   } else {
//     const menuItem = allMenuItems.find(item => item.label === main);
//     if (menuItem && menuItem.subItems.length > 0) {
//       setOpenSidebar(prev => prev === main ? null : main); // Toggle open sidebar
//     } else {
//       if (activeSection.sub === 'Mis planificaciones' && hasUnsavedChanges) {
//         setIsConfirmDialogOpen(true);
//         setPendingNavigation({ main, sub: '', isRefresh: false });
//         return;
//       }
//       setHasUnsavedChanges(false);
//       setActiveSection({ main, sub: '' });
//       setIsMobileMenuOpen(false);
//     }
//   }
//   console.log('After handleSidebarSelect:', { main, openSidebar: openSidebar });
// }; esta si
  
// Modificar handleSidebarSelect
const handleSidebarSelect = (main: string) => {
  // Solo aplicar restricción si NO es admin Y tiene acceso restringido
  if (hasRestrictedAccess && !isAdmin && main !== 'Biblioteca Normativa') {
    return; // No hacer nada si intenta acceder a secciones no permitidas
  }

  // El resto del código original sin cambios
 // console.log('handleSidebarSelect called with:', main);
  if (main === 'Inicio') {
    setActiveSection({ main: 'Inicio', sub: '' });
    setIsMobileMenuOpen(false);
  } else {
    const menuItem = allMenuItems.find(item => item.label === main);
    if (menuItem && menuItem.subItems.length > 0) {
      setOpenSidebar(prev => prev === main ? null : main); // Toggle open sidebar
    } else {
      if (activeSection.sub === 'Mis planificaciones' && hasUnsavedChanges) {
        setIsConfirmDialogOpen(true);
        setPendingNavigation({ main, sub: '', isRefresh: false });
        return;
      }
      setHasUnsavedChanges(false);
      setActiveSection({ main, sub: '' });
      setIsMobileMenuOpen(false);
    }
  }
 // console.log('After handleSidebarSelect:', { main, openSidebar: openSidebar });
};

  
// const handleSubSidebarSelect = (main: string, sub: string) => {
//   if (activeSection.sub === 'Mis planificaciones' && hasUnsavedChanges) {
//     setIsConfirmDialogOpen(true);
//     setPendingNavigation({ main, sub, isRefresh: false });
//     return;
//   }
//   setActiveSection({ main, sub });
//   setOpenMenus(prev => ({ ...prev, [main]: !prev[main] })); // Toggle sub-menu open
//   setIsMobileMenuOpen(false); // Always close mobile menu after selection
// };

// Modificar handleSubSidebarSelect
const handleSubSidebarSelect = (main: string, sub: string) => {
  // Solo aplicar restricción si NO es admin Y tiene acceso restringido
  if (hasRestrictedAccess && !isAdmin && main !== 'Biblioteca Normativa') {
    return; // No hacer nada si intenta acceder a secciones no permitidas
  }

  // El resto del código original sin cambios
  if (activeSection.sub === 'Mis planificaciones' && hasUnsavedChanges) {
    setIsConfirmDialogOpen(true);
    setPendingNavigation({ main, sub, isRefresh: false });
    return;
  }
  setActiveSection({ main, sub });
  setOpenMenus(prev => ({ ...prev, [main]: !prev[main] })); // Toggle sub-menu open
  setIsMobileMenuOpen(false); // Always close mobile menu after selection
};


/*  const handleTopMobileSelect = (main: string) => {
    console.log('handleTopMenuSelect called with:', main);
    if (main === 'Inicio') {
      setActiveSection({ main: 'Inicio', sub: '' });
      setIsMobileMenuOpen(false);
    } else {
      const menuItem = allMenuItems.find(item => item.label === main);
      if (menuItem && menuItem.subItems.length > 0) {
        
          setOpenMenu(openMenu === main ? null : main);
        
      } else {
        setActiveSection({ main, sub: '' });
        setIsMobileMenuOpen(false);
      }
    }
    console.log('After handleTopMenuSelect:', { main, openMenu: openMenu });
  };
  const handleSubMobileSelect = (main: string, sub: string) => {
    setActiveSection({ main, sub });
    
   // setIsMobileMenuOpen(false);  // Always close mobile menu after selection
  };
*/


const getCurrentItems = () => {
  const currentItems = [...topMenuItems, ...sidebarItems];
  return currentItems.find(item => item.label === activeSection.main)?.subItems || [];
};

  const toggleMenu = (label: string | null) => {
    setOpenMenu(label === openMenu ? null : label);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
      setIsSidebarOpen(false);
    }
  };
  

  const handleNavigation = useCallback((direction: 'prev' | 'next') => {
    if (activeSection.sub === 'Mis planificaciones' && hasUnsavedChanges) {
      const currentItems = getCurrentItems();
      const currentIndex = currentItems.indexOf(activeSection.sub);
      let newIndex: number;
  
      if (direction === 'next') {
        newIndex = (currentIndex + 1) % currentItems.length;
      } else {
        newIndex = (currentIndex - 1 + currentItems.length) % currentItems.length;
      }
  
      setIsConfirmDialogOpen(true);
      setPendingNavigation({ main: activeSection.main, sub: currentItems[newIndex] , isRefresh: false});
      return;
    }
    setHasUnsavedChanges(false);
    const currentItems = getCurrentItems();
    const currentIndex = currentItems.indexOf(activeSection.sub);
    let newIndex: number;

    if (direction === 'next') {
      newIndex = (currentIndex + 1) % currentItems.length;
    } else {
      newIndex = (currentIndex - 1 + currentItems.length) % currentItems.length;
    }

    setActiveSection(prev => ({ ...prev, sub: currentItems[newIndex] }));
  }, [activeSection.sub, getCurrentItems]);

  const navigationContextValue = {
    handleNavigation,
    getCurrentItems,
    activeSection,
  };



  const toggleSidebar = () => {
    setIsSidebarOpen(prevState => !prevState);
  };

  
  useEffect(() => {
    // Obtener la variable de sesión almacenada
    const firstname = sessionStorage.getItem('firstName');

    // Verificar si 'firstname' es nulo o una cadena vacía
    if (!firstname) {
      // Redirigir al login si está vacío
      handleLogout();
   //alert('ho')
    }else{
     // alert('ha')
    }
    // Si no está vacío, se queda en la página actual
  }, [navigate]);


// Add event listener for ESC key
useEffect(() => {
  const handleEscKey = (event: KeyboardEvent) => {
    if (event.key === 'Escape' && isSidebarOpen) {
      setIsSidebarOpen(false);
      setIsMobileMenuOpen(false);
    }
  };
 
  
  
  const storedFirstName = sessionStorage.getItem('firstName');
  const storedTitle = sessionStorage.getItem('title');
  const storedCargo = sessionStorage.getItem('cargo');
  const storedLastName = sessionStorage.getItem('lastName');
  const storedEmail = sessionStorage.getItem('email');

  const storedIsAdmin = sessionStorage.getItem('isAdmin');
  const storedUserProfile = sessionStorage.getItem('userProfile');
  setFirstName(storedFirstName);
  setLastName(storedLastName);
  setEmail(storedEmail);
  setTitle(storedTitle);
  const adminStatus = storedIsAdmin === '1';
  setIsAdmin(adminStatus);
  setUserProfile(storedUserProfile);
  
  

//alert(userProfile);
  if (storedUserProfile === 'biblioteca_normativa' && !adminStatus) {
    setHasRestrictedAccess(true);
    // Si no está en Biblioteca Normativa o Inicio, redirigir
    if (activeSection.main !== 'Biblioteca Normativa' && activeSection.main !== 'Inicio') {
      setActiveSection({ main: 'Biblioteca Normativa', sub: '' });
    }
  } else {
    setHasRestrictedAccess(false);
  }
    setUserProfile(storedUserProfile || '');
//alert(sessionStorage.getItem('userProfile'))




  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as Element;
  
    if (sidebarRef.current && !sidebarRef.current.contains(target)) {
      setIsSidebarOpen(false);
    }
  
    if (userMenuRef.current && !userMenuRef.current.contains(target)) {
      setIsUserMenuOpen(false);
    }
  
    if (notificationsRef.current && !notificationsRef.current.contains(target)) {
      setIsNotificationsOpen(false);
    }
  };
  
 

  const handleResize = () => {
    setIsDesktop(window.innerWidth >= 768);
  };



  
  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    
    if (currentScrollY < lastScrollY) {
      setIsHeaderVisible(true);
    } else if (currentScrollY > 100 && currentScrollY > lastScrollY) {
      setIsHeaderVisible(false);
    }
    
    setLastScrollY(currentScrollY);
  };

  window.addEventListener('scroll', handleScroll, { passive: true });

  document.addEventListener('keydown', handleEscKey);

  return () => {
    document.removeEventListener('keydown', handleEscKey);
  };
}, [isSidebarOpen,isMobileMenuOpen]);


  const menuRef = useOutsideClick(() => {
    setOpenMenu(null);
  });

  const UserMenu: React.FC = () => (
    <div ref={userMenuRef} className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-50 menu">
      <div className="py-1">
        {/* <div className="px-4 py-0 text-sm text-gray-700">¡Hola, {firstName}!</div> */}
        {/* <div className="px-4 text-sm text-red-500 text-xs">{isAdmin && <p>Administrador</p>}</div> */}
        <div className="px-4 py-0 text-sm text-gray-700">¡Hola, {firstName}!</div>
<div className="px-4 text-sm text-red-500 text-xs">{isAdmin && <p>Administrador</p>}</div>
{hasRestrictedAccess && !isAdmin && (
  <div className="px-4 text-sm text-blue-500 text-xs">Perfil: Biblioteca Normativa</div>
)}
   <button 
        onClick={() => {
          setIsUserProfileOpen(true);
          setIsUserMenuOpen(false);
        }} 
        className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
      >
        <Settings className="inline-block mr-2 h-4 w-4" />Configuración
      </button>        <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"><HelpCircle className="inline-block mr-2 h-4 w-4" />Ayuda</a>
        {/* <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"><Info className="inline-block mr-2 h-4 w-4" />Acerca de</a> */}
        <button 
        onClick={() => setIsAboutModalOpen(true)} 
        className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
      >
        <Info className="inline-block mr-2 h-4 w-4" />Acerca de
      </button>
        <button onClick={handleLogout} className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
          <LogOut className="inline-block mr-2 h-4 w-4" />Salir
        </button>
      </div>
    </div>
  );

  const ChatWindow: React.FC = () => (
    <div ref={chatRef} className={`fixed z-[9999] right-24 ${isChatMinimized ? 'bottom-4' : 'bottom-0'} w-80 ${isChatMinimized ? 'h-10' : 'h-96'} bg-white rounded-lg shadow-lg flex flex-col transition-all duration-300`}>
      <div className="p-4 bg-custom-header text-white font-bold rounded-t-lg flex justify-between items-center">
        <span><i className="fa fa-eye w-5 h-5 mr-2"></i> Chat IA</span>
        <div className="flex items-center">
          <Minimize2 onClick={() => setIsChatMinimized(!isChatMinimized)} className="cursor-pointer mr-2" />
          <X onClick={() => setIsChatOpen(false)} className="cursor-pointer" />
        </div>
      </div>
      {!isChatMinimized && (
        <>
          <div className="flex-grow p-4 overflow-y-auto">
            {/* Chat messages would go here */}
            <p>Bienvenido a Simagi. Soy tu asistente IA ¿En qué puedo ayudarte?</p>
          </div>
          <div className="p-4 border-t">
            <input type="text" placeholder="Escribe un mensaje..." className="w-full p-2 border rounded" />
          
          </div>
       
        </>
      )}
    </div>
  );

  const NotificationsMenu: React.FC = () => (
    <div ref={notificationsRef} className="${isChatMinimized ? 'bottom-0' : 'bottom-4'} absolute right-0 mt-2 w-80 bg-white rounded-md shadow-lg z-50 max-h-96 overflow-y-auto menu">
      <div className="py-1">
        <div className="px-4 py-2 font-bold text-gray-700">Notificaciones</div>
        <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
          <div className="font-bold">Nueva tarea asignada</div>
          <div className="text-xs text-gray-500">Hace 5 minutos</div>
        </a>
        <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
          <div className="font-bold">Reunión programada</div>
          <div className="text-xs text-gray-500">Hace 1 hora</div>
        </a>
        <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
          <div className="font-bold">Actualización de proyecto</div>
          <div className="text-xs text-gray-500">Ayer</div>
        </a>
      </div>
    </div>
  );





  const renderMenuItems = (items: Array<{ label: string; icon?: JSX.Element; subItems?: string[] }>) => (
    <ul>
      {items.map((item, index) => (
        <li key={index} onClick={() => handleSectionChange(item.label, '')}>
          {item.icon && <span>{item.icon}</span>}
          <span>{item.label}</span>
          {item.subItems && (
            <ul>
              {item.subItems.map((subItem, subIndex) => (
                <li key={subIndex} onClick={() => handleSectionChange(item.label, subItem)}>
                  {subItem}
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  );

  const CurrentComponent = componentMap[activeSection.main]?.[activeSection.sub] || componentMap['Inicio'][''];
  const handleSectionChange = (main: string, sub: string) => {
    setActiveSection({ main, sub });
    navigate(`/`); // Aquí debes poner la lógica para navegar a la ruta correcta
  };

 //https://simagi.cl/php/login/logout.php', {
  const handleLogout = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/login/logout.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error en la respuesta del servidor:', errorText);
        return;
      }
  
      const data = await response.json();
  
      if (data.success) {
        localStorage.removeItem('authToken');
        sessionStorage.removeItem('authToken');
        navigate('/mvp');
      } else {
        console.error('Error en el logout:', data.error);
      }
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };
  
  

  const navigateToSubComponent = (main: string, sub: string) => {
    setActiveSection({ main, sub });
    setOpenMenu(null);
    setIsMobileMenuOpen(false);
  };
  

  return (
    <NavigationContext.Provider value={navigationContextValue}>
    <div className="p-3 overflow-y-scroll h-screen w-screen fondo-imagen z-[1] bg-gray-100" style={{ backgroundImage: `url(${bgImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      {/* Header */}
      <header className={`fixed top-0 left-0 right-0 bg-custom-header z-[1] shadow-md transition-all duration-300 ease-in-out  ${isHeaderVisible ? 'opacity-100' : 'opacity-0 -translate-y-full'}`}>
        <div className="mx-auto px-4 sm:px-6 lg:px-8 py-2">
          <div className="flex justify-between items-center">
            {/* Sidebar toggle button (visible only on desktop) */}
            <button 
              onClick={toggleSidebar}
              className="hidden md:flex left-0 top-0  bg-custom-header p-2 rounded-xl hover:bg-custom-sidebar hover:text-white transition-colors duration-200"
            >
              <Menu className="text-white h-6 w-6" />
            </button>
            <div className="flex items-center">
              <div className="h-10 w-24 mr-4">
              <img 
                src={logoImage} 
                alt='Simagi' 
                className='cursor-pointer' 
                onClick={() => handleTopMenuSelect('Inicio', true)} 
                />
              </div>
{/*              <div className="relative hidden md:block">
                <input type="text" placeholder="Buscar" className="bg-gray-100 rounded-md py-2 px-4 pl-10 w-64" />
                <Search className="absolute left-3 top-2.5 text-gray-400 h-5 w-5" />
              </div>*/ }
            </div>
            {/* Desktop menu */}
            <nav className="hidden md:flex items-center" ref={menuRef}>
  {/* {topMenuItems.map((item, index) => (
    <div key={index} className="relative group mx-4 cursor-pointer bg-custom-top-menu border-white border rounded-2xl">
      <div 
        className={`flex items-center px-4 py-2 ${openMenu === item.label ? 'bg-custom-sidebar text-white rounded-2xl' : 'hover:bg-custom-sidebar hover:text-white rounded-2xl'}`}
        onClick={() => handleTopMenuSelect(item.label)}
      >
        {item.icon}
        <span className="text-sm ml-1 text-white">{item.label}</span>
        {item.subItems.length > 0 && <ChevronDown className={`h-4 text-white w-4 ml-1 transition-transform duration-200 ${openMenu === item.label ? 'transform rotate-180' : ''}`} />}
      </div>
      {openMenu === item.label && item.subItems.length > 0 && (
        <div className="absolute z-10 mt-1 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          {item.subItems.map((subItem, subIndex) => (
            <a
              key={subIndex}
              href="#"
              className={`block px-4 py-2 text-sm ${activeSection.sub === subItem ? 'bg-custom-sidebar text-white' : 'text-gray-700 hover:bg-custom-sidebar hover:text-white'}`}
              onClick={(e) => {
                e.preventDefault();
                handleSubMenuSelect(item.label, subItem);
              }}
            >
              {subItem}
            </a>
          ))}
        </div>
      )}
    </div>
  ))} */}
  {topMenuItems.map((item, index) => (
  <div key={index} className="relative group mx-4 cursor-pointer bg-custom-top-menu border-white border rounded-2xl">
    <div 
           className={`flex items-center px-4 py-2 ${
            !canAccessSection(item.label)
              ? 'opacity-50 cursor-not-allowed'  // Estilo para elementos no disponibles
              : openMenu === item.label 
                ? 'bg-custom-sidebar text-white rounded-2xl' 
                : 'hover:bg-custom-sidebar hover:text-white rounded-2xl'
          }`}
          onClick={() => canAccessSection(item.label) && handleTopMenuSelect(item.label)}
        >
      {item.icon}
      <span className="text-sm ml-1 text-white">{item.label}</span>
      {item.subItems.length > 0 && canAccessSection(item.label) && (
        <ChevronDown className={`h-4 text-white w-4 ml-1 transition-transform duration-200 ${openMenu === item.label ? 'transform rotate-180' : ''}`} />
      )}
    </div>
    {openMenu === item.label && item.subItems.length > 0 && canAccessSection(item.label) && (
      <div className="absolute z-10 mt-1 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
         {item.subItems
          .filter(subItem => {
            // Si es "Inicio" y el usuario tiene acceso restringido Y NO es admin
            if (item.label === 'Inicio' && hasRestrictedAccess && !isAdmin) {
              return subItem !== 'Estadisticas';
            }
            return true; // Mostrar todos los demás subítems
          })
          .map((subItem, subIndex) => (
          <a
            key={subIndex}
            href="#"
            className={`block px-4 py-2 text-sm ${
              !canAccessSection(item.label, subItem)
                ? 'opacity-50 cursor-not-allowed text-gray-400'
                : activeSection.sub === subItem 
                  ? 'bg-custom-sidebar text-white' 
                  : 'text-gray-700 hover:bg-custom-sidebar hover:text-white'
            }`}
            onClick={(e) => {
              e.preventDefault();
              if (canAccessSection(item.label, subItem)) {
                handleSubMenuSelect(item.label, subItem);
              }
            }}
          >
            {subItem}
          </a>
        ))}
      </div>
    )}
  </div>
))}
</nav>

            <div className="flex items-center space-x-4">
          <div className="relative">
            <Eye 
              className="h-6 w-6 text-white cursor-pointer hover:text-custom-sidebar" 
              onClick={() => setIsChatOpen(!isChatOpen)}
            />
            {isChatOpen && <ChatWindow />}
          </div>
          <div className="relative">
            <Bell 
              className="h-6 w-6 text-white cursor-pointer hover:text-custom-sidebar" 
              onClick={() => setIsNotificationsOpen(!isNotificationsOpen)}
            />
            {isNotificationsOpen && <NotificationsMenu />}
          </div>
          <div className="relative">
            <User 
              className="h-6 w-6 text-white cursor-pointer hover:text-custom-sidebar" 
              onClick={() => setIsUserMenuOpen(!isUserMenuOpen)}
            />
            {isUserMenuOpen && <UserMenu />}
          </div>
        

                    {/* Mobile menu toggle button */}
            <button
              className="md:hidden"
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            >
              <Menu className="h-6 w-6 text-white" />
            </button>
          </div>
        </div>

        </div>
      </header>
      <div className="h-16"></div>
     
      <div className="flex-grow flex">
        
        {/* Desktop Sidebar */}
        <aside
                    ref={sidebarRef}

          className={`sticky  left-0 top-20 h-full  bg-custom-sidebar rounded text-white shadow-lg transition-all duration-300 ease-in-out  ${
            isSidebarOpen ? 'md:w-64' : 'md:w-0'
          } overflow-hidden hidden md:block ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'}`}
        >
          <div className="h-full ">
          {sidebarItems.map((item, index) => (
  <div key={index} className="">
    <div
      className={`py-2 px-4 cursor-pointer flex justify-between items-center rounded-md transition-all duration-800 ease-in-out
        ${
          !canAccessSection(item.label)
            ? 'opacity-50 cursor-not-allowed'  // Estilo para elementos no disponibles
            : openSidebar === item.label 
              ? 'bg-custom-light-sim text-cyan-700' 
              : 'hover:bg-custom-header hover:text-white'
        }`}
      onClick={() => canAccessSection(item.label) && handleSidebarSelect(item.label)}
    >
      <span>{item.label}</span>
      {item.subItems.length > 0 && canAccessSection(item.label) && (
        <ChevronDown className={`h-4 w-4 transform transition-transform duration-200 ${openSidebar === item.label ? 'rotate-180' : ''}`} />
      )}
    </div>
    {openSidebar === item.label && canAccessSection(item.label) && (
      <div className="ml-4 mt-2">
        {item.subItems.map((subItem, subIndex) => (
          <div
            key={subIndex}
            className={`py-2 px-4 cursor-pointer rounded-md transition-all duration-200 ease-in-out
              ${
                !canAccessSection(item.label, subItem)
                  ? 'opacity-50 cursor-not-allowed'  // Estilo para elementos no disponibles
                  : activeSection.sub === subItem 
                    ? 'bg-custom-light-sim text-cyan-700' 
                    : 'hover:bg-custom-header hover:text-white'
              }`}
            onClick={() => canAccessSection(item.label, subItem) && handleSubSidebarSelect(item.label, subItem)}
          >
            {subItem}
          </div>
        ))}
      </div>
    )}
  </div>
))}
          </div>
        </aside>
        
    {/* Mobile Menu */}
    <div className={`fixed inset-0 bg-gray-600 bg-opacity-75 z-20 transition-opacity md:hidden ${
          isMobileMenuOpen ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'
        }`}>
          <div className="fixed inset-y-0 left-0 max-w-xs w-full bg-white shadow-xl z-30 overflow-y-auto transition transform ${
            isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full'
          }">
            <div className="p-4">
              <button
                className="absolute top-4 right-4 text-gray-600"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
              {allMenuItems.map((item, index) => (
  <div key={index} className="mb-4">
    <div
      className={`py-2 px-4 cursor-pointer flex justify-between items-center rounded-md transition-all duration-800 ease-in-out
        ${
          !canAccessSection(item.label)
            ? 'opacity-50 cursor-not-allowed'  // Estilo para elementos no disponibles
            : openMobile === item.label 
              ? 'bg-custom-sidebar text-white' 
              : 'hover:bg-blue-100 hover:text-custom-sidebar'
        }`}
      onClick={() => canAccessSection(item.label) && handleTopMobileSelect(item.label)}
    >
      <span>{item.label}</span>
      {item.subItems.length > 0 && canAccessSection(item.label) && (
        <ChevronDown className={`h-4 w-4 transform transition-transform duration-200 ${openMobile === item.label ? 'rotate-180' : ''}`} />
      )}
    </div>
    {openMobile === item.label && canAccessSection(item.label) && (
      <div className="ml-4 mt-2">
        {item.subItems.map((subItem, subIndex) => (
          <div
            key={subIndex}
            className={`py-2 px-4 cursor-pointer rounded-md transition-all duration-200 ease-in-out
              ${
                !canAccessSection(item.label, subItem)
                  ? 'opacity-50 cursor-not-allowed'  // Estilo para elementos no disponibles
                  : activeSection.sub === subItem 
                    ? 'bg-custom-sidebar text-white' 
                    : 'hover:bg-custom-sidebar hover:text-white'
              }`}
            onClick={() => canAccessSection(item.label, subItem) && handleSubMenuSelect(item.label, subItem)}
          >
            {subItem}
          </div>
        ))}
      </div>
    )}
  </div>
))}
            </div>
          </div>
          
        </div> 
  


 

      {/* Main Content */}
      {(activeSection.main !== 'Inicio' || (activeSection.main === 'Inicio' && activeSection.sub === 'Estadisticas' && !(hasRestrictedAccess && !isAdmin))) && (

        <div className={`w-full overflow-auto max-w-screen lg:pr-3 lg:pl-3 px-2 sm:px-6 lg:px-4  `}>
<div className={`w-full flex flex-col`}>
    <div className="w-full items-center">
    <ProcessSelect 
  isVisible={
    (activeSection.main === 'ANALITICAS' && 
     activeSection.sub === 'Huella digital') ||
    (activeSection.main !== 'Inicio' && 
     activeSection.main !== 'Control de Documentos' && 
     activeSection.main !== 'ADMINISTRACIÓN' && 
     activeSection.main !== 'SUPERBANDEJA' &&
     activeSection.main !== 'ANALITICAS' &&
     activeSection.main !== 'Biblioteca Normativa'
    )
  }
  selectedProcess={selectedProcess}
  processes={filteredProcesses}
  isLoading={isLoadingProcesses}
  isProcessActive={isProcessActive}
  onChange={handleProcessChange}
  onRefresh={fetchProcesses} // Pasar la función fetchProcesses

/>
    </div>
    <main
  className={`px-6 py-2 flex-1 shadow-md overflow-hidden bg-white rounded-xl transition-all duration-300 ease-in-out relative ${isSidebarOpen ? 'lg:ml-0' : 'lg:ml-0'}`}
>
  {/* Contenedor que combina flechas y título en línea */}
  <div className="flex justify-between items-center w-full p-2">
    {/* Botón flecha izquierda */}
    {!isSingleViewSection(activeSection.main) && (
      <button
        onClick={() => handleNavigation('prev')}
        className="shadow-md p-2 bg-gray-400 rounded-full text-white hover:bg-custom-sidebar hover:text-white transition-colors duration-200"
        aria-label="Previous"
      >
        <ChevronLeft className="h-12 w-12" />
      </button>
    )}
    
    {/* Título centralizado */}
    <h2 className="text-xl font-bold text-gray-800 uppercase text-left flex-grow mx-4">
      {`${activeSection.main} ➤ ${activeSection.sub}`}
    </h2>
    
    {/* Botón flecha derecha */}
    {!isSingleViewSection(activeSection.main) && (
      <button
        onClick={() => handleNavigation('next')}
        className="shadow-md p-2 bg-gray-400 rounded-full text-white hover:bg-custom-sidebar hover:text-white transition-colors duration-200"
        aria-label="Next"
      >
        <ChevronRight className="h-12 w-12" />
      </button>
    )}
  </div>

{/* Botón de actualización flotante en esquina inferior derecha */}
<motion.button
  onClick={handleRefresh}
  className="p-3 bg-teal-500 text-white rounded-full shadow-lg hover:bg-teal-600 transition fixed bottom-6 right-6 z-50"
  whileTap={{ scale: 0.9 }}
>
  <motion.div
    animate={isSpinning ? { rotate: -360 } : { rotate: 0 }}
    transition={{ duration: 1, repeat: isSpinning ? Infinity : 0, ease: "linear" }}
  >
    <RotateCcw className="h-8 w-8" />
  </motion.div>
</motion.button>

  {/* Main Content */}
  <motion.div
    key={contentKey}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.5 }}
    className="flex-grow overflow-auto mt-2"
  >
          {(() => {
      
  // Para debugging
 // console.log('Current section:', activeSection.main, activeSection.sub);
 // console.log('Component to render:', componentMap[activeSection.main]?.[activeSection.sub]);

 if (!canAccessSection(activeSection.main, activeSection.sub)) {
  return (
    <div className="p-6 text-center">
      <div className="text-red-500 text-xl mb-3">Acceso Restringido</div>
      <p className="text-gray-600">
        No tienes permisos para acceder a esta sección.
      </p>
    </div>
  );
}

  const mainSection = activeSection.main.toUpperCase();

 // 5. Asegúrate de que el renderComponent esté tipado correctamente
const renderComponent = (Component: React.ComponentType<any>) => (
  <Component
    selectedProcess={selectedProcess}
    setSelectedProcess={setSelectedProcess}
    processes={processes} // Puedes pasar todos los procesos o filteredProcesses dependiendo de tu necesidad
    isProcessActive={isProcessActive}
    navigateToSubComponent={navigateToSubComponent}
    onUnsavedChanges={handleUnsavedChanges}
    hasUnsavedChanges={hasUnsavedChanges} 
  />
);
  // Primero intentamos con el nombre exacto
  if (componentMap[activeSection.main] && componentMap[activeSection.main][activeSection.sub]) {
    return renderComponent(componentMap[activeSection.main][activeSection.sub]);
  }
  // Luego intentamos con el nombre en mayúsculas
  else if (componentMap[mainSection] && componentMap[mainSection][activeSection.sub]) {
    return renderComponent(componentMap[mainSection][activeSection.sub]);
  } else {
    return (
      <p className="text-gray-600">
        Contenido no disponible para {activeSection.sub} en {activeSection.main}.
      </p>
    );
  }
})()}
</motion.div>     {/* Page indicators */}
{!isSingleViewSection(activeSection.main) && (
  <div className="flex justify-center mt-5">
    <div className="flex items-center space-x-2">
      {getCurrentItems().map((_, index) => (
        <div
          key={index}
          className={`h-2 w-2 rounded-full ${index === getCurrentItems().indexOf(activeSection.sub) ? 'bg-custom-header' : 'bg-gray-300'}`}
        />
      ))}
    </div>
  </div>
)}
    </main>
  </div>
</div>
)}

    </div>
    </div>
    <AboutModal 
        isOpen={isAboutModalOpen} 
        onClose={() => setIsAboutModalOpen(false)} 
      />
<UserProfile 

  isOpen={isUserProfileOpen}
  onClose={() => setIsUserProfileOpen(false)}
/>

    <ConfirmDialog
  isOpen={isConfirmDialogOpen}
  onClose={handleCancelNavigation}
  onConfirm={handleConfirmNavigation}
  title="Cambios sin guardar"
  message="Hay cambios sin guardar en la planificación. ¿Estás seguro que deseas salir? Los cambios se perderán."
  confirmButtonText="Salir sin guardar"
  cancelButtonText="Cancelar"
/>
    </NavigationContext.Provider>

  );
};

export default SimagiInteractivePreview;