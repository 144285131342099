import React, { useState, useEffect, useMemo, useRef, useCallback, ChangeEvent } from 'react';
import { Card, CardHeader, CardContent } from '../ui/card';
import { Input } from '../ui/input';
import GanttChartView from './ganttChartView';
import dateUtils from './dateUtils';
import { CustomAlert } from '../ui/customAlert';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from '../ui/dialog';
import TemplateSelectionInterface from './TemplateSelectionInterface';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import { ChevronRight, ChevronDown, Plus, Trash2, ArrowUp, ArrowDown, Minimize, Maximize, ArrowLeftCircle, Save, BarChart2, GanttChart, GanttChartIcon, LucideGanttChart, GanttChartSquareIcon, FileDown, SaveAll, SaveOff, LucideSave, SaveIcon, ArrowRightIcon, SaveAllIcon, Copy, Undo, Undo2, LucideTimerReset, RefreshCcw, RefreshCwOff, RefreshCcwDotIcon, Delete, DeleteIcon, LucideDelete, Undo2Icon, PlusCircle, PlusCircleIcon, Check, AlertCircle, HelpCircle, X, Settings2, Loader2, UserIcon, Users, Search, RefreshCcwDot, AlertTriangle, CheckCircle, XCircle } from 'lucide-react';
import toast, { Toaster } from 'react-hot-toast';
import ConfirmDialog from '../ui/confirmDialog';

// Interfaces
interface SelectOption {
  value: string;
  label: string;
}

interface Template {
  id: string;
  name: string;
}


interface User {
  id: number;
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  is_admin: boolean;
  is_active: boolean;
  departamento: string;
}

interface Task {
  id: number;
  name: string;
  responsible: string; // Cambiado de User[] a string
  progress: number;
  start: string;
  end: string;
  descriptor: string;
  organism: string;
  dependsOn: { groupId: number; taskId: number }[];
  enabled: boolean;
  isClosed: boolean;
  orden: number;
  isTramitacion?: boolean;
  subtasks?: SubTask[];
  duration: number;
  hidden?: boolean;
}

interface SubTask {
  id: number;
  name: string;
  responsible: string; // Cambiado de User[] a string
  progress: number;
  start: string;
  end: string;
  duration: number;
  organism: string;
  dependsOn: { groupId: number; taskId: number; subtaskId: number }[];
  enabled: boolean;
  hidden?: boolean;
  type: 'INGRESO' | 'OBSERVACIÓN' | 'RESPUESTA' | 'RESOLUCIÓN' | 'OTROS';
  resolutionType?: string;
  orden: number;
  isClosed: boolean;
}


interface Group {
  name: string;
  expanded: boolean;
  subprocess: string;
  agrupador: string;
  tasks: Task[];
  enabled: boolean; // Nueva propiedad
  descriptor: 'GESTIÓN' | 'TRAMITOLOGÍA'; 
  organismo: string;
  orden: number;

}

interface TasksState {
  name: string;
  groups: Group[];
}

interface EditingField {
  type: 'title' | 'group' | 'task' | 'subtask' | null;
  groupIndex: number | null;
  taskIndex: number | null;
  subtaskIndex?: number | null;
  field: string | null;
}


interface LabelMessageProps {
  type: 'success' | 'error';
  message: string;
}

interface Process {
  nombreProceso: string;
  codigo: string;
  comuna: string;
  subprocesos?: string[]; // Añadimos esta propiedad
}

interface Subproceso {
  id: number;
  subproceso: string;
  color?: string; // Agregamos el campo color

}
const subtasks: [string, "INGRESO" | "OBSERVACIÓN" | "RESPUESTA" | "RESOLUCIÓN"][] = [
  ["IN", "INGRESO"],
  ["OB", "OBSERVACIÓN"],
  ["RP", "RESPUESTA"],
  ["RS", "RESOLUCIÓN"],
];

const LabelMessage: React.FC<LabelMessageProps> = ({ type, message }) => {
  const [progress, setProgress] = useState(100);
  
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress <= 0) {
          clearInterval(timer);
          return 0;
        }
        return prevProgress - 1;
      });
    }, 31); // Ajusta este valor para cambiar la velocidad de la animación

    return () => clearInterval(timer);
  }, []);

  const bgColor = type === 'success' ? 'bg-green-500' : 'bg-red-500';
  const borderColor = type === 'success' ? 'border-green-600' : 'border-red-600';
  const textColor = 'text-white';

  return (
    <div className="fixed bottom-0 left-0 right-0 z-50">
    <div className={`${bgColor} ${borderColor} ${textColor} px-4 py-3 rounded-b relative border-l-4 overflow-hidden`} role="alert">
      <span className="block sm:inline">{message}</span>
      <div 
        className={`absolute bottom-0 left-0 h-1 ${type === 'success' ? 'bg-green-700' : 'bg-red-700'}`} 
        style={{ width: `${progress}%`, transition: 'width 0.1s linear' }}
      ></div>
    </div>
  </div>
  );
};








interface ResolutionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (type: string, customType?: string) => void;
}


const ResolutionModal: React.FC<ResolutionModalProps> = ({ isOpen, onClose, onSave }) => {
  const [selectedType, setSelectedType] = useState<string>('');
  const [customType, setCustomType] = useState<string>('');

  const handleSave = () => {
    
    if (selectedType === 'OTRO' && customType) {
      onSave(selectedType, customType);
    } else if (selectedType) {
      onSave(selectedType);
    }
    onClose();
  };
  const resolutionTypes = [
    { type: 'APROBADO', icon: <Check className="w-5 h-5 text-green-500" />, color: 'bg-green-100 hover:bg-green-200' },
    { type: 'RECHAZADO', icon: <X className="w-5 h-5 text-red-500" />, color: 'bg-red-100 hover:bg-red-200' },
    { type: 'DESISTIMIENTO', icon: <AlertTriangle className="w-5 h-5 text-yellow-500" />, color: 'bg-yellow-100 hover:bg-yellow-200' },
    { type: 'SILENCIO ADMINISTRATIVO POSITIVO', icon: <CheckCircle className="w-5 h-5 text-green-500" />, color: 'bg-yellow-100 hover:bg-yellow-200' },
    { type: 'SILENCIO ADMINISTRATIVO NEGATIVO', icon: <XCircle className="w-5 h-5 text-red-500" />, color: 'bg-yellow-100 hover:bg-yellow-200' },
    { 
      type: 'NO ADMITIDO', 
      icon: (
        <div className="flex items-center">
          <svg viewBox="0 0 24 24" className="h-5 w-5 text-red-700">
            <circle cx="12" cy="12" r="10" fill="none" stroke="currentColor" strokeWidth="2"/>
            <line x1="4" y1="4" x2="20" y2="20" stroke="currentColor" strokeWidth="2"/>
          </svg>
        </div>
      ), 
      color: 'bg-red-100 hover:bg-red-200' 
    },
  ];

  
  return (
    <Dialog width='500px' isOpen={isOpen} onClose={onClose}>
      <DialogContent className="bg-white rounded-xl shadow-xl">
        <DialogHeader>
          <DialogTitle>
            <div className="text-2xl font-bold text-gray-800">Tipo de Resolución</div>
          </DialogTitle>
        </DialogHeader>
        <div className="space-y-4 mt-4">
          {resolutionTypes.map(({ type, icon, color }) => (
            <div key={type} className={`flex items-center p-3 rounded-lg transition-all duration-200 ${selectedType === type ? `${color} ring-2 ring-offset-2 ring-blue-500` : 'hover:bg-gray-100'}`}>
              <input
                type="checkbox"
                id={type}
                checked={selectedType === type}
                onChange={() => setSelectedType(type)}
                className="sr-only"
              />
              <label htmlFor={type} className="flex items-center cursor-pointer w-full">
                <div className="mr-3">{icon}</div>
                <span className="text-lg font-medium text-gray-700">{type}</span>
              </label>
            </div>
          ))}
          {selectedType === 'OTRO' && (
            <div className="mt-3 pl-8">
              <input
                type="text"
                value={customType}
                onChange={(e) => setCustomType(e.target.value)}
                placeholder="Especifique otro tipo"
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-teal-500 focus:border-transparent"
              />
            </div>
          )}
        </div>
        <DialogFooter>
          <div className="mt-6 flex justify-end space-x-2">
            <button onClick={onClose} >
              Cancelar
            </button>
            <button 
              onClick={handleSave} 
              className="bg-teal-500 text-white hover:bg-teal-600 transition-colors duration-200"
              disabled={!selectedType || (selectedType === 'OTRO' && !customType)}
            >
              Guardar
            </button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

interface TaskStatusFieldProps {
  task: {
    isClosed: boolean;
    progress: number;
  };
  subprocessColor: string;
  groupIndex: number;
  taskIndex: number;
  onSave: (type: string, groupIndex: number, taskIndex: number, field: string, value: any) => void;
  disabled?: boolean;
}

const TaskStatusField: React.FC<TaskStatusFieldProps> = ({
  task,
  subprocessColor,
  groupIndex,
  taskIndex,
  onSave,
  disabled = false
}) => {
  // Determinar el estilo basado en el estado
  const getStatusStyle = () => {
    if (task.isClosed) {
      return {
        backgroundImage: `repeating-conic-gradient(${subprocessColor}  0% 25%, #ffffff 0% 50%)`,
        backgroundSize: '10px 10px',
        border: `1px solid ${subprocessColor}`,
        borderRadius: '50%',
      };
    }
    return {
      backgroundColor: '#e5e7eb',
      border: '1px solid #9ca3af',
      borderRadius: '50%',
    };
  };

  const handleToggleStatus = () => {
    if (disabled) return;

    const newStatus = !task.isClosed;
    onSave('task', groupIndex, taskIndex, 'isClosed', newStatus);
    // También actualizar el progreso
    onSave('task', groupIndex, taskIndex, 'progress', newStatus ? 100 : 0);
  };

  return (
    <div
      onClick={handleToggleStatus}
      className={`w-6 h-6 mx-auto cursor-pointer transition-all duration-200 flex items-center justify-center hover:opacity-80 ${
        disabled ? 'opacity-50 cursor-not-allowed' : ''
      }`}
      style={getStatusStyle()}
      title={task.isClosed ? "Tarea cerrada" : "Tarea abierta"}
    >
      </div>
  );
};


// Primero, actualicemos la interfaz de props
interface SubtaskStatusFieldProps {
  subtask: {
    isClosed: boolean;
    progress: number;
  };
  subprocessColor: string;
  groupIndex: number;
  taskIndex: number;
  subtaskIndex: number;
  onSave: (type: 'title' | 'group' | 'task' | 'subtask', groupIndex: number, taskIndex: number, field: string, value: any, subtaskIndex?: number) => void;
  disabled?: boolean;
}

const SubtaskStatusField: React.FC<SubtaskStatusFieldProps> = ({
  subtask,
  subprocessColor,
  groupIndex,
  taskIndex,
  subtaskIndex,
  onSave,
  disabled = false
}) => {
  const getStatusStyle = () => {
    if (subtask.isClosed) {
      return {
        backgroundImage: `repeating-conic-gradient(${subprocessColor}  0% 25%, #ffffff 0% 50%)`,
        backgroundSize: '10px 10px',
        border: `1px solid ${subprocessColor}`,
        borderRadius: '50%',
      };
    }
    return {
      backgroundColor: '#e5e7eb',
      border: '1px solid #9ca3af',
      borderRadius: '50%',
    };
  };

  const handleToggleStatus = () => {
    if (disabled) return;
    
    const newStatus = !subtask.isClosed;
    // Asegurarse de que el tipo coincida con la definición
    onSave('subtask', groupIndex, taskIndex, 'isClosed', newStatus, subtaskIndex);
    onSave('subtask', groupIndex, taskIndex, 'progress', newStatus ? 100 : 0, subtaskIndex);

    // Si es necesario, actualizar también el estado de la tarea padre
    const allSubtasksClosed = true; // Aquí deberías implementar la lógica para verificar si todas las subtareas están cerradas
    if (allSubtasksClosed) {
      onSave('task', groupIndex, taskIndex, 'isClosed', true);
      onSave('task', groupIndex, taskIndex, 'progress', 100);
    }
  };

  return (
    <div
      onClick={handleToggleStatus}
      className={`w-6 h-6 mx-auto cursor-pointer transition-all duration-200 flex items-center justify-center hover:opacity-80 ${
        disabled ? 'opacity-50 cursor-not-allowed' : ''
      }`}
      style={getStatusStyle()}
      title={subtask.isClosed ? "Subtarea cerrada" : "Subtarea abierta"}
    />
  ); 
};

/*
interface UserSelectorModalProps {
  users: User[];
  selectedUsers: User[];
  onUsersChange: (users: User[]) => void;
  enabled?: boolean;
  taskId: number;   // Añadido para identificar la tarea
  groupId: number;
}



const UserSelectorModal: React.FC<UserSelectorModalProps> = ({
  users,
  selectedUsers: initialSelectedUsers = [],
  onUsersChange,
  enabled = true,
  taskId,
  groupId
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [localSelectedUsers, setLocalSelectedUsers] = useState<User[]>(Array.isArray(initialSelectedUsers) ? initialSelectedUsers : []);

  // Cargar usuarios asignados cuando se abre el modal
  const loadAssignedUsers = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/get_task_users.php?taskId=${taskId}&groupId=${groupId}`
      );

      if (!response.ok) {
        throw new Error('Error al cargar los usuarios asignados');
      }

      const data = await response.json();
      
      if (data.success && Array.isArray(data.users)) {
        setLocalSelectedUsers(data.users);
        onUsersChange(data.users); // Actualizar el estado del padre también
      }
    } catch (error) {
      console.error('Error loading assigned users:', error);
      toast.error('Error al cargar los usuarios asignados');
    } finally {
      setIsLoading(false);
    }
  };

  // Cargar usuarios cuando se abre el modal
  useEffect(() => {
    if (isOpen) {
      loadAssignedUsers();
    }
  }, [isOpen, taskId, groupId]);

  const handleOpenModal = () => {
    if (!enabled) return;
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setSearchTerm('');
  };

  const handleSave = async () => {
    try {
      setIsSaving(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/update_task_users.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          taskId,
          groupId,
          userIds: localSelectedUsers.map(user => user.id)
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Error al guardar los responsables');
      }

      toast.success('Responsables actualizados exitosamente');
      onUsersChange(localSelectedUsers);
      handleCloseModal();
    } catch (error) {
      console.error('Error saving users:', error);
      toast.error(error instanceof Error ? error.message : 'Error al guardar los responsables');
    } finally {
      setIsSaving(false);
    }
  };

  // Filtrado de usuarios
  const filteredUsers = useMemo(() => {
    return users.filter(user => {
      const searchString = `${user.firstname} ${user.lastname} ${user.email} ${user.departamento}`.toLowerCase();
      const searchTerms = searchTerm.toLowerCase().split(' ');
      return searchTerms.every(term => searchString.includes(term));
    });
  }, [users, searchTerm]);

  return (
    <>
  <div 
  onClick={handleOpenModal} 
  className={`cursor-pointer flex items-center ml-8 ${enabled ? 'hover:opacity-80' : 'opacity-50 cursor-not-allowed'}`}
  title={localSelectedUsers.length > 0 
    ? `Responsables: ${localSelectedUsers.map(u => `${u.firstname} ${u.lastname}`).join(', ')}` 
    : 'Sin responsables asignados'}
>
  <UserIcon 
    className={`w-5 h-5 mr-2 ${localSelectedUsers.length > 0 ? 'text-green-500' : 'text-gray-400'}`}
  />

</div>

      <Dialog isOpen={isOpen} onClose={handleCloseModal} width="600px">
        <div className="overflow-hidden">
          <div className="bg-gradient-to-r from-teal-500 to-teal-600 p-6">
            <div className="flex items-center space-x-2">
              <Users className="w-6 h-6 text-white" />
              <DialogTitle>
                <span className="text-xl font-semibold text-white">
                  Seleccionar Responsables
                </span>
              </DialogTitle>
            </div>
            <p className="text-teal-100 mt-2 text-sm">
              {localSelectedUsers.length} usuario(s) seleccionado(s)
            </p>
          </div>

          <DialogContent className="px-6 py-4">
            {isLoading ? (
              <div className="flex items-center justify-center py-8">
                <Loader2 className="w-8 h-8 animate-spin text-teal-500" />
                <span className="ml-2">Cargando usuarios...</span>
              </div>
            ) : (
              <>
                <div className="mb-6">
                  <div className="relative">
                    <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                    <input
                      type="text"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      placeholder="Buscar por nombre, email o departamento..."
                      className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                    />
                  </div>
                </div>

                {/* Sección de usuarios seleccionados }
                <div className="bg-teal-50 p-4 rounded-lg border border-teal-100 mb-4">
                  <h3 className="text-sm font-medium text-teal-800 mb-2">Usuarios Seleccionados</h3>
                  <div className="flex flex-wrap gap-2">
                    {localSelectedUsers.map((user) => (
                      <div
                        key={user.id}
                        className="inline-flex items-center gap-2 bg-white text-teal-700 px-3 py-1 rounded-full text-sm border border-teal-200 hover:bg-teal-50 transition-colors"
                      >
                        <UserIcon className="w-4 h-4" />
                        <span>{`${user.firstname} ${user.lastname}`}</span>
                        <button
                          onClick={() => setLocalSelectedUsers(prev => prev.filter(u => u.id !== user.id))}
                          className="ml-1 text-teal-400 hover:text-teal-600"
                        >
                          <X className="w-4 h-4" />
                        </button>
                      </div>
                    ))}
                    {localSelectedUsers.length === 0 && (
                      <p className="text-sm text-teal-600 italic">No hay usuarios seleccionados</p>
                    )}
                  </div>
                </div>

                {/* Lista de usuarios disponibles }
                <div className="space-y-2">
                  <h3 className="text-sm font-medium text-gray-700 mb-2">
                    {filteredUsers.length} usuario(s) encontrado(s)
                  </h3>
                  <div className="grid grid-cols-1 gap-2 max-h-[300px] overflow-y-auto">
                    {filteredUsers.map((user) => (
                      <button
                        key={user.id}
                        onClick={() => {
                          const isSelected = localSelectedUsers.some(u => u.id === user.id);
                          if (isSelected) {
                            setLocalSelectedUsers(prev => prev.filter(u => u.id !== user.id));
                          } else {
                            setLocalSelectedUsers(prev => [...prev, user]);
                          }
                        }}
                        className={`flex items-center justify-between w-full p-3 rounded-lg border transition-all ${
                          localSelectedUsers.some(u => u.id === user.id)
                            ? 'bg-teal-50 border-teal-200 text-teal-700'
                            : 'bg-white border-gray-200 hover:bg-gray-50'
                        }`}
                      >
                        <div className="flex items-center gap-3">
                          <div className="w-8 h-8 rounded-full bg-teal-100 flex items-center justify-center">
                            <UserIcon className="w-4 h-4 text-teal-600" />
                          </div>
                          <div className="flex flex-col items-start">
                            <span className="font-medium">{`${user.firstname} ${user.lastname}`}</span>
                            <span className="text-sm text-gray-500">{user.email}</span>
                          </div>
                        </div>
                        <span className="text-sm text-gray-500">{user.departamento}</span>
                      </button>
                    ))}
                  </div>
                </div>
              </>
            )}
          </DialogContent>

          <DialogFooter>
            <div className="flex justify-end gap-3 px-6 py-4 bg-gray-50 border-t border-gray-200">
              <button
                onClick={handleCloseModal}
                
                className="px-4 py-2"
                disabled={isSaving}
              >
                Cancelar
              </button>
              <button
                onClick={handleSave}
                className="bg-teal-500 text-white hover:bg-teal-600 px-4 py-2"
                disabled={isSaving || isLoading}
              >
                {isSaving ? (
                  <>
                    <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                    Guardando...
                  </>
                ) : (
                  'Confirmar Selección'
                )}
              </button>
            </div>
          </DialogFooter>
        </div>
      </Dialog>
    </>
  );
};


*/

const ResponsibleField = ({ type }: { type: 'task' | 'subtask' }) => {
  return (
    <div 
      className="flex items-center justify-center cursor-default"
      title="Los responsables se asignarán al crear la planificación"
    >
      <UserIcon className="w-5 h-5 text-gray-400 mr-2" />
   
    </div>
  );
};



interface AdvancedGanttChartProps {
  selectedProcess: string;
  setSelectedProcess: (process: string) => void;
  processes: Process[];
  navigateToSubComponent: (main: string, sub: string) => void;
  onUnsavedChanges: (hasChanges: boolean) => void;
  hasUnsavedChanges: boolean;
}
  
 

const AdvancedGanttChart: React.FC<AdvancedGanttChartProps> = ({
  navigateToSubComponent,
  onUnsavedChanges,
}) => {
  const [tasks, setTasks] = useState<TasksState>({
    name: "Plantilla 1",
    groups: []
  });
  const [editingField, setEditingField] = useState<EditingField>({ type: null, groupIndex: null, taskIndex: null, field: null });

    const [editValue, setEditValue] = useState("");
  const [isGloballyExpanded, setIsGloballyExpanded] = useState(true);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isSaveAsOpen, setIsSaveAsOpen] = useState(false);
  const [templateName, setTemplateName] = useState('');
  const [templateDescription, setTemplateDescription] = useState('');
  const [showSelection, setShowSelection] = useState(true);
  const [labelMessage, setLabelMessage] = useState<{ type: 'success' | 'error', message: string } | null>(null);
  const [saveAsError, setSaveAsError] = useState<string | null>(null);
const [isOverwriteConfirmOpen, setIsOverwriteConfirmOpen] = useState(false);
const [selectedProcess, setSelectedProcess] = useState('');
const [processes, setProcesses] = useState<Process[]>([]);
const [selectedCodigoProyecto, setSelectedCodigoProyecto] = useState('');
const [selectedNombreProyecto, setSelectedNombreProyecto] = useState('');
const [subprocesos, setSubprocesos] = useState<Subproceso[]>([]);
const [subprocessColors, setSubprocessColors] = useState<{[key: string]: string}>({});
const [users, setUsers] = useState<string>('');

const [suggestedSubprocesses, setSuggestedSubprocesses] = useState<{ [key: string]: string }>({});
const [isSimagiTemplate, setIsSimagiTemplate] = useState(false);
const [isProcessLoaded, setIsProcessLoaded] = useState(false);
const [plantillaSubprocesos, setPlantillaSubprocesos] = useState<string[]>([]);
const [showGanttModal, setShowGanttModal] = useState(false);

const initialUpdateRef = useRef(false);
const [expandedTramitacion, setExpandedTramitacion] = useState<{ [key: number]: { [key: number]: boolean } }>({});

const [resolutionModalOpen, setResolutionModalOpen] = useState(false);
const [currentResolutionSubtask, setCurrentResolutionSubtask] = useState<{ groupIndex: number, taskIndex: number, subtaskIndex: number } | null>(null);

// Estado para controlar el modal de asignación a proceso
const [isAssignToProcessModalOpen, setIsAssignToProcessModalOpen] = useState(false);

const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
const [isConfirmExitDialogOpen, setIsConfirmExitDialogOpen] = useState(false);
const [pendingAction, setPendingAction] = useState<(() => void) | null>(null);

// Agregar este efecto para detectar al cerrar la ventana
useEffect(() => {
  console.log("[DEBUG] Setting up beforeunload handler, hasUnsavedChanges:", hasUnsavedChanges);
  
  const handleBeforeUnload = (e: BeforeUnloadEvent) => {
    console.log("[DEBUG] beforeunload triggered, hasUnsavedChanges:", hasUnsavedChanges);
    if (hasUnsavedChanges) {
      const message = 'Hay cambios sin guardar. ¿Desea salir sin guardarlos?';
      e.preventDefault();
      e.returnValue = message;
      return message;
    }
  };

  window.addEventListener('beforeunload', handleBeforeUnload);
  return () => {
    console.log("[DEBUG] Removing beforeunload handler");
    window.removeEventListener('beforeunload', handleBeforeUnload);
  };
}, [hasUnsavedChanges]);

// Funciones para manejar el diálogo de confirmación
const handleConfirmExit = () => {
  if (pendingAction) {
    pendingAction();
  }
  setIsConfirmExitDialogOpen(false);
  setPendingAction(null);
};

const handleCancelExit = () => {
  setIsConfirmExitDialogOpen(false);
  setPendingAction(null);
};


// Función para abrir el modal de asignación
const handleAssignToProcess = () => {
  handleSavePlantilla()
                        setSelectedNombreProyecto("");
                        setSelectedCodigoProyecto("");
  setIsAssignToProcessModalOpen(true);
};
;
// Función para guardar los datos relacionales en el proceso
// Función corregida para evitar error de TypeScript
const handleSaveToProcess = async () => {
  // Validar que se ha seleccionado un proceso
  if (!selectedCodigoProyecto) {
    showLabelMessage('error', 'Debe seleccionar un proceso para continuar');
    return;
  }

  // Validar que todos los agrupadores tienen organismo asignado
  const missingOrganisms = tasks.groups.filter(group => !group.organismo || group.organismo.trim() === '');
  if (missingOrganisms.length > 0) {
    showLabelMessage('error', `Faltan organismos por asignar en los siguientes agrupadores: ${missingOrganisms.map(g => g.agrupador).join(', ')}`);
    return;
  }

  try {
    // Preparar los datos para enviar
    const planificacionData = {
      proceso: selectedCodigoProyecto,
      agrupadores: tasks.groups.map(group => {
        // Mapear cada agrupador
        return {
          nombre: group.agrupador,
          enabled: group.enabled,
          descriptor: group.descriptor,
          organismo: group.organismo,
          // Usar un valor por defecto si el subproceso está vacío
          subproceso_id: group.subprocess || "Sin subproceso",
          orden: group.orden,
          tareas: group.tasks.map(task => {
            // Definir el objeto de tarea con todos los campos posibles desde el principio
            // incluyendo subtasks como un array vacío por defecto
            const tareaMapeada: {
              nombre: string;
              responsable: any[];
              progreso: number;
              fecha_inicio: string;
              fecha_termino: string;
              duracion: number;
              descriptor: string;
              organismo: string;
              dependencia: number[];
              enabled: boolean;
              orden: number;
              isClosed: boolean;
              isTramitacion?: boolean;
              subtasks?: any[];
            } = {
              nombre: task.name,
              responsable: Array.isArray(task.responsible) 
                ? task.responsible.map(user => ({ id: user.id }))
                : [],
              progreso: task.progress,
              fecha_inicio: task.start,
              fecha_termino: task.end,
              duracion: calculateDuration(task.start, task.end),
              descriptor: task.descriptor,
              organismo: task.organism,
              dependencia: task.dependsOn.map(dep => dep.taskId),
              enabled: task.enabled,
              orden: task.orden,
              isClosed: task.isClosed,
              isTramitacion: task.isTramitacion,
              subtasks: [] // Inicializar con array vacío
            };

            // Agregar subtareas solo si es una tarea de tramitación y tiene subtareas
            if (task.isTramitacion && task.subtasks && task.subtasks.length > 0) {
             tareaMapeada.subtasks = task.subtasks
  .map((subtask, index) => {
    // Asignar nombre si no existe (ej: INGRESO, OBSERVACIÓN 1, RESPUESTA 1, etc.)
    let generatedName = subtask.name;
    if (!generatedName) {
      if (subtask.type === 'INGRESO') {
        generatedName = 'INGRESO';
      } else if (subtask.type === 'OBSERVACIÓN') {
        generatedName = `OBSERVACIÓN ${index}`;
      } else if (subtask.type === 'RESPUESTA') {
        generatedName = `RESPUESTA ${index}`;
      } else { 
        generatedName = `SUBTAREA ${index}`;
      }
    }
    
    const responsableMapeado = Array.isArray(subtask.responsible)
      ? subtask.responsible.map(user => ({ id: user.id }))
      : typeof subtask.responsible === 'string'
        ? subtask.responsible
        : '';

    return {
      nombre: generatedName || subtask.name || '',
      tipo: subtask.type,
      responsable: responsableMapeado,
      organismo: subtask.organism,
      progreso: subtask.progress,
      fecha_inicio: subtask.start,
      fecha_termino: subtask.end,
      orden: subtask.orden,
      duracion: subtask.duration,
      dependencias: Array.isArray(subtask.dependsOn)
        ? subtask.dependsOn.map(dep => dep.subtaskId)
        : [],
      enabled: subtask.enabled,
      isClosed: subtask.isClosed,
      resolucion_tipo: subtask.resolutionType
    };
    
  });

            }
            return tareaMapeada; 
          })
        };
      })
    };

    console.log('Datos a enviar:', JSON.stringify(planificacionData, null, 2));

    // Enviar los datos al servidor
    const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/save_relational_data.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(planificacionData),
    });

    const result = await response.json();
    
    if (result.success) {
      showLabelMessage('success', 'Planificación guardada exitosamente en el proceso');
      // Limpiar la selección de proceso
      setSelectedCodigoProyecto('');
      setSelectedNombreProyecto('');
      setIsAssignToProcessModalOpen(false);
    } else {
      throw new Error(result.error || 'Error al guardar la planificación');
    }
  } catch (error) {
    console.error('Error:', error);
    showLabelMessage('error', `Error al guardar la planificación: ${error}`);
  }
};

  // Add function to fetch users
  const fetchUsers = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/users/get_users.php`);
      if (!response.ok) throw new Error('Error fetching users');
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.error('Error fetching users:', error);
      showLabelMessage('error', 'Error al cargar la lista de usuarios');
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    // Resetear el proceso seleccionado al iniciar
    setSelectedCodigoProyecto(''); 
    setSelectedNombreProyecto('');
    setSubprocesos([]);
    
    // Luego cargar los procesos disponibles
    const fetchProcesses = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/proceso/get_processes.php?type=type3`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data: Process[] = await response.json();
        
        setProcesses(data);
      } catch (error) {
        console.error('Error fetching processes:', error);
      }
    };
    fetchProcesses();
  }, []);




// Función para asignar colores únicos a subprocesos
const assignSubprocessColors = useCallback(() => {
  const colors: {[key: string]: string} = {};
  subprocesos.forEach((subproceso, index) => {
    if (!colors[subproceso.subproceso]) {
      colors[subproceso.subproceso] = subproceso.color || "";
    }
  });
  setSubprocessColors(colors);
}, [subprocesos]);

useEffect(() => {
  assignSubprocessColors();
}, [subprocesos, assignSubprocessColors]);



const handleProcessChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  const selectedValue = event.target.value;
  if (selectedValue === "") {
    setSelectedCodigoProyecto('');
    setSelectedNombreProyecto('');
    setSubprocesos([]);
  } else {
    const selectedProcess = processes.find(p => p.nombreProceso === selectedValue);
    if (selectedProcess) {
      setSelectedCodigoProyecto(selectedProcess.codigo);
      setSelectedNombreProyecto(selectedProcess.nombreProceso);
  //    fetchSubprocesos(selectedProcess.codigo);
    }
  }
};

const handleSubprocessChange = (groupIndex: number, e: React.ChangeEvent<HTMLSelectElement>) => {
  const selectedSubprocess = e.target.value;
  setTasks(prevTasks => ({
    ...prevTasks,
    groups: prevTasks.groups.map((group, index) => 
      index === groupIndex ? { ...group, subprocess: selectedSubprocess } : group
    )
  }));
};

const renderSubprocessSelect = (group: Group, groupIndex: number) => {
  const suggestedSubprocess = suggestedSubprocesses[group.agrupador] || "";
  
  const getTextColor = (backgroundColor: string): string => {
    // Convert the hex color to RGB
    const r = parseInt(backgroundColor.slice(1, 3), 16);
    const g = parseInt(backgroundColor.slice(3, 5), 16);
    const b = parseInt(backgroundColor.slice(5, 7), 16);

    // Calculate the luminosity
    const luma = 0.299 * r + 0.587 * g + 0.114 * b;

    // Return the text color based on luminosity
    return luma < 128 ? 'white' : 'black';
};

const getSubprocessDescriptor = (group: Group): string | null => {
  if (group.tasks.length === 0) return null;
  const firstTaskDescriptor = group.tasks[0].descriptor;
  return group.tasks.every(task => task.descriptor === firstTaskDescriptor) ? firstTaskDescriptor : null;
};
const subprocessDescriptor = getSubprocessDescriptor(group);
const subprocessColor = subprocessColors[group.subprocess] || "";
const textColor: string = getTextColor(subprocessColor);

   
return (
  <div className="flex flex-col p-2 rounded-lg" style={{backgroundColor: subprocessColor}}>
        <span className={`font-bold flex mr-2 text-${textColor}`}>Subproceso:</span>
        <select
          value={group.subprocess}
          onChange={(e) => handleSubprocessChange(groupIndex, e)}
          className={`border rounded-md p-2 ${!group.enabled ? 'bg-gray-200 text-gray-500' : 'bg-white'}`}
          disabled
        >
          <option value="">Sin subproceso</option>
          {subprocesos.map((subprocess) => (
            <option key={subprocess.id} value={subprocess.subproceso}>
              {subprocess.subproceso}
            </option>
          ))}
        </select>
        {suggestedSubprocess && !group.subprocess && (
          <span className="text-sm text-gray-500 mt-1">
            Sugerencia: {suggestedSubprocess}
          </span>
        )}
      </div>
    );
  };
  const showLabelMessage = (type: 'success' | 'error', message: string, duration = 3500) => {
    setLabelMessage({ type, message });
  
    if (type === 'success') {
      toast.success(message, { duration });
    } else {
      toast.error(message, { duration });
    }
  
    setTimeout(() => {
      setLabelMessage(null);
    }, duration);
  };
  

  const [selectedProject, setSelectedProject] = useState<string>('');



  const updateProcessTemplate = async (processId: string, templateName: string) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/proceso/update_process_template.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ processId, templateName }),
      });
  
      if (!response.ok) {
        throw new Error('Error al actualizar la plantilla del proceso');
      }
  
      const result = await response.json();
      console.log('Plantilla del proceso actualizada:', result);
      showLabelMessage('success', 'Plantilla del proceso actualizada exitosamente');
    } catch (error) {
      console.error('Error:', error);
      showLabelMessage('error', 'Error al actualizar la plantilla del proceso');
    }
  };

 // Cambia las fechas de la plantilla al día de hoy, ajustando a la zona horaria local
useEffect(() => {
  if (tasks.groups.length > 0 && !initialUpdateRef.current) {
    // Obtener la fecha actual en la zona horaria local
    const today = new Date();
    
    // Ajustar la fecha actual a medianoche en la zona horaria local
    today.setHours(0, 0, 0, 0);
    
    tasks.groups.forEach((group, groupIndex) => {
      group.tasks.forEach((task, taskIndex) => {
        // Crear la fecha de la tarea manualmente en la zona horaria local
        const [year, month, day] = task.start.split('-').map(Number);
        const taskStart = new Date(year, month - 1, day); // Meses en JavaScript van de 0 a 11

        // Ajustar la fecha de inicio de la tarea a medianoche para comparar solo fechas
        taskStart.setHours(0, 0, 0, 0);
        
        // Comparar solo fechas sin tomar en cuenta la hora
        if (task.dependsOn.length === 0 && taskStart.getTime() !== today.getTime()) {
          const todayStr = today.toISOString().split('T')[0]; // Convertir la fecha actual a formato ISO
          handleDateChange(groupIndex, taskIndex, 'start', todayStr); // Cambiar la fecha de inicio
        }
      });
    });
    initialUpdateRef.current = true;
  }
}, [tasks.groups]);
const convertTemplateToTasksState = (jsonData: any): TasksState => {
  const content = typeof jsonData.contenido === 'string'
    ? JSON.parse(jsonData.contenido)
    : jsonData.contenido;

  console.log('Contenido de la plantilla en convertTemplateToTasksState:', content);

  const newSuggestedSubprocesses: { [key: string]: string } = {};
  const newPlantillaSubprocesos: string[] = [];

  const groups = content.subprocesos
    .flatMap((subproceso: any, index: number) =>
      subproceso.agrupadores.map((agrupador: any): Group => {
        const subprocessExists = subprocesos.some((sp: any) => sp.subproceso === subproceso.nombre);
        newPlantillaSubprocesos[index] = subproceso.nombre;

        if (!subprocessExists) {
          newSuggestedSubprocesses[agrupador.nombre] = subproceso.nombre;
        }

        return {
          name: agrupador.nombre,
          expanded: true,
          subprocess: subprocessExists ? subproceso.nombre : "",
          agrupador: agrupador.nombre,
          enabled: agrupador.enabled !== undefined ? agrupador.enabled : true,
          descriptor: agrupador.descriptor || 'GESTIÓN',
          organismo: agrupador.organismo || '',
          orden: agrupador.orden || 0,
          tasks: agrupador.tareas
            .map((tarea: any): Task => {
              // Determinar si es una tarea de TRAMITACIÓN
              const isTramitacion = tarea.nombre === 'TRAMITACIÓN';
              
              // Procesar subtareas si existen y es una tarea de TRAMITACIÓN
              let subtasks: SubTask[] = [];
              if (isTramitacion && Array.isArray(tarea.subtasks)) {
                subtasks = tarea.subtasks.map((subtask: any): SubTask => ({
                  id: subtask.id,
                  name: subtask.nombre || subtask.name,
                  responsible: subtask.responsable || '',
                  progress: subtask.progreso || 0,
                  start: subtask.fecha_inicio || tarea.fecha_inicio,
                  end: subtask.fecha_termino || tarea.fecha_termino,
                  duration: subtask.duracion || 1,
                  organism: subtask.organismo || tarea.organismo || '',
                  dependsOn: (subtask.dependencias || []).map((dep: any) => ({
                    groupId: index,
                    taskId: tarea.id,
                    subtaskId: typeof dep === 'number' ? dep : parseInt(dep)
                  })),
                  enabled: subtask.enabled !== undefined ? subtask.enabled : true,
                  type: subtask.tipo || 'INGRESO',
                  isClosed: subtask.isClosed !== undefined ? subtask.isClosed : false,
                  resolutionType: subtask.resolucion_tipo || '',
                  orden: subtask.orden || 0
                }));
              } else if (isTramitacion) {
                // Crear subtarea INGRESO por defecto si no hay subtareas
                subtasks = [{
                  id: 1,
                  name: 'INGRESO',
                  responsible: '',
                  progress: 0,
                  start: tarea.fecha_inicio,
                  end: tarea.fecha_termino,
                  duration: 1,
                  organism: tarea.organismo || '',
                  dependsOn: [],
                  enabled: true,
                  type: 'INGRESO',
                  isClosed: false,
                  orden: 1
                }];
              }

              return {
                id: tarea.id,
                name: tarea.nombre,
                responsible: tarea.responsable || '',
                progress: tarea.progreso || 0,
                start: tarea.fecha_inicio,
                end: tarea.fecha_termino,
                descriptor: tarea.descriptor || '',
                organism: tarea.organismo || '',
                duration: tarea.duracion || 1,
                enabled: tarea.enabled !== undefined ? tarea.enabled : true,
                isClosed: tarea.isClosed !== undefined ? tarea.isClosed : false,
                dependsOn: Array.isArray(tarea.dependencia) 
                  ? tarea.dependencia.map((id: number) => ({
                      groupId: index,
                      taskId: id
                    }))
                  : tarea.dependencia 
                    ? [{ groupId: index, taskId: tarea.dependencia }]
                    : [],
                isTramitacion,
                subtasks: isTramitacion ? subtasks : undefined,
                orden: tarea.orden || 0
              };
            })
            .sort((a: Task, b: Task) => a.orden - b.orden)
        };
      })
    )
    .sort((a: Group, b: Group) => a.orden - b.orden);

  console.log('Grupos convertidos:', groups);

  // Update states
  setSuggestedSubprocesses(newSuggestedSubprocesses);
  setPlantillaSubprocesos(newPlantillaSubprocesos);

  return {
    name: content.nombre,
    groups: groups
  };
};


  const handleProjectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const projectId = e.target.value;
    setSelectedProject(projectId);
    // Aquí podrías cargar los subprocesos correspondientes al proyecto seleccionado
  };
 

  const renderOrganismRow = (group: Group, groupIndex: number) => {
    const organism = group.tasks.length > 0 ? group.tasks[0].organism : '';
    const descriptor = group.tasks.length > 0 ? group.tasks[0].descriptor : '';
    const subprocessColor = subprocessColors[group.subprocess] || "#CCCCCC";
   // alert(descriptor)
    return (
      <div className=" ml-5 px-4 text-sm text-white">
          <div style={{ backgroundColor: darkenColor(subprocessColor, 60) }} className="text-white inline-flex items-center rounded-full px-3  text-sm font-semibold ml-2">
          <span className="mr-2  text-white">{descriptor === "TRAMITOLOGÍA" ? "Organismo de Tramitación:" : "Gestión con:"}</span>
          {renderEditableField('group', groupIndex, null, 'organism', organism)}
        </div>
      </div>
    );
  };



  const updateTemplateName = async (newName: string) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/update_template_name.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          nombreOriginal: tasks.name,
          nuevoNombre: newName,
          proceso: selectedCodigoProyecto
        }),
      });
  
      if (!response.ok) {
        throw new Error('Error al actualizar el nombre de la plantilla');
      }
  
      const result = await response.json();
      if (result.success) {
        console.log('Nombre de la plantilla actualizado:', result);
        showLabelMessage('success', 'Plantilla actualizada exitosamente');
      } else {
        throw new Error(result.error || 'Error al actualizar el nombre de la plantilla');
      }
    } catch (error) {
      console.error('Error:', error);
      showLabelMessage('error', 'Error al actualizar el nombre de la plantilla');
    }
  };

  const fetchPlantilla = async (templateName: string, templateType: 'SIMAGI' | 'custom') => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/get_plantilla.php?type=1&titulo=${encodeURIComponent(templateName)}&tipoPlantilla=${templateType}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (data.error) {
        throw new Error(data.error);
      }
      const content = typeof data.contenido === 'string' ? JSON.parse(data.contenido) : data.contenido;
      const processCode = content.proceso;
      if (processCode) {
        await updateSelectedProcess(processCode);
      }
      const convertedTasks = convertTemplateToTasksState(data);
   
      setTasks(convertedTasks);

      // Extraer y guardar los subprocesos de la plantilla
      const plantillaSubprocesos = content.subprocesos.map((subproceso: any) => subproceso.nombre);
      setPlantillaSubprocesos(plantillaSubprocesos);

    } catch (error) {
      console.error('Error fetching plantilla:', error);
    }
  };

    // Añadir un efecto para actualizar los subprocesos de los grupos cuando se carga la plantilla
    useEffect(() => {
      if (plantillaSubprocesos.length > 0 && subprocesos.length > 0) {
        setTasks(prevTasks => ({
          ...prevTasks,
          groups: prevTasks.groups.map((group, index) => {
            const plantillaSubproceso = plantillaSubprocesos[index] || "";
            const matchingSubprocess = subprocesos.find(sp => sp.subproceso === plantillaSubproceso);
            return {
              ...group,
              subprocess: matchingSubprocess ? matchingSubprocess.subproceso : ""
            };
          })
        }));
      }
    }, [plantillaSubprocesos, subprocesos]);


  const updateSelectedProcess = useCallback(async (processCode: string) => {
    const selectedProcess = processes.find(p => p.codigo === processCode);
    if (selectedProcess) {
      setSelectedCodigoProyecto(selectedProcess.codigo);
      setSelectedNombreProyecto(selectedProcess.nombreProceso);
     // await fetchSubprocesos(selectedProcess.codigo);
      setIsProcessLoaded(true);
    }
  }, [processes]);


  const handleTemplateSelected = (selection: 
    { type: 'new'; templateName: string; } | 
    { type: 'template'; templateName: string; templateType: 'SIMAGI' | 'custom' }
  ) => {
    if (selection.type === 'new') {
      setTasks({
        name: selection.templateName,
        groups: []
      });
    } else if (selection.type === 'template') {
      setTasks({
        name: selection.templateName,
        groups: []
      });
      fetchPlantilla(selection.templateName, selection.templateType);
    }
    setShowSelection(false);
  };

  const calculateDuration = (start: string, end: string): number => {
    const startDate = new Date(start + 'T00:00:00Z');
    const endDate = new Date(end + 'T00:00:00Z');
    const diffTime = Math.abs(endDate.getTime() - startDate.getTime());
    return diffTime / (1000 * 60 * 60 * 24 * 7);
  };

  const formatDuration = (duration: number): string => {
    return duration < 1 ? duration.toFixed(2) : Math.round(duration).toString();
  };

  const calculateNewEndDate = (start: string, durationWeeks: number): string => {
    const startDate = new Date(start + 'T00:00:00Z');
    const endDate = new Date(startDate.getTime() + durationWeeks * 7 * 24 * 60 * 60 * 1000);
    return endDate.toISOString().split('T')[0];
  };

  const updateGroup = (groupIndex: number, field: string, value: any) => {
    setTasks(prevTasks => ({
      ...prevTasks,
      groups: prevTasks.groups.map((group, index) => 
        index === groupIndex ? { ...group, [field]: value } : group
      )
    }));
  };

  const updateTask = (groupIndex: number, taskIndex: number, field: string, value: any) => {
    setTasks(prevTasks => ({
      ...prevTasks,
      groups: prevTasks.groups.map((group, gIndex) => 
        gIndex === groupIndex
          ? {
              ...group,
              tasks: group.tasks.map((task, tIndex) => 
                tIndex === taskIndex ? { ...task, [field]: value } : task
              )
            }
          : group
      )
    }));
  };

  const toggleGroup = (index: number) => {
    updateGroup(index, 'expanded', !tasks.groups[index].expanded);
  };

  const updateDependenciesOnMove = (groupIndex: number, oldIndex: number, newIndex: number) => {
    setTasks(prevTasks => {
      const newTasks = JSON.parse(JSON.stringify(prevTasks));
      const group = newTasks.groups[groupIndex];
      const movedTaskId = group.tasks[oldIndex].id;

      group.tasks.forEach((task: Task) => {
        task.dependsOn = task.dependsOn.map(dep => {
          if (dep.groupId === groupIndex) {
            if (dep.taskId === movedTaskId) {
              return { ...dep, taskId: group.tasks[newIndex].id };
            } else if (oldIndex < newIndex) {
              if (dep.taskId > group.tasks[oldIndex].id && dep.taskId <= group.tasks[newIndex].id) {
                return { ...dep, taskId: dep.taskId - 1 };
              }
            } else if (oldIndex > newIndex) {
              if (dep.taskId >= group.tasks[newIndex].id && dep.taskId < group.tasks[oldIndex].id) {
                return { ...dep, taskId: dep.taskId + 1 };
              }
            }
          }
          return dep;
        });
      });

      return newTasks;
    });
  };

  const isGroupNameUnique = (name: string, currentGroupIndex: number): boolean => {
    return !tasks.groups.some((group, index) => 
      index !== currentGroupIndex && group.agrupador.toUpperCase() === name.toUpperCase()
    );
  };

  const isTaskNameUnique = (name: string, groupIndex: number, currentTaskIndex: number): boolean => {
    return !tasks.groups[groupIndex].tasks.some((task, index) => 
      index !== currentTaskIndex && task.name.toUpperCase() === name.toUpperCase()
    );
  };
  
  const handleSave = (type: 'title' | 'group' | 'task' | 'subtask', groupIndex: number | null, taskIndex: number | null, field: string, value: any, subtaskIndex?: number) => {
    onUnsavedChanges(true); 
  setHasUnsavedChanges(true);
    if (type === 'title') {
      setTasks(prevTasks => ({ ...prevTasks, name: value }));
      updateTemplateName(value);
    } else if (type === 'group' && groupIndex !== null) {
      if (field === 'agrupador') {
        const newName = value.toUpperCase();
        if (!isGroupNameUnique(newName, groupIndex)) {
          showLabelMessage('error', 'El nombre del agrupador ya existe. Por favor, elija otro nombre.');
          return;
        }
        updateGroup(groupIndex, field, newName);
      } else if (field === 'organism') {
        const upperValue = value.toUpperCase();
        setTasks(prevTasks => ({
          ...prevTasks,
          groups: prevTasks.groups.map((group, index) =>
            index === groupIndex
              ? {
                  ...group,
                  organismo: upperValue, // Update the group organism to uppercase
                  tasks: group.tasks.map(task => ({
                    ...task,
                    organism: upperValue // Update task organism to uppercase
                  }))
                }
              : group
          )
        }));
      } else {
        updateGroup(groupIndex, field, value);
      }
    } else if (type === 'task' && groupIndex !== null && taskIndex !== null) {
      if (field === 'name') {
        const newName = value.toUpperCase();
        if (!isTaskNameUnique(newName, groupIndex, taskIndex)) {
          showLabelMessage('error', 'El nombre de la tarea ya existe en este grupo. Por favor, elija otro nombre.');
          return;
        }
        updateTask(groupIndex, taskIndex, field, newName);
      } else if (type === 'task' && field === 'isClosed' && groupIndex !== null && taskIndex !== null) {
        setTasks(prevTasks => {
          const newTasks = JSON.parse(JSON.stringify(prevTasks)); // Deep copy
          const group = newTasks.groups[groupIndex];
          const task = group.tasks[taskIndex];
          const currentDate = new Date().toISOString().split('T')[0];
      
          if (value) { // Si la tarea se está cerrando
            task.isClosed = true;
            task.progress = 100;
            
            // Establecer la fecha de término como la fecha actual
            if (currentDate !== task.end) {
              task.end = currentDate;
              
              // Actualizar todas las tareas que dependen de esta
              group.tasks.forEach((dependentTask: Task, dependentIndex: number) => {
                const hasDependency = dependentTask.dependsOn.some(
                  (dep: { groupId: number; taskId: number }) => 
                    dep.groupId === groupIndex && dep.taskId === taskIndex + 1
                );
      
                if (hasDependency) {
                  // La tarea dependiente debe comenzar el mismo día del cierre
                  const newStart = currentDate;
                  
                  // Mantener la duración original de la tarea dependiente
                  const originalDuration = calculateDuration(dependentTask.start, dependentTask.end);
                  
                  // Actualizar fechas de la tarea dependiente
                  dependentTask.start = newStart;
                  dependentTask.end = calculateNewEndDate(newStart, originalDuration);
      
                  // Recursivamente actualizar las tareas que dependen de esta tarea
                  const updateDependentTasks = (taskId: number) => {
                    group.tasks.forEach((t: Task, idx: number) => {
                      const depends = t.dependsOn.some(
                        (dep: { groupId: number; taskId: number }) => 
                          dep.groupId === groupIndex && dep.taskId === taskId
                      );
                      
                      if (depends) {
                        const depNewStart = group.tasks[taskId - 1].end; // Usar directamente la fecha de término
                        const depDuration = calculateDuration(t.start, t.end);
                        
                        t.start = depNewStart;
                        t.end = calculateNewEndDate(depNewStart, depDuration);
                        
                        // Continuar con la cadena de dependencias
                        updateDependentTasks(idx + 1);
                      }
                    });
                  };
      
                  // Iniciar la actualización recursiva desde la tarea dependiente
                  updateDependentTasks(dependentIndex + 1);
                }
              });
            }
          } else { // Si la tarea se está reabriendo
            task.isClosed = false;
            task.progress = 0;
            
            // Aquí podrías agregar lógica adicional para manejar la reapertura si es necesario
          }
      
          return newTasks;
        });
      }
       
       if (field === 'start' || field === 'end') {
        handleDateChange(groupIndex, taskIndex, field as 'start' | 'end', value);
      } else if (field === 'duration') {
        const newDuration = parseInt(value);
        if (!isNaN(newDuration)) {
          const task = tasks.groups[groupIndex].tasks[taskIndex];
          const newEnd = calculateNewEndDate(task.start, newDuration);
          handleDateChange(groupIndex, taskIndex, 'end', newEnd);
       //   checkDependencies(taskIndex+1, groupIndex);

        }
      } else   if (field === 'dependsOn') {
        let newDependencies: { groupId: number; taskId: number }[];
        
        if (typeof value === 'string') {
          newDependencies = value.split(',')
            .map(pos => pos.trim())
            .filter(pos => pos !== '')
            .map(pos => ({ groupId: groupIndex, taskId: parseInt(pos) }))
            .filter(dep => !isNaN(dep.taskId) && dep.taskId > 0 && dep.taskId <= tasks.groups[groupIndex].tasks.length);
        } else if (Array.isArray(value)) {
          newDependencies = value.map(pos => ({ groupId: groupIndex, taskId: parseInt(pos) }))
            .filter(dep => !isNaN(dep.taskId) && dep.taskId > 0 && dep.taskId <= tasks.groups[groupIndex].tasks.length);
        } else {
          newDependencies = [];
        }

        newDependencies = newDependencies.filter(dep => dep.taskId !== taskIndex + 1);


        


        setTasks(prevTasks => {
          const updatedTasks = JSON.parse(JSON.stringify(prevTasks));
          const task = updatedTasks.groups[groupIndex].tasks[taskIndex];


           // Si la tarea es TRAMITACIÓN, aplicamos la lógica especial
        if (task.isTramitacion) {
          task.dependsOn = newDependencies;

          if (newDependencies.length > 0) {
            // Calcular la nueva fecha de inicio para TRAMITACIÓN
            const maxEndDate = Math.max(...newDependencies.map(dep => {
              const depTask = updatedTasks.groups[dep.groupId].tasks[dep.taskId - 1];
              return new Date(depTask.end).getTime();
            }));
            const newStart = new Date(maxEndDate + 86400000).toISOString().split('T')[0]; // Añadir un día
            
            // Actualizar la fecha de inicio de TRAMITACIÓN
            task.start = newStart;
            
            
            // Actualizar las subtareas
            if (task.subtasks) {
              task.subtasks.forEach((subtask: SubTask) => {
                if (subtask.dependsOn.length === 0) {
                  const duration = calculateDuration(subtask.start, subtask.end);
                  subtask.start = newStart;
                  subtask.end = calculateNewEndDate(subtask.start, subtask.duration);
                }
              });
            }
          }
       // Asegurarse de que la fecha de término de TRAMITACIÓN sea al menos tan tardía como la última subtarea
       if (task.subtasks && task.subtasks.length > 0) {
        const latestSubtaskEnd = new Date(Math.max(...task.subtasks.map((st: SubTask) => new Date(st.end).getTime())));
        if (new Date(task.end) < latestSubtaskEnd) {
          task.end = latestSubtaskEnd.toISOString().split('T')[0];
          // Recalcular la duración de TRAMITACIÓN
          task.duration = calculateDuration(task.start, task.end);
        }
      }
      
} else {
  task.dependsOn = newDependencies;
}

          let latestEndDate: string | null = null;
          newDependencies.forEach(({ taskId }) => {
            const depTask = updatedTasks.groups[groupIndex].tasks[taskId - 1];
            if (depTask && (!latestEndDate || new Date(depTask.end) > new Date(latestEndDate))) {
              latestEndDate = depTask.end;
            }
          });

                  const originalDuration = calculateDuration(task.start, task.end);


          if (latestEndDate) {
            const newStart = addDaysToDate(latestEndDate, 1);
            task.start = newStart;
          task.end = calculateNewEndDate(newStart, originalDuration);
          }

          return updatedTasks;
        });

        // Forzar re-renderización
        setTimeout(() => setTasks(prevTasks => ({ ...prevTasks })), 0);

      } else {
        updateTask(groupIndex, taskIndex, field, value);
      }
    } else  if (type === 'subtask' && groupIndex !== null && taskIndex !== null && subtaskIndex !== undefined) {
      handleSubtaskChange(groupIndex, taskIndex, subtaskIndex, field, value);

      setTasks(prevTasks => {
        const newTasks = JSON.parse(JSON.stringify(prevTasks));
        const subtask = newTasks.groups[groupIndex].tasks[taskIndex].subtasks[subtaskIndex];
        
      if (field === 'name' || field === 'responsible' || field === 'organism') {
      subtask[field] = value;
    } else  if (field === 'isClosed') {
      setTasks(prevTasks => {
        const newTasks = { ...prevTasks };
        const task = newTasks.groups[groupIndex].tasks[taskIndex];
        task.isClosed = value;
        // Si la tarea se está cerrando, verificar sus dependencias
        if (value) {
          task.progress = 100;
          // Aquí podrías agregar lógica adicional para manejar las dependencias
        } else {
          task.progress = 0;
        }
        return newTasks;
      });
     
    } else if (field === 'dependsOn') {
      subtask.dependsOn = value.split(',').map((dep: string) => {
        const subtaskId = parseInt(dep.trim(), 10);
        return { 
          groupId: groupIndex, 
          taskId: taskIndex,
          subtaskId: subtaskId
        };
      }).filter((dep: { groupId: number; taskId: number; subtaskId: number }) => 
        dep.subtaskId > 0 && dep.subtaskId <= newTasks.groups[groupIndex].tasks[taskIndex].subtasks.length
      )
      .filter((dep: { groupId: number; taskId: number; subtaskId: number }) => dep.subtaskId !== subtaskIndex + 1);

    } else if (field === 'enabled') {
      subtask.enabled = value;
    }

  
        return newTasks;
      });
    }


    setEditingField({ type: null, groupIndex: null, taskIndex: null, field: null });
    setEditValue("");

    };


    

                      
    const handleSubtaskChange = (
      groupIndex: number, 
      taskIndex: number, 
      subtaskIndex: number, 
      field: string, 
      value: string
    ) => {
      setTasks(prevTasks => {
        const newTasks = JSON.parse(JSON.stringify(prevTasks));
        const task = newTasks.groups[groupIndex].tasks[taskIndex];
        const subtask = task.subtasks[subtaskIndex];
    
        if (field === 'start' || field === 'end') {
          const updatedDate = new Date(value);
          const otherField = field === 'start' ? 'end' : 'start';
          const otherDate = new Date(subtask[otherField]);
    
          // Verificar si TRAMITACIÓN tiene dependencias
          const tramitacionHasDependencies = task.dependsOn && task.dependsOn.length > 0;
    
          if (tramitacionHasDependencies) {
            const tramitacionStartDate = new Date(task.start);
            if (updatedDate < tramitacionStartDate) {
              showLabelMessage('error', 'La fecha de la subtarea no puede ser anterior a la fecha de inicio de TRAMITACIÓN');
              return prevTasks; // Retornar sin cambios
            }
          }
    
          // Verificar que la fecha de inicio no sea mayor que la fecha de término
          if (field === 'start' && updatedDate > otherDate) {
            showLabelMessage('error', 'La fecha de inicio no puede ser mayor a la fecha de término');
            return prevTasks;
          }
    
          // Verificar que la fecha de término no sea menor que la fecha de inicio
          if (field === 'end' && updatedDate < new Date(subtask.start)) {
            showLabelMessage('error', 'La fecha de término no puede ser menor a la fecha de inicio');
            return prevTasks;
          }
    //aca lo de q cambiaba la duracion
          // Si todas las validaciones pasan, actualizar las fechas
          subtask[field] = value;
         // subtask.duration = calculateDuration(subtask.start, subtask.end);
    
          // Si TRAMITACIÓN no tiene dependencias, actualizar las subtareas dependientes
          if (!tramitacionHasDependencies) {
            task.subtasks.forEach((dependentSubtask: SubTask, index: number) => {
              if (index !== subtaskIndex) {
                const isDependentOn = dependentSubtask.dependsOn.some(dep => dep.subtaskId === subtaskIndex + 1);
                if (isDependentOn) {
                  const duration = calculateDuration(dependentSubtask.start, dependentSubtask.end);
                  dependentSubtask.start = calculateNewEndDate(subtask.end, 1); // Comienza un día después
                  dependentSubtask.end = calculateNewEndDate(dependentSubtask.start, duration);
                }
              }
            });
          }
        } else if (field === 'duration') {
          const newDuration = parseFloat(value);
          if (!isNaN(newDuration)) {
            subtask.duration = newDuration;
            subtask.end = calculateNewEndDate(subtask.start, newDuration);
            updateTramitacionSubtasksStartDate(task);
         //   const shift = Math.floor((updatedDate.getTime() - oldDate.getTime()) / (1000 * 60 * 60 * 24));
        
            updateDependentTasks(newTasks.groups, groupIndex, taskIndex + 1, 0);
//aca el cambio de las dependencias 
          }
        //  alert('aca')

        } else if (field === 'dependsOn') {
          // Actualización de dependencias
          subtask.dependsOn = value.split(',').map((dep: string) => {
            const subtaskId = parseInt(dep.trim(), 10);
            return { groupId: groupIndex, taskId: taskIndex, subtaskId: subtaskId };
          }).filter((dep: { groupId: number, taskId: number, subtaskId: number }) => 
            dep.subtaskId > 0 && dep.subtaskId <= task.subtasks.length && dep.subtaskId !== subtaskIndex + 1
          );
    
          // Actualizar la fecha de inicio basada en las dependencias
          if (subtask.dependsOn.length > 0) {
            const maxEndDate = Math.max(...subtask.dependsOn.map((dep: { subtaskId: number }) => {
              const depSubtask = task.subtasks[dep.subtaskId - 1];
              return new Date(depSubtask.end).getTime();
            }));
            const newStart = new Date(maxEndDate + 86400000).toISOString().split('T')[0]; // Añadir un día
            if (new Date(newStart) > new Date(subtask.start)) {
              subtask.start = newStart;
              subtask.end = calculateNewEndDate(subtask.start, subtask.duration);
            }
          }
        } else {
          // Otros campos
          subtask[field] = value;
        }
    
        updateTramitacionDates(newTasks, groupIndex, taskIndex);
        return newTasks;
      });
    };


  const handleOverwriteConfirm = () => {
    setIsOverwriteConfirmOpen(false);
    handleSaveAs(true);
  };
  
  const handleOverwriteCancel = () => {
    setIsOverwriteConfirmOpen(false);
  };


  const handleDescriptorChange = (groupIndex: number, newDescriptor: 'GESTIÓN' | 'TRAMITOLOGÍA') => {
    setTasks(prevTasks => {
      const group = prevTasks.groups[groupIndex];
      const newTaskName = generateUniqueTaskName(group.tasks);
      const newOrder = Math.max(...group.tasks.map(t => t.orden || 0), 0) + 1;
  
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const startDate = today.toISOString().split('T')[0];
      
      const oneWeekLater = new Date(today);
      oneWeekLater.setDate(today.getDate() + 7);
      const endDate = oneWeekLater.toISOString().split('T')[0];
  
      const newTask: Task = {
        id: group.tasks.length + 1,
        name: 'TRAMITACIÓN',
        responsible: '',
        progress: 0,
        start: startDate,
        end: endDate,
        descriptor: 'TRAMITOLOGÍA',
        duration: 1,
        isClosed: false,
        organism: group.organismo,
        dependsOn: [],
        enabled: true,
        isTramitacion: true,
        subtasks: [{
          id: 1,
          name: 'INGRESO',
          responsible: '',
          progress: 0,
          start: startDate,
          end: endDate,
          duration: 1,
          organism: group.organismo,
          dependsOn: [],
          enabled: true,
          isClosed: false,
          hidden: false,
          type: 'INGRESO',
          orden: 1
        }],
       
        orden: newOrder
      };
  
      const updatedGroup = {
        ...group,
        descriptor: newDescriptor,
        tasks: newDescriptor === 'TRAMITOLOGÍA'
          ? group.tasks
              .map(task => task.name === 'TRAMITACIÓN' ? { ...task, hidden: false } : task)
              .concat(group.tasks.some(task => task.name === 'TRAMITACIÓN') ? [] : [newTask])
          : group.tasks.map(task => 
              task.name === 'TRAMITACIÓN'
                ? { ...task, hidden: true, descriptor: newDescriptor }
                : { ...task, descriptor: newDescriptor }
            )
      };
  
      return {
        ...prevTasks,
        groups: prevTasks.groups.map((g, idx) => (idx === groupIndex ? updatedGroup : g))
      };
    });
  };
  

  const addTramitacionTask = (groupIndex: number) => {
    setTasks(prevTasks => {
      const newTasks = JSON.parse(JSON.stringify(prevTasks));
      const group = newTasks.groups[groupIndex];
      
      // Obtener la fecha actual en formato YYYY-MM-DD
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const todayFormatted = today.getFullYear() + '-' + 
        String(today.getMonth() + 1).padStart(2, '0') + '-' + 
        String(today.getDate()).padStart(2, '0');
      
      // Crear fecha una semana después para la fecha de término
      const oneWeekAfter = new Date(today);
      oneWeekAfter.setDate(today.getDate() + 7);
      const endDate = oneWeekAfter.getFullYear() + '-' + 
        String(oneWeekAfter.getMonth() + 1).padStart(2, '0') + '-' + 
        String(oneWeekAfter.getDate()).padStart(2, '0');
  
      const newTask: Task = {
        id: group.tasks.length + 1,
        name: 'TRAMITACIÓN',
        responsible: '',
        progress: 0,
        start: todayFormatted,
        end: endDate,
        descriptor: 'TRAMITOLOGÍA',
        duration: 1,
        organism: group.organismo,
        dependsOn: [],
        enabled: true,
        isTramitacion: true,
        orden: Math.max(...group.tasks.map((task: Task) => task.orden || 0), 0) + 1,
        isClosed: false,
        subtasks: [{
          id: 1,
          name: 'INGRESO',
          responsible: '', 
          progress: 0,
          start: todayFormatted,
          end: endDate,
          duration: 1,
          organism: group.organismo,
          dependsOn: [],
          enabled: true,
          type: 'INGRESO',
          orden: 1,
          isClosed: false,
        }]
      };
  
      group.tasks.push(newTask);
      return newTasks;
    });
  };
  
  const addSpecificSubtask = (groupIndex: number, taskIndex: number, type: 'INGRESO' | 'OBSERVACIÓN' | 'RESPUESTA' | 'RESOLUCIÓN') => {
    setTasks(prevTasks => {
      // Marcar cambios sin guardar
      onUnsavedChanges(true);
      setHasUnsavedChanges(true);
      
      const newTasks = JSON.parse(JSON.stringify(prevTasks));
      const task = newTasks.groups[groupIndex].tasks[taskIndex];
      
      // Verificar si la tarea TRAMITACIÓN está deshabilitada
      if (task.isTramitacion && !task.enabled) {
        toast.custom((t) => (
          <div className={`bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-3 rounded shadow-md`} role="alert">
            <div className="flex items-center">
              <AlertTriangle className="w-5 h-5 mr-2 text-yellow-700" />
              <p className="text-sm"> No se pueden añadir subtareas a una tarea TRAMITACIÓN deshabilitada</p>
            </div>
          </div>
        ), {
          duration: 4000
        });
        return prevTasks; // Retornar sin cambios
      }
      
      // Inicializar subtasks si no existe
      if (!task.subtasks) task.subtasks = [];
      
      // VALIDACIÓN 1: Solo una subtarea INGRESO
      if (type === 'INGRESO' && task.subtasks.some((st: SubTask) => st.type === 'INGRESO')) {
        showLabelMessage('error', 'Ya existe una subtarea de INGRESO');
        return prevTasks;
      }
      
      // VALIDACIÓN 2: No permitir agregar subtareas después de RESOLUCIÓN
      const resolutionIndex = task.subtasks.findIndex((st: SubTask) => st.type === 'RESOLUCIÓN');
      if (resolutionIndex !== -1) {
        showLabelMessage('error', 'No se pueden agregar más subtareas después de una RESOLUCIÓN');
        return prevTasks;
      }
      
      // VALIDACIÓN 3: No permitir dos subtareas consecutivas del mismo tipo
      if (task.subtasks.length > 0) {
        const lastSubtask = task.subtasks[task.subtasks.length - 1];
        if (lastSubtask.type === type) {
          showLabelMessage('error', `No se pueden agregar dos subtareas consecutivas de tipo ${type}`);
          return prevTasks;
        }
      }
      
      // Obtener la fecha actual
      const currentDate = dateUtils.getTodayString();
      
      // Cerrar la subtarea anterior si existe
      if (task.subtasks.length > 0) {
        const lastSubtask = task.subtasks[task.subtasks.length - 1];
        if (!lastSubtask.isClosed) {
          lastSubtask.end = currentDate;  // La subtarea anterior termina hoy
          lastSubtask.isClosed = true;
          lastSubtask.progress = 100;
        }
      }
      
      // La nueva subtarea comienza en la misma fecha actual
      const formattedEndDate = dateUtils.addDaysToDate(currentDate, 7);
      
      // Calcular el nombre mostrado para OBSERVACIÓN o RESPUESTA
      let displayName: string = type;
      if (type === 'OBSERVACIÓN' || type === 'RESPUESTA') {
        const similarTypeCount = task.subtasks.filter((st: SubTask) => st.type === type).length + 1;
        displayName = `${type} ${similarTypeCount}`;
      }
      
      // Configurar dependencias
      const dependsOn = task.subtasks.length === 1
        ? [{ groupId: groupIndex, taskId: taskIndex, subtaskId: 1 }] // Segunda subtarea depende de ID 1
        : task.subtasks.length > 1
          ? [{ groupId: groupIndex, taskId: taskIndex, subtaskId: task.subtasks[task.subtasks.length - 1].id }]
          : [];
      
      // Crear nueva subtarea
      const newSubtask: SubTask = {
        id: task.subtasks.length + 1,
        name: displayName,
        responsible: task.responsible,
        progress: 0,
        start: currentDate,  // Comienza hoy
        end: formattedEndDate,
        duration: 1,
        organism: task.organism,
        dependsOn: dependsOn,
        enabled: true,
        isClosed: false,
        type: type,
        orden: task.subtasks.length + 1,
      };
      
      task.subtasks.push(newSubtask);
      
      // Actualizar las fechas de la tarea TRAMITACIÓN
      const enabledSubtasks = task.subtasks.filter((st:SubTask) => st.enabled);
      if (enabledSubtasks.length > 0) {
        const earliestStart = enabledSubtasks[0].start;  // La primera subtarea marca el inicio
        const latestEnd = enabledSubtasks[enabledSubtasks.length - 1].end;  // La última marca el fin
        
        task.start = earliestStart;
        task.end = latestEnd;
      }
      
      return newTasks;
    });
  };
  
                       

  const updateSubtasksStartDate = (task: Task) => {
    if (task.subtasks) {
      task.subtasks.forEach(subtask => {
        if (subtask.dependsOn.length === 0) {
          const duration = calculateDuration(subtask.start, subtask.end);
          subtask.start = task.start;
          subtask.end = calculateNewEndDate(subtask.start, subtask.duration);
        }
      });
    }
  };
  


  
const updateSubsequentTasks = (tasks: TasksState, groupIndex: number, tramitacionIndex: number) => {
const group = tasks.groups[groupIndex];
const tramitacionTask = group.tasks[tramitacionIndex];
const tramitacionEndDate = new Date(tramitacionTask.end);

for (let i = tramitacionIndex + 1; i < group.tasks.length; i++) {
const task = group.tasks[i];
const taskStartDate = new Date(task.start);

// Verificar si la tarea depende directamente de TRAMITACIÓN
const dependsOnTramitacion = task.dependsOn.some(dep => 
dep.groupId === groupIndex && dep.taskId === tramitacionIndex + 1
);

if (dependsOnTramitacion && taskStartDate <= tramitacionEndDate) {
// Mover la tarea para que comience un día después del fin de TRAMITACIÓN
const newStartDate = new Date(tramitacionEndDate);
newStartDate.setDate(newStartDate.getDate() + 1);

const duration = calculateDuration(task.start, task.end);
task.start = newStartDate.toISOString().split('T')[0];
task.end = calculateNewEndDate(task.start, duration);
}
}
};

const moveSubtask = (groupIndex: number, taskIndex: number, subtaskIndex: number, direction: 'up' | 'down') => {
setTasks(prevTasks => {
const newTasks = JSON.parse(JSON.stringify(prevTasks));
const task = newTasks.groups[groupIndex].tasks[taskIndex];

if (!task.subtasks || task.subtasks.length < 2) return prevTasks;

if (direction === 'up' && subtaskIndex > 0) {
[task.subtasks[subtaskIndex], task.subtasks[subtaskIndex - 1]] = [task.subtasks[subtaskIndex - 1], task.subtasks[subtaskIndex]];
[task.subtasks[subtaskIndex].orden, task.subtasks[subtaskIndex - 1].orden] = [task.subtasks[subtaskIndex - 1].orden, task.subtasks[subtaskIndex].orden];
} else if (direction === 'down' && subtaskIndex < task.subtasks.length - 1) {
[task.subtasks[subtaskIndex], task.subtasks[subtaskIndex + 1]] = [task.subtasks[subtaskIndex + 1], task.subtasks[subtaskIndex]];
[task.subtasks[subtaskIndex].orden, task.subtasks[subtaskIndex + 1].orden] = [task.subtasks[subtaskIndex + 1].orden, task.subtasks[subtaskIndex].orden];
}

updateTramitacionDates(newTasks, groupIndex, taskIndex);
return newTasks;
});
};

const addSubtask = (groupIndex: number, taskIndex: number) => {
setTasks(prevTasks => {
const newTasks = JSON.parse(JSON.stringify(prevTasks));
const task = newTasks.groups[groupIndex].tasks[taskIndex];

if (!task.subtasks) task.subtasks = [];

// Obtener la fecha de inicio de "TRAMITACIÓN" en el formato YYYY-MM-DD
const [year, month, day] = task.start.split('-').map(Number);
const tramitacionStartDate = new Date(year, month - 1, day); // Crear la fecha de "TRAMITACIÓN"

// Calcular la fecha de término sumando 7 días
const oneWeekLater = new Date(tramitacionStartDate);
oneWeekLater.setDate(tramitacionStartDate.getDate() + 7); // Sumar 7 días a la fecha de inicio

// Ajustar ambas fechas a medianoche (00:00:00) para comparar solo las fechas
tramitacionStartDate.setHours(0, 0, 0, 0);
oneWeekLater.setHours(0, 0, 0, 0);

// Formatear las fechas a 'YYYY-MM-DD'
const start = `${tramitacionStartDate.getFullYear()}-${String(tramitacionStartDate.getMonth() + 1).padStart(2, '0')}-${String(tramitacionStartDate.getDate()).padStart(2, '0')}`;
const end = `${oneWeekLater.getFullYear()}-${String(oneWeekLater.getMonth() + 1).padStart(2, '0')}-${String(oneWeekLater.getDate()).padStart(2, '0')}`;

const newSubtask: SubTask = {
id: task.subtasks.length + 1,
name: `Subtarea ${task.subtasks.length + 1}`,
responsible: '',
progress: 0,
start: start, // Fecha de inicio es la misma que la de "TRAMITACIÓN"
end: end,     // Fecha de término es una semana después
duration: 1,  // Duración de 1 semana
organism: task.organism,
dependsOn: [],
enabled: true,
isClosed: false,
type: 'RESOLUCIÓN',
orden: task.subtasks.length + 1 // Añade esta línea


};

task.subtasks.push(newSubtask);
updateTramitacionDates(newTasks, groupIndex, taskIndex);
return newTasks;
});
};



const deleteSubtask = (groupIndex: number, taskIndex: number, subtaskIndex: number) => {
setTasks(prevTasks => {
const newTasks = JSON.parse(JSON.stringify(prevTasks));
const task = newTasks.groups[groupIndex].tasks[taskIndex];
task.subtasks.splice(subtaskIndex, 1);

// Reasignar IDs
task.subtasks.forEach((subtask: Task, index: number) => {
subtask.id = index + 1;
});

updateTramitacionDates(newTasks, groupIndex, taskIndex);
return newTasks;
});
};



// Fixed handleSubtaskToggle function


const handleSubtaskToggle = (groupIndex: number, taskIndex: number, subtaskIndex: number) => {
  // Marcar cambios sin guardar
  onUnsavedChanges(true); 
  setHasUnsavedChanges(true);

  setTasks(prevTasks => {
    const newTasks = JSON.parse(JSON.stringify(prevTasks));
    const subtask = newTasks.groups[groupIndex].tasks[taskIndex].subtasks[subtaskIndex];
    subtask.enabled = !subtask.enabled;
    
    // Actualizar las fechas de TRAMITACIÓN después de habilitar/deshabilitar una subtarea
    updateTramitacionDates(newTasks, groupIndex, taskIndex);
    return newTasks;
  });
};

useEffect(() => {
  // This useEffect will run when the component mounts, clearing the selected process
  setSelectedCodigoProyecto('');
  setSelectedNombreProyecto('');
  setSubprocesos([]);
  
  const fetchProcesses = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/proceso/get_processes.php?type=type3`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data: Process[] = await response.json();
      
      setProcesses(data);
    } catch (error) {
      console.error('Error fetching processes:', error);
    }
  };
  fetchProcesses();
}, []);

const topologicalSort = (subtasks: SubTask[]): SubTask[] => {
  const sorted: SubTask[] = [];
  const visited = new Set<number>();

  const visit = (subtask: SubTask) => {
    if (visited.has(subtask.id)) return;
    visited.add(subtask.id);
    subtask.dependsOn.forEach(dep => {
      const depSubtask = subtasks.find(st => st.id === dep.subtaskId);
      if (depSubtask) visit(depSubtask);
    });
    sorted.unshift(subtask);
  };

  subtasks.forEach(subtask => {
    if (!visited.has(subtask.id)) visit(subtask);
  });

  return sorted;
};

const updateTramitacionSubtasksStartDate = (task: Task) => {
  if (!task.subtasks || task.subtasks.length === 0) return;
  
  const startDate = new Date(task.start);
  let currentDate = new Date(startDate);

  task.subtasks.forEach((subtask) => {
    if (!subtask.dependsOn || subtask.dependsOn.length === 0) {
      const duration = calculateDuration(subtask.start, subtask.end);
      subtask.start = currentDate.toISOString().split('T')[0];
      subtask.end = calculateNewEndDate(subtask.start, duration);
      
      currentDate = new Date(subtask.end);
      currentDate.setDate(currentDate.getDate() + 1);
    }
  });
};

// Updated updateTramitacionDates function to handle invalid dates
const updateTramitacionDates = (tasks: TasksState, groupIndex: number, taskIndex: number) => {
  const group = tasks.groups[groupIndex];
  const task = group.tasks[taskIndex];

  if (!task.isTramitacion || !task.subtasks || task.subtasks.length === 0) return;

  // Filtrar subtareas habilitadas
  const enabledSubtasks = task.subtasks.filter(st => st.enabled);
  if (enabledSubtasks.length === 0) return;

  // Encontrar la fecha de inicio más temprana entre las subtareas
  const earliestStart = new Date(Math.min(
    ...enabledSubtasks.map(st => new Date(st.start).getTime())
  ));

  // Encontrar la fecha de término más tardía entre las subtareas
  const latestEnd = new Date(Math.max(
    ...enabledSubtasks.map(st => new Date(st.end).getTime())
  ));

  // Actualizar las fechas de TRAMITACIÓN
  task.start = earliestStart.toISOString().split('T')[0];
  task.end = latestEnd.toISOString().split('T')[0];

  // Si TRAMITACIÓN tiene dependencias, asegurarse que respete la fecha mínima
  if (task.dependsOn && task.dependsOn.length > 0) {
    const maxDependencyEnd = Math.max(...task.dependsOn.map(dep => {
      const depTask = group.tasks[dep.taskId - 1];
      return new Date(depTask.end).getTime();
    }));

    const minPossibleStart = new Date(maxDependencyEnd + 86400000); // Un día después
    
    if (earliestStart < minPossibleStart) {
      // Si la fecha más temprana de las subtareas es anterior a la fecha mínima posible
      const daysDifference = (minPossibleStart.getTime() - earliestStart.getTime()) / (1000 * 60 * 60 * 24);
      
      // Ajustar todas las fechas de las subtareas
      enabledSubtasks.forEach(subtask => {
        const subtaskStart = new Date(subtask.start);
        const subtaskEnd = new Date(subtask.end);
        
        subtaskStart.setDate(subtaskStart.getDate() + daysDifference);
        subtaskEnd.setDate(subtaskEnd.getDate() + daysDifference);
        
        subtask.start = subtaskStart.toISOString().split('T')[0];
        subtask.end = subtaskEnd.toISOString().split('T')[0];
      });

      // Actualizar las fechas de TRAMITACIÓN nuevamente
      task.start = minPossibleStart.toISOString().split('T')[0];
      task.end = new Date(latestEnd.getTime() + daysDifference * 86400000)
        .toISOString().split('T')[0];
    }
  }

  // Actualizar la duración de TRAMITACIÓN
  task.duration = calculateDuration(task.start, task.end);
};

 // Update the handleSaveToBackend function
 const handleSaveToBackend = async () => {
  try {
    const plantillaData = {
      nombre: tasks.name,
      descripcion: "Plantilla actualizada",
      contenido: JSON.stringify({
        nombre: tasks.name,
        proceso: selectedCodigoProyecto,
        subprocesos: tasks.groups.map(group => ({
          nombre: group.subprocess,
          agrupadores: [{
            nombre: group.agrupador,
            enabled: group.enabled, 
            descriptor: group.descriptor, 
            organismo: group.organismo,
            tareas: group.tasks.map(task => ({
              id: task.id,
              nombre: task.name,
              responsable: task.responsible,
              progreso: task.progress,
              fecha_inicio: task.start,
              fecha_termino: task.end,
              descriptor: task.descriptor,
              organismo: task.organism,
              dependencia: task.dependsOn.length > 0 ? task.dependsOn[0].taskId : null,
              enabled: task.enabled
            }))
          }]
        }))
      }),
      tipo: 'custom',
      proceso: selectedCodigoProyecto
    };

    //  alert(JSON.stringify(plantillaData))
      console.log(JSON.stringify(plantillaData))
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/update_plantilla.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(plantillaData),
      });
  
      if (!response.ok) {
        throw new Error('Error al actualizar la plantilla');
      }
  
      const result = await response.json();

      console.log('Plantilla actualizada:', result);
      showLabelMessage('success', 'Plantilla guardada exitosamente');
    } catch (error) {
      console.error('Error:', error);
      showLabelMessage('error', 'Error al guardar la plantilla');
    }
  };
  

  const findTaskById = (groupIndex: number, taskId: number): Task | null => {
    const group = tasks.groups[groupIndex];
    if (group) {
      return group.tasks.find(task => task.id === taskId) || null;
    }
    return null;
  };

  const addDaysToDate = (dateString: string, daysToAdd: number): string => {
    const date = new Date(dateString);
    date.setDate(date.getDate() + daysToAdd);
    return date.toISOString().split('T')[0];
  };

  const updateDependentTasks = (
    updatedGroups: Group[],
    changedGroupIndex: number,
    changedTaskPosition: number,
    shift: number
  ) => {
    const updateTask = (group: Group, task: Task, taskIndex: number) => {
      // Si la tarea tiene más de una dependencia
      if (task.dependsOn.length > 1) {
        const dependencies = task.dependsOn.map(dep => dep.taskId);
  
        // Encuentra la fecha de término más tardía entre las dependencias
        const getMaxEndDate = (dependencies: number[]): Date | null => {
          const dates = dependencies
            .map(depIndex => {
              const predecessorTask = group.tasks[depIndex - 1]; // Índices basados en 1
              return predecessorTask ? new Date(predecessorTask.end) : null;
            })
            .filter(Boolean) as Date[]; // Filtrar nulos
  
          return dates.length > 0 ? new Date(Math.max(...dates.map(date => date.getTime()))) : null;
        };
  
        const maxDependencyEndDate = getMaxEndDate(dependencies);
  
        if (maxDependencyEndDate) {
          const newStartDate = new Date(maxDependencyEndDate);
          newStartDate.setDate(newStartDate.getDate() + 1); // La nueva fecha de inicio es el día siguiente a la última fecha de término
  
          const currentStartDate = new Date(task.start);
  
          // Actualiza la tarea si la nueva fecha es diferente a la actual (mayor o menor)
          if (newStartDate.getTime() !== currentStartDate.getTime()) {
            const newStart = newStartDate.toISOString().split('T')[0];
  
            // Calculamos la duración ANTES de cambiar las fechas
            const duration = calculateDuration(task.start, task.end);
  
            // Calculamos la nueva fecha de término manteniendo la duración
            const newEnd = calculateNewEndDate(newStart, duration);
  
            // Actualizamos la tarea
            task.start = newStart;
            task.end = newEnd;
          }
        }
      } else if (task.dependsOn.length === 1) {
        // Si solo tiene una dependencia
        const dependency = task.dependsOn[0].taskId;
  
        const predecessorTask = group.tasks[dependency - 1]; // Índices basados en 1
        if (predecessorTask) {
          const maxDependencyEndDate = new Date(predecessorTask.end);
          const newStartDate = new Date(maxDependencyEndDate);
          newStartDate.setDate(newStartDate.getDate() + 1); // La nueva fecha de inicio es el día siguiente a la fecha de término de la dependencia
  
          const currentStartDate = new Date(task.start);
  
          // Actualiza la tarea si la nueva fecha es diferente a la actual (mayor o menor)
          if (newStartDate.getTime() !== currentStartDate.getTime()) {
            const newStart = newStartDate.toISOString().split('T')[0];
  
            // Calculamos la duración ANTES de cambiar las fechas
            const duration = calculateDuration(task.start, task.end);
  
            // Calculamos la nueva fecha de término manteniendo la duración
            const newEnd = calculateNewEndDate(newStart, duration);
  
            // Actualizamos la tarea
            task.start = newStart;
            task.end = newEnd;
          }
        }
      }
  
      // Luego de actualizar la tarea, debemos actualizar las tareas que dependen de esta
      group.tasks.forEach((dependentTask, idx) => {
        // Verificar si esta tarea depende de la tarea actual (taskIndex + 1)
        const isDependent = dependentTask.dependsOn.some(dep => dep.taskId === taskIndex + 1);
        if (isDependent) {
          // Recursión: actualizamos las tareas dependientes
          updateTask(group, dependentTask, idx);
        }
      });
    };
  
    // Buscamos el grupo que ha sido modificado
    const group = updatedGroups[changedGroupIndex];
    if (!group) {
      console.error(`No se encontró el grupo con índice ${changedGroupIndex}`);
      return;
    }
  
    // Iteramos sobre todas las tareas del grupo para actualizar las dependientes
    group.tasks.forEach((task, taskIndex) => {
      updateTask(group, task, taskIndex);
    });
  };




  
  const renderEditableField = (
    type: 'title' | 'group' | 'task' | 'subtask',
    groupIndex: number | null,
    taskIndex: number | null,
    field: string,
    value: any,
    subtaskIndex?: number
  ) => {
    const isEditing = editingField.type === type &&
                      editingField.groupIndex === groupIndex &&
                      editingField.taskIndex === taskIndex &&
                      editingField.subtaskIndex === subtaskIndex &&
                      editingField.field === field;
  
    const group = groupIndex !== null ? tasks.groups[groupIndex] : null;
    const task = taskIndex !== null && group ? group.tasks[taskIndex] : null;
    const subtask = subtaskIndex !== undefined && task?.subtasks ? task.subtasks[subtaskIndex] : null;
  
    const isTaskClosed = type === 'task' && taskIndex !== null &&
                         tasks.groups[groupIndex!].tasks[taskIndex].isClosed;
  
    const isDisabled = (type === 'group' && !group?.enabled) || 
                       (type === 'task' && (!group?.enabled || !task?.enabled || isTaskClosed)) ||
                       (type === 'task' && task?.isTramitacion && (field === 'start' || field === 'end' || field === 'duration'));
  
    const startEditing = () => {
      if (isDisabled) return;
  
      let initialValue = value;
      if ((type === 'task' || type === 'subtask') && (field === 'start' || field === 'end')) {
        initialValue = formatDateForEdit(value);
      } else if (field === 'dependsOn') {
        if (type === 'subtask' && subtask) {
          initialValue = Array.isArray(value) ? value.map(dep => dep.subtaskId).join(', ') : value;
        } else {
          initialValue = Array.isArray(value) ? value.map(dep => dep.taskId).join(', ') : value;
        }
      } else if (field === 'duration') {
        if (type === 'task' && task) {
          initialValue = formatDuration(calculateDuration(task.start, task.end));
        } else if (type === 'subtask' && subtask) {
          initialValue = formatDuration(calculateDuration(subtask.start, subtask.end));
        }
      }
      
      // Ensure the initial value is uppercase for certain fields like organism
      if (field === 'organism' || field === 'name') {
        initialValue = initialValue.toUpperCase();
      }
  
      setEditingField({ type, groupIndex, taskIndex, subtaskIndex, field });
      setEditValue(initialValue);
    };
  
    if (isEditing) {
      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // Force uppercase while editing for fields like 'organism'
        const newValue = field === 'organism' || field === 'name' ? e.target.value.toUpperCase() : e.target.value;
        setEditValue(newValue);
      };
  
      const saveValue = () => {
        handleSave(type, groupIndex, taskIndex, field, editValue, subtaskIndex);
      };
  
      const handleBlur = () => {
        saveValue(); // Save on blur
      };
  
      return (
        <input
          ref={inputRef}
          type={field === 'start' || field === 'end' ? 'date' : 'text'}
          value={editValue}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              saveValue();
            }
          }}
          className="border-none font-bold text-center bg-white text-black rounded"
          style={{
            width: `${Math.max(editValue.length * 11, 50)}px`,
            maxWidth: '100%',
          }}
          autoFocus
        />
      );
    }
  
    let displayValue: any = value;
    if ((type === 'task' || type === 'subtask') && (field === 'start' || field === 'end')) {
      displayValue = formatDateForDisplay(value);
    } else if (field === 'dependsOn') {
      if (type === 'subtask' && Array.isArray(value)) {
        displayValue = value.map(dep => dep.subtaskId).join(', ');
      } else if (Array.isArray(value)) {
        displayValue = value.map(dep => dep.taskId).join(', ');
      } else {
        displayValue = value;
      }
    } else if (field === 'duration') {
      if (type === 'task' && task) {
        displayValue = `${formatDuration(calculateDuration(task.start, task.end))} semanas`;
      } else if (type === 'subtask' && subtask) {
        displayValue = `${formatDuration(calculateDuration(subtask.start, subtask.end))} semanas`;
      } else {
        displayValue = `${formatDuration(value)} semanas`;
      }
    }
  
    // Ensure display value is in uppercase for specified fields
    if (field === 'organism' || field === 'name') {
      displayValue = displayValue.toString().toUpperCase();
    }
  
    const isEmpty = !displayValue || (typeof displayValue === 'string' && displayValue.trim() === '');
  
    return (
      <span
        className={`cursor-pointer hover:bg-gray-100 hover:text-black p-1 rounded ${
          isDisabled ? 'cursor-not-allowed text-gray-500' : ''
        } ${isEmpty ? 'min-w-[50px]' : ''} inline-flex items-center h-[20px]`}
        onClick={startEditing}
      >
        {isEmpty ? '\u00A0' : displayValue}
      </span>
    );
  };
  
  
                  
 
                    const filterEnabledTasksAndGroups = (tasksState: TasksState): TasksState => {
                      const filteredGroups = tasksState.groups
                        .filter(group => group.enabled)
                        .map(group => {
                          const subprocessColor = subprocessColors[group.subprocess] || getSubprocessColor(group.subprocess);
                          return {
                            ...group,
                            color: subprocessColor,
                            tasks: group.tasks
                              .filter(task => task.enabled)
                              .map(task => ({
                                ...task,
                                color: darkenColor(subprocessColor, 60),
                                
                              }))
                          };
                        })
                        .filter(group => group.tasks.length > 0);
                    
                      // Si no hay grupos o tareas habilitadas, creamos una tarea ficticia
                      if (filteredGroups.length === 0) {
                        const today = new Date().toISOString().split('T')[0];
                        const defaultColor = "#CCCCCC";
                        filteredGroups.push({
                          name: "No hay tareas habilitadas",
                          expanded: true,
                          subprocess: "",
                          agrupador: "Sin agrupador",
                          enabled: true,
                          descriptor: 'GESTIÓN',
                          organismo: '',
                          color: defaultColor,
                          orden: 1,
                          tasks: [{
                            id: 0,
                            name: "Sin tareas",
                            responsible: '',
                            progress: 0,
                            start: today,
                            end: today,
                            descriptor: "",
                            organism: "",
                            duration: 1,
                            dependsOn: [],
                            enabled: true,
                            color: darkenColor(defaultColor, 60),
                            isClosed: false,
                            orden: 1,
                          }]
                        });
                      }
                    
                      return {
                        ...tasksState,
                        groups: filteredGroups
                        
                      };
                      
                    };


                    const generateUniqueGroupName = (groups: Group[]): string => {
                      let counter = 1;
                      let newName = `AGRUPADOR ${counter}`;
                      while (groups.some(group => group.agrupador === newName)) {
                        counter++;
                        newName = `AGRUPADOR ${counter}`;
                      }
                      return newName;
                    };
                  
                    const generateUniqueTaskName = (tasks: Task[]): string => {
                      let counter = 1;
                      let newName = `TAREA ${counter}`;
                      while (tasks.some(task => task.name === newName)) {
                        counter++;
                        newName = `TAREA ${counter}`;
                      }
                      return newName;
                    };
                  
                    const addNewGroup = () => {
                      setTasks(prevTasks => {
                        onUnsavedChanges(true);
                        setHasUnsavedChanges(true);
                        const newGroupName = generateUniqueGroupName(prevTasks.groups);
                        const newOrder = Math.max(...prevTasks.groups.map(g => g.orden), 0) + 1;
                          return {
                          ...prevTasks,
                          groups: [
                            ...prevTasks.groups,
                            {
                              name: newGroupName,
                              expanded: true,
                              subprocess: "Nuevo Subproceso",
                              agrupador: newGroupName,
                              tasks: [],
                              enabled: true,
                              descriptor: 'GESTIÓN',
                              organismo: '',
                              orden: newOrder,
                            }
                          ]
                        };
                      });
                    };
                  
                    const addNewTask = (groupIndex: number) => {
                      if (!tasks.groups[groupIndex].enabled) {
                        console.warn("No se puede añadir una tarea a un grupo deshabilitado");
                        return;
                      }
                  
                      setTasks(prevTasks => {
                        onUnsavedChanges(true);
                        setHasUnsavedChanges(true);
                      
                        const group = prevTasks.groups[groupIndex];
                        const newTaskName = generateUniqueTaskName(group.tasks);
                        
                        // Crear startDate en zona horaria local
                        const today = new Date();
                        today.setHours(0, 0, 0, 0);
                        const startDate = today.toISOString().split('T')[0];
                        
                        // Crear endDate en zona horaria local
                        const nextEndDate = new Date();
                        nextEndDate.setDate(today.getDate() + 7);
                        nextEndDate.setHours(0, 0, 0, 0);
                        const endDate = nextEndDate.toISOString().split('T')[0];
                        const newOrder = Math.max(...group.tasks.map(t => t.orden), 0) + 1;
                        
                        const newTask: Task = {
                            id: group.tasks.length + 1,
                            name: newTaskName,
                            responsible: '',
                            progress: 0,
                            start: startDate,
                            end: endDate,
                            organism: '',
                            duration: 1,
                            descriptor: '',
                            dependsOn: [],
                            enabled: true,
                            isClosed: false,
                            orden: newOrder,
                        };
                        
                        return {
                            ...prevTasks,
                            groups: prevTasks.groups.map((g, index) =>
                                index === groupIndex
                                    ? { ...g, tasks: [...g.tasks, newTask] }
                                    : g
                          
                          )
                        };
                      });
                    };
                  
                    const deleteGroup = (groupIndex: number) => {
                      setTasks(prevTasks => {
                        const newGroups = prevTasks.groups.filter((_, index) => index !== groupIndex);
                        // Reassign IDs to remaining groups
                        return {
                          ...prevTasks,
                          groups: newGroups.map((group, index) => ({
                            ...group,
                            id: index + 1
                          }))
                        };
                      });
                    };

                    const resetGroupTasks = (groupIndex: number) => {
                      setTasks(prevTasks => {
                        const newGroups = [...prevTasks.groups];
                        const group = newGroups[groupIndex];
                        const today = new Date().toISOString().split('T')[0];
                        const oneWeekLater = new Date(new Date().setDate(new Date().getDate() + 7)).toISOString().split('T')[0];
                  
                        group.tasks = group.tasks.map((task, index) => ({
                          ...task,
                          name: `TAREA ${index + 1}`,
                          start: today,
                          end: oneWeekLater,
                          dependsOn: [],
                        }));
                  
                        return { ...prevTasks, groups: newGroups };
                      });
                    };

                    const copyGroup = (groupIndex: number) => {
                      setTasks(prevTasks => {
                        const groupToCopy = prevTasks.groups[groupIndex];
                        const newGroup: Group = {
                          ...groupToCopy,
                          agrupador: `${groupToCopy.agrupador} (Copia)`,
                          tasks: groupToCopy.tasks.map(task => ({
                            ...task,
                            id: Math.max(...prevTasks.groups.flatMap(g => g.tasks.map(t => t.id))) + 1,
                          //  dependsOn: [] // Reseteamos las dependencias para evitar conflictos
                          }))
                        };
                        
                        return {
                          ...prevTasks,
                          groups: [
                            ...prevTasks.groups.slice(0, groupIndex + 1),
                            newGroup,
                            ...prevTasks.groups.slice(groupIndex + 1)
                          ]
                        };
                      });
                    };
                  
                  
                    const deleteTask = (groupIndex: number, taskIndex: number) => {
                      if (!tasks.groups[groupIndex].enabled) {
                        console.warn("No se puede eliminar una tarea en un grupo deshabilitado");
                        return;
                      }
                      setTasks(prevTasks => {
                        const newGroups = prevTasks.groups.map((group, gIndex) => {
                          if (gIndex === groupIndex) {
                            const newTasks = group.tasks.filter((_, tIndex) => tIndex !== taskIndex);
                            // Reassign IDs to remaining tasks
                            return {
                              ...group,
                              tasks: newTasks.map((task, index) => ({
                                ...task,
                                id: index + 1
                              }))
                            };
                          }
                          return group;
                        });
                        return {
                          ...prevTasks,
                          groups: newGroups
                        };
                      });
                    };
                  

                    
                    const forceUpdateDates = (groupIndex: number) => {
                      setTasks(prevTasks => {
                        const newTasks = JSON.parse(JSON.stringify(prevTasks));
                        const group = newTasks.groups[groupIndex];
                        
                        const processedTasks = new Set<number>();
                        
                        const updateTaskDates = (taskIndex: number) => {
                          if (processedTasks.has(taskIndex)) return;
                          processedTasks.add(taskIndex);
                          
                          const task = group.tasks[taskIndex];
                          if (!task.enabled || task.isClosed) return;
                          
                          if (task.dependsOn.length > 0) {
                            const maxEndDate = Math.max(...task.dependsOn.map((dep: { taskId: number }) => {
                              const depTask = group.tasks[dep.taskId - 1];
                              return new Date(depTask.end).getTime();
                            }));
                            
                            // Determine if it depends on a closed task
                            const dependsOnClosedTask = task.dependsOn.some((dep: { taskId: number }) => 
                              group.tasks[dep.taskId - 1].isClosed
                            );
                            
                            // Calculate new start date
                            const newStart = dependsOnClosedTask
                              ? new Date(maxEndDate).toISOString().split('T')[0]
                              : new Date(maxEndDate + 86400000).toISOString().split('T')[0];
                            
                            if (newStart !== task.start) {
                              const duration = calculateDuration(task.start, task.end);
                              task.start = newStart;
                              task.end = calculateNewEndDate(newStart, duration);
                              
                              // Update subtasks if TRAMITACIÓN
                              if (task.isTramitacion && task.subtasks) {
                                updateTramitacionSubtasksStartDate(task);
                              }
                              
                              // Find and update tasks that depend on this one
                              group.tasks.forEach((dependentTask: Task, index: number) => {
                                if (dependentTask.dependsOn.some((dep: { taskId: number }) => dep.taskId === taskIndex + 1)) {
                                  updateTaskDates(index);
                                }
                              });
                            }
                          }
                        };
                        
                        // Process all tasks in order
                        group.tasks.forEach((_: Task, index: number) => {
                          if (!processedTasks.has(index)) {
                            updateTaskDates(index);
                          }
                        });
                        
                        
                        return newTasks;
                      });
                    };


                    const moveGroup = (groupIndex: number, direction: 'up' | 'down') => {
                      setTasks(prevTasks => {
                        const newGroups = [...prevTasks.groups];
                        if (direction === 'up' && groupIndex > 0) {
                          [newGroups[groupIndex], newGroups[groupIndex - 1]] = [newGroups[groupIndex - 1], newGroups[groupIndex]];
                        } else if (direction === 'down' && groupIndex < newGroups.length - 1) {
                          [newGroups[groupIndex], newGroups[groupIndex + 1]] = [newGroups[groupIndex + 1], newGroups[groupIndex]];
                        }
                        // Reassign IDs to all groups
                        return {
                          ...prevTasks,
                          groups: newGroups.map((group, index) => ({
                            ...group,
                            id: index + 1
                          }))
                        };
                      });
                    };
                  
                   // Función mejorada para mover tareas
                    const moveTask = (groupIndex: number, taskIndex: number, direction: 'up' | 'down') => {
                      console.log(`Attempting to move task in group ${groupIndex}, task ${taskIndex} ${direction}`);
                      
                      setTasks(prevTasks => {
                        const newGroups = [...prevTasks.groups];
                        const group = newGroups[groupIndex];
                        
                        if (!group.enabled) {
                          console.warn("No se puede mover una tarea en un grupo deshabilitado");
                          return prevTasks;
                        }

                        const newIndex = direction === 'up' ? taskIndex - 1 : taskIndex + 1;

                        if (newIndex < 0 || newIndex >= group.tasks.length) {
                          console.warn(`Invalid move: task ${taskIndex} cannot be moved ${direction}`);
                          return prevTasks;
                        }

                        // Crear una copia de las tareas del grupo
                        let newTasks = [...group.tasks];

                        // Intercambiar las tareas
                        [newTasks[taskIndex], newTasks[newIndex]] = [newTasks[newIndex], newTasks[taskIndex]];

                        // Actualizar las dependencias
                        newTasks = updateDependenciesAfterMove(newTasks, taskIndex, newIndex);

                        // Actualizar los IDs de las tareas
                        newTasks = newTasks.map((task, index) => ({
                          ...task,
                          id: index + 1
                        }));

                        // Actualizar el grupo con las nuevas tareas
                        newGroups[groupIndex] = {
                          ...group,
                          tasks: newTasks
                        };

                        console.log(`Task moved successfully. New tasks order:`, newTasks.map(t => t.id));

                        return {
                          ...prevTasks,
                          groups: newGroups
                        };
                      });
                    };
                  
                    const updateDependenciesAfterMove = (tasks: Task[], oldIndex: number, newIndex: number): Task[] => {
                      return tasks.map(task => ({
                        ...task,
                        dependsOn: task.dependsOn.map(dep => {
                          if (dep.taskId === oldIndex + 1) {
                            return { ...dep, taskId: newIndex + 1 };
                          } else if (oldIndex < newIndex) {
                            if (dep.taskId > oldIndex + 1 && dep.taskId <= newIndex + 1) {
                              return { ...dep, taskId: dep.taskId - 1 };
                            }
                          } else if (oldIndex > newIndex) {
                            if (dep.taskId >= newIndex + 1 && dep.taskId < oldIndex + 1) {
                              return { ...dep, taskId: dep.taskId + 1 };
                            }
                          }
                          return dep;
                        })
                      }));
                    };
                    
                    const formatDateForDisplay = (dateString: string) => {
                      if (!dateString) return '';
                      const date = new Date(dateString + 'T00:00:00Z');
                      if (isNaN(date.getTime())) return dateString;
                      return date.toLocaleDateString('es-ES', { 
                        day: '2-digit', 
                        month: '2-digit', 
                        year: 'numeric',
                        timeZone: 'UTC'
                      });
                    };
                  
                    const formatDateForEdit = (dateString: string) => {
                      if (!dateString) return '';
                      let date = new Date(dateString + 'T00:00:00Z');
                      
                      if (isNaN(date.getTime())) {
                        const parts = dateString.split('-');
                        if (parts.length === 3) {
                          date = new Date(Date.UTC(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0])));
                        }
                      }
                      
                      if (isNaN(date.getTime())) return '';
                      
                      return date.toISOString().split('T')[0];
                    };
                  
                    const toggleGlobalExpansion = () => {
                      setIsGloballyExpanded(!isGloballyExpanded);
                      setTasks(prevTasks => ({
                        ...prevTasks,
                        groups: prevTasks.groups.map(group => ({
                          ...group,
                          expanded: !isGloballyExpanded
                        }))
                      }));
                    };
                  
                  
                    const handleDateChange = (groupIndex: number, taskIndex: number, field: 'start' | 'end', newDate: string) => {
                      setTasks(prevTasks => {
                        const newTasks = JSON.parse(JSON.stringify(prevTasks));
                        const group = newTasks.groups[groupIndex];
                        const task = group.tasks[taskIndex];
                        const oldDate = new Date(task[field]);
                        const updatedDate = new Date(newDate);
                    
                        // Si se está modificando la fecha de inicio
                        if (field === 'start') {
                          // Actualizar fecha de inicio y mantener la duración
                          task.start = newDate;
                          const currentDuration = task.duration;
                          task.end = calculateNewEndDate(newDate, currentDuration);
                        } 
                        // Si se está modificando la fecha de término
                        else if (field === 'end') {
                          // Validar que la nueva fecha de término no sea anterior a la fecha de inicio
                          if (updatedDate < new Date(task.start)) {
                            showLabelMessage('error', 'La fecha de término no puede ser menor a la fecha de inicio');
                            return prevTasks;
                          }
                          
                          // Actualizar fecha de término y recalcular duración
                          task.end = newDate;
                         // task.duration = calculateDuration(task.start, newDate);
                        }
                    
                        // Actualizar las fechas de las tareas dependientes
                        const updateDependentTasksRecursively = (tasks: Task[], changedTaskIndex: number) => {
                          const processedTasks = new Set<number>();
                        
                          const updateTask = (taskIndex: number) => {
                            // Si ya procesamos esta tarea, salimos para evitar ciclos
                            if (processedTasks.has(taskIndex)) return;
                            processedTasks.add(taskIndex);
                        
                            const task = tasks[taskIndex];
                        
                            // Encontramos todas las tareas que dependen de esta
                            const dependentTasks = tasks.reduce((acc, currentTask, currentIndex) => {
                              if (currentTask.dependsOn.some(dep => dep.taskId === taskIndex + 1)) {
                                acc.push({ task: currentTask, index: currentIndex });
                              }
                              return acc;
                            }, [] as Array<{ task: Task; index: number }>);
                        
                            // Para cada tarea dependiente
                            dependentTasks.forEach(({ task: dependentTask, index: dependentIndex }) => {
                              // Calculamos la fecha más tardía entre todas sus dependencias
                              const maxEndDate = Math.max(
                                ...dependentTask.dependsOn.map(dep => {
                                  const depTask = tasks[dep.taskId - 1];
                                  return new Date(depTask.end).getTime();
                                })
                              );
                        
                            
                              // Verificamos si depende de alguna tarea cerrada
                              const dependsOnClosedTask = dependentTask.dependsOn.some(dep => 
                                tasks[dep.taskId - 1].isClosed
                              );
                        
                              // Calculamos la nueva fecha de inicio
                              const newStart = dependsOnClosedTask
                                ? new Date(maxEndDate).toISOString().split('T')[0] // Mismo día si depende de tarea cerrada
                                : addDaysToDate(new Date(maxEndDate).toISOString().split('T')[0], 1); // Día siguiente si no
                        
                              // Solo actualizamos si la fecha realmente cambió
                              if (new Date(newStart).getTime() !== new Date(dependentTask.start).getTime()) {
                                const duration = calculateDuration(dependentTask.start, dependentTask.end);
                                dependentTask.start = newStart;
                                dependentTask.end = calculateNewEndDate(newStart, duration);
                        
                                // Recursivamente actualizamos las tareas que dependen de esta
                                updateTask(dependentIndex);
                                if(dependentTask.name === 'TRAMITACIÓN'){
                                  updateSubtasksForTramitacion(dependentTask)
                                }
                              }
                            });
                          };
                          
                        
                          if (task.isTramitacion && task.subtasks) {
                            const taskStart = new Date(task.start).getTime();
                            const originalTaskStart = new Date(task.originalStart || task.start).getTime();
                            
                            const dateDifference = (taskStart - originalTaskStart) / (1000 * 60 * 60 * 24); // difference in days
                  
                            // Adjust each subtask's start and end dates based on the new start date of the TRAMITACIÓN task
                            task.subtasks.forEach((subtask: SubTask) => {
                              const subtaskStartDate = new Date(subtask.start);
                              const subtaskEndDate = new Date(subtask.end);
                  
                              subtaskStartDate.setDate(subtaskStartDate.getDate() + dateDifference);
                              subtaskEndDate.setDate(subtaskEndDate.getDate() + dateDifference);
                  
                              subtask.start = subtaskStartDate.toISOString().split('T')[0];
                              subtask.end = subtaskEndDate.toISOString().split('T')[0];
                            });
                  
                            // Update task's end date based on the latest subtask end date
                            const latestSubtaskEndDate = Math.max(
                              ...task.subtasks.map((st: SubTask) => new Date(st.end).getTime())
                            );
                            task.end = new Date(latestSubtaskEndDate).toISOString().split('T')[0];
                          }
                          
                          // Iniciamos la actualización desde la tarea modificada
                          updateTask(changedTaskIndex);
                        };
                        // Actualizar tareas dependientes
                        updateDependentTasksRecursively(group.tasks, taskIndex);
                        updateDependentSubtasksRecursively(group.tasks, 1)
                        return newTasks;
                      });
                    }; 




// Nueva función para actualizar las subtareas de TRAMITACIÓN
const updateSubtasksForTramitacion = (tramitacionTask: Task) => {
  if (!tramitacionTask.subtasks) return;

  let currentDate = dateUtils.parseLocalDate(tramitacionTask.start);
  
  // Primero actualizar subtareas sin dependencias
  tramitacionTask.subtasks.forEach(subtask => {
    if (!subtask.dependsOn || subtask.dependsOn.length === 0) {
      const duration = dateUtils.calculateDaysDifference(subtask.end, subtask.start);
      subtask.start = dateUtils.formatLocalDate(currentDate);
      subtask.end = dateUtils.addDaysToDate(subtask.start, duration);
      
      currentDate = dateUtils.parseLocalDate(subtask.end);
      currentDate.setDate(currentDate.getDate() + 1); // Increment by one day
    }
  });

  // Luego actualizar subtareas con dependencias
  const processedSubtasks = new Set<number>();
  
  
const ensureDependsOnArray = (dependsOn: any): { groupId: number; taskId: number; subtaskId: number }[] => {
  if (!dependsOn) return [];
  if (Array.isArray(dependsOn)) return dependsOn;
  if (typeof dependsOn === 'number') return [{ groupId: 0, taskId: 0, subtaskId: dependsOn }];
  return [];
};
  const updateDependentSubtask = (subtaskIndex: number) => {
    if (processedSubtasks.has(subtaskIndex)) return;
    
    const subtask = tramitacionTask.subtasks![subtaskIndex];
    const dependencies = ensureDependsOnArray(subtask.dependsOn);
    
    // Procesar primero las dependencias
    dependencies.forEach(dep => {
      if (!processedSubtasks.has(dep.subtaskId - 1)) {
        updateDependentSubtask(dep.subtaskId - 1);
      }
    });

    if (dependencies.length > 0) {
      const maxEndDate = Math.max(...dependencies.map(dep => {
        const depSubtask = tramitacionTask.subtasks![dep.subtaskId - 1];
        return dateUtils.parseLocalDate(depSubtask.end).getTime();
      }));

      const newStart = dateUtils.addDaysToDate(dateUtils.formatLocalDate(new Date(maxEndDate)), 1);
      
      const duration = dateUtils.calculateDaysDifference(subtask.end, subtask.start);
      subtask.start = newStart;
      subtask.end = dateUtils.addDaysToDate(newStart, duration);
    }

    processedSubtasks.add(subtaskIndex);
  };

  // Ejecutar actualización en subtareas con dependencias
  tramitacionTask.subtasks.forEach((_, index) => {
    updateDependentSubtask(index);
  });
};
                    
                    const updateDependentSubtasksRecursively = (task: Task, changedSubtaskIndex: number) => {
                      // Check if subtasks are defined
                      if (!task.subtasks || !task.subtasks[changedSubtaskIndex]) return;
                    
                      const changedSubtask = task.subtasks[changedSubtaskIndex];
                      const processedSubtasks = new Set<number>();
                    
                      const updateSubtask = (subtaskIndex: number) => {
                        if (processedSubtasks.has(subtaskIndex) || !task.subtasks) return;
                        processedSubtasks.add(subtaskIndex);
                    
                        const currentSubtask = task.subtasks[subtaskIndex];
                        const dependencies = currentSubtask.dependsOn;
                    
                        // Find the latest end date among all dependencies
                        const latestEndDate = Math.max(
                          ...dependencies.map(dep => {
                            const depSubtask = task.subtasks ? task.subtasks[dep.subtaskId - 1] : undefined;
                            return depSubtask ? new Date(depSubtask.end).getTime() : 0;
                          })
                        );
                    
                        const dependsOnClosedSubtask = dependencies.some(dep => {
                          const depSubtask = task.subtasks ? task.subtasks[dep.subtaskId - 1] : undefined;
                          return depSubtask?.isClosed ?? false;
                        });
                    
                        // Calculate new start date
                        let newStart;
                        if (dependsOnClosedSubtask) {
                          // If it depends on a closed subtask, it starts on the same day
                          newStart = new Date(latestEndDate).toISOString().split('T')[0];
                        } else {
                          // Otherwise, it starts the next day
                          const nextDay = new Date(latestEndDate);
                          nextDay.setDate(nextDay.getDate() + 1);
                          newStart = nextDay.toISOString().split('T')[0];
                        }
                    
                        if (new Date(newStart).getTime() !== new Date(currentSubtask.start).getTime()) {
                          const duration = calculateDuration(currentSubtask.start, currentSubtask.end);
                          currentSubtask.start = newStart;
                          currentSubtask.end = calculateNewEndDate(newStart, duration);
                    
                          // Find and update subtasks that depend on this one
                          task.subtasks?.forEach((subtask, index) => {
                            if (subtask.dependsOn.some(dep => dep.subtaskId === subtaskIndex + 1)) {
                              updateSubtask(index);
                            }
                          });
                        }
                      };
                    
                      // Update all subtasks that directly depend on the modified subtask
                      task.subtasks?.forEach((subtask, index) => {
                        if (subtask.dependsOn.some(dep => dep.subtaskId === changedSubtaskIndex + 1)) {
                          updateSubtask(index);
                        }
                      });
                    };

                    
                    const handleReturnToSelection = () => {
                      if (hasUnsavedChanges) {
                        setIsConfirmExitDialogOpen(true);
                        setPendingAction(() => {
                          return () => {
                            onUnsavedChanges(false);
                            setHasUnsavedChanges(false);
                            setShowSelection(true);
                            setSelectedCodigoProyecto('');
                            setSelectedNombreProyecto('');
                            setSubprocesos([]);
                            setTasks({
                              name: "Plantilla 1",
                              groups: []
                            });
                          };
                        });
                      } else {
                        onUnsavedChanges(false);
                        setHasUnsavedChanges(false);
                        setShowSelection(true);
                        setSelectedCodigoProyecto('');
                        setSelectedNombreProyecto('');
                        setSubprocesos([]);
                        setTasks({
                          name: "Plantilla 1",
                          groups: []
                        });
                      }
                    };


                    const lightenColor = (color: string, amount: number): string => {
                      const num = parseInt(color.replace("#", ""), 16);
                      const r = Math.min(255, Math.max(0, (num >> 16) + amount));
                      const g = Math.min(255, Math.max(0, ((num >> 8) & 0x00FF) + amount));
                      const b = Math.min(255, Math.max(0, (num & 0x0000FF) + amount));
                      return `#${(1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1)}`;
                    };
                    
                    // Función para oscurecer colores
                    const darkenColor = (color: string, amount: number): string => {
                      return lightenColor(color, -amount);
                    };
                    
                  
                    const getSubprocessColor = (subprocess: string) => {
                      const predefinedColors = [
                        '#ADD8E6', '#90EE90', '#FFDAB9', '#E6E6FA', '#FFFACD', '#F5DEB3', '#D8BFD8', '#87CEFA', 
                        '#D3FFCE', '#F0E68C', '#E0FFFF', '#FAFAD2', '#FFB6C1', '#FFE4B5', '#98FB98', '#E0B0FF', 
                        '#FFCCCB', '#FFD700', '#B0E0E6', '#FFDEAD', '#FF69B4', '#FFE4E1', '#F0FFF0', '#E6E6FA'
                      ];
                  
                      let hash = 0;
                      for (let i = 0; i < subprocess.length; i++) {
                        hash = subprocess.charCodeAt(i) + ((hash << 5) - hash);
                      }
                  
                      return predefinedColors[Math.abs(hash) % predefinedColors.length];
                    };


                    const getResolutionIcon = (resolutionType: string) => {
                      switch (resolutionType) {
                        case 'APROBADO':
                          return <Check className="w-6 h-6 text-green-500" />;
                        case 'RECHAZADO':
                          return <X className="w-5 h-5 text-red-500" />;
                        case 'DESISTIMIENTO':
                          return <AlertTriangle className="w-5 h-5 text-yellow-500" />;
                        case 'SILENCIO ADMINISTRATIVO POSITIVO':
                          return <CheckCircle className="w-5 h-5 text-green-500" />;
                        case 'SILENCIO ADMINISTRATIVO NEGATIVO':
                          return <XCircle className="w-5 h-5 text-red-500" />;
                        
                        case 'NO ADMITIDO':
                          return (
                             <div className="flex items-center">
                              <svg viewBox="0 0 24 24" className="h-5 w-5 text-red-700">
                                <circle cx="12" cy="12" r="10" fill="none" stroke="currentColor" strokeWidth="2" />
                                <line x1="4" y1="4" x2="20" y2="20" stroke="currentColor" strokeWidth="2" />
                              </svg>
                            </div>
                          );
                        
                          case 'No especificado':
                            return <X className="w-5 h-5 text-red-500" />;
                          
                          default:
                          return <HelpCircle className="w-5 h-5 text-blue-500" />;
                      }
                    };


                    const getResolutionColor = (resolutionType: string) => {
                      switch (resolutionType) {
                        case 'APROBADO':
                          return "text-green-500";
                        case 'RECHAZADO':
                          return "text-red-500";
                        case 'DESISTIMIENTO':
                          return "text-yellow-500";
                        case 'SILENCIO ADMINISTRATIVO POSITIVO':
                          return "text-green-500";
                        case 'SILENCIO ADMINISTRATIVO NEGATIVO':
                          return "text-red-500";
                        case 'NO ADMITIDO':
                          return "text-red-500";
                        default:
                          return "text-black";
                      }
                    };
                    
                    
                    const handleSaveAsOpen = () => setIsSaveAsOpen(true);
                    const handleSaveAsClose = () => {
                      setIsSaveAsOpen(false);
                      setTemplateName('');
                      setTemplateDescription('');
                      setSaveAsError(null);
                      setIsOverwriteConfirmOpen(false);
                    };

                    const handleSaveAs = async (overwrite: boolean = false) => {
                      try {
                        const plantillaData = {
                          nombre: templateName,
                          descripcion: templateDescription,
                          contenido: JSON.stringify({
                            nombre: templateName,
                            subprocesos: tasks.groups.map(group => {
                              const matchingSubprocess = subprocesos.find(sp => sp.subproceso === group.subprocess);
                              return {
                                nombre: matchingSubprocess ? matchingSubprocess.subproceso : "",
                                agrupadores: [{
                                  nombre: group.agrupador,
                                  enabled: group.enabled,
                                  tareas: group.tasks.map(task => ({
                                    id: task.id,
                                    nombre: task.name,
                                    responsable: task.responsible,
                                    progreso: task.progress,
                                    fecha_inicio: task.start,
                                    fecha_termino: task.end,
                                    descriptor: task.descriptor,
                                    organismo: task.organism,
                                    dependencia: task.dependsOn.length > 0 ? task.dependsOn[0].taskId : null,
                                    enabled: task.enabled
                                  }))
                                }]
                              };
                            })
                          }),
                          tipo: 'custom',
                          proceso: selectedCodigoProyecto,// Añadimos el proceso seleccionado
                          overwrite: overwrite
                        };
                    
                        console.log('Sending data:', plantillaData);
                    
                        const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/insert_plantilla.php`, {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                          },
                          body: JSON.stringify(plantillaData),
                        });
                    
                        const result = await response.json();
                        console.log('Received result:', result);
                    
                        if (result.success) {
                          console.log('Plantilla guardada:', result);
                          handleSaveAsClose();
                          showLabelMessage('success', result.message || 'Plantilla guardada exitosamente');
                        } else if (result.error === 'Ya existe una plantilla con este nombre' && !overwrite) {
                          setIsOverwriteConfirmOpen(true);
                        } else {
                          throw new Error(result.error || 'Error al guardar la plantilla');
                        }
                      } catch (error) {
                        console.error('Error:', error);
                        setSaveAsError('Error al guardar la plantilla');
                      }
                    };
                    
    
            
                    const getSubprocessDescriptor = (group: Group): string | null => {
                      if (group.tasks.length === 0) return null;
                      const firstTaskDescriptor = group.tasks[0].descriptor;
                      return group.tasks.every(task => task.descriptor === firstTaskDescriptor) ? firstTaskDescriptor : null;
                    };
     


                    const handleSavePlantilla = async () => {
                      try {
// En tu función handleSavePlantilla o similar
const plantillaData = {
  nombre: tasks.name,
  descripcion: "Plantilla actualizada",
  contenido: JSON.stringify({
    nombre: tasks.name,
    proceso: selectedCodigoProyecto || "",
    subprocesos: tasks.groups.map(group => ({
      nombre: group.subprocess || "",
      agrupadores: [{
        nombre: group.agrupador,
        enabled: group.enabled,
        descriptor: group.descriptor,
        organismo: group.organismo || "",
        orden: group.orden,
        tareas: group.tasks.map(task => ({
          id: task.id,
          nombre: task.name,
          responsable: Array.isArray(task.responsible) 
            ? task.responsible.map(user => ({ id: user.id }))
            : [],
          progreso: task.progress,
          fecha_inicio: task.start,
          fecha_termino: task.end,
          descriptor: task.descriptor,
          organismo: task.organism || "",
          dependencia: task.dependsOn.length > 0 ? 
            task.dependsOn.map(dep => dep.taskId) : [],
          enabled: task.enabled,
          orden: task.orden,
          isClosed: task.isClosed,
          isTramitacion: task.isTramitacion,
          subtasks: task.isTramitacion && task.subtasks ? task.subtasks.map(subtask => ({
            // IMPORTANTE: Usar el nombre correcto de la subtarea, no sobreescribirlo
            nombre: subtask.name, // Asegúrate de que se use el nombre original
            tipo: subtask.type,   // Mantener el tipo
            type: subtask.type,   // Incluir campo duplicado para compatibilidad
            responsable: Array.isArray(subtask.responsible)
              ? subtask.responsible.map(user => ({ id: user.id }))
              : [],
            organismo: subtask.organism,
            progreso: subtask.progress,
            fecha_inicio: subtask.start,
            fecha_termino: subtask.end,
            orden: subtask.orden,
            duracion: subtask.duration,
            dependencias: Array.isArray(subtask.dependsOn) 
              ? subtask.dependsOn.map(dep => dep.subtaskId)
              : [],
            enabled: subtask.enabled,
            isClosed: subtask.isClosed,
            resolucion_tipo: subtask.resolutionType,
          })) : []
        }))
      }]
    }))
  }),
  tipo: 'custom',
  proceso: selectedCodigoProyecto || ""
};
                    
                        const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/update_plantilla.php`, {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                          },
                          body: JSON.stringify(plantillaData), 
                        });
                   //     alert(JSON.stringify(plantillaData))
                        const result = await response.json();
                        
                        if (result.success) {
                          showLabelMessage('success', 'Plantilla guardada exitosamente');
                        } else {
                          throw new Error(result.error || 'Error al guardar la plantilla');
                        }
                      } catch (error) {
                        console.error('Error:', error);
                        showLabelMessage('error', `Error al guardar la plantilla: ${error}`);
                      }
                    };

                    const loadDependencies = (templateData: any) => {
                      const dependencies = new Map();
                      
                      templateData.groups.forEach((group: any, groupIndex: number) => {
                        group.tasks.forEach((task: any) => {
                          if (task.dependencia) {
                            const deps = Array.isArray(task.dependencia) ? 
                              task.dependencia : [task.dependencia];
                            
                            dependencies.set(task.id, deps.map((depId: number) => ({
                              groupId: groupIndex,
                              taskId: depId
                            })));
                          }
                          
                          if (task.subtasks) {
                            task.subtasks.forEach((subtask: any) => {
                              if (subtask.dependencias) {
                                const subtaskDeps = Array.isArray(subtask.dependencias) ?
                                  subtask.dependencias : [subtask.dependencias];
                                
                                dependencies.set(`${task.id}-${subtask.id}`, subtaskDeps.map((depId: number) => ({
                                  groupId: groupIndex,
                                  taskId: task.id,
                                  subtaskId: depId
                                })));
                              }
                            });
                          }
                        });
                      });
                      
                      return dependencies;
                    };
                    


                    const handleClearPlantilla = () => {
                      setTasks({
                        name: tasks.name,
                        groups: []
                      });
                    };
                  
                    const isPlantillaEmpty = () => {
                      return tasks.groups.length === 0 || tasks.groups.every(group => group.tasks.length === 0);
                    };


                    const toggleGroupEnabled = (groupIndex: number) => {
                      setTasks(prevTasks => ({
                        ...prevTasks,
                        groups: prevTasks.groups.map((group, index) => 
                          index === groupIndex 
                            ? { 
                                ...group, 
                                enabled: !group.enabled, 
                                tasks: group.tasks.map(task => ({ ...task, enabled: !group.enabled }))
                              }
                            : group
                        )
                      }));
                    };




                    const handleResolutionTypeChange = (groupIndex: number, taskIndex: number, subtaskIndex: number, type: string, customType?: string) => {
                      setTasks(prevTasks => {
        
                        const newTasks = JSON.parse(JSON.stringify(prevTasks));
                        const task = newTasks.groups[groupIndex].tasks[taskIndex];
                        const subtask = task.subtasks[subtaskIndex];
                    
                        // Establecer el tipo de resolución
                        subtask.resolutionType = type === 'OTRO' ? customType : type;
                    
                        // Obtener la fecha actual
                        const today = new Date();
                        today.setHours(0, 0, 0, 0);
                        const formattedToday = today.toISOString().split('T')[0];
                    
                        // Cerrar la subtarea de RESOLUCIÓN
                        subtask.isClosed = true;
                        subtask.progress = 100;
                        subtask.end = formattedToday;
                    
                        // Cerrar la tarea TRAMITACIÓN
                        task.isClosed = true;
                        task.progress = 100;
                        task.end = formattedToday;
                    
                        return newTasks;
                      });
                    };

                    const openResolutionModal = (groupIndex: number, taskIndex: number, subtaskIndex: number) => {
                      setCurrentResolutionSubtask({ groupIndex, taskIndex, subtaskIndex });
                      setResolutionModalOpen(true);
                    };




const toggleTramitacion = (groupIndex: number, taskIndex: number) => {
  setExpandedTramitacion(prev => ({
    ...prev,
    [groupIndex]: {
      ...(prev[groupIndex] || {}),
      [taskIndex]: !(prev[groupIndex]?.[taskIndex] || false)
    }
  }));
};

                  
                    const toggleTaskEnabled = (groupIndex: number, taskIndex: number) => {
                      setTasks(prevTasks => ({
                        ...prevTasks,
                        groups: prevTasks.groups.map((group, gIndex) => 
                          gIndex === groupIndex
                            ? {
                                ...group,
                                tasks: group.tasks.map((task, tIndex) => 
                                  tIndex === taskIndex ? { ...task, enabled: !task.enabled } : task
                                )
                              }
                            : group
                        )
                      }));
                    };
                  

                    const handleGroupCheckbox = (groupIndex: number, checked: boolean) => {
                      setTasks(prevTasks => ({
                        ...prevTasks,
                        groups: prevTasks.groups.map((group, index) => 
                          index === groupIndex
                            ? {
                                ...group,
                                tasks: group.tasks.map(task => ({ ...task, enabled: checked }))
                              }
                            : group
                        )
                      }));
                    };
                  
                  
                    // Componente de modal para asignar la plantilla a un proceso
const AssignToProcessModal = () => ( 
  <Dialog width='500px' isOpen={isAssignToProcessModalOpen} onClose={() => setIsAssignToProcessModalOpen(false)}>
    <DialogContent className="bg-white rounded-xl shadow-xl">
      <DialogHeader>
        <DialogTitle>
          <div className="text-2xl font-bold text-gray-800">Asignar plantilla a proceso</div>
        </DialogTitle>
      </DialogHeader>
      <div className="space-y-4 my-4">
        <div className="bg-blue-50 p-4 rounded-lg border border-blue-100">
          <div className="flex items-center text-blue-700 mb-2">
            <AlertCircle className="w-5 h-5 mr-2" />
            <h3 className="font-semibold">Importante</h3>
          </div>
          <p className="text-blue-600 text-justify text-sm">
            Al asignar esta plantilla a un proceso, se crearán todos los datos relacionales (tareas, subtareas, etc).
            Para esto necesitamos que todos los organismos y responsables estén asignados correctamente.
          </p>
        </div>
        
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">Seleccione un proceso</label>
          <select
            value={selectedNombreProyecto}
            onChange={handleProcessChange}
            className="w-full p-2 text-gray-700 border border-gray-300 rounded-md"
          >
            <option value="">Seleccionar proceso</option>
            {processes.map((process, index) => (
              <option key={index} value={process.nombreProceso}>
                {process.codigo} - {process.nombreProceso} - {process.comuna}
              </option>
            ))}
          </select>
        </div>
      </div>
      <DialogFooter>
        <div className="flex justify-end space-x-2">
          <button 
            onClick={() => setIsAssignToProcessModalOpen(false)} 
            className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50"
          >
            Cancelar
          </button>
          <button 
            onClick={handleSaveToProcess} 
            className="bg-teal-500 text-white hover:bg-teal-600 px-4 py-2 rounded-md"
            disabled={!selectedCodigoProyecto}
          >
            Guardar en proceso
          </button>
        </div>
      </DialogFooter>
    </DialogContent>
  </Dialog>
);

                   
                    const renderTableHeaders = (group: Group) => {
                      if (group.tasks.length === 0) return null;
                    
                      const allTasksEnabled = group.tasks.every(task => task.enabled);
                      const subprocessColor = subprocessColors[group.subprocess] || "#8C8C8C";
                    
                      return (
                        <thead style={{ backgroundColor: lightenColor(subprocessColor, 80) }}>
                          <tr>
                            <th className="p-2 text-center w-10"></th>
                            <th className="p-2 text-center w-10">#</th>
                            <th className="p-2 w-1/4 min-w-[200px]">Nombre de las tareas</th>
                            <th className="p-2 text-left">Responsable</th>
                            <th className="p-2 text-center">Fecha de Inicio</th>
                            <th className="p-2 text-center">Fecha de término</th>
                            <th className="p-2 text-center">Duración</th>
                            <th className="p-2 text-center">Dependencias</th>
                            <th className="p-2 text-center">Estado</th>

                            <th className="p-2 text-center">Acciones</th>
                            {group.descriptor === 'TRAMITOLOGÍA' && <th className="p-2 text-center">Subtareas</th>}
                          </tr>
                        </thead>
                      );
                    };

/*
                    const StickyGanttbutton = () => (
                      <button 
                        onClick={() => setShowGanttModal(true)}
                        className={`fixed bottom-4 right-4 w-12 h-12 text-white rounded-full shadow-lg flex items-center justify-center transition-colors duration-300 z-50`}
                        style={{
                          backgroundColor: darkenColor("#49D4D7", 60)
                        }}
                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = darkenColor("#49D4D7", 60)}
                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "#49D4D7"}
                      >
                        <GanttChartIcon className="w-6 h-6" />
                      </button>
                    );
                  */
                 
           // Componente de barra de acciones flotante actualizado
const FloatingActionBar = () => (
  <div className="fixed right-0 bottom-24 flex flex-col space-y-2 z-50">
    {/* Botón para guardar plantilla */}
    <button
      onClick={handleSavePlantilla}
      className="rounded-s-full p-3 w-12 h-12 bg-teal-500 hover:bg-teal-600 transition-colors text-white shadow-lg"
      title="Guardar plantilla"
    >
      <Save className="h-6 w-6" />
    </button>
    
    {/* Botón para asignar a proceso */}
    <button
      onClick={handleAssignToProcess}
      className="rounded-s-full p-3  w-12 h-12 bg-indigo-500 hover:bg-indigo-600 transition-colors text-white shadow-lg"
      title="Asignar a proceso"
    >
      <ArrowRightIcon className="h-6 w-6" />
    </button>
    
    {/* Mantén tus botones existentes */}
    <button onClick={addNewGroup}   
      className="rounded-s-full p-3  w-12 h-12 bg-red-300 hover:bg-red-500 transition-colors text-white shadow-lg"
      title="Añadir agrupador"
    >
      <PlusCircleIcon className="w-6 h-6" /> 
    </button>
    
    <button
      onClick={toggleGlobalExpansion}
      className="rounded-s-full p-3  w-12 h-12 bg-purple-500 hover:bg-purple-600 transition-colors text-white shadow-lg"
      title={isGloballyExpanded ? "Colapsar Todo" : "Expandir Todo"}
    >
      {isGloballyExpanded ? <Minimize className="h-6 w-6" /> : <Maximize className="h-6 w-6" />}
    </button>
    
    <button
      onClick={() => setShowGanttModal(true)}
      className="rounded-s-full p-3  w-12 h-12 bg-yellow-500 hover:bg-yellow-700 transition-colors text-white shadow-lg"
      title="Vista Gantt"
    >
      <GanttChartIcon className="h-6 w-6" />
    </button>
  </div>
);
                   // console.log(JSON.stringify(filterEnabledTasksAndGroups(tasks)));
                    const GanttModal = () => (
                      <Dialog className='' isOpen={showGanttModal} onClose={() => setShowGanttModal(false)} width="1200px" height="700px">
                        <DialogContent className="w-full">
                          <DialogHeader>
                            <DialogTitle>Carta Gantt</DialogTitle>
                          </DialogHeader>
                          <div className="">
                          <GanttChartView tasks={filterEnabledTasksAndGroups(tasks)} />
                          </div>
                          <DialogFooter>
                            <button className="p-3 bg-teal-500 p-2 text-white hover:bg-teal-800" onClick={() => setShowGanttModal(false)}>Cerrar</button>
                          </DialogFooter>
                        </DialogContent> 
                      </Dialog>
                    );


                    if (showSelection) {
                      return <TemplateSelectionInterface onTemplateSelected={handleTemplateSelected} />;
                    }
                  
                    return (
                      <Card className="w-full max-w-7xl border-none mx-auto overflow-x-auto">
                      <CardHeader className="flex justify-between items-center w-full">
  <div className="items-center mb-3 w-full">
    <button 
      onClick={handleReturnToSelection} 
       
      
      className="flex mr-2"
    >
      <ArrowLeftCircle className="w-4 h-4 mr-2" /> Volver a Selección
    </button>
    <div className="w-full flex items-center rounded-xl shadow-lg bg-gray-400 mt-3">
      <div className="flex items-center justify-center w-full">
        <h1 className="text-xl font-bold text-center text-white py-3 px-4 mt-2 rounded inline-block">
          {renderEditableField('title', null, null, 'name', tasks.name)}
        </h1>
      </div>
    </div>
 
  </div>
</CardHeader>
                        <CardContent>
                
                          <div className="flex items-center justify-between">
                          
                         {/*   <button onClick={toggleGlobalExpansion}  >
                              {isGloballyExpanded ? <Minimize className="w-4 h-4 mr-2" /> : <Maximize className="w-4 h-4 mr-2" />}
                              {isGloballyExpanded ? "Colapsar Todo" : "Expandir Todo"}
                            </button>
                          */}
                          </div>
                  
                          {tasks.groups.map((group, groupIndex) => {
      const subprocessColor = subprocessColors[group.subprocess] || "#ADADAD";
      // Función mejorada para aclarar colores



    
      return (
        <div key={groupIndex} className={` mb-4 border rounded ${!group.enabled ? 'opacity-50' : ''}`}>
    <div className="relative pr-3" style={{ backgroundColor: lightenColor(subprocessColor, 60) }}>
            
            <div className="flex items-center">
              <div className="py-2 pr-4 flex items-center rounded-lg" style={{ backgroundColor: subprocessColor }}>
                   <input
                                      type="checkbox"
                                      checked={group.enabled}
                                      onChange={() => toggleGroupEnabled(groupIndex)}
                                      className="mr-2 ml-4"
                                    />
                                      <button 
                                        onClick={() => toggleGroup(groupIndex)}
                                        
                                        
                                        className="mr-2"
                                      >
                                        {group.expanded ? <ChevronDown className="w-4 h-4" /> : <ChevronRight className="w-4 h-4" />}
                                      </button>
                                      <div className="flex items-center">
                                        
                      
                                      {renderSubprocessSelect(group, groupIndex)}
                      
                    </div>
                                        {/*renderEditableField('group', groupIndex, null, 'subprocess', group.subprocess)*/}
                                     
                                      
                                    </div>
                                    
                                    <div className="flex-grow flex items-center ml-4 ">
                                      <div className="flex-wrap items-center">
                               
                                        <button 
                                          onClick={() => toggleGroup(groupIndex)}
                                          
                                          
                                          className="mr-2"
                                        >
                                          {group.expanded ? <ChevronDown className="w-4 h-4" /> : <ChevronRight className="w-4 h-4" />}
                                        </button>
                                        <span className="font-bold mr-2">Agrupador:</span>
                                        {renderEditableField('group', groupIndex, null, 'agrupador', group.agrupador)}
                                        {renderOrganismRow(group, groupIndex)}
  
                    </div>
            
                  </div>
                  
                  <div className="flex items-center space-x-1 ml-4">
                  <select
    value={group.descriptor}
    onChange={(e) => handleDescriptorChange(groupIndex, e.target.value as 'GESTIÓN' | 'TRAMITOLOGÍA')}
    className={`ml-2 mr-3 px-2 py-1 rounded-full text-xs font-semibold text-white`}
    style={{ backgroundColor: darkenColor(subprocessColor, 60) }}

    >
    <option value="GESTIÓN">GESTIÓN</option>
    <option value="TRAMITOLOGÍA">TRAMITOLOGÍA</option>
  </select>
  {group.descriptor === 'TRAMITOLOGÍA' && !group.tasks.some(task => task.name === 'TRAMITACIÓN') && (
  <button
    onClick={() => addTramitacionTask(groupIndex)}
    
    
    className="border-black border rounded-lg p-1 font-bold"
    title="Agregar TRAMITACIÓN"
  >
    T
  </button>
)}
      <button
        onClick={() => forceUpdateDates(groupIndex)}
        
        
        className="p-1 border-black border rounded-lg"
        title="Forzar actualización de fechas"
      >
        <RefreshCcwDot className="w-4 h-4" />
      </button>
                    <button
                      onClick={() => moveGroup(groupIndex, 'up')}
                      
                      
                      disabled={groupIndex === 0}
                      className="border-black border rounded-lg p-1"
                    >
                      <ArrowUp className="w-4 h-4" />
                    </button>
                    <button
                      onClick={() => moveGroup(groupIndex, 'down')}
                      
                      
                      disabled={groupIndex === tasks.groups.length - 1}
                      className="border-black border rounded-lg p-1"
                    >
                      <ArrowDown className="w-4 h-4" />
                    </button>

                    <button
                onClick={() => copyGroup(groupIndex)}
                
                
                className="border-black border rounded-lg p-1"
                title="Copiar Agrupador"
              >
                <Copy className="w-4 h-4" />
              </button>

              <button
                  onClick={() => resetGroupTasks(groupIndex)}
                  
                  
                  className="p-1 border-black border rounded-lg"
                  title="Resetear tareas"
                >
                  <Undo2Icon className="w-4 h-4" />
                </button>

                    <button
                      onClick={() => deleteGroup(groupIndex)}
                      
                      
                      className="text-red-500 p-1 border-red-500 border rounded-lg"
                    >
                      <Trash2 className="w-4 h-4" />
                    </button>
                  
                  </div>
                  
                </div>
                
              </div>
              
              {group.expanded && (
                <div className="p-2 shadow-lg">
                  {group.tasks.length > 0 ? (
                    <table className="w-full table-fixed">
                      {renderTableHeaders(group)}
                      <tbody>
                      {group.tasks.filter(task => !task.hidden).map((task, taskIndex) => {

                  const isExpanded = expandedTramitacion[groupIndex]?.[taskIndex] ?? true;
                  
                  return (
                    <React.Fragment key={taskIndex}>
                                    
                  <tr className={`border-b ${!task.enabled ? 'opacity-50' : ''}`}>
                      <td className="p-2 text-center">
                        <input
                          type="checkbox"
                          checked={task.enabled}
                          onChange={() => toggleTaskEnabled(groupIndex, taskIndex)}
                          disabled={!group.enabled}
                          className={`mr-2 ${!group.enabled ? 'cursor-not-allowed' : ''}`}
                        />
                      </td>
                      <td className="p-2 text-center">{taskIndex + 1}</td>
                      <td className="p-2 text-center">
                        <div className="flex items-center">
                        {task.isTramitacion && (
              <button
                onClick={() => toggleTramitacion(groupIndex, taskIndex)}
                
                
                className="mr-2"
              >
                {isExpanded ? <ChevronDown className="w-4 h-4" /> : <ChevronRight className="w-4 h-4" />}
              </button>
            )}


                          {renderEditableField('task', groupIndex, taskIndex, 'name', task.name)}
                        </div>
                      </td>
                      <td className="p-2 text-center">
                    {/*  <UserSelectorModal
    users={users}
    selectedUsers={task.responsible || []}
    onUsersChange={(newUsers) => handleSave('task', groupIndex, taskIndex, 'responsible', newUsers)}
    enabled={group.enabled && task.enabled}
    taskId={task.id}
    groupId={groupIndex + 1}  // Asumiendo que los IDs de grupo empiezan en 1
  />*/}
    <ResponsibleField type="task" />

</td>
                      <td className="p-2 text-center">
                        {renderEditableField('task', groupIndex, taskIndex, 'start', task.start)}
                      </td>
                      <td className="p-2 text-center">
                        {renderEditableField('task', groupIndex, taskIndex, 'end', task.end)}
                      </td>
                      <td className="p-2 text-center">
                        {renderEditableField('task', groupIndex, taskIndex, 'duration', null)}
                      </td>
                      <td className="p-2 text-center">
                        {renderEditableField('task', groupIndex, taskIndex, 'dependsOn', task.dependsOn)}
                      </td>
                      <td className="p-2 text-center">
  <TaskStatusField
    task={{
      ...task,
      isClosed: task.isClosed ?? false, // Valor por defecto si falta `isClosed`
      progress: task.progress ?? 0       // Valor por defecto si falta `progress`
    }}
    groupIndex={groupIndex}
    taskIndex={taskIndex}
    subprocessColor={subprocessColor}
    onSave={(type, groupIdx, taskIdx, field, value) => 
      handleSave(type as 'task' | 'group' | 'subtask', groupIdx, taskIdx, field, value)
    }
    disabled={!group.enabled || !task.enabled}
  />
</td>

                      <td className="p-2">
                        <div className="flex justify-center items-center space-x-1">
                          <button
                            onClick={() => moveTask(groupIndex, taskIndex, 'up')}
                            
                            
                            disabled={taskIndex === 0}
                            className="border-black border rounded-lg p-1"
                          >
                            <ArrowUp className="w-4 h-4" />
                          </button>
                          <button
                            onClick={() => moveTask(groupIndex, taskIndex, 'down')}
                            
                            
                            disabled={taskIndex === group.tasks.length - 1}
                            className="border-black border rounded-lg p-1"
                          >
                            <ArrowDown className="w-4 h-4" />
                          </button>
                          <button
                            onClick={() => deleteTask(groupIndex, taskIndex)}
                            
                            
                            className="border-red-500 border rounded-lg text-red-500 p-1"
                          >
                            <Trash2 className="w-4 h-4" />
                          </button>
                        </div>
                      </td>
                      {group.descriptor === 'TRAMITOLOGÍA' && task.isTramitacion && (
 <td className="p-2 text-center">
 <div className="grid grid-cols-2 gap-2">
   {subtasks.map(([label, type]) => (
     <button
       key={type}
       disabled={!task.enabled}
       onClick={() => addSpecificSubtask(groupIndex, taskIndex, type)}
       className={`p-2 border border-black rounded text-sm w-full ${
         !task.enabled ? 'opacity-50 cursor-not-allowed' : ''
       }`}
       
     >
       {label}
     </button>
   ))}
 </div>
</td>
      )}
                    </tr>
                    {task.isTramitacion && isExpanded && task.subtasks && task.subtasks.map((subtask, subtaskIndex) => (
        <tr key={`subtask-${subtaskIndex}`} className={`bg-gray-100 ${!subtask.enabled ? 'opacity-50' : ''}`}>
        <td className="p-2 text-center">
                          <input
                            type="checkbox"
                            checked={subtask.enabled}
                            onChange={() => handleSubtaskToggle(groupIndex, taskIndex, subtaskIndex)}
                            className="mr-2"
                          />
                        </td>
                        
                        <td className="p-2 text-center">{`${taskIndex + 1}.${subtaskIndex + 1}`}</td>
                        <td className="p-2">
                          {/*   <button
            onClick={() => openResolutionModal(groupIndex, taskIndex, subtaskIndex)}
            
            
            className="p-1"
          >
            <Settings2 className="w-4 h-4" />
          </button>*/}
      {subtask.type === 'RESOLUCIÓN' ? (
  <div className="flex space-x-2">
    {/* Código para RESOLUCIÓN */}
    <div className="flex ">
      {getResolutionIcon(subtask.resolutionType || 'No especificado')}
      <span 
        className="font-normal cursor-pointer" 
        onClick={() => openResolutionModal(groupIndex, taskIndex, subtaskIndex)}
      >
        <span className={getResolutionColor(subtask.resolutionType || 'No especificado')} >
          &nbsp;<b>RESOLUCIÓN:</b> {subtask.resolutionType || 'No especificado'}
        </span>
      </span>
    </div>
  </div>
) : subtask.type === 'INGRESO' ? (
  <div className="flex space-x-2">
    {/* Código para INGRESO */}
      <span className="font-normal" >
        &nbsp;<b>INGRESO</b>
      </span>
  </div>
) : (
  renderEditableField('subtask', groupIndex, taskIndex, 'name', subtask.name, subtaskIndex)
)}

    </td>
    <td className="p-2 text-center">
      {/* {(() => {
        if (subtask.type === 'OBSERVACIÓN' || 
            (subtask.type === 'RESOLUCIÓN' && subtask.resolutionType && subtask.resolutionType !== 'DESISTIMIENTO')) {
          // Mostrar el organismo
          return <span className="text-sm text-gray-600 italic">{group.organismo || 'Sin organismo'}</span>;
        } else if (subtask.type === 'INGRESO' || 
                  subtask.type === 'RESPUESTA' || 
                  (subtask.type === 'RESOLUCIÓN' && subtask.resolutionType === 'DESISTIMIENTO')) {
          // Mostrar el icono con los responsables de TRAMITACIÓN
          return (
            <div 
              className={`flex justify-center ${task.responsible && task.responsible.length > 0 ? 'cursor-pointer hover:opacity-80' : ''}`}
              title={task.responsible && task.responsible.length > 0 ? 
                `Responsables: ${task.responsible.map(u => `${u.firstname} ${u.lastname}`).join(', ')}` : 
                'Sin responsables asignados'}
            >
              <UserIcon 
                className={`w-5 h-5 ${task.responsible && task.responsible.length > 0 ? 'text-green-500' : 'text-gray-400'}`}
              />
            </div>
          );
        }
        return null;
      })()} */}
    </td>
                        <td className="p-2  text-center">
                          {renderEditableField('subtask', groupIndex, taskIndex, 'start', subtask.start, subtaskIndex)}
                        </td>
                        <td className="p-2 text-center">
                          {renderEditableField('subtask', groupIndex, taskIndex, 'end', subtask.end, subtaskIndex)}
                        </td>
                        <td className="p-2 text-center">
                          {renderEditableField('subtask', groupIndex, taskIndex, 'duration', subtask.duration, subtaskIndex)}
                        </td>
                        <td className="p-2 text-center">
                          {renderEditableField('subtask', groupIndex, taskIndex, 'dependsOn', subtask.dependsOn, subtaskIndex)}
                        </td>
                        <td className="p-2 text-center">
      <SubtaskStatusField
        subtask={{
          isClosed: subtask.isClosed ?? false,
          progress: subtask.progress ?? 0
        }}
        groupIndex={groupIndex}
        taskIndex={taskIndex}
        subtaskIndex={subtaskIndex}
        subprocessColor={subprocessColor}
        onSave={handleSave}
        disabled={!group.enabled || !task.enabled || !subtask.enabled}
      />
    </td>

                        <td className="p-2">
                          <div className="flex justify-center items-center space-x-1">
                            <button
                              onClick={() => moveSubtask(groupIndex, taskIndex, subtaskIndex, 'up')}
                              
                              
                              disabled={subtaskIndex === 0}
                              className="p-1 border-black border rounded-lg"
                            >
                              <ArrowUp className="w-4 h-4" />
                            </button>
                            <button
                              onClick={() => moveSubtask(groupIndex, taskIndex, subtaskIndex, 'down')}
                              
                              
                              disabled={subtaskIndex === (task.subtasks?.length ?? 0) - 1}
                              className="p-1 border-black border rounded-lg"
                            >
                              <ArrowDown className="w-4 h-4" />
                            </button>
                            <button
                              onClick={() => deleteSubtask(groupIndex, taskIndex, subtaskIndex)}
                              
                              
                              className="text-red-500 border-red-500 border rounded-lg p-1"
                            >
                              <Trash2 className="w-4 h-4" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                        </React.Fragment>

                  );
                  })}
                   </tbody>
                    </table>
                  ) : (
                    <p className="text-center text-gray-500 py-4">No hay tareas en este agrupador.</p>
                  )}
                  <button
                    onClick={() => addNewTask(groupIndex)}
                    
                    
                    className="border border-black flex p-2 mt-2"
                  >
                    <Plus className="w-4 h-4 mr-2" />  Añadir nueva tarea
                  </button>
                </div>
              )}
            </div>
          );
        })}
      </CardContent>
     
     
      <div className="flex justify-center">
      {/*

        <button
          onClick={handleSavePlantilla}
          
          size="lg"
          className="bg-teal-500 hover:bg-teal-700 text-white"
          disabled={isPlantillaEmpty()}
        >
          <Save className="w-4 h-4 mr-2" /> Guardar Plantilla
        </button>
        <button
          onClick={handleSaveAsOpen}
          
          size="lg"
          className="bg-blue-500 hover:bg-blue-700 text-white"
        >
          <Save className="w-4 h-4 mr-2" /> Guardar como
        </button>
      {/*  <button
          onClick={handleClearPlantilla}
          
          size="lg"
          className="bg-red-500 text-white"
        >
          <Trash2 className="w-4 h-4 mr-2" /> Limpiar Plantilla
        </button>*/}
      </div>
      {labelMessage && (
        <div className="">
          <LabelMessage type={labelMessage.type} message={labelMessage.message} />
        </div>
      )}

      <Dialog width="500px" isOpen={isSaveAsOpen} onClose={handleSaveAsClose}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Guardar plantilla como</DialogTitle>
          </DialogHeader>
          {saveAsError && (
  <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
    <span className="block sm:inline">{saveAsError}</span>
  </div>
)}
          <Input
            placeholder="Nombre de la plantilla"
            value={templateName}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setTemplateName(e.target.value)}
            className='mb-2'
          />
          <Input
            placeholder="Descripción de la plantilla"
            value={templateDescription}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setTemplateDescription(e.target.value)}
          />
          
          <DialogFooter>
            <button onClick={handleSaveAsClose} className='borer border-black p-3'>Cancelar</button>
            <button onClick={() => handleSaveAs(false)} className="p-3 bg-teal-500 ml-3 border text-white hover:bg-teal-800" >Guardar</button>

          </DialogFooter>
        </DialogContent>
      </Dialog>
      <Dialog isOpen={isOverwriteConfirmOpen} onClose={handleOverwriteCancel}>
  <DialogContent>
    <DialogHeader>
      <DialogTitle>Confirmar sobrescritura</DialogTitle>
    </DialogHeader>
    <p>Ya existe una plantilla con este nombre. ¿Desea sobrescribirla?</p>
    <DialogFooter>
      <button onClick={handleOverwriteCancel} >Cancelar</button>
      <button onClick={handleOverwriteConfirm} className="bg-red-500 ml-3 border text-white hover:bg-red-800" >Sobrescribir</button>
    </DialogFooter>
  </DialogContent>
</Dialog>
<FloatingActionBar />

<GanttModal />
<ResolutionModal
      isOpen={resolutionModalOpen}
      onClose={() => setResolutionModalOpen(false)}
      onSave={(type, customType) => {
        if (currentResolutionSubtask) {
          handleResolutionTypeChange(
            currentResolutionSubtask.groupIndex,
            currentResolutionSubtask.taskIndex,
            currentResolutionSubtask.subtaskIndex,
            type,
            customType
          );
        }
        setResolutionModalOpen(false);
      }}
    />
    <AssignToProcessModal />
    <ConfirmDialog
  isOpen={isConfirmExitDialogOpen}
  onClose={handleCancelExit}
  onConfirm={handleConfirmExit}
  title="Cambios sin guardar"
  message="Hay cambios sin guardar. ¿Estás seguro que deseas salir? Los cambios se perderán."
  confirmButtonText="Salir sin guardar"
  cancelButtonText="Cancelar"
/>
    </Card>
      
  
 
  );
  
};

export default AdvancedGanttChart;