import React, { useState, useRef, useEffect, useMemo } from 'react';
import { ChevronLeft, ChevronRight, Paperclip, Eye, Trash, Edit, Mail, User, Users, Clock, Tag, Filter, X, Loader, ChevronDown, ChevronUp } from 'lucide-react';
import { Dialog, DialogContent } from '../ui/dialog';
import axios from 'axios';
import toast from 'react-hot-toast';

interface Subproceso {
  id: number;
  nombre: string;
}

interface Process {
  id: number;
  codigo: string;
  nombreProceso: string;
  // Add other properties as needed
}

interface Document {
  id: number;
  project: string;
  projectName: string;
  projectCode: string;
  document: string;
  title: string;
  revision: string;
  version: number;
  date: string;
  issuedBy: string;
  comment: string;
  path: string;
  flagged: boolean;
  subVersions: any[];
}

interface Transmittal {
  numero: number;
  codigo_proyecto: string;
  subprocesos: string[];
  comuna: string;
  emisor: string;
  destinatarios: string;
  cc: string;
  asunto: string;
  referencia: string;
  descripcion: string;
  subproceso: string;
  detalle_transmittal: string;
  documentos_seleccionados: string; // JSON en bruto
  estado: string;
  comentarios: string;
  codigo_transmittal: string;
  fecha: string;
  ruta: string;
}


interface Process {
  id: number;
  codigo: string;
  nombreProceso: string;
  comuna: string;
}

interface Document {
  id: number;
  project: string;
  projectName: string;
  projectCode: string;
  document: string;
  title: string;
  revision: string;
  version: number;
  date: string;
  issuedBy: string;
  comment: string;
  path: string;
  flagged: boolean;
  subVersions: any[];
  itemType?: 'document' | 'transmittal';
}

interface Subproceso {
  id: number;
  subproceso: string;
}

interface EmailOption {
  id: number;
  name: string;
  email: string;
}

interface Nivel {
  nivel_id: number;
  titulo: string;
}

interface Data {
  nivel_id: number;
  nomenclatura: string;
  concepto: string;
}

interface TransmittalModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit?: () => void;
  documents: Document[];
  fetchDocuments?: () => Promise<void>;
}

// Input Field Component
const InputField: React.FC<{
  label: string;
  placeholder?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
  inputClassName?: string;
}> = ({ label, placeholder, value, onChange, disabled, readOnly, className, inputClassName }) => (
  <div className={`mb-4 ${className}`}>
    {label && <label className="block text-sm font-medium text-gray-700 mb-1">{label}</label>}
    <input
      type="text"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      disabled={disabled}
      readOnly={readOnly}
      className={`w-full border border-gray-300 rounded-md p-2 ${disabled ? 'bg-gray-100' : ''} ${inputClassName || ''}`}
    />
  </div>
);

// Button Component
const Button: React.FC<{
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  className?: string;
  children: React.ReactNode;
}> = ({ onClick, disabled, className, children }) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={`px-4 py-2 rounded-md transition-colors ${className} ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
  >
    {children}
  </button>
);

// TransmittalComboBox Component
const TransmittalComboBox: React.FC<{
  detalleTransmittal: { [key: string]: boolean };
  setDetalleTransmittal: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
}> = ({ detalleTransmittal, setDetalleTransmittal }) => {
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;
    setDetalleTransmittal(prev => ({
      ...prev,
      [id]: checked
    }));
  };

  return (
    <div className="grid grid-cols-2 gap-4">
      <div className="flex items-center space-x-2">
        <input
          type="checkbox"
          id="Preliminar"
          checked={detalleTransmittal.Preliminar}
          onChange={handleCheckboxChange}
          className="h-4 w-4 text-cyan-600 focus:ring-cyan-500 border-gray-300 rounded"
        />
        <label htmlFor="Preliminar" className="text-sm text-gray-700">Preliminar</label>
      </div>
      <div className="flex items-center space-x-2">
        <input
          type="checkbox"
          id="Para Información"
          checked={detalleTransmittal['Para Información']}
          onChange={handleCheckboxChange}
          className="h-4 w-4 text-cyan-600 focus:ring-cyan-500 border-gray-300 rounded"
        />
        <label htmlFor="Para Información" className="text-sm text-gray-700">Para Información</label>
      </div>
      <div className="flex items-center space-x-2">
        <input
          type="checkbox"
          id="Para Coordinación"
          checked={detalleTransmittal['Para Coordinación']}
          onChange={handleCheckboxChange}
          className="h-4 w-4 text-cyan-600 focus:ring-cyan-500 border-gray-300 rounded"
        />
        <label htmlFor="Para Coordinación" className="text-sm text-gray-700">Para Coordinación</label>
      </div>
      <div className="flex items-center space-x-2">
        <input
          type="checkbox"
          id="Para Aprobación"
          checked={detalleTransmittal['Para Aprobación']}
          onChange={handleCheckboxChange}
          className="h-4 w-4 text-cyan-600 focus:ring-cyan-500 border-gray-300 rounded"
        />
        <label htmlFor="Para Aprobación" className="text-sm text-gray-700">Para Aprobación</label>
      </div>
      <div className="flex items-center space-x-2">
        <input
          type="checkbox"
          id="Para Construcción"
          checked={detalleTransmittal['Para Construcción']}
          onChange={handleCheckboxChange}
          className="h-4 w-4 text-cyan-600 focus:ring-cyan-500 border-gray-300 rounded"
        />
        <label htmlFor="Para Construcción" className="text-sm text-gray-700">Para Construcción</label>
      </div>
    </div>
  );
};

interface AutoCompleteEmailInputProps {
  label: string;
  emailOptions: EmailOption[];
  onChange: (value: string) => void;
}
// AutoCompleteEmailInput Component
const AutoCompleteEmailInput: React.FC<AutoCompleteEmailInputProps> = ({ label, emailOptions, onChange }) => {
  const [query, setQuery] = useState('');
  const [filteredEmails, setFilteredEmails] = useState<EmailOption[]>([]);
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isFocused) {
      const lastEmail = query.split(',').pop()?.trim() || '';
      if (lastEmail === '') {
        setFilteredEmails(emailOptions);
      } else {
        setFilteredEmails(
          emailOptions.filter(option => 
            option.email.toLowerCase().includes(lastEmail.toLowerCase()) || 
            option.name.toLowerCase().includes(lastEmail.toLowerCase())
          )
        );
      }
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  }, [query, emailOptions, isFocused]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setQuery(value);
    onChange(value);
    
    // Si el último carácter es una coma, abrimos las sugerencias
    if (value.endsWith(',')) {
      setShowSuggestions(true);
      setIsFocused(true);
    }
  };

  const handleSuggestionClick = (option: EmailOption) => {
    const emails = query.split(',');
    emails.pop();
    const newEmail = `${option.name} <${option.email}>`;
    const updatedQuery = [...emails, newEmail].join(', ').trim();
    setQuery(updatedQuery);
    onChange(updatedQuery);

    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        const length = updatedQuery.length;
        inputRef.current.setSelectionRange(length, length);
        setIsAnimating(true);
        setTimeout(() => setIsAnimating(false), 300);
      }
    }, 0);

    // Cerramos las sugerencias después de seleccionar una
    setShowSuggestions(false);
  };


  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const emails = query.split(',');
      const lastEmail = emails.pop()?.trim() || '';
      if (lastEmail) {
        const updatedQuery = [...emails, lastEmail].join(', ').trim();
        setQuery(updatedQuery);
        onChange(updatedQuery);
        setShowSuggestions(true);
        setIsFocused(true);
      }
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setTimeout(() => {
      setIsFocused(false);
    }, 200);
  };




  return (
    <div className="relative">
      <label className="block text-sm font-semibold mb-2">{label}</label>
      <input
        ref={inputRef}
        type="text"
        value={query}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className={`p-2 border border-gray-300 rounded w-full ${isAnimating ? 'animate-pulse' : ''}`}
        placeholder="Ingrese correos electrónicos separados por comas"
      />
      {showSuggestions && filteredEmails.length > 0 && (
        <ul className="absolute top-full left-0 mt-2 w-full bg-white border border-gray-300 rounded shadow-lg max-h-60 overflow-y-auto">
          {filteredEmails.map(option => (
            <li
              key={option.email}
              onMouseDown={() => handleSuggestionClick(option)}
              className="p-2 cursor-pointer hover:bg-gray-200"
            >
              {`${option.name} <${option.email}>`}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

interface Process {
  id: number;
  codigo: string;
  nombreProceso: string;
  comuna: string;
}

interface Document {
  id: number;
  project: string;
  projectName: string;
  projectCode: string;
  document: string;
  title: string;
  revision: string;
  version: number;
  date: string;
  issuedBy: string;
  comment: string;
  path: string;
  flagged: boolean;
  subVersions: any[];
  itemType?: 'document' | 'transmittal';
}

interface Subproceso {
  id: number;
  subproceso: string;
}

interface EmailOption {
  id: number;
  name: string;
  email: string;
}

interface Nivel {
  nivel_id: number;
  titulo: string;
}

interface Data {
  nivel_id: number;
  nomenclatura: string;
  concepto: string;
}

interface TransmittalModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit?: () => void;
  documents: Document[];
  fetchDocuments?: () => Promise<void>;
}

// Main TransmittalModal Component
const TransmittalModal: React.FC<TransmittalModalProps> = ({ isOpen, onClose, onSubmit, documents, fetchDocuments }) => {
  // State for project and filtering
  const [projectFilter, setProjectFilter] = useState('');
  const [processFilter, setProcessFilter] = useState('');
  const [processes, setProcesses] = useState<Process[]>([]);
  
  // State for selected project details
  const [selectedNombreProyecto, setSelectedNombreProyecto] = useState('');
  const [selectedCodigoProyecto, setSelectedCodigoProyecto] = useState('');
  const [selectedComuna, setSelectedComuna] = useState('');
  
  // State for transmittal details
  const [transmittalNumber, setTransmittalNumber] = useState('');
  const [codigoTransmittal, setCodigoTransmittal] = useState('');
  const [asunto, setAsunto] = useState('');
  const [referencia, setReferencia] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [comentarios, setComentarios] = useState('');
    
  // State for user details
  const [username, setUsername] = useState<string | null>(null);
  const [emisor, setEmisor] = useState<string>('');
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [userTitle, setUserTitle] = useState<string | null>(null);
  
  // State for email inputs
  const [toEmail, setToEmail] = useState('');
  const [ccEmail, setCcEmail] = useState('');
  const [emailOptions, setEmailOptions] = useState<EmailOption[]>([]);
  
  // State for subprocesos
  const [subprocesos, setSubprocesos] = useState<Subproceso[]>([]);
  const [selectedSubproceso, setSelectedSubproceso] = useState('');
  
  // State for UI control
  const [loading, setLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState<{ text: string, type: 'success' | 'error' } | null>(null);
  
  // State for detalle transmittal checkboxes
  const [detalleTransmittal, setDetalleTransmittal] = useState<{ [key: string]: boolean }>({
    Preliminar: false,
    'Para Información': false,
    'Para Coordinación': false,
    'Para Aprobación': false,
    'Para Construcción': false,
  });
  
  // State for document filtering and sorting
  const [sortColumn, setSortColumn] = useState<string>('fecha'); // Inicializar con 'fecha'
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc'); // Orden descendente por defecto
  
  const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);
  const [filteredDocuments, setFilteredDocuments] = useState<Document[]>([]);
  const [documentFilter, setDocumentFilter] = useState('');
  
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [documentsPerPage, setDocumentsPerPage] = useState(5);

  // Effect for loading transmittal number
  useEffect(() => {
    if (isOpen) {
      fetchNextTransmittalNumber();
      fetchProcesses();
      fetchEmails();
      setFilteredDocuments(documents);
      setCurrentPage(1);
    }
  }, [isOpen, documents]);

  // Effect for loading user data from session
  useEffect(() => {
    const storedFirstName = sessionStorage.getItem('firstName');
    const storedLastName = sessionStorage.getItem('lastName');
    const storedEmail = sessionStorage.getItem('email');
    const storedTitle = sessionStorage.getItem('title');
    
    if (storedFirstName && storedLastName) {
      setUsername(`${storedFirstName} ${storedLastName}`);
      setEmisor(`${storedFirstName} ${storedLastName} <${storedEmail}>`);
    }
     
    if (storedEmail) {
      setUserEmail(storedEmail);
    }
     
    if (storedTitle) {
      setUserTitle(storedTitle);
    }
  }, []);

  // Effect for generating codigo transmittal
  useEffect(() => {
    if (transmittalNumber && selectedCodigoProyecto) {
      setCodigoTransmittal(`${selectedCodigoProyecto}-P-TRL-AI-${transmittalNumber}`);
    }
  }, [transmittalNumber, selectedCodigoProyecto]);

  // Effect for loading subprocesos
  useEffect(() => {
    if (selectedCodigoProyecto) {
      fetchSubprocesos();
    }
  }, [selectedCodigoProyecto]);

  // Effect to validate form fields
  useEffect(() => {
    const shouldDisableButton = 
      !processFilter || 
      !toEmail || 
      !asunto || 
      !selectedSubproceso;
  
    setIsButtonDisabled(shouldDisableButton);
  }, [processFilter, toEmail, asunto, selectedSubproceso]);

  // Effect for document filtering
  useEffect(() => {
    if (documentFilter) {
      const filtered = documents.filter(doc => 
        doc.title.toLowerCase().includes(documentFilter.toLowerCase()) ||
        doc.document.toLowerCase().includes(documentFilter.toLowerCase())
      );
      setFilteredDocuments(filtered);
    } else {
      setFilteredDocuments(documents);
    }
    setCurrentPage(1); // Reset to first page on filter change
  }, [documentFilter, documents]);
  
  // Calculate pagination
  const indexOfLastDocument = currentPage * documentsPerPage;
  const indexOfFirstDocument = indexOfLastDocument - documentsPerPage;
  const currentDocuments = filteredDocuments.slice(indexOfFirstDocument, indexOfLastDocument);
  const totalPages = Math.ceil(filteredDocuments.length / documentsPerPage);
  
  // Handle page change
  const paginate = (pageNumber: number) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  // Effect to reset form when closed
  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen]);

  // Fetch next transmittal number
  const fetchNextTransmittalNumber = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/transmittal/get_next_transmittal_number.php`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setTransmittalNumber(data.transmittal_number.toString().padStart(3, '0'));
    } catch (error) {
      console.error('Error fetching next transmittal number:', error);
    }
  };

  // Fetch processes
  const fetchProcesses = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/proceso/get_processes.php?type=type4`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data: Process[] = await response.json();
      setProcesses(data);
    } catch (error) {
      console.error('Error fetching processes:', error);
    }
  };

  // Fetch subprocesos
  const fetchSubprocesos = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/transmittal/get_subprocesos.php?codigo=${selectedCodigoProyecto}`);
      const data = await response.json();
      if (data && typeof data === 'object' && Array.isArray(data.subprocesos)) {
        setSubprocesos(data.subprocesos);
      } else {
        console.error('Error al obtener los subprocesos', data);
      }
    } catch (error) {
      console.error('Error de red', error);
    }
  };

  // Fetch email options
  const fetchEmails = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/php/pages/transmittal/get_emails.php`);
      setEmailOptions(response.data);
    } catch (error) {
      console.error('Error al obtener correos:', error);
    }
  };

  // Reset form to initial state
  const resetForm = () => {
    setSelectedNombreProyecto('');
    setSelectedCodigoProyecto('');
    setSelectedComuna('');
    setProcessFilter('');
    setIsButtonDisabled(false);
    setIsLoading(false);
    setCodigoTransmittal('');
    setToEmail('');
    setCcEmail('');
    setAsunto('');
    setReferencia('');
    setDescripcion('');
    setSelectedSubproceso('');
    setDetalleTransmittal({
      Preliminar: false,
      'Para Información': false,
      'Para Coordinación': false,
      'Para Aprobación': false,
      'Para Construcción': false,
    });
    setComentarios('');
    setSortColumn('fecha');
    setSortDirection('desc');
    setMessage(null);
    setSelectedDocuments([]);
    setDocumentFilter('');
  };

  // Handle project filter change
  const handleProjectFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCodigo = e.target.value;
    const selectedProcess = processes.find(process => process.codigo === selectedCodigo);
    
    if (selectedProcess) {
      setProcessFilter(selectedCodigo);
      setSelectedNombreProyecto(selectedProcess.nombreProceso);
      setSelectedCodigoProyecto(selectedProcess.codigo);
      setSelectedComuna(selectedProcess.comuna);
    } else {
      setProcessFilter('');
      setSelectedNombreProyecto('');
      setSelectedCodigoProyecto('');
      setSelectedComuna('');
    }
  };

  // Handle subproceso change
  const handleSubprocesoChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = event.target.value;
    const selectedSubp = subprocesos.find(sub => sub.id.toString() === selectedId);
    
    if (selectedSubp) {
      setSelectedSubproceso(selectedSubp.subproceso);
    }
  };

  // Handle asunto change
  const handleAsuntoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAsunto(e.target.value);
  };

  // Handle referencia change
  const handleReferenciaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReferencia(e.target.value);
  };

  // Handle descripcion change
  const handleDescripcionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescripcion(e.target.value);
  };

  // Handle comentarios change
  const handleComentariosChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length <= 300) {
      setComentarios(e.target.value);
    }
  };

  // Handle document sorting
  const handleSort = (column: string) => {
    setSortDirection(sortColumn === column && sortDirection === 'asc' ? 'desc' : 'asc');
    setSortColumn(column);
  };

  // Handle document selection
  const toggleDocumentSelection = (id: string | number) => {
    const idStr = id.toString();
    setSelectedDocuments(prev =>
      prev.includes(idStr) ? prev.filter(docId => docId !== idStr) : [...prev, idStr]
    );
  };

  // Get row class name for document selection
  const getRowClassName = (id: string | number) => {
    return selectedDocuments.includes(id.toString()) ? 'bg-blue-100' : '';
  };

  // Handle document filter change
  const handleDocumentFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDocumentFilter(e.target.value);
  };

  // Function removed - replaced by pagination

  // View file
  const viewFile = (path: string, type: 'document' | 'transmittal' | undefined) => {
    if (!path) {
      console.error('El path es undefined o vacío.');
      return;
    }

    let fullUrl = path;

    if (type === 'document') {
      const baseUrl = process.env.REACT_APP_DOCS_URL;
      if (!baseUrl) {
        console.error('REACT_APP_DOCS_URL no está definido en el .env');
        return;
      }

      fullUrl = `${baseUrl}/uploads/${path}`;
    } else if (type === 'transmittal') {
      fullUrl = path;
    } else {
      const baseUrl = process.env.REACT_APP_DOCS_URL;
      if (!baseUrl) {
        console.error('REACT_APP_DOCS_URL no está definido en el .env');
        return;
      }

      fullUrl = `${baseUrl}/uploads/${path}`;
    }

    console.log('Abriendo URL:', fullUrl);
    window.open(fullUrl, '_blank');
  };

  // Extract document IDs
  const extractDocumentIds = (docs: Document[]) => {
    const selectedDocs = docs.filter(doc => selectedDocuments.includes(doc.id.toString()));
    return selectedDocs.map(doc => doc.id);
  };

  // Handle preview
  const handlePreview = async () => {
    setLoading(true);
    try {
      // Extrae los IDs de los documentos
      const selectedDocs = documents.filter(doc => selectedDocuments.includes(doc.id.toString()));
      const documentIds = extractDocumentIds(documents);
      
      // Solicitar URLs de documentos
      const responseDownloads = await axios.post(
        `${process.env.REACT_APP_API_URL}/php/pages/controldocument/busquedarapida/download_documents.php?type=type2`,
        { documentIds },
        { responseType: 'json' }
      );
      
      const documentUrls = responseDownloads.data.url;
      
      // Request transmittal preview
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/controldocument/busquedarapida/generar_transmital.php?type=type1`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          transmittal_number: parseInt(transmittalNumber, 10),
          codigo_proyecto: selectedCodigoProyecto,
          nombre_proyecto: selectedNombreProyecto,
          comuna: selectedComuna,
          emisor: username,
          emisorCorreo: userEmail,
          emisorTitle: userTitle !== null && userTitle !== undefined ? userTitle : "",
          destinatarios: toEmail,
          cc: ccEmail,
          asunto: asunto,
          referencia,
          descripcion,
          subproceso: selectedSubproceso,
          detalle_transmittal: detalleTransmittal,
          documentos_seleccionados: selectedDocs,
          zipUrl: documentUrls,
          comentarios
        })
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const blob = await response.blob();
      const pdfUrl = URL.createObjectURL(blob);
      window.open(pdfUrl);
    } catch (error) {
      console.error('Error generating PDF:', error);
    } finally {
      setLoading(false);
    }
  };

  // Save transmittal
  const saveTransmittal = async () => {
    try {
      // Extrae los IDs de los documentos
      const selectedDocs = documents.filter(doc => selectedDocuments.includes(doc.id.toString()));
      const documentIds = extractDocumentIds(documents);
      
      // Solicitar URLs de documentos
      const responseDownloads = await axios.post(
        `${process.env.REACT_APP_API_URL}/php/pages/controldocument/busquedarapida/download_documents.php?type=type2`,
        { documentIds },
        { responseType: 'json' }
      );
      
      const documentUrls = responseDownloads.data.url;
  
      // Generate transmittal file
      const responseFileTRL = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/controldocument/busquedarapida/generar_transmital.php?type=type2`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          transmittal_number: parseInt(transmittalNumber, 10),
          codigo_proyecto: selectedCodigoProyecto,
          nombre_proyecto: selectedNombreProyecto,
          comuna: selectedComuna,
          emisor: username,
          emisorCorreo: userEmail,
          emisorTitle: userTitle !== null && userTitle !== undefined ? userTitle : "",
          destinatarios: toEmail,
          cc: ccEmail,
          asunto: asunto,
          referencia,
          descripcion,
          subproceso: selectedSubproceso,
          detalle_transmittal: detalleTransmittal,
          documentos_seleccionados: selectedDocs,
          zipUrl: documentUrls,
          comentarios
        })
      });
      
      if (!responseFileTRL.ok) {
        throw new Error(`HTTP error! Status: ${responseFileTRL.status}`);
      }
      
      // Get generated PDF URL from response
      const fileTRLResponse = await responseFileTRL.json();
      if (!fileTRLResponse.pdfUrl) {
        throw new Error('No se recibió una URL de PDF en la respuesta.');
      }
      
      const transmittalLink = fileTRLResponse.pdfUrl;
      
      // Save transmittal in the database
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/transmittal/save_transmittal.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          transmittal_number: parseInt(transmittalNumber, 10),
          pdfUrl: transmittalLink,
          codigo_proyecto: selectedCodigoProyecto,
          nombre_proyecto: selectedNombreProyecto,
          comuna: selectedComuna,
          emisor: username,
          destinatarios: toEmail,
          cc: ccEmail,
          asunto: asunto,
          referencia,
          descripcion,
          subproceso: selectedSubproceso,
          detalle_transmittal: detalleTransmittal,
          documentos_seleccionados: selectedDocs,
          zipUrl: documentUrls,
          comentarios
        })
      });
      
      const data = await response.json();
      if (!data.success) {
        throw new Error(data.error || 'Error al guardar el transmittal');
      }
      
      return data.transmittal_code;
    } catch (error) {
      console.error('Error saving transmittal:', error);
      throw error;
    }
  };

  // Handle submit
  const handleSubmit = async () => {
    setIsLoading(true);
    setIsButtonDisabled(true);
    
    try {
      await saveTransmittal();
      
      setMessage({
        text: 'Transmittal enviado exitosamente.',
        type: 'success'
      });
      toast.success('Transmittal enviado exitosamente.');
      
      // Reload documents if fetchDocuments is provided
      if (fetchDocuments) {
        await fetchDocuments();
      }
      
      // Close modal after 3 seconds
      setTimeout(() => {
        onClose();
        if (onSubmit) onSubmit();
      }, 3000);
    } catch (error) {
      setMessage({
        text: 'Error al enviar el transmittal.',
        type: 'error'
      });
      setIsLoading(false);
      setIsButtonDisabled(false);
    }
  };

  return (
    <Dialog width="auto" height="full" isOpen={isOpen} onClose={onClose} className="overflow-y-auto">
          <DialogContent className="max-h-screen mx-auto p-6 bg-white rounded-lg shadow-lg overflow-y-auto w-full max-w-3xl">
   
           <div className="border-b top-0  p-4 flex justify-between items-center shadow-sm">
           <h1 className="text-2xl font-bold mb-1 text-cyan-700">📄 Nuevo Transmittal</h1>
              <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                <X size={20} /> 
              </button>
            </div> 

        
        <div className="mt-5 grid grid-cols-1 md:grid-cols-2 gap-4">
          <InputField 
            label="N° Transmittal" 
            placeholder="N° Transmittal" 
            disabled
            value={transmittalNumber} 
            readOnly 
            className="text-sm"
          />
          <InputField 
            label="Código Transmittal" 
            placeholder="Código Transmittal" 
            value={codigoTransmittal} 
            readOnly 
            disabled
            className="text-sm"
          />
        </div>
        
        <h2 className="text-xl font-semibold text-cyan-600 mt-4">Proceso</h2>
        <select 
          id="processFilter"
          className="w-full border border-gray-300 rounded-md p-2 focus:ring-cyan-500 focus:border-cyan-500"
          value={processFilter}
          onChange={handleProjectFilterChange}
          aria-label="Filtro de Procesos"
        >
          <option value="">Seleccionar proceso</option>
          {processes.length > 0 ? (
            processes.map((process) => (
                              <option key={process.codigo} value={process.codigo}>
                {`${process.codigo} - ${process.nombreProceso} - ${process.comuna}`}
              </option>
            ))
          ) : (
            <option disabled>No hay procesos disponibles</option>
          )}
        </select>
        
        <div className="mt-6">
          <h2 className="text-xl font-semibold text-cyan-600">Subproceso</h2>
          <select
            className="w-full border border-gray-300 rounded-md p-2 focus:ring-cyan-500 focus:border-cyan-500"
            onChange={handleSubprocesoChange}
            value={subprocesos.find(sub => sub.subproceso === selectedSubproceso)?.id || ''}
          >
            <option value="">Seleccione un subproceso</option>
            {subprocesos.map((subproceso) => (
              <option key={subproceso.id} value={subproceso.id}>
                {subproceso.subproceso}
              </option>
            ))}
          </select>
        </div>
        
        <div className="mt-6">
          <h2 className="text-xl font-semibold text-cyan-600">Asunto</h2>
          <InputField 
            label="" 
            placeholder="Asunto" 
            className="mb-4 text-sm"
            value={asunto}
            onChange={handleAsuntoChange}
            inputClassName="w-full border-2 border-cyan-500 rounded-md p-2 focus:ring-2 focus:ring-cyan-500"
          />
        </div>
        
        <div className="mb-8 mt-5">
          <h2 className="text-xl font-semibold mb-4 text-cyan-600">Propósito Transmittal</h2>
          <div className="bg-white rounded-md">
            <TransmittalComboBox
              detalleTransmittal={detalleTransmittal}
              setDetalleTransmittal={setDetalleTransmittal}
            />
          </div>
        </div>
        
        <div className="mb-6">
        <h2 className="text-xl font-semibold mb-2 text-cyan-600">Destinatarios</h2>
         {/* <div className="grid grid-cols-1 md:grid-cols-3 gap-2"> */}
            <InputField label="De:" value={emisor} disabled readOnly className="text-sm" />
          
          <AutoCompleteEmailInput
        label="Para:"
        emailOptions={emailOptions}
        onChange={setToEmail}
      />
      <br></br>
       <AutoCompleteEmailInput
        label="CC:"
        emailOptions={emailOptions}
        onChange={setCcEmail}
      />
        </div>
        
        <div className="mb-6">
          <h2 className="text-xl font-semibold mb-2 text-cyan-600">Selección de Documentos</h2>
          <div className="mb-4">
            <input
              type="text"
              placeholder="Buscar documentos por título o código..."
              className="w-full border border-gray-300 rounded-md p-2 focus:ring-cyan-500 focus:border-cyan-500"
              value={documentFilter}
              onChange={handleDocumentFilterChange}
            />
          </div>
          
          <div className="overflow-x-auto border rounded-lg">
            <div className="w-full max-w-full">
              <table className="w-full table-fixed divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="w-16 px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <span className="sr-only">Seleccionar</span>
                    </th>
                    <th className="w-1/6 px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider truncate">
                      Documento
                    </th>
                    <th className="w-2/6 px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider truncate">
                      <button onClick={() => handleSort('title')} className="uppercase flex items-center">
                        Título
                        {sortColumn === 'title' ? (sortDirection === 'asc' ? ' 🔼' : ' 🔽') : ''}
                      </button>
                    </th>
                    <th className="w-1/6 px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider truncate">
                      Fecha
                    </th>
                    <th className="w-1/6 px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider truncate">
                      Emitido Por
                    </th>
                    <th className="w-1/6 px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider truncate">
                      Comentario
                    </th>
                    <th className="w-12 px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <span className="sr-only">Ver</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {currentDocuments
                    .sort((a, b) => {
                      if (sortColumn) {
                        const aVal = a[sortColumn as keyof Document]?.toString().toLowerCase() || '';
                        const bVal = b[sortColumn as keyof Document]?.toString().toLowerCase() || '';
                        if (aVal < bVal) return sortDirection === 'asc' ? -1 : 1;
                        if (aVal > bVal) return sortDirection === 'asc' ? 1 : -1;
                      }
                      return 0;
                    })
                    .map((doc) => (
                      <tr 
                        key={doc.id} 
                        className={getRowClassName(doc.id)}
                        onClick={() => toggleDocumentSelection(doc.id)}
                      >
                        <td className="px-2 py-3 whitespace-nowrap">
                          <input
                            type="checkbox"
                            checked={selectedDocuments.includes(doc.id.toString())}
                            onChange={() => toggleDocumentSelection(doc.id)}
                            className="h-4 w-4 text-cyan-600 border-gray-300 rounded focus:ring-cyan-500"
                            onClick={(e) => e.stopPropagation()}
                          />
                        </td>
                        <td className="px-2 py-3 text-sm font-medium text-gray-900 truncate" title={`${doc.document}${doc.revision ? `-REV${doc.revision}` : ''}${doc.version ? `-v${doc.version}` : ''}`}>
                          {`${doc.document}${doc.revision ? `-REV${doc.revision}` : ''}${doc.version ? `-v${doc.version}` : ''}`}
                        </td>
                        <td className="px-2 py-3 text-sm font-medium text-gray-900 truncate" title={doc.title}>
                          {doc.title}
                        </td>
                        <td className="px-2 py-3 text-sm text-gray-500">
                          {doc.date ? (
                            doc.date.includes(' ') ? (
                              <span title={doc.date}>
                                {doc.date.split(' ')[0]}<br/>
                                {doc.date.split(' ')[1]}
                              </span>
                            ) : doc.date
                          ) : '-'}
                        </td>
                        <td className="px-2 py-3 text-sm text-gray-500 truncate" title={doc.issuedBy || '-'}>
                          {doc.issuedBy || '-'}
                        </td>
                        <td className="px-2 py-3 text-sm text-gray-500 truncate" title={doc.comment || '-'}>
                          {doc.comment || '-'}
                        </td>
                        <td className="px-2 py-3 text-sm font-medium text-gray-500">
                          <button 
                            className='text-gray-500 hover:text-cyan-600' 
                            onClick={(e) => {
                              e.stopPropagation();
                              viewFile(doc.path, doc.itemType);
                            }}
                          >
                            <Eye size={18} />
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          
          {/* Pagination Controls */}
          <div className="mt-4 flex items-center justify-between">
            <div className="text-sm text-gray-500">
              Mostrando {filteredDocuments.length > 0 ? indexOfFirstDocument + 1 : 0} - {Math.min(indexOfLastDocument, filteredDocuments.length)} de {filteredDocuments.length} documentos
            </div>
            
            {totalPages > 1 && (
              <div className="flex items-center space-x-1">
                <button
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="p-2 border rounded hover:bg-gray-100 disabled:opacity-50"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                  </svg>
                </button>
                
                {totalPages <= 5 ? (
                  // If less than 5 pages, show all page numbers
                  [...Array(totalPages)].map((_, index) => {
                    const page = index + 1;
                    return (
                      <button
                        key={page}
                        onClick={() => paginate(page)}
                        className={`w-8 h-8 text-sm border rounded ${
                          page === currentPage 
                            ? 'bg-cyan-500 text-white' 
                            : 'hover:bg-gray-100'
                        }`}
                      >
                        {page}
                      </button>
                    );
                  })
                ) : (
                  // If more than 5 pages, show a subset with ellipsis
                  <>
                    {/* First page always shown */}
                    <button
                      onClick={() => paginate(1)}
                      className={`w-8 h-8 text-sm border rounded ${
                        currentPage === 1 ? 'bg-cyan-500 text-white' : 'hover:bg-gray-100'
                      }`}
                    >
                      1
                    </button>
                    
                    {/* Show ellipsis if not on pages 1-3 */}
                    {currentPage > 3 && <span className="px-1">...</span>}
                    
                    {/* Pages around current page */}
                    {[...Array(5)].map((_, index) => {
                      const page = Math.max(2, Math.min(currentPage - 2 + index, totalPages - 1));
                      // Only render if within valid range and not already rendered
                      if (page > 1 && page < totalPages && 
                          (index === 0 || page > Math.max(2, Math.min(currentPage - 2 + index - 1, totalPages - 1)))) {
                        return (
                          <button
                            key={page}
                            onClick={() => paginate(page)}
                            className={`w-8 h-8 text-sm border rounded ${
                              page === currentPage ? 'bg-cyan-500 text-white' : 'hover:bg-gray-100'
                            }`}
                          >
                            {page}
                          </button>
                        );
                      }
                      return null;
                    })}
                    
                    {/* Show ellipsis if not on last 3 pages */}
                    {currentPage < totalPages - 2 && <span className="px-1">...</span>}
                    
                    {/* Last page always shown */}
                    <button
                      onClick={() => paginate(totalPages)}
                      className={`w-8 h-8 text-sm border rounded ${
                        currentPage === totalPages ? 'bg-cyan-500 text-white' : 'hover:bg-gray-100'
                      }`}
                    >
                      {totalPages}
                    </button>
                  </>
                )}
                
                <button
                  onClick={() => paginate(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className="p-2 border rounded hover:bg-gray-100 disabled:opacity-50"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                  </svg>
                </button>
              </div>
            )}
            
            <div className="flex items-center space-x-2">
              <label htmlFor="documentsPerPage" className="text-sm text-gray-500">Mostrar:</label>
              <select
                id="documentsPerPage"
                value={documentsPerPage}
                onChange={(e) => {
                  setDocumentsPerPage(Number(e.target.value));
                  setCurrentPage(1);  // Reset to first page
                }}
                className="border rounded p-1 text-sm"
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
              </select>
            </div>
          </div>
          
          <div className="mt-3 text-sm text-gray-500">
            {selectedDocuments.length} documento(s) seleccionado(s)
          </div>
        </div>
        
        <div className="mt-6">
          <label className="block text-sm font-medium text-gray-700 mb-2">Comentarios</label>
          <textarea 
            className="w-full border-2 border-cyan-500 rounded-md p-3 focus:ring-2 focus:ring-cyan-300 focus:border-cyan-500 transition duration-300" 
            rows={4} 
            value={comentarios}
            onChange={handleComentariosChange}
            placeholder="Ingrese sus comentarios aquí"
          ></textarea>
          <div className="text-right text-xs text-gray-500">
            {comentarios.length}/300 caracteres
          </div>
        </div>
        
        {!message ? (
          <div className="mt-6 flex items-center justify-between">
            <div className="flex-grow">
              <button 
                onClick={onClose} 
                className="bg-gray-500 hover:bg-gray-600 text-white"
              >
                Cancelar
              </button>
            </div>
            <div className="flex space-x-4">
              <button
                disabled={loading || isButtonDisabled} 
                className="bg-cyan-500 hover:bg-cyan-700 text-white font-semibold py-2 px-4 rounded flex items-center gap-2"
                onClick={async (event) => {
                  event.preventDefault();
                  await handlePreview();
                }}
              >
                {loading ? (
                  <>
                    <Loader className="animate-spin w-5 h-5" /> Cargando...
                  </>
                ) : (
                  "Previsualizar"
                )}
              </button>
              <button 
                onClick={handleSubmit} 
                disabled={isLoading || isButtonDisabled}
                className="bg-cyan-600 hover:bg-cyan-700 text-white font-semibold py-2 px-4 rounded flex items-center gap-2"
              >
                {isLoading ? (
                  <>
                    <Loader className="animate-spin w-5 h-5 mr-2" />
                    Enviando...
                  </>
                ) : (
                  'Guardar y enviar'
                )}
              </button>
            </div>
          </div>
        ) : (
          <div
            className={`mt-6 p-4 text-white text-center rounded-md ${
              message.type === 'success' ? 'bg-green-600' : 'bg-red-600'
            }`}
          >
            {message.text}
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};




const BandejaTransmittal: React.FC = () => {
  const [transmittals, setTransmittals] = useState<Transmittal[]>([]);
  const [processes, setProcesses] = useState<Process[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const [subprocesos, setSubprocesos] = useState<Subproceso[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterBy, setFilterBy] = useState('todos');
  const [selectedTransmittal, setSelectedTransmittal] = useState<Transmittal | null>(null);
  const [showDetails, setShowDetails] = useState(false);
  const [isTransmittalModalOpen, setIsTransmittalModalOpen] = useState(false);
  const [documents, setDocuments] = useState<Document[]>([]);


const [sortColumn, setSortColumn] = useState<string | null>(null);
const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

const getRecipientInitials = (recipients: string): string[] => {
  return recipients
    .split(',') // Separa los destinatarios por coma
    .map(name => name.replace(/<.*?>/g, '').trim()) // Elimina el correo dentro de < >
    .map(cleanName => cleanName.split(' ').map(word => word[0] || '').join('').toUpperCase()) // Genera iniciales
    .slice(0, 5); // Solo los primeros 5 destinatarios
};


    // Function to extract only the names from email format
    const getDisplayRecipients = (recipients: string) => {
      if (!recipients) return '';
      
      // If multiple recipients are separated by commas
      if (recipients.includes(',')) {
        // Count the number of recipients
        const recipientCount = recipients.split(',').length;
        
        // Get the first recipient
        let firstRecipient = recipients.split(',')[0].trim();
        
        // Extract name from email format if possible (e.g., "John Doe <john@example.com>")
        if (firstRecipient.includes('<')) {
          firstRecipient = firstRecipient.split('<')[0].trim();
        }
        
        // Return first recipient name + count of others
        if (recipientCount > 1) {
          return `${firstRecipient} + ${recipientCount - 1} más`;
        }
        return firstRecipient;
      }
      
      // Single recipient - extract name if in email format
      if (recipients.includes('<')) {
        return recipients.split('<')[0].trim();
      }
      
      // Just return as is if no special format
      return recipients.length <= 25 ? recipients : recipients.substring(0, 25) + '...';
    };

  const fetchTransmittals = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/transmittal/get_transmittals.php`);
      if (!response.ok) throw new Error('Network response was not ok');
      const data: Transmittal[] = await response.json();
      setTransmittals(data);
    } catch (error) {
      console.error('Failed to fetch transmittals:', error);
    }
  };

  const fetchProcesses = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/proceso/get_processes.php?type=type4`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data: Process[] = await response.json();
      setProcesses(data);
    } catch (error) {
      console.error('Error fetching processes:', error);
    }
  };


  useEffect(() => {
   
    fetchTransmittals();
    fetchProcesses();
    fetchDocuments();

  }, []);

  const fetchDocuments = async () => {
    try {
      // First fetch regular documents
      const docResponse = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/controldocument/busquedarapida/get_docs.php`);
      const docData = await docResponse.json();
      
      let allDocuments = [];
     
      if (Array.isArray(docData) && docData.length > 0) {
        // Add documents with type "document"
        allDocuments = docData.map(doc => ({
          ...doc,
          itemType: 'document'
        }));
      }
      
      // Now fetch transmittals
      try {
        const transmResponse = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/transmittal/get_transmittals.php?type=type2`);
        if (transmResponse.ok) {
          const transmData = await transmResponse.json();
          
          if (Array.isArray(transmData) && transmData.length > 0) {
            // Process transmittals
            const transmittals = transmData.map(transmittal => {
              // Format date if needed
              let formattedDate = transmittal.date;
              if (transmittal.date && transmittal.date.includes('-')) {
                const parts = transmittal.date.split(' ');
                if (parts.length === 2) {
                  const dateParts = parts[0].split('-');
                  if (dateParts.length === 3) {
                    // Only convert if in DD-MM-YYYY format
                    if (dateParts[0].length <= 2) {
                      formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]} ${parts[1]}`;
                    }
                  }
                }
              }
              
              // Extract code from transmittal document code
              let projectCode = '';
              if (transmittal.document) {
                projectCode = transmittal.document.split('-')[0];
              } else if (transmittal.codigo_transmittal) {
                projectCode = transmittal.codigo_transmittal.split('-')[0];
              } else {
                projectCode = transmittal.projectCode || transmittal.codigo_proyecto || '';
              }
              
              // Get projectName
              let projectName = transmittal.nombre_proyecto || transmittal.projectName || ``;
            
              return {
                id: transmittal.id || `transmittal-${Math.random().toString(36).substr(2, 9)}`,
                date: formattedDate,
                document: transmittal.document || transmittal.codigo_transmittal || '',
                projectName: projectName,
                project: projectName,
                projectCode: projectCode,
                path: transmittal.path || transmittal.ruta || '',
                revision: 'A',
                version: 1,
                title: transmittal.title || transmittal.asunto || '',
                subVersions: [],
                flagged: false,
                issuedBy: transmittal.issuedBy || transmittal.emisor || '',
                itemType: 'transmittal',
                comment: transmittal.comment || transmittal.comentarios || '',
              };
            });
            
            // Merge both arrays
            allDocuments = [...allDocuments, ...transmittals];
          }
        }
      } catch (error) {
        console.error('Error fetching transmittals:', error);
      }
      
      // Save everything to documents state
      setDocuments(allDocuments);
      console.log('All documents loaded:', allDocuments.length);
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
  };

   // Function to handle modal open/close
   const openNewTransmittalModal = () => {
    setIsTransmittalModalOpen(true);
  };

  const closeTransmittalModal = () => {
    setIsTransmittalModalOpen(false);
  };

  // Handler for when a transmittal is submitted
  const handleTransmittalSubmitted = () => {
    // Refresh the transmittals list
    fetchTransmittals();
  };

  const filteredTransmittals = useMemo(() => {
    if (!Array.isArray(transmittals)) return [];
    
    return transmittals.filter(t => {
      const matchesSearch = 
        t.asunto.toLowerCase().includes(searchTerm.toLowerCase()) || 
        t.destinatarios.toLowerCase().includes(searchTerm.toLowerCase()) ||
        t.subproceso.toLowerCase().includes(searchTerm.toLowerCase()) ||
        t.codigo_transmittal.toLowerCase().includes(searchTerm.toLowerCase());
      
      if (!matchesSearch) return false;
      
      if (filterBy === 'todos') return true;
      if (filterBy === 'pendiente' && t.estado.toLowerCase() === 'pendiente') return true;
      if (filterBy === 'aprobado' && t.estado.toLowerCase() === 'aprobado') return true;
      if (filterBy === 'en-tramite' && t.estado.toLowerCase() === 'en tramite') return true;
      
      return false;
    });
  }, [transmittals, searchTerm, filterBy]);
  
  const totalPages = useMemo(() => {
    const validRowsPerPage = rowsPerPage > 0 ? rowsPerPage : 1;
    const calculatedPages = Math.ceil(filteredTransmittals.length / validRowsPerPage);
    return Math.max(1, calculatedPages); // Ensure at least 1 page
  }, [filteredTransmittals, rowsPerPage]);
  
  const paginatedTransmittals = useMemo(() => {
    const startIndex = (currentPage - 1) * rowsPerPage;
    return filteredTransmittals.slice(startIndex, startIndex + rowsPerPage);
  }, [filteredTransmittals, currentPage, rowsPerPage]);
  
  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const getProcessNameByCode = (code: string): string => {
  
    
    const process = processes.find(p => p.codigo === code);

    return process ? process.nombreProceso : 'Proceso no encontrado';
  };

  
const handleSort = (column: string) => {
  if (sortColumn === column) {
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc'); // Alterna dirección
  } else {
    setSortColumn(column);
    setSortDirection('desc'); // Reinicia en ascendente
  }
};

// Función para ordenar los transmittals
const sortedTransmittals = [...paginatedTransmittals].sort((a, b) => {
  if (!sortColumn) return 0;

  const key = sortColumn as keyof Transmittal;

  // Special handling for datetime fields
  if (key === 'fecha') {
    // Parse dates - handle different possible date formats
    let dateA: Date;
    let dateB: Date;
    
    // Attempt to parse the date strings
    try {
      // Try standard date format first
      dateA = new Date(a[key] || '');
      dateB = new Date(b[key] || '');
      
      // If either date is invalid, try alternative DD-MM-YYYY HH:MM:SS format
      if (isNaN(dateA.getTime()) && typeof a[key] === 'string') {
        const parts = a[key].split(' ');
        if (parts.length >= 2) {
          const dateParts = parts[0].split('-');
          const timeParts = parts[1].split(':');
          if (dateParts.length === 3 && timeParts.length >= 2) {
            // Convert from DD-MM-YYYY to YYYY-MM-DD for proper Date parsing
            dateA = new Date(
              `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}T${parts[1]}`
            );
          }
        }
      }
      
      if (isNaN(dateB.getTime()) && typeof b[key] === 'string') {
        const parts = b[key].split(' ');
        if (parts.length >= 2) {
          const dateParts = parts[0].split('-');
          const timeParts = parts[1].split(':');
          if (dateParts.length === 3 && timeParts.length >= 2) {
            dateB = new Date(
              `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}T${parts[1]}`
            );
          }
        }
      }
    } catch (e) {
      console.error("Error parsing dates:", e);
      dateA = new Date(0); // fallback to epoch
      dateB = new Date(0);
    }
    
    // Check if dates are valid
    const isValidDateA = !isNaN(dateA.getTime());
    const isValidDateB = !isNaN(dateB.getTime());
    
    // Handle invalid dates
    if (!isValidDateA && !isValidDateB) return 0;
    if (!isValidDateA) return sortDirection === 'asc' ? 1 : -1;
    if (!isValidDateB) return sortDirection === 'asc' ? -1 : 1;
    
    // Compare valid dates with time
    if (dateA < dateB) return sortDirection === 'asc' ? -1 : 1;
    if (dateA > dateB) return sortDirection === 'asc' ? 1 : -1;
    return 0;
  }
  
  // Regular string/number comparison for non-date fields
  let valueA = a[key];
  let valueB = b[key];

  if (typeof valueA === 'string') valueA = valueA.toLowerCase();
  if (typeof valueB === 'string') valueB = valueB.toLowerCase();

  if (valueA < valueB) return sortDirection === 'asc' ? -1 : 1;
  if (valueA > valueB) return sortDirection === 'asc' ? 1 : -1;
  return 0;
});


  const viewFile = (path: string, type: 'document' | 'transmittal') => {
    if (!path) {
        console.error('El path es undefined o vacío.');
        return;
    }

    let fullUrl = path;

    if ((type === 'document') ){
        const baseUrl = process.env.REACT_APP_DOCS_URL; // Asegúrate de tener esto en el .env
        if (!baseUrl) {
            console.error('REACT_APP_DOCS_URL no está definido en el .env');
            return;
        }

        fullUrl = `${baseUrl}/uploads/${path}`;
    } else if (type === 'transmittal') {
        // Si es transmittal, el path ya es la URL completa.
        fullUrl = path;
    }else{
      const baseUrl = process.env.REACT_APP_DOCS_URL; // Asegúrate de tener esto en el .env
      if (!baseUrl) {
          console.error('REACT_APP_DOCS_URL no está definido en el .env');
          return;
      }

      fullUrl = `${baseUrl}/uploads/${path}`;
    }

    console.log('Abriendo URL:', fullUrl);
    window.open(fullUrl, '_blank');
};

  
  const handleEdit = (transmittal: Transmittal) => {
    console.log('Edit', transmittal);
  };

  const handleDelete = (transmittal: Transmittal) => {
    console.log('Delete', transmittal);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const openModal = (files: string[]) => {
    setSelectedFiles(files);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedFiles([]);
  };

  const viewTransmittalDetails = (transmittal: Transmittal) => {
    setSelectedTransmittal(transmittal);
    setShowDetails(true);
  };

  const closeDetailsView = () => {
    setShowDetails(false);
    setSelectedTransmittal(null);
  };

  // Function to check if there are multiple recipients
  const hasMultipleRecipients = (recipients: string) => {
    if (!recipients) return false;
    return recipients.includes(',');
  };
  
  // Function to get the recipient count
  const getRecipientCount = (recipients: string): number => {
    if (!recipients) return 0;
    
    // If multiple recipients are separated by commas
    if (recipients.includes(',')) {
      return recipients.split(',').length;
    }
    
    // Single recipient
    return 1;
  };
  
  // Function to extract only the first recipient name
  const getRecipientName = (recipients: string): string => {
    if (!recipients) return '';
    
    // Get the first recipient (whether multiple or single)
    let firstRecipient = recipients.includes(',') 
      ? recipients.split(',')[0].trim() 
      : recipients.trim();
    
    // Extract name from email format if possible (e.g., "John Doe <john@example.com>")
    if (firstRecipient.includes('<')) {
      firstRecipient = firstRecipient.split('<')[0].trim();
    }
    
    // Truncate if too long
    return firstRecipient.length <= 25 ? firstRecipient : firstRecipient.substring(0, 25) + '...';
  };

  const formatDate = (dateStr: string) => {
    try {
      let date: Date;
      
      // Try standard date parsing first
      date = new Date(dateStr);
      
      // If invalid, try parsing DD-MM-YYYY HH:MM:SS format
      if (isNaN(date.getTime()) && typeof dateStr === 'string') {
        const parts = dateStr.split(' ');
        if (parts.length >= 2) {
          const dateParts = parts[0].split('-');
          if (dateParts.length === 3) {
            // Convert from DD-MM-YYYY to YYYY-MM-DD for proper Date parsing
            date = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}T${parts[1]}`);
          }
        }
      }
      
      // If still invalid, return original string
      if (isNaN(date.getTime())) {
        return dateStr;
      }
      
      // Format with date and time
      return new Intl.DateTimeFormat('es-ES', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      }).format(date);
    } catch (e) {
      console.error("Error formatting date:", e);
      return dateStr;
    }
  };
  

  return (
    <div className="font-sans w-full mx-auto bg-cyan-500">
      <div className="bg-white p-2 rounded-lg shadow-sm">
        <div className="flex justify-between items-center mb-6">
          {/* <h2 className="text-2xl font-semibold text-gray-800">Bandeja de salida de Transmittals</h2> */}
              {/* BOTON NUEVO TRANSMITTAL
          <button className="bg-cyan-600 hover:bg-cyan-700 text-white py-2 px-4 rounded-md flex items-center gap-2" 
                  onClick={openNewTransmittalModal}>
            <Mail size={16} />
            Nuevo Transmittal
          </button> */}
        </div>
        <TransmittalModal 
          isOpen={isTransmittalModalOpen} 
          onClose={closeTransmittalModal} 
          onSubmit={handleTransmittalSubmitted}
          documents={documents}
          fetchDocuments={fetchDocuments}
        />
        
        {/* Search and Filter Bar */}
        <div className="flex flex-col md:flex-row justify-between gap-4 mb-6">
          <div className="relative flex-grow max-w-xl">
            <input 
              type="text" 
              placeholder="Buscar por asunto, subproceso, destinatario o código..." 
              className="w-full px-4 py-2 pl-10 border rounded-md focus:outline-none focus:ring-2 focus:ring-cyan-500"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg className="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
              </svg>
            </div>
          </div>
          
          <div className="flex items-center gap-2">
            <select
              value={rowsPerPage}
              onChange={handleRowsPerPageChange}
              className="border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-cyan-500"
            >
              <option value={5}>5 por página</option>
              <option value={10}>10 por página</option>
              <option value={20}>20 por página</option>
              <option value={50}>50 por página</option>
            </select>
          </div>
        </div>

        {/* Simplified Table View */}
        {paginatedTransmittals.length === 0 ? (
          <div className="text-center py-16 bg-white rounded-lg border">
            <Mail size={48} className="mx-auto text-gray-300 mb-4" />
            <h3 className="text-lg font-semibold text-gray-600">No hay transmittals disponibles</h3>
            <p className="text-gray-500">No se encontraron transmittals con los criterios de búsqueda actuales.</p>
          </div>
        ) : (
          <div className="overflow-y-auto border rounded-lg">
            <table className="min-w-full divide-y  divide-cyan-400">
    <thead className="bg-cyan-400">
      <tr>
      <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">#</th>

        {[
          { key: 'codigo_transmittal', label: 'Código Transmittal' },
          { key: 'asunto', label: 'Asunto' },
          { key: 'fecha', label: 'Fecha' }
        ].map(({ key, label }) => ( 
          <th 
          key="fecha"
          className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider cursor-pointer select-none"
          onClick={() => handleSort('fecha')}
        >
          <div className="flex items-center">
        <b>  {label}</b> 
            {sortColumn === 'fecha' && (
              sortDirection === 'desc' 
                ? <ChevronUp size={16} className="ml-1 text-white" />
                : <ChevronDown size={16} className="ml-1 text-white" />
            )}
          </div>
          </th> 
        ))}
        <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"><b>Propósito</b></th>
        <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"><b>Docs.</b></th>
        <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"><b>Destinatarios</b></th>
        <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"><b>Subproceso</b></th>
        <th className="px-6 py-3 text-right text-xs font-medium text-white uppercase tracking-wider"><b>Acciones</b></th>
        
      </tr>
    </thead>
    <tbody className="bg-white divide-y divide-gray-200">
  {sortedTransmittals.map((t, index) => (
    <tr 
      key={t.numero} 
      className="hover:bg-gray-50 cursor-pointer transition-colors"
      onClick={() => viewTransmittalDetails(t)}
    >
      {/* Columna de numeración */}
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        {index + 1}
      </td>

      {/* Código Transmittal */}
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        {t.codigo_transmittal}
      </td>

      {/* Asunto */}
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 truncate" style={{ maxWidth: '200px' }}>
        {t.asunto}
      </td>

      {/* Fecha */}
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
  {formatDate(t.fecha)}
</td>

      {/* Propósito */}
      <td className="border p-2 whitespace-nowrap">
        {(() => {
          const firstTrueEntry = Object.entries(JSON.parse(t.detalle_transmittal))
            .find(([, value]) => value === true); // Encuentra solo el primer `true`

          return firstTrueEntry ? (
            <label key={firstTrueEntry[0]}>
              &nbsp;&nbsp;
              <input type="checkbox" checked readOnly /> {firstTrueEntry[0].replace(/\\u00f3n/g, 'ón')}
            </label>
          ) : null;
        })()}
      </td>

      {/* Documentos Seleccionados */}
      <td className="p-2 whitespace-nowrap flex mt-2 justify-center items-center">
        {t.documentos_seleccionados && JSON.parse(t.documentos_seleccionados).length > 0 ? (
          <button 
            onClick={() => openModal(JSON.parse(t.documentos_seleccionados).map((doc: Document) => doc.path))}
            className="relative flex items-center justify-center"
          >
            {/* Icono de Paperclip */}
            <Paperclip className="w-6 h-6 text-gray-900" />

            {/* Círculo rojo con cantidad de documentos */}
            <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs font-bold w-5 h-5 flex items-center justify-center rounded-full">
              {JSON.parse(t.documentos_seleccionados).length}
            </span>
          </button>
        ) : null}
      </td>

      {/* Destinatarios */}
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
        <div className="flex items-center">
          {/* Contenedor de Círculos con Iniciales */}
          <div className="flex items-center space-x-1">
            {getRecipientInitials(t.destinatarios).map((initials, index) => (
              <div  
                key={index} 
                className="w-7 h-7 flex items-center justify-center rounded-full bg-cyan-600 text-white font-bold text-xs"
              >
                {initials}
              </div>
            ))}

            {/* Contador para destinatarios adicionales */}
            {getRecipientCount(t.destinatarios) > 5 && (
              <span className="ml-2 inline-flex items-center justify-center bg-red-500 text-white rounded-full min-w-6 h-6 px-2 text-xs font-medium">
                +{getRecipientCount(t.destinatarios) - 5}
              </span>
            )}
          </div>
        </div>
      </td>

      {/* Subproceso */}
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
        {t.subproceso} 
      </td>

      {/* Acciones */}
      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
        <div className="flex mr-3 justify-end space-x-2">
          <button 
            onClick={(e) => {e.stopPropagation(); viewFile(t.ruta, 'transmittal')}} 
            className="text-cyan-500 hover:text-cyan-700 p-1"
            title="Ver transmittal"
          >
            <Eye size={18} />
          </button>
        </div>
      </td>
    </tr>
  ))}
</tbody>

            </table>
          </div>
        )}

        {/* Pagination Controls */}
        <div className="mt-6 relative flex items-center">
          <div className="text-sm text-gray-600">
            Mostrando {filteredTransmittals.length > 0 ? (currentPage - 1) * rowsPerPage + 1 : 0} - {Math.min(currentPage * rowsPerPage, filteredTransmittals.length)} de {filteredTransmittals.length} transmittals
          </div>

          <div className="absolute left-1/2 transform -translate-x-1/2 flex items-center space-x-1">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="p-2 border rounded hover:bg-gray-100 disabled:opacity-50"
            >
              <ChevronLeft size={18} />
            </button>

            {totalPages <= 5 ? (
              [...Array(totalPages)].map((_, index) => {
                const page = index + 1;
                return (
                  <button
                    key={page}
                    onClick={() => handlePageChange(page)}
                    className={`w-9 h-9 text-sm border rounded ${page === currentPage ? 'bg-cyan-500 text-white' : 'hover:bg-gray-100'}`}
                  >
                    {page}
                  </button>
                );
              })
            ) : (
              <>
                {[...Array(Math.min(3, totalPages))].map((_, index) => {
                  const page = index + 1;
                  return (
                    <button
                      key={page}
                      onClick={() => handlePageChange(page)}
                      className={`w-9 h-9 text-sm border rounded ${page === currentPage ? 'bg-cyan-500 text-white' : 'hover:bg-gray-100'}`}
                    >
                      {page}
                    </button>
                  );
                })}
                {currentPage > 3 && <span className="px-1">...</span>}
                {currentPage > 3 && currentPage < totalPages - 1 && (
                  <button
                    onClick={() => handlePageChange(currentPage)}
                    className="w-9 h-9 text-sm border rounded bg-cyan-500 text-white"
                  >
                    {currentPage}
                  </button>
                )}
                {currentPage < totalPages - 1 && <span className="px-1">...</span>}
                {currentPage < totalPages && (
                  <button
                    onClick={() => handlePageChange(totalPages)}
                    className={`w-9 h-9 text-sm border rounded ${totalPages === currentPage ? 'bg-cyan-500 text-white' : 'hover:bg-gray-100'}`}
                  >
                    {totalPages}
                  </button>
                )}
              </>
            )}

            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="p-2 border rounded hover:bg-gray-100 disabled:opacity-50"
            >
              <ChevronRight size={18} />
            </button>
          </div>
        </div>
      </div>

      {/* Transmittal Details View */}
      {showDetails && selectedTransmittal && (
        <Dialog width="auto" height="full" isOpen={showDetails} onClose={closeDetailsView} className="overflow-y-auto">
          <DialogContent className="max-h-screen mx-auto p-6 bg-white rounded-lg shadow-lg overflow-y-auto w-full max-w-3xl">
            
            {/* Encabezado */}
            <div className="border-b top-0 z-[300] bg-white p-4 flex justify-between items-center shadow-sm">
              <h2 className="text-xl font-semibold text-cyan-600">📄 Detalles del Transmittal</h2>
              <button onClick={closeDetailsView} className="text-gray-500 hover:text-gray-700">
                <X size={20} />
              </button>
            </div>

            {/* Cuerpo del Modal */}
            <div className="p-4">
              
              {/* Información General */}
              <div className="mb-6">
                <div className="flex justify-between items-center mb-3">
                  <h3 className="text-xl font-bold text-cyan-700">{selectedTransmittal.asunto}</h3>
                </div>
                <div className="text-sm text-gray-500">
                  <span className="font-medium">Código:</span> {selectedTransmittal.codigo_transmittal} |
                  <span className="font-medium ml-2">Fecha:</span> {formatDate(selectedTransmittal.fecha)}
                </div>
              </div>

              {/* Sección de Información */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                <div className="space-y-3">
                  <div className="bg-gray-50 rounded-xl p-3">
                    <h4 className="text-sm font-medium text-gray-700">📤 De:</h4>
                    <p className="text-gray-900">{selectedTransmittal.emisor}</p>
                  </div>
                  <div className="bg-gray-50 rounded-xl p-3">
                    <h4 className="text-sm font-medium text-gray-700">📥 Para:</h4>
                    <p className="text-gray-900">{selectedTransmittal.destinatarios}</p>
                  </div>
                  {selectedTransmittal.cc && (
                    <div className="bg-gray-50 rounded-xl p-3">
                      <h4 className="text-sm font-medium text-gray-700">📌 CC:</h4>
                      <p className="text-gray-900">{selectedTransmittal.cc}</p>
                    </div>
                  )}
                </div>

                <div className="space-y-3">
                  <div className="bg-gray-50 rounded-xl p-3">
                    <h4 className="text-sm font-medium text-gray-700">🏗 Proyecto:</h4>
                    <p className="text-gray-900">{`${selectedTransmittal.codigo_proyecto}-${getProcessNameByCode(selectedTransmittal.codigo_proyecto)}-${selectedTransmittal.comuna}`}</p>
                  </div>
                  <div className="bg-gray-50 rounded-xl p-3">
                    <h4 className="text-sm font-medium text-gray-700">🛠 Subproceso:</h4>
                    <p className="text-gray-900">{selectedTransmittal.subproceso}</p>
                  </div>
                </div>
              </div>

              {/* Referencia y Descripción */}
              {selectedTransmittal.referencia && (
                <div className="mb-6 bg-gray-50 rounded-xl p-3">
                  <h4 className="text-sm font-medium text-gray-700">📎 Referencia:</h4>
                  <p className="text-gray-900">{selectedTransmittal.referencia}</p>
                </div>
              )}

              {/* Propósito TRL */}
              <div className="mb-6 bg-gray-50 rounded-xl p-3">
                <h4 className="text-sm font-medium text-gray-700">🎯 Propósito TRL:</h4>
                <div className="grid grid-cols-2 gap-2 mt-2">
                  {Object.entries(JSON.parse(selectedTransmittal.detalle_transmittal))
                    .filter(([_, value]) => Boolean(value)) // Filtra solo los valores que sean true
                    .map(([key]) => (
                      <div key={key} className="flex items-center">
                        <input 
                          type="checkbox" 
                          checked={true} 
                          readOnly 
                          className="h-4 w-4 text-cyan-600" 
                        />
                        <span className="ml-2 text-sm text-gray-900">{key}</span>
                      </div>
                    ))}
                </div>
              </div>

              {/* Documentos Adjuntos */}
              <div className="mb-6">
                <h4 className="text-sm font-medium text-gray-700">📂 Documentos Adjuntos:</h4>
                <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
                  {(() => {
                    try {
                      const docs = JSON.parse(selectedTransmittal.documentos_seleccionados);
                      if (docs.length === 0) return <p className="text-gray-500">No hay documentos adjuntos</p>;
                      
                      return (
                        <ul className="divide-y">
                          {docs.map((doc: Document, index: number) => (
                            <li key={index} className="py-3 first:pt-0 last:pb-0 flex items-center justify-between">
                              <button 
                                onClick={() => viewFile(selectedTransmittal.ruta, 'transmittal')} 
                                className="flex items-center text-blue-600 hover:text-blue-800 hover:underline"
                              >
                                <Paperclip size={16} className="mr-2 flex-shrink-0" />
                                <span className="break-all">{doc.title || doc.path.split('/').pop()}</span>
                              </button>
                              <button 
                                onClick={() => viewFile(selectedTransmittal.ruta, 'transmittal')} 
                                className="bg-cyan-500 text-white px-2 py-1 text-sm rounded-md hover:bg-cyan-600 transition duration-300"
                              >
                                Descargar
                              </button>
                            </li>
                          ))}
                        </ul>
                      );
                    } catch {
                      return <p className="text-gray-500">Error al cargar documentos</p>;
                    }
                  })()}
                </div>
              </div>

              {/* Comentarios */}
              {selectedTransmittal.comentarios && (
                <div className="mb-6 bg-gray-50 rounded-xl p-3">
                  <h4 className="text-sm font-medium text-gray-700">💬 Comentarios:</h4>
                  <p className="text-gray-900 whitespace-pre-line">{selectedTransmittal.comentarios}</p>
                </div>
              )}
            </div>

            {/* Botones de Acción */}
            <div className="p-4 border-t flex justify-end gap-3">
              <button 
                onClick={() => viewFile(selectedTransmittal.ruta, 'transmittal')} 
                className="bg-cyan-500 text-white px-4 py-2 rounded-md hover:bg-cyan-600 transition duration-300 flex items-center gap-2"
              >
                <Eye size={16} />
                Ver PDF 
              </button>
              <button 
                onClick={closeDetailsView} 
                className="bg-gray-200 text-gray-800 px-4 py-2 rounded-md hover:bg-gray-300 transition duration-300"
              >
                Cerrar
              </button>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default BandejaTransmittal;