import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Save, List, PlusCircle, Edit, Trash, Eye, X, AlertTriangle } from 'lucide-react';

// Interfaces
interface Subproceso {
  id: number;
  nombre: string;
  color: string;
  orden: number;
}

interface PlantillaSubprocesos {
  id: number;
  nombre: string;
  descripcion: string;
  subprocesos: Subproceso[];
}

// Componente Modal de Vista Previa
const PreviewModal: React.FC<{
  plantilla: PlantillaSubprocesos | null;
  onClose: () => void;
}> = ({ plantilla, onClose }) => {
  if (!plantilla) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-md overflow-y-auto">
        <div className="flex justify-between items-center p-4 border-b">
          <h3 className="text-lg font-semibold">{plantilla.nombre}</h3>
          <button 
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <X className="w-5 h-5" />
          </button>
        </div>
        
        {plantilla.descripcion && (
          <div className="px-4 py-2 text-sm text-gray-600">
            {plantilla.descripcion}
          </div>
        )}
        
        <div className="p-4">
          <h4 className="font-medium mb-2">Subprocesos:</h4>
          <div className="space-y-2 max-h-96 overflow-y-auto">
            {plantilla.subprocesos.map((sp) => (
              <div key={sp.id} className="flex items-center p-2 bg-gray-50 rounded">
                <div 
                  className="w-4 h-4 rounded-full mr-3" 
                  style={{ backgroundColor: sp.color }}
                ></div>
                <span>{sp.nombre}</span>
              </div>
            ))}
          </div>
        </div>
        
        <div className="p-4 border-t flex justify-end">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition-colors"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  );
};

// Componente para crear o editar plantillas
const PlantillaForm: React.FC<{
  plantilla: PlantillaSubprocesos | null;
  onSave: (plantilla: PlantillaSubprocesos) => Promise<boolean>;
  onCancel: () => void;
}> = ({ plantilla, onSave, onCancel }) => {
  const [formData, setFormData] = useState<PlantillaSubprocesos>({
    id: plantilla?.id || 0,
    nombre: plantilla?.nombre || '',
    descripcion: plantilla?.descripcion || '',
    subprocesos: plantilla?.subprocesos?.length ? [...plantilla.subprocesos] : [
      { id: 1, nombre: '', color: '#000000', orden: 1 }
    ],
  });
  const [isSaving, setIsSaving] = useState(false);
  const [validationErrors, setValidationErrors] = useState<{[key: string]: string}>({});

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    
    // Limpiar error de validación
    if (validationErrors[name]) {
      setValidationErrors(prev => {
        const newErrors = {...prev};
        delete newErrors[name];
        return newErrors;
      });
    }
  };

  const handleSubprocesoChange = (id: number, field: 'nombre' | 'color', value: string) => {
    setFormData(prev => ({
      ...prev,
      subprocesos: prev.subprocesos.map(sp =>
        sp.id === id ? { ...sp, [field]: value } : sp
      ),
    }));
    
    // Limpiar errores de validación para subprocesos
    setValidationErrors(prev => {
      const newErrors = {...prev};
      delete newErrors['subprocesos'];
      return newErrors;
    });
  };

  const handleAddSubproceso = () => {
    setFormData(prev => ({
      ...prev,
      subprocesos: [
        ...prev.subprocesos,
        { 
          id: Date.now(), // Usar timestamp como ID para garantizar unicidad
          nombre: '', 
          color: '#000000', 
          orden: prev.subprocesos.length + 1 
        }
      ],
    }));
  };

  const handleRemoveSubproceso = (id: number) => {
    if (formData.subprocesos.length <= 1) return; // Mantener al menos un subproceso
    
    setFormData(prev => ({
      ...prev,
      subprocesos: prev.subprocesos.filter(sp => sp.id !== id).map((sp, idx) => ({
        ...sp,
        orden: idx + 1
      })),
    }));
  };

  const validateForm = (): boolean => {
    const errors: {[key: string]: string} = {};
    
    if (!formData.nombre.trim()) {
      errors.nombre = 'El nombre de la plantilla es obligatorio';
    }
    
    const emptySubprocesos = formData.subprocesos.some(sp => !sp.nombre.trim());
    if (emptySubprocesos) {
      errors.subprocesos = 'Todos los subprocesos deben tener un nombre';
    }
    
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleFormSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (!validateForm()) {
      return;
    }
    
    setIsSaving(true);
    
    try {
      const success = await onSave(formData);
      if (success) {
        // Si el guardado es exitoso, onSave ya habrá cerrado el modal
      } else {
        setIsSaving(false);
      }
    } catch (error) {
      console.error('Error al guardar:', error);
      setIsSaving(false);
    }
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onCancel();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <div className="p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold">
              {plantilla ? 'Editar' : 'Nueva'} plantilla de subprocesos
            </h2>
            <button 
              onClick={handleCancel}
              className="text-gray-500 hover:text-gray-700"
            >
              <X className="w-5 h-5" />
            </button>
          </div>
          
          <div className="space-y-4">
            <div className="mb-4">
              <label htmlFor="nombre" className="block text-sm font-medium mb-1">
                Nombre de la Plantilla *
              </label>
              <input
                type="text"
                id="nombre"
                name="nombre"
                value={formData.nombre}
                onChange={handleInputChange}
                className={`w-full p-2 border ${validationErrors.nombre ? 'border-red-500' : 'border-gray-300'} rounded`}
              />
              {validationErrors.nombre && (
                <p className="text-red-500 text-xs mt-1">{validationErrors.nombre}</p>
              )}
            </div>
            
            <div className="mb-4">
              <label htmlFor="descripcion" className="block text-sm font-medium mb-1">
                Descripción
              </label>
              <textarea
                id="descripcion"
                name="descripcion"
                value={formData.descripcion}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded min-h-[100px] text-sm"
                placeholder="Descripción opcional de la plantilla..."
              />
            </div>
            
            <div className="mb-4">
              <div className="flex justify-between items-center mb-2">
                <label className="block text-sm font-medium">
                  Subprocesos *
                </label>
                <button 
                  type="button" 
                  onClick={handleAddSubproceso} 
                  className="text-blue-500 hover:text-blue-700 transition-colors text-sm flex items-center"
                >
                  <PlusCircle className="w-4 h-4 mr-1" /> 
                  <span>Añadir subproceso</span>
                </button>
              </div>
              
              {validationErrors.subprocesos && (
                <p className="text-red-500 text-xs mb-2">{validationErrors.subprocesos}</p>
              )}
              
              <div className="bg-gray-50 p-3 rounded-lg border border-gray-200 space-y-2">
                {formData.subprocesos.map((subproceso) => (
                  <div key={subproceso.id} className="flex items-center bg-white p-2 rounded border border-gray-100">
                    <div className="relative">
                      <input
                        type="color"
                        value={subproceso.color}
                        onChange={e => handleSubprocesoChange(subproceso.id, 'color', e.target.value)}
                        className="absolute top-0 left-0 opacity-0 w-6 h-6 cursor-pointer"
                      />
                      <div 
                        className="w-6 h-6 rounded-full mr-2 border border-gray-300 cursor-pointer" 
                        style={{ backgroundColor: subproceso.color }}
                      ></div>
                    </div>
                    <input
                      type="text"
                      value={subproceso.nombre}
                      onChange={e => handleSubprocesoChange(subproceso.id, 'nombre', e.target.value)}
                      className="flex-grow p-2 border border-gray-200 rounded text-sm"
                      placeholder="Nombre del subproceso"
                    />
                    <button 
                      type="button" 
                      onClick={() => handleRemoveSubproceso(subproceso.id)} 
                      className="ml-2 text-red-500 hover:text-red-700 transition-colors duration-200"
                      disabled={formData.subprocesos.length <= 1}
                    >
                      <Trash className="w-4 h-4" />
                    </button>
                  </div>
                ))}
                
                {formData.subprocesos.length === 0 && (
                  <p className="text-gray-500 text-sm p-2 text-center">
                    No hay subprocesos. Añade al menos uno.
                  </p>
                )}
              </div>
            </div>
            
            <div className="flex justify-end space-x-3 mt-6">
              <button
                type="button"
                onClick={handleCancel}
                className="px-4 py-2 border border-gray-300 rounded hover:bg-gray-100"
                disabled={isSaving}
              >
                Cancelar
              </button>
              <button
                type="button"
                onClick={handleFormSubmit}
                disabled={isSaving}
                className={`px-4 py-2 ${isSaving ? 'bg-blue-400' : 'bg-blue-500 hover:bg-blue-600'} text-white rounded flex items-center justify-center min-w-[100px]`}
              >
                {isSaving ? (
                  <>
                    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2"></div>
                    Guardando...
                  </>
                ) : (
                  <>
                    <Save className="w-4 h-4 mr-2" />
                    Guardar Plantilla
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


interface ConfirmationModalProps {
    isOpen: boolean;
    title: string;
    message: string;
    confirmText?: string;
    cancelText?: string;
    onConfirm: () => void;
    onCancel: () => void;
    type?: 'delete' | 'warning' | 'info';
  }
  
  const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
    isOpen,
    title,
    message,
    confirmText = 'Confirmar',
    cancelText = 'Cancelar',
    onConfirm,
    onCancel,
    type = 'warning'
  }) => {
    if (!isOpen) return null;
  
    // Definir colores según el tipo de modal
    const getColors = () => {
      switch (type) {
        case 'delete':
          return {
            icon: 'text-red-500',
            button: 'bg-red-500 hover:bg-red-600',
            border: 'border-red-200'
          };
        case 'warning':
          return {
            icon: 'text-amber-500',
            button: 'bg-amber-500 hover:bg-amber-600',
            border: 'border-amber-200'
          };
        case 'info':
          return {
            icon: 'text-blue-500',
            button: 'bg-blue-500 hover:bg-blue-600',
            border: 'border-blue-200'
          };
        default:
          return {
            icon: 'text-gray-500',
            button: 'bg-gray-500 hover:bg-gray-600',
            border: 'border-gray-200'
          };
      }
    };
  
    const colors = getColors();
  
    return (
      <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center p-4">
        <div className="bg-white rounded-lg shadow-xl w-full max-w-md overflow-hidden">
          <div className="flex justify-between items-center p-4 border-b">
            <h3 className="text-lg font-medium">{title}</h3>
            <button 
              onClick={onCancel}
              className="text-gray-400 hover:text-gray-600 transition-colors"
            >
              <X className="w-5 h-5" />
            </button>
          </div>
          
          <div className="p-6">
            <div className="flex items-start space-x-4">
              <div className={`flex-shrink-0 ${colors.icon}`}>
                <AlertTriangle className="w-10 h-10" />
              </div>
              <div className="flex-grow">
                <p className="text-gray-700">{message}</p>
              </div>
            </div>
          </div>
          
          <div className="flex justify-end space-x-3 p-4 bg-gray-50 border-t">
            <button
              onClick={onCancel}
              className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              {cancelText}
            </button>
            <button
              onClick={onConfirm}
              className={`px-4 py-2 rounded-md shadow-sm text-sm font-medium text-white ${colors.button} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500`}
            >
              {confirmText}
            </button>
          </div>
        </div>
      </div>
    );
  };
  
  export default ConfirmationModal;

// Componente de gestión de plantillas mejorado
const TemplatesManager: React.FC<{
  onSelectTemplate: (subprocesos: Subproceso[]) => void;
}> = ({ onSelectTemplate }) => {
  const [plantillas, setPlantillas] = useState<PlantillaSubprocesos[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPlantilla, setCurrentPlantilla] = useState<PlantillaSubprocesos | null>(null);
  const [showTemplateForm, setShowTemplateForm] = useState<boolean>(false);
  const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);
  const [previewPlantilla, setPreviewPlantilla] = useState<PlantillaSubprocesos | null>(null);
  const [message, setMessage] = useState<{type: 'success' | 'error', text: string} | null>(null);
  const messageTimerRef = useRef<NodeJS.Timeout | null>(null);
  const [isDeleting, setIsDeleting] = useState<number | null>(null);
  const [showConfirmModal, setShowConfirmModal] = useState<{ id: number | null; isOpen: boolean }>({
    id: null,
    isOpen: false,
  });
  const [confirmModal, setConfirmModal] = useState<{
    isOpen: boolean;
    plantillaId: number | null;
    plantillaNombre: string;
  }>({
    isOpen: false,
    plantillaId: null,
    plantillaNombre: ''
  });
    // Función para mostrar mensajes con duración personalizada
  const showMessage = useCallback((type: 'success' | 'error', text: string, duration = 5000) => {
    setMessage({ type, text });
    
    if (messageTimerRef.current) {
      clearTimeout(messageTimerRef.current);
    }
    
    messageTimerRef.current = setTimeout(() => {
      setMessage(null);
      messageTimerRef.current = null;
    }, duration);
  }, []);

  // Limpiar el temporizador al desmontar
  useEffect(() => {
    return () => {
      if (messageTimerRef.current) {
        clearTimeout(messageTimerRef.current);
      }
    };
  }, []);

  // Inicialización y verificación de tablas
  useEffect(() => {
    const setupDatabase = async () => {
      try {
        console.log("Verificando tablas de base de datos...");
        
        // Verificar y crear tablas
        const checkResponse = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/plantilla_subproceso/check_tables.php`);
        
        if (!checkResponse.ok) {
          console.error("Error al verificar tablas:", checkResponse.statusText);
        } else {
          try {
            await checkResponse.json();
          } catch (e) {
            console.warn("No se pudo parsear la respuesta de check_tables");
          }
        }
        
        // Cargar plantillas
        await fetchPlantillas();
        
      } catch (error) {
        console.error("Error en la configuración inicial:", error);
        setLoading(false);
        showMessage('error', 'Error al inicializar el componente');
      }
    };
    
    setupDatabase();
  }, [showMessage]);

  // Función para cargar plantillas
  const fetchPlantillas = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/plantilla_subproceso/get_plantilla_sp.php`);
      
      if (!response.ok) {
        throw new Error(`Error al cargar plantillas: ${response.status} ${response.statusText}`);
      }
      
      const responseText = await response.text();
      
      // Si la respuesta está vacía, establecer array vacío
      if (!responseText.trim()) {
        setPlantillas([]);
        setLoading(false);
        return;
      }
      
      try {
        const data = JSON.parse(responseText);
        
        if (Array.isArray(data)) {
          setPlantillas(data);
        } else {
          console.warn("La respuesta no es un array:", data);
          setPlantillas([]);
        }
      } catch (parseError) {
        console.error("Error al parsear JSON:", parseError);
        setPlantillas([]);
      }
    } catch (error) {
      console.error("Error en fetchPlantillas:", error);
      showMessage('error', 'Error al cargar las plantillas');
      setPlantillas([]);
    } finally {
      setLoading(false);
    }
  };

  // Función para crear o actualizar plantilla
  const handleSavePlantilla = async (plantilla: PlantillaSubprocesos): Promise<boolean> => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/plantilla_subproceso/${
        plantilla.id ? 'update_plantilla_sp.php' : 'create_plantilla_sp.php'
      }`;
      
      // Verificar tablas primero
      await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/plantilla_subproceso/check_tables.php`);
      
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(plantilla),
      });
      
      if (!response.ok) {
        throw new Error(`Error del servidor: ${response.status} ${response.statusText}`);
      }
      
      const data = await response.json();
      
      if (data.success) {
        showMessage('success', `Plantilla ${plantilla.id ? 'actualizada' : 'creada'} correctamente`);
        await fetchPlantillas();
        setShowTemplateForm(false);
        setCurrentPlantilla(null);
        return true;
      } else {
        throw new Error(data.error || 'Error al guardar la plantilla');
      }
    } catch (error) {
      console.error('Error al guardar la plantilla:', error);
      showMessage('error', `Error al guardar la plantilla: ${error instanceof Error ? error.message : 'Error desconocido'}`);
      return false;
    }
  };

  const handleDeleteConfirmation = (plantilla: PlantillaSubprocesos) => {
    setConfirmModal({
      isOpen: true,
      plantillaId: plantilla.id,
      plantillaNombre: plantilla.nombre
    });
  };
  
  const handleDeletePlantilla = async () => {
    // Cerrar el modal
    const plantillaId = confirmModal.plantillaId;
    setConfirmModal({
      isOpen: false,
      plantillaId: null,
      plantillaNombre: ''
    });
    
    if (!plantillaId) return;
    
    setIsDeleting(plantillaId);
    
    try {
      console.log("Eliminando plantilla con ID:", plantillaId);
      
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/plantilla_subproceso/delete_plantilla_sp.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: plantillaId }),
      });
      
      const responseText = await response.text();
      console.log("Respuesta de eliminación:", responseText);
      
      let data;
      try {
        data = JSON.parse(responseText);
      } catch (parseError) {
        console.error("Error al parsear JSON:", parseError);
        throw new Error(`Error de parseo: ${responseText}`);
      }
      
      if (data && data.success) {
        showMessage('success', 'Plantilla eliminada correctamente');
        await fetchPlantillas();
      } else {
        throw new Error(data?.error || 'Error desconocido al eliminar la plantilla');
      }
    } catch (error) {
      console.error('Error al eliminar la plantilla:', error);
      showMessage('error', `Error al eliminar la plantilla: ${error instanceof Error ? error.message : 'Error desconocido'}`);
    } finally {
      setIsDeleting(null);
    }
  };
  

  // Función para seleccionar una plantilla
  const handleSelectPlantilla = (plantilla: PlantillaSubprocesos) => {
    onSelectTemplate(plantilla.subprocesos);
    showMessage('success', `Plantilla "${plantilla.nombre}" aplicada correctamente`);
  };

  // Función para mostrar vista previa de plantilla
  const handleShowPreview = (plantilla: PlantillaSubprocesos) => {
    setPreviewPlantilla(plantilla);
    setShowPreviewModal(true);
  };

  return (
    <div className="mb-6 bg-white p-5 rounded-lg shadow-sm border border-gray-100">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold text-gray-800">Listas de Subprocesos</h2>
        <button
          type="button"
          onClick={() => {
            setCurrentPlantilla(null);
            setShowTemplateForm(true);
          }}
          className="bg-green-500 hover:bg-green-600 text-white px-3 py-1 rounded-md flex items-center text-sm transition-colors"
        >
          <PlusCircle className="w-4 h-4 mr-1" /> Nueva lista de Subprocesos
        </button>
      </div>

      {message && (
        <div 
          className={`p-3 mb-4 rounded-md ${
            message.type === 'success' ? 'bg-green-50 text-green-800 border border-green-200' : 'bg-red-50 text-red-800 border border-red-200'
          }`}
        >
          {message.text}
        </div>
      )}

      {loading ? (
        <div className="flex justify-center py-8">
          <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-blue-500"></div>
        </div>
      ) : plantillas.length === 0 ? (
        <div className="text-center py-8 bg-gray-50 rounded-lg border border-gray-100">
          <div className="text-gray-500 mb-2">No hay plantillas disponibles</div>
          <button
            type="button"
            onClick={() => {
              setCurrentPlantilla(null);
              setShowTemplateForm(true);
            }}
            className="text-blue-500 hover:text-blue-700 flex items-center mx-auto"
          >
            <PlusCircle className="w-4 h-4 mr-1" /> Crear primera plantilla
          </button>
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {plantillas.map((plantilla) => (
            <div 
              key={plantilla.id} 
              className="border border-gray-200 rounded-lg p-4 hover:shadow-md transition-shadow bg-white"
            >
              <div className="flex justify-between items-start mb-2">
                <h3 className="font-semibold text-lg text-gray-800">{plantilla.nombre}</h3>
                <div className="flex space-x-1">
                  <button
                    type="button"
                    onClick={() => handleShowPreview(plantilla)}
                    className="text-blue-500 hover:text-blue-700 p-1 rounded hover:bg-blue-50"
                    title="Ver detalles"
                  >
                    <Eye className="w-4 h-4" />
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setCurrentPlantilla(plantilla);
                      setShowTemplateForm(true);
                    }}
                    className="text-yellow-500 hover:text-yellow-700 p-1 rounded hover:bg-yellow-50"
                    title="Editar plantilla"
                  >
                    <Edit className="w-4 h-4" />
                  </button>
                  <button
  type="button"
  onClick={() => handleDeleteConfirmation(plantilla)}
  className="text-red-500 hover:text-red-700 p-1 rounded hover:bg-red-50"
  title="Eliminar plantilla"
  disabled={isDeleting === plantilla.id}
>
  {isDeleting === plantilla.id ? (
    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-red-500"></div>
  ) : (
    <Trash className="w-4 h-4" />
  )}
</button>
                </div>
              </div>
              
              {plantilla.descripcion && (
                <p className="text-gray-600 text-sm mb-3 line-clamp-2">{plantilla.descripcion}</p>
              )}
              
              <div className="flex flex-wrap gap-1 mb-3">
                {plantilla.subprocesos.slice(0, 10).map((sp) => (
                  <div 
                    key={sp.id}
                    className="inline-flex items-center text-xs px-2 py-1 bg-gray-100 text-gray-800 rounded-full"
                  >
                    <div 
                      className="w-2 h-2 rounded-full mr-1" 
                      style={{ backgroundColor: sp.color }}
                    ></div>
                    <span className="truncate max-w-[120px]">{sp.nombre}</span>
                  </div>
                ))}
                {plantilla.subprocesos.length > 10 && (
                  <div className="inline-flex items-center text-xs px-2 py-1 bg-gray-100 text-gray-500 rounded-full">
                    +{plantilla.subprocesos.length - 10} más
                  </div>
                )}
              </div>
              
              <button
                type="button"
                onClick={() => handleSelectPlantilla(plantilla)}
                className="bg-blue-500 hover:bg-blue-600 text-white px-3 py-1 rounded text-sm flex items-center w-full justify-center transition-colors"
              >
                <List className="w-4 h-4 mr-1" /> Aplicar Plantilla
              </button>
            </div>
          ))}
        </div>
      )}

      {showTemplateForm && (
        <PlantillaForm
          plantilla={currentPlantilla}
          onSave={handleSavePlantilla}
          onCancel={() => {
            setShowTemplateForm(false);
            setCurrentPlantilla(null);
          }}
        />
      )}
<ConfirmationModal
  isOpen={confirmModal.isOpen}
  title="Eliminar plantilla"
  message={`¿Estás seguro de que deseas eliminar la plantilla "${confirmModal.plantillaNombre}"? Esta acción no se puede deshacer.`}
  confirmText="Eliminar"
  cancelText="Cancelar"
  onConfirm={handleDeletePlantilla}
  onCancel={() => setConfirmModal({ isOpen: false, plantillaId: null, plantillaNombre: '' })}
  type="delete"
/>

      {showPreviewModal && previewPlantilla && (
        <PreviewModal 
          plantilla={previewPlantilla}
          onClose={() => {
            setShowPreviewModal(false);
            setPreviewPlantilla(null);
          }}
        />
      )}
    </div>
  );
};

export { TemplatesManager }; export type { PlantillaSubprocesos, Subproceso };