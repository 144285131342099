import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import { 
  BarChart, 
  Bar, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  ResponsiveContainer,
  Cell,
  PieChart,
  Pie,
  Legend
} from 'recharts';
import { CommonComponentProps } from '../../types/common';
import { AlertCircle } from 'lucide-react';

// Define interfaces for type safety
interface Transmittal {
  id: string;
  subproceso: string;
  codigo_transmittal?: string;
  estado: string;
  codigo_proyecto: string;
}

interface SubprocessCount {
  name: string;
  count: number;
  color: string;
}

interface Process {
  id: number;
  nombreProceso: string;
  codigo: string;
  comuna: string;
  subprocesos?: string[];
  plantilla: string;
}

interface Props extends Partial<CommonComponentProps> {
  setSelectedProcess: React.Dispatch<React.SetStateAction<string>>;
  processes: Process[];
}

const TransmittalsBySubprocess: React.FC<Props> = ({ 
  selectedProcess,
  setSelectedProcess,
  processes,
  navigateToSubComponent,
  ...props
}) => {
  // State variables
  const [transmittals, setTransmittals] = useState<Transmittal[]>([]);
  const [filteredTransmittals, setFilteredTransmittals] = useState<Transmittal[]>([]);
  const [subprocessCounts, setSubprocessCounts] = useState<SubprocessCount[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [chartType, setChartType] = useState<'bar' | 'pie'>('bar');

  // Default colors for subprocesses (used as fallback)
  const DEFAULT_COLORS = [
    "#8884d8", "#83a6ed", "#8dd1e1", "#82ca9d", "#a4de6c", 
    "#d0ed57", "#ffc658", "#ff8042", "#ff6361", "#bc5090"
  ];
  
  // State for subprocess definitions with colors
  const [subprocessDefinitions, setSubprocessDefinitions] = useState<{
    id: number;
    subProceso: string;
    color: string;
    orden: number;
  }[]>([]);

  // Find the current process object based on selectedProcess
  const currentProcess = processes.find(proc => proc.nombreProceso === selectedProcess);

  // Fetch transmittals data and subprocess definitions
// Nueva función para cargar subprocesos
const fetchSubprocessDefinitions = async (processId: number) => {
  try {
    const subprocessResponse = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/proceso/get_subprocesos.php?processId=${processId}`);
    if (!subprocessResponse.ok) {
      throw new Error(`HTTP error! Status: ${subprocessResponse.status}`);
    }
    const subprocessData = await subprocessResponse.json();
    
    if (Array.isArray(subprocessData)) {
      setSubprocessDefinitions(subprocessData);
    }
  } catch (error) {
    console.error('Error fetching subprocess definitions:', error);
    setSubprocessDefinitions([]);
  }
};

// Cargar transmittals y luego calcular
useEffect(() => { 
  const fetchTransmittals = async () => {
    if (!selectedProcess) return;
    setIsLoading(true);
    
    try {
      const transmittalsResponse = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/transmittal/get_transmittals.php?type=type2`);
      if (!transmittalsResponse.ok) {
        throw new Error(`HTTP error! Status: ${transmittalsResponse.status}`);
      }
      const transmittalsData = await transmittalsResponse.json();

      if (currentProcess && currentProcess.id) {
        await fetchSubprocessDefinitions(currentProcess.id); // Aseguramos que los subprocesos se carguen antes
      }

      if (Array.isArray(transmittalsData)) {
        setTransmittals(transmittalsData);
        
        const filtered = transmittalsData.filter(
          transmittal => transmittal.projectCode === currentProcess?.codigo
        );
        setFilteredTransmittals(filtered);
      }
    } catch (error) {
      console.error('Error fetching transmittals:', error);
      setTransmittals([]);
      setFilteredTransmittals([]);
    } finally {
      setIsLoading(false);
    }
  };

  fetchTransmittals();
}, [selectedProcess, processes]);

useEffect(() => {
  if (filteredTransmittals.length > 0 && subprocessDefinitions.length > 0) {
    countBySubprocess(filteredTransmittals, subprocessDefinitions);
  }
}, [filteredTransmittals, subprocessDefinitions]);


  // Count transmittals by subprocess and apply colors from definitions
  const countBySubprocess = (data: Transmittal[], subprocessDefs: { id: number; subProceso: string; color: string; orden: number }[] = []) => {
    const countMap: Record<string, number> = {};
  
    data.forEach(transmittal => {
      const subproceso = transmittal.subproceso || 'No asignado';
      countMap[subproceso] = (countMap[subproceso] || 0) + 1;
    });
  
    const subprocessColorMap: Record<string, string> = subprocessDefs.reduce((acc, def) => {
      if (def.subProceso) acc[def.subProceso] = def.color;
      return acc;
    }, {} as Record<string, string>);
  
    console.log("Mapa de colores de subprocesos:", subprocessColorMap); // Verificar que se genera correctamente
  
    const countsArray = Object.entries(countMap).map(([name, count], index) => {
      const color = subprocessColorMap[name] || DEFAULT_COLORS[index % DEFAULT_COLORS.length];
      return { name, count, color };
    });
  
    setSubprocessCounts(countsArray);
  };
  
  // Custom tooltip for charts
  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white p-3 shadow-md rounded-md border border-gray-200">
          <p className="font-semibold">{`${payload[0].name}`}</p>
          <p className="text-sm">{`Cantidad: ${payload[0].value}`}</p>
        </div>
      );
    }
    return null;
  };

  // Toggle chart type
  const toggleChartType = () => {
    setChartType(chartType === 'bar' ? 'pie' : 'bar');
  };

  // Loading state
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
        <p className="ml-2">Cargando datos...</p>
      </div>
    );
  }
  
  // Get current process name for display
  const currentProcessName = currentProcess?.nombreProceso || selectedProcess;

  return (
    <div className="flex flex-col bg-white">
      <div className="p-3 max-w-7xl h-full mx-auto w-full">
        {!selectedProcess ? (
      <div className="flex flex-col items-center justify-center h-96 text-center text-gray-600">
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.3 }}
        className="mb-4"
      >
        <AlertCircle className="w-16 h-16 text-gray-400" />
      </motion.div>
      <h2 className="text-xl font-semibold mb-2">No hay ningún proceso seleccionado</h2>
      <p className="text-gray-500">Por favor seleccione un proceso de la lista.</p>
    </div> 
        ) : filteredTransmittals.length === 0 ? (
          <div className="flex flex-col items-center justify-center h-96 text-center text-gray-600">
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3 }}
            className="mb-4"
          >
            <AlertCircle className="w-16 h-16 text-gray-400" />
          </motion.div>
          <h2 className="text-xl font-semibold mb-2">No hay Transmittals disponibles para este proceso</h2>
          <p className="text-gray-500">Verifique el registro de Transmittals o consulte con el administrador</p>
        </div> 
        ) : (
          <div className="bg-white border border-gray-200 rounded shadow mb-6">
            <div className="bg-cyan-500 text-white px-4 py-2.5 rounded-t flex justify-between items-center">
              <h2 className="text-base font-medium">
                Transmittals por Subproceso - {currentProcess?.nombreProceso || selectedProcess}
              </h2>
              <button 
                onClick={toggleChartType}
                className="bg-white text-cyan-700 px-2 py-1 rounded text-xs hover:bg-cyan-100"
              >
                Cambiar a {chartType === 'bar' ? 'Gráfico Circular' : 'Gráfico de Barras'}
              </button>
            </div>
            <div className="p-4">
            <div className="grid grid-cols-[2fr_3fr] gap-6">
            {/* Table */}
                <div className="bg-white border border-gray-200 rounded shadow">
                  <div className="bg-gray-100 px-4 py-2 rounded-t border-b">
                    <h3 className="text-sm font-medium text-gray-700">Tabla de Subprocesos</h3>
                  </div>
                  <div className="overflow-y-auto max-h-126">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50 sticky top-0">
                        <tr>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Subproceso</th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Cantidad</th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {subprocessCounts.map((item, index) => (
                          <tr key={index} className="hover:bg-gray-50">
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="flex items-center">
                                <div 
                                  className="w-3 h-3 rounded-full mr-2" 
                                  style={{ backgroundColor: item.color }}
                                ></div>
                                <span className="text-sm font-medium text-gray-900">{item.name}</span>
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                                {item.count}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                {/* Chart */}
                <div className="bg-white border border-gray-200 rounded shadow">
                  <div className="bg-gray-100 px-4 py-2 rounded-t border-b">
                    <h3 className="text-sm font-medium text-gray-700">
                      {chartType === 'bar' ? 'Gráfico de Barras' : 'Gráfico Circular'}
                    </h3>
                  </div>
                  <div className="p-4">
                    <ResponsiveContainer width="100%" height={300}>
                      {chartType === 'bar' ? (
                        <BarChart
                          data={subprocessCounts}
                          margin={{ top: 20, right: 30, left: 20, bottom: 70 }}
                        >
                          <CartesianGrid strokeDasharray="3 3" vertical={false} />
                          <XAxis 
                            dataKey="name" 
                            angle={-45} 
                            textAnchor="end" 
                            height={70}
                            tick={{ fontSize: 12 }} 
                          />
                          <YAxis />
                          <Tooltip content={<CustomTooltip />} />
                          <Bar dataKey="count">
                            {subprocessCounts.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={entry.color} />
                            ))}
                          </Bar>
                        </BarChart>
                      ) : (
                        <PieChart>
                          <Pie
                            data={subprocessCounts}
                            cx="50%"
                            cy="50%"
                            labelLine={true}
                            outerRadius={100}
                            fill="#8884d8"
                            dataKey="count"
                            nameKey="name"
                            label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                          >
                            {subprocessCounts.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={entry.color} />
                            ))}
                          </Pie>
                          <Tooltip content={<CustomTooltip />} />
                          <Legend layout="vertical" align="right" verticalAlign="middle" />
                        </PieChart>
                      )}
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </div>
            
            {/* Summary */}
            <div className="bg-gray-50 px-4 py-3 border-t">
              <div className="flex justify-between items-center">
                <span className="text-sm font-medium text-gray-500">
                  Total de Subprocesos: {subprocessCounts.length}
                </span>
                <span className="text-sm font-medium text-gray-500">
                  Total de Transmittals: {filteredTransmittals.length}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TransmittalsBySubprocess;