import React, { useState, useEffect, forwardRef } from 'react'; 
import { ChevronLeft, ChevronRight, ChevronDown, ChevronUp, Upload, Trash, Edit, Save, Plus, XCircle } from 'lucide-react';
import { Download } from 'lucide-react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ReactQuillProps } from 'react-quill';
import { default as DatePicker } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";



interface UploadedFile {
  name: string;
  type: string;
  url: string;
}

interface Report {
  id: number;
  date: string;
  sections: { [key: string]: string[] };
  uploadedFiles: { [key: string]: UploadedFile[] };
  categories: string[];
}

const Dashboard: React.FC<{}> = () => {
  //Defina el estadoreports que almacena la lista de informes.
  //Se inicializa desdelocalStorage , si existen informes guardados.
  //Si no hay datos, cree un informe de ejemplo con categorías predeterminadas.
  //Cada informe contiene: un id, date, sections, uploadedFilesy categories.
  const [reports, setReports] = useState<Report[]>(() => {
    const saved = localStorage.getItem('reports');
    const defaultCategories = [
      'Galería de Fotos',
      'Control de Hormigones',
      'Visita Mecánica Suelos',
      'Proveedores',
      'Calidad',
      'Seguridad',
      'Inspecciones'
    ];
  
    if (saved) {
      const parsedReports = JSON.parse(saved);
      // Asegura que cada reporte tenga categories
      return parsedReports.map((report: Report) => ({
        ...report,
        categories: report.categories || defaultCategories
      }));
    }
  
    return [{
      id: 1,
      date: '2025-04-20',
      sections: {
        Generalidades: ['Visita mecánica terreno ejecutada al 100%'],
        Plazo: ['Se prepara solicitud de aumento de plazo.'],
        Calidad: ['Revisiones de paneles SIP, estructuras metálicas.'],
        Costo: ['Dificultades para cerrar compras por alzas de costos.']
      },
      uploadedFiles: {},
      categories: defaultCategories
    }];
  });

  //Controla el índice del informe actualmente seleccionado en la lista.
  const [currentReportIndex, setCurrentReportIndex] = useState(0);

  //Mueve al informe siguiente en la lista, Avanza al siguiente reporte en la lista (si no está en el último).
  const nextReport = () => {
    if (currentReportIndex < reports.length - 1) {
      setCurrentReportIndex(currentReportIndex + 1);
    }
  };
  
  //Retrocede al reporte anterior en la lista (si no está en el primero). //Mueve al informe anterior en la lista
  const previousReport = () => {
    if (currentReportIndex > 0) {
      setCurrentReportIndex(currentReportIndex - 1);
    }
  };

  //Muestra un modal para crear un nuevo informe. Estado para abrir/cerrar el modo de creación de un nuevo informe.
  const [newReportModal, setNewReportModal] = useState<boolean>(false);

  //Abre el modal para agregar un nuevo informe. 
  const addNewReport = () => {
    setNewReportModal(true);
  };

  //Crea un nuevo informe con un template predeterminado. Crea un nuevo reporte con la estructura del reporte actual (secciones vacías).
   //Usa la fecha actual y copia las categorías del informe actual.
  const handleNewReportWithTemplate = () => {
    const newReport: Report = {
      id: reports.length + 1,
      date: new Date().toLocaleDateString('es-ES', { month: 'short', year: '2-digit' }),
      sections: Object.keys(reports[currentReportIndex].sections).reduce((acc, key) => ({
        ...acc,
        [key]: []
      }), {}),
      uploadedFiles: {},
      categories: [...(reports[currentReportIndex].categories || [])]
    };
    setReports([...reports, newReport]);
    setCurrentReportIndex(reports.length);
    setNewReportModal(false);
  };
  
  //Crea un nuevo informe vacío. Crea un nuevo reporte vacío con secciones vacías y categorías vacías.
  const handleNewReportEmpty = () => {
    const newReport: Report = {
      id: reports.length + 1,
      date: new Date().toLocaleDateString('es-ES', { month: 'short', year: '2-digit' }),
      sections: {},
      uploadedFiles: {},
      categories: []
    };
    setReports([...reports, newReport]);
    setCurrentReportIndex(reports.length);
    setNewReportModal(false);
  };

  //Controla el estado del modal de eliminación de informes.
  const [deleteReportModal, setDeleteReportModal] = useState<{
    isOpen: boolean;
    reportIndex: number | null;
  }>({
    isOpen: false,
    reportIndex: null
  });

  //Abre el modal para confirmar la eliminación de un informe. Elimina el informe seleccionado. Elimina un reporte , si el índice es válido, y actualiza el estado.
  const handleDeleteReport = () => {
    if (deleteReportModal.reportIndex !== null) {
      const updatedReports = reports.filter((_, index) => index !== deleteReportModal.reportIndex);
      setReports(updatedReports);
      if (currentReportIndex >= updatedReports.length) {
        setCurrentReportIndex(Math.max(0, updatedReports.length - 1));
      }
      setDeleteReportModal({ isOpen: false, reportIndex: null });
    }
  };

  //editar fecha
  //Muestra un modal para editar la fecha del informe actual. Indica si la fecha del reporte está en modo edición.
  const [editingDate, setEditingDate] = useState<boolean>(false);
  
  //Actualiza la fecha del reporte actual.
  const handleDateChange = (date: string) => {
    const updatedReports = [...reports];
    updatedReports[currentReportIndex] = {
      ...updatedReports[currentReportIndex],
      date: date // Ya está en `YYYY-MM-DD`
    };
    setReports(updatedReports);
  };

  //Guarda las categorías disponibles en la interfaz (se carga desde localStorage o usa valores predeterminados).
  const [buttons, setButtons] = useState<string[]>(() => {
    const savedButtons = localStorage.getItem('buttons');
    return savedButtons ? JSON.parse(savedButtons) : [
      'Galería de Fotos',
      'Control de Hormigones',
      'Visita Mecánica Suelos',
      'Proveedores',
      'Calidad',
      'Seguridad',
      'Inspecciones'
    ];
  });

  //Controla qué sección está expandida en la vista del informe.
  const [openSection, setOpenSection] = useState<string | null>(null);

  //Almacena la sección que está en modo edición.
  const [editingSection, setEditingSection] = useState<string | null>(null);

  //Controla qué carpeta de archivos está abierta .
  const [openFolder, setOpenFolder] = useState<string | null>(null);

  // Guarda los archivos subidos organizados por categorías.
  const [uploadedFiles, setUploadedFiles] = useState<{
    [key: string]: UploadedFile[];
  }>(() => {
    const saved = localStorage.getItem('uploadedFiles');
    return saved ? JSON.parse(saved) : {};
  });
  
  // Añade este useEffect para guardar los archivos 
  // Guarda en localStoragelos archivos subidos y los botones (categorías) cuando cambian.
  useEffect(() => {
    localStorage.setItem('uploadedFiles', JSON.stringify(uploadedFiles));
    localStorage.setItem('buttons', JSON.stringify(buttons));
  }, [uploadedFiles, buttons]);
  
  //Guarda en localStoragela lista de informes cada vez que se actualiza .
  useEffect(() => {
    localStorage.setItem('reports', JSON.stringify(reports));
  }, [reports]);

  //Abre/cierra una sección del reporte.
  const toggleSection = (section: string) => {
    setOpenSection(openSection === section ? null : section);
  };

  //Muestra o oculta una carpeta de archivos. Oculta la carpeta actual si se muestra otra. Abre/cierra una carpeta de archivos del reporte.
  const toggleFolder = (button: string) => {
    setOpenFolder(openFolder === button ? null : button);
  };

  //Abre el modal para agregar una nueva categoría.
  const addNewButton = () => {
    setAddCategoryModal(true);
  };

  //Abre el modal para confirmar la eliminación de una categoría .
  const removeButton = (button: string) => {
    setDeleteCategoryModal({ isOpen: true, category: button });
  };

  //Confirma la eliminación de una categoría. Elimina una categoría del reporte actual .
  const confirmDeleteCategory = () => {
    if (deleteCategoryModal.category) {
      const updatedReports = [...reports];
      updatedReports[currentReportIndex] = {
        ...updatedReports[currentReportIndex],
        categories: updatedReports[currentReportIndex].categories.filter(
          (cat) => cat !== deleteCategoryModal.category
        )
      };
      setReports(updatedReports);
      setDeleteCategoryModal({ isOpen: false, category: null });
    }
  };

  //Abre el modal para agregar una nueva sección.
  const addNewSection = () => {
    setAddSectionModal(true);
  };

  //Agrega una nueva categoría al reporte actual.
  const handleAddCategory = () => {
    if (newItemName.trim()) {
      const updatedReports = [...reports];
      updatedReports[currentReportIndex] = {
        ...updatedReports[currentReportIndex],
        categories: [...updatedReports[currentReportIndex].categories, newItemName]
      };
      setReports(updatedReports);
      setNewItemName('');
      setAddCategoryModal(false);
    }
  };
  
  //Agrega una nueva sección al reporte actual.
  const handleAddSection = () => {
    if (newItemName.trim()) {
      const updatedReports = [...reports];
      updatedReports[currentReportIndex] = {
        ...updatedReports[currentReportIndex],
        sections: {
          ...updatedReports[currentReportIndex].sections,
          [newItemName]: []
        }
      };
      setReports(updatedReports);
      setNewItemName('');
      setAddSectionModal(false);
    }
  };

  // Controla el modo de confirmación para eliminar archivos .
  const [deleteFileModal, setDeleteFileModal] = useState<{
    isOpen: boolean;
    folder: string | null;
    fileIndex: number | null;
  }>({
    isOpen: false,
    folder: null,
    fileIndex: null
  });

  //Abre el modal para eliminar una sección .
  const removeSection = (section: string) => {
    setDeleteModal({ isOpen: true, section });
  };

  //Elimina la sección seleccionada del informe. confirmar la eliminación
  const confirmDelete = () => {
    if (deleteModal.section) {
      const updatedReports = [...reports];
      const currentReport = { ...updatedReports[currentReportIndex] };
      const { [deleteModal.section]: _, ...remainingSections } = currentReport.sections;
      
      currentReport.sections = remainingSections;
      updatedReports[currentReportIndex] = currentReport;
      
      setReports(updatedReports);
      setDeleteModal({ isOpen: false, section: null });
    }
  };

  //Habilita la edición de una sección y carga su contenido.
  const editSection = (section: string) => {
    // Obtiene el contenido actual de la sección
    const currentContent = reports[currentReportIndex].sections[section][0] || '';
    setTempContent(currentContent); // Establece el contenido actual en el editor
    setEditingSection(section);
  };

  //Guarda el contenido editado de una sección.
  const saveSection = (section: string, content: string) => {
    const updatedReports = [...reports];
    updatedReports[currentReportIndex] = {
      ...updatedReports[currentReportIndex],
      sections: {
        ...updatedReports[currentReportIndex].sections,
        [section]: [content] // Guarda el contenido HTML completo
      }
    };
    setReports(updatedReports);
    setEditingSection(null);
    setTempContent('');
  };

  //Maneja la subida de archivos arrastrados a una categoría específica .
  const handleFileDrop = async (event: React.DragEvent<HTMLDivElement>, button: string) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    
    const filePromises = files.map(file => new Promise<UploadedFile>((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve({
          name: file.name,
          type: file.type,
          url: reader.result as string
        });
      };
      reader.readAsDataURL(file);
    }));
  
    const fileData = await Promise.all(filePromises);
    
    const updatedReports = [...reports];
    updatedReports[currentReportIndex] = {
      ...updatedReports[currentReportIndex],
      uploadedFiles: {
        ...updatedReports[currentReportIndex].uploadedFiles,
        [button]: [
          ...(updatedReports[currentReportIndex].uploadedFiles[button] || []),
          ...fileData
        ]
      }
    };
    setReports(updatedReports);
  };

  //Abre el modo de eliminación de un archivo .
  const handleFileRemove = (button: string, index: number) => {
    setDeleteFileModal({
      isOpen: true,
      folder: button,
      fileIndex: index
    });
  };

  //Elimina un archivo del informe actual .
  const confirmFileDelete = () => {
    if (deleteFileModal.folder && deleteFileModal.fileIndex !== null) {
      const updatedReports = [...reports];
      updatedReports[currentReportIndex] = {
        ...updatedReports[currentReportIndex],
        uploadedFiles: {
          ...updatedReports[currentReportIndex].uploadedFiles,
          [deleteFileModal.folder]: updatedReports[currentReportIndex].uploadedFiles[deleteFileModal.folder]
            .filter((_, i) => i !== deleteFileModal.fileIndex)
        }
      };
      setReports(updatedReports);
      setDeleteFileModal({ isOpen: false, folder: null, fileIndex: null });
    }
  };

  //Almacena temporalmente el contenido editado de una sección.
  const [tempContent, setTempContent] = useState<string>('');

  // Controla el modo de eliminación de secciones.
  const [deleteModal, setDeleteModal] = useState<{
    isOpen: boolean;
    section: string | null;
  }>({
    isOpen: false,
    section: null
  });  

  // Controle el modal para añadir secciones.
  const [addSectionModal, setAddSectionModal] = useState<boolean>(false);

  //Controle el modal para añadir categorías.
  const [addCategoryModal, setAddCategoryModal] = useState<boolean>(false);

  //Guarde temporalmente el nombre de una nueva sección o categoría.
  const [newItemName, setNewItemName] = useState<string>('');

  // Controla el modo de eliminación de categorías.
  const [deleteCategoryModal, setDeleteCategoryModal] = useState<{
    isOpen: boolean;
    category: string | null;
  }>({
    isOpen: false,
    category: null
  });

  //Configura la barra de herramientas del editor de texto ReactQuill.
  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }], // Importante para listas
      [{ 'color': [] }, { 'background': [] }],
      [{ 'align': [] }],
      [{ 'font': [] }],
      [{ 'size': ['small', false, 'large', 'huge'] }],
      ['blockquote', 'code-block'],
      ['link'],
      ['clean']
    ],
    clipboard: {
      matchVisual: false // Importante para preservar el formato
    }
  };

  //Envía el reporte actual al servidor usando fetch y FormData .
  const guardarReporte = async () => {
    const reporteActual = reports[currentReportIndex];

    const formData = new FormData();

     const formattedDate = format(new Date(reporteActual.date), "yyyy-MM-dd");
         formData.append("fecha", formattedDate);
    formData.append("numero_reporte", reporteActual.id.toString());

    // Agregar las secciones al FormData
    Object.entries(reporteActual.sections).forEach(([titulo, contenido]) => {
      formData.append(`secciones[${titulo}]`, JSON.stringify(contenido));
    });

    // Agregar las categorías al FormData
    reporteActual.categories.forEach((categoria, index) => {
      formData.append(`categorias[${index}]`, categoria);
    });

    // Convertir archivos en Blobs antes de enviarlos
    const filePromises = Object.values(reporteActual.uploadedFiles)
      .flat()
      .map(async (file) => {
        const response = await fetch(file.url);
        const blob = await response.blob();
        return new File([blob], file.name, { type: file.type });
      });
    
    const archivos = await Promise.all(filePromises);
    archivos.forEach((archivo) => formData.append("archivos[]", archivo));

    try {
      const response = await fetch('http://localhost:3000/php/components/reporteSemanal/guardar_reporte.php', {
        method: 'POST',
        body: formData
      });

      if (!response.ok) {
        throw new Error(`Error HTTP: ${response.status}`);
      }

      const data = await response.json();
      console.log("Respuesta del servidor:", data);

      if (data.error) {
        alert("Error al guardar: " + data.error);
      } else {
        alert("Reporte guardado correctamente");
      }

    } catch (error) {
      console.error("Error en la solicitud:", error);
      alert("No se pudo conectar con el servidor");
    }
  };

  //Componente de entrada personalizado paraDatePicker.
  const CustomInput = forwardRef<HTMLInputElement, any>(({ value, onClick }, ref) => (
    <input
      className="text-sm border rounded px-2 py-1 w-32 cursor-pointer"
      onClick={onClick}
      value={value}
      readOnly
      ref={ref}
    />
  ));

  //Convierte DatePickeren un componente de React compatible .
  const DatePickerComponent = DatePicker as unknown as React.FC<any>;

  
  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg">
      <div className="flex justify-between items-center mb-4">
        <div>
          <div className="flex items-center space-x-4">
            <h2 className="text-xl font-bold">Reporte N° {reports[currentReportIndex].id}</h2>
            <button
              className="text-red-500 hover:text-red-700"
              onClick={() => setDeleteReportModal({ isOpen: true, reportIndex: currentReportIndex })}
            >
              <Trash className="w-4 h-4" />
            </button>
          </div>
          <div className="flex items-center space-x-2">
            {editingDate ? (
              <div className="flex items-center space-x-2">
                <input
                  type="date"
                  className="text-sm border rounded px-2 py-1 w-24"
                  value={reports[currentReportIndex].date} // `type="date"` maneja fechas correctamente
                  onChange={(e) => handleDateChange(e.target.value)}
                  autoFocus
                />
                <button
                  className="text-gray-500 hover:text-gray-700"
                  onClick={() => setEditingDate(false)}
                >
                  <XCircle className="w-4 h-4" />
                </button>
              </div>
            ) : (
              <div className="flex items-center space-x-2">
                <DatePickerComponent
                  selected={reports[currentReportIndex].date ? new Date(reports[currentReportIndex].date) : null}
                  onChange={(date: Date | null) => {
                    if (date) {
                      handleDateChange(date.toISOString().split("T")[0]);
                    }
                  }}
                  dateFormat="yyyy-MM-dd"
                  customInput={<CustomInput />}
                />

                <button
                  className="text-gray-500 hover:text-gray-700"
                  onClick={() => setEditingDate(true)}
                >
                  <Edit className="w-4 h-4" />
                </button>
              </div>
            )}
          </div>

        <div className="flex items-center space-x-4">
          <div className="flex space-x-2">
            <button
              className={`w-8 h-8 flex items-center justify-center rounded-full ${
                currentReportIndex > 0 ? 'text-blue-500 hover:bg-blue-100' : 'text-gray-300'
              }`}
              onClick={previousReport}
              disabled={currentReportIndex === 0}
            >
              <ChevronLeft className="w-6 h-6" />
            </button>
            <button
              className={`w-8 h-8 flex items-center justify-center rounded-full ${
                currentReportIndex < reports.length - 1 ? 'text-blue-500 hover:bg-blue-100' : 'text-gray-300'
              }`}
              onClick={nextReport}
              disabled={currentReportIndex === reports.length - 1}
            >
              <ChevronRight className="w-6 h-6" />
            </button>
          </div>
          <button
            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition-colors flex items-center"
            onClick={addNewReport}
          >
            <Plus className="w-4 h-4 mr-2" />
            Nuevo Reporte
          </button>
        </div>
      </div>

      <div className="flex flex-wrap gap-2 mb-6">
        {(reports[currentReportIndex]?.categories || []).map((button) => (
          <div key={button} className="relative flex items-center">
            <button
              className="px-4 py-2 bg-blue-500 text-white rounded-full text-sm hover:bg-blue-600 transition-colors"
              onClick={() => toggleFolder(button)}
            >
              {button}
            </button>
            {openFolder === button && (
              <button 
                className="text-red-500 hover:text-red-700 ml-2"
                onClick={() => removeButton(button)}
              >
                <Trash className="w-4 h-4" />
              </button>
            )}
          </div>
        ))}
        <button
          className="px-4 py-2 bg-green-500 text-white rounded-full text-sm hover:bg-green-600 transition-colors"
          onClick={addNewButton}
        >
          + Agregar Categoría
        </button>
      </div>
    </div>
  
      {openFolder && (
        <div className="p-4 border border-gray-300 bg-gray-50 rounded-lg mt-4">
          <h3 className="font-bold text-lg">{openFolder}</h3>
          <div
            className="border-dashed border-2 border-gray-400 p-6 text-center text-gray-500 rounded-md mt-2 cursor-pointer hover:bg-gray-100"
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => handleFileDrop(e, openFolder)}
          >
            <Upload className="inline-block w-8 h-8 mb-2 text-gray-500" />
            <p>Arrastra y suelta archivos aquí o haz clic para subir</p>
          </div>

          {/* Modifica esta parte */}
          {reports[currentReportIndex].uploadedFiles[openFolder] && 
           reports[currentReportIndex].uploadedFiles[openFolder].length > 0 && (
            <div className="mt-4">
              <h4 className="font-semibold">Archivos subidos:</h4>
              <ul className="mt-2 space-y-2">
                {reports[currentReportIndex].uploadedFiles[openFolder].map((file, index) => (
                  <li key={index} className="flex justify-between items-center p-2 bg-white border rounded">
                    <div className="flex items-center space-x-2 flex-grow cursor-pointer" onClick={() => window.open(file.url, '_blank')}>
                      {file.type.includes('image') ? (
                        <img 
                          src={file.url} 
                          alt={file.name} 
                          className="w-8 h-8 object-cover"
                        />
                      ) : (
                        <div className="w-8 h-8 flex items-center justify-center bg-gray-100 rounded">
                          <i className="text-gray-500 text-xs">{file.type.split('/')[1]}</i>
                        </div>
                      )}
                      <span className="text-sm text-blue-600 hover:text-blue-800">{file.name}</span>
                    </div>
                    <div className="flex space-x-2">
                      <a
                        href={file.url}
                        download={file.name}
                        className="text-blue-500 hover:text-blue-700"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Download className="w-4 h-4" />
                      </a>
                      <button
                        className="text-red-500 hover:text-red-700"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleFileRemove(openFolder, index);
                        }}
                      >
                        <Trash className="w-4 h-4" />
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}

      <div className="space-y-4 mt-6">
        {Object.entries(reports[currentReportIndex].sections).map(([title, items]) => (
          <div key={title} className="border border-blue-200 rounded-lg overflow-hidden">
            <button
              className="w-full flex justify-between items-center p-4 bg-blue-100 text-teal-800 font-bold"
              onClick={() => toggleSection(title)}
              aria-expanded={openSection === title}
            >
              {title}
              {openSection === title ? <ChevronUp /> : <ChevronDown />}
            </button>

            {openSection === title && (
              <div className="p-4 bg-white">
                {editingSection === title ? (
                  <div>
                    {/* @ts-ignore */}
                    <ReactQuill
                      className="mb-4"
                      theme="snow"
                      modules={modules}
                      value={tempContent}
                      onChange={(value) => setTempContent(value)}
                      defaultValue={reports[currentReportIndex].sections[title][0] || ''}
                    /> 
                    <button
                      className="mt-2 bg-green-500 text-white px-3 py-1 rounded"
                      onClick={() => {
                        saveSection(title, tempContent);
                      }}
                    >
                      <Save className="inline-block w-4 h-4 mr-1" />
                      Guardar
                    </button>
                  </div>
                ) : (
                  <div className="list-disc list-inside space-y-2">
                    {items.map((item, index) => (
                      <div 
                        key={index} 
                        className="text-sm pl-4 [&>ul]:list-disc [&>ul]:ml-4 [&>ol]:list-decimal [&>ol]:ml-4"
                        dangerouslySetInnerHTML={{ __html: item }}
                      />
                    ))}
                  </div>
                )}
                <button className="mt-2 text-blue-500" onClick={() => editSection(title)}>
                  <Edit className="inline-block w-4 h-4 mr-1" />
                  Editar
                </button>
                <button 
                  className="mt-2 text-red-500 ml-4" 
                  onClick={() => removeSection(title)}
               >
                  <Trash className="inline-block w-4 h-4 mr-1" />
                  Eliminar
                </button>
              </div>
            )}
          </div>
        ))}
        <div className="flex justify-between items-center mt-4">
          <button className="bg-green-500 text-white px-4 py-2 rounded" onClick={addNewSection}>
            + Añadir Sección
          </button>
          
          <button 
            className="px-4 py-2 bg-blue-500 text-white rounded"
            onClick={guardarReporte}
          >
            Guardar Reporte
          </button>
        </div>


      </div>

      {/* Agregar el modal de confirmación aquí */}

      {newReportModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full mx-4">
            <h3 className="text-lg font-bold mb-4">Nuevo Reporte</h3>
            <p className="mb-6">¿Desea mantener el formato del reporte anterior?</p>
            <div className="flex justify-end space-x-3">
              <button
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
                onClick={handleNewReportEmpty}
              >
                Reporte Vacío
              </button>
              <button
                className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                onClick={handleNewReportWithTemplate}
              >
                Mantener Formato
              </button>
            </div>
          </div>
        </div>
      )}

      {addCategoryModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full mx-4">
            <h3 className="text-lg font-bold mb-4">Agregar Nueva Categoría</h3>
            <input
              type="text"
              className="w-full p-2 border rounded mb-4"
              placeholder="Nombre de la categoría"
              value={newItemName}
              onChange={(e) => setNewItemName(e.target.value)}
            />
            <div className="flex justify-end space-x-3">
              <button
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
                onClick={() => {
                  setAddCategoryModal(false);
                  setNewItemName('');
                }}
              >
                Cancelar
              </button>
              <button
                className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                onClick={handleAddCategory}
              >
                Agregar
              </button>
            </div>
          </div>
        </div>
      )}

      {addSectionModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full mx-4">
            <h3 className="text-lg font-bold mb-4">Agregar Nueva Sección</h3>
            <input
              type="text"
              className="w-full p-2 border rounded mb-4"
              placeholder="Nombre de la sección"
              value={newItemName}
              onChange={(e) => setNewItemName(e.target.value)}
            />
            <div className="flex justify-end space-x-3">
              <button
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
                onClick={() => {
                  setAddSectionModal(false);
                  setNewItemName('');
                }}
              >
                Cancelar
              </button>
              <button
                className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                onClick={handleAddSection}
              >
                Agregar
              </button>
            </div>
          </div>
        </div>
      )}

      {deleteReportModal.isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full mx-4">
            <h3 className="text-lg font-bold mb-4">Confirmar eliminación de reporte</h3>
            <p className="mb-6">¿Está seguro que desea eliminar este reporte? Esta acción no se puede deshacer.</p>
            <div className="flex justify-end space-x-3">
              <button
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
                onClick={() => setDeleteReportModal({ isOpen: false, reportIndex: null })}
              >
                Cancelar
              </button>
              <button
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                onClick={handleDeleteReport}
              >
                Eliminar
              </button>
            </div>
          </div>
        </div>
      )}
      
       {/*Añade el modal de confirmación para eliminar categoría junto a los otros modales*/}
      {deleteCategoryModal.isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full mx-4">
            <h3 className="text-lg font-bold mb-4">Confirmar eliminación de categoría</h3>
            <p className="mb-6">¿Está seguro que desea eliminar esta categoría? Esta acción no se puede deshacer.</p>
            <div className="flex justify-end space-x-3">
              <button
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
                onClick={() => setDeleteCategoryModal({ isOpen: false, category: null })}
              >
                Cancelar
              </button>
              <button
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                onClick={confirmDeleteCategory}
              >
                Eliminar
              </button>
            </div>
          </div>
        </div>
      )}

      {deleteFileModal.isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full mx-4">
            <h3 className="text-lg font-bold mb-4">Confirmar eliminación de archivo</h3>
            <p className="mb-6">¿Está seguro que desea eliminar este archivo? Esta acción no se puede deshacer.</p>
            <div className="flex justify-end space-x-3">
              <button
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
                onClick={() => setDeleteFileModal({ isOpen: false, folder: null, fileIndex: null })}
              >
                Cancelar
              </button>
              <button
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                onClick={confirmFileDelete}
              >
                Eliminar
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Agregar el modal de confirmación aquí */}
      {deleteModal.isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full mx-4">
            <h3 className="text-lg font-bold mb-4">Confirmar eliminación</h3>
            <p className="mb-6">¿Está seguro que desea eliminar esta sección? Esta acción no se puede deshacer.</p>
            <div className="flex justify-end space-x-3">
              <button
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
                onClick={() => setDeleteModal({ isOpen: false, section: null })}
              >
                Cancelar
              </button>
              <button
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                onClick={confirmDelete}
              >
                Eliminar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
// Esto cambie en algunos archivos porque me dio error 
//"resolutions": {
//  "@types/react": "^18.0.26",
//  "@types/react-dom": "^18.0.9"
//}
// Resumen general
//  Muestra un informe con su número de identificación, fecha y opciones de navegación entre informes.
//  Permite editar la fecha del informe con un selector.
//  Incluye botones para agregar y eliminar informes.
//  Administra categorías dentro del informe, con la opción de eliminar categorías.
//  Maneja la carga de archivos en cada categoría y su previsualización o descarga.
//  Organice el contenido del informe en secciones colapsables y editables.
//  Usa modos para confirmar acciones como agregar/eliminar categorías, eliminar informes y subir archivos.

// Este código gestiona informes con varias funciones:
//  Navegar entre informes
//  Editar fechas
//  Agregar/eliminar informes
//  Gestionar categorías y archivos
//  Administrar secciones del informe con editor de texto
//  Mostrar modos de confirmación antes de eliminar contenido

//Este código de React realiza múltiples funciones para gestionar informes, archivos y categorías. 
//   Funciones de navegación de informes: previousReport, nextReport.
//   Funciones para modificar informes: handleDateChange, addNewReport, handleDeleteReport.
//   Funciones para manejar categorías: addNewButton, toggleFolder, deleteCategory.
//   Funciones para manejar archivos: handleFileDrop, handleFileUpload, handleFileRemove.
//   Funciones para manejar secciones del informe: toggleSection, editSection, saveSection, removeSection.
//   Funciones para modos de confirmación: confirmDeleteCategory, confirmFileDelete.

//    Resumen de funciones
 //           Función                          	Descripción
 //          previousReport	                Ir al informe anterior
 //          nextReport                   	Ir al siguiente informe
 //          handleDateChange             	Cambiar la fecha del informe
 //          addNewReport	                  Crear un nuevo informe
 //          handleDeleteReport           	Eliminar un informe
 //          addNewButton	                  Agregar una categoría
 //          toggleFolder                 	abrir/cerrar una categoría
 //          deleteCategory	                Eliminar una categoría
 //          handleFileDrop	                Arrastrar y soltar archivos
 //          handleFileUpload	              Subir archivos
 //          handleFileRemove   	          Eliminar un archivo
 //          toggleSection       	          Abrir/cerrar una sección
 //          editSection	                  Activar edición de sección
 //          saveSection                   	Guardar cambios de sección
 //          removeSection	                Eliminar una sección
 //          confirmDeleteCategory	        Confirmar eliminación de categoría
 //          confirmFileDelete	            Confirmar eliminación de archivo
 
 // En sintesis llegue a guardar en las bases de datos, que se viera el calendario, que en la edición se pudiera colocar negrita, cursiva, punteo, en orden numerico, subrayado, en si un mini editor de texto. Además se puede agregar o eliminar, un reporte, una categoría, una sección. La fecha se puede editar. Hay un boton para guardar el reporte y se guarda. Se pueden arrastrar archivos, documentos o imagenes, donde se guardan en una carpeta uploads y se pueden descargar de la misma pagina. Se puede cambiar de reporte y el anterior no afecta al siguiente en el ambito de la información.
 // Correr el codigo y ver el resultado, debe agregar la base de datos que se muestra acontinuación (son las tablas de la base de datos curvas)
// -- 1. Crear la base de datos (si no existe)
//CREATE DATABASE IF NOT EXISTS reportes_db;
//USE reportes_db;
//
//-- 2. Crear la tabla de reportes
//CREATE TABLE reportes (
//    id INT AUTO_INCREMENT PRIMARY KEY,
//    fecha DATE NOT NULL,
//    numero_reporte INT NOT NULL UNIQUE
//);
//
//-- 3. Crear la tabla de secciones
//CREATE TABLE secciones (
//    id INT AUTO_INCREMENT PRIMARY KEY,
//    reporte_id INT,
//    titulo VARCHAR(255) NOT NULL,
//    contenido TEXT,
//    FOREIGN KEY (reporte_id) REFERENCES reportes(id) ON DELETE CASCADE
//);
//
//-- 4. Crear la tabla de categorías
//CREATE TABLE categorias (
//    id INT AUTO_INCREMENT PRIMARY KEY,
//    reporte_id INT,
//    nombre VARCHAR(255) NOT NULL,
//    FOREIGN KEY (reporte_id) REFERENCES reportes(id) ON DELETE CASCADE
//);
//
//-- 5. Crear la tabla de archivos subidos
//CREATE TABLE archivos (
//    id INT AUTO_INCREMENT PRIMARY KEY,
//    reporte_id INT,
//    categoria VARCHAR(255),
//    nombre_archivo VARCHAR(255) NOT NULL,
//    tipo_archivo VARCHAR(100),
//    url_archivo VARCHAR(500) NOT NULL,
//    FOREIGN KEY (reporte_id) REFERENCES reportes(id) ON DELETE CASCADE
//); 
//PD: Si necesita saber que hace cada parte del codigo preguntar a una IA.
export default Dashboard;