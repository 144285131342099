import { AlertTriangle } from "lucide-react";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "./dialog";

interface ConfirmDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    title: string;
    message: string;
    confirmButtonText?: string;
    cancelButtonText?: string;
  }
  
  const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
    isOpen,
    onClose,
    onConfirm,
    title,
    message,
    confirmButtonText = "Confirmar",
    cancelButtonText = "Cancelar"
  }) => {
    return (
      <Dialog isOpen={isOpen} onClose={onClose} width="400px">
        <DialogContent className="bg-white rounded-xl shadow-xl">
          <DialogHeader>
            <DialogTitle>
              <div className="text-2xl font-bold text-gray-800">{title}</div>
            </DialogTitle>
          </DialogHeader>
  
          <div className="mt-4 text-gray-600">
            <AlertTriangle className="w-16 h-16 text-amber-500 mx-auto mb-4" />
            <p className="text-center">{message}</p>
          </div>
  
          <DialogFooter>
            <div className="flex justify-end space-x-2 w-full">
              <button
                onClick={onClose}
                className="border border-black p-2"
              >
                {cancelButtonText}
              </button> 
              <button
                onClick={onConfirm}
                className="bg-red-500 p-2 hover:bg-red-700 text-white"
              >
                {confirmButtonText}
              </button>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  };
  export default ConfirmDialog;
