import React, { useState } from 'react';
import { 
  BookOpen, 
  FileText, 
  Database, 
  Mail, 
  ChevronDown, 
  ChevronRight, 
  Home,
  Image,
  Eye
} from 'lucide-react';
import { motion, AnimatePresence } from "framer-motion";

// Definición de Props
export interface ModuleItem {
  id: string;
  title: string;
  icon: React.ReactNode;
  description?: string;
  subItems?: Array<{id: string; title: string; icon?: React.ReactNode}>;
}

interface VerticalButtonsWithModalProps {
  isOpen: boolean;
  openModal: (id: string) => void;
  isContactFormOpen: boolean;
  setIsContactFormOpen: (isOpen: boolean) => void;
}

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  content: React.ReactNode | string;
}

// Componente Modal simple
export const Modal: React.FC<ModalProps> = ({ isOpen, onClose, title, content }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50" onClick={onClose}>
      <div className="bg-white rounded-lg shadow-xl max-w-md w-full overflow-hidden" onClick={(e) => e.stopPropagation()}>
        <div className="px-6 py-4 bg-custom-header text-white flex justify-between items-center">
          <h2 className="text-xl font-bold">{title}</h2>
          <button onClick={onClose}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
          </button>
        </div>
        <div className="p-6">
          {typeof content === 'string' ? (
            content.split('\n\n').map((paragraph, index) => (
              <p key={index} className="mb-4 text-gray-700">{paragraph}</p>
            ))
          ) : (
            content
          )}
        </div>
        <div className="px-6 py-4 bg-gray-50 border-t flex justify-end">
          <button 
            onClick={onClose}
            className="px-4 py-2 bg-custom-sidebar text-white rounded hover:bg-opacity-90 transition-colors"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  );
};

export const VerticalButtonsWithModal: React.FC<VerticalButtonsWithModalProps> = ({ 
  isOpen, 
  openModal, 
  isContactFormOpen, 
  setIsContactFormOpen 
}) => {
  const [expandedMenus, setExpandedMenus] = useState<string[]>([]);

  // Definición de los módulos y submenús
  const modules: ModuleItem[] = [
  
    {
      id: 'functionality',
      title: 'Funcionalidades',
      icon: <Database size={24} />,
      description: 'Funcionalidades principales',
      subItems: [
        { id: 'review', title: 'Reseña', icon: <BookOpen size={18} /> },
        { id: 'sectorial-permits', title: 'Permisos Sectoriales', icon: <FileText size={18} /> },
        { id: 'ai-integration', title: 'Integración IA', icon: <Eye size={18} /> }
      ]
    },
    {
      id: 'contact',
      title: 'Contacto',
      icon: <Mail size={24} />,
      description: 'Envíanos un mensaje'
    }
  ];

  const toggleMenu = (menuId: string) => {
    if (expandedMenus.includes(menuId)) {
      setExpandedMenus(expandedMenus.filter(id => id !== menuId));
    } else {
      setExpandedMenus([...expandedMenus, menuId]);
    }
  };

  const handleButtonClick = (moduleId: string) => {
    // Si es un módulo con submenús, toggle el menú expandido
    const module = modules.find(m => m.id === moduleId);
    if (module?.subItems?.length) {
      toggleMenu(moduleId);
    } else if (moduleId === 'contact') {
      // Si es el botón de contacto, abre el formulario
      setIsContactFormOpen(true);
    } else {
      // Para otros módulos, abre el modal correspondiente
      openModal(moduleId);
    }
  };

  const handleSubItemClick = (subItemId: string) => {
    openModal(subItemId);
  };

  return (
    <div className="flex flex-col gap-2">
      {modules.map((module) => (
        <div key={module.id} className="relative">
          {/* Botón principal */}
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => handleButtonClick(module.id)}
            className="flex items-center justify-between w-full px-4 py-3 text-white bg-custom-sidebar rounded-lg shadow-md hover:bg-opacity-90 transition-all"
          >
            <div className="flex items-center">
              <div className="mr-3">{module.icon}</div>
              <span className="text-sm font-medium hidden md:block">{module.title}</span>
            </div>
            {module.subItems?.length && (
              <div className="ml-2 hidden md:block">
                {expandedMenus.includes(module.id) ? (
                  <ChevronDown className="h-4 w-4" />
                ) : (
                  <ChevronRight className="h-4 w-4" />
                )}
              </div>
            )}
          </motion.button>

          {/* Tooltip para móviles */}
          <div className="absolute left-full ml-2 px-2 py-1 bg-gray-800 text-white text-xs rounded pointer-events-none opacity-0 group-hover:opacity-100 transition-opacity md:hidden">
            {module.title}
          </div>

          {/* Submenús desplegables */}
          <AnimatePresence>
            {module.subItems?.length && expandedMenus.includes(module.id) && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
                className="ml-4 mt-1 space-y-1 overflow-hidden"
              >
                {module.subItems.map((subItem) => (
                  <motion.button
                    key={subItem.id}
                    whileHover={{ scale: 1.03 }}
                    whileTap={{ scale: 0.97 }}
                    onClick={() => handleSubItemClick(subItem.id)}
                    className="flex items-center w-full px-3 py-2 text-white bg-custom-sidebar bg-opacity-80 rounded-md hover:bg-opacity-100 transition-all"
                  >
                    {subItem.icon && <div className="mr-2">{subItem.icon}</div>}
                    <span className="text-xs font-medium hidden md:block">{subItem.title}</span>
                  </motion.button>
                ))}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      ))}
    </div>
  );
};

export default VerticalButtonsWithModal;