import { parse } from 'path';
import { Card, CardHeader, CardContent } from '../ui/card';
import dateUtils from './dateUtils';

import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { AlertTriangle, Bookmark, Calendar, CheckCircle, ChevronDown, ChevronRight, Clock, FileQuestion, Users, X } from 'lucide-react';


interface Process {
  nombreProceso: string;
  codigo: string;
  comuna: string;
  subprocesos?: string[];
  plantilla: string;
}

// Actualizamos las interfaces para coincidir con la estructura existente
interface User {
  id: number;
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  is_admin: boolean;
  departamento: string;
  is_active: boolean;
}

interface Subproceso {
  id: number;
  subproceso: string;
  color: string;
}

interface PlantillaDB {
  id: number;
  nombre: string;
  descripcion: string | null;
  contenido: string;
  tipo: string;
  fecha_creacion: string;
  fecha_modificacion: string;
}

interface Task {
  id: number;
  name: string;
  description: string;
  responsible: User[]; 
  progress: number;
  start: string;
  organismo: string;
  duracion: number;
  end: string;
  followUp: boolean;
  followUpDate: string; // New property for follow-up date
  dependsOn: { groupId: number; taskId: number }[];
  enabled: boolean; // Nueva propiedad
  status: TaskStatus;
  dependencia: string | number | null;
  descriptor: 'GESTIÓN' | 'TRAMITOLOGÍA';
  comments: string; // Nueva propiedad para los comentarios
  semaphoreStatus: SemaphoreStatus; // New property for semaphore status
  isClosed: boolean;
  delayInDays?: number;
  orden: number;
  subtasks?: SubTask[];
}

enum TaskStatus {
  None = "none",
  Green = "green",
  Yellow = "yellow",
  Red = "red",
}


// Actualizar la interfaz SubTask
interface SubTask {
  id: number;
  name: string;
  responsible: User[];
  progress: number;
  start: string;
  end: string;
  duration: number;
  organism: string;
  dependsOn: { groupId: number; taskId: number; subtaskId: number }[];
  enabled: boolean; 
  hidden?: boolean;
  type: 'INGRESO' | 'OBSERVACIÓN' | 'RESPUESTA' | 'RESOLUCIÓN' | 'OTROS';
  resolutionType?: 'APROBADO' | 'RECHAZADO' | 'DESISTIMIENTO' | 'SILENCIO ADMINISTRATIVO POSITIVO' | 'SILENCIO ADMINISTRATIVO NEGATIVO' | 'OTRO';
  orden: number;
  isClosed: boolean;
}





interface ProjectTimelineProps {
  selectedProcess: string;
  setSelectedProcess: React.Dispatch<React.SetStateAction<string>>;
  processes: Process[];
  subprocessColor: string;
}

interface SubprocessTabProps {
  label: string;
  isActive: boolean;
  onClick: () => void;
  color: string;
  index: number;
  totalTabs: number;
  activeIndex: number;
}



const SubprocessTab: React.FC<SubprocessTabProps> = ({ 
  label, 
  isActive, 
  onClick, 
  color, 
  index, 
  totalTabs
}) => {
  const [scale, setScale] = useState(1);
  const activeScale = 1.19;
  const fixedOffset = 20; // Offset fijo para todas las pestañas

  useEffect(() => {
    if (isActive) {
      setScale(activeScale);
    } else {
      setScale(1);
    }
  }, [isActive]);


  

  const buttonStyle: React.CSSProperties = {
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    padding: '0 30px 0 30px',
    fontSize: '14px',
    fontWeight: 'medium',
    color: isActive ? '#ffffff' : '#115E59',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    overflow: 'visible',
    transition: 'all 0.3s',
    marginRight: `-${fixedOffset}px`,
    zIndex: isActive ? totalTabs : index,
    whiteSpace: 'nowrap',
  };

  const shapeStyle: React.CSSProperties = {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: color,
    opacity: isActive ? 1 : 0.3,
    transition: 'opacity 0.3s',
    clipPath: `polygon(0 0, calc(100% - ${fixedOffset}px) 0, 100% 50%, calc(100% - ${fixedOffset}px) 100%, 0 100%, ${fixedOffset}px 50%)`,
  };

  return (
    <motion.button
      onClick={onClick}
      className="relative"
      animate={{ 
        scale,
        zIndex: isActive ? totalTabs : index,
      }}
      whileHover={{ scale: scale * 1.02 }}
      whileTap={{ scale: scale * 0.98 }}
      transition={{ duration: 0.2 }}
      style={buttonStyle}
    >
      <div style={shapeStyle} />
      <span className="relative z-10 px-2">{label}</span>
    </motion.button>
  );
};

interface Agrupador {
  nombre: string;
  fecha_inicio: string;
  fecha_termino: string;
  progreso: number;
  subproceso: string;
  enabled: boolean;
  descriptor: string;
  organismo: string;
  tareas: Task[];
 
  
}


const EmptySubprocessMessage: React.FC<{ subprocessName: string }> = ({ subprocessName }) => {
  return (
    <div className="flex flex-col items-center justify-center p-10 bg-white rounded-lg ">
      <FileQuestion size={60} className="text-teal-500 mb-4" />
      <h3 className="text-2xl font-bold text-teal-700 mb-2">Proceso sin contenido</h3>
      <p className="text-teal-600 text-center mb-6">
        Este proceso no contiene información, por favor revise la planificación.
      </p>
    </div>
  );
};



const parseLocalDate = (dateString: string): Date => {
  const [year, month, day] = dateString?dateString.split('-').map(Number): [0, 0 ,0];
  // Meses en JavaScript van de 0 a 11
  const localDate = new Date(year, month - 1, day);
  return localDate;
};

interface AgrupadorDetailModalProps {
  isOpen: boolean;
  onClose: () => void;
  agrupador: {
    nombre: string;
    fecha_inicio: string;
    fecha_termino: string;
    progreso: number;
    tareas: Task[];
    descriptor: string;
    organismo: string;
    enabled: boolean;
    subproceso: string;
  };
  processName?: string;
  subprocessName?: string;
  subprocessColor?: string;
}


const getSemaphoreColor = (status: SemaphoreStatus): string => {
  const colors = {
    [SemaphoreStatus.Red]: 'bg-red-500',
    [SemaphoreStatus.Orange]: 'bg-orange-500',
    [SemaphoreStatus.Yellow]: 'bg-yellow-500',
    [SemaphoreStatus.Green]: 'bg-green-500',
    [SemaphoreStatus.Gray]: 'bg-gray-300'
  };
  return colors[status] || colors[SemaphoreStatus.Gray];
};

const getSemaphoreIcon = (status: string) => {
  switch (status) {
    case 'red':
      return <AlertTriangle className="w-4 h-4 text-white" />;
    case 'green':
      return <CheckCircle className="w-4 h-4 text-white" />;
    default:
      return <Clock className="w-4 h-4 text-white" />;
  }
};

enum SemaphoreStatus {
  Red = "red",
  Orange = "orange",
  Yellow = "yellow",
  Green = "green",
  Gray = "gray"
}

const calculateSemaphoreStatus = (
  start: string,
  end: string,
  taskId: number,
  dependencia: string | number | null,
  isClosed: boolean,
  allTasks?: Task[],
  parentTask?: Task
): SemaphoreStatus => {
  // Si la tarea está cerrada, retornar gris
  if (isClosed) {
    return SemaphoreStatus.Gray;
  }

  // Obtener fecha actual y establecer a medianoche
  const today = dateUtils.getTodayLocal();
  today.setHours(0, 0, 0, 0);

  // Convertir fechas de string a Date y establecer a medianoche
  const startDate = dateUtils.parseLocalDate(start);
  const endDate = dateUtils.parseLocalDate(end);
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(0, 0, 0, 0);

  // Validación de fechas
  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
    return SemaphoreStatus.Gray;
  }

  // Si la tarea aún no comienza
  if (startDate > today) {
    return SemaphoreStatus.Gray;
  }

  // Si ya pasó la fecha de fin o es el mismo día de fin
  if (today >= endDate) {
    return SemaphoreStatus.Red;
  }

  // Calcular duración total y días restantes
  const totalDuration = Math.max(1, Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)));
  const daysRemaining = Math.ceil((endDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
  const percentageCompleted = ((totalDuration - daysRemaining) / totalDuration) * 100;

  // Para debug
  console.log({
    taskId,
    start: startDate.toISOString(),
    end: endDate.toISOString(),
    today: today.toISOString(),
    totalDuration,
    daysRemaining,
    percentageCompleted
  });

  // Enfoque híbrido: usar días restantes para tareas cortas y porcentajes para largas
  if (totalDuration <= 5) {
    // Lógica para tareas cortas (5 días o menos)
    if (daysRemaining === 0) {
      return SemaphoreStatus.Red;     // Mismo día = Rojo
    } else if (daysRemaining === 1) {
      return SemaphoreStatus.Orange;  // Un día restante = Naranja
    } else if (daysRemaining <= 3) {
      return SemaphoreStatus.Yellow;  // 2-3 días restantes = Amarillo
    }
    return SemaphoreStatus.Green;     // Más de 3 días = Verde
  } else {
    // Lógica para tareas largas (más de 5 días)
    if (percentageCompleted >= 90) {
      return SemaphoreStatus.Red;     // 90% o más completado = Rojo
    } else if (percentageCompleted >= 75) {
      return SemaphoreStatus.Orange;  // 75-89% completado = Naranja
    } else if (percentageCompleted >= 50) {
      return SemaphoreStatus.Yellow;  // 50-74% completado = Amarillo
    }
    return SemaphoreStatus.Green;     // Menos del 50% completado = Verde
  }
};


const getSemaphoreTooltipText = (status: SemaphoreStatus): string => {
  switch (status) {
    case SemaphoreStatus.Red:
      return 'Tarea vencida';
    case SemaphoreStatus.Orange:
      return 'Por vencer en 2 días o menos';
    case SemaphoreStatus.Yellow:
      return 'Por vencer en 5 días o menos';
    case SemaphoreStatus.Green:
      return 'En tiempo';
    case SemaphoreStatus.Gray:
      return 'No iniciada o completada';
    default:
      return '';
  }
};


const CheckerboardPattern = ({ subprocessColor }: { subprocessColor: string }) => (
  <div
    className="relative w-8 h-8 rounded-full overflow-hidden flex items-center justify-center"
    style={{ 
      backgroundImage: `repeating-conic-gradient(${subprocessColor} 0% 25%, #fff 0% 50%)`,
      backgroundSize: '10px 10px',
      border: `1px solid ${subprocessColor}`,
      borderRadius: '50%',
    }}
  >
  </div>
);




function AgrupadorDetailModal({ isOpen, onClose, agrupador, processName, subprocessName, subprocessColor }: AgrupadorDetailModalProps) {

 
  const [expandedTasks, setExpandedTasks] = useState<number[]>([]);
  
  const toggleTask = (taskId: number) => {
    setExpandedTasks(prev => 
      prev.includes(taskId) 
        ? prev.filter(id => id !== taskId)
        : [...prev, taskId]
    );
  };
 
  const formatDate = (dateString: string) => {
    if (!dateString || !dateString.includes('-')) return '-';
    const [year, month, day] = dateString.split('-');
    return `${day}-${month}-${year}`;
  };
  

  return (
    <AnimatePresence>
      {isOpen && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black bg-opacity-40 transition-opacity"
              onClick={onClose}
            />

<div
  className="relative inline-block w-full max-w-4xl p-6 my-8 text-left bg-gradient-to-b rounded-lg shadow-xl transform transition-all"
  style={{ 
    background: '#fff' 
  }}
>
              {/* Process and Subprocess Banner */}
              <div 
                className="absolute top-0 left-0 right-0 text-white p-3 rounded-t-lg"
                style={{ backgroundColor: subprocessColor }}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <Bookmark className="w-5 h-5" />
                    <span className="text-sm font-medium">
                      {processName} / {subprocessName}
                    </span>
                  </div>
                </div>
              </div>

              {/* Header Section with increased top padding for banner */}
              <div className="border-b pb-4 mb-4 mt-12" style={{ borderColor: `${subprocessColor}40` }}>
                <div className="flex justify-between items-start">
                  <div>
                    <h3 className="text-2xl font-bold" style={{ color: subprocessColor }}>
                      {agrupador.nombre}
                    </h3>
                    <div className="flex items-center mt-2 space-x-2">
                      <span className="px-3 py-1 rounded-full text-sm" 
                        style={{ 
                          backgroundColor: `${subprocessColor}20`,
                          color: subprocessColor 
                        }}>
                        {agrupador.descriptor}
                      </span>
                      <span className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm">
                        {agrupador.organismo}
                      </span>
                    </div> 
                  </div>
                  <button
  className="p-2 rounded-full transition-colors hover:bg-opacity-20"
  style={{ backgroundColor: `${subprocessColor}20` }}
  onClick={onClose}
>
  <X className="w-6 h-6" style={{ color: subprocessColor }} />
</button>

                </div>

                <div className="mt-4 grid grid-cols-3 gap-4">
                  <Card className="bg-white shadow-sm">
                    <CardContent className="p-4">
                      <div className="flex items-center space-x-2">
                        <Calendar className="w-5 h-5" style={{ color: subprocessColor }} />
                        <div>
                          <p className="text-sm" style={{ color: subprocessColor }}>Fecha Inicio</p>
                          <p className="font-medium text-gray-900">{agrupador.fecha_inicio}</p>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                  <Card className="bg-white shadow-sm">
                    <CardContent className="p-4">
                      <div className="flex items-center space-x-2">
                        <Calendar className="w-5 h-5" style={{ color: subprocessColor }} />
                        <div>
                          <p className="text-sm" style={{ color: subprocessColor }}>Fecha Término</p>
                          <p className="font-medium text-gray-900">{agrupador.fecha_termino}</p>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                  <Card className="bg-white shadow-sm">
                    <CardContent className="p-4">
                      <p className="text-sm mb-1" style={{ color: subprocessColor }}>Progreso Total</p>
                      <div className="flex items-center">
                        <div className="flex-1 h-2 bg-gray-100 rounded-full mr-2">
                          <div
                            className="h-2 rounded-full transition-all duration-300"
                            style={{ 
                              width: `${agrupador.progreso}%`,
                              backgroundColor: subprocessColor 
                            }}
                          />
                        </div>
                        <span className="text-sm font-medium text-gray-900">{agrupador.progreso}%</span>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </div>

              {/* Tasks Table */}
              <div className="overflow-hidden rounded-lg border" style={{ borderColor: `${subprocessColor}40` }}>
                <table className="min-w-full divide-y" style={{ borderColor: `${subprocessColor}40` }}>
                  <thead style={{ backgroundColor: `${subprocessColor}10` }}>
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                          style={{ color: subprocessColor }}>
                        Tarea
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                          style={{ color: subprocessColor }}>
                        Responsable
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                          style={{ color: subprocessColor }}>
                        Fechas
                      </th>
                      <th className="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider"
                          style={{ color: subprocessColor }}>
                        Estado
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-100">
      {agrupador.tareas.map((tarea, index) => {
        const semaphoreStatus = tarea.semaphoreStatus;
        const isExpanded = expandedTasks.includes(tarea.id);
        const hasSubtasks = tarea.subtasks && tarea.subtasks.length > 0;

        return (
          <React.Fragment key={tarea.id}>
            <motion.tr
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className={`${
                index % 2 === 0 ? 'bg-white' : `bg-opacity-5`
              } ${tarea.isClosed ? 'bg-green-50' : ''}`}
              style={{ 
                backgroundColor: index % 2 === 0 ? 'white' : `${subprocessColor}05`
              }}
            >
              <td className="px-6 py-4">
                <div className="flex items-center space-x-2">
                  {hasSubtasks && (
                    <button
                      onClick={() => toggleTask(tarea.id)}
                      className="focus:outline-none"
                    >
                      {isExpanded ? (
                        <ChevronDown className="w-4 h-4" style={{ color: subprocessColor }} />
                      ) : (
                        <ChevronRight className="w-4 h-4" style={{ color: subprocessColor }} />
                      )}
                    </button>
                  )}
                  <div>
                    <div className="text-sm font-medium text-gray-900">
                      {tarea.name}
                    </div>
                    {hasSubtasks && (
                   <div className="text-xs mt-1" style={{ color: subprocessColor }}>
                   {tarea.subtasks?.length || 0} subtarea(s)
                 </div>
                 
                    )}
                  </div>
                </div> 
              </td>
                          <td className="px-6 py-4">
                            <div className="relative group">
                              <button className="flex items-center space-x-1 hover:text-teal-800"
                                     style={{ color: subprocessColor }}>
                                <Users className="w-5 h-5" />
                                <span className="text-sm">{tarea.responsible?.length || 0} responsables</span>
                              </button>
                              <div className="absolute z-[100] hidden group-hover:block mt-2 w-64 bg-white rounded-lg shadow-lg border border-gray-200 -translate-x-1/2 left-1/2">
                                <div className="p-3">
                                  <h4 className="text-sm font-medium mb-2" style={{ color: subprocessColor }}>
                                    Responsables:
                                  </h4>
                                  <ul className="space-y-1">
                                    {tarea.responsible?.map((r, idx) => (
                                      <li key={idx} className="text-sm text-gray-600">
                                        {r.firstname} {r.lastname}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-6 py-4">
                            <div className="text-sm text-gray-600">
                              <div className="flex flex-col space-y-1">
                                <span>Inicio: {formatDate(tarea.start)}</span>
                                <span>Término: {formatDate(tarea.end)}</span>
                              </div>
                            </div>
                          </td> 
                          <td className="px-6 py-4">
  <div className="flex justify-center items-center space-x-2">
    <div className="group relative">
      {tarea.isClosed ? (
        <CheckerboardPattern subprocessColor={subprocessColor || '#000000'} />

        
      ) : (
        <div className={`w-8 h-8 rounded-full flex items-center justify-center ${
          getSemaphoreColor(semaphoreStatus)
        }`}>
          {getSemaphoreIcon(semaphoreStatus)}
        </div>
      )}
      <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 bg-gray-800 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap z-[100]">
        {tarea.isClosed ? 'Tarea completada' : getSemaphoreTooltipText(semaphoreStatus)}
      </div>
    </div>
    {tarea.isClosed && (
      <span className="text-xs text-gray-600">Completada</span>
    )}
  </div>
</td>
                        </motion.tr>
                     {/* Subtareas */}
                     {isExpanded && hasSubtasks && tarea.subtasks?.map((subtask, subtaskIndex) => (
              <motion.tr
                key={`${tarea.id}-${subtask.id}`}
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                className="bg-gray-50"
              >
                <td className="px-6 py-3">
                  <div className="flex items-center pl-6">
                    <div className="w-1 h-1 rounded-full mr-2" style={{ backgroundColor: subprocessColor }}></div>
                    <span className="text-sm text-gray-600">{subtask.name}</span>
                  </div>
                </td>
                <td className="px-6 py-3">
                  <div className="relative group">
                    <button className="flex items-center space-x-1 hover:text-teal-800"
                           style={{ color: subprocessColor }}>
                      <Users className="w-5 h-5" />
                      <span className="text-sm">{subtask.responsible?.length || 0} responsables</span>
                    </button>
                    <div className="absolute z-[100] hidden group-hover:block mt-2 w-64 bg-white rounded-lg shadow-lg border border-gray-200 -translate-x-1/2 left-1/2">
                      <div className="p-3">
                        <h4 className="text-sm font-medium mb-2" style={{ color: subprocessColor }}>
                          Responsables:
                        </h4>
                        <ul className="space-y-1">
                          {subtask.responsible?.map((r, idx) => (
                            <li key={idx} className="text-sm text-gray-600">
                              {r.firstname} {r.lastname}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </td>
                <td className="px-6 py-3">
                  <div className="text-sm text-gray-600">
                    <div className="flex flex-col space-y-1">
                      <span>Inicio: {formatDate(subtask.start)}</span>
                      <span>Término: {formatDate(subtask.end)}</span>
                    </div>
                  </div>
                </td>
                <td className="px-6 py-3">
  <div className="flex justify-center items-center space-x-2">
    <div className="group relative">
      {subtask.isClosed ? (
        <CheckerboardPattern subprocessColor={subprocessColor || '#000000'} />
      ) : (
        <div className={`w-8 h-8 rounded-full flex items-center justify-center ${
          getSemaphoreColor(calculateSemaphoreStatus(
            subtask.start,
            subtask.end,
            subtask.id,
            null,
            subtask.isClosed,
            undefined,
            undefined
          ))
        }`}>
          <div className="flex items-center justify-center">
            {getSemaphoreIcon(calculateSemaphoreStatus(
              subtask.start,
              subtask.end,
              subtask.id,
              null,
              subtask.isClosed,
              undefined,
              undefined
            ))}
          </div>
        </div>
      )}
      <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 bg-gray-800 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap z-[100]">
        {subtask.isClosed ? 'Subtarea completada' : getSemaphoreTooltipText(
          calculateSemaphoreStatus(
            subtask.start,
            subtask.end,
            subtask.id,
            null,
            subtask.isClosed,
            undefined,
            undefined
          )
        )}
      </div>
    </div>
    {subtask.isClosed && (
      <span className="text-xs text-gray-600">Completada</span>
    )}
  </div>
</td>
              </motion.tr>
            ))}
          </React.Fragment>
        );
      })}
    </tbody>
                </table>
              </div>
              </div>
          </div>
        </div>
      )}
    </AnimatePresence>
  );
}


const ProjectTimeline: React.FC<ProjectTimelineProps> = ({ selectedProcess, setSelectedProcess, processes, subprocessColor}) => {
  const [selectedNombreProyecto, setSelectedNombreProyecto] = useState('');
  const [selectedCodigoProyecto, setSelectedCodigoProyecto] = useState('');
  const [subprocesos, setSubprocesos] = useState<Subproceso[]>([]);
 // const [processes, setProcesses] = useState<Process[]>([]);
  const [plantillaDB, setPlantillaDB] = useState<PlantillaDB | null>(null);
  const [isProcessLoaded, setIsProcessLoaded] = useState(false);
  const [activeSubprocess, setActiveSubprocess] = useState<number>(0);
  const [processedPlantilla, setProcessedPlantilla] = useState<any>(null);
  const [allAgrupadores, setAllAgrupadores] = useState<Agrupador[]>([]);
  const [currentAgrupadores, setCurrentAgrupadores] = useState<Agrupador[]>([]);
  const [filteredSubprocesos, setFilteredSubprocesos] = useState<Subproceso[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const previousProcessRef = useRef<string | null>(null);
  const [selectedAgrupador, setSelectedAgrupador] = useState<Agrupador | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  

  const formatDate = (dateString: string): string => {
    const date = parseLocalDate(dateString);
    return date.toLocaleDateString('es-CL', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }).replace(/\//g, '-');
  };
  


  /*
  useEffect(() => {
    const fetchProcesses = async () => {
      try {
        const response = await fetch('http://localhost:3000/php/pages/proceso/get_processes.php?type=type3');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data: Process[] = await response.json();
        setProcesses(data);
      } catch (error) {
        console.error('Error fetching processes:', error);
      }
    };
    fetchProcesses();
  }, []);*/


  useEffect(() => {
    if (filteredSubprocesos && filteredSubprocesos.length > 0 && allAgrupadores && allAgrupadores.length > 0) {
      const savedSubprocess = localStorage.getItem('selectedSubprocess');
      
      if (savedSubprocess) {
        const subprocessIndex = filteredSubprocesos.findIndex(sp => sp?.subproceso === savedSubprocess);
  
        if (subprocessIndex !== -1) {
          const agrupadoresForSavedSubprocess = allAgrupadores.filter(agrupador => agrupador?.subproceso === filteredSubprocesos[subprocessIndex]?.subproceso);
          
          setCurrentAgrupadores(agrupadoresForSavedSubprocess);
          setActiveSubprocess(subprocessIndex);
          handleSubprocessChange(subprocessIndex);
        } else {
          const firstSubprocess = filteredSubprocesos[0];
          const agrupadoresForFirstSubprocess = allAgrupadores.filter(agrupador => agrupador?.subproceso === firstSubprocess?.subproceso);
          
          setCurrentAgrupadores(agrupadoresForFirstSubprocess);
          setActiveSubprocess(0);
          handleSubprocessChange(0);
        }
      } else {
        const firstSubprocess = filteredSubprocesos[0];
        const agrupadoresForFirstSubprocess = allAgrupadores.filter(agrupador => agrupador?.subproceso === firstSubprocess?.subproceso);
        
        setCurrentAgrupadores(agrupadoresForFirstSubprocess);
        setActiveSubprocess(0);
        handleSubprocessChange(0);
      }
    } else {
      setActiveSubprocess(-1);
      setCurrentAgrupadores([]);
    }
  }, [filteredSubprocesos, allAgrupadores]);
  
  useEffect(() => {
    if (activeSubprocess !== -1 && filteredSubprocesos && filteredSubprocesos[activeSubprocess]) {
      localStorage.setItem('selectedSubprocess', filteredSubprocesos[activeSubprocess].subproceso);
    }
  }, [activeSubprocess, filteredSubprocesos]);
  
  // En el componente donde renderizas el agrupador, añade el manejador de clic:
const handleAgrupadorClick = (agrupador: Agrupador) => {
  setSelectedAgrupador(agrupador);
  setIsModalOpen(true);
};


  useEffect(() => {
    if (selectedProcess) {
      loadProcessData(selectedProcess);
      
    }
  }, [selectedProcess]);
/*
  useEffect(() => {
    if (filteredSubprocesos.length > 0 && allAgrupadores.length > 0) {
      setActiveSubprocess(0);
      const firstSubprocess = filteredSubprocesos[0];
      const agrupadoresForFirstSubprocess = allAgrupadores.filter(agrupador => agrupador.subproceso === firstSubprocess.subproceso);
      setCurrentAgrupadores(agrupadoresForFirstSubprocess);
    } else {
      setActiveSubprocess(-1);
      setCurrentAgrupadores([]);
    }
  }, [filteredSubprocesos, allAgrupadores]);*/


  const resetSubprocessState = () => {
    setActiveSubprocess(0);
    setCurrentAgrupadores([]);
    localStorage.removeItem('selectedSubprocess');
  };
  


  useEffect(() => {
    const initializeSubprocess = () => {
      if (!filteredSubprocesos.length || !allAgrupadores.length) {
        setActiveSubprocess(-1);
        setCurrentAgrupadores([]);
        return;
      }

      const savedSubprocess = localStorage.getItem('selectedSubprocess');
      let targetIndex = 0;

      if (savedSubprocess) {
        const foundIndex = filteredSubprocesos.findIndex(
          sp => sp.subproceso === savedSubprocess
        );
        if (foundIndex !== -1) {
          targetIndex = foundIndex;
        }
      }

      const targetSubprocess = filteredSubprocesos[targetIndex];
      const relevantAgrupadores = allAgrupadores.filter(
        agrupador => agrupador.subproceso === targetSubprocess.subproceso
      );

      setActiveSubprocess(targetIndex);
      setCurrentAgrupadores(relevantAgrupadores);
    };

    initializeSubprocess();
  }, [filteredSubprocesos, allAgrupadores]);



  const loadProcessData = async (processName: string) => {
    setIsLoading(true);

    // Verificar si el proceso ha cambiado realmente
    if (processName === previousProcessRef.current) {
      setIsLoading(false);
      return;
    }

    // Limpiar estado si no hay proceso seleccionado
    if (processName === "") {
      resetState();
      setIsLoading(false);
      return;
    }

    const selectedProcess = processes.find(p => p.nombreProceso === processName);
    if (!selectedProcess) {
      setIsLoading(false);
      return;
    }

    try {
      setSelectedNombreProyecto(selectedProcess.nombreProceso);
      setSelectedCodigoProyecto(selectedProcess.codigo);

      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/proceso/get_process_data.php?codigo=${selectedProcess.codigo}`);
      if (!response.ok) throw new Error('Network response was not ok');
      
      const data = await response.json();

      // Procesar subprocesos
      const fetchedSubprocesos: Subproceso[] = data.subprocesos
      .filter((sp: any) => 
        sp.nombre.toLowerCase() !== "sin subproceso" &&
        sp.agrupadores && 
        sp.agrupadores.some((agr: Agrupador) => agr.enabled)
      )
      .map((sp: any) => ({
        id: sp.id,
        nombre: sp.nombre,
        subproceso: sp.nombre,
        color: sp.color || "",
      }));
    
      // Procesar agrupadores
      const processedAgrupadores: Agrupador[] = data.subprocesos.flatMap((subproceso: any) =>
        subproceso.agrupadores
          .filter((agrupador: any) => agrupador.enabled)
          .map((agrupador: any) => ({
            nombre: agrupador.nombre,
            fecha_inicio: formatDate(getEarliestStartDate(agrupador.tareas)),
            fecha_termino: formatDate(getLatestEndDate(agrupador.tareas)),
            progreso: calculateAverageProgress(agrupador.tareas),
            subproceso: subproceso.nombre,
            enabled: agrupador.enabled,
            descriptor: agrupador.descriptor,
            organismo: agrupador.organismo,
            tareas: agrupador.tareas.map((tarea: any) => ({
              id: tarea.id,
              name: tarea.nombre,
              description: tarea.nombre,
              responsible: Array.isArray(tarea.responsable) 
                ? tarea.responsable 
                : typeof tarea.responsable === 'string'
                  ? [{ 
                      id: 0,
                      username: '',
                      firstname: tarea.responsable,
                      lastname: '',
                      email: '',
                      is_admin: false,
                      departamento: '',
                      is_active: true
                    }]
                  : [],
              progress: tarea.progreso,
              start: tarea.fecha_inicio,
              end: tarea.fecha_termino,
              followUp: tarea.followUp || false,
              followUpDate: tarea.followUpDate || '',
              dependsOn: [],
              enabled: tarea.enabled,
              status: TaskStatus.None,
              dependencia: tarea.dependencia,
              descriptor: tarea.descriptor as 'GESTIÓN' | 'TRAMITOLOGÍA',
              comments: tarea.comments || '',
              semaphoreStatus: calculateSemaphoreStatus(
                tarea.fecha_inicio, 
                tarea.fecha_termino, 
                tarea.id,
                tarea.dependencia,
                tarea.isClosed, 
                agrupador.tareas,
                undefined
              ),
              isClosed: Boolean(tarea.isClosed),
              organismo: tarea.organismo || agrupador.organismo,
              duracion: tarea.duracion || 0,
              orden: tarea.orden || 0,
              subtasks: tarea.subtasks ? tarea.subtasks.map((subtask: any) => ({
                id: subtask.id,
                name: subtask.nombre,
                responsible: subtask.responsable || [],
                progress: subtask.progreso,
                start: subtask.fecha_inicio,
                end: subtask.fecha_termino,
                duration: subtask.duracion || 0,
                organism: subtask.organismo || tarea.organismo,
                dependsOn: [],
                enabled: subtask.enabled,
                type: subtask.tipo,
                resolutionType: subtask.resolucion_tipo,
                orden: subtask.orden || 0,
                isClosed: Boolean(subtask.isClosed)
              })) : []
            }))
          }))
      );

      const filteredSubs = fetchedSubprocesos.filter(subproceso => 
        processedAgrupadores.some(agrupador => agrupador.subproceso === subproceso.subproceso)
      );

      setSubprocesos(fetchedSubprocesos);
      setAllAgrupadores(processedAgrupadores);
      setFilteredSubprocesos(filteredSubs);
      setIsProcessLoaded(true);

      // No establecemos aquí el activeSubprocess ni currentAgrupadores
      // porque el useEffect se encargará de eso

    } catch (error) {
      console.error('Error al cargar el proceso:', error);
      resetState();
    } finally {
      previousProcessRef.current = processName;
      setIsLoading(false);
    }
  };


  // Funciones auxiliares
  const getDefaultColor = (id: number): string => {
    const colors = ['#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8', '#F7DC6F', '#BB8FCE', '#5DADE2', '#45B39D', '#EC7063'];
    return colors[id % colors.length];
  };
  const getEarliestStartDate = (tareas: any[]): string => {
    return tareas.reduce((earliest, tarea) => 
      parseLocalDate(tarea.fecha_inicio) < parseLocalDate(earliest) ? tarea.fecha_inicio : earliest
    , tareas[0]?.fecha_inicio || '');
  };
  
  const getLatestEndDate = (tareas: any[]): string => {
    return tareas.reduce((latest, tarea) => 
      parseLocalDate(tarea.fecha_termino) > parseLocalDate(latest) ? tarea.fecha_termino : latest
    , tareas[0]?.fecha_termino || '');
  };
  
  const calculateAverageProgress = (tareas: Task[]): number => {
    if (tareas.length === 0) return 0;
    
    const totalCompletedTasks = tareas.reduce((sum, tarea) => sum + (tarea.isClosed ? 1 : 0), 0);
    return Math.round((totalCompletedTasks / tareas.length) * 100);
  };


  const resetState = () => {
    setSelectedCodigoProyecto('');
    setSubprocesos([]);
    setFilteredSubprocesos([]);
    setAllAgrupadores([]);
    setCurrentAgrupadores([]);
    setIsProcessLoaded(false);
  };



  
  const handleSubprocessChange = (index: number) => {
    if (index < 0 || index >= filteredSubprocesos.length) return;

    const selectedSubprocess = filteredSubprocesos[index];
    const agrupadoresForSubprocess = allAgrupadores.filter(
      agrupador => agrupador.subproceso === selectedSubprocess.subproceso
    );

    setActiveSubprocess(index);
    setCurrentAgrupadores(agrupadoresForSubprocess);
    localStorage.setItem('selectedSubprocess', selectedSubprocess.subproceso);
  };



  const lightenColor = (color: string, amount: number): string => {
    if (!color) return '#CCCCCC'; // Color por defecto si no se proporciona
    const num = parseInt(color.replace("#", ""), 16);
    const r = Math.min(255, Math.max(0, (num >> 16) + amount));
    const g = Math.min(255, Math.max(0, ((num >> 8) & 0x00FF) + amount));
    const b = Math.min(255, Math.max(0, (num & 0x0000FF) + amount));
    return `#${(1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1)}`;
  };
  
  const darkenColor = (color: string, amount: number): string => {
    return lightenColor(color || '#CCCCCC', -amount);
  };
  const tableContainerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { 
        duration: 0.5,
        type: "spring",
        stiffness: 100,
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    },
    exit: { 
      opacity: 0,
      y: -50,
      transition: { duration: 0.3 }
    }
  };

  const rowVariants = {
    hidden: { opacity: 0, x: -20, scale: 0.95 },
    visible: { 
      opacity: 1, 
      x: 0,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 15
      }
    },
    hover: {
      scale: 1.02,
      backgroundColor: "#f8fafc",
      transition: { duration: 0.2 }
    }
  };

  const progressBarVariants = {
    hidden: { width: 0 },
    visible: (progress: number) => ({
      width: `${progress}%`,
      transition: { duration: 0.8, ease: "easeOut" }
    })
  };























  return (
    <div className="w-full rounded-xl shadow-xl overflow-hidden p-6">
      {isLoading ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 0 }}
          exit={{ opacity: 0 }}
          
          className="flex items-center justify-center p-10"
        >
          <div className="w-16 h-16 border-4 border-teal-500 border-t-transparent rounded-full animate-spin" />
        </motion.div>
      ) : isProcessLoaded && filteredSubprocesos.length > 0 ? (
        <>
          <motion.div
            layout
            className="flex mb-8  mt-3"
            style={{ opacity: 1 }} // Asegúrate de que no se aplique ninguna opacidad aquí

          >
            {filteredSubprocesos.map((subprocess, index) => (
              <SubprocessTab
                key={index}
                label={subprocess.subproceso}
                isActive={activeSubprocess === index}
                onClick={() => handleSubprocessChange(index)}
                color={subprocess.color}
                index={index}
                totalTabs={filteredSubprocesos.length}
                activeIndex={activeSubprocess}
              />
            ))}
          </motion.div>
  
          {currentAgrupadores.length > 0 ? (
            <AnimatePresence mode="wait">
              <motion.div
                key={activeSubprocess}
                variants={tableContainerVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                className="overflow-hidden rounded-xl shadow-lg"
              >
                <table className="min-w-full">
                  <thead style={{ 
                    backgroundColor: filteredSubprocesos[activeSubprocess]?.color || '#CCCCCC'
                  }}>
                    <tr>
                      <th scope="col" className="px-6 py-4 text-left text-xs font-bold text-white uppercase tracking-wider">ID</th>
                      <th scope="col" className="px-6 py-4 text-left text-xs font-bold text-white uppercase tracking-wider">Agrupador</th>
                      <th scope="col" className="px-6 py-4 text-left text-xs font-bold text-white uppercase tracking-wider">Fecha Inicio</th>
                      <th scope="col" className="px-6 py-4 text-left text-xs font-bold text-white uppercase tracking-wider">Fecha Término</th>
                      <th scope="col" className="px-6 py-4 text-left text-xs font-bold text-white uppercase tracking-wider">Progreso</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-100">
                    {currentAgrupadores.map((agrupador, index) => (
                      <motion.tr
                        key={`${agrupador.subproceso}-${index}`}
                        variants={rowVariants}
                        initial="hidden"
                        animate="visible"
                        whileHover="hover"
                        className="hover:bg-gray-50 transition-colors cursor-pointer"
                        onClick={() => handleAgrupadorClick(agrupador)}

                      >
                        
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{index + 1}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{agrupador.nombre}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{agrupador.fecha_inicio}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{agrupador.fecha_termino}</td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="w-full bg-gray-200 rounded-full h-2.5 mr-2 overflow-hidden">
                              <motion.div
                                className="bg-teal-500 h-2.5 rounded-full"
                                variants={progressBarVariants}
                                custom={agrupador.progreso}
                                initial="hidden"
                                animate="visible"
                              />
                            </div>
                            <motion.span
                              initial={{ opacity: 1 }}
                              animate={{ opacity: 1 }}
                              className="text-sm font-medium text-gray-700"
                            >
                              {agrupador.progreso}%
                            </motion.span>
                          </div>
                        </td>
                      </motion.tr>
                    ))}
                  </tbody>
                </table>
              </motion.div>
            </AnimatePresence>
          ) : (
            <EmptySubprocessMessage subprocessName={filteredSubprocesos[activeSubprocess]?.subproceso || ""} />
          )}
        </>
      ) : (
        <EmptySubprocessMessage subprocessName={filteredSubprocesos[activeSubprocess]?.subproceso || ""} />
      )}
{isModalOpen && selectedAgrupador && (
  <AgrupadorDetailModal
  isOpen={isModalOpen}
  onClose={() => setIsModalOpen(false)}
  agrupador={selectedAgrupador}
  processName={selectedProcess}
  subprocessName={filteredSubprocesos[activeSubprocess]?.subproceso}
  subprocessColor={filteredSubprocesos[activeSubprocess]?.color}
/>
)}

    </div>
    
  );
  
}
export default ProjectTimeline;