import React, { useEffect, useState } from 'react';
import { Edit, Trash, CheckCircle, XCircle, UserPlus, PlusCircle, Loader2, ShieldCheck, User, BookOpen, ExternalLink } from 'lucide-react';
import { Card, CardHeader, CardContent, CardTitle } from '../ui/card';
import { Input } from '../ui/input';
import { Button } from '../ui/button';
import toast from 'react-hot-toast';
import { Switch } from '../ui/switch';
import { isExternal } from 'util/types';
import ConfirmDialog from '../ui/confirmDialog';
import PhoneInput from 'react-phone-input-2';

import 'react-phone-input-2/lib/style.css';

interface User {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  is_admin: boolean;
  departamento: string;
  empresa: string;
  is_active: boolean;
  is_external: boolean;
  cargo: string;
  user_profile?: string;
}

const profileLabels: Record<string, string> = {
  admin: "Administrador",
  standard: "Usuario Estándar",
  biblioteca_normativa: "Biblioteca Normativa",
  externo: "Usuario Externo",
};



const UserManagement: React.FC = () => {
  // Estados para la lista de usuarios
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  // Estado para el usuario logueado
const [currentUserId, setCurrentUserId] = useState<number | null>(null);

  // Estados para la ordenación
  const [sortField, setSortField] = useState<keyof User>('id');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  
  // Estados para modales
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isInviting, setIsInviting] = useState<boolean>(false);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  
  // Estados para el formulario de invitación
  const [inviteEmail, setInviteEmail] = useState('');
  const [inviteName, setInviteName] = useState('');
  const [inviteLastname, setInviteLastname] = useState('');
  const [inviteCargo, setInviteCargo] = useState('');
  const [inviteDpto, setInviteDpto] = useState('');
  const [inviteCompany, setInviteCompany] = useState('');
  const [inviteExternal, setInviteExternal] =  useState<boolean>(false);
  const [invitePhone, inviteSetPhone] = useState('');

  const [userProfile, setUserProfile] = useState('');
  const [inviteLoading, setInviteLoading] = useState(false);
  
  // Asegúrate de declarar estas variables al principio del componente:
const [userAuthorized, setUserAuthorized] = useState<boolean>(false);
const departamentos: string[] = ['Finanzas', 'Arquitectura', 'Contabilidad', 'Recursos Humanos', 'IT'];
const [password, setPassword] = useState('');

const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState<boolean>(false);
const [userToDelete, setUserToDelete] = useState<number | null>(null);
const userDetails = userToDelete ? users.find(user => user.id === userToDelete) : null;

const [searchTerm, setSearchTerm] = useState('');



// Manejar cambios en el checkbox de administrador en el modal de edición
const handleAdminCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  const isAdminChecked = e.target.checked;
  if (!selectedUser) return;

  setSelectedUser({ 
    ...selectedUser, 
    is_admin: isAdminChecked,
    user_profile: isAdminChecked
      ? 'admin'
      : selectedUser.user_profile === 'admin'
        ? 'standard'
        : selectedUser.user_profile
  });
};

const handleEditProfileChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
  const profile = e.target.value;
  if (!selectedUser) return;

  setSelectedUser({
    ...selectedUser,
    user_profile: profile,
    is_admin: profile === 'admin'
  });
};


// Add this function near getSortedUsers() or other user filtering functions
const getFilteredUsers = () => {
  const sorted = getSortedUsers();
  if (!searchTerm.trim()) return sorted;
  
  return sorted.filter(user => {
    const searchLower = searchTerm.toLowerCase();
    return (
      user.firstname.toLowerCase().includes(searchLower) ||
      user.lastname.toLowerCase().includes(searchLower) ||
      user.email.toLowerCase().includes(searchLower) ||
      user.departamento.toLowerCase().includes(searchLower) ||
      user.empresa.toLowerCase().includes(searchLower) ||
      user.cargo?.toLowerCase().includes(searchLower) ||
      (user.user_profile && profileLabels[user.user_profile]?.toLowerCase().includes(searchLower))
    );
  });
};

  // Estados para el formulario de creación
  const [newUser, setNewUser] = useState<Omit<User, 'id'> & { user_profile?: string }>({
    firstname: '',
    lastname: '',
    email: '',
    is_admin: false,
    departamento: '',
    empresa: '',
    is_active: true,
    is_external: false,
    cargo: '',
    user_profile: ''
  });



  const [inviteFormValid, setInviteFormValid] = useState(false);
  const [createFormValid, setCreateFormValid] = useState(false);
  
  // Replace the existing isFormValid variable with a useEffect for invite form validation
  useEffect(() => {
    // Validation for invite form
    const isValid = 
      inviteName.trim() !== '' &&
      inviteLastname.trim() !== '' &&
      inviteEmail.trim() !== '' &&
      inviteDpto.trim() !== '' &&
      inviteCompany.trim() !== '' &&
      inviteCargo.trim() !== '' &&
      userProfile !== '';
    
    setInviteFormValid(isValid);
  }, [inviteName, inviteLastname, inviteEmail, inviteDpto, inviteCompany, inviteCargo, userProfile]);
  
  // Add validation for create user form
  useEffect(() => {
    // Validation for create user form
    const isValid = 
      newUser.firstname.trim() !== '' &&
      newUser.lastname.trim() !== '' &&
      newUser.email.trim() !== '' &&
      newUser.departamento.trim() !== '' &&
      newUser.empresa.trim() !== '' &&
      newUser.user_profile !== '';
    
    setCreateFormValid(isValid);
  }, [newUser.firstname, newUser.lastname, newUser.email, newUser.departamento, newUser.empresa, newUser.user_profile]);
  


  const resetForm = () => {
    setNewUser({
      firstname: '',
      lastname: '',
      email: '',
      empresa: '',
      departamento: '',
      cargo: '',
      user_profile: '',
      is_admin: false,
      is_external: false,
      is_active: true,
    });
    setPassword('');
  };
  
  

  const [createLoading, setCreateLoading] = useState(false);
  
  // Función para generar nombre de usuario automáticamente
  // const generateUsername = (firstName: string, lastName: string) => {
  //   if (!firstName || !lastName) return '';
    
  //   const [firstNamePart] = firstName.split(' ');
  //   const lastNamePart = lastName.split(' ')[0];
  //   const usernamePrefix = firstNamePart.charAt(0).toLowerCase();
  //   const usernameSuffix = lastNamePart.toLowerCase();
  //   return `${usernamePrefix}${usernameSuffix}`;
  // };
  
  // Manejar cambio de perfil
// Manejar cambio de perfil


const confirmDeleteUser = (id: number) => {
  // No permitir eliminar la propia cuenta
  if (id === currentUserId) {
    toast.error('No puedes eliminar tu propia cuenta');
    return;
  }
  
  // Guardar el ID del usuario a eliminar y mostrar el diálogo de confirmación
  setUserToDelete(id);
  setIsDeleteConfirmOpen(true);
};

const handleProfileChange = (profile: string) => {
  setNewUser({
    ...newUser,
    user_profile: profile,
    is_admin: profile === 'admin'
  });
};
  // Función para ordenar los usuarios
  const sortUsers = (field: keyof User) => {
    if (sortField === field) {
      // Si ya estamos ordenando por este campo, invertimos la dirección
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      // Si es un nuevo campo, ordenamos ascendente por defecto
      setSortField(field);
      setSortDirection('asc');
    }
  };

  // Obtener usuarios ordenados según el campo y dirección actual
  const getSortedUsers = () => {
    return [...users].sort((a, b) => {
      let aValue = a[sortField];
      let bValue = b[sortField];
      
      // Manejar valores especiales para comparación
      if (typeof aValue === 'boolean') {
        aValue = aValue ? 1 : 0;
        bValue = bValue ? 1 : 0;
      } else if (aValue === null || aValue === undefined) {
        aValue = '';
      } else if (bValue === null || bValue === undefined) {
        bValue = '';
      }
      
      // Convertir a string para comparación
      const aString = String(aValue).toLowerCase();
      const bString = String(bValue).toLowerCase();
      
      if (sortDirection === 'asc') {
        return aString.localeCompare(bString);
      } else {
        return bString.localeCompare(aString);
      }
    });
  };

  useEffect(() => {
    const adminStatus = sessionStorage.getItem('isAdmin');
    if (adminStatus === '1') {
      setUserAuthorized(true);
    } else {
      setUserAuthorized(false);
    }
    const userId = sessionStorage.getItem('userId');
    if (userId) {
      setCurrentUserId(parseInt(userId));
    }
    fetchUsers();
  }, []);




  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/users/get_users.php`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        credentials: 'omit'
      });
      
      const contentType = response.headers.get('content-type');
      if (!contentType || !contentType.includes('application/json')) {
        throw new TypeError('La respuesta no es JSON');
      }

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data: User[] = await response.json();
      setUsers(data);
    } catch (error) {
      console.error("Error detallado:", error);
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  // Funciones para manejar usuarios existentes
  const handleDelete = async (id: number) => {
    try {
      if (!userToDelete) return;

      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/users/delete_user.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id }),
      });
      const data = await response.json();
      if (id === currentUserId) {
        toast.error('No puedes eliminar tu propia cuenta');
        return;
      }
      if (data.success) {
        setUsers(users.filter(user => user.id !== id));
        toast.success('Usuario eliminado exitosamente');
      } else {
        setError(data.error || 'Error al eliminar el usuario.');
        toast.error(data.error || 'Error al eliminar el usuario');
      }
      setIsDeleteConfirmOpen(false);
      setUserToDelete(null);
    } catch (error) {
      setError('Ocurrió un error al eliminar el usuario.');
      toast.error('Ocurrió un error al eliminar el usuario');
    }
  };

  const handleEdit = (user: User) => {
    if (user.id === currentUserId) {
      toast.error('No puedes editar tu propia cuenta desde esta sección');
      return;
    }
    setSelectedUser(user);
    setIsEditing(true);
  };

  const handleSave = async () => {
    if (selectedUser) {
      // alert(JSON.stringify(selectedUser))
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/users/update_user.php`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(selectedUser),
        });
        const data = await response.json();

        if (data.success) {
          setUsers(users.map(user => (user.id === selectedUser.id ? selectedUser : user)));
          setIsEditing(false);
          setSelectedUser(null);
          toast.success('Usuario actualizado exitosamente');
        } else {
          setError(data.error || 'Error al actualizar el usuario.');
          toast.error(data.error || 'Error al actualizar el usuario');
        }
      } catch (error) {
        setError('Ocurrió un error al actualizar el usuario.');
        toast.error('Ocurrió un error al actualizar el usuario');
      }
    }
  };

  const handleToggleActive = async (id: number, isActive: boolean) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/users/update_user_status.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id, is_active: !isActive }),
      });
      const data = await response.json();
      if (id === currentUserId) {
        toast.error('No puedes cambiar el estado de tu propia cuenta');
        return;
      }
      if (data.success) {
        setUsers(users.map(user => (user.id === id ? { ...user, is_active: !isActive } : user)));
        toast.success(`Usuario ${!isActive ? 'activado' : 'desactivado'} exitosamente`);
      } else {
        setError(data.error || 'Error al actualizar el estado del usuario.');
        toast.error(data.error || 'Error al actualizar el estado del usuario');
      }
    } catch (error) {
      setError('Ocurrió un error al actualizar el estado del usuario.');
      toast.error('Ocurrió un error al actualizar el estado del usuario');
    }
  };

  const handleToggleAdmin = async (id: number, isAdmin: boolean) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/users/update_user_admin.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id, is_admin: !isAdmin }),
      });
      const data = await response.json();
      if (id === currentUserId) {
        toast.error('No puedes cambiar tus propios privilegios de administrador');
        return;
      }
      if (data.success) {
        setUsers(users.map(user => (user.id === id ? { ...user, is_admin: !isAdmin } : user)));
        toast.success(`Privilegios de administrador ${!isAdmin ? 'otorgados' : 'removidos'} exitosamente`);
      } else {
        setError(data.error || 'Error al actualizar el estado de administrador.');
        toast.error(data.error || 'Error al actualizar el estado de administrador');
      }
    } catch (error) {
      setError('Ocurrió un error al actualizar el estado de administrador.');
      toast.error('Ocurrió un error al actualizar el estado de administrador');
    }
  };

  // Función para invitar usuario
// Función para invitar usuario
const handleInvite = async () => {
  // Validations
  if (!inviteFormValid) {
    toast.error('Por favor complete todos los campos obligatorios');
    return;
  }
  
  setInviteLoading(true);
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/php/login/send_invitation.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        email: inviteEmail, 
        name: inviteName, 
        lastname: inviteLastname,
        is_external: inviteExternal, 
        cargo: inviteCargo,
        departamento: inviteDpto,
        empresa: inviteCompany,
        is_admin: userProfile === 'admin', 
        user_profile: userProfile 
      }),
    });

    const data = await response.json();

    if (data.success) {
      toast.success('Invitación enviada exitosamente');
      resetInviteForm();
      setIsInviting(false);
    } else {
      setError(data.error);
      toast.error(`Error: ${data.error}`);
    }
  } catch (error) {
    setError(`Error en la solicitud. ${error}`);
    toast.error(`Error en la solicitud: ${error}`);
  } finally {
    setInviteLoading(false);
  }
};



const handleCreate = async () => {
  // Validations
  if (!createFormValid) {
    toast.error('Por favor complete todos los campos obligatorios');
    return;
  }
  
  setCreateLoading(true);
  try {
    // Preparar datos para enviar
    const formData = {
      ...newUser,
      is_admin: newUser.user_profile === 'admin'
    };
    
    const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/users/create_user.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    const data = await response.json();

    if (data.success) {
      toast.success('Usuario creado exitosamente');
      fetchUsers(); // Recargar la lista de usuarios
      resetCreateForm();
      setIsCreating(false);
    } else {
      setError(data.error || 'Error al crear el usuario');
      toast.error(data.error || 'Error al crear el usuario');
    }
  } catch (error) {
    setError('Ocurrió un error al crear el usuario');
    toast.error('Ocurrió un error al crear el usuario');
  } finally {
    setCreateLoading(false);
  }
};
  // Funciones de utilidad
  const resetInviteForm = () => {
    setInviteEmail('');
    setInviteCompany('');
    setInviteDpto('');
    setInviteLastname('');
    setInviteName('');
    setInviteExternal(false);
    setUserProfile('');
  };

  const resetCreateForm = () => {
    setNewUser({
      firstname: '',
      lastname: '',
      email: '',
      is_admin: false,
      departamento: '',
      empresa: '',
      is_active: true,
      is_external: false,
      cargo: '',
      user_profile: ''
    });
    setPassword('');
  };
  
  // Actualizar username automáticamente cuando cambia firstname o lastname
  // useEffect(() => {
  //   if (newUser.firstname && newUser.lastname) {
  //     const generatedUsername = generateUsername(newUser.firstname, newUser.lastname);
  //     setNewUser(prev => ({...prev, username: generatedUsername}));
  //   }
  // }, [newUser.firstname, newUser.lastname]);

  const toggleInviteAdmin = () => {

      setUserProfile('');
  };

  if (!userAuthorized) {
    return (
      <div className="p-4">
        <div className="p-3 bg-red-50 border border-red-200 rounded-lg text-red-700 text-base">
          No tienes permiso para ver esta página.
        </div>
      </div>
    );
  }

  if (loading) {
    return <div className="text-center text-lg">Cargando...</div>;
  }

  if (error && !isEditing && !isInviting && !isCreating) {
    return <div className="text-center text-red-600">{error}</div>;
  }

  return (
    <div className="p-6">
      {/* Botones de acción */}
      <div className="flex justify-between items-center mb-4">
  {/* Botones a la izquierda */}
  <div className="flex space-x-3">
    <button
      onClick={() => setIsCreating(true)}
      className="flex p-2 items-center bg-teal-600 hover:bg-teal-700 text-white rounded"
    >
      <PlusCircle className="w-5 h-5 mr-2" />
      Crear Usuario
    </button>

    <button
      onClick={() => setIsInviting(true)}
      className="flex p-2 items-center bg-cyan-500 hover:bg-cyan-600 text-white rounded"
    >
      <UserPlus className="w-5 h-5 mr-2" />
      Invitar Usuario
    </button>
  </div>

  {/* Buscador a la derecha */}
  <div className="relative w-full max-w-xs">
    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
      <svg
        className="w-5 h-5 text-gray-500"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
        />
      </svg>
    </div>
    <input
      type="text"
      placeholder="Buscar usuarios..."
      className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
    />
  </div>
</div>


      {/* Tabla de usuarios */}
      <div className="overflow-x-auto mb-6">
        
        <table className="min-w-full bg-white border border-gray-200 rounded-md shadow-md">
          <thead>
            <tr className="bg-cyan-400 text-left text-white">
              {/* <th className="py-3 px-4 border-b">Usuario</th> */}
              <th className="text-sm py-3 px-4 border-b">Nombre</th>
              <th className="text-sm py-3 px-4 border-b">Apellido</th>
              <th className="text-sm py-3 px-4 border-b">Correo Electrónico</th>
              <th className="text-sm py-3 px-4 border-b">Perfil de usuario</th>
              <th className="text-sm py-3 px-4 border-b">Cargo</th>
              <th className="text-sm py-3 px-4 border-b">Departamento</th>
              <th className="text-sm py-3 px-4 border-b">Empresa</th>
              <th className="text-sm py-3 px-4 border-b">Habilitado</th>
              <th className="text-sm py-3 px-4 border-b">Acciones</th>
            </tr>
          </thead>
          <tbody>
          {getFilteredUsers().map(user => (
              <tr key={user.id} className={user.id === currentUserId ? "bg-cyan-50 hover:bg-cyan-100" : "hover:bg-gray-50"}>                
              {/* <td className="py-2 px-4 border-b">@{user.username}</td> */}
                <td className="py-2 px-4 border-b">{user.firstname}</td>
                <td className="py-2 px-4 border-b">{user.lastname}</td>
                <td className="py-2 px-4 border-b">{user.email}</td>
                <td className="py-2 px-4 border-b">
  <div className="flex items-center space-x-1">
    {user.user_profile === 'admin' && (
      <span title="Administrador">
        <ShieldCheck className="ml-3 w-6 h-6 text-red-600" />
      </span>
    )}
    {user.user_profile === 'standard' && (
      <span title="Usuario Estándar">
        <User className="ml-3 w-6 h-6 text-gray-600" />
      </span>
    )}
    {user.user_profile === 'biblioteca_normativa' && (
      <span title="Biblioteca Normativa">
        <BookOpen className="ml-3 w-6 h-6 text-green-600" />
      </span>
    )}
    {user.user_profile === 'externo' && (
      <span title="Usuario Externo">
        <ExternalLink className="ml-3 w-6 h-6 text-purple-600" />
      </span>
    )}
    <span className="text-gray-700">
    {/* {profileLabels[user.user_profile as string] || user.user_profile} */}
    </span>
  </div>
</td>

                {/* <td className="py-2 px-4 border-b text-center">
  <button
    onClick={() => handleToggleAdmin(user.id, user.is_admin)}
    className={`w-5 h-5 ${user.is_admin ? 'text-green-600' : 'text-gray-400'} ${user.id === currentUserId ? 'cursor-not-allowed opacity-60' : ''}`}
    disabled={user.id === currentUserId}
  >
    {user.is_admin ? <CheckCircle /> : <XCircle />}
  </button>
</td> */}
                <td className="py-2 px-4 border-b">{user.cargo}</td>
                <td className="py-2 px-4 border-b">{user.departamento}</td>
                <td className="py-2 px-4 border-b">{user.empresa}</td>
                <td className="py-2 px-4 border-b text-center">
  <button
    onClick={() => handleToggleActive(user.id, user.is_active)}
    className={`w-5 h-5 ${user.is_active ? 'text-green-600' : 'text-gray-400'} ${user.id === currentUserId ? 'cursor-not-allowed opacity-60' : ''}`}
    disabled={user.id === currentUserId}
  >
    {user.is_active ? <CheckCircle /> : <XCircle />}
  </button>
</td>
<td className="py-4 px-4 border-b flex space-x-2">
  <button
    onClick={() => handleEdit(user)}
    className={`text-blue-600 hover:text-blue-800 ${user.id === currentUserId ? 'cursor-not-allowed opacity-60' : ''}`}
    disabled={user.id === currentUserId}
  >
    <Edit className="w-5" />
  </button>
  <button
                    onClick={() => confirmDeleteUser(user.id)}
                    className={`text-red-600 hover:text-red-800 ${user.id === currentUserId ? 'cursor-not-allowed opacity-60' : ''}`}
                    disabled={user.id === currentUserId}
                  >
                    <Trash className="w-5" />
                  </button>
  {user.id === currentUserId && (
    <span className="text-xs text-gray-500 italic ml-2">Tu cuenta</span>
  )}
</td>
              </tr>
            ))}
            {getFilteredUsers().length === 0 && (
  <tr>
    <td colSpan={10} className="py-4 px-4 text-center text-gray-500">
      No se encontraron usuarios que coincidan con la búsqueda.
    </td>
  </tr>
)}
          </tbody>
        </table>
      </div>
      <ConfirmDialog
  isOpen={isDeleteConfirmOpen}
  onClose={() => setIsDeleteConfirmOpen(false)}
  onConfirm={() => userToDelete && handleDelete(userToDelete)}
  title="Confirmar eliminación"
  message={`¿Estás seguro que deseas eliminar al usuario ${userDetails?.firstname} ${userDetails?.lastname}? Esta acción no puede deshacerse.`}
  confirmButtonText="Eliminar"
  cancelButtonText="Cancelar"
/>
      {/* Modal de Edición de Usuario */}
      {isEditing && selectedUser && (
  <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
    <div className="bg-white p-6 rounded-md shadow-lg max-w-md w-full max-h-[90vh] overflow-y-auto text-xs">
      
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-xl font-semibold">Editar Usuario</h3>
        <div className="flex items-center gap-2">
          <label className="text-gray-700">Activo</label>
          <Switch
            checked={selectedUser.is_active}
            onCheckedChange={(value) =>
              setSelectedUser({ ...selectedUser, is_active: value })
            }
          />
        </div>
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSave();
        }}
      >
              <div className="mb-4">
                <label className="block text-gray-700">Nombre:</label>
                <input
                  type="text"
                  value={selectedUser.firstname}
                  onChange={e => setSelectedUser({ ...selectedUser, firstname: e.target.value })}
                  className="w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Apellido:</label>
                <input
                  type="text"
                  value={selectedUser.lastname}
                  onChange={e => setSelectedUser({ ...selectedUser, lastname: e.target.value })}
                  className="w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Correo Electrónico:</label>
                <input
                  type="email"
                  value={selectedUser.email}
                  onChange={e => setSelectedUser({ ...selectedUser, email: e.target.value })}
                  className="w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              
             
                {/* <input
                  type="checkbox"
                  checked={selectedUser.is_external}
                  onChange={e => setSelectedUser({ ...selectedUser, is_external: e.target.checked })}
                  className="mr-2 ml-5"
                />
                <label className="text-gray-700">Externo</label> */}


  {/* <Switch
    checked={selectedUser.is_admin}
    onCheckedChange={(value) => setSelectedUser({ ...selectedUser, is_admin: value })}
  />
  <label className="text-gray-700">Administrador</label> */}



              <div className="mb-4">
                <label className="block text-gray-700">Cargo:</label>
                <input
                  type="text"
                  value={selectedUser.cargo}
                  onChange={e => setSelectedUser({ ...selectedUser, cargo: e.target.value })}
                  className="w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Departamento:</label>
                <select
                  value={selectedUser.departamento}
                  onChange={e => setSelectedUser({ ...selectedUser, departamento: e.target.value })}
                  className="w-full p-2 border border-gray-300 rounded-md"
                >
                  <option value="" disabled>Seleccionar departamento</option>
                  {departamentos.map(departamento => (
                    <option key={departamento} value={departamento}>{departamento}</option>
                  ))}
                </select>
              </div>
              <div className="mb-4">
              <label className="block text-gray-700">Empresa:</label>
                <input
                  type="text"
                  value={selectedUser.empresa}
                  onChange={e => setSelectedUser({ ...selectedUser, empresa: e.target.value })}
                  className="w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div className="mb-4">
  <label className="block text-gray-700">Perfil de Usuario <span className="text-red-500">*</span></label>
  <select
    value={selectedUser.user_profile || ''}
    onChange={handleEditProfileChange}
    className="w-full p-2 border border-gray-300 rounded-md"
    required
  >
    <option value="">Seleccionar perfil</option>
    <option value="admin">Administrador</option>
    <option value="biblioteca_normativa">Biblioteca Normativa</option>
    <option value="standard">Usuario Estándar</option>
    {selectedUser.is_external && <option value="externo">Usuario Externo</option>}
  </select>
</div>
              <div className="flex justify-end space-x-2">
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                >
                  Guardar
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setIsEditing(false);
                    setSelectedUser(null);
                  }}
                  className="px-4 py-2 bg-gray-400 text-white rounded-md hover:bg-gray-500"
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Modal de Invitar Usuario */}
      {isInviting && (
  <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
    <div className="bg-white p-6 rounded-md shadow-lg max-w-md w-full max-h-[90vh] overflow-y-auto text-xs">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-semibold">Invitar Usuario</h3>
              <button onClick={() => setIsInviting(false)} className="text-gray-500 hover:text-gray-700">
                &times;
              </button>
            </div>
            
            <p className="text-gray-600 mb-4">
              Complete el formulario para enviar una invitación a un nuevo usuario.
            </p>
            
            <form
  onSubmit={(e) => {
    e.preventDefault();
    handleInvite();
  }}
  className="bg-white p-6 rounded-lg shadow space-y-6"
>
  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
  

    <div>
      <label htmlFor="inviteName" className="block text-gray-700 mb-1 font-medium">
        Nombre <span className="text-red-500">*</span>
      </label>
      <Input
        id="inviteName"
        type="text"
        placeholder="Ingrese el nombre"
        value={inviteName}
        onChange={(e) => setInviteName(e.target.value)}
        className="w-full"
        required
      />
    </div>
    <div className="flex items-center justify-between">
      <label htmlFor="inviteExternal" className="text-gray-700 font-medium">
        ¿Es usuario externo? <span className="text-red-500">*</span>
      </label>
      <Switch
        checked={inviteExternal}
        onCheckedChange={setInviteExternal}
        disabled
      />
    </div>
    <div>
      <label htmlFor="inviteLastname" className="block text-gray-700 mb-1 font-medium">
        Apellido <span className="text-red-500">*</span>
      </label>
      <Input
        id="inviteLastname"
        type="text"
        placeholder="Ingrese el apellido"
        value={inviteLastname}
        onChange={(e) => setInviteLastname(e.target.value)}
        className="w-full"
        required
      />
    </div>

    <div>
      <label htmlFor="inviteEmail" className="block text-gray-700 mb-1 font-medium">
        Correo electrónico <span className="text-red-500">*</span>
      </label>
      <Input
        id="inviteEmail"
        type="email"
        placeholder="ejemplo@correo.com"
        value={inviteEmail}
        onChange={(e) => setInviteEmail(e.target.value)}
        className="w-full"
        required
      />
    </div>

   

    <div>
      <label htmlFor="inviteDpto" className="block text-gray-700 mb-1 font-medium">
        Cargo <span className="text-red-500">*</span>
      </label>
      <Input
        id="inviteCargo"
        type="text"
        placeholder="Ingrese el cargo"
        value={inviteCargo}
        onChange={(e) => setInviteCargo(e.target.value)}
        className="w-full"
        required
      />
    </div>

    <div>
      <label htmlFor="inviteDpto" className="block text-gray-700 mb-1 font-medium">
        Departamento <span className="text-red-500">*</span>
      </label>
      <Input
        id="inviteDpto"
        type="text"
        placeholder="Ingrese el departamento"
        value={inviteDpto}
        onChange={(e) => setInviteDpto(e.target.value)}
        className="w-full"
        required
      />
    </div>
    <div>
      <label htmlFor="inviteCompany" className="block text-gray-700 mb-1 font-medium">
        Empresa <span className="text-red-500">*</span>
      </label>
      <Input
        id="inviteCompany"
        type="text"
        placeholder="Ingrese la empresa"
        value={inviteCompany}
        onChange={(e) => setInviteCompany(e.target.value)}
        className="w-full"
        required
      />
    </div>

    <div className="md:col-span-2">
      <label htmlFor="userProfile" className="block text-gray-700 mb-1 font-medium">
        Perfil de Usuario <span className="text-red-500">*</span>
      </label>
      <select
        id="userProfile"
        value={userProfile}
        onChange={(e) => setUserProfile(e.target.value)}
        className="w-full p-2 border border-gray-300 rounded-md"
        required
      >
        <option value="">Seleccionar perfil</option>
        <option value="admin">Administrador</option>
        <option value="biblioteca_normativa">Biblioteca Normativa</option>
        <option value="standard">Usuario Estándar</option>
      </select>
    </div>
  </div>

  <div className="flex justify-end space-x-2 pt-4">
  <button
    type="button"
    
    onClick={() => {
      resetInviteForm();
      setIsInviting(false);
    }}
    className="px-4 py-2 bg-gray-400 text-white rounded-md hover:bg-gray-500 transition"
    disabled={inviteLoading} // 🔒 deshabilitado mientras se envía
  >
    Cancelar
  </button>
  <button
  type="submit"
  disabled={!inviteFormValid || inviteLoading}
  className={`px-4 py-2 rounded-md transition flex items-center 
    ${!inviteFormValid || inviteLoading
      ? 'bg-gray-200 text-gray-400 cursor-not-allowed border border-gray-300'
      : 'bg-cyan-600 hover:bg-cyan-700 text-white'}`}
>
  {inviteLoading ? (
    <>
      <Loader2 className="mr-2 h-5 w-5 animate-spin" />
      Enviando...
    </>
  ) : (
    'Enviar Invitación'
  )}
</button>
</div>

</form>

          </div>
        </div>
      )}

      {/* Modal de Crear Usuario */}
      {isCreating && (
  <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
    <div className="bg-white p-6 rounded-md shadow-lg max-w-md w-full max-h-[90vh] overflow-y-auto text-xs">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-base font-semibold">Crear Usuario Manualmente</h3>
        <button onClick={() => setIsCreating(false)} className="text-gray-500 hover:text-gray-700 text-xl">
          &times;
        </button>
      </div>

      <form onSubmit={(e) => { e.preventDefault(); handleCreate(); }}>
        <div className="space-y-4">
          <div>
            <label className="block text-gray-700 mb-1">Nombre <span className="text-red-500">*</span></label>
            <Input
              type="text"
              placeholder="Nombre"
              value={newUser.firstname}
              onChange={(e) => setNewUser({ ...newUser, firstname: e.target.value })}
              className="w-full"
              required
            />
          </div>

          <div>
            <label className="block text-gray-700 mb-1">Apellido <span className="text-red-500">*</span></label>
            <Input
              type="text"
              placeholder="Apellido"
              value={newUser.lastname}
              onChange={(e) => setNewUser({ ...newUser, lastname: e.target.value })}
              className="w-full"
              required
            />
          </div>

          <div>
            <label className="block text-gray-700 mb-1">Correo Electrónico <span className="text-red-500">*</span></label>
            <Input
              type="email"
              placeholder="Correo electrónico"
              value={newUser.email}
              onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
              className="w-full"
              required
            />
          </div>

          <div>
            <label className="block text-gray-700 mb-1">Contraseña</label>
            <input
              type="password"
              placeholder="Contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="border w-full p-2 rounded-md"
            />
            <p className="text-xs text-gray-500 mt-1">Si se deja en blanco, se generará una contraseña temporal</p>
          </div>

          <div>
            <label className="block text-gray-700 mb-1">Departamento</label>
            <select
              value={newUser.departamento}
              onChange={(e) => setNewUser({ ...newUser, departamento: e.target.value })}
              className="w-full p-2 border border-gray-300 rounded-md"
            >
              <option value="" disabled>Seleccionar departamento</option>
              {departamentos.map(departamento => (
                <option key={departamento} value={departamento}>{departamento}</option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-gray-700 mb-1">Empresa <span className="text-red-500">*</span></label>
            <Input
              type="text"
              placeholder="Empresa"
              value={newUser.empresa}
              onChange={(e) => setNewUser({ ...newUser, empresa: e.target.value })}
              className="w-full"
              required
            />
          </div>

          <div>
            <label className="block text-gray-700 mb-1">Perfil de Usuario <span className="text-red-500">*</span></label>
            <select
              value={newUser.user_profile || ''}
              onChange={(e) => handleProfileChange(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md"
              required
            >
              <option value="">Seleccionar perfil</option>
              <option value="admin">Administrador</option>
              <option value="biblioteca_normativa">Biblioteca Normativa</option>
              <option value="standard">Usuario Estándar</option>
            </select>
          </div>
{/* 
          <div className="flex items-center p-3 bg-gray-50 rounded-lg border border-gray-200">
            <input
              type="checkbox"
              id="new-is-external"
              checked={newUser.is_external}
              onChange={(e) => setNewUser({ ...newUser, is_external: e.target.checked })}
              className="mr-2"
              
            />
            <label htmlFor="new-is-external" className="text-gray-700">Usuario Externo</label>
          </div> */}

          <div className="flex justify-end space-x-2 pt-2">
            <button
              type="button"
              
              onClick={() => {
                resetCreateForm();
                setIsCreating(false);
              }}
              className="px-4 py-2 bg-gray-400 text-white rounded-md hover:bg-gray-500"
            >
              Cancelar
            </button>
            <button
  type="submit"
  className={`px-4 py-2 rounded-md flex items-center transition 
    ${!createFormValid || createLoading
      ? 'bg-gray-200 text-gray-400 cursor-not-allowed border border-gray-300'
      : 'bg-green-600 hover:bg-green-700 text-white'}`}
  disabled={!createFormValid || createLoading}
>
  {createLoading ? (
    <>
      <Loader2 className="mr-2 h-5 w-5 animate-spin" />
      Creando...
    </>
  ) : (
    'Crear Usuario'
  )}
</button>

          </div>
        </div>
      </form>
    </div>
  </div>
)}

    </div>
  );
};

export default UserManagement;