import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/Login.css';
import '../css/LogoAnimation.css';
import LogoAnimation from './LogoAnimation';
import RequestPasswordReset from './RequestPasswordReset';

const Login: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [showResetPopup, setShowResetPopup] = useState<boolean>(false);
  const [showAnimation, setShowAnimation] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    if (!username || !password) {
      setError('Por favor, completa todos los campos.');
      setLoading(false);
      return;
    }

    try {
     // console.log('Iniciando login...');
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/login/login.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password })
      });

      const text = await response.text();
    //  console.log('Respuesta del servidor:', text);
      
      let data;
      try {
        data = JSON.parse(text);
      } catch (e) {
        throw new Error('La respuesta del servidor no es un JSON válido.');
      }

      if (data.success) {
        //console.log('Login exitoso, token recibido:', !!data.token);
        setSuccess(data.message || 'Inicio de sesión exitoso.');
        
        // Almacenar el token JWT
        if (data.token) {
          localStorage.setItem('authToken', data.token);
          //console.log('Token almacenado en localStorage');
        } else {
         // console.error('No se recibió token del servidor');
        }

        // Almacenar datos del usuario
        sessionStorage.setItem('userId', data.id);
        sessionStorage.setItem('firstName', data.firstname);
        sessionStorage.setItem('lastName', data.lastname);
        sessionStorage.setItem('email', data.email);
        sessionStorage.setItem('title', data.title);
        sessionStorage.setItem('cargo', data.cargo);
        sessionStorage.setItem('isAdmin', data.is_admin.toString());
        sessionStorage.setItem('gender', data.gender);
        sessionStorage.setItem('userProfile', data.user_profile);
        
        setShowAnimation(true);
        
        setTimeout(() => {
          navigate('/ui');
        }, 3000);
      } else {
        console.error('Error en login:', data.error);
        setError(data.error || 'Error al iniciar sesión.');
      }
    } catch (error) {
      console.error('Error en la petición:', error);
      setError(`Ocurrió un error durante el inicio de sesión. ${error}`);
    } finally {
      setLoading(false);
    }
  };

  const handleResetPasswordClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowResetPopup(true);
  };

  const handleClosePopup = () => {
    setShowResetPopup(false);
  };

  return (
    <div className="relative">
      <LogoAnimation show={showAnimation} type="login" />
      <div className="login-container">
        <div className="login-box">
          <div className="login-logo"></div>
          <form onSubmit={handleLogin}>
            <div className="input-container">
              <i className="input-icon fas fa-user"></i>
              <input
                type="text"
                placeholder="Usuario"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="login-input"
              />
            </div>
            <div className="input-container">
              <i className="input-icon fas fa-lock"></i>
              <input
                type="password"
                placeholder="Contraseña"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="login-input"
              />
            </div>
            <button
              type="submit"
              className="login-button"
              disabled={loading}
            >
              {loading ? <div className="spinner"></div> : 'INICIAR SESIÓN'}
            </button>
            {success && <p className="success-message">{success}</p>}
            {error && <p className="error-message">{error}</p>}
          </form>
          <a 
            href="#!" 
            className="forgot-password-link mb-3" 
            onClick={handleResetPasswordClick}
          >
            ¿Olvidaste tu contraseña?
          </a>
        </div>
        <div className="triangle"></div>
      </div>

      {showResetPopup && (
        <div className="absolute inset-0 flex items-center justify-center">
          <div 
            className="absolute inset-0 bg-black opacity-50 z-[10]" 
            onClick={handleClosePopup}
          ></div>
          <div className="z-10">
            <RequestPasswordReset onClose={handleClosePopup} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;