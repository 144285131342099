import React, { useState, useEffect } from 'react';
import { 
  LineChart, 
  Line, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend, 
  ResponsiveContainer,
  BarChart,
  Bar,
  Cell
} from 'recharts';

// Define interfaces for type safety
interface NivelItem {
  nivel: string;
  fecha: string;
  codigo: string;
  cantidad: number;
}

interface DataItem {
  concepto: string;
  nomenclatura: string;
  cantidad: number;
  color?: string;
}

interface SerieData {
  codigo: string;
  nombre: string;
  values: number[];
}

interface ChartDataPoint {
  fecha: string;
  [key: string]: string | number;
}

interface Document {
  id: string;
  project: string;
  projectName: string;
  projectCode: string;
  document: string;
  title: string;
  correlativo?: string;
  revision: string;
  version: number;
  date: string;
  issuedBy: string;
  comment: string;
  path: string;
  flagged: boolean;
  itemType?: 'document' | 'transmittal';
  subVersions?: Document[];
}

const ControlDocumental: React.FC = () => {
  // State variables
  const [datos, setDatos] = useState<DataItem[]>([]);
  const [niveles, setNiveles] = useState<NivelItem[]>([]);
  const [showAcumulado, setShowAcumulado] = useState<boolean>(true);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [documentCounts, setDocumentCounts] = useState<{[key: string]: number}>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [dataReady, setDataReady] = useState<boolean>(false);
  
  // Dinámicamente creados en base a datos de la API
  const [codigoToNombre, setCodigoToNombre] = useState<{[key: string]: string}>({});
  const [colors, setColors] = useState<{[key: string]: string}>({});

  // Fechas, datos parciales y acumulados
  const [fechas, setFechas] = useState<string[]>([]);
  const [datosParciales, setDatosParciales] = useState<SerieData[]>([]);
  const [datosAcumulados, setDatosAcumulados] = useState<SerieData[]>([]);
  const [lineChartData, setLineChartData] = useState<ChartDataPoint[]>([]);

  // Construir URLs para las API
  const apiBase = `${process.env.REACT_APP_API_URL || ''}/php/pages/controldocument/glosario`;

  // Fetch data from API and documents on component mount
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Primero obtenemos los datos para cargar los colores y códigos
        await loadDatabaseMetadata();
        
        // Luego cargamos los documentos y transmittals
        await fetchDocuments();
        
        setDataReady(true);
      } catch (error) {
        console.error('Error al cargar datos:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  // Load database metadata (nomenclature, names, colors)
  const loadDatabaseMetadata = async () => {
    try {
      const response = await fetch(`${apiBase}/get_levels_data.php?type=type2`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      const data = await response.json();
      
      if (data.error) {
        console.error('Error en la respuesta API:', data.error);
        return;
      }
      
      // Mapeo de código a nombre y colores
      const newCodigoToNombre: {[key: string]: string} = {};
      const newColors: {[key: string]: string} = {};
      
      data.forEach((item: DataItem) => {
        if (item.nomenclatura) {
          // Mapeo de código a nombre
          newCodigoToNombre[item.nomenclatura] = item.concepto;
          
          // Mapeo de código a color (si existe)
          if (item.color) {
            newColors[item.nomenclatura] = item.color;
          } else {
            // Color gris predeterminado si no hay color
            newColors[item.nomenclatura] = '#CCCCCC';
          }
        }
      });
      
      // Guardar datos iniciales
      setDatos(data as DataItem[]);
      setCodigoToNombre(newCodigoToNombre);
      setColors(newColors);
      
      console.log('Colores cargados:', newColors);
      console.log('Códigos a nombres:', newCodigoToNombre);
      
      return data;
    } catch (error) {
      console.error('Error al cargar metadatos:', error);
      return [];
    }
  };

  // Fetch both documents and transmittals
  const fetchDocuments = async () => {
    try {
      let allDocuments: Document[] = [];
      
      // 1. Fetch regular documents
      try {
        const docResponse = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/controldocument/busquedarapida/get_docs.php`);
        const docData = await docResponse.json();
        
        if (Array.isArray(docData) && docData.length > 0) {
          const docs = docData.map(doc => ({
            ...doc,
            itemType: 'document'
          }));
          allDocuments = [...allDocuments, ...docs];
        }
      } catch (docError) {
        console.error('Error fetching documents:', docError);
      }
      
      // 2. Fetch transmittals
      try {
        const transmResponse = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/transmittal/get_transmittals.php?type=type2`);
        const transmData = await transmResponse.json();
        
        if (Array.isArray(transmData) && transmData.length > 0) {
          const transmittals = transmData.map(transmittal => {
            // Format date if needed
            let formattedDate = transmittal.date;
            if (transmittal.date && transmittal.date.includes('-')) {
              const parts = transmittal.date.split(' ');
              if (parts.length === 2) {
                const dateParts = parts[0].split('-');
                if (dateParts.length === 3) {
                  // Only convert if in DD-MM-YYYY format
                  if (dateParts[0].length <= 2) {
                    formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]} ${parts[1]}`;
                  }
                }
              }
            }
            
            // Extract code from transmittal document code
            let projectCode = '';
            if (transmittal.document) {
              projectCode = transmittal.document.split('-')[0];
            } else if (transmittal.codigo_transmittal) {
              projectCode = transmittal.codigo_transmittal.split('-')[0];
            } else {
              projectCode = transmittal.projectCode || transmittal.codigo_proyecto || '';
            }
            
            // Get projectName
            const projectName = transmittal.nombre_proyecto || transmittal.projectName || ``;
            
            // Cast as Document to ensure type safety
            const docTransmittal: Document = {
              id: transmittal.id || `transmittal-${Math.random().toString(36).substr(2, 9)}`,
              date: formattedDate,
              document: transmittal.document || transmittal.codigo_transmittal || '',
              projectName: projectName,
              project: projectName,
              projectCode: projectCode,
              path: transmittal.path || transmittal.ruta || '',
              revision: 'A',
              version: 1,
              title: transmittal.title || transmittal.asunto || '',
              subVersions: [],
              flagged: false,
              issuedBy: transmittal.issuedBy || transmittal.emisor || '',
              itemType: 'transmittal', // Explicitly typed as 'transmittal'
              comment: transmittal.comment || transmittal.comentarios || '',
            };
            
            return docTransmittal;
          });
          
          allDocuments = [...allDocuments, ...transmittals];
        }
      } catch (transmError) {
        console.error('Error fetching transmittals:', transmError);
      }
      
      // Update documents state
      setDocuments(allDocuments);
      
      // Now count documents by category and update counts
      updateDocumentCounts(allDocuments);
      
      // Generate niveles data from documents if needed
      generateNivelesFromDocuments(allDocuments);
      
      return allDocuments;
    } catch (error) {
      console.error('Error in fetchDocuments:', error);
      return [];
    }
  };
  
  // Update document counts based on fetched documents
  const updateDocumentCounts = (docs: Document[]) => {
    if (!docs.length) return;
    
    const counts: {[key: string]: number} = {};
    
    // Initialize counts for all categories from datos
    datos.forEach(item => {
      counts[item.nomenclatura] = 0;
    });
    
    // Count documents by category
    docs.forEach(doc => {
      if (!doc.document) return;
      
      // Extract category code (first character after hyphen)
      const parts = doc.document.split('-');
      if (parts.length > 1) {
        const categoryCode = parts[1].charAt(0);
        counts[categoryCode] = (counts[categoryCode] || 0) + 1;
      }
    });
    
    // Update document counts state
    setDocumentCounts(counts);
    console.log('Document counts updated:', counts);
  };
  
  // Generate niveles data from documents for timeline
  const generateNivelesFromDocuments = (docs: Document[]) => {
    if (!docs.length) return;
    
    // Group documents by month and category
    const monthlyData: {[key: string]: {[category: string]: number}} = {};
    
    docs.forEach(doc => {
      if (!doc.date || !doc.document) return;
      
      // Extract month in YYYY-MM format
      const dateObj = new Date(doc.date);
      const month = `${dateObj.getFullYear()}-${String(dateObj.getMonth() + 1).padStart(2, '0')}`;
      
      // Extract category
      const parts = doc.document.split('-');
      if (parts.length <= 1) return;
      
      const category = parts[1].charAt(0);
      
      // Initialize month if not exists
      if (!monthlyData[month]) {
        monthlyData[month] = {};
      }
      
      // Initialize category if not exists
      if (!monthlyData[month][category]) {
        monthlyData[month][category] = 0;
      }
      
      // Increment count
      monthlyData[month][category]++;
    });
    
    // Convert to NivelItem format
    const newNiveles: NivelItem[] = [];
    
    Object.entries(monthlyData).forEach(([month, categories]) => {
      const fecha = `${month}-01`; // First day of month
      
      Object.entries(categories).forEach(([category, count]) => {
        newNiveles.push({
          nivel: '1',
          fecha,
          codigo: category,
          cantidad: count
        });
      });
    });
    
    // Sort by date
    newNiveles.sort((a, b) => a.fecha.localeCompare(b.fecha));
    
    setNiveles(newNiveles);
  };

  // Process niveles data for charts when niveles or codigoToNombre changes
  useEffect(() => {
    if (niveles.length > 0 && Object.keys(codigoToNombre).length > 0) {
      // Extraer fechas únicas de los niveles
      const fechasSet = new Set<string>();
      niveles.forEach(item => {
        if (item.fecha) {
          fechasSet.add(item.fecha);
        }
      });
      
      const fechasUnicas = Array.from(fechasSet).sort();
      setFechas(fechasUnicas);

      // Procesar los datos para formato parcial y acumulado
      const codigosDocs = Object.keys(codigoToNombre);
      
      const parciales = codigosDocs.map(codigo => {
        const nombre = codigoToNombre[codigo] || "";
        const values = fechasUnicas.map(fecha => {
          // Filtrar niveles por fecha y código, y sumar cantidades
          const itemsEnFecha = niveles.filter(
            item => item.fecha === fecha && item.codigo === codigo
          );
          return itemsEnFecha.reduce((sum, item) => sum + (item.cantidad || 0), 0);
        });
        return { codigo, nombre, values };
      });
      setDatosParciales(parciales);
      
      // Calcular datos acumulados
      const acumulados = codigosDocs.map(codigo => {
        const nombre = codigoToNombre[codigo] || "";
        let acumulado = 0;
        const values = fechasUnicas.map((fecha, index) => {
          // Si tenemos datos parciales, usamos esos
          if (parciales.length > 0) {
            const codigoData = parciales.find(item => item.codigo === codigo);
            if (codigoData) {
              acumulado += codigoData.values[index] || 0;
            }
          }
          return acumulado;
        });
        return { codigo, nombre, values };
      });
      setDatosAcumulados(acumulados);

      // Preparar datos para el gráfico de líneas
      const chartData = fechasUnicas.map((fecha, index) => {
        const dataPoint: ChartDataPoint = { fecha };
        acumulados.forEach(serie => {
          dataPoint[serie.codigo] = serie.values[index];
        });
        return dataPoint;
      });
      setLineChartData(chartData);
    }
  }, [niveles, codigoToNombre]);

  // Function to get color for a code
  const getColorForCode = (codigo: string) => {
    // Si hay un color en la DB, usarlo
    if (colors[codigo]) {
      return colors[codigo];
    }
    // Si no, usar gris
    return "#CCCCCC";
  };
  
  // Get color for an item in the chart
  const getColorForItem = (item: any) => {
    const count = documentCounts[item.codigo] !== undefined 
      ? documentCounts[item.codigo] 
      : item.cantidad;
      
    // Si cantidad es cero, mostrar gris
    if (count === 0) {
      return '#CCCCCC';
    }
    
    // Si no, usar color guardado
    return getColorForCode(item.codigo);
  };
  
  // Prepare data for UI
  const resumenActual = datos.length > 0 
    ? datos.map(item => ({
        nombre: item.concepto,
        codigo: item.nomenclatura,
        cantidad: documentCounts[item.nomenclatura] !== undefined 
          ? documentCounts[item.nomenclatura] 
          : item.cantidad,
        color: item.color
      }))
    : [];

  const barData = resumenActual;
  
  // Loading state
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
        <p className="ml-2">Cargando datos...</p>
      </div>
    );
  }
  
  // Calculate max value for Y axes
  const calculateMaxValue = () => {
    // Para datos acumulados o parciales, según cuál se esté mostrando
    const datos = showAcumulado ? datosAcumulados : datosParciales;
    if (datos.length === 0) return 10; // Valor por defecto si no hay datos
    
    // Encontrar el valor máximo en todos los arrays de values
    return Math.max(...datos.flatMap(serie => serie.values)) * 1.1; // 10% más para espacio visual
  };

  return (
    <div className="flex flex-col bg-white">
      <div className="p-3 max-w-7xl h-full mx-auto w-full">
        {/* Fila 1: Resumen Control Documental y Documentos Actuales lado a lado */}
        <div className="grid grid-cols-2 gap-4">
          {/* Panel izquierdo: Resumen Control Documental */}
          <div className="bg-white border border-gray-200 rounded shadow">
            <div className="bg-cyan-500 text-white px-4 py-2.5 rounded-t">
              <h2 className="text-base font-medium">Resumen Control Documental</h2>
            </div>
            <div className="bg-gray-50 overflow-y-auto">
              <table className="w-full bg-gray-50">
                <thead className="sticky top-0 bg-gray-50 z-10">
                  <tr className=''>
                    <th className="text-left py-2 px-4 text-gray-600 font-medium">NOMBRE</th>
                    <th className="text-left py-2 px-4 text-gray-600 font-medium">CÓDIGO</th>
                    <th className="text-left py-2 px-4 text-gray-600 font-medium">CANTIDAD</th>
                  </tr>
                </thead>
                <tbody>
                  {resumenActual.map((item, idx) => (
                    <tr key={item.codigo} className="border-t border-gray-200">
                      <td className="py-2 px-4 flex items-center">
                        <div 
                          className="w-3 h-3 rounded-full mr-2" 
                          style={{ backgroundColor: getColorForItem(item) }}
                        ></div>
                        {item.nombre}
                      </td>
                      <td className="py-2 px-4">{item.codigo}</td>
                      <td className="py-2 px-4">
                        <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium ${
                          documentCounts[item.codigo] === 0 ? 'bg-gray-100 text-gray-600' : 'bg-green-100 text-green-800'
                        }`}>
                          {documentCounts[item.codigo] !== undefined 
                            ? documentCounts[item.codigo] 
                            : item.cantidad}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* Panel derecho: Documentos Actuales */}
          <div className="bg-white border border-gray-200 rounded shadow">
            <div className="bg-cyan-500 text-white px-4 py-2.5 rounded-t">
              <h2 className="text-base font-medium">Documentos Actuales</h2>
            </div>
            <div className="p-4 pb-1">
              <ResponsiveContainer width="100%" height={580}>
                <BarChart
                  data={barData.map(item => ({
                    ...item,
                    cantidad: documentCounts[item.codigo] !== undefined 
                      ? documentCounts[item.codigo] 
                      : item.cantidad
                  }))}
                  margin={{ top: 20, right: 30, left: 0, bottom: 30 }}
                >
                  <CartesianGrid strokeDasharray="3 3" vertical={false} />
                  <XAxis dataKey="codigo" tickLine={false} />
                  <YAxis domain={[0, 'auto']} axisLine={false} tickLine={false} />
                  <Tooltip
                    cursor={{fill: 'rgba(0,0,0,0.05)'}}
                    content={({ active, payload }) => {
                      if (active && payload && payload.length) {
                        return (
                          <div className="bg-white p-2 border border-gray-200 shadow-sm">
                            <p className="text-gray-900 font-bold">{payload[0].payload.codigo}</p>
                            <p className="text-gray-700">{payload[0].payload.nombre}: {payload[0].value}</p>
                          </div>
                        );
                      }
                      return null;
                    }}
                  />
                  <Bar dataKey="cantidad">
                    {barData.map((entry, index) => (
                      <Cell 
                        key={`cell-${index}`} 
                        fill={getColorForItem(entry)} 
                      />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
              <div className="text-center text-xs font-medium text-cyan-900">Cantidad de documentos</div>
            </div>
          </div>
        </div>

        {/* Fila 2: Acumulado Documentos (uncomment if needed) */}
        {/* <div className="mt-8">
          <div className="bg-white border border-gray-200 rounded shadow">
            <div className="bg-cyan-500 text-white px-4 py-2.5 rounded-t flex justify-between">
              <h2 className="text-base font-medium">
                {showAcumulado ? "Acumulado Documentos" : "Documentos Parciales"}
              </h2>
              <span 
                className="text-xs bg-gray-700 px-2 py-1 rounded cursor-pointer"
                onClick={() => setShowAcumulado(!showAcumulado)}
              >
                {showAcumulado ? "Vista Acumulativa" : "Vista Parcial"}
              </span>
            </div>
            <div className="p-4">
              <ResponsiveContainer width="100%" height={220}>
                <LineChart
                  data={lineChartData}
                  margin={{ top: 10, right: 30, left: 10, bottom: 25 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis 
                    dataKey="fecha" 
                    angle={-45} 
                    textAnchor="end"
                    height={50}
                    tick={{ fontSize: 10 }}
                    tickMargin={10}
                  />
                  <YAxis domain={[0, calculateMaxValue()]} />
                  <Tooltip />
                  <Legend 
                    layout="horizontal" 
                    verticalAlign="top" 
                    align="center"
                    iconType="circle"
                    iconSize={8}
                    wrapperStyle={{ paddingBottom: '10px' }}
                  />
                  {(showAcumulado ? datosAcumulados : datosParciales)
                    .map(serie => (
                      <Line
                        key={serie.codigo}
                        type="monotone"
                        dataKey={serie.codigo}
                        name={`${serie.codigo} - ${serie.nombre}`}
                        stroke={getColorForCode(serie.codigo)}
                        strokeWidth={2}
                        dot={{ r: 3 }}
                        activeDot={{ r: 6 }}
                      />
                    ))}
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default ControlDocumental;

// import React, { useState, useEffect } from 'react';
// import { 
//   LineChart, 
//   Line, 
//   XAxis, 
//   YAxis, 
//   CartesianGrid, 
//   Tooltip, 
//   Legend, 
//   ResponsiveContainer,
//   BarChart,
//   Bar,
//   Cell
// } from 'recharts';

// // Define interfaces for type safety
// interface NivelItem {
//   nivel: string;
//   fecha: string;
//   codigo: string;
//   cantidad: number;
// }

// interface DataItem {
//   concepto: string;
//   nomenclatura: string;
//   cantidad: number;
// }

// interface SerieData {
//   codigo: string;
//   nombre: string;
//   values: number[];
// }

// interface ChartDataPoint {
//   fecha: string;
//   // This index signature allows for any string key to be added with number values
//   [key: string]: string | number;
// }


// interface Document {
//   id: string;
//   project: string;
//   projectName: string;
//   projectCode: string;
//   document: string;
//   title: string;
//   correlativo: string;
//   revision: string;
//   version: number;
//   date: string;
//   issuedBy: string;
//   comment: string;
//   path: string;
//   flagged: boolean;
//   subVersions?: Document[];
// }



// const ControlDocumental: React.FC = () => {
//   // State variables
//   const [datos, setDatos] = useState<DataItem[]>([]);
//   const [niveles, setNiveles] = useState<NivelItem[]>([]);
//   const [showAcumulado, setShowAcumulado] = useState<boolean>(true);
//   const [documents, setDocuments] = useState<Document[]>([]);

//   // Mapeo de códigos a nombres completos
//   const codigoToNombre: {[key: string]: string} = {
//     'T': 'Terreno',
//     'A': 'Arquitectura',
//     'E': 'Estructuras',
//     'U': 'Urbanización',
//     'D': 'Ingreso DOM',
//   };

//   // Define colors for different document types
//   const colors: {[key: string]: string} = {
//     'A': '#E08734',
//     'E': '#5B5B5B',
//     'U': '#EDC240',
//     'T': '#3174AD',
//     'D': '#5DA6CA'
//   };

//   // Fechas, datos parciales y acumulados se obtendrán de la API
//   const [fechas, setFechas] = useState<string[]>([]);
//   const [datosParciales, setDatosParciales] = useState<SerieData[]>([]);
//   const [datosAcumulados, setDatosAcumulados] = useState<SerieData[]>([]);
//   const [lineChartData, setLineChartData] = useState<ChartDataPoint[]>([]);

//   // Procesar los datos de la API para generar las fechas y datos
//   useEffect(() => {
//     if (niveles.length > 0) {
//       // Extraer fechas únicas de los niveles
//       const fechasSet = new Set<string>();
//       niveles.forEach(item => {
//         if (item.fecha) {
//           fechasSet.add(item.fecha);
//         }
//       });
      
//       const fechasUnicas = Array.from(fechasSet).sort();
//       setFechas(fechasUnicas);

//       // Procesar los datos para formato parcial y acumulado
//       const codigosDocs = ['T', 'A', 'E', 'U', 'D'];
      
//       const parciales = codigosDocs.map(codigo => {
//         const nombre = codigoToNombre[codigo] || "";
//         const values = fechasUnicas.map(fecha => {
//           // Filtrar niveles por fecha y código, y sumar cantidades
//           const itemsEnFecha = niveles.filter(
//             item => item.fecha === fecha && item.codigo === codigo
//           );
//           return itemsEnFecha.reduce((sum, item) => sum + (item.cantidad || 0), 0);
//         });
//         return { codigo, nombre, values };
//       });
//       setDatosParciales(parciales);
      
//       // Calcular datos acumulados
//       const acumulados = codigosDocs.map(codigo => {
//         const nombre = codigoToNombre[codigo] || "";
//         let acumulado = 0;
//         const values = fechasUnicas.map((fecha, index) => {
//           // Si tenemos datos parciales, usamos esos
//           if (parciales.length > 0) {
//             const codigoData = parciales.find(item => item.codigo === codigo);
//             if (codigoData) {
//               acumulado += codigoData.values[index] || 0;
//             }
//           }
//           return acumulado;
//         });
//         return { codigo, nombre, values };
//       });
//       setDatosAcumulados(acumulados);

//       // Preparar datos para el gráfico de líneas
//       const chartData = fechasUnicas.map((fecha, index) => {
//         const dataPoint: ChartDataPoint = { fecha };
//         acumulados.forEach(serie => {
//           // Using proper index signature notation
//           dataPoint[serie.codigo] = serie.values[index];
//         });
//         return dataPoint;
//       });
//       setLineChartData(chartData);
//     }
//   }, [niveles, codigoToNombre]);

//   // Construir URLs para las API
//   const apiBase = `${process.env.REACT_APP_API_URL || ''}/php/pages/controldocument/glosario`;

//   // Fetch data from API
//   useEffect(() => {
//     const fetchNiveles = async () => {
//       try {
//         const response = await fetch(`${apiBase}/get_levels.php`);
//         if (!response.ok) {
//           throw new Error(`HTTP error! Status: ${response.status}`);
//         }
//         const data = await response.json();
//          // Verificar si hay un error en la respuesta
//         if (data.error) {
//           console.error('Error en la respuesta API:', data.error);
//           setNiveles([]);
//           return;
//         }
//         setNiveles(data as NivelItem[]);
//       } catch (error) {
//         console.error('Error fetching niveles:', error);
//         setNiveles([]);
//       }
//     };

//     const fetchData = async () => {
//       try {
//         const response = await fetch(`${apiBase}/get_levels_data.php?type=type2`);
//         if (!response.ok) {
//           throw new Error(`HTTP error! Status: ${response.status}`);
//         }
//         const data = await response.json();
        
//         // alert(JSON.stringify(data))
//         // Verificar si hay un error en la respuesta
//         if (data.error) {
//           console.error('Error en la respuesta API:', data.error);
//           setDatos([]);
//           return;
//         }
        
//         setDatos(data as DataItem[]);
//       } catch (error) {
//         console.error('Error fetching data:', error);
//         setDatos([]);
//       }
//     };

//     fetchNiveles();
//     fetchData();
//   }, [apiBase]);


  
// useEffect(() => {
//   fetchDocuments();
// }, []);

// // const fetchDocuments = async () => {
// //   try {
// //     const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/controldocument/busquedarapida/get_docs.php`);
// //     const data: Document[] = await response.json();

// //     console.log(data);
// //     // Validar estructura de datos
// //     if (Array.isArray(data) && data.every(doc => doc.id && doc.project && doc.document && doc.date)) {
    
// //       setDocuments(data);
// //       console.log(JSON.stringify(data));
// //     } else {
// //       console.error('Estructura de datos inválida:', data);
// //     }
// //   } catch (error) {
// //     console.error('Error al obtener documentos:', error);
// //   }
// // };


//   // Use fetched data from API
  
//   const fetchDocuments = async () => {
//     try { 
//       // First fetch regular documents
//       const docResponse = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/controldocument/busquedarapida/get_docs.php`);
//       const docData = await docResponse.json();
      
//       let allDocuments = [];
     
//       if (Array.isArray(docData) && docData.length > 0) {
//         // Add documents with type "document"
//         allDocuments = docData.map(doc => ({
//           ...doc,
//           itemType: 'document'
//         }));
        
    
//       }
      
//       // Now fetch transmittals
//       try {
//         const transmResponse = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/transmittal/get_transmittals.php?type=type2`);
//         if (transmResponse.ok) {
//           const transmData = await transmResponse.json();
          
//           if (Array.isArray(transmData) && transmData.length > 0) {
//             // Process transmittals and match with project names from the map
//             const transmittals = transmData.map(transmittal => {
//               // Format date if needed
//               let formattedDate = transmittal.date;
//               if (transmittal.date && transmittal.date.includes('-')) {
//                 const parts = transmittal.date.split(' ');
//                 if (parts.length === 2) {
//                   const dateParts = parts[0].split('-');
//                   if (dateParts.length === 3) {
//                     // Only convert if in DD-MM-YYYY format
//                     if (dateParts[0].length <= 2) {
//                       formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]} ${parts[1]}`;
//                     }
//                   }
//                 }
//               }
              
//               // Extract code from transmittal document code
//               let projectCode = '';
//               if (transmittal.document) {
//                 projectCode = transmittal.document.split('-')[0];
//               } else if (transmittal.codigo_transmittal) {
//                 projectCode = transmittal.codigo_transmittal.split('-')[0];
//               } else {
//                 projectCode = transmittal.projectCode || transmittal.codigo_proyecto || '';
//               }
              
//               // Get projectName from our mapping
//               let projectName = '';
           
//                 projectName = transmittal.nombre_proyecto || transmittal.projectName || ``;
            
              
//               console.log(`Transmittal ${transmittal.id || 'unknown'} - Project Code: ${projectCode}, Found Project Name: ${projectName}`);
              
//               return {
//                 id: transmittal.id || `transmittal-${Math.random().toString(36).substr(2, 9)}`,
//                 date: formattedDate,
//                 document: transmittal.document || transmittal.codigo_transmittal || '',
//                 projectName: projectName,
//                 project: projectName, // Use project name for project field
//                 projectCode: projectCode,
//                 path: transmittal.path || transmittal.ruta || '',
//                 revision: 'A',
//                 version: 1,
//                 title: transmittal.title || transmittal.asunto || '',
//                 subVersions: [],
//                 flagged: false,
//                 issuedBy: transmittal.issuedBy || transmittal.emisor || '',
//                 itemType: 'transmittal',
//                 comment: transmittal.comment || transmittal.comentarios || '',
//               };
//             });
            
//             // Merge both arrays
//             allDocuments = [...allDocuments, ...transmittals];
//           }
//         }
//       } catch (error) {
//         console.error('Error fetching transmittals:', error);
//       }
      
//       // Save everything to documents state
//       setDocuments(allDocuments);
//       console.log('All documents:', allDocuments);
//     } catch (error) {
//       console.error('Error fetching documents:', error);
//     }
//   };
  
//   const resumenActual = datos.length > 0 
//     ? datos.map(item => ({
//         nombre: item.concepto,
//         codigo: item.nomenclatura,
//         cantidad: item.cantidad
//       }))
//     : [];

//     const barData = resumenActual;
      
//   // Calcular el valor máximo para los ejes Y
//   const calculateMaxValue = () => {
//     // Para datos acumulados o parciales, según cuál se esté mostrando
//     const datos = showAcumulado ? datosAcumulados : datosParciales;
//     if (datos.length === 0) return 10; // Valor por defecto si no hay datos
    
//     // Encontrar el valor máximo en todos los arrays de values
//     return Math.max(...datos.flatMap(serie => serie.values)) * 1.1; // 10% más para espacio visual
//   };

//   return (
//     <div className="flex flex-col bg-white">
//       <div className="p-3 max-w-7xl h-full mx-auto w-full">
//         {/* Fila 1: Resumen Control Documental y Documentos Actuales lado a lado */}
//         <div className="grid grid-cols-2 gap-4">
//           {/* Panel izquierdo: Resumen Control Documental */}
//           <div className="bg-white border border-gray-200 rounded shadow">
//             <div className="bg-cyan-500 text-white px-4 py-2.5 rounded-t">
//               <h2 className="text-base font-medium">Resumen Control Documental</h2>
//             </div>
//             <div className="bg-gray-50   overflow-y-auto">
//               <table className="w-full  bg-gray-50">
//                 <thead className="sticky top-0 bg-gray-50 z-10">
//                   <tr className=''>
//                     <th className="text-left py-2 px-4 text-gray-600 font-medium">NOMBRE</th>
//                     <th className="text-left py-2 px-4 text-gray-600 font-medium">CÓDIGO</th>
//                     <th className="text-left py-2 px-4 text-gray-600 font-medium">CANTIDAD</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {resumenActual.map((item, idx) => (
//                     <tr key={item.codigo} className="border-t border-gray-200">
//                       <td className="py-2 px-4">{item.nombre}</td>
//                       <td className="py-2 px-4">{item.codigo}</td>
//                       <td className="py-2 px-4">
//                         <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">
//                           {item.cantidad}
//                         </span>
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             </div>
//           </div>

//           {/* Panel derecho: Documentos Actuales */}
//           <div className="bg-white border border-gray-200 rounded shadow">
//             <div className="bg-cyan-500 text-white px-4 py-2.5 rounded-t">
//               <h2 className="text-base font-medium">Documentos Actuales</h2>
//             </div>
//             <div className="p-4 pb-1">
//               <ResponsiveContainer width="100%" height={580}>
//                 <BarChart
//                   data={barData}
//                   margin={{ top: 20, right: 30, left: 0, bottom: 30 }}
//                 >
//                   <CartesianGrid strokeDasharray="3 3" vertical={false} />
//                   <XAxis dataKey="codigo" tickLine={false} />
//                   <YAxis domain={[0, 'auto']} axisLine={false} tickLine={false} />
//                   <Tooltip
//                     cursor={{fill: 'rgba(0,0,0,0.05)'}}
//                     content={({ active, payload }) => {
//                       if (active && payload && payload.length) {
//                         return (
//                           <div className="bg-white p-2 border border-gray-200 shadow-sm">
//                             <p className="text-gray-900 font-bold">{payload[0].payload.codigo}</p>
//                             <p className="text-gray-700">{payload[0].payload.nombre}: {payload[0].value}</p>
//                           </div>
//                         );
//                       }
//                       return null;
//                     }}
//                   />
//                   <Bar dataKey="cantidad">
//                     {barData.map((entry: {codigo: string, nombre: string, cantidad: number}, index: number) => (
//                       <Cell key={`cell-${index}`} fill={colors[entry.codigo]} />
//                     ))}
//                   </Bar>
//                 </BarChart>
//               </ResponsiveContainer>
//               <div className="text-center text-xs font-medium text-cyan-900">Cantidad de documentos</div>
//             </div>
//           </div>
//         </div>

//         {/* Fila 3: Acumulado Documentos */}
//         {/* <div className="mt-8">
//           <div className="bg-white border border-gray-200 rounded shadow">
//             <div className="bg-cyan-500 text-white px-4 py-2.5 rounded-t flex justify-between">
//               <h2 className="text-base font-medium">
//                 {showAcumulado ? "Acumulado Documentos" : "Documentos Parciales"}
//               </h2>
//               <span 
//                 className="text-xs bg-gray-700 px-2 py-1 rounded cursor-pointer"
//                 onClick={() => setShowAcumulado(!showAcumulado)}
//               >
//                 {showAcumulado ? "Vista Acumulativa" : "Vista Parcial"}
//               </span>
//             </div>
//             <div className="p-4">
//               <ResponsiveContainer width="100%" height={220}>
//                 <LineChart
//                   data={lineChartData}
//                   margin={{ top: 10, right: 30, left: 10, bottom: 25 }}
//                 >
//                   <CartesianGrid strokeDasharray="3 3" />
//                   <XAxis 
//                     dataKey="fecha" 
//                     angle={-45} 
//                     textAnchor="end"
//                     height={50}
//                     tick={{ fontSize: 10 }}
//                     tickMargin={10}
//                   />
//                   <YAxis domain={[0, 'auto']} axisLine={false} tickLine={false} />
//                   <Tooltip />
//                   <Legend 
//                     layout="horizontal" 
//                     verticalAlign="top" 
//                     align="center"
//                     iconType="circle"
//                     iconSize={8}
//                     wrapperStyle={{ paddingBottom: '10px' }}
//                   />
//                   {(showAcumulado ? datosAcumulados : datosParciales)
//                     .map(serie => (
//                       <Line
//                         key={serie.codigo}
//                         type="monotone"
//                         dataKey={serie.codigo}
//                         name={`${serie.codigo} - ${serie.nombre}`}
//                         stroke={colors[serie.codigo]}
//                         strokeWidth={2}
//                         dot={{ r: 3 }}
//                         activeDot={{ r: 6 }}
//                       />
//                     ))}
//                 </LineChart>
//               </ResponsiveContainer>
//             </div>
//           </div>
//         </div> */}

//         {/* Fila 2: Detalle por Fecha */}
//         {/* <div className="mt-4">
//           <div className="bg-white border border-gray-200 rounded shadow">
//             <div className="bg-cyan-500 text-white px-4 py-2.5 rounded-t flex justify-between">
//               <h2 className="text-base font-medium">
//                 {showAcumulado ? "Datos Acumulados" : "Datos Parciales"} - Detalle por Fecha
//               </h2>
//               <div className="flex items-center space-x-2">
//                 <div className="h-2 w-2 rounded-full bg-green-500"></div>
//                 <span className="text-xs">Actualizado</span>
//               </div>
//             </div>
//             <div className="p-2 overflow-x-auto max-h-60">
//               <table className="min-w-full">
//                 <thead className="sticky top-0 bg-gray-50 z-10">
//                   <tr className="bg-gray-50">
//                     <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sticky left-0 bg-gray-50">CÓDIGO</th>
//                     {fechas.map(fecha => (
//                       <th key={fecha} className="px-3 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
//                         {fecha}
//                       </th>
//                     ))}
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {(showAcumulado ? datosAcumulados : datosParciales)
//                     .map((serie, idx) => (
//                     <tr key={serie.codigo} className={`border-b ${idx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}`}>
//                       <td className={`px-3 py-2 text-sm font-medium sticky left-0 ${idx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}`}>
//                         <div className="flex items-center">
//                           <div className="h-3 w-3 rounded-full mr-2" style={{backgroundColor: colors[serie.codigo]}}></div>
//                           <span>{serie.codigo}</span>
//                           <span className="ml-2 text-gray-500">- {serie.nombre}</span>
//                         </div>
//                       </td>
//                       {serie.values.map((value, index) => (
//                         <td key={index} className="px-3 py-2 text-center text-sm">
//                           {value > 0 ? value : '-'}
//                         </td>
//                       ))}
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             </div>
//           </div>
//         </div> */}
//       </div>
//     </div>
//   );
// };

// export default ControlDocumental;
// // import React, { useState, useEffect, useCallback, useRef } from 'react';
// // import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Cell } from 'recharts';
// // import axios from 'axios';

// // // Define la interfaz para los datos
// // interface DataItem {
// //   concepto: string;
// //   nomenclatura: string;
// //   cantidad: number;
// // }

// // const ResumenControlDocumental: React.FC = () => {
// //   const [datos, setDatos] = useState<DataItem[]>([]);
// //   const [niveles, setNiveles] = useState<any[]>([]);
// //   const [sortConfig, setSortConfig] = useState<{ key: keyof DataItem | null, direction: 'ascending' | 'descending' }>({ key: null, direction: 'ascending' });
// //   const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);

// //   // Ref for the component
// //   const componentRef = useRef<HTMLDivElement>(null);

// //   useEffect(() => {
// //     const fetchNiveles = async () => {
// //       try {
// //         const response = await axios.get<any[]>(`${process.env.REACT_APP_API_URL}/php/pages/controldocument/glosario/get_levels.php`);
// //         setNiveles(response.data);
// //       } catch (error) {
// //         console.error('Error fetching niveles:', error);
// //       }
// //     };

// //     const fetchData = async () => {
// //       try {
// //         const response = await axios.get<DataItem[]>(`${process.env.REACT_APP_API_URL}/php/pages/controldocument/glosario/get_levels_data.php?type=type2`);
// //         setDatos(response.data);
// //       } catch (error) {
// //         console.error('Error fetching data:', error);
// //       }
// //     };

// //     fetchNiveles();
// //     fetchData();
// //   }, []);

// //   const sortData = useCallback((key: keyof DataItem) => {
// //     let direction: 'ascending' | 'descending' = 'ascending';
// //     if (sortConfig.key === key && sortConfig.direction === 'ascending') {
// //       direction = 'descending';
// //     }
// //     const sortedData = [...datos].sort((a, b) => {
// //       if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1;
// //       if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1;
// //       return 0;
// //     });
// //     setDatos(sortedData);
// //     setSortConfig({ key, direction });
// //   }, [datos, sortConfig]);

// //   const handleRowClick = useCallback((index: number) => {
// //     setSelectedRowIndex(index);
// //   }, []);

// //   useEffect(() => {
// //     const handleClickOutside = (event: MouseEvent) => {
// //       if (componentRef.current && !componentRef.current.contains(event.target as Node)) {
// //         setSelectedRowIndex(null);  // Reset selection when clicking outside
// //       }
// //     };

// //     document.addEventListener('mousedown', handleClickOutside);

// //     return () => {
// //       document.removeEventListener('mousedown', handleClickOutside);
// //     };
// //   }, []);

// //   return (
// //     <div ref={componentRef} className="font-sans p-4 max-w-4xl mx-auto">
// //       <h2 className="text-2xl font-bold mb-4 text-center bg-cyan-300 py-2">Resumen Control Documental</h2>
// //       <div className="flex flex-col md:flex-row gap-4">
// //         <div className="md:w-1/2">
// //           <table className="w-full border-collapse">
// //             <thead>
// //               <tr className="bg-orange-200">
// //                 <th className="p-2 cursor-pointer" onClick={() => sortData('concepto')}>Nombre</th>
// //                 <th className="p-2 cursor-pointer" onClick={() => sortData('nomenclatura')}>Código</th>
// //                 <th className="p-2 cursor-pointer" onClick={() => sortData('cantidad')}>Cantidad</th>
// //               </tr>
// //             </thead>
// //             <tbody>
// //               {datos.map((item, index) => (
// //                 <tr 
// //                   key={index} 
// //                   className={`${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'} ${selectedRowIndex === index ? 'bg-yellow-200' : 'hover:bg-yellow-100'} cursor-pointer`}
// //                   onClick={() => handleRowClick(index)}
// //                 >
// //                   <td className="border p-2">{item.concepto}</td>
// //                   <td className="border p-2">{item.nomenclatura}</td>
// //                   <td className="border p-2">{item.cantidad}</td>
// //                 </tr>
// //               ))}
// //             </tbody>
// //           </table>
// //         </div>
// //         <div className="md:w-1/2 h-64">
// //           <ResponsiveContainer width="100%" height="100%">
// //             <BarChart data={datos}>
// //               <XAxis dataKey="nomenclatura" />
// //               <YAxis />
// //               <Tooltip />
// //               <Bar dataKey="cantidad">
// //                 {datos.map((entry, index) => (
// //                   <Cell 
// //                     key={`cell-${index}`}
// //                     fill={selectedRowIndex === index ? '#ffa726' : '#8884d8'}
// //                   />
// //                 ))}
// //               </Bar>
// //             </BarChart>
// //           </ResponsiveContainer>
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // export default ResumenControlDocumental;


