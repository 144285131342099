import React, { useState, useEffect } from 'react';
import '../../css/FormularioCurvas.css';
import { values } from 'lodash';

const FormularioCurvasReal: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>('Formulario1');
  const [form1Data, setForm1Data] = useState<any[]>([]);
  const [form2Data, setForm2Data] = useState<any[]>([]);
  const [summary1, setSummary1] = useState<{ sumaTotal: number; [key: string]: number }>({
    sumaTotal: 0,
  });
  const [summary2, setSummary2] = useState<{ sumaCubicacionTotal: number; [key: string]: number }>({
    sumaCubicacionTotal: 0,
  });
  const [summary3, setSummary3] = useState<{
    periodos: string[];
    viviendasreal: number[];
    urbanizacionreal: number[];
    totalreal: number[];
  }>({
    periodos: [],
    viviendasreal: [],
    urbanizacionreal: [],
    totalreal: [],
  });

  const [showModalReal, setShowModalReal] = useState<boolean>(true);

  useEffect(() => {
    if (activeTab === 'Formulario1') {
      loadExistingData1();
    } else if (activeTab === 'Formulario2') {
      loadExistingData2();
    } else if (activeTab === 'Resumen') {
      updateResumen();
    }
  }, [activeTab]);

  const loadExistingData1 = () => {
    fetch('http://localhost:3000/php/pages/gestion/curvas2/obtener_datos.php')
      .then(response => response.json())
      .then(data => {
        const transformedData = data.map((item: any) => ({
          ...item,
          dynamicColumns: JSON.parse(item.dynamicColumns),
          dynamicColumnsReal: JSON.parse(item.dynamicColumnsReal)
        }));
        console.log('Transformed Data:', transformedData);
        setForm1Data(transformedData);
        updateSummary1(transformedData);
      })
      .catch(error => console.error('Error al cargar los datos del Formulario 1:', error));
  };

  const loadExistingData2 = () => {
    fetch('http://localhost:3000/php/pages/gestion/curvas2/obtener_datos_2.php')
      .then(response => response.json())
      .then(data => {
        const transformedData = data.map((item: any) => ({
          ...item,
          dynamicColumns: JSON.parse(item.dynamicColumns),
          dynamicColumnsReal: JSON.parse(item.dynamicColumnsReal)
        }));
        console.log('Transformed Data:', transformedData);
        setForm2Data(transformedData);
        updateSummary2(transformedData);
      })
      .catch(error => console.error('Error al cargar los datos del Formulario 2:', error));
  };

  const updateSummary1 = (data: any[]) => {
    const sumaTotal = data.reduce((sum, item) => sum + (parseFloat(item.Total) || 0), 0);
    const semanas: { [key: string]: number } = {};

    data.forEach(item => {
      if (item.dynamicColumnsReal && Array.isArray(item.dynamicColumnsReal)) {
        item.dynamicColumnsReal.forEach((value: number, index: number) => {
          const key = `S${index + 1}`;
          if (!semanas[key]) semanas[key] = 0;
          semanas[key] += (value / sumaTotal) * 100;
        });
      }
    });

    const summaryUpdate: { sumaTotal: number; [key: string]: number } = { sumaTotal };
    Object.keys(semanas).forEach(semana => {
      summaryUpdate[semana] = semanas[semana];
    });

    setSummary1(summaryUpdate);
  };

  const updateSummary2 = (data: any[]) => {
    const sumaCubicacionTotal = data.reduce((sum, item) => sum + (parseFloat(item.CubicacionTotal) || 0), 0);
    const semanas: { [key: string]: number } = {};

    data.forEach(item => {
      const porcentaje = sumaCubicacionTotal > 0
        ? (parseFloat(item.CubicacionTotal) || 0) / sumaCubicacionTotal
        : 0;

      if (item.dynamicColumnsReal && Array.isArray(item.dynamicColumnsReal)) {
        item.dynamicColumnsReal.forEach((value: number, index: number) => {
          const key = `S${index + 1}`;
          if (!semanas[key]) semanas[key] = 0;
          semanas[key] += value * porcentaje;
        });
      }
    });

    const summaryUpdate: { sumaCubicacionTotal: number; [key: string]: number } = { sumaCubicacionTotal };
    Object.keys(semanas).forEach(semana => {
      summaryUpdate[semana] = semanas[semana];
    });

    setSummary2(summaryUpdate);
  };

  const updateResumen = async () => {
    const todasLasSemanas = new Set<string>();

    [...form1Data, ...form2Data].forEach(item => {
      if (item.dynamicColumnsReal && Array.isArray(item.dynamicColumnsReal)) {
        item.dynamicColumnsReal.forEach((_: number | null, index: number) => {
          todasLasSemanas.add(`S${index + 1}`);
        });
      }
    });

    const semanas = Array.from(todasLasSemanas).sort((a, b) => parseInt(a.slice(1)) - parseInt(b.slice(1)));

    const viviendasreal = semanas.map(semana => summary1[semana] || 0);
    const urbanizacionreal = semanas.map(semana => summary2[semana] || 0);

    const totalreal = semanas.map(semana => {
      const sumaViviendas = summary1[semana] || 0;
      const sumaUrbanizacion = summary2[semana] || 0;
      const sumaTotal = summary1.sumaTotal + summary2.sumaCubicacionTotal;

      if (sumaViviendas >= 0 && sumaUrbanizacion >= 0) {
        return sumaTotal > 0
          ? (sumaViviendas * summary1.sumaTotal + sumaUrbanizacion * summary2.sumaCubicacionTotal) / sumaTotal
          : 0;
      } else if (sumaViviendas >= 0) {
        return sumaViviendas;
      } else if (sumaUrbanizacion >= 0) {
        return sumaUrbanizacion;
      } else {
        return 0;
      }
    });

    let proyeccion = [...totalreal]; // Copia de los valores reales existentes

    const L = 100; // Máximo valor (100%)
    const k = 0.1; // Controla la pendiente de crecimiento

    // Encontrar el último punto real más reciente
    let ultimoRealIndex = totalreal.length - 1;
    while (ultimoRealIndex >= 0 && (totalreal[ultimoRealIndex] === null || totalreal[ultimoRealIndex] === 0)) {
        ultimoRealIndex--;
    }

    // Si no hay datos reales, comenzamos desde 1
    let valorBase = ultimoRealIndex >= 0 ? totalreal[ultimoRealIndex] : 1;
    const x0 = ultimoRealIndex + Math.floor((semanas.length - ultimoRealIndex) / 3); // Punto de inflexión

    // Calcular la pendiente promedio de los últimos datos reales
    let sumaPendientes = 0, contador = 0;
    for (let i = 1; i <= 5; i++) {
        if (ultimoRealIndex - i >= 0) {
            let diff = totalreal[ultimoRealIndex - i + 1] - totalreal[ultimoRealIndex - i];
            sumaPendientes += diff;
            contador++;
        }
    }
    let pendientePromedio = contador > 0 ? sumaPendientes / contador : 0;

    let alcanzado100 = false; // Bandera para marcar cuando se llega a 100
    //let alertaMostrada = false; // Bandera para controlar si ya se mostró una alerta
    //for (let i = 0; i < semanas.length; i++) {
    //   // Verificar si el valor actual es menor o igual que el anterior en totalreal
    //   if (i > 0 && viviendasreal[i] == 0 && urbanizacionreal[i] > urbanizacionreal[i - 1]){
    //    alert(`Faltan datos que ingresar en Viviendas, en la semana ${semanas[i]}.`);
    //    alertaMostrada = true; // Marcar que ya se mostró una alerta
    //   //if ((i > 0 && viviendasreal[i] == 0 && urbanizacionreal[i] > urbanizacionreal[i - 1])||(i > 0 && urbanizacionreal[i] == 0 && viviendasreal[i] > viviendasreal[i - 1])) {
    //   // alert(`Faltan datos que ingresar en la semana ${semanas[i]}.`);
    //   // alertaMostrada = true; // Marcar que ya se mostró una alerta
    //  }
    //  if (i > 0 && urbanizacionreal[i] == 0 && viviendasreal[i] > viviendasreal[i - 1]) {
    //    alert(`Faltan datos que ingresar en Urbanización, en la semana ${semanas[i]}.`);
    //    alertaMostrada = true; // Marcar que ya se mostró una alerta
    //  }
    //}

    // Proyección combinando sigmoide con la tendencia de los datos anteriores
    for (let i = ultimoRealIndex + 1; i < semanas.length; i++) {
        if (alcanzado100) {
            proyeccion[i] = 100; // Si ya llegamos a 100, todos los valores restantes son 100
            continue;
        }

        // Verificar si el valor actual es menor o igual que el anterior en totalreal
        //if (i > 0 && urbanizacionreal[i] == 0 && viviendasreal[i] > viviendasreal[i - 1] && !alertaMostrada) {
        //  alert(`Faltan datos que ingresar en el formulario de urbanizacion en la semana ${semanas[i]}.`);
        //  alertaMostrada = true; // Marcar que ya se mostró una alerta
        //}
      
        // Modelo sigmoide para suavizar la curva
        const sigmoide = valorBase + (L - valorBase) / (1 + Math.exp(-k * (i - x0)));
      
        // Agregar la tendencia previa para hacer la transición más fluida
        let proyeccionTendencia = proyeccion[i - 1] + Math.max(0, pendientePromedio); // Asegurar que solo incrementa
      
        // Mezcla entre tendencia y sigmoide con un peso dinámico
        let pesoSigmoide = 1 - (i - ultimoRealIndex) / semanas.length;
        proyeccion[i] = pesoSigmoide * proyeccionTendencia + (1 - pesoSigmoide) * sigmoide;
      
        // Asegurar que la proyección solo sube y no baja
        proyeccion[i] = Math.max(proyeccion[i - 1], proyeccion[i]);
      
        // Si llega a 100, marcar bandera y establecer todos los siguientes en 100
        if (proyeccion[i] >= 100) {
            proyeccion[i] = 100;
            alcanzado100 = true;
        }
    }
  // Inicializar proyección con valores predeterminados
  //  let proyeccion = semanas.map((_, index) => totalreal[index] || 0);
//
  //  // Calcular la proyección sucesivamente hasta alcanzar el 100%
  //  for (let i = 0; i < semanas.length; i++) {
  //    let valorBase = totalreal[i];
//
  //    // Si el valor real es 0 o null, usar el valor anterior de proyección
  //    if (valorBase === 0 || valorBase === null) {
  //      valorBase = (i > 0) ? proyeccion[i - 1] : 0;
  //    }
//
  //    for (let j = i; j < semanas.length; j++) {
  //      const tendencia = (valorBase - (totalreal[0] || 0)) / (j + 1);
  //      proyeccion[j] = valorBase + tendencia;
//
  //      // Restringir la proyección entre 0 y 100
  //      proyeccion[j] = Math.max(0, Math.min(100, proyeccion[j]));
//
  //      // Si alcanza el 100%, detener el cálculo
  //      if (proyeccion[j] >= 100) {
  //        break;
  //      }
//
  //      // Actualizar el valor base para la siguiente iteración
  //      valorBase = proyeccion[j];
  //    
  //    }
  //  }

    // Inicializar proyección con valores predeterminados
    //let proyeccion = semanas.map((_, index) => totalreal[index] || 0);
//
    //// Calcular la proyección sucesivamente hasta alcanzar el 100%
    //for (let i = 0; i < semanas.length; i++) {
    //  let valorBase = totalreal[i];
//
    //  // Si el valor real es 0 o null, usar el valor anterior de proyección
    //  if (valorBase === 0 || valorBase === null) {
    //    valorBase = (i > 0) ? proyeccion[i - 1] : 0;
    //  }
//
    //  for (let j = i; j < semanas.length; j++) {
    //    const programado = updateResumen[semanas[j]] || 0; // Obtener el valor programado
    //    const diferenciaProgramado = programado - valorBase;
    //    const ajuste = diferenciaProgramado / (semanas.length - j);
//
    //    proyeccion[j] = valorBase + ajuste;
//
    //    // Restringir la proyección entre 0 y 100
    //    proyeccion[j] = Math.max(0, Math.min(100, proyeccion[j]));
//
    //    // Si alcanza el 100%, detener el cálculo
    //    if (proyeccion[j] >= 100) {
    //      break;
    //    }
//
    //    // Actualizar el valor base para la siguiente iteración
    //    valorBase = proyeccion[j];
    //  }
    //}
    
    setSummary3({
      periodos: semanas,
      viviendasreal,
      urbanizacionreal,
      totalreal,
    });

    const datosParaDB = semanas.map((semana, index) => ({
      Semana: semana,
      ID: index + 1,
      Mes: parseInt(semana.slice(1)),
      AvanceReal: typeof totalreal[index] === "number" ? parseFloat(totalreal[index].toFixed(2)) : 0,
      ViviendasReal: typeof viviendasreal[index] === "number" ? parseFloat(viviendasreal[index].toFixed(2)) : 0,
      UrbanizacionReal: typeof urbanizacionreal[index] === "number" ? parseFloat(urbanizacionreal[index].toFixed(2)) : 0,
      Proyección: typeof proyeccion[index] === "number" ? parseFloat(proyeccion[index].toFixed(2)) : 0, // Agregar proyección
    }));

    try {
      const response = await fetch('http://localhost:3000/php/pages/gestion/curvasreal/actualizar_curva_real.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ datos: datosParaDB }),
      });

      const result = await response.json();
      if (!result.success) {
        console.error('Error al actualizar la curva:', result.error);
      }
    } catch (error) {
      console.error('Error al enviar los datos a la curva:', error);
    }
  };

  const handlePasteForm1 = async (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    const clipboardData = event.clipboardData.getData('text');
    const rows = clipboardData.split('\n').filter(row => row.trim() !== ''); // Ignorar filas vacías
    const parsedData = rows.map(row => {
      const cols = row.split('\t');
      const dynamicColumnsReal: (number | null)[] = [];
  
      // Procesar columnas dinámicas a partir de la columna 7
      for (let i = 4; i < cols.length; i++) {
        const value = cols[i]?.trim();
        if (value === "") {
          dynamicColumnsReal.push(null); // Si está vacío, lo convierte en null
        } else if (value.includes("%")) {
          // Si es porcentaje, elimina el "%" y convierte a número
          dynamicColumnsReal.push(parseFloat(value.replace(',', '.').replace('%', '')));
        } else {
          // Convierte números normales con coma decimal a punto decimal
          dynamicColumnsReal.push(parseFloat(value.replace(',', '.')));
        }
      }
  
      return {
        Item: cols[0]?.trim() || '',
        Partida: cols[1]?.trim() || '',
        Tipo: cols[2]?.trim() || '',
        Total: parseFloat(cols[3]?.replace(',', '.') || '0'),
        dynamicColumnsReal
      };
    });
  
    setForm1Data(prev => {
      console.log("Previous form data:", prev);
      console.log("New data being added:", parsedData);
      return [...prev, ...parsedData];
    });
    //setForm2Data(prev => [...prev, ...parsedData]);
  
    try {
      console.log("Data sent to server:", JSON.stringify({ data: parsedData }, null, 2));
      const response = await fetch('http://localhost:3000/php/pages/gestion/curvasreal/actualizar_semanas_real.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data: parsedData }),
      });
  
      const result = await response.json();
      if (result.success) {
        //alert('Base de datos actualizada correctamente');
        // Recargar los datos actualizados
        await loadExistingData1();
        await updateResumen();
      } else {
        console.error('Error:', result.error);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Faltan datos');
    }
  };

  const handlePasteForm2 = async (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    const clipboardData = event.clipboardData.getData('text');
    const rows = clipboardData.split('\n').filter(row => row.trim() !== ''); // Ignorar filas vacías
    const parsedData = rows.map(row => {
      const cols = row.split('\t');
      const dynamicColumnsReal: (number | null)[] = [];
      //dynamicColumnsReal = dynamicColumnsReal || new Array(row.dynamicColumns.length).fill(0);
  
      // Procesar columnas dinámicas a partir de la columna 7
      for (let i = 6; i < cols.length; i++) {
        const value = cols[i]?.trim();
        if (value === "") {
          dynamicColumnsReal.push(null); // Si está vacío, lo convierte en null
        } else if (value.includes("%")) {
          // Si es porcentaje, elimina el "%" y convierte a número
          dynamicColumnsReal.push(parseFloat(value.replace(',', '.').replace('%', '')));
        } else {
          // Convierte números normales con coma decimal a punto decimal
          dynamicColumnsReal.push(parseFloat(value.replace(',', '.')));
        }
      }
  
      return {
        Item: cols[2]?.trim() || '',
        CubicacionTotal: parseFloat(cols[0]?.replace(',', '.') || '0'),
        PorcentajeRepresentativo: parseFloat(cols[1]?.replace(',', '.') || '0'),
        Partida: cols[3]?.trim() || '',
        Tipo: cols[4]?.trim() || '',
        Unidad: cols[5]?.trim() || '',
        dynamicColumnsReal
      };
    });
  
    setForm2Data(prev => {
      console.log("Previous form data:", prev);
      console.log("New data being added:", parsedData);
      return [...prev, ...parsedData];
    });
    //setForm2Data(prev => [...prev, ...parsedData]);
  
    try {
      console.log("Data sent to server:", JSON.stringify({ data: parsedData }, null, 2));
      const response = await fetch('http://localhost:3000/php/pages/gestion/curvasreal/actualizar_semanas_urbanizacion_real.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data: parsedData }),
      });
  
      const result = await response.json();
      if (result.success) {
        //alert('Base de datos actualizada correctamente');
        // Recargar los datos actualizados
        await loadExistingData2();
        await updateResumen();
      } else {
        console.error('Error:', result.error);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  const handlePasteSingleColumn = async (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    const clipboardData = event.clipboardData.getData('text');
    const rows = clipboardData.split('\n').map(row => {
      const trimmedRow = row.trim();
      return trimmedRow !== '' ? trimmedRow : null; // Mantén las filas vacías como null
    });
    if (rows.length === 0) {
      console.error("No se han pegado datos.");
      return;
    }
    // La primera fila debe contener el número de la semana
    const weekHeader = rows[0];
    if (weekHeader === null) {
      console.error("El encabezado de la columna no es válido");
      return;
    }    const weekIndex = parseInt(weekHeader.slice(1)) - 1; // Convertir "S47" a índice 46
    if (isNaN(weekIndex)) {
      console.error("El encabezado de la columna no es válido:", weekHeader);
      return;
    }
    const parsedData = form1Data.map((row, i) => {
       // Inicializar dynamicColumnsReal con nulls si no existe
       row.dynamicColumnsReal = row.dynamicColumnsReal || new Array(row.dynamicColumns.length).fill(0);
  
      if (!row.dynamicColumnsReal) {
        console.error("row.dynamicColumnsReal es null o undefined en la fila:", i);
        return row; // Evita que se intente asignar a un objeto nulo
      }
      
      const value = rows[i+1]!== undefined ? rows[i + 1] : null;
      if (value === "" || value === null) {
        row.dynamicColumnsReal[weekIndex] = null;
      } else if (value.includes("%")) {
        // Si es porcentaje, elimina el "%" y convierte a número
        row.dynamicColumnsReal[weekIndex] = parseFloat(value.replace(',', '.').replace('%', ''));
      } else {
        // Convierte números normales con coma decimal a punto decimal
        row.dynamicColumnsReal[weekIndex] = parseFloat(value.replace(',', '.'));
      }
      return row;
    });
    // Actualizar el estado con los datos modificados
    setForm1Data(parsedData);
  
    // Preparar los datos para enviar al servidor
    try {
      const response = await fetch('http://localhost:3000/php/pages/gestion/curvasreal/actualizar_semanas_real.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data: parsedData },),
      });
  
      const result = await response.json();
      if (result.success) {
        await loadExistingData1();
        await updateResumen();
      } else {
        console.error('Error:', result.error);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handlePasteSingleColumn2 = async (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    const clipboardData = event.clipboardData.getData('text');
    const rows = clipboardData.split('\n').map(row => {
      const trimmedRow = row.trim();
      return trimmedRow !== '' ? trimmedRow : null; // Mantén las filas vacías como null
    });
    if (rows.length === 0) {
      console.error("No se han pegado datos.");
      return;
    }
    // La primera fila debe contener el número de la semana
    const weekHeader = rows[0];
    if (weekHeader === null) {
      console.error("El encabezado de la columna no es válido");
      return;
    }    const weekIndex = parseInt(weekHeader.slice(1)) - 1; // Convertir "S47" a índice 46
    if (isNaN(weekIndex)) {
      console.error("El encabezado de la columna no es válido:", weekHeader);
      return;
    }
    const parsedData = form2Data.map((row, i) => {
      // Inicializar dynamicColumnsReal con nulls si no existe
      row.dynamicColumnsReal = row.dynamicColumnsReal || new Array(row.dynamicColumns.length).fill(0);
  
      if (!row.dynamicColumnsReal) {
        console.error("row.dynamicColumnsReal es null o undefined en la fila:", i);
        return row; // Evita que se intente asignar a un objeto nulo
      }
      
      const value = rows[i+1]!== undefined ? rows[i + 1] : null;
      if (value === "" || value === null) {
        row.dynamicColumnsReal[weekIndex] = null;
      } else if (value.includes("%")) {
        // Si es porcentaje, elimina el "%" y convierte a número
        row.dynamicColumnsReal[weekIndex] = parseFloat(value.replace(',', '.').replace('%', ''));
      } else {
        // Convierte números normales con coma decimal a punto decimal
        row.dynamicColumnsReal[weekIndex] = parseFloat(value.replace(',', '.'));
      }
      return row;
    });
    // Actualizar el estado con los datos modificados
    setForm2Data(parsedData);
  
    // Preparar los datos para enviar al servidor
    try {
      const response = await fetch('http://localhost:3000/php/pages/gestion/curvasreal/actualizar_semanas_urbanizacion_real.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data: parsedData },),
      });
  
      const result = await response.json();
      if (result.success) {
        await loadExistingData2();
        await updateResumen();
      } else {
        console.error('Error:', result.error);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    const clipboardData = event.clipboardData.getData('text');
    const rows = clipboardData.split('\n').map(row => row.trim()).filter(row => row !== '');

    if (rows.length === 0) {
        console.error("No se han pegado datos.");
        return;
    }

    // Detectar si es formato de columna única o formato de tabla
    const firstRow = rows[0];

    if (/^S\d+$/.test(firstRow)) {
        // Si comienza con "S" seguido de un número, es una sola columna
        console.log("Detectado formato de una sola columna.");
        handlePasteSingleColumn(event);
    } else if (/^\d+\s+\D+/.test(firstRow)) {
        // Si comienza con un número seguido de texto, es formato de tabla
        console.log("Detectado formato de tabla.");
        handlePasteForm1(event);
    } else {
        console.error("Formato de datos no reconocido.");
    }
  };

  const handlePaste1 = (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    const clipboardData = event.clipboardData.getData('text');
    const rows = clipboardData.split('\n').map(row => row.trim()).filter(row => row !== '');

    if (rows.length === 0) {
        console.error("No se han pegado datos.");
        return;
    }

    // Detectar si es formato de columna única o formato de tabla
    const firstRow = rows[0];

    if (/^S\d+$/.test(firstRow)) {
        // Si comienza con "S" seguido de un número, es una sola columna
        console.log("Detectado formato de una sola columna.");
        handlePasteSingleColumn2(event);
    } else {
        // Si comienza con un número seguido de texto, es formato de tabla
        console.log("Detectado formato de tabla.");
        handlePasteForm2(event);
    } 
  };
  
  const handleInputChange = async (index: number, key: string, value: string | number) => {
    if (activeTab === 'Formulario1') {
      const newData = [...form1Data];
      newData[index][key] = value;
      setForm1Data(newData);
      await handleSubmit('viviendasForm', 'formulario_real_1.php');
    } else if (activeTab === 'Formulario2') {
      const newData = [...form2Data];
      newData[index][key] = value;
      setForm2Data(newData);
      await handleSubmit('urbanizacionForm', 'formulario_real_2.php');
    }
  };

  const handleDynamicInputChange = async (index: number, key: string, value: string | number | (number | null)[]) => {
    try {
      const newData = activeTab === 'Formulario1' ? [...form1Data] : [...form2Data];

      if (key === 'dynamicColumnsReal' && Array.isArray(value)) {
        newData[index][key] = value.map(v =>
          v === null || v === undefined || isNaN(Number(v)) ? null : Number(v)
        );
      } else {
        newData[index][key] = value;
      }

      if (activeTab === 'Formulario1') {
        setForm1Data(newData);
        await handleSubmit('viviendasForm', 'formulario_real_1.php');
      } else if (activeTab === 'Formulario2') {
        setForm2Data(newData);
        await handleSubmit('urbanizacionForm', 'formulario_real_2.php');
      }
    } catch (error) {
      console.error('Error en handleDynamicInputChange:', error);
    }
  };

  const handleSubmit = async (formId: string, url: string) => {
    try {
      console.log(`📤 Iniciando envío para: ${formId} -> ${url}`);

      const dataToSend = formId === 'viviendasForm' ? form1Data : form2Data;

      const transformedData = dataToSend.map(item => {
        if (!Array.isArray(item.dynamicColumnsReal)) {
          console.error("❌ Error: dynamicColumnsReal no es un array", item.dynamicColumnsReal);
          throw new Error("dynamicColumnsReal debe ser un array");
        }

        const dynamicColumnsObj = item.dynamicColumns.reduce((acc: {[key: string]: number}, value: number | null, index: number) => {

          acc[`S${index + 1}`] = value ?? 0;
          return acc;
        }, {});

        const dynamicColumnsString = JSON.stringify(dynamicColumnsObj);

        const { dynamicColumns, ...rest } = item;
        return { ...rest, dynamicColumnsReal: dynamicColumnsString };
      });

      console.log('📤 Datos a enviar:', JSON.stringify({ data: transformedData }, null, 2));

      const response = await fetch(`http://localhost:3000/php/pages/gestion/curvasreal/${url}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ data: transformedData }),
      });

      if (!response.ok) {
        throw new Error(`⚠️ Error en la respuesta HTTP: ${response.status}`);
      }

      const responseText = await response.text();
      console.log("📩 Respuesta cruda del servidor:", responseText);

      const cleanedResponse = JSON.parse(responseText.trim());

      console.log("✅ Respuesta limpia del servidor:", cleanedResponse);

      if (cleanedResponse.success) {
        if (formId === 'viviendasForm') {
          await loadExistingData1();
        } else if (formId === 'urbanizacionForm') {
          await loadExistingData2();
        }
      } else {
        console.warn("⚠️ El servidor respondió con un error:", cleanedResponse.message);
        alert(cleanedResponse.message || '⚠️ Error al procesar el formulario');
      }
    } catch (error) {
      console.error('❌ Error en handleSubmit:', error);
      alert(`❌ Error al enviar el formulario: ${error}`);
    }
  };

  const handleSubmit1 = async (formId: string, url: string) => {
    try {
        console.log(`📤 Iniciando envío para: ${formId} -> ${url}`);

        const dataToSend = formId === 'viviendasForm' ? form1Data : form2Data;

        const transformedData = dataToSend.map(item => {
            console.log("🔍 Debug antes de procesar dynamicColumnsReal:", item.dynamicColumnsReal);

            if (!Array.isArray(item.dynamicColumnsReal)) {
                console.error("❌ Error: dynamicColumnsReal no es un array", item.dynamicColumnsReal);
                throw new Error("dynamicColumnsReal debe ser un array");
            }

            // Convertir array en objeto con claves S1, S2, S3...
            const dynamicColumnsObj = item.dynamicColumns.reduce((acc: {[key: string]: number}, value: number | null, index: number) => {

              acc[`S${index + 1}`] = value ?? 0; // Si es null, convertir a 0
                return acc;
            }, {});

            console.log("✅ dynamicColumnsReal convertido a objeto:", dynamicColumnsObj);

            // 🔥 Convertir a string JSON manteniendo formato
            const dynamicColumnsString = JSON.stringify(dynamicColumnsObj);

            console.log("📝 dynamicColumnsReal como string JSON:", dynamicColumnsString);

            // Crear objeto final con dynamicColumns en formato string JSON
            const { dynamicColumns, ...rest } = item;
            return { ...rest, dynamicColumnsReal: dynamicColumnsString };
        });

        console.log('📤 Datos a enviar:', JSON.stringify({ data: transformedData }, null, 2));

        const response = await fetch(`http://localhost:3000/php/pages/gestion/curvasreal/${url}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ data: transformedData }),
        });

        console.log("🔄 Esperando respuesta del servidor...");

        if (!response.ok) {
            throw new Error(`⚠️ Error en la respuesta HTTP: ${response.status}`);
        }

        const responseText = await response.text();
        console.log("📩 Respuesta cruda del servidor:", responseText);

        // 🔍 Limpiar espacios en blanco y convertir a JSON
        const cleanedResponse = JSON.parse(responseText.trim());

        console.log("✅ Respuesta limpia del servidor:", cleanedResponse);

        if (cleanedResponse.success) {
            if (formId === 'viviendasForm') {
                await loadExistingData1();
            } else if (formId === 'urbanizacionForm') {
                await loadExistingData2();
            }
            //alert('✅ Datos actualizados correctamente');
        } else {
            console.warn("⚠️ El servidor respondió con un error:", cleanedResponse.message);
            alert(cleanedResponse.message || '⚠️ Error al procesar el formulario');
        }
    } catch (error) {
        console.error('❌ Error en handleSubmit:', error);
        //alert(`❌ Error al enviar el formulario: ${error.message}`);
    }
  };

  const tableHeaderStyle = {
    backgroundColor: '#4ecdc4',
    color: 'white',
    padding: '12px',
    border: '1px solid #ddd'
  } as const;

  const tableCellStyle = {
    padding: '8px',
    border: '1px solid #ddd'
  } as const;

  const tableContainerStyle = {
    maxWidth: '100%',
    overflowX: 'auto',
    overflowY: 'hidden'
  } as const;

  return (
//    <div>
//      {showModalReal && (
//        <div style={{
//          position: 'fixed',
//          top: 0,
//          left: 0,
//          width: '100vw',
//          height: '100vh',
//          backgroundColor: 'rgba(0, 0, 0, 0.5)',
//          display: 'flex',
//          justifyContent: 'center',
//          alignItems: 'center',
//          zIndex: 1000,
//        }}>
//          <div style={{
//            backgroundColor: '#fff',
//            padding: '20px',
//            borderRadius: '8px',
//            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
//            width: '90%',
//            maxWidth: '1400px',
//            maxHeight: '80vh',
//            overflowY: 'auto',
//            position: 'relative',
//          }}>
//            <button
//              style={{
//                position: 'absolute',
//                top: '10px',
//                right: '10px',
//                background: 'transparent',
//                border: 'none',
//                fontSize: '20px',
//                cursor: 'pointer',
//              }}
//              onClick={() => {
//                setShowModalReal(false);
//                window.location.reload();
//              }}
//            >
//              &times;
//            </button>
//            <div>
//              <div className="tab">
//                <td style={{ maxHeight: '60vh', overflowY: 'auto', padding: '10px' }}>Datos Reales</td>
//                <button className={activeTab === 'Formulario1' ? 'active' : ''} onClick={() => setActiveTab('Formulario1')}>
//                  Formulario Viviendas
//                </button>
//                <button className={activeTab === 'Formulario2' ? 'active' : ''} onClick={() => setActiveTab('Formulario2')}>
//                  Formulario Urbanización
//                </button>
//                <button className={activeTab === 'Resumen' ? 'active' : ''} onClick={() => setActiveTab('Resumen')}>
//                  Resumen
//                </button>
//              </div//>
//
//              {activeTab === 'Formulario1' && (
//                <div style={{ maxHeight: '60vh', overflowY: 'auto', padding: '10px' }}>
//                  <form id="viviendasForm">
//                    {form1Data.map((row, index) => (
//                      <div key={index} className="form-group">
//                        <input type="text" name={`Item-${index}`} value={row.Item || ''} onChange={e => handleInputChange(index, 'Item', e.target.value)} />
//                        <input type="text" name={`Partida-${index}`} value={row.Partida || ''} onChange={e => handleInputChange(index, 'Partida', e.target.value)} style={{width: '600px', minWidth: '280px'}} />
//                        <input type="text" name={`Tipo-${index}`} value={row.Tipo || ''} onChange={e => handleInputChange(index, 'Tipo', e.target.value)} style={{width: '200px', minWidth: '200px'}} />
//                        <input type="number" name={`Total-${index}`} value={row.Total || ''} onChange={e => handleInputChange(index, 'Total', e.target.value)} />
//                        {row.dynamicColumnsReal && row.dynamicColumnsReal.map((value, semanaIndex) => (
//                          <input
//                            key={semanaIndex}
//                            type="number"
//                            name={`S${semanaIndex + 1}[]`}
//                            placeholder={`S${semanaIndex + 1}`}
//                            value={value !== null ? value : ''}
//                            onChange={e => {
//                              const newValue = e.target.value === '' ? null : parseFloat(e.target.value);
//                              const newDynamicColumns = [...row.dynamicColumnsReal];
//                              newDynamicColumns[semanaIndex] = newValue;
//                              handleDynamicInputChange(index, 'dynamicColumnsReal', newDynamicColumns);
//                            }}
//                          />
//                        ))}
//                      </div>
//                    ))}
//                    <textarea
//                      placeholder="Pega aquí los datos copiados desde Excel"
//                      onPaste={handlePasteForm1}
//                      style={{ width: '100%', height: '100px', marginTop: '10px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px' }}
//                    />
//                  </form>
//                  <button
//                    type="button"
//                    onClick={async () => {await handleSubmit1('viviendasForm', 'formulario_real_11.php'); window.location.reload();
//                    }}
//                    style={{
//                      marginTop: '10px',
//                      padding: '8px 16px',
//                      backgroundColor: '#4ecdc4',
//                      color: 'white',
//                      border: 'none',
//                      borderRadius: '4px',
//                      cursor: 'pointer'
//                    }}
//                  >
//                    Enviar
//                  </button>
//                  <div className="summary" style={{marginTop: '20px'}}>
//                    <p>Suma Total: {summary1.sumaTotal.toFixed(2)}</p>
//                    {Object.entries(summary1)
//                      .filter(([key]) => key.startsWith('S'))
//                      .sort(([a], [b]) => parseInt(a.slice(1)) - parseInt(b.slice(1)))
//                      .map(([key, value]) => (
//                        <p key={key}>
//                          {key}: {value.toFixed(2)}%
//                        </p>
//                      ))}
//                  </div>
//                </div>
//              )//}
//
//              {activeTab === 'Formulario2' && (
//                <div style={{ maxHeight: '60vh', overflowY: 'auto', padding: '10px' }}>
//                  <form id="urbanizacionForm">
//                    {form2Data.map((row, index) => (
//                      <div key={index} className="form-group">
//                        <input type="text" name={`Item-${index}`} value={row.Item || ''} onChange={e => handleInputChange(index, 'Item', e.target.value)} />
//                        <input type="number" name={`CubicacionTotal-${index}`} value={row.CubicacionTotal || ''} onChange={e => handleInputChange(index, 'CubicacionTotal', e.target.value)} />
//                        <input type="number" name={`PorcentajeRepresentativo-${index}`} value={row.PorcentajeRepresentativo || ''} readOnly />
//                        <input type="text" name={`Partida-${index}`} value={row.Partida || ''} onChange={e => handleInputChange(index, 'Partida', e.target.value)} style={{width: '600px', minWidth: '280px'}} />
//                        <input type="text" name={`Tipo-${index}`} value={row.Tipo || ''} onChange={e => handleInputChange(index, 'Tipo', e.target.value)} style={{width: '200px', minWidth: '200px'}} />
//                        <input type="text" name={`Unidad-${index}`} value={row.Unidad || ''} onChange={e => handleInputChange(index, 'Unidad', e.target.value)} />
//                        {row.dynamicColumnsReal && row.dynamicColumnsReal.map((value, semanaIndex) => (
//                          <input
//                            key={semanaIndex}
//                            type="number"
//                            name={`S${semanaIndex + 1}[]`}
//                            placeholder={`S${semanaIndex + 1}`}
//                            value={value !== null ? value : ''}
//                            onChange={e => {
//                              const newValue = e.target.value === '' ? null : parseFloat(e.target.value);
//                              const newDynamicColumns = [...row.dynamicColumnsReal];
//                              newDynamicColumns[semanaIndex] = newValue;
//                              handleDynamicInputChange(index, 'dynamicColumnsReal', newDynamicColumns);
//                            }}
//                          />
//                        ))}
//                      </div>
//                    ))}
//                    <textarea
//                      placeholder="Pega aquí los datos copiados desde Excel"
//                      onPaste={handlePasteForm2}
//                      style={{ width: '100%', height: '100px', marginTop: '10px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px' }}
//                    />
//                  </form>
//                  <button
//                    type="button"
//                    onClick={async () => {await handleSubmit1('urbanizacionForm', 'formulario_real_22.php'); window.location.reload();
//                    }}
//                    style={{
//                      marginTop: '10px',
//                      padding: '8px 16px',
//                      backgroundColor: '#4ecdc4',
//                      color: 'white',
//                      border: 'none',
//                      borderRadius: '4px',
//                      cursor: 'pointer'
//                    }}
//                  >
//                    Enviar
//                  </button>
//                  <div className="summary">
//                    <p>Cubicación Total: {summary2.sumaCubicacionTotal.toFixed(2)}</p>
//                    {Object.entries(summary2)
//                    .filter(([key]) => key !== 'sumaCubicacionTotal')
//                    .sort(([a], [b]) => {
//                      const aValue = parseInt(a.slice(1));
//                      const bValue = parseInt(b.slice(1));
//                      return aValue - bValue;
//                    })
//                    .map(([key, value], index) => {
//                      const semana = index + 1;
//                      return (
//                        <p key={key}>
//                          S{semana}: {value.toFixed(2)}%
//                        </p>
//                      );
//                    })}
//                  </div>
//                </div>
//              )//}
//
//              {activeTab === 'Resumen' && (
//                <div style={tableContainerStyle}>
//                  <table style={{
//                    width: '100%',
//                    borderCollapse: 'collapse',
//                    marginTop: '20px',
//                    textAlign: 'center'
//                  }}>
//                    <thead>
//                      <tr>
//                        <th style={tableHeaderStyle}>Semana</th>
//                        {summary3.periodos.map(periodo => (
//                          <th key={periodo} style={tableHeaderStyle}>{periodo}</th>
//                        ))}
//                      </tr>
//                    </thead>
//                    <tbody>
//                      <tr>
//                        <td style={tableCellStyle}>Viviendas</td>
//                        {summary3.viviendasreal.map((valor, index) => (
//                          <td key={index} style={tableCellStyle}>{valor.toFixed(2)}%</td>
//                        ))}
//                      </tr>
//                      <tr>
//                        <td style={tableCellStyle}>Urbanización</td>
//                        {summary3.urbanizacionreal.map((valor, index) => (
//                          <td key={index} style={tableCellStyle}>{valor.toFixed(2)}%</td>
//                        ))}
//                      </tr>
//                      <tr style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>
//                        <td style={tableCellStyle}>Total</td>
//                        {summary3.totalreal.map((valor, index) => (
//                          <td key={index} style={tableCellStyle}>{valor.toFixed(2)}%</td>
//                        ))}
//                      </tr>
//                    </tbody>
//                  </table>
//                </div>
//              )}
//            </div>
//          </div>
//        </div>
//      )}
//    </div>
//  );
//};
<div>
  
        <div>
          <div className="tab">
            <td style={{ maxHeight: '60vh', overflowY: 'auto', padding: '10px', backgroundColor: '#d5f5ee', borderRadius: '0 0 8px 8px' }}>Datos Reales</td>
            <button className={activeTab === 'Formulario1' ? 'active' : ''} onClick={() => setActiveTab('Formulario1')}>
              Formulario Viviendas
            </button>
            <button className={activeTab === 'Formulario2' ? 'active' : ''} onClick={() => setActiveTab('Formulario2')}>
              Formulario Urbanización
            </button>
            <button className={activeTab === 'Resumen' ? 'active' : ''} onClick={() => setActiveTab('Resumen')}>
              Resumen
            </button>
          </div>

          {activeTab === 'Formulario1' && (
            <div style={{ maxHeight: '60vh', overflow: 'auto', padding: '10px' }}>
              <div className="sticky-header" style={{ display: 'flex', gap: '10px', padding: '10px', borderBottom: '1px solid #ddd' }}>
                <div style={{ flex: '0 0 150px' }}>Item</div>
                <div style={{ flex: '0 0 280px' }}>Partida</div>
                <div style={{ flex: '0 0 200px' }}>Tipo</div>
                <div style={{ flex: '0 0 150px' }}>Total</div>
                {summary3.periodos.map((periodo, index) => (
                  <div key={index} style={{ flex: '0 0 100px', backgroundColor: '#d5f5ee' }}>{periodo}</div>
                ))}
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '1px' }}>
                {form1Data.map((row, index) => (
                  <div key={index} className="form-group" style={{ display: 'flex', gap: '10px', width: 'max-content', minWidth: 'max-content', boxSizing: 'border-box' }}>
                    <input type="text" name={`Item-${index}`} value={row.Item || ''} onChange={e => handleInputChange(index, 'Item', e.target.value)} style={{ flex: '0 0 150px' }} />
                    <input type="text" name={`Partida-${index}`} value={row.Partida || ''} onChange={e => handleInputChange(index, 'Partida', e.target.value)} style={{ flex: '0 0 280px' }} />
                    <input type="text" name={`Tipo-${index}`} value={row.Tipo || ''} onChange={e => handleInputChange(index, 'Tipo', e.target.value)} style={{ flex: '0 0 200px' }} />
                    <input type="number" name={`Total-${index}`} value={row.Total || ''} onChange={e => handleInputChange(index, 'Total', e.target.value)} style={{ flex: '0 0 100px' }} />
                    {row.dynamicColumns && row.dynamicColumns.map((value: number | null, semanaIndex: number) => (


<input
                        key={semanaIndex}
                        type="number"
                        name={`S${semanaIndex + 1}[]`}
                        placeholder={`S${semanaIndex + 1}`}
                        value={value !== null ? value : ''}
                        onChange={e => {
                          const newValue = e.target.value === '' ? null : parseFloat(e.target.value);
                          const newDynamicColumns = [...row.dynamicColumnsReal];
                          newDynamicColumns[semanaIndex] = newValue;
                          handleDynamicInputChange(index, 'dynamicColumnsReal', newDynamicColumns);
                        }}
                        style={{ flex: '0 0 100px' }}
                      />
                    ))}
                  </div>
                ))}
              </div>
              <textarea
                placeholder="Pega aquí los datos copiados desde Excel"
                onPaste={handlePaste}
                style={{ width: '100%', height: '100px', marginTop: '10px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px' }}
              />
              {/*<textarea placeholder="Pega aquí la columna copiada desde Excel" onPaste={handlePasteSingleColumn} style={{ width: '100%', height: '100px', marginTop: '10px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px' }}></textarea>*/}
             <div className="summary" style={{marginTop: '20px'}}>
                    <p>Suma Total: {summary1.sumaTotal.toFixed(2)}</p>
                    {Object.entries(summary1)
                      .filter(([key]) => key.startsWith('S'))
                      .sort(([a], [b]) => parseInt(a.slice(1)) - parseInt(b.slice(1)))
                      .map(([key, value]) => (
                        <p key={key}>
                          {key}: {value.toFixed(2)}%
                        </p>
                      ))}
                  </div>
            </div>
          )}

          {activeTab === 'Formulario2' && (
            <div style={{ maxHeight: '60vh', overflow: 'auto', padding: '10px' }}>
              <div className="sticky-header" style={{ display: 'flex', gap: '10px', padding: '10px', borderBottom: '1px solid #ddd' }}>
                <div style={{ flex: '0 0 150px' }}>Item</div>
                <div style={{ flex: '0 0 150px' }}>CubicacionTotal</div>
                <div style={{ flex: '0 0 100px' }}>PorcentajeRepresentativo</div>
                <div style={{ flex: '0 0 280px' }}>Partida</div>
                <div style={{ flex: '0 0 200px' }}>Tipo</div>
                <div style={{ flex: '0 0 150px' }}>Unidad</div>
                {summary3.periodos.map((periodo, index) => (
                  <div key={index} style={{ flex: '0 0 100px', backgroundColor: '#d5f5ee' }}>{periodo}</div>
                ))}
              </div>
              <div className="form-group-container" style={{ display: 'flex', flexDirection: 'column', gap: '1px' }}>
                {form2Data.map((row, index) => (
                  <div key={index} className="form-group" style={{ display: 'flex', gap: '10px', width: 'max-content', minWidth: '100%', boxSizing: 'border-box' }}>
                    <input type="text" name={`Item-${index}`} value={row.Item || ''} onChange={e => handleInputChange(index, 'Item', e.target.value)} style={{ flex: '0 0 30px' }} />
                    <input type="number" name={`CubicacionTotal-${index}`} value={row.CubicacionTotal || ''} onChange={e => handleInputChange(index, 'CubicacionTotal', e.target.value)} style={{ flex: '0 0 100px' }} />
                    <input type="number" name={`PorcentajeRepresentativo-${index}`} value={row.PorcentajeRepresentativo || ''} readOnly style={{ flex: '0 0 100px' }} />
                    <input type="text" name={`Partida-${index}`} value={row.Partida || ''} onChange={e => handleInputChange(index, 'Partida', e.target.value)} style={{ flex: '0 0 280px' }} />
                    <input type="text" name={`Tipo-${index}`} value={row.Tipo || ''} onChange={e => handleInputChange(index, 'Tipo', e.target.value)} style={{ flex: '0 0 200px' }} />
                    <input type="text" name={`Unidad-${index}`} value={row.Unidad || ''} onChange={e => handleInputChange(index, 'Unidad', e.target.value)} style={{ flex: '0 0 100px' }} />
                    {row.dynamicColumns && row.dynamicColumns.map((value: number | null, semanaIndex: number) => (


<input
                        key={semanaIndex}
                        type="number"
                        name={`S${semanaIndex + 1}[]`}
                        placeholder={`S${semanaIndex + 1}`}
                        value={value !== null ? value : ''}
                        onChange={e => {
                          const newValue = e.target.value === '' ? null : parseFloat(e.target.value);
                          const newDynamicColumns = [...row.dynamicColumnsReal];
                          newDynamicColumns[semanaIndex] = newValue;
                          handleDynamicInputChange(index, 'dynamicColumnsReal', newDynamicColumns);
                        }}
                        style={{ flex: '0 0 100px' }}
                      />
                    ))}
                  </div>
                ))}
              </div>
              <textarea
                placeholder="Pega aquí los datos copiados desde Excel"
                onPaste={handlePaste1}
                style={{ width: '100%', height: '100px', marginTop: '10px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px' }}
              />
              <div className="summary">
                    <p>Cubicación Total: {summary2.sumaCubicacionTotal.toFixed(2)}</p>
                    {Object.entries(summary2)
                    .filter(([key]) => key !== 'sumaCubicacionTotal')
                    .sort(([a], [b]) => {
                      const aValue = parseInt(a.slice(1));
                      const bValue = parseInt(b.slice(1));
                      return aValue - bValue;
                    })
                    .map(([key, value], index) => {
                      const semana = index + 1;
                      return (
                        <p key={key}>
                          S{semana}: {value.toFixed(2)}%
                        </p>
                      );
                    })}
                  </div>
            </div>
          )}

          {activeTab === 'Resumen' && (
                <div style={tableContainerStyle}>
                  <table style={{
                    width: '100%',
                    borderCollapse: 'collapse',
                    marginTop: '20px',
                    textAlign: 'center'
                  }}>
                    <thead>
                      <tr>
                        <th style={tableHeaderStyle}>Semana</th>
                        {summary3.periodos.map(periodo => (
                          <th key={periodo} style={tableHeaderStyle}>{periodo}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={tableCellStyle}>Viviendas</td>
                        {summary3.viviendasreal.map((valor, index) => (
                          <td key={index} style={tableCellStyle}>{valor.toFixed(2)}%</td>
                        ))}
                      </tr>
                      <tr>
                        <td style={tableCellStyle}>Urbanización</td>
                        {summary3.urbanizacionreal.map((valor, index) => (
                          <td key={index} style={tableCellStyle}>{valor.toFixed(2)}%</td>
                        ))}
                      </tr>
                      <tr style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>
                        <td style={tableCellStyle}>Total Real</td>
                        {summary3.totalreal.map((valor, index) => (
                          <td key={index} style={tableCellStyle}>{valor.toFixed(2)}%</td>
                        ))}
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
);
}

export default FormularioCurvasReal;
