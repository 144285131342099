import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import LogoAnimation from './LogoAnimation';

interface UserData {
  firstname?: string;
  lastname?: string;
  email?: string;
  empresa?: string;
  departamento?: string;
  user_profile?: string;
}

interface InvitationData {
  name: string;
  lastname: string;
  email: string;
  is_admin: boolean;
  user_profile: string;
  is_external: string;
  empresa: string;
  departamento: string;
}

interface SuccessMessageProps {
  message: string;
  userData: UserData;
}

const SuccessMessage: React.FC<SuccessMessageProps> = ({ message, userData }) => {
  const [countdown, setCountdown] = useState(5);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          navigate('/ui');
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [navigate]);

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-xl p-8 max-w-md w-full mx-4 transform transition-all animate-fadeIn">
        <div className="flex flex-col items-center text-center">
          <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mb-4">
            <svg className="w-10 h-10 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
            </svg>
          </div>
          
          <h2 className="text-2xl font-bold text-gray-800 mb-2">¡Registro Exitoso!</h2>
          <p className="text-gray-600 mb-6">{message}</p>
          
          <div className="bg-blue-50 border border-blue-200 rounded-lg p-4 w-full mb-6">
            <h3 className="font-semibold text-blue-800 mb-2">Datos de tu cuenta:</h3>
            <p className="text-blue-700 mb-1"><span className="font-medium">Nombre:</span> {userData?.firstname} {userData?.lastname}</p>
            <p className="text-blue-700 mb-1"><span className="font-medium">Correo:</span> {userData?.email}</p>
            {userData?.empresa && (
              <p className="text-blue-700 mb-1"><span className="font-medium">Empresa:</span> {userData?.empresa}</p>
            )}
            {userData?.departamento && (
              <p className="text-blue-700 mb-1"><span className="font-medium">Departamento:</span> {userData?.departamento}</p>
            )}
            {userData?.user_profile && (
              <p className="text-blue-700"><span className="font-medium">Perfil:</span> {userData?.user_profile === 'biblioteca_normativa' ? 'Biblioteca Normativa' : userData?.user_profile}</p>
            )}
          </div>
          
          <div className="w-full bg-gray-200 rounded-full h-2 mb-4">
            <div 
              className="bg-teal-500 h-2 rounded-full transition-all duration-1000 ease-out" 
              style={{ width: `${(countdown / 5) * 100}%` }}>
            </div>
          </div>
          
          <p className="text-sm text-gray-500">
            Serás redirigido automáticamente en {countdown} segundo{countdown !== 1 ? 's' : ''}
          </p>
        </div>
      </div>
    </div>
  );
};

const Register: React.FC = () => {
  const [firstname, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');
  const [token, setToken] = useState<string | null>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [userProfile, setUserProfile] = useState<string | null>(null);
  const [empresa, setEmpresa] = useState<string>('');
  const [departamento, setDepartamento] = useState<string>('');
  const [isExternal, setIsExternal] = useState<string>('');
  const [userData, setUserData] = useState<UserData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingInvitation, setLoadingInvitation] = useState<boolean>(true);
  const [showAnimation, setShowAnimation] = useState<boolean>(false);
  const [registrationSuccessful, setRegistrationSuccessful] = useState<boolean>(false);

  const navigate = useNavigate();

  // Fetch invitation data when component mounts
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenParam = urlParams.get('token');

    if (tokenParam) {
      setToken(tokenParam);
      fetchInvitationData(tokenParam);
    } else {
      setLoadingInvitation(false);
    }
  }, []);

  // Fetch invitation data from the new endpoint
  const fetchInvitationData = async (token: string) => {
    setLoadingInvitation(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/login/get_invitation_data.php?token=${token}`);
      const data = await response.json(); 
      // alert(JSON.stringify(data))
      if (data.success) {
        const invitationData: InvitationData = data.data;
        
        // Update all fields with the invitation data
        setName(invitationData.name);
        setLastname(invitationData.lastname);
        setEmail(invitationData.email);
        setIsAdmin(invitationData.is_admin);
        setUserProfile(invitationData.user_profile);
        setIsExternal(invitationData.is_external);
        setEmpresa(invitationData.empresa);
        setDepartamento(invitationData.departamento);
      } else {
        setError('Invitación no válida o expirada.');
      }
    } catch (error) {
      console.error('Error fetching invitation data:', error);
      setError('Error al cargar los datos de la invitación.');
    } finally {
      setLoadingInvitation(false);
    }
  };

  // Function to handle auto login after registration
  const handleAutoLogin = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/login/login.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          username: email, // Using email as username
          password: password 
        })
      });

      const responseText = await response.text();
      console.log('Respuesta del login automático:', responseText);
      
      let data;
      try {
        data = JSON.parse(responseText);
      } catch (e) {
        console.error('La respuesta del servidor no es un JSON válido.');
        return false;
      }

      if (data.success) {
        console.log('Login automático exitoso');
        
        // Almacenar el token JWT
        if (data.token) {
          localStorage.setItem('authToken', data.token);
        }

        // Almacenar datos del usuario
        sessionStorage.setItem('userId', data.id);
        sessionStorage.setItem('firstName', data.firstname);
        sessionStorage.setItem('lastName', data.lastname);
        sessionStorage.setItem('email', data.email);
        if (data.title) sessionStorage.setItem('title', data.title);
        sessionStorage.setItem('isAdmin', data.is_admin.toString());
        sessionStorage.setItem('userProfile', data.user_profile);
        
        setShowAnimation(true);
        return true;
      } else {
        console.error('Error en login automático:', data.error);
        return false;
      }
    } catch (error) {
      console.error('Error en el login automático:', error);
      return false;
    }
  };

  const handleRegister = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  
    if (password !== confirmPassword) {
      toast.error('Las contraseñas no coinciden.');
      return;
    }
    
    setLoading(true);
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/login/register.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          firstname, 
          lastname, 
          email, 
          password, 
          token, 
          is_admin: isAdmin,
          user_profile: userProfile,
          is_external: isExternal,
          empresa: empresa,
          departamento: departamento,
        }),
      });
  
      const responseText = await response.text();
      console.log('Respuesta del servidor (registro):', responseText);
  
      if (responseText.trim() === '') {
        toast.error('La respuesta del servidor está vacía.');
        setLoading(false);
        return;
      }
  
      try {
        const data = JSON.parse(responseText);
  
        if (data.success) {
          toast.success(data.message || 'Registro exitoso.');
          
          // Save user data for success message
          setUserData({
            firstname,
            lastname,
            email,
            empresa,
            departamento,
            user_profile: userProfile || undefined
          });
          
          // Inicio de sesión automático después del registro exitoso
          try {
            const loginResponse = await fetch(`${process.env.REACT_APP_API_URL}/php/login/login.php`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ 
                username: email, // Usando email como nombre de usuario
                password: password 
              })
            });
  
            const loginText = await loginResponse.text();
            console.log('Respuesta del servidor (login automático):', loginText);
            
            const loginData = JSON.parse(loginText);
            
            if (loginData.success) {
              console.log('Login automático exitoso');
              
              // Almacenar el token JWT
              if (loginData.token) {
                localStorage.setItem('authToken', loginData.token);
              }
  
              // Almacenar datos del usuario en sessionStorage
              sessionStorage.setItem('userId', loginData.id);
              sessionStorage.setItem('firstName', loginData.firstname);
              sessionStorage.setItem('lastName', loginData.lastname);
              sessionStorage.setItem('email', loginData.email);
              if (loginData.title) sessionStorage.setItem('title', loginData.title);
              sessionStorage.setItem('isAdmin', loginData.is_admin.toString());
              sessionStorage.setItem('userProfile', loginData.user_profile);
              
              // Show success message with countdown
              setRegistrationSuccessful(true);
              setSuccess('Tu cuenta ha sido creada exitosamente y has iniciado sesión automáticamente.');
              
              // Show logo animation
              setShowAnimation(true);
              
              toast.success('Iniciando sesión automáticamente...');
            } else {
              console.error('Error en login automático:', loginData.error);
              toast.error('No se pudo iniciar sesión automáticamente. Por favor, inicie sesión manualmente.');
            }
          } catch (loginError) {
            console.error('Error en el login automático:', loginError);
            toast.error('Error al procesar el inicio de sesión automático.');
          }
        } else {
          toast.error(data.error || 'Error al registrar el usuario.');
        }
      } catch (jsonError) {
        console.error('Error al parsear JSON:', jsonError);
        toast.error('Error en la respuesta del servidor: ' + responseText);
      }
    } catch (error) {
      console.error('Register failed:', error);
      toast.error(`Ocurrió un error durante el registro. ${error}`);
    } finally {
      setLoading(false);
    }
  };
  
  if (loadingInvitation) {
    return (
      <div className="login-container">
        <div className="login-box">
          <div className="login-logo"></div>
          <div className="flex flex-col items-center justify-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
            <p className="mt-4 text-gray-600">Cargando datos de la invitación...</p>
          </div>
        </div>
        <div className="triangle"></div>
      </div>
    );
  }
  
  // Show success message after successful registration
  if (registrationSuccessful && userData) {
    return <SuccessMessage message={success} userData={userData} />;
  }

  // Show logo animation in full screen if needed
  if (showAnimation) {
    return <LogoAnimation show={true} type="login" />;
  }
  
  return (
    <div className="login-container">
      <div className="login-box">
        <div className="login-logo"></div>
        <form onSubmit={handleRegister}>
          {/* Información de la empresa/departamento si existe */}
          {(empresa || departamento) && (
            <div className="bg-cyan-50 border border-cyan-200 rounded-md p-3 mb-4">
              <h3 className="font-medium text-cyan-800 mb-2">Información organizacional:</h3>
              {empresa && (
                <p className="text-cyan-700 mb-1 text-sm"><span className="font-medium">Empresa:</span> {empresa}</p>
              )}
              {departamento && (
                <p className="text-cyan-700 text-sm"><span className="font-medium">Departamento:</span> {departamento}</p>
              )}
            </div>
          )}
          {userProfile && !isAdmin && (
            <div className="input-container mb-3">
              <div className="bg-cyan-50 border border-cyan-200 rounded p-2 text-cyan-800">
                <i className="fas fa-user-tag mr-2"></i>
                Perfil: {userProfile === 'biblioteca_normativa' ? 'Biblioteca Normativa' : userProfile}
              </div>
            </div>
          )}
          <div className="input-container">
            <i className="input-icon fas fa-user"></i>
            <input
              type="text"
              placeholder="Nombre"
              value={firstname}
              onChange={(e) => setName(e.target.value)}
              className="login-input"
              readOnly={token !== null}
            />
          </div>
          <div className="input-container">
            <i className="input-icon fas fa-user"></i>
            <input
              type="text"
              placeholder="Apellido"
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
              className="login-input"
              readOnly={token !== null}
            />
          </div>
 
          <div className="flex flex-col gap-2 input-container">
            <div className="relative">
              <i className="input-icon fas fa-envelope absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"></i>
              <input
                type="email"
                placeholder="Correo electrónico"
                value={email}
                readOnly
                className="login-input bg-gray-100 cursor-default pl-10"
              />
            </div>

            <span className="bg-yellow-100 text-yellow-600 text-xs rounded-md px-3 py-1 inline-block shadow-sm">
              Su correo electrónico será su nombre de usuario para iniciar sesión en SIMAGI
            </span>
          </div>
   
          <div className="input-container">
            <i className="input-icon fas fa-lock"></i>
            <input
              type="password"
              placeholder="Contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="login-input"
            />
          </div>
          <div className="input-container">
            <i className="input-icon fas fa-lock"></i>
            <input
              type="password"
              placeholder="Confirmar contraseña"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="login-input"
            />
          </div>
          
          <button type="submit" className='submit-button mb-5' disabled={loading}>
            {loading ? 'Registrando...' : 'Registrarse'}
          </button>
        </form>
      </div>
      <div className="triangle"></div>
    </div>
  );
};

export default Register;