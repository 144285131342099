import React, { useState, useEffect, useRef } from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/Tabs";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { Separator } from "../ui/separator";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import { Label } from "../ui/label";
import { Badge } from "../ui/badge";
import { Switch } from "../ui/switch";
import { motion, AnimatePresence } from "framer-motion";

import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import { 
  User, 
  KeyRound, 
  Image, 
  Bell,  
  MailCheck, 
  Shield, 
  Eye, 
  EyeOff,
  Save,
  Loader2,
  Camera,
  CheckCircle,
  ChevronLeft,
  X,
  Calendar,
  Clock,
  Building,
  Users
} from "lucide-react";
import toast from 'react-hot-toast';

interface UserProfileData {
  id: number;
  firstname: string; 
  lastname: string;
  email: string;
  cargo: string;
  departamento: string;
  empresa: string;
  profile_image?: string;
  is_admin: boolean;
  user_profile?: string;
  notifications_enabled?: boolean;
  created_at?: string;
  last_login?: string;
}

interface UserProfileProps {
  isOpen: boolean;
  onClose: () => void;
}

const UserProfile: React.FC<UserProfileProps> = ({ isOpen, onClose }) => {
  // Estados para los datos del usuario
  const [userData, setUserData] = useState<UserProfileData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  
  // Estados para actualización de contraseña
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [showCurrentPassword, setShowCurrentPassword] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [passwordLoading, setPasswordLoading] = useState<boolean>(false);
  
  // Estados para actualización de datos personales
  const [editedUser, setEditedUser] = useState<Partial<UserProfileData> | null>(null);
  
  // Estados para imagen de perfil
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [uploadingImage, setUploadingImage] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  
  // Estado para notificaciones
  const [notificationsEnabled, setNotificationsEnabled] = useState<boolean>(false);
  
  const departamentos = ['Finanzas', 'Arquitectura', 'Contabilidad', 'Recursos Humanos', 'IT'];

  const storedFirstName = sessionStorage.getItem('firstName');
  const storedTitle = sessionStorage.getItem('title');
  const storedLastName = sessionStorage.getItem('lastName');
  const storedEmail = sessionStorage.getItem('email');
  
  // Cargar datos del usuario cuando se abre el modal
  useEffect(() => {
    if (isOpen) {
      fetchUserData();
    }
  }, [isOpen]);

  // Método fetchUserData modificado para mejorar la obtención de datos del usuario
  const fetchUserData = async () => {
    setLoading(true);
    try {
      // Obtener el ID del usuario de sessionStorage
      const userId = sessionStorage.getItem('userId');
      
      if (!userId) {
        setError('No se pudo identificar al usuario. Por favor, inicie sesión nuevamente.');
        setLoading(false);
        return;
      }
      
      // Incluir la información de userId en la URL y también como parámetro de consulta
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/profile/get_user_profile.php?userId=${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // Incluir token de autorización si existe
          ...(localStorage.getItem('authToken') 
            ? { 'Authorization': `Bearer ${localStorage.getItem('authToken')}` } 
            : {})
        },
        credentials: 'include', // Importante para enviar cookies de sesión
      });
  
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
  
      const data = await response.json();
      
      // Depuración
      console.log('Respuesta del servidor:', data);
      
      if (data.success) {
        setUserData(data.user);
        setEditedUser(data.user);
        setNotificationsEnabled(data.user.notifications_enabled || false);
        
        // Actualizar sessionStorage con la información más reciente si es necesario
        sessionStorage.setItem('firstName', data.user.firstname);
        sessionStorage.setItem('lastName', data.user.lastname);
        sessionStorage.setItem('email', data.user.email);
        if (data.user.title) {
          sessionStorage.setItem('title', data.user.title);
        }
        
      } else {
        setError(data.error || 'Error al cargar los datos del usuario');
      }
    } catch (error) {
      console.error('Error al obtener datos del usuario:', error);
      setError((error instanceof Error) ? error.message : 'Error desconocido');
      toast.error('Error al cargar el perfil');
    } finally {
      setLoading(false);
    }
  };
  
  // Manejar cambio de imagen de perfil
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setImageFile(file);
      
      // Crear preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };


  const updateLastLogin = async () => {
    try {
      const userId = sessionStorage.getItem('userId');
      if (!userId) return;
  
      await fetch(`${process.env.REACT_APP_API_URL}/php/pages/profile/update_last_login.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId }),
        credentials: 'include',
      });
      
      // No necesitamos hacer nada con la respuesta
    } catch (error) {
      console.error('Error al actualizar último acceso:', error);
      // No mostramos un error al usuario ya que esto no es crítico
    }
  };

  // Subir imagen de perfil
  const handleImageUpload = async () => {
    if (!imageFile) return;
    
    setUploadingImage(true);
    const formData = new FormData();
    formData.append('profile_image', imageFile);
    
    // Añadir el userId al formData para autenticación adicional
    const userId = sessionStorage.getItem('userId');
    if (userId) {
      formData.append('userId', userId);
    }
   
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/profile/update_profile_image.php`, {
        method: 'POST',
        body: formData,
        credentials: 'include',
      });
      
      const data = await response.json();
      
      if (data.success) {
        toast.success('Imagen de perfil actualizada');
        setUserData(prev => prev ? {...prev, profile_image: data.image_url} : null);
        setEditedUser(prev => prev ? {...prev, profile_image: data.image_url} : null);
        
        // Limpiar estados de imagen
        setImageFile(null);
        setImagePreview(null);
      } else {
        toast.error(data.error || 'Error al actualizar la imagen');
      }
    } catch (error) {
      console.error('Error al subir imagen:', error);
      toast.error('Error al subir la imagen');
    } finally {
      setUploadingImage(false);
    }
  };
  
  // Manejar cambio de contraseña
  const handlePasswordChange = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (newPassword !== confirmPassword) {
      toast.error('Las contraseñas no coinciden');
      return;
    }
    
    if (newPassword.length < 8) {
      toast.error('La contraseña debe tener al menos 8 caracteres');
      return;
    }
    
    setPasswordLoading(true);
    
    try {
      const userId = sessionStorage.getItem('userId');
      
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/profile/update_password.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId,
          current_password: currentPassword,
          new_password: newPassword
        }),
        credentials: 'include',
      });
      
      const data = await response.json();
      
      if (data.success) {
        toast.success('Contraseña actualizada correctamente');
        // Limpiar campos de contraseña
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
      } else {
        toast.error(data.error || 'Error al actualizar la contraseña');
      }
    } catch (error) {
      console.error('Error al cambiar contraseña:', error);
      toast.error('Error en la solicitud');
    } finally {
      setPasswordLoading(false);
    }
  };
  
  // Manejar actualización de perfil
  const handleProfileUpdate = async () => {
    if (!editedUser) return;
    
    // Validaciones básicas
    if (!editedUser.firstname?.trim() || !editedUser.lastname?.trim() || !editedUser.email?.trim()) {
      toast.error('Todos los campos obligatorios deben ser completados');
      return;
    }
    
    setSaving(true);
    
    try {
      const userId = sessionStorage.getItem('userId');
      
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/profile/update_profile.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId,
          firstname: editedUser.firstname,
          lastname: editedUser.lastname,
          email: editedUser.email,
          cargo: editedUser.cargo,
          empresa: editedUser.empresa,
          departamento: editedUser.departamento,
          notifications_enabled: notificationsEnabled
        }),
        
        credentials: 'include',
      });
      
      const data = await response.json();
      
      if (data.success) {
        // Actualizar datos del usuario en el estado y sessionStorage
        setUserData({
          ...userData!,
          ...editedUser,
          notifications_enabled: notificationsEnabled
        });
        
        sessionStorage.setItem('firstName', editedUser.firstname || '');
        sessionStorage.setItem('lastName', editedUser.lastname || '');
        sessionStorage.setItem('email', editedUser.email || '');
        // if (editedUser.title) {
        //   sessionStorage.setItem('title', editedUser.title);
        // }
        
        toast.success('Perfil actualizado correctamente');
      } else {
        toast.error(data.error || 'Error al actualizar el perfil');
      }
    } catch (error) { 
      console.error('Error al actualizar perfil:', error);
      toast.error('Error en la solicitud');
    } finally {
      setSaving(false);
    }
  };

  // Eliminando el manejador handleTabChange que causa errores

  if (!isOpen) return null;
  
  return (
    <div className="fixed inset-0 bg-black/70 z-[1000] backdrop-blur-sm flex justify-center items-center overflow-y-auto">
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.95 }}
          transition={{ type: "spring", damping: 20, stiffness: 300 }}
          className="bg-white dark:bg-gray-900 shadow-2xl rounded-xl max-w-6xl mx-auto max-h-[90vh] overflow-hidden relative"
        >
          <div className="sticky top-0  bg-white dark:bg-gray-900 p-6 border-b dark:border-gray-800 flex justify-between items-center">
            <div className="flex items-center">
              <button 
                onClick={onClose}
                className="p-2 mr-3 rounded-full hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors"
              >
                <ChevronLeft className="h-6 w-6 text-gray-600 dark:text-gray-400" />
              </button>
              <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Configuración de Perfil</h1>
            </div>
            
            <button 
              onClick={onClose}
              className="p-2 rounded-full hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors"
            >
              <X className="h-6 w-6 text-gray-600 dark:text-gray-400" />
            </button>
          </div>
          
          <div className="p-6 overflow-y-auto max-h-[calc(90vh-80px)]">
            {loading ? (
              <div className="flex flex-col items-center justify-center py-12 gap-4">
                <Loader2 className="h-12 w-12 animate-spin text-primary" />
                <p className="text-gray-500 dark:text-gray-400 animate-pulse">Cargando tu perfil...</p>
              </div>
            ) : error ? (
              <div className="p-6 text-center">
                <div className="p-6 bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-800 rounded-xl text-red-700 dark:text-red-400 max-w-xl mx-auto shadow-sm">
                  <h2 className="text-xl font-bold mb-3">Error</h2>
                  <p className="mb-4">{error}</p>
                  <button 
                    className="mt-2"
                    onClick={fetchUserData}
                  >
                    Reintentar
                  </button>
                </div>
              </div>
            ) : userData && editedUser ? (
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
                {/* Tarjeta de perfil */}
                <Card className="bg-white dark:bg-gray-900 shadow-md lg:col-span-1 border dark:border-gray-800 overflow-hidden">
                  <CardHeader className="pb-2 bg-gradient-to-r from-blue-50 to-indigo-50 dark:from-blue-900/20 dark:to-indigo-900/20">
                    <CardTitle className="text-xl">Tu Perfil</CardTitle>
                    <CardDescription>Información de tu cuenta</CardDescription>
                  </CardHeader>
                  <CardContent className="flex flex-col items-center space-y-6 pt-6">
                    <div className="relative group">
                    <Avatar className="h-36 w-36 border-4 border-white dark:border-gray-800 shadow-lg ring-2 ring-offset-2 ring-primary/30">
  {(imagePreview || userData.profile_image) ? (
    <AvatarImage 
      src={imagePreview || userData.profile_image || ''} 
      alt={userData.email} 
    />
  ) : (
    <AvatarFallback className="bg-gradient-to-br from-primary to-indigo-600 text-primary-foreground text-4xl">
      {userData.firstname.charAt(0)}{userData.lastname.charAt(0)}
    </AvatarFallback>
  )}
</Avatar>
                      <button 
                        className="absolute bottom-1 right-1 bg-cyan-500 text-white rounded-full p-2.5 shadow-lg transition-transform group-hover:scale-110"
                        onClick={() => fileInputRef.current?.click()}
                      >
                        <Camera className="h-5 w-5" />
                      </button>
                      <input 
                        type="file" 
                        ref={fileInputRef} 
                        className="hidden" 
                        accept="image/*"
                        onChange={handleImageChange} 
                      />
                    </div>
                    
                    {imagePreview && (
                      <button 
                        className="w-full bg-gradient-to-r from-teal-500 to-cyan-600 text-white hover:transition-all shadow-md" 
                        onClick={handleImageUpload}
                        disabled={uploadingImage}
                      >
                        {uploadingImage ? (
                          <>
                            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                            Subiendo...
                          </>
                        ) : (
                          'Guardar Imagen'
                        )}
                      </button>
                    )}
                    
                    <div className="text-center space-y-2 w-full">
                      <h3 className="text-2xl font-bold text-gray-900 dark:text-white">
                        {userData.firstname} {userData.lastname}
                      </h3>
                      <p className="text-sm text-muted-foreground">{userData.email}</p>
                      <Badge className={`${userData.is_admin ? "bg-gradient-to-r from-teal-500 to-cyan-600" : "bg-gradient-to-r from-cyan-500 to-blue-500"} text-white px-3 py-1 rounded-full font-medium`}>
                        {userData.is_admin ? 'Administrador' : (userData.user_profile === 'biblioteca_normativa' ? 'Biblioteca Normativa' : 'Usuario Estándar')}
                      </Badge>
                    </div>
                    
                    <Separator className="my-2" />
                    
                    <div className="w-full space-y-4">
                      <div className="flex items-center space-x-3 bg-gray-50 dark:bg-gray-800/50 p-3 rounded-lg">
                        <Building className="h-5 w-5 text-gray-500 dark:text-gray-400" />
                        <div className="flex-1">
                          <p className="text-sm text-muted-foreground">Departamento</p>
                          <p className="font-medium text-gray-900 dark:text-white">{userData.departamento || 'No asignado'}</p>
                        </div>
                      </div>
                      <div className="flex items-center space-x-3 bg-gray-50 dark:bg-gray-800/50 p-3 rounded-lg">
  <Building className="h-5 w-5 text-gray-500 dark:text-gray-400" />
  <div className="flex-1">
    <p className="text-sm text-muted-foreground">Empresa</p>
    <p className="font-medium text-gray-900 dark:text-white">{userData.empresa || 'No asignada'}</p>
  </div>
</div>
                      
                      {/* <div className="flex items-center space-x-3 bg-gray-50 dark:bg-gray-800/50 p-3 rounded-lg">
                        <Users className="h-5 w-5 text-gray-500 dark:text-gray-400" />
                        <div className="flex-1">
                          <p className="text-sm text-muted-foreground">Género</p>
                          <p className="font-medium text-gray-900 dark:text-white">
                            {userData.gender === 'M' ? 'Masculino' : 
                             userData.gender === 'F' ? 'Femenino' : 
                             userData.gender === 'O' ? 'Otro' : 'No especificado'}
                          </p>
                        </div>
                      </div> */}
                      
                      {userData.created_at && (
                        <div className="flex items-center space-x-3 bg-gray-50 dark:bg-gray-800/50 p-3 rounded-lg">
                          <Calendar className="h-5 w-5 text-gray-500 dark:text-gray-400" />
                          <div className="flex-1">
                            <p className="text-sm text-muted-foreground">Cuenta creada</p>
                            <p className="font-medium text-gray-900 dark:text-white">
                              {new Date(userData.created_at).toLocaleDateString('es-ES', {
                                day: 'numeric',
                                month: 'long',
                                year: 'numeric'
                              })}
                            </p>
                          </div>
                        </div>
                      )}
                      
                      {userData.last_login && (
                        <div className="flex items-center space-x-3 bg-gray-50 dark:bg-gray-800/50 p-3 rounded-lg">
                          <Clock className="h-5 w-5 text-gray-500 dark:text-gray-400" />
                          <div className="flex-1">
                            <p className="text-sm text-muted-foreground">Último acceso</p>
                            <p className="font-medium text-gray-900 dark:text-white">
                              {new Date(userData.last_login).toLocaleDateString('es-ES', {
                                day: 'numeric',
                                month: 'long', 
                                year: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit'
                              })}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </CardContent>
                </Card>
                
                {/* Tabs de configuración */}
                <div className="lg:col-span-2">
                  <Tabs 
                    defaultValue="general" 
                    className="bg-white dark:bg-gray-900 shadow-md rounded-xl border dark:border-gray-800 overflow-hidden"
                  >
   <TabsList className="grid w-full grid-cols-3 border-b dark:border-gray-800 rounded-t-xl rounded-b-none p-0 h-auto bg-gradient-to-r from-cyan-50 to-cyan-50 dark:from-gray-800/50 dark:to-cyan-900/20">
  <TabsTrigger
    value="general" 
    className="rounded-none transition-all duration-300 py-4"
  >
    <div className="flex px-5 items-center">
      <User className="h-4 w-4 mr-2" />
      <span>General</span>
    </div>
  </TabsTrigger>
  <TabsTrigger
    value="security" 
    className="rounded-none transition-all duration-300 py-4"
  >
    <div className="flex px-5 items-center">
      <KeyRound className="h-4 w-4 mr-2" />
      <span>Seguridad</span>
    </div>
  </TabsTrigger>
  <TabsTrigger
    value="notifications" 
    className="rounded-none transition-all duration-300 py-4"
  >
    <div className="flex px-5 items-center">
      <Bell className="h-4 w-4 mr-2" />
      <span>Notificaciones</span>
    </div>
  </TabsTrigger>
</TabsList>

                    {/* Pestaña General */}
                    <TabsContent value="general" className="p-6 focus:outline-none">
                      <motion.div
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.3 }}
                      >
                        <h3 className="text-xl font-bold mb-6 text-gray-900 dark:text-white flex items-center">
                          <User className="h-5 w-5 mr-2 text-primary" />
                          Información personal
                        </h3>
                        <div className="space-y-6">
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div className="space-y-2">
                              <Label htmlFor="firstname">Nombre</Label>
                              <Input 
                                id="firstname"
                                value={editedUser.firstname}
                                onChange={(e) => setEditedUser({...editedUser, firstname: e.target.value})}
                                className="h-11 transition-all focus-visible:ring-primary/30 focus-visible:ring-offset-2"
                              />
                            </div>
                            
                            <div className="space-y-2">
                              <Label htmlFor="lastname">Apellido</Label>
                              <Input 
                                id="lastname"
                                value={editedUser.lastname}
                                onChange={(e) => setEditedUser({...editedUser, lastname: e.target.value})}
                                className="h-11 transition-all focus-visible:ring-primary/30 focus-visible:ring-offset-2"
                              />
                            </div>
                          </div>
                          
                          <div className="space-y-2">
                            <Label htmlFor="email">Correo electrónico</Label>
                            <Input 
                              id="email"
                              type="email"
                              value={editedUser.email}
                              onChange={(e) => setEditedUser({...editedUser, email: e.target.value})}
                              className="h-11 transition-all focus-visible:ring-primary/30 focus-visible:ring-offset-2"
                            />
                          </div>
                          
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 z-[200]">
                          <div className="space-y-2">
                              <Label htmlFor="lastname">Cargo</Label>
                              <Input 
                                id="empresa"
                                value={editedUser.cargo}
                                onChange={(e) => setEditedUser({...editedUser, cargo: e.target.value})}
                                className="h-11 transition-all focus-visible:ring-primary/30 focus-visible:ring-offset-2"
                              />
                            </div>
                          <div className="">
  <label htmlFor="department" className="text-sm font-medium text-gray-700 dark:text-gray-200">
    Departamento
  </label>
  <select
    id="department"
    value={editedUser.departamento || ''}
    onChange={(e) => setEditedUser({ ...editedUser, departamento: e.target.value })}
    className="h-11 w-full border border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-900 text-gray-900 dark:text-white rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:border-cyan-500 transition-all"
  >
    <option value="" disabled>Selecciona un departamento</option>
    {departamentos.map((depto) => (
      <option key={depto} value={depto}>
        {depto}
      </option>
    ))}
  </select>
</div>
<div className="space-y-2">
                              <Label htmlFor="lastname">Empresa</Label>
                              <Input 
                                id="empresa"
                                value={editedUser.empresa}
                                onChange={(e) => setEditedUser({...editedUser, empresa: e.target.value})}
                                className="h-11 transition-all focus-visible:ring-primary/30 focus-visible:ring-offset-2"
                              />
                            </div>

{/*                             
<div className="space-y-2">
  <label htmlFor="gender" className="text-sm font-medium text-gray-700 dark:text-gray-200">
    Género
  </label>
  <select
    id="gender"
    value={editedUser.gender || ''}
    onChange={(e) => setEditedUser({ ...editedUser, gender: e.target.value })}
    className="h-11 w-full border border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-900 text-gray-900 dark:text-white rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:border-cyan-500 transition-all"
  >
    <option value="" disabled>Selecciona tu género</option>
    <option value="M">Masculino</option>
    <option value="F">Femenino</option>
    <option value="O">Otro</option>
  </select>
</div> */}

                          </div>
                        </div>
                      </motion.div>
                      
                      <div className="pt-8">
                        <button 
                          className="w-full p-3 rounded text-white flex md:w-auto bg-cyan-500 transition-all shadow-md"
                          onClick={handleProfileUpdate}
                          disabled={saving}
                        >
                          {saving ? (
                            <>
                              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                              Guardando...
                            </>
                          ) : (
                            <>
                              <Save className="mr-2 h-4 w-4" />
                              Guardar cambios
                            </>
                          )}
                        </button>
                      </div>
                    </TabsContent>
                    
                    {/* Pestaña Seguridad */}
                    <TabsContent value="security" className="p-6 focus:outline-none">
                      <motion.div
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.3 }}
                      >
                        <h3 className="text-xl font-bold mb-6 text-gray-900 dark:text-white flex items-center">
                          <KeyRound className="h-5 w-5 mr-2 text-primary" />
                          Cambiar contraseña
                        </h3>
                        <form onSubmit={handlePasswordChange} className="space-y-6">
                          <div className="space-y-2">
                            <Label htmlFor="current-password">Contraseña actual</Label>
                            <div className="relative">
                              <Input 
                                id="current-password"
                                type={showCurrentPassword ? "text" : "password"}
                                value={currentPassword}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                                required
                                className="h-11 pr-10 transition-all focus-visible:ring-primary/30 focus-visible:ring-offset-2"
                              />
                              <button 
                                type="button"
                                className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                                onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                              >
                                {showCurrentPassword ? <EyeOff className="h-5 w-5" /> : <Eye className="h-5 w-5" />}
                              </button>
                            </div>
                          </div>
                          
                          <div className="space-y-2">
                            <Label htmlFor="new-password">Nueva contraseña</Label>
                            <div className="relative">
                              <Input 
                                id="new-password"
                                type={showNewPassword ? "text" : "password"}
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                required
                                minLength={8}
                                className="h-11 pr-10 transition-all focus-visible:ring-primary/30 focus-visible:ring-offset-2"
                              />
                              <button 
                                type="button"
                                className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                                onClick={() => setShowNewPassword(!showNewPassword)}
                              >
                                {showNewPassword ? <EyeOff className="h-5 w-5" /> : <Eye className="h-5 w-5" />}
                              </button>
                            </div>
                            <p className="text-xs text-muted-foreground mt-1">La contraseña debe tener al menos 8 caracteres</p>
                          </div>
                          
                          <div className="space-y-2">
                            <Label htmlFor="confirm-password">Confirmar contraseña</Label>
                            <Input 
                              id="confirm-password"
                              type="password"
                              value={confirmPassword}
                              onChange={(e) => setConfirmPassword(e.target.value)}
                              required
                              className="h-11 transition-all focus-visible:ring-primary/30 focus-visible:ring-offset-2"
                            />
                          </div>
                          
                          <div className="pt-4">
                       
                        <button 
                          className="w-full p-3 rounded text-white flex md:w-auto bg-cyan-500 transition-all shadow-md"
                          onClick={handleProfileUpdate}
                          type="submit"  

                          disabled={passwordLoading}
                        >
                              {passwordLoading ? (
                                <>
                                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                  Actualizando...
                                </>
                              ) : (
                                <>
                                  <Shield className="mr-2 h-4 w-4" />
                                  Cambiar contraseña
                                </>
                              )}
                            </button>
                          </div>
                        </form>
                        
                        <Separator className="my-8" />
                        
                        <div className="space-y-6">
                          <h3 className="text-xl font-bold text-gray-900 dark:text-white flex items-center">
                            <Shield className="h-5 w-5 mr-2 text-primary" />
                            Seguridad de la cuenta
                          </h3>
                          <div className="flex flex-col space-y-4">
                            <div className="flex items-center justify-between bg-gray-50 dark:bg-gray-800/50 p-4 rounded-xl border border-gray-100 dark:border-gray-800">
                              <div>
                                <h4 className="font-medium text-gray-900 dark:text-white">Sesión actual</h4>
                                <p className="text-sm text-muted-foreground mt-1">
                                  Has iniciado sesión desde: {navigator.userAgent}
                                </p>
                              </div>
                              <div className="bg-cyan-100 dark:bg-cyan-900/30 p-2 rounded-full">
                                <CheckCircle className="h-5 w-5 text-cyan-600 dark:text-cyan-500" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </motion.div>
                    </TabsContent>
                    
                    {/* Pestaña Notificaciones */}
                    <TabsContent value="notifications" className="p-6 focus:outline-none">
                      <motion.div
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.3 }}
                      >
                        <h3 className="text-xl font-bold mb-6 text-gray-900 dark:text-white flex items-center">
                          <Bell className="h-5 w-5 mr-2 text-primary" />
                          Preferencias de notificaciones
                        </h3>
                        <div className="space-y-6">
                          <div className="flex items-center justify-between bg-gray-50 dark:bg-gray-800/50 p-4 rounded-xl border border-gray-100 dark:border-gray-800">
                            <div className="space-y-1">
                              <h4 className="font-medium text-gray-900 dark:text-white">Notificaciones por correo</h4>
                              <p className="text-sm text-muted-foreground">
                                Recibir notificaciones por correo electrónico
                              </p>
                            </div>
                            <Switch 
                              checked={notificationsEnabled} 
                              onCheckedChange={setNotificationsEnabled}
                            />
                          </div>
                          
                          <div className="flex items-center justify-between bg-gray-50 dark:bg-gray-800/50 p-4 rounded-xl border border-gray-100 dark:border-gray-800">
                            <div className="space-y-1">
                              <h4 className="font-medium text-gray-900 dark:text-white">Actualizaciones del sistema</h4>
                              <p className="text-sm text-muted-foreground">
                                Recibir notificaciones sobre actualizaciones importantes
                              </p>
                            </div>
                            <Switch 
                              checked={notificationsEnabled} 
                              onCheckedChange={setNotificationsEnabled} 
                              disabled={!notificationsEnabled}
                            />
                          </div>
                          
                          <div className="pt-4">
                         <button 
                            className="w-full p-3 rounded text-white flex md:w-auto bg-cyan-500 transition-all shadow-md"
                            onClick={handleProfileUpdate}
                            disabled={saving}
                          >
                              {saving ? (
                                <>
                                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                  Guardando...
                                </>
                              ) : (
                                <>
                                  <MailCheck className="mr-2 h-4 w-4" />
                                  Guardar preferencias
                                </>
                              )}
                            </button>
                          </div>
                        </div>
                      </motion.div>
                    </TabsContent>
                  </Tabs>
                </div>
              </div>
            ) : null}
          </div>
          
        
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default UserProfile;