import React, { useState, useRef, useEffect } from 'react';
import { ChevronDown, ChevronLeft, ChevronRight, Search, Bell, Eye, User, Home, Menu, X, Minimize2, Settings, HelpCircle, Info, LogOut, Database, BookOpen, Calendar, CheckCircle, FileText, AlertCircle, FileCode, Layers, EyeOff } from 'lucide-react';
import logoImage from '../img/logo3.png';
import bgImage from '../img/background2.png';
import SIMAGICarousel from './SIMAGICarousel';
import { VerticalButtonsWithModal, Modal, ModuleItem } from './VerticalButtonsWithModal'; 
import { motion, AnimatePresence } from "framer-motion";
import ContactForm from './ContactForm';
import HomeModalContent from './HomeModalContent'; 
import { MdHideImage, MdViewCarousel } from 'react-icons/md';
import GearLoader from '../components/GearLoader'

const SimagiInterface = () => {
  // Basic UI state
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [isChatMinimized, setIsChatMinimized] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState<string | null>(null);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [activeSection, setActiveSection] = useState({ main: 'Inicio', sub: '' });
  const [isSpinning, setIsSpinning] = useState(false);
  const [contentKey, setContentKey] = useState(0);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [activeModal, setActiveModal] = useState<string | null>(null);
  const [isContactFormOpen, setIsContactFormOpen] = useState(false);
 // NUEVOS ESTADOS para funcionalidades solicitadas
 const [expandedMenus, setExpandedMenus] = useState(['funcionalidades']); // Por defecto expandimos "funcionalidades"
 const [isCarouselVisible, setIsCarouselVisible] = useState(true); // Control de visibilidad del carrusel
 const [isMobileView, setIsMobileView] = useState(false); // Detector de vista móvil
 const [isLoading, setIsLoading] = useState(true);

 
  // Refs
  const userMenuRef = useRef<HTMLDivElement>(null);
  const notificationsRef = useRef<HTMLDivElement>(null);
  const chatRef = useRef<HTMLDivElement>(null);
  



// Add this useEffect to handle clicks outside the user menu
useEffect(() => {
  function handleClickOutside(event: MouseEvent) {
    const target = event.target as Element; // Convertimos el target a tipo Element

    if (userMenuRef.current && !userMenuRef.current.contains(event.target as Node) &&
        !target.closest('svg[class*="User"]')) {
      setIsUserMenuOpen(false);
    }

    if (notificationsRef.current && !notificationsRef.current.contains(event.target as Node) &&
        !target.closest('svg[class*="Bell"]')) {
      setIsNotificationsOpen(false);
    }

    if (chatRef.current && !chatRef.current.contains(event.target as Node) &&
        !target.closest('svg[class*="Eye"]')) {
      setIsChatOpen(false);
    }
  }

  document.addEventListener('mousedown', handleClickOutside);

  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, []);


// useEffect para mostrar el loader por 3 segundos
useEffect(() => {
  const timer = setTimeout(() => {
    setIsLoading(false);
  }, 1000);

  return () => clearTimeout(timer);
}, []);


   // Detección de vista móvil
   useEffect(() => {
    const checkMobileView = () => {
      setIsMobileView(window.innerWidth < 768);
    };
    
    // Comprobar al cargar y al cambiar de tamaño
    checkMobileView();
    window.addEventListener('resize', checkMobileView);
    
    return () => {
      window.removeEventListener('resize', checkMobileView);
    };
  }, []);


  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <GearLoader />
      </div>
    );
  }
  
  interface EnhancedModalProps {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    content: React.ReactNode | string;
    icon?: React.ReactNode; // Hacemos que icon sea opcional
  }


  const CarouselIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="h-5 w-5 text-gray-600"
    >
      <rect x="3" y="5" width="18" height="14" rx="2" />
      <line x1="3" y1="10" x2="21" y2="10" />
      <circle cx="7" cy="15" r="1.5" />
      <circle cx="12" cy="15" r="1.5" />
      <circle cx="17" cy="15" r="1.5" />
    </svg>
  );

  


  const CarouselOffIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="h-5 w-5 text-gray-600"
    >
      <rect x="3" y="5" width="18" height="14" rx="2" />
      <line x1="3" y1="10" x2="21" y2="10" />
      <circle cx="7" cy="15" r="1.5" />
      <circle cx="12" cy="15" r="1.5" />
      <circle cx="17" cy="15" r="1.5" />
      <line x1="2" y1="2" x2="22" y2="22" stroke="currentColor" strokeWidth="2" />
    </svg>
  );
  
  // Componente para el botón flotante de control de visibilidad
const VisibilityControls = () => (
  <div className="fixed bottom-4 left-4 z-[1000] flex flex-col gap-2">
    {/* Botón para mostrar/ocultar carrusel */}
    <button 
  onClick={() => setIsCarouselVisible(!isCarouselVisible)}
  className="flex items-center justify-center p-3 bg-white rounded-full shadow-lg hover:bg-gray-100 transition-all duration-300"
  title={isCarouselVisible ? "Ocultar carrusel" : "Mostrar carrusel"}
>
  {isCarouselVisible ? <CarouselOffIcon /> : <CarouselIcon />}
</button>


   
  </div>
);

  const EnhancedModal : React.FC<EnhancedModalProps> = ({ isOpen, onClose, title, content, icon }) => {
    if (!isOpen) return null;
  
    return (
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[1000]"
          onClick={onClose}
        >
          <motion.div
            initial={{ scale: 0.9, opacity: 0, y: 20 }}
            animate={{ scale: 1, opacity: 1, y: 0 }}
            exit={{ scale: 0.9, opacity: 0, y: 20 }}
            transition={{ type: 'spring', damping: 25, stiffness: 300 }}
            className="bg-white rounded-2xl shadow-2xl max-w-2xl w-full m-4 overflow-hidden"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="bg-gradient-to-r from-cyan-600 to-teal-500 p-6 flex items-center">
              <div className="bg-white p-3 rounded-full mr-4">
                {icon || <Info className="h-6 w-6 text-cyan-600" />}
              </div>
              <h2 className="text-2xl font-bold text-white">{title}</h2>
              <button
                onClick={onClose}
                className="ml-auto bg-white bg-opacity-20 hover:bg-opacity-30 rounded-full p-2 text-white transition-all duration-200"
              >
                <X className="h-5 w-5" />
              </button>
            </div>
            
            <div className="p-6">
              <div className="prose max-w-none">
                {typeof content === 'string' ? (
                  content.split('\n\n').map((paragraph, index) => (
                    <p key={index} className="mb-4 text-gray-700 leading-relaxed">
                      {paragraph}
                    </p>
                  ))
                ) : (
                  content
                )}
              </div>
              
              <div className="mt-6 flex justify-end">
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={onClose}
                  className="px-6 py-2 bg-gradient-to-r from-cyan-500 to-teal-500 text-white font-medium rounded-lg shadow-md hover:shadow-lg transition-all duration-200"
                >
                  Entendido
                </motion.button>
              </div>
            </div>
          </motion.div>
        </motion.div>
      </AnimatePresence>
    );
  };
  

  const closeModal = () => {
    setActiveModal(null);
  };

  const openModal = (id: string) => {
    setActiveModal(id);
  };


  // Mock user data
  const firstName = "Usuario";
  const isAdmin = true;
  

  const modalIcons: { [key: string]: JSX.Element } = {
    'Planificación': <Calendar className="h-6 w-6 text-cyan-600" />,
    'Control de Documentos': <FileText className="h-6 w-6 text-cyan-600" />,
    'Gestión': <Database className="h-6 w-6 text-cyan-600" />,
    'review': <BookOpen className="h-6 w-6 text-cyan-600" />,
    'sectorial-permits': <FileCode className="h-6 w-6 text-cyan-600" />,
    'ai-integration': <Database className="h-6 w-6 text-cyan-600" />,
    'Inicio': <Home className="h-6 w-6 text-cyan-600" />,

  };
  
  // Menu items structure con descripciones mejoradas
  const topMenuItems = [ 
    { 
      label: "Inicio", 
      icon: <Home className="h-5 w-5 text-white" />, 
      subItems: [] ,
      modalContent: ( <HomeModalContent />)
    },
    { 
      label: "Planificación", 
      icon: <Calendar className="h-5 w-5 text-white" />, 
      subItems: [],
      modalContent: (
        <div>
          <p className="mb-4">
            <span className="font-bold text-cyan-600">Convierte ideas en estrategias ganadoras con SIMAGI</span>. 
            Integra datos en tiempo real, asigna responsables y define tu matriz de riesgos para planificar con precisión y agilidad.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-6">
            <div className="bg-cyan-50 p-4 rounded-lg border border-cyan-100">
              <div className="flex items-center mb-2">
                <CheckCircle className="h-5 w-5 text-green-500 mr-2" />
                <h3 className="font-semibold text-gray-800">Optimización de Procesos</h3>
              </div>
              <p className="text-sm text-gray-600">Mejora cada paso de tu planificación con inteligencia artificial.</p>
            </div>
            <div className="bg-cyan-50 p-4 rounded-lg border border-cyan-100">
              <div className="flex items-center mb-2">
                <AlertCircle className="h-5 w-5 text-amber-500 mr-2" />
                <h3 className="font-semibold text-gray-800">Previsión de Riesgos</h3>
              </div>
              <p className="text-sm text-gray-600">Anticipa desafíos y prepárate para cualquier eventualidad.</p>
            </div>
          </div>
        </div>
      )
    },
    { 
      label: "Control de Documentos", 
      icon: <FileText className="h-5 w-5 text-white" />, 
      subItems: [],
      modalContent: (
        <div>
          <p className="mb-4">
            <span className="font-bold text-cyan-600">Optimiza la gestión documental</span> con un sistema digital que organiza archivos intuitivamente, elimina retrasos y mejora la colaboración.
          </p>
          <p className="mb-4">
            Olvídate de archivos físicos y errores costosos; integra todas tus herramientas en un entorno seguro y eficiente.
          </p>
          <div className="bg-gray-50 p-4 rounded-lg border border-gray-200 mt-4">
            <h3 className="font-semibold text-gray-800 mb-2">Beneficios clave:</h3>
            <ul className="space-y-2 text-gray-700">
              <li className="flex items-start">
                <CheckCircle className="h-5 w-5 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                <span>Centralización de todos los documentos en un solo lugar</span>
              </li>
              <li className="flex items-start">
                <CheckCircle className="h-5 w-5 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                <span>Control de versiones para evitar confusiones</span>
              </li>
              <li className="flex items-start">
                <CheckCircle className="h-5 w-5 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                <span>Búsqueda inteligente que ahorra tiempo</span>
              </li>
            </ul>
          </div>
        </div>
      )
    },
    { 
      label: "Gestión", 
      icon: <Database className="h-5 w-5 text-white" />, 
      subItems: [],
      modalContent: (
        <div>
          <p className="mb-4">
            <span className="font-bold text-cyan-600">Centraliza y optimiza la administración</span> en tiempo real. Con SIMAGI, automatiza tareas, integra información clave y toma decisiones precisas.
          </p>
          <div className="bg-cyan-50 p-4 rounded-lg border border-cyan-100 mt-4">
            <h3 className="font-semibold text-cyan-800 mb-2 flex items-center">
              <CheckCircle className="h-5 w-5 text-cyan-600 mr-2" />
              Resultados comprobados
            </h3>
            <div className="grid grid-cols-2 gap-4 mt-3">
              <div className="bg-white p-3 rounded-md shadow-sm">
                <p className="text-3xl font-bold text-cyan-600">30%</p>
                <p className="text-sm text-gray-600">Reducción de errores</p>
              </div>
              <div className="bg-white p-3 rounded-md shadow-sm">
                <p className="text-3xl font-bold text-cyan-600">45%</p>
                <p className="text-sm text-gray-600">Aumento en productividad</p>
              </div>
            </div>
          </div>
        </div>
      )
    }
  ];

  // Contenido mejorado para los modales laterales
  const moduleData: Record<string, { title: string; content: JSX.Element }> = {
    'review': { 
      title: 'Reseña', 
      content: (
        <div>
          <p className="mb-4">
            <span className="font-bold text-cyan-600">SIMAGI SPA</span> (Sistema Maestro de Gestión Integrado SpA) es una empresa innovadora fundada el 6 de febrero de 2025, especializada en software para Tramitología mediante herramientas para la Gestión documental, Planificación y Monitoreo.
          </p>
          <p className="mb-4">
            Su tecnología sin duda impactará los sectores inmobiliario, construcción e industria en general, integrando ERP e inteligencia artificial.
          </p>
          <div className="bg-gray-50 p-4 rounded-lg border border-gray-200 mt-4">
            <h3 className="font-semibold text-gray-800 flex items-center mb-2">
              <Calendar className="h-5 w-5 text-cyan-500 mr-2" />
              Línea de Tiempo
            </h3>
            <div className="space-y-2">
              <div className="flex">
                <div className="w-24 font-medium text-gray-700">Feb 2025:</div>
                <div className="flex-1 text-gray-600">Fundación de SIMAGI SPA</div>
              </div>
              <div className="flex">
                <div className="w-24 font-medium text-gray-700">Mar 2025:</div>
                <div className="flex-1 text-gray-600">Lanzamiento de plataforma beta</div>
              </div>
              <div className="flex">
                <div className="w-24 font-medium text-gray-700">Jun 2025:</div>
                <div className="flex-1 text-gray-600">Implementación con primeros clientes</div>
              </div>
            </div>
          </div> 
        </div>
      )
    },
    'sectorial-permits': { 
      title: 'Permisos sectoriales', 
      content: (
        <div>
          <p className="mb-4">
            La gestión de permisos sectoriales involucra a arquitectos, consultoras, oficinas de ingeniería, inmobiliarias, constructoras y fondos de inversión, junto con organismos regulatorios como MINVU y DOM.
          </p>
          <div className="bg-cyan-50 p-4 rounded-lg border border-cyan-100 mb-4">
            <h3 className="font-semibold text-cyan-800 mb-3">Actores principales</h3>
            <div className="grid grid-cols-2 gap-3">
              <div className="flex items-center bg-white p-2 rounded-md shadow-sm">
                <div className="p-2 bg-cyan-100 rounded-full mr-2">
                  <User className="h-4 w-4 text-cyan-600" />
                </div>
                <span className="text-sm text-gray-700">Consultoras</span>
              </div>
              <div className="flex items-center bg-white p-2 rounded-md shadow-sm">
                <div className="p-2 bg-cyan-100 rounded-full mr-2">
                  <User className="h-4 w-4 text-cyan-600" />
                </div>
                <span className="text-sm text-gray-700">Oficinas de Ingeniería</span>
              </div>
              <div className="flex items-center bg-white p-2 rounded-md shadow-sm">
                <div className="p-2 bg-cyan-100 rounded-full mr-2">
                  <User className="h-4 w-4 text-cyan-600" />
                </div>
                <span className="text-sm text-gray-700">Fondos de Inversión</span>
              </div>
              <div className="flex items-center bg-white p-2 rounded-md shadow-sm">
                <div className="p-2 bg-cyan-100 rounded-full mr-2">
                  <User className="h-4 w-4 text-cyan-600" />
                </div>
                <span className="text-sm text-gray-700">Desarrollo Inmobiliario</span>
              </div>
              <div className="flex items-center bg-white p-2 rounded-md shadow-sm">
                <div className="p-2 bg-cyan-100 rounded-full mr-2">
                  <User className="h-4 w-4 text-cyan-600" />
                </div>
                <span className="text-sm text-gray-700">Otras Industrias</span>
              </div>
            </div>
          </div>
          <p className="mb-4">
            SIMAGI centraliza la documentación y automatiza los procesos, facilitando una coordinación eficiente entre estos actores agilizando la aprobación de proyectos y facilitando el cumplimiento normativo.
          </p>
        </div>
      )
    },
    'ai-integration': { 
      title: 'Integración IA', 
      content: (
        <div>
          <p className="mb-4">
            <span className="font-bold text-cyan-600">SIMAGI potencia su ecosistema con inteligencia artificial (IA)</span> para optimizar la gestión y acceso a la información clave.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
              <div className="flex items-center mb-3">
                <div className="p-2 bg-cyan-100 rounded-full mr-2">
                  <BookOpen className="h-4 w-4 text-cyan-600" />
                </div>
                <h3 className="font-semibold text-gray-800">Biblioteca Normativa</h3>
              </div>
              <p className="text-sm text-gray-600">
                Consultas rápidas sobre regulaciones actualizadas, evitando errores y agilizando la toma de decisiones.
              </p>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
              <div className="flex items-center mb-3">
                <div className="p-2 bg-cyan-100 rounded-full mr-2">
                  <Database className="h-4 w-4 text-cyan-600" />
                </div>
                <h3 className="font-semibold text-gray-800">Base de Datos Inteligente</h3>
              </div>
              <p className="text-sm text-gray-600">
                Búsquedas avanzadas en lenguaje natural, recuperando información específica sin necesidad de conocimientos técnicos.
              </p>
            </div>
          </div>
          <p className="text-gray-700">
            Esta capacidad transforma la interacción con el sistema en una experiencia fluida, eficiente y accesible para todos los usuarios.
          </p>
        </div>
      )
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleRefresh = () => {
    setIsSpinning(true);
    setContentKey(prev => prev + 1);
    setTimeout(() => setIsSpinning(false), 1000);
  };


const handleTopMenuSelect = (main: string) => {
  // If the menu item has modal content, show it
  const menuItem = topMenuItems.find(item => item.label === main);
  if (menuItem?.modalContent) {
    setActiveModal(main);
  }
  
  // Toggle the open menu state
  if (openMenu === main) {
    setOpenMenu(null);
  } else {
    setOpenMenu(main);
    setActiveSection({ main, sub: '' });
  }
  
  // Close user menu whenever clicking on top menu
  setIsUserMenuOpen(false);
};

  const handleSubMenuSelect = (main: string, sub: string) => {
    setActiveSection({ main, sub });
    setOpenMenu(null);
  };

  // Component rendering
  const UserMenu = () => (
    <div ref={userMenuRef} className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-[1000] menu">
      <div className="py-1">
        <div className="px-4 py-0 text-sm text-gray-700">¡Hola, {firstName}!</div>
        <div className="px-4 text-sm text-red-500 text-xs">{isAdmin && <p>Administrador</p>}</div>
        <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"> <Settings className="inline-block mr-2 h-4 w-4" />Configuración</a>
        <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"><HelpCircle className="inline-block mr-2 h-4 w-4" />Ayuda</a>
        <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"><Info className="inline-block mr-2 h-4 w-4" />Acerca de</a>
        <button className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
          <LogOut className="inline-block mr-2 h-4 w-4" />Salir
        </button>
      </div>
    </div>
  );

  const ChatWindow = () => (
    <div ref={chatRef} className={`fixed right-4 ${isChatMinimized ? 'bottom-0' : 'bottom-4'} w-80 ${isChatMinimized ? 'h-10' : 'h-96'} bg-white rounded-lg shadow-lg z-[1000] flex flex-col transition-all duration-300`}>
      <div className="p-4 bg-custom-header text-white font-bold rounded-t-lg flex justify-between items-center">
        <span>Chat IA</span>
        <div className="flex items-center">
          <Minimize2 onClick={() => setIsChatMinimized(!isChatMinimized)} className="cursor-pointer mr-2" />
          <X onClick={() => setIsChatOpen(false)} className="cursor-pointer" />
        </div>
      </div>
      {!isChatMinimized && (
        <>
          <div className="flex-grow p-4 overflow-y-auto">
            <p>Bienvenido a Simagi. Soy tu asistente IA ¿En qué puedo ayudarte?</p>
          </div>
          <div className="p-4 border-t">
            <input type="text" placeholder="Escribe un mensaje..." className="w-full p-2 border rounded" />
          </div>
        </>
      )}
    </div>
  );

  const NotificationsMenu = () => (
    <div ref={notificationsRef} className="absolute right-0 mt-2 w-80 bg-white rounded-md shadow-lg z-[1000] max-h-96 overflow-y-auto menu">
      <div className="py-1">
        <div className="px-4 py-2 font-bold text-gray-700">Notificaciones</div>
        <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
          <div className="font-bold">Nueva tarea asignada</div>
          <div className="text-xs text-gray-500">Hace 5 minutos</div>
        </a>
        <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
          <div className="font-bold">Reunión programada</div>
          <div className="text-xs text-gray-500">Hace 1 hora</div>
        </a>
        <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
          <div className="font-bold">Actualización de proyecto</div>
          <div className="text-xs text-gray-500">Ayer</div>
        </a>
      </div>
    </div>
  );


  return (
    <div className="pb-2 overflow-hidden h-screen w-screen fondo-imagen z-[9000] bg-gray-100" style={{ backgroundImage: `url(${bgImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      {/* Header */}
      <header className={`fixed top-0 left-0 right-0 bg-custom-header z-[900] shadow-md transition-all duration-300 ease-in-out ${isHeaderVisible ? 'opacity-100' : 'opacity-0 -translate-y-full'}`}>
        <div className="mx-auto px-4 sm:px-6 lg:px-8 py-2">
          <div className="flex justify-between items-center">
            {/* Sidebar toggle button (visible only on desktop) */}
           <div className="flex items-center gap-0">
  <button 
    onClick={toggleSidebar}
    className="flex left-0 top-0 p-2 rounded-xl hover:bg-custom-sidebar hover:text-white transition-colors duration-200"
  >
    <Menu className="text-white h-6 w-6" />
  </button>

  <div className="h-10 w-24 flex items-center">
    <img src={logoImage} alt="Simagi" className="cursor-pointer" />
  </div>
</div>

            
            {/* Desktop menu */}
            <nav className="hidden md:flex items-center">
  {topMenuItems.map((item, index) => (
    <div key={index} className="relative group mx-4 cursor-pointer bg-custom-top-menu border-white border rounded-2xl">
      <div 
        className={`flex items-center px-4 py-2 transition-colors duration-200 ${
          openMenu === item.label 
            ? 'bg-custom-top-menu text-white rounded-2xl' 
            : 'text-white hover:bg-custom-sidebar hover:text-white rounded-2xl'
        }`}
        onClick={() => handleTopMenuSelect(item.label)}
      >
        {item.icon}
        <span className="text-sm ml-1 text-white">{item.label}</span>
        {item.subItems.length > 0 && (
          <ChevronDown 
            className={`h-4 text-white w-4 ml-1 transition-transform duration-200 ${
              openMenu === item.label ? 'transform rotate-180' : ''
            }`} 
          />
        )}
      </div>
      {openMenu === item.label && item.subItems.length > 0 && (
        <div className="absolute z-10 mt-1 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          {item.subItems.map((subItem, subIndex) => (
            <a
              key={subIndex}
              href="#"
              className={`block px-4 py-2 text-sm ${
                activeSection.sub === subItem 
                  ? 'bg-custom-sidebar text-white' 
                  : 'text-gray-700 hover:bg-custom-sidebar hover:text-white'
              }`}
              onClick={(e) => {
                e.preventDefault();
                handleSubMenuSelect(item.label, subItem);
              }}
            >
              {subItem}
            </a>
          ))}
        </div>
      )}
    </div>
  ))}
</nav>

            <div className="flex items-center space-x-4">
              <div className="relative">
                <Eye 
                  className="h-6 w-6 text-white cursor-pointer hover:text-custom-sidebar" 
                  onClick={() => setIsChatOpen(!isChatOpen)}
                />
                {isChatOpen && <ChatWindow />}
              </div>
              <div className="relative">
                <Bell 
                  className="h-6 w-6 text-white cursor-pointer hover:text-custom-sidebar" 
                  onClick={() => setIsNotificationsOpen(!isNotificationsOpen)}
                />
                {isNotificationsOpen && <NotificationsMenu />}
              </div>
              <div className="relative">
                <User 
                  className="h-6 w-6 text-white cursor-pointer hover:text-custom-sidebar" 
                  onClick={() => setIsUserMenuOpen(!isUserMenuOpen)}
                />
                {isUserMenuOpen && <UserMenu />}
              </div>

              {/* Mobile menu toggle button */}
              <button
                className="md:hidden"
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              >
                <Menu className="h-6 w-6 text-white" />
              </button>
            </div>
          </div>
        </div>
      </header>
      <div className="h-6"></div>
     
      <div className="flex-grow flex">
        {/* Add Vertical Buttons with Modal Component */}
      
        <div 
  className={`fixed left-4 top-24 flex flex-col gap-3 z-[800] transition-all duration-300 ease-in-out ${
    isSidebarOpen ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-full pointer-events-none'
  }`}
>
  <VerticalButtonsWithModal 
    isOpen={isSidebarOpen}
    openModal={openModal}
    isContactFormOpen={isContactFormOpen}
    setIsContactFormOpen={setIsContactFormOpen}
  />
</div>
        {/* Mobile Menu */}
        <div className={`fixed inset-0 bg-gray-600 bg-opacity-75 z-20 transition-opacity md:hidden ${
          isMobileMenuOpen ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'
        }`}>
          <div className={`fixed inset-y-0 left-0 max-w-xs w-full bg-white shadow-xl z-30 overflow-y-auto transition transform ${
            isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full'
          }`}>
            <div className="p-4">
              <button
                className="absolute top-4 right-4 text-gray-600"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <X className="h-6 w-6" />
              </button>
              
              {/* Mobile Menu Items */}
              {topMenuItems.map((item, index) => (
                <div key={index} className="mb-4">
                  <div 
                    className="py-2 px-4 cursor-pointer flex justify-between items-center rounded-md hover:bg-cyan-100 hover:text-custom-sidebar"
                    onClick={() => {
                      if (item.modalContent) {
                        setActiveModal(item.label);
                        setIsMobileMenuOpen(false);
                      }
                    }}
                  >
                    <span>{item.label}</span>
                    {item.subItems.length > 0 && <ChevronDown className="h-4 w-4" />}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <VisibilityControls /> 

        {/* Main Content Optimizado */} 
      {isCarouselVisible && ( 
        <div className="w-full scale-90  lg:w-1/2 mx-auto transition-all duration-300 mt-2 md:mt-6 mb-12 md:mb-6">
          <div className="bg-white rounded-2xl shadow-2xl mt-5  md:mt-4 md:mt-0 lg:mt-0 xl:mt-0 lg:mb-2 overflow-hidden">
            <div className="w-full flex flex-col">
              <main className="flex-1 shadow-md overflow-hidden bg-white rounded-xl transition-all duration-300 ease-in-out">
                
                {/* Content area with Carousel */}
                <motion.div
                  key={contentKey}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5 }}
                  className="flex-grow overflow-auto"
                >
                  <div className="max-w-6xl h-auto  mx-auto">
                    {/* CARRUSEL */}
                    <div className="p-3">
                      <div className="px-2 sm:px-6 md:px-10">
                        {/* Aquí se inserta el componente del carrusel */}
                        <SIMAGICarousel />
                      </div>
                    </div>
                  </div>
                </motion.div>
              </main>
            </div>
          </div>
        </div>
      )}
      </div>
          
 {/* Modals para los elementos del menú superior */}
 {topMenuItems.map((item) => (
      item.modalContent && (
        <EnhancedModal
          key={item.label}
          isOpen={activeModal === item.label}
          onClose={closeModal}
          title={item.label}
          content={item.modalContent}
          icon={modalIcons[item.label] || modalIcons['default']}
        />
      )
    ))}

    {/* Modals para los elementos de la barra lateral - usando EnhancedModal */}
    {['review', 'sectorial-permits', 'ai-integration'].map((id) => (
      <EnhancedModal
        key={id}
        isOpen={activeModal === id}
        onClose={closeModal}
        title={moduleData[id].title}
        content={moduleData[id].content}
        icon={modalIcons[id] || modalIcons['default']}
      />
    ))}

  
    
    {/* Formulario de contacto */}
    {isContactFormOpen && <ContactForm onClose={() => setIsContactFormOpen(false)} />}
  </div>
);
  
};

export default SimagiInterface;