//import React, { useEffect, useState } from 'react';
//import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
//import FormularioCurvas from './FormularioCurvas';
//
//interface DatosCurvaS {
//  Mes: string;
//  Programado: number;
//  AvanceReal: number;
//  Proyección: number;
//}
//
//const CurvaSModerna = () => {
//  const [data, setData] = useState<DatosCurvaS[]>([]);
//
//    useEffect(() => {
//        fetch('http://localhost:3000/php/pages/gestion/curvas/curvas.php')
//            .then(response => response.json())
//            .then(data => setData(data))
//            .catch(error => console.error('Error fetching data:', error));
//    }, []);
//  return (
//    <div style={{ width: '100%', maxWidth: 800, margin: 'auto', paddingBottom: 20 }}>
//      <h2 style={{ textAlign: 'center', fontFamily: 'Arial, sans-serif', color: '#333' }}>Curva S de Avance de Proyecto</h2>
//      <ResponsiveContainer width="100%" aspect={2}>
//        <LineChart
//          data={data}
//          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
//        >
//          <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
//          <XAxis dataKey="Mes" stroke="#888" />
//          <YAxis stroke="#888" tickFormatter={(value) => `${value}%`} />
//          <Tooltip formatter={(value) => `${value}%`} />
//          <Line type="monotone" dataKey="Programado" stroke="#ff6b6b" strokeWidth={2} dot={{ r: 4 }} name="Programado" />
//          <Line type="monotone" dataKey="AvanceReal" stroke="#4ecdc4" strokeWidth={2} dot={{ r: 4 }} name="Real" />
//          <Line type="monotone" dataKey="Proyección" stroke="#333" strokeWidth={2} strokeDasharray="5 5" dot={{ r: 4 }} name="Proyección" />
//        </LineChart>
//      </ResponsiveContainer>
//      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20, fontFamily: 'Arial, sans-serif' }}>
//        <div style={{ marginRight: 20, display: 'flex', alignItems: 'center' }}>
//          <div style={{ width: 20, height: 3, backgroundColor: '#ff6b6b', marginRight: 5 }}></div>
//          <span>Programado</span>
//        </div>
//        <div style={{ marginRight: 20, display: 'flex', alignItems: 'center' }}>
//          <div style={{ width: 20, height: 3, backgroundColor: '#4ecdc4', marginRight: 5 }}></div>
//          <span>Real</span>
//        </div>
//        <div style={{ display: 'flex', alignItems: 'center' }}>
//          <div style={{ width: 20, height: 3, backgroundColor: '#333', marginRight: 5, borderTop: '1px dashed #333' }}></div>
//          <span>Proyección a término</span>
//        </div>
//      </div>
//      <FormularioCurvas />
//    </div>
//  );
//};
//
////Nueva sección
//export default CurvaSModerna;

//import React, { useEffect, useState } from 'react';
//import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
//import FormularioCurvas from './FormularioCurvas'; // Importar el formulario
//import FormularioCurvas2 from './FormularioCurvas2'; // Importar el formulario
//import FormularioCurvas3 from './FormularioCurvas3'; // Importar el formulario
//import FormularioCurvasReal from './FormularioCurvas'; // Importar el formulario
//
//interface DatosCurvaS {
//  Mes: string;
//  Programado: number;
//  AvanceReal: number;
//  Proyección: number;
//}
//
//const CurvaSModerna = () => {
//  const [data, setData] = useState<DatosCurvaS[]>([]);
//  const [showModal, setShowModal] = useState(false); // Estado para controlar el modal
//
//  useEffect(() => {
//    fetch('http://localhost:3000/php/pages/gestion/curvas2/curvas_1.php')
//      .then(response => response.json())
//      .then(data => setData(data))
//      .catch(error => console.error('Error fetching data:', error));
//  }, []);
//
//  return (
//    <div style={{ width: '100%', maxWidth: 800, margin: 'auto', paddingBottom: 20 }}>
//      <h2 style={{ textAlign: 'center', fontFamily: 'Arial, sans-serif', color: '#333' }}>Curva S de Avance de Proyecto</h2>
//      <ResponsiveContainer width="100%" aspect={2}>
//        <LineChart
//          data={data}
//          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
//        >
//          <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
//          <XAxis dataKey="Mes" stroke="#888" />
//          <YAxis stroke="#888" tickFormatter={(value) => `${value}%`} />
//          <Tooltip formatter={(value) => `${value}%`} />
//          <Line type="monotone" dataKey="Programado" stroke="#ff6b6b" strokeWidth={2} dot={{ r: 4 }} name="Programado" />
//          <Line type="monotone" dataKey="AvanceReal" stroke="#4ecdc4" strokeWidth={2} dot={{ r: 4 }} name="Real" />
//          <Line type="monotone" dataKey="Proyección" stroke="#333" strokeWidth={2} strokeDasharray="5 5" dot={{ r: 4 }} name="Proyección" />
//        </LineChart>
//      </ResponsiveContainer>
//      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20, fontFamily: 'Arial, sans-serif' }}>
//        <div style={{ marginRight: 20, display: 'flex', alignItems: 'center' }}>
//          <div style={{ width: 20, height: 3, backgroundColor: '#ff6b6b', marginRight: 5 }}></div>
//          <span>Programado</span>
//        </div>
//        <div style={{ marginRight: 20, display: 'flex', alignItems: 'center' }}>
//          <div style={{ width: 20, height: 3, backgroundColor: '#4ecdc4', marginRight: 5 }}></div>
//          <span>Real</span>
//        </div>
//        <div style={{ display: 'flex', alignItems: 'center' }}>
//          <div style={{ width: 20, height: 3, backgroundColor: '#333', marginRight: 5, borderTop: '1px dashed #333' }}></div>
//          <span>Proyección a término</span>
//        </div>
//      </div>
//
//      {/* Botón para abrir el modal */}
//      <div style={{ textAlign: 'center', marginTop: 20 }}>
//        <button
//          style={{
//            padding: '10px 20px',
//            fontSize: '16px',
//            backgroundColor: '#4ecdc4',
//            color: '#fff',
//            border: 'none',
//            borderRadius: '5px',
//            cursor: 'pointer'
//          }}
//          onClick={() => setShowModal(true)}
//        >
//          Ingresar datos Reales
//        </button>
//      </div>
//
//      {/* Modal */}
//      {showModal && (
//        <div
//          style={{
//            position: 'fixed',
//            top: 0,
//            left: 0,
//            width: '100vw',
//            height: '100vh',
//            backgroundColor: 'rgba(0, 0, 0, 0.5)',
//            display: 'flex',
//            justifyContent: 'center',
//            alignItems: 'center'
//          }}
//        >
//          <div
//            style={{
//              backgroundColor: '#fff',
//              padding: '20px',
//              borderRadius: '8px',
//              width: '90%',
//              maxWidth: '600px'
//            }}
//          >
//            <button
//              style={{
//                position: 'absolute',
//                top: '10px',
//                right: '10px',
//                backgroundColor: 'transparent',
//                border: 'none',
//                fontSize: '20px',
//                cursor: 'pointer'
//              }}
//              onClick={() => setShowModal(false)}
//            >
//              &times;
//            </button>
//            <FormularioCurvasReal /> {/* Incluir el formulario */}
//            </div>
//        </div>
//      )}
//
//      {/* Botón para abrir el modal */}
//      <div style={{ textAlign: 'center', marginTop: 20 }}>
//        <button
//          style={{
//            padding: '10px 20px',
//            fontSize: '16px',
//            backgroundColor: '#4ecdc4',
//            color: '#fff',
//            border: 'none',
//            borderRadius: '5px',
//            cursor: 'pointer'
//          }}
//          onClick={() => setShowModal(true)}
//        >
//          Ingresar datos Programados
//        </button>
//      </div>
//
//      {/* Modal */}
//      {showModal && (
//        <div
//          style={{
//            position: 'fixed',
//            top: 0,
//            left: 0,
//            width: '100vw',
//            height: '100vh',
//            backgroundColor: 'rgba(0, 0, 0, 0.5)',
//            display: 'flex',
//            justifyContent: 'center',
//            alignItems: 'center'
//          }}
//        >
//          <div
//            style={{
//              backgroundColor: '#fff',
//              padding: '20px',
//              borderRadius: '8px',
//              width: '90%',
//              maxWidth: '600px'
//            }}
//          >
//            <button
//              style={{
//                position: 'absolute',
//                top: '10px',
//                right: '10px',
//                backgroundColor: 'transparent',
//                border: 'none',
//                fontSize: '20px',
//                cursor: 'pointer'
//              }}
//              onClick={() => setShowModal(false)}
//            >
//              &times;
//            </button>
//            <FormularioCurvas3 /> {/* Incluir el formulario */}
//          </div>
//        </div>
//      )}
//    </div>
//  );
//};
//
//export default CurvaSModerna;

//import React, { useEffect, useState } from 'react';
//import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
//import FormularioCurvas3 from './FormularioCurvas3'; // Importar el formulario programado
//import FormularioCurvasReal from './FormularioCurvasReal'; // Importar el formulario real
//
//interface DatosCurvaS {
//  Mes: string;
//  Programado: number;
//  AvanceReal: number;
//  Proyección: number;
//  Viviendas: number;
//  Urbanizacion: number;
//}
//
//const CurvaSModerna = () => {
//  const [data, setData] = useState<DatosCurvaS[]>([]);
//  const [showModalProgramado, setShowModalProgramado] = useState(false); // Estado para controlar el modal programado
//  const [showModalReal, setShowModalReal] = useState(false); // Estado para controlar el modal real
//
//  useEffect(() => {
//    fetch('http://localhost:3000/php/pages/gestion/curvas2/curvas_1.php')
//      .then(response => response.json())
//      .then(data => setData(data))
//      .catch(error => console.error('Error fetching data:', error));
//  }, []);
//
//  return (
//    <div style={{ width: '100%', maxWidth: 800, margin: 'auto', paddingBottom: 20 }}>
//      <h2 style={{ textAlign: 'center', fontFamily: 'Arial, sans-serif', color: '#333' }}>Curva S de Avance de Proyecto</h2>
//      <ResponsiveContainer width="100%" aspect={2}>
//        <LineChart
//          data={data}
//          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
//        >
//          <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
//          <XAxis dataKey="Mes" stroke="#888" />
//          <YAxis stroke="#888" tickFormatter={(value) => `${value}%`} />
//          <Tooltip formatter={(value) => `${value}%`} />
//          <Line type="monotone" dataKey="Programado" stroke="#ff6b6b" strokeWidth={2} dot={{ r: 4 }} name="Programado" />
//          <Line type="monotone" dataKey="Viviendas" stroke="#808080" strokeWidth={2} dot={{ r: 4 }} name="Viviendas" />
//          <Line type="monotone" dataKey="Urbanizacion" stroke="#FF8C00" strokeWidth={2} dot={{ r: 4 }} name="Urbanización" />
//          <Line type="monotone" dataKey="AvanceReal" stroke="#4ecdc4" strokeWidth={2} dot={{ r: 4 }} name="Real" />
//          <Line type="monotone" dataKey="Proyección" stroke="#333" strokeWidth={2} strokeDasharray="5 5" dot={{ r: 4 }} name="Proyección" />
//        </LineChart>
//      </ResponsiveContainer>
//      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20, fontFamily: 'Arial, sans-serif' }}>
//        <div style={{ marginRight: 20, display: 'flex', alignItems: 'center' }}>
//          <div style={{ width: 20, height: 3, backgroundColor: '#ff6b6b', marginRight: 5 }}></div>
//          <span>Programado</span>
//        </div>
//        <div style={{ marginRight: 20, display: 'flex', alignItems: 'center' }}>
//          <div style={{ width: 20, height: 3, backgroundColor: '#808080', marginRight: 5 }}></div>
//          <span>Viviendas</span>
//        </div>
//        <div style={{ marginRight: 20, display: 'flex', alignItems: 'center' }}>
//          <div style={{ width: 20, height: 3, backgroundColor: '#FF8C00', marginRight: 5 }}></div>
//          <span>Urbanización</span>
//        </div>
//        <div style={{ marginRight: 20, display: 'flex', alignItems: 'center' }}>
//          <div style={{ width: 20, height: 3, backgroundColor: '#4ecdc4', marginRight: 5 }}></div>
//          <span>Real</span>
//        </div>
//        <div style={{ display: 'flex', alignItems: 'center' }}>
//          <div style={{ width: 20, height: 3, backgroundColor: '#333', marginRight: 5, borderTop: '1px dashed #333' }}></div>
//          <span>Proyección a término</span>
//        </div>
//      </div>
//
//      {/* Botón para abrir el modal de datos reales */}
//      <div style={{ textAlign: 'center', marginTop: 20 }}>
//        <button
//          style={{
//            padding: '10px 20px',
//            fontSize: '16px',
//            backgroundColor: '#4ecdc4',
//            color: '#fff',
//            border: 'none',
//            borderRadius: '5px',
//            cursor: 'pointer'
//          }}
//          onClick={() => setShowModalReal(true)}
//        >
//          Ingresar datos Reales
//        </button>
//      </div>
//
//      {/* Modal para datos reales */}
//      {showModalReal && (
//        <div
//          style={{
//            position: 'fixed',
//            top: 0,
//            left: 0,
//            width: '100vw',
//            height: '100vh',
//            backgroundColor: 'rgba(0, 0, 0, 0.5)',
//            display: 'flex',
//            justifyContent: 'center',
//            alignItems: 'center'
//          }}
//        >
//          <div
//            style={{
//              backgroundColor: '#fff',
//              padding: '20px',
//              borderRadius: '8px',
//              width: '90%',
//              maxWidth: '600px'
//            }}
//          >
//            <button
//              style={{
//                position: 'absolute',
//                top: '10px',
//                right: '10px',
//                backgroundColor: 'transparent',
//                border: 'none',
//                fontSize: '20px',
//                cursor: 'pointer'
//              }}
//              onClick={() => setShowModalReal(false)}
//            >
//              &times;
//            </button>
//            <FormularioCurvasReal /> {/* Incluir el formulario real */}
//          </div>
//        </div>
//      )}
//
//      {/* Botón para abrir el modal de datos programados */}
//      <div style={{ textAlign: 'center', marginTop: 20 }}>
//        <button
//          style={{
//            padding: '10px 20px',
//            fontSize: '16px',
//            backgroundColor: '#4ecdc4',
//            color: '#fff',
//            border: 'none',
//            borderRadius: '5px',
//            cursor: 'pointer'
//          }}
//          onClick={() => setShowModalProgramado(true)}
//        >
//          Ingresar datos Programados
//        </button>
//      </div>
//
//      {/* Modal para datos programados */}
//      {showModalProgramado && (
//        <div
//          style={{
//            position: 'fixed',
//            top: 0,
//            left: 0,
//            width: '100vw',
//            height: '100vh',
//            backgroundColor: 'rgba(0, 0, 0, 0.5)',
//            display: 'flex',
//            justifyContent: 'center',
//            alignItems: 'center'
//          }}
//        >
//          <div
//            style={{
//              backgroundColor: '#fff',
//              padding: '20px',
//              borderRadius: '8px',
//              width: '90%',
//              maxWidth: '600px'
//            }}
//          >
//            <button
//              style={{
//                position: 'absolute',
//                top: '10px',
//                right: '10px',
//                backgroundColor: 'transparent',
//                border: 'none',
//                fontSize: '20px',
//                cursor: 'pointer'
//              }}
//              onClick={() => setShowModalProgramado(false)}
//            >
//              &times;
//            </button>
//            <FormularioCurvas3 /> {/* Incluir el formulario programado */}
//          </div>
//        </div>
//      )}
//    </div>
//  );
//};
//
//export default CurvaSModerna;
//
// Obtener lista de archivos disponibles en "guardar_excel/uploads/"
  //const fetchFiles = () => {
  //  fetch("descargar.php") // Solicita la lista de archivos al backend
  //      .then((res) => res.json())
  //      .then((data) => setUploadedFiles(data))
  //      .catch((err) => console.error("Error al obtener archivos:", err));
  //};
//
  //useEffect(() => {
  //    fetchFiles();
  //}, []);
//
  //// Guardar archivo seleccionado
  //const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //  if (event.target.files && event.target.files.length > 0) {
  //      setSelectedFile(event.target.files[0]);
  //  }
  //};
//
  //// Subir archivo
  //const handleUpload = async () => {
  //  if (!selectedFile) {
  //      alert("Selecciona un archivo primero.");
  //      return;
  //  }
//
  //  const formData = new FormData();
  //  formData.append("file", selectedFile);
//
  //  try {
  //      const response = await fetch("upload.php", {
  //          method: "POST",
  //          body: formData,
  //      });
//
  //      const result = await response.json();
  //      if (result.success) {
  //          alert("Archivo subido exitosamente.");
  //          fetchFiles(); // Recargar la lista de archivos subidos
  //      } else {
  //          alert("Error al subir el archivo.");
  //      }
  //  } catch (error) {
  //      console.error("Error en la subida:", error);
  //  }
  //};
  //const [uploadedFiles, setUploadedFiles] = useState<string[]>([]);
import React, { useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import FormularioCurvas3 from './FormularioCurvas3'; // Importar el formulario programado
import FormularioCurvasReal from './FormularioCurvasReal'; // Importar el formulario real

interface DatosCurvaS {
  Mes: string;
  Programado: number;
  AvanceReal: number;
  Proyección: number;
  Viviendas: number;
  Urbanizacion: number;
  ViviendasReal: number;
  UrbanizacionReal: number;
}

const CurvaSModerna = () => {
  const [data, setData] = useState<DatosCurvaS[]>([]);
  const [showModalProgramado, setShowModalProgramado] = useState(false); // Estado para controlar el modal programado
  const [showModalReal, setShowModalReal] = useState(false); // Estado para controlar el modal real
  const [startDate, setStartDate] = useState<Date | null>(() => {
    const savedDate = localStorage.getItem('startDate');
    return savedDate ? new Date(savedDate) : null;
  }); // Estado para la fecha de inicio
  //const [selectedFile, setSelectedFile] = useState<File | null>(null);
  //const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadedFiles, setUploadedFiles] = useState<string[]>([]);
  const baseUrl = process.env.REACT_APP_DOCS_URL; 

  useEffect(() => { 
    fetch(`${process.env.REACT_APP_API_URL}/php/pages/gestion/curvas2/curvas_1.php`)
      .then(response => response.json())
      .then(data => {
        if (Array.isArray(data)) {
          const preparedData = data.map((item, index) => ({
            ...item,
            Mes: item.Mes || `S${index + 1}` // Asegura que Mes siempre tenga un valor
          }));
          setData(preparedData);
          //calcularProyeccion(preparedData);
          //guardarDatosEnBaseDeDatos(preparedData);
        } else {
          console.error("❌ Datos recibidos no son un array:", data);
        }
      })
      .catch(error => console.error("❌ Error fetching data:", error));
  }, []);

  // Guardar la fecha de inicio en localStorage
  useEffect(() => {
    if (startDate) {
      localStorage.setItem('startDate', startDate.toISOString().split('T')[0]);
    }
  }, [startDate]);

  const getWeekDate = (weekLabel: string) => {
    if (!startDate) return "Fecha desconocida"; // Validar que startDate exista
    const weekNumber = parseInt(weekLabel.replace("S", ""), 10);
    if (isNaN(weekNumber)) return "Fecha desconocida";
    
    const date = new Date(startDate);
    date.setDate(date.getDate() + (weekNumber - 1) * 7);
    
    return date.toLocaleDateString("es-CL", { year: 'numeric', month: 'long', day: 'numeric' });
};


  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/php/pages/gestion/guardar_excel/listar_archivos.php`)
        .then(response => response.json())
        .then(data => setUploadedFiles(data.files || []))
        .catch(error => console.error("Error al obtener archivos:", error));
  }, []);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
        setSelectedFile(event.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
        alert("Selecciona un archivo primero.");
        return;
    }
    const formData = new FormData();
    formData.append("file", selectedFile);
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/gestion/guardar_excel/upload.php`, {
            method: "POST",
            body: formData,
        });
        const result = await response.json();
        if (result.success) { 
            alert("Archivo subido exitosamente.");
            setUploadedFiles([...uploadedFiles, result.fileName]);
        } else {
            alert("Error al subir el archivo.");
        }
    } catch (error) {
        console.error("Error en la subida:", error);
    }
  };

  //// Manejar selección de archivo
  //const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //    if (event.target.files && event.target.files.length > 0) {
  //        setSelectedFile(event.target.files[0]);
  //    }
  //};
  //// Subir archivo al servidor
  //const handleUpload = async () => {
  //    if (!selectedFile) {
  //        alert("Selecciona un archivo primero.");
  //        return;
  //    }
  //    const formData = new FormData();
  //    formData.append("file", selectedFile);
  //    try {
  //        const response = await fetch("http://localhost:3000/php/pages/gestion/guardar_excel/upload.php", {
  //            method: "POST",
  //            body: formData,
  //        });
  //        const result = await response.json();
  //        if (result.success) {
  //            alert("Archivo subido exitosamente.");
  //            //setUploadedFiles([...uploadedFiles, result.fileName]);
  //            setUploadedFileName(result.fileName); // Guardar nombre del archivo subido
  //        } else {
  //            alert("Error al subir el archivo.");
  //        }
  //    } catch (error) {
  //        console.error("Error en la subida:", error);
  //    }
  //};
  //// Descargar el archivo
  //const handleDownload = () => {
  //  if (!uploadedFileName) {
  //      alert("No hay archivos para descargar.");
  //      return;
  //  }
  //  const downloadUrl = `http://localhost:3000/php/pages/gestion/guardar_excel/descargar.php?file=${uploadedFileName}`;
  //  window.open(downloadUrl, "_blank");
  //};

  //const calcularProyeccion = (datos) => {
  //  // Filtrar solo los datos que tienen AvanceReal válido (descartando 0 o null)
  //  const datosReales = datos.filter(d => d.AvanceReal !== null && d.AvanceReal !== 0);
//
  //  // Calcular la tendencia basada solo en AvanceReal
  //  const tendenciaReal = datosReales.length > 1
  //      ? (datosReales[datosReales.length - 1].AvanceReal - datosReales[0].AvanceReal) / (datosReales.length - 1)
  //      : 0;
//
  //  const datosConProyeccion = datos.map((item, index) => {
  //      // Si la semana ya tiene AvanceReal, usarlo directamente
  //      if (item.AvanceReal !== null && item.AvanceReal !== 0) {
  //          return { ...item, Proyección: item.AvanceReal };
  //      }
//
  //      // Tomar el último valor real como punto de partida
  //      const ultimoReal = datosReales.length > 0 ? datosReales[datosReales.length - 1].AvanceReal : 0;
//
  //      // Calcular proyección en base a la tendencia
  //      let proyeccion = ultimoReal + tendenciaReal * (index - datosReales.length + 1);
//
  //      // Ajuste progresivo hacia la curva programada
  //      const programado = item.Programado !== null ? item.Programado : 100;
  //      const diferenciaProgramado = programado - proyeccion;
  //      const factorAjuste = 0.2; // Controla qué tan rápido se acerca al programado
  //      proyeccion += diferenciaProgramado * factorAjuste;
//
  //      // No permitir que la proyección supere el programado
  //      proyeccion = Math.min(proyeccion, programado);
//
  //      return {
  //          ...item,
  //          Proyección: proyeccion
  //      };
  //  });
//
  //  setData(datosConProyeccion);
  //  guardarDatosEnBaseDeDatos(datosConProyeccion); // Guarda los datos con proyección
  //};
//
  //const guardarDatosEnBaseDeDatos = async (datos) => {
  //  try {
  //      const response = await fetch('http://localhost:3000/php/pages/gestion/curvasreal/guardar_curvas.php', {
  //          method: 'POST',
  //          headers: { 'Content-Type': 'application/json' },
  //          body: JSON.stringify({ datos }) // Enviar datos con la proyección
  //      });
//
  //      const result = await response.json();
  //      if (result.success) {
  //          console.log('✅ Datos guardados correctamente');
  //      } else {
  //          console.error('❌ Error al guardar los datos:', result.error);
  //      }
  //  } catch (error) {
  //      console.error('❌ Error en la solicitud:', error);
  //  }
  //};

  return (
    <div style={{ width: '100%', maxWidth: 800, margin: 'auto', paddingBottom: 20 }}>
      <h2 style={{ textAlign: 'center', fontFamily: 'Arial, sans-serif', color: '#333' }}>Curva S de Avance de Proyecto</h2>

      {/* Botón para ingresar la fecha de inicio */}
      <div style={{ textAlign: 'center', marginTop: 20 }}>
      <h3 style={{ textAlign: 'center', fontFamily: 'Arial, sans-serif', color: '#333' }}>Fecha de inicio</h3>
        <input
          type="date"
          value={startDate?.toISOString().split('T')[0]}
          onChange={(e) => setStartDate(new Date(e.target.value))}
          style={{ padding: '10px', fontSize: '16px' }}
        />
      </div>

      <ResponsiveContainer width="100%" aspect={2}>
        <LineChart
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
          <XAxis dataKey="Mes" stroke="#888" />
          <YAxis stroke="#888" tickFormatter={(value) => `${value}%`} />
          <Tooltip
            labelFormatter={(label, payload) => {
              if (!payload || payload.length === 0) {
                return "Sin datos";
              }
              const semana = payload[0]?.payload?.Mes || `Semana ${label}`;
              const fecha = getWeekDate(semana); // Obtener la fecha real
              return `${semana} (${fecha})`;
            }}
            formatter={(value, name) => [`${name}: ${value}%`]}
          />
          <Line type="monotone" dataKey="Programado" stroke="#ff6b6b" strokeWidth={2} dot={{ r: 4 }} name="Programado" />
          {/*<Line type="monotone" dataKey="Viviendas" stroke="#808080" strokeWidth={2} dot={{ r: 4 }} name="Viviendas" />*/}
          {/*<Line type="monotone" dataKey="Urbanizacion" stroke="#FF8C00" strokeWidth={2} dot={{ r: 4 }} name="Urbanización" />*/}
          <Line type="monotone" dataKey="AvanceReal" stroke="#4ecdc4" strokeWidth={2} dot={{ r: 4 }} name="Real" />
          {/*<Line type="monotone" dataKey="ViviendasReal" stroke="#3A3B3C" strokeWidth={2} dot={{ r: 4 }} name="Viviendas Real" />*/}
          {/*<Line type="monotone" dataKey="UrbanizacionReal" stroke="#FF5722" strokeWidth={2} dot={{ r: 4 }} name="Urbanización Real" />*/}
          {/*this is it <Line type="monotone" dataKey="Proyección" stroke="#333" strokeWidth={2} strokeDasharray="5 5" dot={{ r: 4 }} name="Proyección" /> */}
        </LineChart>
      </ResponsiveContainer>

      {/* Leyenda del gráfico */}
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20, fontFamily: 'Arial, sans-serif' }}>
        <div style={{ marginRight: 20, display: 'flex', alignItems: 'center' }}>
          <div style={{ width: 20, height: 3, backgroundColor: '#ff6b6b', marginRight: 5 }}></div>
          <span>Programado</span>
        </div>
        {/*<div style={{ marginRight: 20, display: 'flex', alignItems: 'center' }}>
          <div style={{ width: 20, height: 3, backgroundColor: '#808080', marginRight: 5 }}></div>
          <span>Viviendas</span>
        </div>*/}
        {/*<div style={{ marginRight: 20, display: 'flex', alignItems: 'center' }}>
          <div style={{ width: 20, height: 3, backgroundColor: '#FF8C00', marginRight: 5 }}></div>
          <span>Urbanización</span>
        </div>*/}
        <div style={{ marginRight: 20, display: 'flex', alignItems: 'center' }}>
          <div style={{ width: 20, height: 3, backgroundColor: '#4ecdc4', marginRight: 5 }}></div>
          <span>Real</span>
        </div>
        {/*<div style={{ marginRight: 20, display: 'flex', alignItems: 'center' }}>
          <div style={{ width: 20, height: 3, backgroundColor: '#3A3B3C', marginRight: 5 }}></div>
          <span>Viviendas Real</span>
        </div>*/}
        {/*<div style={{ marginRight: 20, display: 'flex', alignItems: 'center' }}>
          <div style={{ width: 20, height: 3, backgroundColor: '#FF5722', marginRight: 5 }}></div>
          <span>Urbanización Real</span>
        </div>*/}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ width: 20, height: 3, backgroundColor: '#333', marginRight: 5, borderTop: '1px dashed #333' }}></div>
          <span>Proyección a término</span>
        </div>
      </div>

      {/* Botón para abrir el modal de datos reales */}
      <div style={{ textAlign: 'center', marginTop: 20 }}>
        <button
          style={{
            padding: '10px 20px',
            fontSize: '16px',
            backgroundColor: '#4ecdc4',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer'
          }}
          onClick={() => setShowModalReal(true)}
        >
          Ingresar datos Reales
        </button>
      </div>

      {/* Modal para datos reales */}
      {showModalReal && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: '300'
            
          }}
        >
          <div
            style={{
              backgroundColor: '#fff',
              padding: '20px',
              borderRadius: '8px',
              width: '100%',
              maxWidth: '1200px'
            }}
          >
            <button
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                backgroundColor: 'transparent',
                border: 'none',
                fontSize: '20px',
                cursor: 'pointer'
              }}
              onClick={() => setShowModalReal(false)}
            >
              &times;
            </button>
            <FormularioCurvasReal /> {/* Incluir el formulario real */}
          </div>
        </div>
      )}

      {/* Botón para abrir el modal de datos programados */}
      <div style={{ textAlign: 'center', marginTop: 20 }}>
        <button
          style={{
            padding: '10px 20px',
            fontSize: '16px',
            backgroundColor: '#ff6b6b',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            
          }}
          onClick={() => setShowModalProgramado(true)}
        >
          Ingresar datos Programados
        </button>
      </div>

      {/* Modal para datos programados */}
      {showModalProgramado && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: '300'

          }}
        >
          <div
            style={{
              backgroundColor: '#fff',
              padding: '20px',
              borderRadius: '8px',
              width: '100%',
              maxWidth: '1200px'
            }}
          >
            <button
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                backgroundColor: 'transparent',
                border: 'none',
                fontSize: '20px',
                cursor: 'pointer'
              }}
              onClick={() => setShowModalProgramado(false)}
            >
              &times;
            </button>
            <FormularioCurvas3 /> {/* Incluir el formulario programado */}
          </div>
        </div>
      )}
      {/*<div style={{ textAlign: "center", padding: 20 }}>
            <h2 style={{ textAlign: 'center', fontFamily: 'Arial, sans-serif', color: '#333' }}>Subir y Descargar Archivos Excel</h2>

            <input type="file" onChange={handleFileChange} />
            <button onClick={handleUpload}>Subir</button>

            <h3 style={{ textAlign: 'center', fontFamily: 'Arial, sans-serif', color: '#333' }}>Archivos Subidos</h3>
            <ul>
                {uploadedFiles.map((file, index) => (
                    <li key={index}>
                        <a href={`guardar_excel/uploads/${file}`} download>
                            {file}
                        </a>
                    </li>
                ))}
            </ul>
        </div>*/}
      {/*<div style={{ textAlign: "center", padding: 20 }}>
        <h2 style={{ textAlign: 'center', fontFamily: 'Arial, sans-serif', color: '#333' }}>Subir y Descargar Archivo Excel</h2>
        <input type="file" onChange={handleFileChange} />
        <button onClick={handleUpload}>Subir</button>
        <h3 style={{ textAlign: 'center', fontFamily: 'Arial, sans-serif', color: '#333' }}>Archivos Subidos</h3>
        {uploadedFileName && (
        <button onClick={handleDownload}>Descargar Último Archivo</button>
        )}
      </div>*/}
      {/*<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 20, fontFamily: 'Arial, sans-serif' }}>
        <h2 style={{ color: '#333' }}>Subir y Descargar Archivos Excel</h2>
        <div style={{ marginBottom: 20 }}>
            <input type="file" onChange={handleFileChange} style={{ display: 'none' }} id="fileInput" />
            <label htmlFor="fileInput" style={{ backgroundColor: 'rgb(174, 244, 236)', padding: '10px 20px', borderRadius: 5, cursor: 'pointer', color: '#333', fontSize: '16px', transition: 'background-color 0.3s' }}>
                Seleccionar Archivo
            </label>
            <button onClick={handleUpload} style={{ backgroundColor: '#333', color: '#fff', padding: '10px 20px', border: 'none', borderRadius: 5, cursor: 'pointer', fontSize: '16px', marginLeft: 10, transition: 'background-color 0.3s' }}>
                Subir
            </button>
        </div>

        <h3 style={{ color: '#333' }}>Archivos Subidos</h3>
        <ul style={{ listStyleType: 'none', padding: 0 }}>
            {uploadedFiles.map((file, index) => (
                <li key={index} style={{ margin: '10px 0', textAlign: 'center' }}>
                    <a href={`http://localhost:3000/php/pages/gestion/guardar_excel/uploads/${file}`} download style={{ textDecoration: 'none', color: '#333', fontSize: '16px', transition: 'color 0.3s' }}>
                        <span role="img" aria-label="file" style={{ marginRight: 5 }}>📄</span> {file}
                    </a>
                </li>
            ))}
        </ul>
      </div>*/}

      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 20, fontFamily: 'Arial, sans-serif' }}>
            <h2 style={{ textAlign: 'center', fontFamily: 'Arial, sans-serif', color: '#333' }}>Subir y Descargar Archivos Excel</h2>
            <div style={{ marginBottom: 20 }}>
              <input style={{ backgroundColor: 'rgb(222, 222, 222)', padding: '10px 20px', borderRadius: 5, cursor: 'pointer', color: '#333', fontSize: '16px', transition: 'background-color 0.3s' }} type="file" onChange={handleFileChange} />
              <button style={{ backgroundColor: 'rgb(126, 126, 126)', color: '#fff', padding: '10px 20px', border: 'none', borderRadius: 5, cursor: 'pointer', fontSize: '16px', marginLeft: 10, transition: 'background-color 0.3s' }} onClick={handleUpload}>Subir</button>
              </div>
            <h3 style={{ textAlign: 'center', fontFamily: 'Arial, sans-serif', color: '#333' }}>Archivos Subidos</h3>
            <ul style={{ listStyleType: 'none', padding: 0 }}>
                {uploadedFiles.map((file, index) => (
                    <li key={index} style={{ margin: '10px 0', textAlign: 'center' }}>
                        <a href={`${baseUrl}/uploads/curvas/${file}`} download>
                        {/* <a href={`http://localhost:3000/php/pages/gestion/guardar_excel/uploads/${file}`} download></a> */}
                        
                        <span role="img" aria-label="file" style={{ marginRight: 5 }}>📄</span>{file}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    </div>
  );
};

export default CurvaSModerna;